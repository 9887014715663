@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-chat {	
	
	#wrap {
		display: flex;
		flex-direction: column;
		background-color: color(base, 000);
	}
	
	.page-body {
		overflow: hidden;

		#local {
			.local-util {
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
					}
				}
			}

			.local-body {
				@include most-outer-selector('.mobile') {
					@include overflow-scroll-system(dark, medium, y);
				}

				@include most-outer-selector('.laptop') {
					@include overflow-scroll-custom(dark, medium, y);
				}

				.section.module-a {
					.section-wrap {
						display: flex;
						flex-direction: column;
						row-gap: 12rem;
						
						.section-head {
							.section-subject {
								margin: 0;
								@include font((outline: body, size: small-1x, weight: bold)...);
								color: color(normal, 100);
				
								.section-name {
									display: block;
								}
							}
						}
					}
				
					&:not([class*=type-]) {
						.section-wrap {
							.section-head {
								@include hidden;
							}
						}
					}
				}
				#content {
					.content-util {
						// padding: 16rem;
						box-shadow: 0 -1rem #0000000f;
						.board-display {
							background-color: #f9f9f9;
							padding: 8rem 16rem;
							box-shadow: 0 1rem #0000000f;
							.board-subject {
								@include font((outline: body, size: small-2x, weight: bold)...);	
							}
						}
						.submit-form.module-b {
							flex-direction: row;
							padding: 16rem;
							.form-wrap {
								flex: 100%;
								.form {
									&.textfield {
										.form-elem {
											height: auto;
											padding-right: 50rem;
											textarea {
												min-height: 48rem;
												width: 100%;
												@include font((outline: body, size: small-1x, weight: regular)...);	
												line-height: 1.6;
												outline: 0;
												padding: 8rem;
												box-sizing: border-box;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			// .local-util {
			// 	padding: 16rem;
			// 	box-shadow: 0 -1rem #0000000f;
			// 	.submit-form.module-b {
			// 		flex-direction: row;
			// 		.form-wrap {
			// 			flex: 100%;
			// 		}
			// 	}
			// }
		}
	}

	// mobile
	.page-head {
		@include respond-below(laptop) {
			order: 3;
			flex-shrink: 0;
			bottom: 0;
	
			.page-title {
				@include hidden;
			}
	
			.page-navi {
				position: relative;
				z-index: 20;
				width: 100%;
				box-shadow: 0 -1rem 0 0 rgba(0,0,0,0.06);
			
				.navi-list {
					display: flex;
					margin: 0;
					padding: 0;
					list-style: none;
			
					.navi-item:nth-child(4) {
						display: none;
					}
			
					.navi-item {
						flex: 1 1 100%;
						position: relative;
						width: 100%;
			
						.navi-name {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							position: relative;
							height: 60rem;
							@include font((outline: body, size: small-2x)...);
							color: color(base, 080);
							text-align: center;
							text-decoration: none;
			
							&::before {
								content: '';
								display: block;
								width: 24rem;
								height: 24rem;
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
							}

							.count {
								display: flex;
								padding: 0 6rem 0 5rem;
								height: 16rem;
								@include font((outline: body, size: small-4x)...);
								color: color(base, 000);
								border-radius: 8rem;
								text-align: center;
								background-color: color(accent, 010);
								position: absolute;
								top: 4rem;
								right: 16rem;
							}
						}
			
						&.current {
							.navi-name {
								background-color: color(base, 005);
							}
						}
			
						&:nth-child(1) {
							order: 1;
							display: block;
							.navi-name::before {
								background-image: icon(dawin-logo, color(base, 090));
							}
						}
						&:nth-child(2) {
							order: 3;
							.navi-name::before {
								background-image: icon(dawin-map, color(base, 090));
							}
						}
						&:nth-child(3) {
							order: 2;
							.navi-name::before {
								background-image: icon(dawin-sell, color(base, 090));
							}
						}
						&:nth-child(4) {
							display: none;
						}
						&:nth-child(5) {
							display: none;
						}
						&:nth-child(6) {
							order: 4;
							.navi-name::before {
								background-image: icon(dawin-chat, color(base, 090));
							}
						}
						&:nth-child(7) {
							order: 5;
							display: block;
							.navi-name::before {
								background-image: icon(dawin-user, color(base, 090));
							}
						}

						// &:nth-child(4) {
						// 	.navi-name::before {
						// 		background-image: icon(dawin-graph, color(base, 090));
						// 	}
						// }
						// &:nth-child(5) {
						// 	.navi-name::before {
						// 		background-image: icon(dawin-board, color(base, 090));
						// 	}
	
						// 	&::after {
						// 		content: "Beta";
						// 		display: block;
						// 		position: absolute;
						// 		top: 8rem;
						// 		left: 50%;
	
						// 		padding: 0 4px;
						// 		height: 14px;
						// 		line-height: 14px;
						// 		font-size: 9px;
						// 		color: color(base, 000);
						// 		letter-spacing: 0;
						// 		border-radius: 7px;
						// 		background: color(secondary, 040);
						// 	}
						// }
						// &:nth-child(5) {
						// 	.navi-name::before {
						// 		background-image: icon(dawin-user, color(base, 090));
						// 	}
						// }
					}
				}
			}
	
			.section.page-util {
				.navi-list {
					.navi-item {
						&:nth-child(2) {
							display:block;
						}
						&:nth-child(3) {
							display:none;
						}
					}
				}			
			}
			
		}
	}
	
	
	@include respond-below(laptop) {
		.page-body {
			#local {
				flex: 1 1 100%;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				
				.local-head {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
							.btn.call::before {
								background-color: color(base, 000);
								background-image: icon(call, color(base, 100));
							}
							.btn.favorite::before {
								background-color: color(base, 000);
								background-image: icon(favorite, color(base, 100));
							}
							.btn.favorite.on::before {
								background-color: color(base, 000);
								background-image: icon(favorite-on, color(accent, 010));
							}
						}
					}
				}
			
				.local-body {
					padding: 0;
					width: 100%;
					height: 100%;
					flex-direction: column;
					flex: 100%;
					display: flex;
					overflow: hidden;

					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
	
					#content {
						// margin: 0 0 80rem 0;
						display:block;
						border-top: none;
						flex-direction: row;
						flex: 100%;
						display: flex;
						overflow: hidden;

						.content-head {
							flex-shrink: 0;
							display: flex;
							align-items: center;
							column-gap: 8rem;
							padding: 0 16rem;
							height: 64rem;
							box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
							z-index: 10;
							background-color: #fff;
							flex-shrink: 0;
							// position: absolute;
							// top: 0;
							// left: 0;
							// right: 0;
						
							.content-subject {
								order: 2;
								flex: 1 1 100%;
								display: flex;
								flex-direction: column;
								justify-content: center;
								margin: 0;
								cursor: pointer;
						
								.content-name {
									display: flex;
									color: color(base, 100);
									@include font((outline: head, size: small-1x, weight: bold)...);
									@include ellipsis(1);
									.mark {
										margin-left: 4rem;
									}									
								}
								.content-summary {
									display: flex;
									color: color(base, 100);
									@include font((outline: head, size: small-2x, weight: bold)...);
									@include ellipsis(1);
								}
			
								&.type-d {
									.content-name {
										order: 2;
										display: flex;
										align-items: center;
									}
									.content-summary {
										order: 1;
									}
								}
							}
						
							.content-control {
								order: 1;
								flex-shrink: 0;
						
								.button-area {
									.btn {
										display: block;
										position: relative;
										height: 24rem;
										width: 24rem;
										color: color(base, 100);
										text-align: center;
						
										.btn-text {
											@include font((outline: body, size: small-4x)...);
										}
						
										&::before {
											content: '';
											display: block;
											position: absolute;
											top: 0;
											right: 0;
											bottom: 0;
											left: 0;
											background-repeat: no-repeat;
											background-position: center;
											background-size: cover;
										}
									}
						
									.btn.back::before {
										background-color: color(base, 000);
										background-image: icon(arrow-back, color(base, 100));
									}
									.btn.call::before {
										background-color: color(base, 000);
										background-image: icon(call, color(base, 100));
									}
									.btn.favorite::before {
										background-color: color(base, 000);
										background-image: icon(favorite, color(base, 100));
									}
									.btn.favorite.on::before {
										background-color: color(base, 000);
										background-image: icon(favorite-on, color(accent, 010));
									}
								}
								&:nth-child(3) {
									order: 3
								}
								&:nth-child(4) {
									order: 4
								}
							}
						}

						.chatlist-wrap {
							display: flex;
							flex-direction: column;
							overflow: hidden;
							flex: 100;
							height: 100%;
							
							.content-body {
								width: 100%;
								flex-direction: column;
								margin: 0 auto;
								padding: 0;
								display: flex;
								position: relative;
								flex: 1;
								flex-shrink: 0;
								overflow: hidden;

								.chat-display {
									flex: 1;
									flex-shrink: 0;
									overflow-y: overlay;
									padding: 0;
								}
							}							
						}	
					}	
					&.local-chatlist {
						#content {
							.chatlist-wrap {
								&.chatlist {
									display:flex;
									width:100%;
									min-width:100%;
									border-right: none;
								}
								&.chatroom {
									display: none;
									width:100%;
									min-width:100%;
									border-left: none;
									padding: none;
									box-sizing: border-box;
									.chat-display {
										overflow-y: hidden;
										.chat-list {
											overflow-y: overlay;
											@at-root html.mobile & {
												@include overflow-scroll-system(dark, medium, y);
											}
									
											@at-root html.laptop & {
												@include overflow-scroll-custom(dark, medium, y);
											}
										}
									}
								}
								&.chatuser {
									display: none;
									width:100%;
									min-width:100%;
									border-left: none;
									padding: none;
									box-sizing: border-box;
								}
							}	
						}
					}
					&.local-chatroom {
						#content {
							.chatlist-wrap {
								&.chatlist {
									display: none;
									width:100%;
									min-width:100%;
									border-right: none;
								}
								&.chatroom {
									display:flex;
									width:100%;
									min-width:100%;
									border-left: none;
									padding: none;
									box-sizing: border-box;
									.chat-display {
										overflow-y: hidden;
										.chat-list {
											overflow-y: overlay;
											@at-root html.mobile & {
												@include overflow-scroll-system(dark, medium, y);
											}
									
											@at-root html.laptop & {
												@include overflow-scroll-custom(dark, medium, y);
											}
										}
									}
								}
								&.chatuser {
									display: none;
									width:100%;
									min-width:100%;
									border-left: none;
									padding: none;
									box-sizing: border-box;									
								}
							}	
						}
					}				
				}
	
				.local-util {
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
		}
	
		#page.page-intro {
			flex: 1 1 100%;
			padding: 64rem 0 60rem 0;
			overflow:hidden;

			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, medium, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, medium, y);
			}
	
			.page-head {
				position: fixed;
				right: 0;
				bottom: 0;
				left: 0;

				.page-util {
					display: none;
				}
			}
	
			.page-body {
				flex:100%;

				#local {
					.local-head {
						position: absolute;
						z-index: 10;
						top: 0;
						right: 0;
						left: 0;
						flex-shrink: 0;
						background-color: color(base, 000);

						.local-control {
							display: none;
						}
					}
				}
			}

			.page-foot {
				display:none;
			}
		}
	
		#page.page-front {
			.page-head {
				display: none;
			}
	
			.page-body {
				overflow: hidden;
				flex: 100%;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
					
					.local-head {
						// display:none;
					}
				}
	
				.local-body {
					// flex: 1 1 100%;	
					flex:100%;

					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
					.content {
						// padding-top: 64rem;
						border-top: none;
						// margin: 0 0 80rem;
						
						.content-head {
							flex-shrink: 0;
							display: flex;
							align-items: center;
							column-gap: 8rem;
							padding: 0 16rem;
							height: 64rem;
							box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
							z-index: 10;
							background-color: #fff;
							flex-shrink: 0;
							// position: absolute;
							// top: 0;
							// left: 0;
							// right: 0;
						
							.content-subject {
								order: 2;
								flex: 1 1 100%;
								display: flex;
								flex-direction: column;
								justify-content: center;
								margin: 0;
						
								.content-name {
									display: flex;
									color: color(base, 100);
									@include font((outline: head, size: small-1x, weight: bold)...);
									@include ellipsis(1);
								}
								.content-summary {
									display: flex;
									color: color(base, 100);
									@include font((outline: head, size: small-2x, weight: bold)...);
									@include ellipsis(1);
								}
							}
						
							.content-control {
								order: 1;
								flex-shrink: 0;
						
								.button-area {
									.btn {
										display: block;
										position: relative;
										height: 24rem;
										width: 24rem;
										color: color(base, 100);
										text-align: center;
						
										.btn-text {
											@include font((outline: body, size: small-4x)...);
										}
						
										&::before {
											content: '';
											display: block;
											position: absolute;
											top: 0;
											right: 0;
											bottom: 0;
											left: 0;
											background-repeat: no-repeat;
											background-position: center;
											background-size: cover;
										}
									}
						
									.btn.back::before {
										background-color: color(base, 000);
										background-image: icon(arrow-back, color(base, 100));
									}
									.btn.call::before {
										background-color: color(base, 000);
										background-image: icon(call, color(base, 100));
									}
									.btn.favorite::before {
										background-color: color(base, 000);
										background-image: icon(favorite, color(base, 100));
									}
									.btn.favorite.on::before {
										background-color: color(base, 000);
										background-image: icon(favorite-on, color(accent, 010));
									}
								}
								&:nth-child(3) {
									order: 3
								}
								&:nth-child(4) {
									order: 4
								}
							}
						}											
					}					
				}
			}
	
			.page-foot {
				display: none;
			}
		}
	}
	
	
	@include respond-above(laptop) {
		#page {
			// 임시 선언
			display: block;
			margin: 56rem 0 0 0;
			overflow: hidden !important;
			
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, large, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, large, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
		}
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				padding: 40rem 0 80rem 0;
	
				.local-head {
					display: flex;
					flex-direction: column;
					align-items: center;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0;
				}
			}
		}
		.page-foot,
		#page-foot {
			display: none;
		}
	}
	
	
	.local-body {
		padding:0;
		#content {	
			display: flex;
			border-top: 1rem solid rgba(0,0,0,0.05);
			border-bottom: 1rem solid rgba(0,0,0,0.05);
			height: calc(100vh - 214rem);
			justify-content: center;

			.chatlist-wrap {			

				.content-head {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
					z-index: 10;
					background-color: #fff;
					flex-shrink: 0;
					position: unset;
					top: unset;
					left: unset;
					right: unset;
				
					.content-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						margin: 0;
						gap: 4rem;
						cursor: pointer;
				
						.content-name {							
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
							gap: 4rem;
							align-items: center;
							display: flex;
							.mark {
								margin-left: 4rem;
							}
							&:after {
								flex-shrink: 0;
								content: '';
								display: inline-flex;
								width: 24rem;
								height: 24rem;
								background: icon(chevron-right, color(normal, 020)) no-repeat center/cover;
							}
						}
						.content-summary {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-2x, weight: bold)...);
							@include ellipsis(1);
						}

						&.type-d {
							.content-name {
								order: 2;
							}
							.content-summary {
								order: 1;
							}
						}
					}
				
					.content-control {
						order: 1;
						flex-shrink: 0;
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
							.btn.call::before {
								background-color: color(base, 000);
								background-image: icon(call, color(base, 100));
							}
							.btn.favorite::before {
								background-color: color(base, 000);
								background-image: icon(favorite, color(base, 100));
							}
							.btn.favorite.on::before {
								background-color: color(base, 000);
								background-image: icon(favorite-on, color(accent, 010));
							}
						}
						&:nth-child(3) {
							order: 3
						}
						&:nth-child(4) {
							order: 4
						}
					}
				}
				.content-body {
					display: flex;
					flex-direction: column;
					position: relative;
					margin: 0 auto;
					padding: 0;
					width: 100%;
					// max-width: 800rem;
					.chat-display {						
						width: 100%;
						flex-direction: column;
						flex: 1 0;
						margin: 0 auto;
						padding: 0;
						display: flex;
						position: relative;
						overflow: hidden;
						overflow-y: overlay;
						.info-board {
							margin: 20rem 16rem 0; 
							&.chat-subject {
								padding: 16rem;
								border-radius: 16rem;
								background-color: color(secondary, 005);
								&.style-b {
									.board-wrap {
										.board-inform {
											&::after {
												display: none;
											}
										}
									}
								}
							}
							.board-wrap {
								margin: 0;
								.board-inform {							
									flex: 100;
									justify-content: flex-start;
									align-items: center;
									gap: 16rem;
									display: flex;
									.board-head {	
										flex: 100;
										width: 80%;								
										.goods-display {
											.goods-item {
												.goods-wrap {
													.goods-inform {
														.goods-tag {
															margin-bottom: 4rem;
															.head {
																@include hidden;
															}
															.body {
																display: flex;
																gap: 4rem;
																flex-wrap: wrap;
															}
														}
														.goods-price {
															margin-top:2rem;
															.data-list {
																.data-item {
																	display: flex;
																	gap: 4rem;
																	.body {
																		@include font((outline: body, size: small-1x, weight: bold)...);
																	}
																}
															}
														}
													}
												}
											}
										}									
									}
									&::after {
										flex-shrink: 0;
										content: '';
										display: block;
										width: 24rem;
										height: 24rem;
										margin-left: auto;
										background: icon(chevron-right, color(normal, 020)) no-repeat center/cover;
									}
								}
							}
							.board-wrap {
								margin: 0;
								.board-inform {							
									flex: 100;
									justify-content: flex-start;
									align-items: center;
									gap: 16rem;
									display: flex;
									.board-head {	
										flex: 100;	
										width: 80%;								
										.posts-display {
											.posts-wrap {
												.posts-inform {
													display: flex;
													flex-direction: column;
													.posts-info {
														order: 3;
														.data-list {
															.data-item {
																.head {
																	@include hidden;
																}
																.body {
																	color: color(normal, 030);
																	@include font((outline: body, size: small-2x)...);
																}
															}
														}
													}
													.posts-tag {
														margin-bottom: 4rem;
														order: 1;
														.head {
															@include hidden;
														}
														.body {
															display: flex;
															gap: 4rem;
															flex-wrap: wrap;
														}
													}
													.posts-head {
														order: 2;
														.posts-subject {
															.posts-name {
																@include ellipsis(2);
															}
														}													
													}
												}
											}
										}									
									}
									&::after {
										flex-shrink: 0;
										content: '';
										display: block;
										width: 24rem;
										height: 24rem;
										margin-left: auto;
										background: icon(chevron-right, color(normal, 020)) no-repeat center/cover;
									}
								}
							}
						}	
						.chat-list {
							flex: 1;
							flex-shrink: 0;
							// overflow-y: overlay;
							padding-bottom: 40rem;
						}				
					}				
				}
				&.chatlist {
					width:400rem;
					min-width: 320rem;
					border-right: 1rem solid rgba(0,0,0,0.05);
					border-left: 1rem solid rgba(0,0,0,0.05);
					display: flex;
					overflow: hidden;
					box-sizing:border-box;
					.tab-display {
						padding:0; 
						.tab-list {
							padding:0 20rem;
							&::before {
								width: 100%;
								left: 0;
								right: 0;
							}
							.tab-item {
								cursor: pointer;
							}
						}
					}
					.chat-display {
						// padding: 0 16rem;
						.chat-list {
							.chat-item {
								padding: 16rem 24rem;
								cursor: pointer;
								&:hover,
								&.current {
									background-color: color(base, 005);
								}
							}							
						}
					}
					.button-display {
						position: absolute;
						bottom: 32rem;
						filter: drop-shadow(0 2rem 4rem rgba(0,0,0,.25));
					}
				}
				&.chatroom {
					min-width: 480rem;
					max-width: 500rem;
					// flex: auto;
					height: 100%;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					border-right: 1rem solid rgba(0,0,0,0.05);
					.content-body {
						// padding: 0 16rem;
						flex: 1;
						flex-shrink: 0;
						overflow: hidden;
						.chat-display {
							overflow-y: hidden;
							.chat-list {
								overflow-y: overlay;
								@at-root html.mobile & {
									@include overflow-scroll-system(dark, medium, y);
								}
						
								@at-root html.laptop & {
									@include overflow-scroll-custom(dark, medium, y);
								}
							}
						}
					}
					&.unknown {
						.content-head {
							.content-subject {
								.content-name {
									display:none
								}
								.content-summary {
									color: color(base, 040);
									@include font((outline: body, size: small)...);
								}
							}
						}		
					}
					// &.unselect {
					// 	.content-head {
					// 		display: none;
					// 	}
					// 	.content-body {
					// 		.info-board {
					// 			margin-top: 120rem;
					// 		}
					// 		.chat-display {
					// 			display: none;
					// 		}
					// 	}
					// 	.content-util {
					// 		display: none;
					// 	}
					// }
				}
				&.chatuser {
					min-width: 360rem;
					width: 360rem;
					border-right: 1rem solid rgba(0,0,0,0.05);
					box-sizing: border-box;
					display: flex;
					overflow: hidden;
					flex-direction: column;
					position: relative;
					.content-head {
						justify-content: space-between;
						box-shadow: unset;
						height: 56rem;
						display: flex;
						gap: 16rem;
						.content-head-control {
							order: 1;
							flex-shrink: 0;
					
							.button-area {
								.btn {
									display: block;
									position: relative;
									height: 24rem;
									width: 24rem;
									color: color(base, 100);
									text-align: center;
					
									.btn-text {
										@include font((outline: body, size: small-4x)...);
									}
					
									&::before {
										content: '';
										display: block;
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										left: 0;
										background-repeat: no-repeat;
										background-position: center;
										background-size: cover;
									}
								}
					
								.btn.close::before {
									background-color: color(base, 000);
									background-image: icon(close-medium, color(base, 100));
								}
							}
						}

					}
					.content-body {
						overflow-y: overlay;
    					overflow-x: hidden;
						.info-board {
							padding: 0 24rem;
							.board-wrap {
								.board-head {
									.board-subject {
										.board-name {
											@include font((outline: body, size: medium, weight: bold)...);
										}
									}
								}
							}
						}
						.section {
							padding: 24rem;	
							&.brokerintroduce {
								.section-wrap {
									.section-body {
										.goods-display.module-c.style-c {
											.goods-list {
												.goods-item {
													.goods-wrap {
														.goods-inform {
															.goods-info {
																padding-right: 0;
															}
															.button-display {
																position:unset;
																margin-top: 8rem;
															}
														}
														.info-board {
															padding: 0;
														}
													}
												}
											}
										}
									}
								}
							}	
							&.brokerinfo {
								.section-wrap {
									.section-body {
										.goods-display.module-c.style-a {
											.goods-list	{
												flex-direction: column;
												gap: 8rem;
											}		
										}
									}
								}
							}
							&.activitydetails {
								.section-wrap {
									.section-body {
										.subsection {
											.subsection-wrap {
												.goods-display {
													.goods-list {
														// flex-direction: column;
														gap: 16rem;
														.goods-item {
															// min-width: 100%;
															// max-width: 100%
														}
													}
												}
											}
										}
									}
								}
							}
							&.type-a {
								.section-subject {
									gap: 4rem;
									margin: 0;
									display: flex;
								}
							}							
						}
					}
				}
			}
		}
	}
}