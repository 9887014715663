/*
 * btn system reset
*/

// input.btn,
// button.btn {
// 	overflow: visible;
// 	margin: 0;
// 	padding: 0;
// 	cursor: pointer;
// 	border: hidden;
// 	background-color: transparent;
// 	appearance: none;
// }





:root[class*=root-bui-],
:root[class*=root-front-] {

}

input.btn:not([class*=btn-]),
button.btn:not([class*=btn-]) {
	overflow: visible;
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: hidden;
	cursor: pointer;
	appearance: none;
}

.btn svg {
	display: block;
	position: relative;
	top: 0;
	left: 0;
}

.btn-ico {
	flex-shrink: 1;
	display: inline-flex;
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: cover;

	.btn-text {
		@include hidden;
	}

	svg {
		width: 100%;
		height: 100%;
	}

	&.small-1x {
		width: 24rem;
		height: 24rem;
	}
	&.small-2x {
		width: 20rem;
		height: 20rem;
	}

	&.normal-02 {
		background-color: color(normal, 020);
	}

	&.attr-help {
		mask-image: icon(help-line, color(normal, 100));
	}
	&.attr-prev {
		mask-image: icon(chevron-left, color(normal, 100));
	}
	&.attr-first {
		mask-image: icon(chevron-leftdouble, color(normal, 100));
	}
	&.attr-next {
		mask-image: icon(chevron-right, color(normal, 100));
	}
	&.attr-last {
		mask-image: icon(chevron-rightdouble, color(normal, 100));
	}
}

$btn-module-a-accent: (
	normal-00: color(base, 100),
	normal-01: color(base, 080),
	normal-02: color(base, 060),
	normal-03: color(base, 040),
	normal-10: color(base, 000),
	
	accent-01: color(primary, 050),
	accent-02: color(secondary, 010),
	accent-03: color(secondary, 020),
	accent-04: color(secondary, 030),

	social-01: color(social, 100),
	social-02: color(social, 010),
	social-03: color(social, 020),
);

$btn-module-a-outline: (
	'small-3x'		16rem 1.25,
	'small-2x'		18rem 1.25,
	'small'			20rem 1.50,
	'medium'		24rem 1.50,
	'large'			28rem 1.25,
	'large-2x'		32rem 1.50,
);

input.btn.module-a,
button.btn.module-a {
	border-style: solid;
}

// btn module-a reset
a.btn.module-a {
	text-decoration: none;
}

.btn.module-a {
	// general
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@include font((family: default)...);

	.btn-text {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	// accent
	@each $name, $color in $btn-module-a-accent {
		&.#{$name} {
			color: $color;
		}
	}

	// outline
	@each $name, $line-height, $font-size in $btn-module-a-outline {
		&.#{$name} {
			line-height: $line-height;
			font-size: calc($line-height / $font-size);
		}
	}
}



/*
 * btn module-b system
 */

/*
 * @ module-b accent
 *
 * @ $text-color
 * @ $border-color
 * @ $background-color
*/
$btn-module-b-accent: (
	'normal-00': (
		'none' color(normal, 100) transparent transparent,
		'line' color(normal, 100) color(normal, 100) color(normal, 000),
		'linenone' color(normal, 000) color(normal, 000) transparent,
		'fill' color(normal, 000) color(normal, 100) color(normal, 100),
	),
	'normal-01': (
		'none' color(normal, 010) transparent transparent,
		'line' color(normal, 010) color(normal, 010) color(normal, 000),
		'linenone' color(normal, 010) color(normal, 010) transparent,
		'fill' color(normal, 000) color(normal, 010) color(normal, 010),
	),
	'normal-02': (
		'none' color(normal, 020) transparent transparent,
		'line' color(normal, 020) color(normal, 020) color(normal, 000),
		'fill' color(normal, 000) color(normal, 020) color(normal, 020),
	),
	'normal-03': (
		'none' color(base, 030) transparent transparent,
		'line' color(normal, 020) color(normal, 030) color(normal, 000),
		'fill' color(normal, 000) color(normal, 030) color(normal, 030),
	),
	'normal-04': (
		'none' color(normal, 020) transparent transparent,
		'line' color(normal, 020) color(normal, 040) color(normal, 000),
		'fill' color(normal, 000) color(normal, 040) color(normal, 040),
	),
	'normal-05': (
		'none' color(normal, 030) transparent transparent,
		'line' color(normal, 030) color(normal, 050) color(normal, 000),
		'fill' color(normal, 030) color(normal, 050) color(normal, 050),
	),
	'normal-10': (
		'none' color(normal, 000) transparent transparent,
		'line' color(normal, 000) color(normal, 000) color(normal, 000),
		'fill' color(normal, 100) color(normal, 000) color(normal, 000),
	),
	'accent-01': (
		'none' color(accent, 010) transparent transparent,
		'line' color(accent, 010) color(accent, 010) color(accent, 000),
		'fill' color(accent, 000) color(accent, 010) color(accent, 010),
	),
	'accent-02': (
		'none' color(accent, 020) transparent transparent,
		'line' color(accent, 020) color(accent, 020) color(accent, 000),
		'fill' color(accent, 000) color(accent, 020) color(accent, 020),
	),
	'accent-03': (
		'none' color(accent, 030) transparent transparent,
		'line' color(accent, 030) color(accent, 030) color(accent, 000),
		'fill' color(accent, 000) color(accent, 030) color(accent, 030),
	),
	'social-01': (
		'none' color(social, 100) transparent transparent,
		'line' color(social, 100) color(social, 100) color(accent, 000),
		'fill' color(accent, 000) color(social, 100) color(social, 100),
	),
	'social-02': (
		'none' color(social, 010) transparent transparent,
		'line' color(social, 010) color(social, 010) color(accent, 000),
		'fill' color(accent, 000) color(social, 010) color(social, 010),
	),
	'social-03': (
		'none' color(social, 010) transparent transparent,
		'line' color(social, 010) color(social, 010) color(accent, 000),
		'fill' color(accent, 000) color(social, 020) color(social, 020),
	),
	'disabled': (
		'none' color(base, 010) transparent transparent,
		'line' color(base, 010) color(base, 010) color(base, 000),
		'fill' color(base, 000) color(base, 010) color(base, 010),
	),
	'primary-010': (
		'none' color(base, 010) transparent transparent,
		'line' color(base, 010) color(base, 010) color(base, 000),
		'fill' color(accent, 010) color(primary, 010) color(primary, 010),
	),
);

/*
 * module-b outline
 *
 * @ $height
 * @ $line-height
 * @ $font-size
 * @ $font-weight
 * @ $border-width
 */  
$btn-module-b-outline: (
	'small-2x'	20rem 12rem 1.25 700 1rem,
	'small-1x'	24rem 16rem 1.25 700 1rem,
	'small'		28rem 16rem 1.25 700 1rem,
	'medium'	32rem 20rem 1.50 700 1rem,
	'large'		40rem 20rem 1.50 700 1rem,
	'large-1x'	48rem 24rem 1.50 700 1rem,
	'large-2x'	56rem 24rem 1.50 700 1rem,
	'large-3x'	64rem 28rem 1.50 700 1rem,
);

$btn-module-b-symbols: (
	'add-circle',
	'chevron-right',
	'check-fill-round',
	'more-data',
	'add-estate',
	'bolt',
	'brand-kakao',
	'brand-naver',
	'brand-apple',
	'check',
	'more-view',
	'chevron-bottom',
	'verified',
	'dawin-user',
	'cardlist',
	'navigation',
	'maplocation',
	'list',
	'chevron-left',
	'chevron-leftdouble',
	'chevron-rightdouble',
	'refresh',
	'dawin-search',
	'chevron-roundright',
	'quick-guide',
	'help-line',
	'tooltip',
	'arrow_drop_up',
	'arrow_drop_down',
	'change',
	'chevron-top',
	'data-estate',
	'more-vert',
	'close',
	'find-dawin',
	'chart',
	'next-circle'
);

@each $symbol-name in $btn-module-b-symbols {
	[class*=symbol-ltr-#{$symbol-name}] span[class*=-text]::before,
	[class*=symbol-rtl-#{$symbol-name}] span[class*=-text]::after {
		mask-image: icon(#{$symbol-name}, color(normal, 000));
	}
}

input.btn.module-b,
button.btn.module-b {
	border-style: solid;
}

// btn module-b reset
.btn.module-b {
	.btn-text {
		font-weight: 700;
	}
}


a.btn.module-a,
a.btn.module-b {
	 text-decoration: none;
}

.btn.module-a,
.btn.module-b {
	// general
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@include font((family: default)...);
	box-sizing: border-box;
	border-style: solid;

	.btn-text {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		white-space: nowrap;
		column-gap: 0.25em;
	}

	// icon
	// 1. 아이콘의 유무는 특정 클래스명으로 한다.
	// 2. 아이콘의 위치는 특정 클래스명으로 한다.
	// 3. 아이콘의 종류는 지정한 아이콘셋(클래스명)으로 한다.
	// 4. 아이콘의 사이즈는 outline별로 달라야 한다.
	//		1) 행간에 맞춘다.
	//		2) 별도의 사이즈를 지정한다.
	// 5. 무조건 중앙에 또는 상단 기준을 택일
	// 6. accent에 따라 컬러를 다르게 한다.

	&[class*=symbol-ltr-] .btn-text::before,
	&[class*=symbol-rtl-] .btn-text::after {
		content: '';
		display: block;
		mask-repeat: no-repeat;
		mask-size: cover;
	}

	// outline
	@each $name, $height, $line-height, $font-size, $font-weight, $border-width in $btn-module-b-outline {
		&.#{$name} {
			padding-right: $height * 0.5;
			padding-left: $height * 0.5;
			height: $height;

			// font-weight: $font-weight;
			line-height: $line-height !important;
			font-size: calc($line-height / $font-size);
			border-width: $border-width;

			&.style-b {
				border-radius: $height * 0.1;
			}

			&.style-c {
				border-radius: $height * 0.5;
			}

			&.style-c.half-first {
				border-radius: $height * 0.5 0 0 $height * 0.5;
				padding-left: 20rem;
				padding-right: 15rem;
			}

			&.style-c.half-second {
				border-radius: 0 $height * 0.5 $height * 0.5 0;
				padding-left: 15rem;
				padding-right: 20rem;
			}

			// icon
			&[class*=symbol-ltr-] .btn-text::before,
			&[class*=symbol-rtl-] .btn-text::after {
				width: $line-height;
				min-width: $line-height;
				height: $line-height;
			}

			
			&[class*=symbol-ltr] {
				// padding-left: ($height * 0.5) - ($line-height * 0.25);
				.btn-text {
					&::before {
						margin-right: $line-height * 0.1;
					}
				}
			}

			&[class*=symbol-rtl] {
				// padding-right: ($height * 0.5) - ($line-height * 0.25);
				.btn-text {
					&::after {
						margin-left: $line-height * 0.1;
					}
				}
			}
		}
	}

	// accent
	@each $accent-name in $btn-module-b-accent {
		&:not(:disabled).#{nth($accent-name, 1)} {
			// @debug "divider offset: #{nth($accent-name, 1)}";
			@each $type-name, $color, $border-color, $background-color in map-get($btn-module-b-accent, nth($accent-name, 1)) {
				&.type-#{$type-name} {
					color: $color;
					border-color: $border-color;
					background-color: $background-color;

					// icon
					&[class*=symbol-ltr-] .btn-text::before,
					&[class*=symbol-rtl-] .btn-text::after {
						background-color: $color;
					}
				}
			}
		}
	}

	&:disabled {
		color: color(base, 000);
		border-color: color(base, 010);
		background-color: color(base, 010);
	}
}
.btn.module-a.posts-moreview {
	padding:0;
}
// .btn.module-a.medium[class*="symbol-rtl"],
// .btn.module-a.style-b {
// 	padding: 0;
// }

/*
 * module-icon outline
 *
 * @ $height
 * @ $icon-size
 */  
 $btn-module-icon: (
	'x-large'		56rem 28rem,
);

// btn module-icon reset
a.btn.module-ico {
	text-decoration: none;
}

.btn.module-ico {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 4rem 8rem 0 rgba(0,0,0,0.3);

	&::before {
		content: '';
		display: block;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: cover;
	}

	.btn-text {
		@include hidden;
	}

	// outline
	@each $name, $height, $icon-size in $btn-module-icon {
		&.#{$name} {
			width: $height;
			height: $height;

			&.style-b {
				border-radius: $height * 0.2;
			}

			&.style-c {
				border-radius: $height * 0.5;
			}

			// icon
			&::before {
				width: $icon-size;
				height: $icon-size;
				flex-shrink: 0;
			}
		}
	}

	// accent
	@each $accent-name in $btn-module-b-accent {
		&.#{nth($accent-name, 1)} {
			// @debug "divider offset: #{nth($accent-name, 1)}";
			@each $type-name, $color, $border-color, $background-color in map-get($btn-module-b-accent, nth($accent-name, 1)) {
				&.type-#{$type-name} {
					color: $color;
					border-color: $border-color;
					background-color: $background-color;
					
					@each $icon-name, $icon in $symbol-list {
						&.symbol-#{$icon-name} {&::before {background-image: icon($icon-name, $color);}}
					}
				}
			}
		}
	}
}

.btn.module-a.style-d {
	padding: 0;
	height: unset;
	width: unset;
	&[class*=symbol-rtl] {
		padding: 0;
	}
	&[class*=symbol-ltr] {
		padding: 0;
	}
}

// button-display
.button-display.module-a {
	display: flex;

	.button-area {
		display: flex;

		.btn.flex {
			flex: 1 1 100%;
			width: 100%;
		}

		.btn:not(.flex) {
			flex-shrink: 0;
		}
	}

	// style-a
	&.style-a {
		&.type-a {
			justify-content: flex-start;

			.button-area {
				flex: 1 1 100%;
			}
		}
	
		&.type-b {
			justify-content: flex-end;

			.button-area {
				flex: 1 1 100%;
			}
		}
	
		&.type-c {
			justify-content: center;
		}

		&.type-d {
			.button-area {
				flex: 1 1 100%;
				width: 100%;
			}			
		}
		&.gradient {
			position: relative;
			&::before {
				display: block;
				position: absolute;
				right: 0;
				bottom: 32rem;
				left: 0;
				height: 44rem;
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 100%);
				content: "";
				z-index: 1;
			}
		}

		.button-area {
			.btn.small-1x {
				&~*,
				&+* {
					margin-left: 4rem;
				}
			}

			.btn.small {
				&~*,
				&+* {
					margin-left: 6rem;
				}
			}

			.btn.medium {
				&~*,
				&+* {
					margin-left: 8rem;
				}
			}
		
			.btn.large {
				&~*,
				&+* {
					margin-left: 8rem;
				}
			}

			.btn.large-1x {
				~*,
				+* {
					margin-left: 8rem;
				}
			}

			.btn.large-2x {
				~*,
				+* {
					margin-left: 8rem;
				}
			}
		
			.btn.x-large {
				&~*,
				&+* {
					margin-left: 10rem;
				}
			}
		}
	}

	// style-b
	&.style-b {

		flex-direction: column;
		gap: 8rem;

		&.type-b {
			justify-content: flex-end;

			.button-area {
				flex: 1 1 100%;
			}
		}
		
		.button-area {
			flex: 1 1 100%;
			width: 100%;

			&.style-b {
				flex-direction: column;
				gap: 8rem;
			}
		}
	}
}


