@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-board {
	#wrap {
		display: flex;
		flex-direction: column;
	}
	
	.page-body {
		#local {
			.local-util {
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}
	
	.content-body {
		.section.module-a {
			.section-wrap {
				display: flex;
				flex-direction: column;
				row-gap: 12rem;
				
				.section-head {
					.section-subject {
						margin: 0;
						@include font((outline: body, size: small-1x, weight: bold)...);
						color: color(normal, 100);
		
						.section-name {
							display: block;
						}
					}
				}
			}
		
			&:not([class*=type-]) {
				.section-wrap {
					.section-head {
						@include hidden;
					}
				}
			}
		}
	}
	
	@include respond-below(tablet) {
		.page-body {
			display: flex;
			flex-direction: column;
	
			#local {
				display: flex;
				flex-direction: column;
				
				.local-head {
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
	
						+.local-control {
							order: 3;
						}
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
	
							.btn.share::before {
								background-color: color(base, 000);
								background-image: icon(share, color(base, 100));
							}
	
							.btn.search {
								&::before {
									background-color: color(base, 000);
									background-image: icon(dawin-search, color(base, 100));
								}
								&.selected::after {
									content: "";
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									width: 4rem;
									height: 4rem;
									border-radius: 2rem;
									background-color: color(accent, 010);
								}
							}
						}
					}
				}
			
				.local-body {
					padding: 0 20rem;
	
					#content {
						margin: 0 0 80rem 0;
					}
				}
	
				.local-util {
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
		}
	
		#page.page-intro {
			flex: 1 1 100%;
			padding: 64rem 0 0 0;
			overflow: hidden;
	
			.page-body {
				flex: 1 1 100%;
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
	
					.local-head {
						position: absolute;
						z-index: 1;
						top: 0;
						right: 0;
						left: 0;
						background-color: color(base, 000);

						.local-control {
							display: none;
						}
					}
					.local-body {
						@include most-outer-selector('.mobile') {
							@include overflow-scroll-system(dark, medium, y);
						}
				
						@include most-outer-selector('.laptop') {
							@include overflow-scroll-custom(dark, medium, y);
						}
					}
				}
			}
		}
	
		#page.page-front {
			.page-head {
				display: none;
			}
	
			.page-body {
				overflow: hidden;
				flex: 100%;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
					
					.local-head {
						position: relative;
						z-index: 10;
						background-color: color(base, 000);
					}
				}
	
				.local-body {
					flex: 1 1 100%;

					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
				}
			}
	
		}
		
		#footer,
		.page-foot {
			display: none;
		}
	}
	
	
	@include respond-above(tablet) {
		#page {
			margin: 56rem 0 0 0;
			
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, medium, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, medium, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
	
		}
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				padding: 0 0 80rem 0;
	
				.local-head {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 48rem 0;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large, weight: medium)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0 40rem;
				}
			}
		}
	}
	
	
	.local-body {
		#content {	
			.content-body {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 640rem;
			}
		}
	}
	
	
	.posts-display.module-b {
		a.posts-name {
			text-decoration: initial;
		}	
	}
	
	.posts-display.module-b {
		a {
			text-decoration: initial;
		}
		
	
		&.style-a {
			.posts-list {
				.posts-item {
					padding: 20rem 0;
					border-bottom: 1rem solid color(base, 005);
				}
			}
	
			@include respond-below(tablet) {
				position: relative;
				left: calc(50% - 50vw);
				width: 100vw;
		
				.posts-list {	
					.posts-item {
						padding: 20rem;
					}
				}
			}
		}
	
		.data-sort {
			.btn {
				display: initial;
				width: initial;
				text-align: initial;
				white-space: initial;
				vertical-align: initial;
				cursor: initial;
				box-sizing: initial;
				margin-bottom: initial;
				padding: initial;
				font-size: initial;
				font-weight: initial;
				line-height: initial;
		
				.btn-text {
					height: initial;
					line-height: initial;
				}
			}
			
			@include respond-below(tablet) {
				position: relative;
				left: calc(50% - 50vw);
				width: 100vw;
				border-bottom: 1rem solid color(normal, 040);
			}
			
			@include respond-above(tablet) {
				border: 1rem solid color(normal, 040);
				border-radius: 8rem;
			}
		
			.data-date {
				display: flex;
				justify-content: center;
				column-gap: 8rem;
				padding: 16rem 0;
			
				.current-date {
					display: block;
					position: relative;
	
					@include font((outline: body, size: medium, weight: medium)...);
					text-align: center;
		
					// &::before {
					// 	content: attr(data-selected-date);
					// 	display: block;
					// 	@include font((outline: body, size: medium, weight: medium)...);
					// 	text-align: center;
					// }
		
					// .select-date {
					// 	position: absolute;
					// 	z-index: 1;
					// 	top: 0;
					// 	right: 0;
					// 	bottom: 0;
					// 	left: 0;
					// 	opacity: 0;
					// }
				}
			
				.btn {
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					margin: 0;
					padding: 0;
					width: 32rem;
					height: 32rem;
					overflow: hidden;
					@include font((outline: body, size: small-3x)...);
					cursor: pointer;
	
					&.disabled {
						opacity: 0.25;
						pointer-events: none;
					}
		
					// .btn-text {
					// 	display: flex;
					// 	justify-content: center;
					// 	align-items: center;
					// 	position: absolute;
					// 	top: 0;
					// 	right: 0;
					// 	bottom: 0;
					// 	left: 0;
					// }
			
					// &::after {
					// 	content: '';
					// 	display: block;
					// 	width: 32rem;
					// 	height: 32rem;
					// 	background-color: color(normal, 000);
					// 	background-repeat: no-repeat;
					// 	background-position: center;
					// 	background-size: cover;
					// }
		
					.btn-text {
						display: flex;
						justify-content: center;
						align-items: center;
		
					}
			
					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 32rem;
						height: 32rem;
						background-color: color(normal, 000);
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
					}
					
					&.prev::after {
						background-image: icon(navigate-prev, color(normal, 020));
					}
					&.next::after {
						background-image: icon(navigate-next, color(normal, 020));
					}
				}
			}
		
			.data-filter {
				padding: 12rem 20rem;
				border-top: 1rem solid color(base, 005);
		
				.submit-form {
					legend {
						@include hidden;
					}
		
					.form-head {
						@include hidden;
		
					}
		
					.form-body {
						.option-list {
							display: flex;
							column-gap: 4rem;
						}
					}
				}
			}
		}
	
		.posts-list {
			margin: 0;
			padding: 0;
			list-style: none;
	
			.posts-item {
				.posts-wrap {
					display: flex;
					column-gap: 16rem;
					
					.posts-figure {
						flex-shrink: 0;
						width: 100rem;
						height: 100rem;
						
						.posts-cover {
							display: block;
							border-radius: 8rem;
							overflow: hidden;
							width: 100%;
							height: 100%;
							
							img {
								display: block;
								object-fit: cover;
								width: 100%;
								height: 100%;
							}
						}
					}
					
					.posts-inform {
						flex: 1 1 100%;
						display: flex;
						flex-direction: column;
						row-gap: 8rem;
						
						.posts-head {
							order: 2;
							.posts-subject {
								margin: 0;
		
								.posts-name {
									@include ellipsis(3);
									@include font((outline: body, size: small-1x)...);
									color: color(normal, 010);
	
									&:not(:hover) {
										text-decoration: none;
									}
	
									&:hover {
										text-decoration: underline;
									}
								}
							}
						}
	
						.posts-data {
							order: 1;
			
							.data-list {
								display: flex;
								justify-content: space-between;
								
								
								.data-item {
									.head {
										@include hidden;
									}
	
									.body {
										display: block;
										@include font((outline: body, size: small-2x)...);
									}
	
									&.type {
										.body {
											@include font((weight: bold)...);
											color: color(accent, 020);
										}
									}
	
									&.media {
										.body {
											color: color(normal, 030);
	
											a {
												color: inherit;
	
												&:not(:hover) {
													text-decoration: none;
												}
	
												&:hover {
													text-decoration: underline;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	
	
	// (팝업으로 변경하여 임시 추가)
	.content-body {
		.posts-read.module-a {
			// laptop
			@include respond-above(tablet) {
				border-top: 1rem solid color(normal, 010);
			}
		}
	}
	
	@include most-outer-selector('.active-posts-fab') {
		.posts-func {
			.dropdown {
				.dropdown-wrap {
					.dropdown-head {
						.dropdown-subject {		
							.dropdown-name {
								&::before {
									transform: rotate(180deg);
								}
							}
						}
					}
					.dropdown-body {
						.navi-list {
							.navi-item {
								animation-name: posts-func-fab;
								animation-duration: 0.25s;
								animation-fill-mode: forwards;
								animation-timing-function: cubic-bezier(.2,0,0,1);
				
								&:nth-child(1) {
									animation-delay: 0s;
								}
				
								&:nth-child(2) {
									animation-delay: 0.025s;
								}
				
								&:nth-child(3) {
									animation-delay: 0.05s;
								}
				
								&:nth-child(4) {
									animation-delay: 0.075s;
								}
							}
						}
					}
				}
			}
		}
	}
	// html {
	// 	body {
	// 		&::before {
	// 			content: '';
	// 			position: fixed;
	// 			z-index: 30;
	// 			top: 0;
	// 			right: 0;
	// 			bottom: 0;
	// 			left: 0;
	// 			pointer-events: none;
	// 			visibility: hidden;
	// 			opacity: 0;
	// 			background-color: rgba(0,0,0,0.4);
	// 			transition: visibility 0.25s ease, opacity 0.25s ease;
	// 		}
	// 	}
	
	// 	&.active-posts-fab {
	// 		body {
	// 			&::before {
	// 				pointer-events: auto;
	// 				visibility: visible;
	// 				opacity: 1;
	// 			}
	// 		}
	// 	}
	// }
	
	.posts-func {
		position: fixed;
		z-index: 40;
		right: 16rem;
		bottom: 84rem;
		filter: drop-shadow(0 2rem 4rem rgba(0,0,0,0.25));
	
		@include respond-above(tablet) {
			right: 48rem;
			bottom: 48rem;
		}
	
		.dropdown {
			position: relative;
			pointer-events: auto;
	
			.dropdown-head {
				position: relative;
				z-index: 1;
				.dropdown-subject {
					margin: 0;
	
					.dropdown-name {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 64rem;
						height: 64rem;
						@include font((outline: body, size: small-2x)...);
						color: color(normal, 000);
						text-decoration: none;
						
						&::before {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							border-radius: 50%;
							transform: rotate(45deg);
							background-repeat: no-repeat;
							background-position: center;
							transition: transform 0.25s ease;
							background-size: 32rem;
							background-image: icon(close, color(normal, 000));
							background-color: color(accent, 010);
						}
					}
				}
			}
	
			.dropdown-body {
				position: absolute;
				bottom: 100%;
				right: 0;
				width: 64rem;
				outline: 0 none;
	
				.navi-list {
					display: flex;
					flex-direction: column-reverse;
					align-items: flex-end;
					gap: 8rem;
					padding: 8rem;
				
					.navi-item {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						position: relative;
						gap: 8rem;
	
						visibility: hidden;
						opacity: 0;
						transform: translate(0, 48rem);
								
						&::after {
							content: '';
							flex-shrink: 0;
							display: block;
							width: 48rem;
							height: 48rem;
							border-radius: 50%;
							background-repeat: no-repeat;
							background-position: center;
							background-size: 24rem;
							background-color: color(normal, 010);
						}
	
						.navi-text {
							flex-shrink: 0;
							display: flex;
							align-items: center;
							height: 32rem;
							padding: 0 20rem 0 12rem;
							@include font((outline: body, size: small-2x, weight: medium)...);
							color: color(normal, 010);
							text-decoration: none;
							border-top-left-radius: 16rem;
							border-bottom-left-radius: 16rem;
							background-repeat: no-repeat;
							background-size: cover;
							background-position: right 0;
							// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 240 32'%3E %3Cpath fill='rgba(0,0,0,1)' d='M0,0H218a16,16,0,0,1,15.05,10.59,5.82,5.82,0,0,0,2.11,2.84l3.14,2.16a.5.5,0,0,1,0,.82l-3.14,2.16a5.82,5.82,0,0,0-2.11,2.84A16,16,0,0,1,218,32H0Z'/%3E%3C/svg%3E");
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 240 32'%3E %3Cpath fill='rgba(255,255,255,1)' d='M0,0H218a16,16,0,0,1,15.05,10.59,5.82,5.82,0,0,0,2.11,2.84l3.14,2.16a.5.5,0,0,1,0,.82l-3.14,2.16a5.82,5.82,0,0,0-2.11,2.84A16,16,0,0,1,218,32H0Z'/%3E%3C/svg%3E");
	
							&::after {
								content: '';
								display: block;
								position: absolute;
								top: 0;
								right: 0;
								width: 48rem;
								height: 48rem;
								background-color: transparent;
							}
						}
	
						&:nth-child(1) {
							&::after {
								background-image: icon(add-home, color(normal, 000));
							}
						}
	
						&:nth-child(2) {
							&::after {
								background-image: icon(home-work, color(normal, 000));
							}
						}
	
						&:nth-child(3) {
							&::after {
								background-image: icon(complex, color(normal, 000));
							}
						}
	
						&:nth-child(4) {
							&::after {
								// background-image: icon(inventory, color(normal, 000));
								background-image: icon(talk, color(normal, 000));
							}
						}
					}
				}
			}
		}
	}
	
	@keyframes posts-func-fab {
		0% {
			visibility: hidden;
			opacity: 0;
			transform: translate(0, 48rem);
		}
	
		100% {
			visibility: visible;
			opacity: 1;
			transform: translate(0, 0);
		}
	}

	.section {
		.subsection.module-a {
			.subsection-wrap {
				.subsecton-head {
					.subsection-subject {
						@include font((outline: body, size: small, weight: bold)...);	
						color: color(base, 000);

					}
				}
				.subsection-body {
					.para {
						@include font((outline: body, size: small, weight: regular)...);	
					}
				}
			}
		}
		&.brokerinfo {
			.section-head {
				.section-subject {
					.section-name {
						@include font((outline: body, size: medium, weight: bold)...);	
					}
				}				
			}
		}
		&.activitydetails {
			.section-head {
				.section-subject {
					.section-name {
						@include font((outline: body, size: medium, weight: bold)...);	
					}
				}				
			}
			.section-body {
				gap: 48rem;
				.subsection-wrap {
					.subsection-head {
						.subsection-subject {
							color: color(normal, 010);
							gap: 4rem;
							margin: 0;
							@include font((outline: body, size: small, weight: bold)...);	
							display: flex;
						}
					}
				}
				.goods-display {
					.goods-list {
						flex-wrap: wrap;
						gap: 20rem;
						justify-content: flex-start;
						.goods-item {
							width: calc((100% - 46rem)/3);
							min-width: 192rem;
							flex-shrink: 0;
							border: 1rem solid color(base, 010);
							border-radius: 16rem;
							background-color: color(base, 000);
							.goods-wrap {
								display: flex;
								flex-direction: column;
								gap: 16rem;
								padding: 24rem;
								.goods-figure {
									width: 100%;
									display: none;
									.goods-thumbnail {
										width:100%;
										img {
											width:100%;
										}
									}
								}
								.goods-inform {
									.goods-head {
										.goods-subject {
											margin-bottom: 12rem;
											@include font((outline: body, size: small, weight: bold)...);
										}
									}
									.goods-price {
										justify-content: flex-start;
										margin-top: 12rem;
										margin-bottom: 12rem;
										.data-list {
											display: flex;
											flex-direction: column;
											gap: 4rem;
											.data-item {
												.body {
													@include font((outline: body, size: small-1x, weight: regular)...);
												}
											}
										}
									}
									.goods-info {
										display: none;
										.data-list {
											.data-item {
												.body {
													@include font((outline: body, size: small-2x, weight: regular)...);
													font-size: 14rem;
												}
											}
										}
									}
								}
							}
							+.button-display {
								align-items: center;
								flex: 100;
							}
							&:hover {
								border-color: color(normal, 030)
							}
						}					
					}				
				}
			}
			@include respond-below(tablet) {
				.section-body {
					.goods-display {
						.goods-list {	
							flex-wrap: nowrap;
							gap: 16rem;
							justify-content: flex-start;
							.goods-item {
								+.button-display {
									.btn.module-a .btn-text {
										flex-direction: column;
									}
								}
							}
						}
					}
				}				
			}
		}
	}
}