
@import url('../assets_prev/mobile/scss/m_reset.scss') only screen and (max-width: 767px);
@import url('../assets_prev/web/scss/reset.scss') only screen and (min-width: 768px);

/* font */
// @import url('../assets_prev/assets/scss/spoqa.scss');

/* mobile */
// @import url('../assets_prev/mobile/scss/m_base.scss') only screen and (max-width: 767px);
@import url('../assets_prev/mobile/scss/m_common.scss') only screen and (max-width: 767px);
@import url('../assets_prev/mobile/scss/m_component.scss') only screen and (max-width: 767px);
@import url('../assets_prev/mobile/scss/m_main.scss') only screen and (max-width: 767px);
@import url('../assets_prev/mobile/scss/m_sub.scss') only screen and (max-width: 767px);
@import url('../assets_prev/mobile/scss/m_analysis.scss') only screen and (max-width: 767px);

/* web */
@import url('../assets_prev/web/scss/component.scss') only screen and (min-width: 768px);
@import url('../assets_prev/web/scss/common.scss') only screen and (min-width: 768px); // 레이아웃 (footer)
@import url('../assets_prev/web/scss/sub.scss') only screen and (min-width: 768px);
@import url('../assets_prev/web/scss/pop.scss') only screen and (min-width: 768px);
@import url('../assets_prev/web/scss/analysis.scss') only screen and (min-width: 768px);

/* both */
@import url('../assets_prev/web/scss/bk_common.scss');
@import url('../assets_prev/web/scss/bk_sub.scss');
@import url('../assets_prev/web/scss/bk_new.scss');

@import url('../assets_prev/mobile/scss/new.scss'); // 메인
@import url('../assets_prev/mobile/scss/chat.scss');
@import url('../assets_prev/mobile/scss/community.scss');

@import url('../assets_prev/assets/scss/new_220218.scss');
@import url('../assets_prev/assets/scss/new.sub.scss'); // 레이아웃 (header)s
@import url('../assets_prev/assets/scss/new.bksub.scss');

// @import url('../assets/custom/module.global.scss');
@import url('../resources/design/v2/custom/module.global.scss');


// imageSlide.css


// root-front
@import url('./scss/module.global.scss');
// @import url('./scss/bui.layout.front.scss');
@import url('./scss/bui.page.snippet.scss');
@import url('./scss/layout.front.scss');
@import url('./scss/page.map.scss');
@import url('./scss/page.news.scss');
@import url('./scss/page.board.scss');
@import url('./scss/page.mypage.scss');
@import url('./scss/page.selling.scss');
@import url('./scss/page.blank.scss');
@import url('./scss/page.signupbroker.scss');
@import url('./scss/page.tsreport.scss');
@import url('./scss/page.login.scss');
@import url('./scss/page.brokermypage.scss');
@import url('./scss/page.system.scss');
@import url('./scss/page.fronthome.scss');
@import url('./scss/page.brokerprofile.scss');
@import url('./scss/page.chat.scss');
@import url('./scss/page.promotion.scss');



@media (prefers-color-scheme: dark) {
	:root {
	  background: #ffffff;
	}
}