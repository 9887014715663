@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */

/*.find.allmap .logo {left:7px}*/
/*.find.allmap .btn-right {right:13px;}*/
.find.allmap .map-btn-group-left .logo_link a {display:inline-block;text-indent:-9999px;background:url(../images/logo02.svg) no-repeat center;width:50px; height:55px;margin-left:-10px}
.find.allmap .map-btn-group-right .btn-menu {margin-right:-13px}
/*.container.analysis {z-index:202;padding:0;top:55px}*/
.analysis .map-btn-group-left, .analysis .map-btn-group-right, .analysis .mapview_tag {top:20px;z-index:203}
.analysis_lyr {z-index:100;max-height:500px;overflow-y:inherit}
.analysis_lyr .btn-close-small {position:absolute;right:15px;top:15px;z-index:1}
.analysis_lyr h4 {font-size:20px;display:inline-block;font-weight:500}
.analysis_lyr h4 + p {margin-top:5px;font-size:14px;}
.analysis_lyr .add_text {display:inline-block;margin-left:10px}
.analysis_lyr .menu-group {margin-top:15px}
.analysis_lyr .menu-group h5 {font-size:15px;font-weight:400;margin-bottom:8px}
.analysis_lyr .menu-group h5:before {display:inline-block;content:'';width:5px;height:5px;background:#7a1d05;margin-right:5px;margin-bottom:2px}
.analysis_lyr .mn_analysis {/*display:flex;flex-wrap:wrap;align-items:stretch;align-content:stretch;*/display:block;margin:0 -3px 0 -3px }
.analysis_lyr .mn_analysis:after {content:'';display:block;clear:both}
.analysis_lyr .mn_analysis>li {position:relative;/*flex:0 0 33.333333%;*/float:left;width:calc(100% / 3);box-sizing:border-box;padding: 0 3px 8px 3px;text-align:center;font-size:15px;height:50px;background-color: #fff;}
.analysis_lyr .mn_analysis>li>a {position:relative;display:block; width:100%;background-color:#f1f1f1;color:#333;line-height:42px;border-radius:6px;letter-spacing:-1.6px}
.analysis_lyr .mn_analysis>li>a.on {background:#7686eb;color:#fff}
.analysis .btn_analy.on {background:#3b44a7;border:1px solid #8f1d00;border-color:#2b3498}
.analysis_lyr .mn_analysis>li>a.prep {color:#CCCCCC;background:#f9f9f9}
.analysis_lyr .mn_analysis>li>a small {color:#ccc;vertical-align:middle;margin-top:-3px}

.visual-info {display:block;background-color:rgba(194, 40, 0, 0.45);position:absolute;left:50%;top:50%;transform:translate(-50%, -57%);width:66px;height:66px;border-radius:100px;z-index:11}
.visual-info.decrease {background-color:rgba(36, 81, 196, 0.45);}
.pin-type05 {width:54px;height:58px}
.pin-type05 .info01 {width:54px;height:52px;text-align:center;color:#fff;line-height:31px;font-size:14px;position:relative;background:url("../images/new/ico_analy.png") no-repeat;background-size:contain;}
.pin-type05 .info01 .info02 {line-height:1.2}
.pin-type05 .info01 .a_count {position:absolute;bottom:5px;left:50%;transform:translatex(-50%);z-index:12}
.pin-type05 .info01 .summ_graph {position:relative;z-index:12}
.analysis .cluster .info01 span {display:block;font-size:13px;position:relative;z-index:12}
.analysis .cluster .info01 span.summ_graph {margin-top:-2px;}
.analysis .cluster .info01 .a_area {font-size:12px;padding:0 0 2px}
.pin-type05.on .info01 {background:url("../images/new/ico_analy_on.png") no-repeat;background-size:contain;color:#222;font-weight:600}
/*.analysis .pin-type01.on .info01, .analysis .pin-type02.on .info01, .analysis .pin-type03.on .info01, .analysis .pin-type04.on .info01 {background:url("../images/new/ico_price_on.png") no-repeat;background-size:contain;color:#222;font-weight:600}*/
/*.pin-type05.on .visual-info {z-index:-1}*/
.cluster .visual-info {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.real-pin.on .visual-info {z-index:-1}
.summ_graph {display:inline-block;width:40px;height:auto;}
.summ_graph img {object-fit:contain;width:100%;height:100%;}
.real-pin.pin-type07 {width:45px;height:48px;}
.pin-type07 .info01 {background:url("../images/ico_price_sgrey.png") no-repeat;background-size:contain;color:#222;font-weight:600;width:40px;height:40px;color:#fff;position:relative}
.pin-type07 .info01 .a_count {position:absolute;top:16px;left:44%;transform:translatey(-50%)}


.analy_ftgroup {margin-top:20px}
.slider-wrap {position:relative;margin:0px 15px;}
.slider {display:inline-block;position:relative;box-sizing:border-box;width:100%;  height:23px;vertical-align:middle;}
.slider .track {position:relative;top:50%;margin-top: -4.5px;}
.slider .track span {display:inline-block;width:calc((100% - 6px) / 4);background-color:#d8d8d8;height:5px;float:left;margin-right:2px;}
.slider .track span:last-child {margin-right:0;/*border-bottom-right-radius:11px;border-top-right-radius:11px;*/}
/*.slider .track span:first-child {border-bottom-left-radius:11px;border-top-left-radius:11px;}*/
.slider .thumb-group {position:absolute;width:100%;height:100%;top:0;}
.slider .thumb-group .thumb {z-index:3;position:absolute;box-sizing:border-box;top:0;left:0;height:20px;width:20px;vertical-align:middle;-webkit-border-radius: 12.5px;-moz-border-radius: 12.5px;-ms-border-radius: 12.5px;-o-border-radius: 12.5px;border-radius: 12.5px;margin-left:-9px;background-color:#c22800;}
.slider.range .thumb-bar {position:absolute;box-sizing:border-box;top:40%;left:0;transform:translatey(-50%);height:5px;background-color:#c22800;z-index:1;}
.slider-wrap .slider-range {position:relative;height:20px;padding:5px 0;}
.slider-wrap .slider-range li {position:absolute;font-size:12px;display:block;white-space:nowrap;text-align:center;transform:translateX(-50%);color:#333;z-index:2}
.slider-wrap .slider-range li:before {background-color:#fff;width:2px;height:9px;line-height:1;content:'';position:absolute;display:block;top:-22px;left:50%;}
.slider-wrap .slider-range li:first-child:before, .slider-wrap .slider-range li:last-child:before {display:none}
.slider-wrap .slider-range li:nth-child(2) {left:25%;}
.slider-wrap .slider-range li:nth-child(3) {left:50%;}
.slider-wrap .slider-range li:nth-child(4) {left:75%;}
.slider-wrap .slider-range li:nth-child(5) {left:100%;}
.slider-wrap .slider-range.quart li:nth-child(2) {left:33.333333%;}
.slider-wrap .slider-range.quart li:nth-child(3) {left:66.666666%;}
.slider-wrap .slider-range.quart li:nth-child(4) {left:100%;}
.slider .thumb-group.right-side .thumb {width:4px;right:0;left:inherit}
.slider .track>span:last-child:after {display:inline-block;content:'';width:4px;height:16px;background:#c22800;position:absolute;right:0;top:-5px;}

.analysis_lyr .tab_style02 {position:absolute;right:40px;top:2px}
.analysis_lyr .tab_style02 a {font-size:13px;padding-bottom:2px;padding-top:2px}
.analysis_lyr .tab_style02 ul li a.on {border-color:#e44332;color:#e44332}
/*.analysis_lyr .share {position:fixed;right:10px;bottom:250px;width:45px;}*/
/*.analysis_lyr .share li {margin-bottom:6px}*/
/*.analysis_lyr .share li:last-child {margin-bottom:0}*/

.analysis_lyr .btn-hidden {position:absolute;top:-23px;left:50%;transform:translatex(-50%)}
.btn-open {position:absolute;bottom:0;left:50%;transform:translatex(-50%)}

.lyrpop_analy {display:table;width:90%;z-index:1004;height:100%;background:none;box-shadow:none;}
.lyrpop_analy .btn-close {position:absolute;right:0;top:0}
.lyrpop_wrap {width:100%;min-height:300px;max-height:96%;background:#fff;vertical-align:middle;position:absolute;top:50%;transform:translatey(-50%);overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.lyrpop_analy .lyr_inner {padding:22px 25px 30px;}
.lyrpop_analy .content {height:100%;}
.lyrpop_analy .content p {font-size:15px;line-height:1.3;font-weight:400}
.lyrpop_analy .content p img {width:100%;height:auto;display:block;margin-bottom:20px;margin-top:10px}
.lyrpop_analy .content p.box {padding:10px 12px;;background:#e5e5e5;margin-top:5px;margin-bottom:13px;font-size:14px}
.lyrpop_analy .content p.p02 {font-size:14px;margin-bottom:10px}
.lyrpop_analy .content p.p03 {font-size:12px;}
.real-pin .info04 + .info05 {top:-22px}
.lyr_box p {font-size:13px}

/*학군*/
.school-pin {width:48px;height:44px;}
.school-pin.highschool {background:url("../images/new/ico_analy_school.png") no-repeat;background-size:contain;}
.school-pin.highschool.on {background:url("../images/new/ico_analy_school_on.png") no-repeat;background-size:contain;}
.school-pin.middleschool {background:url("../images/new/ico_analy_school02.png") no-repeat;background-size:contain;}
.school-pin.middleschool.on {background:url("../images/new/ico_analy_school02_on.png") no-repeat;background-size:contain;}
.school-pin .info01 {width:48px;height:44px;line-height:3.7}
.school-pin.highschool.on .info01 {color:#5827d8;font-weight:600}
.school-pin.middleschool.on .info01 {color:#3861eb;font-weight:600}
.school-pin .info02 {transform:translatex(-50%)}
.academy-pin {width:10px;height:10px;border-radius:20px;}
.academy-pin {z-index:98}
.academy-pin.type01 {background:#ff0099;border:1px solid #222;z-index:99}
.academy-pin.type02 {background:#fff100;border:1px solid #222;z-index:99}
.analysis_lyr .analy_ftgroup .mn_analysis>li {flex:0 0 33.333333333%}
.analysis_lyr .analy_ftgroup .mn_analysis>li>a {background:#eeeeee;color:#222}
.analysis_lyr .analy_ftgroup .mn_analysis>li>a.on {background:#9e1500;color:#fff}
.legend-wrap {padding:10px 25px 10px 10px;background:#fff;border:1px solid #d2d2d2;position:relative}
.legend-wrap .btn-close-xs {position:absolute;right:8px;top:8px;}
.legend-wrap .legend .academy-pin {display:inline-block;vertical-align:middle}
.legend-wrap .legend span {font-size:13px;display:inline-block;vertical-align:middle;margin-left:3px;}
.academy-pin .visual-info {background:rgb(255, 0, 114, 0.4);border:1px solid rgb(0, 0, 0, 0.7);}
.visual-name {display:block;font-size:12px;background:rgb(0,0,0,0.7);border:1px solid #000;color:#fff;position:absolute;left:50%;transform:translateX(-50%);top:-30px;border-radius:3px;padding:2px;width:80px;text-align:center;}

/*재건축사업성*/
.analysis_lyr .analy_ftgroup .mn_recons>li>a {font-size:14px}
.analysis_lyr .analy_ftgroup .mn_recons>li>a.on {background:#b53210;color:#fff}

/*공시지가*/
.analysis .real-pin .info01 .a_price {position:absolute;top:44%;left:50%;transform:translate(-50%, -60%);z-index:12;width:55px;text-align:center;}

/*아파트단지비교*/
/*.find.allmap .container.analysis {padding-top:0}*/
.find.allmap .header {/*z-index:203;*/background:none;z-index:unset;}
.find.allmap .danjicompare .header, .danjicompare .header {background:#fff;z-index:2}
.find.allmap .map {z-index:0}
.find.allmap .analysis .map-btn-group-left, .find.allmap .analysis .map-btn-group-right {top:0;z-index:1}
.find.allmap .around_lst {top:55px}
/*.find.allmap .btn-map-control + .around_lst {top:100px}*/
.sch-container .box-list.comparelst {padding-bottom:50px;border-top:1px solid #ccc;}
.comparelst li {text-align:left;padding:4px 0 4px;}
.comparelst .sch_word {display:inline-block;width:auto;font-size:16px;vertical-align:middle;padding:0;}
.seleclst {margin:-5px;display:block;overflow:hidden}
.seleclst:after {display:inline-block;content:'';clear:both}
.seleclst li {display:inline-block;float:left;margin:4px 20px 4px 3px;border:1px solid #d0d2d6;border-radius:30px;position:relative;background:#fff;box-sizing:border-box;line-height:1.5}
.seleclst li span {display:block;padding:3px 10px;font-size:15px}
.seleclst li.selected {background:#c43713;color:#fff;border:1px solid #c43713}
.seleclst li .del {position:absolute;right:-17px;top:7px;left:inherit;display:inline-block;width:12px;height:12px;background:url("../images/btn_del02.png") no-repeat;background-size:contain;text-indent:-9999px;transform:none;z-index:unset}
/*.seleclst li.selected .del {position:absolute;right:12px;top:7px;left:inherit;display:inline-block;width:12px;height:12px;background:url("../images/btn_del04.png") no-repeat;background-size:contain;text-indent:-9999px;transform:none}*/
.analysis_lyr .btn_group {margin-top:25px;text-align:center;padding-bottom:10px}
.analysis_lyr .btn_group a {display:inline-block;font-size:17px;padding:10px 30px;color:#888;line-height:20px;height:42px;box-sizing:border-box;vertical-align:middle;box-shadow: 0 2px 2px rgba(0,0,0,.08), -1px 1px 1px rgba(0,0,0,.04), 1px 1px 1px rgba(0,0,0,.04);border-radius:40px;}
.analysis_lyr .btn_group .btn-delete {border:1px solid #d3d3d3;background:#fff;margin-right:5px}
.analysis_lyr .btn_group .btn-compare {background:#7a1d05;border:1px solid #7a1d05;color:#fff;}
.danjicompare .cont_wrap {height:100%}
.danjicompare .panel-box {margin-top:0;padding-top:55px;}
.danjicompare .seleclst {padding:15px;max-height:144px;overflow:scroll;box-sizing:border-box;margin:0}
.danjicompare .seleclst + .lyr_box {padding:0 15px 20px;}
/*.danjicompare .seleclst li {margin:3px 2px}*/
.danjicompare .itmbox .cont {height:350px}
.danjicompare .itmbox .tit {font-size:17px;font-weight:500;}
.danjicompare .itmbox .cont img {width:100%;height:350px;object-fit:contain}
.danjicompare .analy_ftgroup {padding:0 15px 20px}
.danjicompare .analy_ftgroup .tit {font-size:17px;font-weight:500;margin-bottom:15px;}
.itmbox-term {margin-top:-20px}
.itmbox-term + .itmbox-term.share {margin-top:10px;}
.itmbox .moreview {display:block;/*border-top:1px solid #e9e9e9;*/width:100%;text-align:center;margin:10px -20px;padding:0 20px}
.itmbox .moreview span {display:inline-block;padding:5px 30px 5px 20px;border:1px solid #d4d4d4;border-radius:40px;font-size:14px;}
.itmbox .moreview span.open {background: url(../images/ico_arr06.png) no-repeat 80% center;}
.itmbox .moreview span.close {background: url(../images/ico_arr07.png) no-repeat 80% center;}
.cont_more {/*border-top:1px solid #e9e9e9;*/padding-top:15px;margin:0 -20px;}
.cont_more .tit {padding:0 20px 10px 20px;}
.cont_more table.list thead td {font-weight:400;padding:5px 2px}
.cont_more table.list tr {background:#fafafa;}
.cont_more table.list tbody tr:nth-child(2n) {background:#fafafa}
.cont_more table.list tbody tr:nth-child(odd) {background:#fff}
.cont_more .list, .cont_more .list td {border:none}
.cont_more table.list tbody td {padding:3px 2px}
.cont_more table.list tbody td:nth-child(2) {text-align:left;color:#3565c9;position:relative}
.cont_more table.list tbody td:nth-child(2) .btn_link {display:block;width:14px;height:14px;background:url(../images/btn_link.png) no-repeat;background-size:contain;position:absolute;right:-15px;top:50%;transform:translatey(-50%);z-index:1}
.cont_more table.list tbody td:nth-child(n+3) {font-weight:400}
.cont_more table.list col.selected tbody td {background:#e1e1e1}
.cont_more table.list + .m_exp {margin:10px 20px 0;color:#BCBCBC;font-size:14px}
.cont_more .add-text {margin:0 20px 10px 20px;color:#777;font-size:14px}
.expbox li {padding:15px 25px;margin:0 -25px}
.expbox li:nth-child(2n+1) {background:#f8f8f8}
.lyrpop_analy .graph_area {padding:10px 20px 20px;height:250px;text-align:center;box-sizing:border-box}
.lyrpop_analy .graph_area img {object-fit:contain;width:100%;height:190px;margin:0}
.lyrpop_analy .vGraph{padding:20px 0;}
.lyrpop_analy .vGraph ul{display:flex;flex:1;justify-content:center;margin:0;padding:0;height:170px;border:0;font-size:11px;}
.lyrpop_analy .vGraph li{float:inherit;display:inline-block;width:40px;height:100%;position:relative;text-align:center;white-space:nowrap;padding:0 5%;box-sizing:border-box;margin:0 20px}
.lyrpop_analy .vGraph .gTerm{/*position:relative;display:inline-block;width:100%;height:60px;line-height:60px; margin:0 -100% -20px 0;padding:110px 0 0 0; vertical-align:bottom;*/position:absolute;bottom:0;left:50%;transform:translatex(-50%);color:#767676;font-weight:bold;padding:0;margin:0}
.lyrpop_analy .vGraph .gBar{position:absolute;bottom:0;left:0;width:100%;display:inline-block; width:100%;margin:-1px 0 0 0;background:#c22800;vertical-align:bottom;}
.lyrpop_analy .vGraph li:nth-child(1) .gBar {background:#ff4f59}
.lyrpop_analy .vGraph li:nth-child(2) .gBar {background:#9a6dd5}
.lyrpop_analy .vGraph li:nth-child(3) .gBar {background:#a6df7d}
.lyrpop_analy .vGraph li:nth-child(4) .gBar {background:#33b3ed}
.lyrpop_analy .vGraph li:nth-child(5) .gBar {background:#f4a76d}
.lyrpop_analy .vGraph li:nth-child(1) .gBar span {color:#ff4f59}
.lyrpop_analy .vGraph li:nth-child(2) .gBar span {color:#9a6dd5}
.lyrpop_analy .vGraph li:nth-child(3) .gBar span {color:#a6df7d}
.lyrpop_analy .vGraph li:nth-child(4) .gBar span {color:#33b3ed}
.lyrpop_analy .vGraph li:nth-child(4) .gBar span {color:#f4a76d}
.lyrpop_analy .vGraph .gBar span{position:absolute;width:90px;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;top:-25px;left:50%;transform:translatex(-50%);color:#767676;font-size:13px}
.lyrpop_analy .vGraph .gTerm span{position:absolute;width:70px;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;bottom:-25px;left:50%;transform:translatex(-50%);color:#222;font-weight:500;font-size:12px}
.search-container {position:relative}
.search-container .inp {background:#fff}
.box-list.comparelst {position:static;margin-top:-1px;border:1px solid #d0d2d6;max-height:180px;padding-bottom:40px;}
.box-list.comparelst ul {display:block;max-height:130px;box-sizing:border-box;overflow-y:scroll}
.box-list.comparelst .check-box .check {height:auto;}
.box-list.comparelst ul .address {display:block;font-size:14px;margin-top:2px}
.box-list.comparelst .filter-btn-area {border:1px solid #d0d2d6;box-sizing:border-box}
.add-text {}
.danjicompare .panel-box .seleclst li {display:block;margin:2px 20px 2px 3px;border:none;background:none;}
.danjicompare .panel-box .seleclst li span {display:inline-block;vertical-align:middle}
.danjicompare .panel-box .seleclst li span:first-child {padding:0;font-size:15px;font-weight:500}
.danjicompare .panel-box .seleclst .link_danji {display:inline-block;padding:2px 10px;border:1px solid #d0d2d6;border-radius:50px;background:#fff;margin-left:5px;font-size:12px;cursor:pointer}
.danjicompare .panel-box .seleclst  .link_danji.selected {background:#c43713;color:#fff;border:1px solid #c43713}
.danjicompare .container {height:100%}

.lyrpop_analy .list {border:1px solid #e5e5e5;border-collapse:separate;border-top:0;border-spacing:0;margin-top:10px}
.lyrpop_analy .list tr {height:32px;}
.lyrpop_analy .list thead tr {background-color:#fafafa;}
.lyrpop_analy .list thead td {text-align:center}
.lyrpop_analy .list td {position:relative;text-align:center;font-size:13px;font-weight:300;color:#222;vertical-align:middle;border-top:1px solid #e5e5e5;padding:5px;}
.lyrpop_analy .list tbody td {background:#fff;}
.lyrpop_analy .list tbody td:nth-child(2) {font-weight:500}
.lyrpop_analy .list tbody td:nth-child(3) {text-align:left}

.go_report {display:inline-block;width:100%;box-sizing:border-box;text-align:center;font-weight:400;font-size:16px;padding:0  10px;background:url(../images/ico_arr02.png) no-repeat 87% center;}

.btn_link[data-tooltip-text]:hover:after {width:200px;top:-9px;left:19px;white-space:pre-line;}



/*랭킹*/
/*.ranking .panel-header {background:#fff}*/
.ranking .container {height:100vh}
.badge {position:absolute;top:-5px;left:-28px;display:inline-block;width:35px;height:35px;text-indent:-9999px;}
.ranking01 {background:url(../images/new/ico_ranking01.png) no-repeat;background-size:contain;}
.ranking02 {background:url(../images/new/ico_ranking02.png) no-repeat;background-size:contain;}
.ranking03 {background:url(../images/new/ico_ranking03.png) no-repeat;background-size:contain;}
.ranking04 {background:url(../images/new/ico_ranking04.png) no-repeat;background-size:contain;}
.ranking05 {background:url(../images/new/ico_ranking05.png) no-repeat;background-size:contain;}
.ranking .header {background:none}
.rnk_lyr, .rnk_lyr01 {z-index:1004}
/*.lyrpop_analy.ranking .content {margin-top:40px}*/
.ranking .lyrpop_wrap {background:#eeeeee;overflow-y:inherit;/*height:80%*/}
.ranking .lyrpop_wrap:after {content: ''; display:block;clear:both;}
.ranking .lyr_inner {box-sizing:border-box;overflow-y:auto;height:100%;}
.rankingbox {padding:0 20px 20px}
.ranking_item a {display:block;padding:10px;text-align:center;background:#fff;border-radius:30px;margin:16px 0;}
.ranking_item a.on {background:#9e1500;color:#fff;border-radius:30px}
.ranking_item .rankin-subfilter {padding:0 20px 5px}
.ranking h3 {width:100%;position:fixed;left:0;top:0;background:#eee;text-align:center;font-weight:500;z-index:1000}

.ranking .filter_inner {width:auto;display:inline-block;padding-top:5px}
.ranking .filter_inner li {margin-right:15px;margin-left:0;display:inline-block;height:50px;padding:10px 0;box-sizing:border-box}
.ranking .filter_inner li a:after {display:inline-block;content:'';width:16px;height:16px;background:url(../images/btn_arr01.png) no-repeat 0 6px;margin-left:10px}
.ranking .filter_inner li:last-child {margin-right:0}
.ranking .inp-box {margin-top:60px;padding:0 30px 10px}
.ranking .inp-horiz {margin:0 auto;width:100%}
.ranking .inp-horiz .selec {background:#fff url(../images/btn_arr01.png) no-repeat 10 center;height:43px;line-height:43px;border-color:#921500}
/*.ranking .inp-horiz .selec:first-child {margin-right:0;width:150px}*/
.ranking .radio-box .radio {padding:0}

.filter_selec {width:100%;background:#fff;position:absolute;top:50px;left:0;border-top:1px solid #ccc;border-bottom:1px solid #ccc;min-height:115px;max-height:450px;padding:15px 15px 60px;box-sizing:border-box;z-index:2}
.filter_selec form {min-height:45px;max-height:375px;overflow-y:scroll;}
.filter_selec .filter-btn-area {background:#d4d4d4}
.ranking .panel-box {margin-top:0;padding-bottom:60px}
.item-card.rank {min-height:auto}
.rank .item_info {width:100%;padding-right:70px;margin-top:0}
.rank .d_info01 {font-size:20px;font-weight:600}
.rank .d_info01 i {color:#c22800;display:inline-block;vertical-align:middle;margin-right:5px;}
.rank .d_info01 .danjiname {font-size:20px;}
.rank .value {position:absolute;right:15px;top:16px;font-size:20px;font-weight:600}
.ranking .mlst_tit .area {display:block;font-size:14px;font-weight:500}
.ranking .mlst_tit {font-size:16px;font-weight:600;line-height:1;padding-right:50px;color:#222}
.ranking .mlst_tit span {color:#222;}
.map_share {position:fixed;right:15px;bottom:160px;}
.map_share ul li {display:block;margin-bottom:8px;padding-right:0;margin-right:0}
.lyrpop_analy.ranking {
	width:100%;
	// height:100vh;
	.lyrpop_wrap {
		height:100%;
		max-height:100%;
		.btn-right {
			width: 55rem;
			height: 55rem;
			position: absolute;
			left:0;
			top:0;
			.btn-close-small {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
  }

/*일자리수*/
.lyrpop_analy .ul-container {padding:0}
.lyrpop_analy .ul-container li {height:35px}

/*재건축상세*/
.score .panel-box {margin-top:0;padding-top:0;}
.score .itmbox_g {background:#eaebed;height:160px;padding:0}
.score .itmbox_g img {width:100%;height:auto;object-fit:contain;}
.score .basicinfo li span:first-child {width:50%;color:#222}
.score .basicinfo li span:first-child:before {display:inline-block;content:'';width:4px;height:4px;background:#a40000;margin-right:10px;margin-bottom:3px;}
.score .basicinfo li span:last-child {font-weight:bold}
.score .basicinfo {border-bottom:none;padding-bottom:0;margin-bottom:0}
.score .basicinfo li span {font-size:16px;}
.score .basicinfo small {margin-left:12px;margin-top:-8px}
.score .basicinfo+.t_red {margin-top:14px;font-size:15px}
.score .scroll-menu-container ul {padding-right:50px;}
.score .btn_space_box {right:0;top:12px}
.score .itmbox_wrap:last-child {margin-bottom:10px}
.score .mlst_tit .subtitle {display:block;font-size:14px;line-height:1.2;margin-top:0;color:#222;font-weight:300}
.score .scroll-menu-container {margin-bottom:15px}
.score .invest-comm {padding:10px 15px;background:#eaeaea;color:#c22800;line-height:1.4;font-size:14px;margin-top:15px}
.itmbox.share {height:50px;text-align:center;background:#7a1d05;margin-top:-10px;margin-bottom:0;padding:20px}
.share li {display:inline-block;width:45px;margin-right:8px;border-radius:50px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), -1px 1px 1px rgba(0, 0, 0, 0.04), 2px 2px 2px rgba(0, 0, 0, 0.04);}
.share li:last-child {margin-right:0}
.share li a {display:block;height:45px;width:45px;margin-top:0;text-indent:-9999px;}
.share li a.kakao {background:url("../images/share_kakao.png") no-repeat;background-size:contain;vertical-align:top;}
.share li a.facebook {background:url("../images/share_fb.png") no-repeat;background-size:contain;vertical-align:top;}
.share li a.band {background:url("../images/share_band.png") no-repeat;background-size:contain;vertical-align:top;}
.my_location + div .container {height:100%}
.share li a.link {background:url("../images/share_link.png") no-repeat;background-size:contain;vertical-align:top;}

/*추천*/
.img_recomm {width:100%;margin-top:30px;margin-bottom:20px}
.recomm .container {height:100vh}
.recomm .cont_wrap {height:100%;padding:10px 20px;box-sizing:border-box;position:relative}
.recomm .subj_tit .m_tit {font-size:22px;font-weight:500}
/*.recomm .tab_cont {height:100%;box-sizing:border-box;position:relative;padding-bottom:60px}*/
.recomm .process {display:block;position:static;margin-bottom:20px}
.recomm .addexp {position:absolute;bottom:100px;left:20px}
.selec span {position:absolute;top:50%;transform:translatey(-50%)}
.recomm .cont {position:relative}
.recomm_group {margin-bottom:30px;}
.recomm_group .lbl {font-size:15px;display:block;margin-bottom:10px;}
.recomm .filter_selec {padding:0;border:none;z-index:100;top:55px;background:#fff;height:350px;}
.recomm .filter_selec form {overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);height:100%;}
.recomm .filter_selec .check-box03 {padding-bottom:55px;}
/*.recomm .cont form + form {margin-top:20px}*/
/*.recomm .cont form + form.recomm-tradetype02 {margin-top:10px}*/
.order-container li {display:block;border:1px solid #e9e9e9;margin-bottom:10px}
.order-container li a {display:block;width:100%;padding:15px;text-align:center;position:relative;box-sizing:border-box;font-size:18px}
.order-container li a.selected01:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'1';position:absolute;left:20px;font-size:14px;line-height:22px}
.order-container li a.selected02:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'2';position:absolute;left:20px;font-size:14px;line-height:22px}
.order-container li a.selected03:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'3';position:absolute;left:20px;font-size:14px;line-height:22px}
.order-container li a.selected04:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'4';position:absolute;left:20px;font-size:14px;line-height:22px}
.order-container li a.selected05:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'5';position:absolute;left:20px;font-size:14px;line-height:22px}
.slider-value {text-align:center;padding:15px 0 20px;}
.slider-value span {display:inline-block;font-size:30px;color:#c22800}
.slider-adjust {position:absolute;top:15px;width:100%;}
.btn.btn-adjust-minus {position:absolute;left:-10px;top:5px;width:30px;height:30px;border:1px solid #cdcdcd;border-radius:35px;padding:0;color:#cdcdcd;font-size:20px;line-height:1}
.btn.btn-adjust-plus {position:absolute;right:-10px;top:5px;width:30px;height:30px;border:1px solid #cdcdcd;border-radius:35px;padding:0;color:#cdcdcd;font-size:20px;line-height:1}
.lyrpop_analy .content p.recomm-p {font-size:16px;padding-top:15px;padding-bottom:10px}
.lyrpop_analy .lyrpop_wrap {min-height:200px}
.link-history {color:#b9342a;position:absolute;bottom:80px;right:15px;display:inline-block;padding:10px;font-size:16px;}
.link-history:after {display:inline-block;content:'>';margin-left:7px}
.recomm form {margin-bottom:10px}
.recomm form .lbl {display:block;font-size:16px;margin-bottom:10px;}
.btn-reset {position:absolute;bottom:80px;right:15px;display:inline-block;padding:10px;font-size:16px}
.btn-reset:before {display:inline-block;content:'';background:url("../images/new/ico_refresh.png") no-repeat;width:16px;height:14px;background-size:contain;margin-right:5px}
.danjicompare .itmbox:first-child {margin-top:10px}
.recontr_scrore {height:100%}
/*.analysis_lyr .map_share {bottom:100px;}*/
.recomm_filter {position:absolute;bottom:80px;left:15px}
.recomm_filter li {margin-bottom:8px;/*box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08), -1px 1px 1px rgba(0, 0, 0, 0.04), 1px 1px 1px rgba(0, 0, 0, 0.04);*/}
.recomm_filter li a {display:inline-block;padding:6px 25px 6px 12px;min-width:90px;box-sizing:border-box;border-radius:30px;background:#ca0202 url(../images/ico_arrowr.png) center right 10px no-repeat;color:#fff;letter-spacing:-.5px;}
.recomm .subj_tit small {font-size:15px;margin-top:10px;line-height:1.5}


/*실거래가*/
.analysis_lyr .weekly {position:fixed !important;top:0;bottom:0;right:0;left:0;background:#fff}
.weekly .header {z-index:100}
.weekly .container {padding-top:55px;height:100%;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.weekly .panel-header {position: fixed; top: 0; right: 0; left: 0; background:#fff;border-bottom:1px solid #d6d6d6}
.weekly .subj_tit .m_tit {font-size:22px;font-weight:500}
.weekly .cont_wrap {display:block;height:100%;box-sizing:border-box;position:relative;padding:10px 20px 60px}
.weekly .tab_cont {height:100%;}
.weekly .addexp {position:absolute;bottom:150px;left:20px}
.selec span {position:absolute;top:50%;transform:translatey(-50%)}
.weekly .inp-box {position:relative}
.weekly .filter_selec {padding:0;border:none;z-index:100;top:55px;background:#fff;height:400px}
.weekly .filter_selec form {overflow-y:scroll;height:300px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.weekly .cont form + form {margin-top:10px}
.weekly .addexp .week {font-size:17px;}
.weekly .addexp span {display:block;font-size:20px;}
.weekly .addexp span .end {display:inline-block;margin-bottom:20px}
.weekly .date {margin-right:5px;}
.weekly .panel-box {margin-top:0}
.weekly .summary {padding:10px 15px 0}
.weekly .summary h2 {font-size:18px}
.weekly .summary h2 small {font-size:15px;font-weight:400;/*margin-top:-10px;*/margin-left:0;}
.weekly .summary .count {font-size:20px;}
.weekly .item-card.wk .item_info {display:block;width:100%;padding-bottom:10px;margin-bottom:10px;border-bottom:1px solid #efefef;font-weight:500;padding-right:0;max-width:100%;margin-top:0;}
.weekly .item-card.wk .item_info .danjiname {max-width:100%;font-weight:500;font-size:17px;}
/*.weekly .dealbox {padding:0 10px}*/
.deal-lst .area {width:15%}
.deal-lst .date {width:15%}
.deal-lst .dealtype {width:65%}
.deal-lst .date {color: color(normal, 030);}
.deal-lst .deal-type span {display:inline-block;margin:0}
.deal-lst .deal-type .real-price {font-size:17px;vertical-align:middle}
.deal-lst .deal-type .floor {font-size:15px;font-weight:500;margin-left:5px;vertical-align:middle}
.item-card-container .item-card.wk {min-height:100px}
.weekly .panel-box, .ranking .panel-box {padding-bottom:90px;/*min-height:calc(100vh - 55px);*/height:100% !important}
.weekly .itmbox.share, .ranking .itmbox.share {position:fixed;width:100%;bottom:0;box-sizing:border-box;padding:20px;height:auto;}
.weekly .panel-group {height:100vh}

/*개발호재*/
.svg-group {display:flex;flex-wrap:wrap;align-items:stretch;align-content:stretch;margin-top:10px;padding:15px 10px 0 10px;border-top:1px solid #f0f0f0;}
.svg-group .svg {cursor:pointer;flex:0 0 33%;display:inline-block;font-size:13px;text-align:center;color:#747481;position:relative}
.svg-group .svg svg {width:50px;height:20px;}
.svg-group .svg div {color:#aaa;margin-top:3px;}
.svg-group .svg.on div {color:#ba1900;}
.svg-group .svg-struc.on div {color:#8957a1;}
.svg-group .svg-restruc.on div {color:#ff7d29;}
.ico_struc {display:block;width:25px;height:25px;background:url("../images/ico_struc.png") no-repeat;position:absolute;top:-10px;left:50%;transform:translatex(-50%)}
.svg.on .ico_struc {width:25px;height:25px;background:url("../images/ico_struc_on.png") no-repeat;background-size:contain;}
.goodnews div {position:relative}
.goodnews div .nametag {display:inline-block;padding:2px 5px;background:#ba1900;color:#fff;position:absolute;top:-47px;font-size:12px;width:auto;text-align:center;/*right:-10px;*/transform:translatex(-50%);left:50%;white-space:nowrap;opacity:.9}
.goodnews div.struc .nametag {display:inline-block;padding:2px 5px;background:#8957a1;color:#fff;position:absolute;top:-47px;font-size:12px;width:auto;text-align:center;/*right:-10px;*/transform:translatex(-50%);left:50%;white-space:nowrap;opacity:.9}
.goodnews div.train .nametag {position:absolute;background:#fff;font-weight:600;transform:none;right:inherit}
.svgleg {width:20px;}
.goodnews div.restruc .nametag {display:inline-block;padding:2px 5px;background:#fb8538;color:#fff;position:absolute;top:-20px;font-size:12px;width:auto;text-align:center;left:50%;transform:translatex(-50%);white-space:nowrap;opacity:.9}
.goodnews div .nametag span {display:block;}
.goodnews div .nametag .nt02 {font-size:11px;}
.goodnews .ico_struc {width:25px;height:25px;background:url("../images/ico_struc_on.png") no-repeat;background-size:contain;}
.goodnews-cont {padding:10px 20px 20px;padding-top:65px}
.goodnews-cont p {margin-bottom:20px}
.goodnews-cont .inp-group {margin-bottom:15px}
.goodnews-cont .inp-group .lbl {font-size:15px;margin-bottom:5px;display:block;}
.goodnews-cont .txtarea {height:250px;}

.analysis_lyr .lyr_box {position:relative}
.analysis_lyr .link_txt {display:inline-block;position:absolute;right:25px;top:6px;font-size:14px;font-weight:300}
.radio-block .radio-box .radio {display:block;margin-bottom:7px}

.lyr_bottom {display:block;width:100%;height:320px;position:fixed;bottom:0;left:0;top:inherit;transform:inherit;z-index:1002}
.lyr_bottom .lyr_inner {padding:20px;height:100%}
.lyr_bottom .lyrpop_wrap {min-height:320px;}
.lyr_bottom .btn-close {position:absolute;right:0;top:0}
.lyr_bottom .subj_tit {padding-bottom:20px;padding-right:10px;}
.lyr_bottom .subj_tit .m_tit {font-size:18px;font-weight:500}
.lyr_bottom .filter_selec {/*min-height:100px;*/max-height:220px;top:63px;z-index:100}
.lyr_bottom .filter_selec form {max-height:150px;}
.lyr_bottom .btn-area-bottom {z-index:98}
.lyr_bottom .recomm-tradetype01 {margin-bottom:8px;}
.lyr_bottom .recomm_group {height:170px;overflow-y:hidden}

/* 210805 분석의 우측컨트롤버튼 */
.find.allmap .analysis .map-btn-group-right {top:20px;right:16px;}

// root-dawin
:root.root-dawin {
	.small {font-size:14px;}
}