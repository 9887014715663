/* post-display */
.goods-display {
	.goods-list {
		margin: 0;
		padding: 0;
		.goods-item {
			list-style: none;
		}
	}

	.goods-info {

	}
}

.goods-display.module-a {
	.goods-list {
		display: flex;
		flex-direction: column;

		.goods-item {
			.goods-wrap {
				display: flex;
				column-gap: 16rem;

				.goods-select {
					flex-shrink: 1;

					.form.module-a.radio.small-1x {
						
						column-gap: 16rem;

						.form-text {
							display:flex;
							flex-direction: column;
							row-gap:4px;
							
							.subject {
								display: block;
								@include font((outline: body, size: small-1x, weight: bold)...);
								color: color(normal, 010);
							}
							.summary {
								color: #9d9d9d;
								margin: 0;
								font-size: 13.3333rem;
								line-height: 20rem;
								display: flex;
								font-weight: 400;

							}
						}
					}						
				}

				.goods-inform {
					flex: 1 1 100%;
					display: flex;
					align-items: center;
					column-gap: 8rem;
					
					.goods-head {
						flex: 1 1 100%;
	
						display: flex;
						flex-direction: column;
						row-gap: 4rem;
	
						.goods-subject {
							display: flex;
							align-items: center;
							column-gap: 8rem;
							margin: 0;
	
							.goods-name {
								display: block;
								@include font((outline: body, size: small-1x, weight: bold)...);
								color: color(normal, 010);
							}
	
							.mark {
								flex-shrink: 0;
							}
	
						}
						.goods-summary {
							display: flex;
							margin: 0;
	
							@include font((outline: body, size: small-2x)...);
							color: color(normal, 030);
						}

						.goods-utility {
							display: flex;
							margin: 8rem 0 0 0;

							.button-area {
								display: flex;
								gap: 4rem;
							}
						}
					}
	
					.goods-func {
						.button-area {
							display: flex;
							column-gap: 6rem;
						
							.btn.delete {
								@include button-reset;
								position: relative;
								z-index: 1;
	
								.btn-text {
									display: flex;
									justify-content: center;
									align-items: center;
									position: relative;
									z-index: -1;
									overflow: hidden;
									width: 24rem;
									height: 24rem;
									@include font((outline: body, size: small-4x)...);
	
									&::after {
										content: '';
										display: block;
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										left: 0;
										border-radius: 50%;
										border: 1rem solid color(normal, 040);
										box-sizing: border-box;
										background: color(normal, 040) icon(close-small, color(normal, 000)) no-repeat center/24rem;
									}
								}
							}
						}
					}
				}
			}

			a.goods-wrap {
				text-decoration: none;

				&::after {
					flex-shrink: 0;
					content: '';
					display: block;
					width: 24rem;
					height: 24rem;
					background: icon(chevron-right, color(normal, 040)) no-repeat center/cover;
				}
			}
		}
	}

	&.style-a {
		.button-display.attr-more-data {
			margin-top: 24rem;
		}

		.goods-list {
			row-gap: 24rem;
		}
	}

	&.style-c {
		.goods-list {
			row-gap: 12rem;
	
			.goods-item {
				.goods-wrap {
					.goods-inform {
						padding: 16rem;
						border-radius: 8rem;
						background-color: color(base, 000);
					}
				}
			}
		}
	}
}



.goods-display.module-b {
	&.type-b,
	&.type-a {
		.goods-item {
			.goods-wrap {
				display: flex;
				column-gap: 16rem;

				.goods-figure {
					flex-shrink: 0;
				}

				.goods-inform {
					flex: 1 1 100%;
				}
			}
		}
	}

	&.type-b {
		.goods-item {
			.goods-wrap {
				.goods-figure {
					order: 2;
				}

				.goods-inform {
					order: 1;
				}
			}
		}
	}

	&.type-c {
		.goods-item {
			.goods-wrap {
				.goods-price {
					justify-content: center;
				}
			}
		}
	}

	&.type-d {
		.goods-head {
			display: flex;
			gap: 16rem;
			align-items: center;
			
			.goods-subject {
				display: flex;
				gap: 4rem;
				flex-wrap: wrap;
			}
		} 
	}

	&.style-a {
		.goods-list {
			display: flex;
			flex-direction: column;
			row-gap: 40rem;

			.goods-item {
				position: relative;
				
				+.goods-item {
					padding-top: 40rem;

					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: calc(50% - 50vw);
						width: 100vw;
						height: 1rem;
						background-color: color(base, 005);
					}
				}


				// mobile
				@include respond-above(tablet) {
					right: 0;
					left: 0;
					width: initial;
				}
			}
		}
	}

	&.style-b {
		.goods-list {
			display: flex;
			flex-direction: column;
			row-gap: 24rem;

			.goods-item {
				position: relative;
				padding-top: 24rem;
				
				+.goods-item {

					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 1rem;
						background-color: color(base, 005);
					}
				}
			}
		}
	}
	&.style-c {
		.goods-list {
			display: flex;
			flex-direction: row;
			column-gap: 16rem;

			.goods-item {
				position: relative;
				padding-top: 0;
			}
		}
	}

	.goods-list {
		.goods-item {
			.goods-form {
				display: flex;
				margin-bottom: 24rem;

				.form {
					flex: 1 1 100%;
				}
			}
			.goods-wrap {
				.goods-figure {
					position: relative;
					z-index: 1;
					width: 120rem;
					height: 120rem;
					// background-color: color(base, 005);
					
					.button-area {
						display: flex;
						justify-content: center;
						position: absolute;
						top: calc(100% - 8rem);
						right: 0;
						left: 0;
					}

					.form.file {
						flex-shrink: 0;
						display: flex;
						width: 120rem;
						height: 120rem;
						
						&::before {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							border: 1rem dashed color(normal, 100, 0.2);
							border-radius: 8rem;
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
							background-origin: border-box;
							background-image: var(--background-image);
						}
						
						&[data-bui-form-file-name] {
							&::before {
								border-style: solid;
							}
		
							.form-elem {
								pointer-events: none;
							}
						}
		
						&:not([data-bui-form-file-name]) {
							&::after {
								content: '사진추가';
								display: flex;
								justify-content: center;
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								padding-top: calc(50% + 8rem);
								pointer-events: none;
								color: color(normal, 040);
								@include font((outline: body, size: small-2x)...);
								background: icon(add-photo, color(normal, 040)) no-repeat center calc(50% - 8rem)/24rem;
							}
						}
				
						.form-elem {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
				
							margin: 0;
							padding: 0;
							font-size: 0;
							border: 0 none;
							border-radius: 0;
							background-color: transparent;
							appearance: none;
				
							&::-webkit-file-upload-button {opacity: 0;}
							&::file-selector-button {opacity: 0;}
						}
				
						.form-func {
							position: absolute;
							top: 0;
							right: 0;
		
							.btn.delete {
								position: relative;
								justify-content: center;
								align-items: center;
								width: 32rem;
								height: 32rem;
		
								svg {
									fill: rgba(0,0,0,0.75);
								}
							}
						}
					}

					.goods-thumbnail {
						display: flex;
						justify-content: center;
						align-items: center;

						position: relative;
						width: 120rem;
						height: 120rem;
						border-radius: 8rem;
						overflow: hidden;
						object-fit: cover;
						background: icon(no-image, color(normal, 040)) no-repeat center/32rem;
						
						&::after {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							border-radius: 8rem;
							box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.05);
						}

						img {
							display: block;
							position: relative;
							max-width: initial;
							max-height: 100%;
							object-fit: cover;
							height: 100%;
							display: block;
							position: relative;
							width: 100%;
							height: 100%;
							flex: 1;	
							border-radius: 8rem;					
						}
					}

					.mark.module-a {
						position: absolute;
						bottom: 8rem;
						left: 8rem;
					}
				}
				.goods-inform {
					.goods-head {
						.goods-subject {
							margin: 0;
							@include font((outline: body, size: small-1x, weight: medium)...);
							color: color(normal, 010);

							.goods-name {
								display: block;
								word-break: break-all;
								text-align: left;
							}
						}
					}

					.goods-info {
						.data-list {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							column-gap: 4rem;
							margin: 0;
							overflow: hidden;

							.data-item {
								display: flex;
								flex-wrap: wrap;
								position: relative;
								left: -16rem;

								.head {
									@include hidden;
								}

								.body {
									display: flex;
									align-items: center;
									@include font((outline: body, size: small-1x)...);
									color: color(normal, 030);

									&::before {
										content: '';
										flex-shrink: 0;
										display: block;
										width: 16rem;
										height: 16rem;
										background: icon(bullet-dot, color(normal, 030)) no-repeat center/cover;
									}
								}
							}
						}
					}

					.goods-price {
						display: flex;
						flex-wrap: wrap;
						align-items: flex-end;
						column-gap: 8rem;
						// row-gap: 8rem;
						margin-top: 4rem;

						>.button-area {
							flex-shrink: 0;
							display: flex;
							align-items: center;
							height: 32rem;
						}

						.data-list {
							flex-shrink: 0;
							margin: 0;

							.data-item {
								display: flex;
								align-items: center;
								column-gap: 8rem;

								.head {
									display: block;
									@include font((outline: body, size: small-1x)...);
									color: color(normal, 030);
								}

								.body {
									display: flex;
									flex-wrap: wrap;
									align-items: baseline;
									column-gap: 8rem;
									@include font((outline: body, size: medium, weight: medium)...);
									color: color(normal, 010);

									.btn {
										align-self: center;
									}
								}
							}
						}
						+.para {
							margin-top: 8rem;
						}
					}

					.goods-util {
						margin-top: 8rem;
					}
				}
			}

			.goods-data {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 8rem;
				margin-top: 24rem;

				.data-list {
					display: flex;
					flex-wrap: wrap;
					column-gap: 16rem;
					margin: 0;
					row-gap: 4rem;
	
					.data-item {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						column-gap: 4rem;
	
						.head {
							flex-shrink: 0;
							display: block;
							@include font((outline: body, size: small-1x)...);
							color: color(normal, 030);
						}
	
						.body {
							display: flex;
							align-items: center;
							column-gap: 8rem;
							@include font((outline: body, size: small-1x)...);
							color: color(normal, 010);
							flex-wrap: wrap;
							row-gap: 4rem;
						}
					}
				}
				>.data-util {
					flex: 100%;
				}
			}
			>.goods-util {
				margin-top: 24rem;
			}
		}
	}

	>.button-display {
		margin-top: 40rem;
	}
}

.goods-display.module-c {
	&.type-a {
		.goods-item {
			.goods-wrap {
				display: flex;
				column-gap: 16rem;

				.goods-figure {
					flex-shrink: 0;
				}

				.goods-inform {
					flex: 1 1 100%;
				}
			}
		}
	}

	&.style-a {
		.goods-list {
			display: flex;
			flex-direction: column;
			row-gap: 24rem;
			.goods-item {
				.goods-wrap {
					.goods-inform {
						.goods-head {
							order: 2;
						}
						.goods-info {
							order: 3;
						}
						.goods-data {
							order: 4;
						}
						.goods-foot {
							order: 1;
							margin-bottom: 4rem;
							.head {
								@include hidden;
							}
							.body {
								@include font((outline: body, size: small-1x)...);
								color: color(base, 040);
							}
						}
						.goods-util {
							order: 5;
						}
					}
					.goods-inform.disabled {
						color: color(normal, 030);
					}
				}
			}
		}
	}

	&.style-c {
		.goods-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16rem;
			.goods-inform {
				.goods-info {
					.data-list {
						flex-direction: column;								
					}
				}
			}
		}

	}

	.goods-list {
		.goods-item {
			.goods-wrap {
				.goods-select {
					order: 1;
					flex-shrink: 0;

					.goods-form {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				.goods-figure {
					~.goods-select {
						order: 1;
	
						.goods-form {
							min-height: 72rem;
						}
					}
				}

				.goods-figure {
					order: 2;
					position: relative;
					z-index: 1;

					.goods-thumbnail {
						display: block;
						position: relative;
						width: 72rem;
						height: 72rem;
						border-radius: 100%;
						overflow: hidden;
						background: icon(no-image, color(normal, 040)) no-repeat center/32rem;
						
						&::after {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							border-radius: 100%;
							box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);
						}

						img {
							display: block;
							position: relative;
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}
				}
				.goods-inform {
					order: 3;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.goods-head {
						// margin-bottom: 4rem;
						
						.goods-subject {
							margin: 0;
							@include font((outline: body, size: small-1x, weight: medium)...);
							color: color(normal, 010);

							.goods-name {
								display: block;

								> div {
									display:inline-block;
								}

								&:not(:hover) {
									text-decoration: none;
								}

								&:hover {
									text-decoration: underline;
								}
							}
						}
					}

					.goods-info {
						// margin-top: 4rem;

						.data-list {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							column-gap: 4rem;
							margin: 0;

							.data-item {
								display: flex;

								.head {
									@include hidden;
								}

								.body {
									display: block;
								}

								&.name {
									@include font((outline: body, size: small-1x, weight: bold)...);
									color: color(normal, 010);
								}

								&.grade {
									.body {
										@include grade(20rem);

										.point {
											@include hidden;
										}
									
										.count {
											display: block;
											margin-left: 8rem;
											@include font((outline: head, size: small-3x)...);
											color: color(normal, 010);
										}
									}
								}
								&.grade {
									&.style-b {
										.body {
											@include gradeAV(20rem);

											.point {
												display: block;
												margin-left: 2rem;
												@include font((outline: head, size: small-3x)...);
												color: color(normal, 010);
												visibility: visible;
												width: unset;
												height: unset;
												clip: unset;
												clip: unset;
												pointer-events: unset;
												position: unset;
											}
										
											.count {
												display: inline-flex;
												margin-left: 8rem;
												@include font((outline: head, size: small-3x)...);
												color: color(normal, 010);
												&::before {
													display:inline-block;
													content:'(리뷰';
													margin-right: 4rem;
												}
												&::after {
													display:inline-block;
													content:')';
												}
											}
										}
									}
								}
							}
						}
					}

					.goods-data {
						margin-top: 4rem;

						.data-list {
							margin: 0;

							.data-item {
								display: flex;

								.head {
									@include hidden;
								}

								.body {
									display: block;
									@include font((outline: body, size: small-2x)...);
									color: color(normal, 030);
								}

								&.brokerinfo {
									.body {
										display: flex;
										gap: 8rem;
										.mark {
											.mark-text {
												strong {
													margin-left: 4rem;
												}
											}
										}
									}
								}
							}
						}
					}

					.goods-util {
						margin-top: 8rem;

						.button-area {
							column-gap: 6rem;
						}
					}
				}
			}
			&.style-a {

			}
			&.style-b {
				.goods-wrap {
					.goods-inform {
						.goods-data {
							.data-item {
								&:not(.brokerinfo) {
									display:none;
								}
							}
						}
						.goods-util {
							display: none
						}
					}
				}				
			}			
		}
	}
}
.stamp {
	position: absolute;
	bottom: 8%;
	right: 8%;
	// transform: rotate(-15deg) translate(-43%, -85%);
	z-index: 1;
	.stamp-infom {
		border: 3rem solid #FF0000;
		background-color: rgba(255, 255, 255, 0.8);
		padding: 4rem 8rem;
		.stamp-data {
			display: flex;
			gap: 4rem;
			.head {
				@include hidden;
			}
			.body {
				display: flex;
				gap: 2rem;
				flex-direction: column;
				@include font((outline: body, size: small-2x, weight: bold)...);
				color: #FF0000;
				.date {
					@include font((outline: body, size: small-2x, weight: bold)...);
					color: #FF0000;
				}
			}
		}
	}
	// &.rotate {
	// 	transform: rotate(-15deg);
	// }
}

.chat-display.module-c {
	&.type-a {
		.chat-item {
			padding: 16rem 20rem;
			.chat-wrap {
				display: flex;
				column-gap: 16rem;

				.chat-figure {
					flex-shrink: 0;
				}

				.chat-inform {
					flex: 1 1 100%;
				}
			}			
		}
	}

	&.style-a {
		.chat-list {
			display: flex;
			flex-direction: column;
		}
	}

	&.style-c {
		.chat-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16rem;
			.chat-inform {
				.chat-info {
					.data-list {
						flex-direction: column;								
					}
				}
			}
		}		
	}
	.chat-list {
		.chat-item {
			.chat-wrap {
				.chat-select {
					order: 1;
					flex-shrink: 0;

					.chat-form {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				.chat-figure {
					~.chat-select {
						order: 1;
	
						.chat-form {
							min-height: 72rem;
						}
					}
				}

				.chat-figure {
					order: 2;
					position: relative;
					z-index: 1;

					.chat-thumbnail {
						display: block;
						position: relative;
						width: 48rem;
						height: 48rem;
						border-radius: 100%;
						overflow: hidden;
						background: icon(no-image, color(normal, 040)) no-repeat center/32rem;
						
						&::after {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							border-radius: 100%;
							box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);
						}

						img {
							display: block;
							position: relative;
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}
				}
				.chat-inform {
					order: 3;
					display: flex;
					flex-direction: column;
					justify-content: center;
					white-space: pre-wrap;
					word-break: break-all;

					.chat-head {
						
						.chat-subject {
							margin: 0;
							@include font((outline: body, size: small-1x, weight: medium)...);
							color: color(normal, 010);

							.chat-name {
								display: block;

								&:not(:hover) {
									text-decoration: none;
								}

								&:hover {
									text-decoration: underline;
								}
							}
						}
					}

					.chat-info {
						// margin-top: 4rem;

						.data-list {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							column-gap: 4rem;
							margin: 0;

							.data-item {
								display: flex;

								.head {
									@include hidden;
								}

								.body {
									display: block;
								}

								&.name {
									@include font((outline: body, size: small-1x, weight: bold)...);
									color: color(normal, 010);
								}

								&.grade {
									.body {
										@include grade(20rem);

										.point {
											@include hidden;
										}
									
										.count {
											display: block;
											margin-left: 8rem;
											@include font((outline: head, size: small-3x)...);
											color: color(normal, 010);
										}
									}
								}
							}
						}
					}
					.chat-body {
						margin: 8rem 0;
						display: flex;
						gap: 4rem; 
						
						.para {
							color: color(normal, 010);
							@include font((outline: body, size: small-2x)...);
							flex: 100%;
							@include ellipsis(2);
							word-break: break-all;
							white-space: pre-wrap;
							a {
								display:inline-flex;
								color: #2d88df;
								text-decoration: underline;
							}
						}
						.count {
							display: flex;
							padding: 0 6rem 0 5rem;
							height: 16rem;
							@include font((outline: body, size: small-4x)...);
							color: color(base, 000);
							border-radius: 8rem;
							text-align: center;
							background-color: color(accent, 010);
							margin-top: 4rem;
						}
						.data-list {
							.data-item {
								@include font((outline: body, size: small-2x)...);
							}
						}
					}

					.chat-data {
						// margin-top: 8rem;

						.data-list {
							margin: 0;
							display: flex;
							justify-content: space-between;
							flex-direction: column;
							gap: 4rem;

							.data-item {
								display: flex;

								.head {
									@include hidden;
								}

								.body {
									display: block;
									@include font((outline: body, size: small-2x)...);
									color: color(normal, 030);
								}
							}
						}
					}

					.chat-util {
						margin-top: 8rem;

						.button-area {
							column-gap: 6rem;
						}
					}
				}
			}
			&.style-a {

			}
			&.style-b {
				.chat-wrap {
					.chat-inform {
						.chat-data,
						.chat-util {
							display: none
						}
					}
				}				
			}	
			&.unknown {
				.chat-inform {
					.goods-name {
						color: color(base, 040)
					}
				}
				.chat-info {
					display: none;
				}
			}		
		}
	}

	&.style-d {
		.chat-list {
			padding-bottom: 24rem;
			.chat-item {
				padding: 6rem 32rem 6rem 20rem;
				
				.chat-wrap {
					display: flex;
					align-items: flex-start;
					gap: 8rem;
		
					.chat-figure {
		
						.chat-thumbnail {
							display: block;
							position: relative;
							width: 32rem;
							height: 32rem;
							border-radius: 100%;
							overflow: hidden;
							background: icon(no-image, color(normal, 040)) no-repeat center/32rem;
							
							&::after {
								content: '';
								display: block;
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								border-radius: 100%;
								box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);
							}
		
							img {
								display: block;
								position: relative;
								object-fit: cover;
								width: 100%;
								height: 100%;
								background-color: color(base, 000);
								border-radius: 100%;
							}
						}
					}
					.chat-inform {
						display: flex;
						gap: 4rem;
						flex-direction: row;
						align-items: flex-end;
						justify-content: flex-start;

						.chat-info {
							.data-list {
								flex-direction: column;								
							}
						}
						.chat-body {	
							margin: 0;					
							padding: 8rem 16rem;
							border-radius: 16rem;
							background-color: #f4f4f4;
							
							.para {
								text-overflow: unset;
								-webkit-line-clamp: unset;
								max-height: unset;		
								word-break: break-all;		
								white-space: pre-wrap;				
							}
						}
						.chat-data {
							flex-shrink: 0;
							.head {
								@include hidden;
							}
		
							.body {
								display: block;
								@include font((outline: body, size: small-3x)...);
								color: color(normal, 030);
							}
						}
					}
				}

				&.type-a {
					.chat-wrap {
						.chat-inform {
							justify-content: flex-start;
						}
					}
				}
				&.type-b {
					padding-left: 32rem;
					padding-right: 20rem;
					.chat-wrap {
						.chat-figure {
							@include hidden;
						}
						.chat-inform {
							justify-content: flex-end;

							.chat-body {
								order: 2;
								background-color: color(base, 080);
								.para {
									color: color(normal, 000);
								}	
							}
							.chat-data {
								order: 1;
							}
						}
					}
				}
				&.type-c {
					.chat-wrap {
						.chat-inform {
							justify-content: center;
						}
					}
				}
				&.chat-date {
					// margin-bottom: 16rem;
					padding: 16rem 20rem;
				}
				&.chat-dawin {
					.chat-wrap {
						.chat-inform {
							.chat-body {
								background-color: color(primary, 010);
							}
						}
					}
				}
				&.chat-my {
					.chat-wrap {						
						.chat-inform {
							.chat-body {
								background-color: color(base, 080);
								.para {
									color: color(normal, 000);
									a {
										color: #4291dd;
									}
								}								
							}
						}
					}
				}
				&.chat-file {
					.chat-wrap {						
						.chat-inform {
							.chat-body {
								padding: 0;
								background-color: unset;
								.atch-file {
									.data-list {
										flex-direction: column;
										gap: 8rem;
										.data-item {
											width: fit-content;
											margin: 0;
											.data-wrap {
												.data-body {
													min-height: 100rem;
													height:fit-content;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}		
	}
}	