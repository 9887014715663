@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */

/* faq */
.pfaq_wrap {position:fixed;top:0;right:0;bottom:0;width:450px;height:100%;padding-bottom:80px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);box-sizing:border-box;background:#fff;box-shadow:0 0 0 2px rgba(0,0,0,.06);z-index:1001}
.faq-header {position:fixed;width:450px;height:65px;background:#6f1700;padding:0 30px;box-sizing:border-box;z-index:100}
.faq-header .title {color:#fff;text-align:center;/*margin-bottom:20px;*/font-size:20px;font-weight:300;padding-top:19px}
.pfaq_wrap .btn-close-wlg {position:absolute;top:20px;left:20px;}
.sch_wrap {position:relative;height:45px;background:#fff;/*padding:10px 50px 10px 20px;*/box-sizing:border-box}
.sch_wrap .btn_m_sch {top:11px}
.faq-cont {padding:64px 0 30px 0;padding-bottom:30px !important;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);height:100%;overscroll-behavior:contain;}
.more_view {padding-bottom:10px;text-align:right}
.more_view .btn_more {display:inline-block;padding-right:20px;background:url(../images/main/btn_arr_rights.png) no-repeat center right}
.accordian li {display:block}
.accordian li .qitem {display:block;padding:12px 20px 12px 20px;font-size:16px;border-top:1px solid #c3c4c8;background:url(../images/main/btn_arr_down.png) no-repeat top 20px right 20px;font-weight:bold}
.accordian li:first-child .qitem {border-top:0}
.accordian li .qitem.active {font-size:16px;background:url(../images/main/btn_arr_up.png) no-repeat top 20px right 20px}
.accordian li .qitem b {margin-right:5px;color:#e44332}
.q_wrap {border-top:1px solid #ccc;}
.accordian li .aitem {display:block;padding:12px 40px 12px 35px;line-height:1.6;background:#fff url("../images/main/btn_arr_down.png") no-repeat center right 20px;}
.accordian li .aitem.active {background:url(../images/main/btn_arr_up.png) no-repeat top 20px right 20px}
.accordian li .aitem:hover, ul.accordian li .aitem.on {background-color:#f9f9f9}
.accordian li .aitem b {margin-right:5px;color:#6E6E6E}
.ans {padding:12px 40px 12px 35px;background:#f9f9f9;line-height:1.4;white-space: pre-line;}
.accordian li:last-child .q_wrap:last-child {border-bottom:1px solid #ccc}
.faq_footer {position:fixed;bottom:0;width:450px;height:104px;background:#fff}
.faq_footer p {font-size:17px;text-align:center;padding-top:10px}
.faq .btn-box {position:fixed;bottom:0;width:450px;background:#fff;}
.faq_footer .btn_qna01 {position:absolute;left:30px;bottom:20px;padding:15px 70px;font-size:16px}
.faqans, .qna {position:relative;padding:50px 25px;height:100%;overflow-y:auto}
.faqans .btn_back, .qna .btn_back {display:inline-block;position:absolute;left:25px;top:30px;text-indent:0;width:100%;line-height:32px}
.faqans .subj {font-size:18px;font-weight:bold;margin-bottom:20px;padding-top:20px}
.faqans .des {font-size:15px;line-height:1.8}
.qna_cont {position:relative;padding:50px 25px;height:100%;overflow-y:auto}
.qna_cont .inp {margin-top:10px}
.qna_cont .txtarea {height:250px;width:100%;margin-top:10px;margin-bottom:10px}
.qna_cont  p {padding-bottom:20px}
.qna_cont .btn_area01 {text-align:left;margin-top:10px}
.pfaq_wrap .btn-area-bottom {width:100%;position:absolute;bottom:0}

/* main */
.main-content{position:relative;height:100vh;}
.slide_box {position:absolute;left:0;top:0;right:0;bottom:0;height:100%;width:100%;display:block;overflow:hidden;background-size:cover;background-position:50%}
.slidelst {position:absolute;top:0;left:0;right:0;bottom:0;height:100%;}
.slide01 {background-repeat:no-repeat;background-position:50% 50%;background-size:cover;background-image: url(../images/main/img_section_05.png)}
.slide02 {background-repeat:no-repeat;background-position:50% 50%;background-size:cover;background-image: url(../images/main/img_section_03.png)}
.slide03 {background-repeat:no-repeat;background-position:50% 50%;background-size:cover;background-image: url(../images/main/img_section_04.png)}
.main_sch_wrap {min-width:400px;width:80%;max-width:750px;position:absolute;top:43%;left:50%;transform:translate(-50%, -65%);z-index:10;}
.main-tit {padding-bottom:30px}
.main-tit .text01 {font-size:50px;color:rgb(255,255,255);}
.main-tit .text02 {font-size:65px;color:rgb(255,255,255);line-height:1;letter-spacing:-.9px}
.main-tit .text02 b {color:rgb(255,210,0)}
.main-content .searchbox {position:relative;background:#fff;height:70px;padding:10px 20px 10px 20px;box-sizing:border-box;margin-top:-1px}
.main-content .searchbox form {height:100%}
.main-content .searchbox .sch_inp {height:50px}
.main-content .searchbox .inp {width:100%;height:50px;font-size:20px;border:none;}
.main-content .searchbox .inp-textbox .btn-sch {top:15px;} 
.box-list {max-width:100%;min-width:100%;max-height:200px;box-sizing:border-box;background:#fff;position:absolute;left:0;overflow-y:auto;top:69px;}
.box-list .sch_word {display:block;width:100%;padding:10px 20px;position:relative;font-size:15px;box-sizing:border-box}
.main_sch_wrap .box-list a:hover {text-decoration:none;background:#7a1d05;color:#fff}
.box-list a .address {color:#888;position:absolute;right:20px;font-size:13px;top:50%;transform:translatey(-50%)}
.main_sch_wrap .box-list a:hover .address {color:#fff}
.sec_cont {width:750px;height:150px;z-index:1;background-image:url(../images/main/txt_sec01.png);background-repeat:no-repeat;background-size:contain;margin-bottom:25px}
.sc_tit {font-size:44px;font-weight:500;text-align:center;}
.sc_dec {font-size:20px;font-weight:300;text-align:center;color:#1e1e1e;padding:30px 0 40px}
.sec_box .add_t {color:#666;font-size:15px;margin-top:15px;text-align:center}
.sec_box .add_t button {padding:5px 15px;font-size:14px;margin-left:10px}
.vegas-overlay {background:none !important}
.ban_right {position:fixed;right:10px;top:200px;width:185px;height:230px;background:url(../images/main/banner.png) no-repeat;text-indent:-9999px;z-index:900}
.sub_section .box-inner {padding:0 30px}
.head-tit {font-size:50px;padding-bottom:0;margin-bottom:40px;position:relative;font-weight:300}
/*.head-tit:after {content: '';posivtion:absolute;bottom:-10px;left:50%;width:16px;height:16px;background:url("../images/main/ico_diag.png") no-repeat}*/
.sub_section .submain-box:last-child {/*background:#f7f7f7;*/padding-bottom:70px;/*margin-top:120px*/}
/*.sub_section .submain-box:last-child .box-inner {padding-top:100px}*/
.sc_dec_s {margin-top:30px;}
.main_sch_wrap .sc_dec_s {color:#fff;margin-top:20px;font-weight:500;font-size:16px}
.main_sch_wrap .sc_dec_s b {color:#fad200}
.main .scdec_img {position:relative;max-width:800px;display:inline-block}
@keyframes zoomIn { 
	0% { 
		opacity: 0; 
		transform: scale3d(.3, .3, .3); 
	} 
	50% { 
		opacity: 1; 
	} 
} 
.animated {animation-duration:1s;animation-timing-function:ease-in-out;animation-iteration-count:1;animation-fill-mode:forwards;animation-name:zoomIn;} 
.circle01 {width:114px;height:114px;background-color:#6a0000;border-radius:300px;display:block;position:absolute;top:150px;right:220px;opacity:0.4;animation-delay:1s;}
.circle02 {width:70px;height:70px;background-color:#3c52c4;border-radius:300px;display:block;position:absolute;top:190px;right:190px;opacity:0.4;animation-delay:1s;}
.circle03 {width:114px;height:114px;background-color:#e3441b;border-radius:300px;display:block;position:absolute;top:202px;right:100px;opacity:0.4;animation-delay:1s;}
.circle04 {width:78px;height:78px;background-color:#791900;border-radius:300px;display:block;position:absolute;top:148px;right:70px;opacity:0.4;animation-delay:1s;}
.circle05 {width:64px;height:64px;background-color:#3b1e1e;border-radius:300px;display:block;position:absolute;top:330px;right:20px;opacity:0.4;animation-delay: 1s;}
.circle06 {width:150px;height:150px;background:url(../images/main/img_bubble01.png) no-repeat;display:block;position:absolute;top:50px;right:-30px;animation-delay: 2s;}
.circle07 {width:177px;height:177px;background:url(../images/main/img_bubble02.png) no-repeat;display:block;position:absolute;top:180px;right:-40px;animation-delay: 2.5s;}
.circle08 {width:199px;height:199px;background:url(../images/main/img_bubble03.png) no-repeat;display:block;position:absolute;top:220px;right:150px;animation-delay: 3s;}
.submain-box .box-inner .add_txt {margin-top:24px;line-height:1.2}
.submain-box .box-inner .add_txt .btn-xsmall {font-size:13px;padding:3px 5px;margin-left:5px;vertical-align:middle}

@media screen and (max-width: 768px) {
.main_sch_wrap {width:80%}
.box-list a .address {display:block;position:static}
}
small.more_s {font-size:42px;line-height:1.5}



/* 회사소개 */
.company-imgbox01 {position:relative;height:490px;width:100%;background:url("../images/main/img_company02.png") no-repeat 50% 0;background-size:cover;}
.company-imgbox01 .box-inner {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.company-imgbox01 .box-content {width:389px;height:126px;background:url("../images/main/img_company01.png") no-repeat center center;}
.lst-special {margin-bottom:50px}
.lst-special:after {display:block;content:'';clear:both}
.lst-special li {display:inline-block;float:left;width:calc(100% / 4)}
.img-special {display:block;padding-bottom:20px}
.ico-spcail01 {display:inline-block;width:56px;height:56px;background:url(../images/main/ico_special01.png)}
.ico-spcail02 {display:inline-block;width:56px;height:56px;background:url(../images/main/ico_special02.png)}
.ico-spcail03 {display:inline-block;width:56px;height:56px;background:url(../images/main/ico_special03.png)}
.ico-spcail04 {display:inline-block;width:56px;height:56px;background:url(../images/main/ico_special04.png)}
.txt-special {font-size:18px;;display:block;padding-bottom:50px}
.company-imgbox02 {position:relative;height:400px;width:100%;background:url("../images/main/img_company03.png") no-repeat 50% 0;background-size:cover;}
.company-imgbox02 .box-inner {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.company-imgbox02 .box-content {width:498px;height:126px;background:url("../images/main/img_company04.png") no-repeat center center;}
.company-dec {font-size:24px;line-height:1.6}
.company .submain-box:last-child {background:#7a1d05;color:#fff}
.company .submain-box:last-child .company-dec {color:#fff}
@media screen and (max-width: 768px) {
.lst-special li {width:calc(100% / 2)}
}

.vegas-timer {display:none}

.inquery {border-top:1px solid #1e1e1e;border-bottom:1px solid #1e1e1e;padding:20px 0}
.row {padding:10px 20px;display:table;width:100%}
.row .lbl {display:table-cell;/*margin-right:30px;*/width:20%;vertical-align:middle}
.row .ins {display:table-cell;width:80%;vertical-align:middle;position:relative}
@media screen and (max-width: 800px) {
.row .lbl {width:100%;display:block;padding:0 0 15px;}
.row .ins {width:100%;display:block;}
}

/* 집내놓기, 중개사회원가입 메인 */
#dawinWrap #wrap.joinagent-main {height:auto;margin-top:0;min-height:100%;}
#dawinWrap #wrap.joinagent {margin-top:0;min-height:100%;}
.submain-img-box {position:relative;}
.box-inner .btn-box {text-align:center;display:inline-block}
.box-inner {max-width:1200px;min-width:400px;text-align:center;position:absolute;top:50%;left:10%;transform:translatey(-50%);z-index:6;margin:0;padding:0}
.sell-imgbox01 .box-inner, .ag-imgbox01 .box-inner {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.sell-imgbox01 {width:100%;height:640px;background: url(../images/sub/img_submain01.png) no-repeat 50% 0;background-size:cover;min-height:550px}
.sell-imgbox01 .box-content {width:856px;height:332px;background:url(../images/sub/img_submain02.png) no-repeat;margin-bottom:60px}
.submain-img-box .box-inner .addexp {color:#fff;font-size:15px;padding-top:20px;font-weight:300}
.submain-img-box .box-inner .addexp b {color:#fec500}
.scdec_img img {max-width:100%;height:auto}
.ag-imgbox08 {background:#f9f9f9;height:400px;}
.ag-imgbox01 .box-content {width:814px;height:275px;background:url(../images/sub/img_submain08.png) no-repeat;margin-bottom:50px}
.submain-box.ag-imgbox05 .box-inner {padding-top:70px}
.ag-imgbox06 p, .ag-imgbox07 p {color:#fff}
.submain-box.ag-imgbox09 {padding:30px 0}
.submain-box.ag-imgbox09 .m_exp {text-align:center}
.ag-imgbox01 {height:600px;width:100%;background:url("../images/sub/img_joinag01.png") no-repeat 50% 0;background-size:cover;}
/*.ag-imgbox02 {position:relative;width:100%;height:509px;background-image:url(../images/sub/img_joinag05.png);background-repeat:no-repeat;background-position:50% 0;background-size:cover;}*/
/*.ag-imgbox03 {position:relative;width:100%;height:630px;background-image:url(../images/sub/img_joinag03.png);background-repeat:no-repeat;background-position:50% 0;background-size:cover;}*/
.ag-imgbox04 {position:relative;width:100%;height:561px;background-image:url(../images/sub/img_joinag02.png);background-repeat:no-repeat;background-position:50% 0;background-size:cover;}
.ag-imgbox05 {position:relative;width:100%;height:400px;background:#791d04}
.submain-box .btn sub, .submain-img-box .btn sub {display:block;font-size:14px;font-weight:300;color:#fed700}
.joinagent .box-inner .sc_tit, .joinagent .box-inner .sc_dec {text-align:left;}
.ag-imgbox05 .box-inner .sc_tit {text-align:center;color:#fff;font-size:40px;line-height:1.2}
.ag-imgbox05 .box-inner .sc_dec {text-align:center;color:#fff;font-size:20px}
.joinagent .ag-imgbox02 .box-inner, .joinagent .ag-imgbox03 .box-inner, .joinagent .ag-imgbox04 .box-inner {position:absolute;top:50%;left:10%;transform:translatey(-50%);padding-top:0}
.submain-box:last-child {padding-bottom:100px}
.submain-box .box-inner {max-width:1200px;text-align: center;margin: 0 auto;padding-top: 120px;position: inherit;top: inherit;left: inherit;transform: inherit;}
.sc_dec .emp_naver {color:#00cd39;}
.sc_dec .emp_naver:before {display:inline-block;content:'';width:20px;height:20px;background:url(../images/sub/ico_n.png) no-repeat;background-size:contain;margin-right:2px;vertical-align:middle;padding-top:4px;}
.joinagent-main .submain-img-box.share-imgbox01 {position:relative;height:490px;width:100%;background:url("../images/sub/img_share01.png") no-repeat 50% 0;background-size:cover;}
.share-imgbox01 .box-inner {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.joinagent-main .submain-img-box.share-imgbox01 .box-content {width:436px;height:154px;background:url(../images/sub/img_share02.png) no-repeat center center;margin:0 auto 50px}
.joinagent-main .submain-box:nth-child(2).share-imgbox02 {position:relative;width:100%;padding:80px 0;background:#505050}
.share-imgbox02 .box-inner {padding-top:0}
.share-imgbox02 .box-inner .sc_tit {text-align:center;color:#fff;font-size:26px;}
.share-imgbox02 .box-inner .sc_dec {text-align:center;color:#fff;font-size:16px;padding-bottom:0;padding-top:0}
.share-imgbox02 .box-inner .sc_dec span {display:block;}
.share-imgbox02 .box-inner .sc_dec span:last-child {margin-bottom:0}
.share-imgbox02 .box-inner .sc_dec span:first-child {font-size:24px;margin-bottom:10px}
.share-imgbox02 .box-inner .sc_dec_s {color:#fff;font-weight:300}
.share-imgbox03 {position:relative;height:500px;width:100%;background:url(../images/sub/img_map.png) no-repeat;background-size:cover;background-position:50% 50%;}
.joinagent-main .submain-box:nth-child(4).share-imgbox03 {background:#fff}
.pin-office {background:url("../images/sub/ico_pointer.png") no-repeat;width:30px;height:43px;position:absolute;}
.pin-office span {color:#fff;display:inline-block;padding:2px 5px;background:rgb(0,0,0,0.6  );position:absolute;font-size:11px;min-width:40px;left:50%;top:-25px;transform:translatex(-50%);border-radius:2px;border:1px solid #000;box-sizing:border-box;text-align:center;}
.joinagent-main .share-imgbox04 {background:#f0f0f0;}
.share-imgbox04 .box-inner {max-width:1000px;padding:80px 0}
.joinagent .share-imgbox04 .box-inner .sc_tit {text-align:center;margin-bottom:30px}
.share-imgbox04 .sc_dec {position:relative;}
.share-imgbox04 .pagination a {top:40%}
.share-imgbox04 .pagination .btn_prev {left:5px}
.share-imgbox04 .pagination .btn_next {right:5px}
.share-imgbox04 ul {display:flex;flex-direction:row;flex-wrap:wrap;max-width:1000px;overflow:hidden;margin:0 -6px}
.share-imgbox04 ul li {display:block;padding:0;box-sizing:border-box;background:#fff;margin:6px;flex:1;min-width:calc((100% / 3) - 12px);float:left;}
.share-imgbox04 ul li a {display:block;width:100%;height:100%;padding:20px;box-sizing:border-box}
.thumb_office {width:100%;height:100%}
.thumb_office.office01 {background:url("../images/sub/img_office01.png") no-repeat;width:100%;height:300px;background-size:cover;background-position:50% 0}
.thumb_office.office02 {background:url("../images/sub/img_office02.png") no-repeat;width:100%;height:300px;background-size:cover;background-position:50% 0}
.thumb_office.office03 {background:url("../images/sub/img_office03.png") no-repeat;width:100%;height:300px;background-size:cover;background-position:50% 0}
/*.info_office {padding:15px 10px;}*/
.info_office span {font-size:20px;display:block;font-weight:600;text-align:center}
.info_office span + span {font-size:15px;;font-weight:400;margin-top:5px}
.share-imgbox04 .btn-horiz {margin-top:40px}
.submain-box .m_exp {text-align:left}
/*.submain-img-box .btn-big, .submain-img-box .row .btn-big {width:400px;height:60px;}*/
.joinagent .share-imgbox04 .sc_dec.dec02 {text-align:center;padding-top:10px;padding-bottom:10px}

.joinagent-main .submain-img-box {height:calc(100vh - 50px);width:100%;}
.joinagent-main .submain-img-box .ja-slide01 {height:100%;width:100%;background-image:url(../images/sub/img_joinag01.png) ;background-repeat:no-repeat;background-position:50% 0;background-size: cover;/*position:relative;*/text-align:center;}
.joinagent-main .submain-img-box .ja-slide01 .box-content {width:789px;height:500px;background:url(../images/sub/img_submain08.png) no-repeat;/*margin-bottom:50px;*/display:inline-block;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
/*.joinagent-main .submain-img-box .ja-slide01 +  .btn-horiz {position:absolute;bottom:12%;left:50%;transform:translatex(-50%)}*/
.joinagent-main .submain-img-box .row {max-width:1200px;min-width:400px;text-align:center;/*position:absolute;top:45%;left:50%;transform: translate(-50%, -50%);z-index:6;margin:0;padding:0;*/height:500px;margin:0 auto;}
.joinagent-main .submain-img-box .ja-slide02 {height:100%;width:100%;background-image: url(../images/sub/bg_jabk.png);background-repeat:no-repeat;background-position:50% 0;background-size: cover;/*position:relative;*/text-align:center;}
.ja-slide02 .box-content {text-align:center;width:832px;height:500px;margin:0 auto;text-align:center;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.ja-slide02 .box-content:after {display:block;content:'';clear:both}
.ja-slide02 .box-content p:first-child {width:517px;height:217px;background:url(../images/sub/img_submain18.png) no-repeat;margin-bottom:40px;display:inline-block}
.ja-slide02 .box-content p:nth-child(2){width:416px;height:175px;background:url(../images/sub/ja01.png) no-repeat;display:inline-block;float:left}
.ja-slide02 .box-content p:nth-child(3){width:416px;height:175px;background:url(../images/sub/ja02.png) no-repeat;display:inline-block;float:left}
/*.submain-img-box .ja-slide02 + .btn-horiz {position:absolute;bottom:150px;left:50%;transform:translate(-50%)}*/
.joinagent-main .btn-horiz {position:absolute;bottom:10%;transform:translatex(-50%);left:50%;}
.joinagent-main .share-imgbox01 .btn-horiz {bottom:inherit}

.joinagent-main .submain-box:nth-child(4) {background:#f8f8f8}
/*.joinagent-main .submain-box .row {max-width:960px;display:flex;flex-wrap:wrap;margin:0 auto;box-sizing:border-box;padding:100px 0;text-align:center}*/
.joinagent-main .submain-box .row .col-left {padding-right:40px;box-sizing:border-box;width:50%;margin:auto 0}
.joinagent-main .submain-box .row .col-left p {text-align:left}
.joinagent-main .submain-box .row .col-right {width:50%;margin:auto 0}
.joinagent-main .submain-box .row .col-right img {object-fit:contain;width:100%;height:100%}
.joinagent-main .submain-box .row .m_exp {width:90%;text-align:center;font-size:16px;color:#9E9E9E;margin:0 auto}
.joinagent-main .submain-box:last-child {position:relative;width:100%;background:#791d04}
.joinagent-main .submain-box:last-child .row {display:block;padding-bottom:0}
.joinagent-main .submain-box:last-child .row .sc_tit {text-align:center;color:#fff;font-size:40px;line-height:1.2;display:block}
.joinagent-main .submain-box:last-child .row .sc_dec {text-align:center;color:#fff;font-size:22px;display:block}
.joinagent-main .submain-box:nth-child(4) .row {padding:60px 0}
.joinagent-main .submain-box:nth-child(2) {background:#f8f8f8} 
.video_layout {margin-left:100px;width:300px;height:533.333333333333333px;border-radius:52px;box-shadow:0 40px 61px 58px hsla(0,0%,93.3%,.9);background-color:#fff;display:-webkit-box;display: flex;-webkit-box-pack:center;justify-content:center;-webkit-box-align:center;align-items:center;overflow:hidden;}
.video_md {margin-top:1px;border-radius:52px;width:100%;height:100%;}
.regi-main .submain-img-box {height:100vh;width:100%;background: url(../images/sub/img_submain01.png) no-repeat 50% 0;background-size: cover;position:relative;text-align:center;}

.joinagent-main .submain-box.ja01 {background:url("../images/sub/img_ja01.png") no-repeat 50% 0;background-size:cover;padding-top: 32%;width:100%;height:0;position:relative;}
.joinagent-main .submain-box.ja02 {background:url("../images/sub/img_ja02.png") no-repeat 50% 0;background-size:cover;padding-top: 48%;width:100%;height:0;position:relative;}
.joinagent-main .submain-box.ja03 {background:#fff;/*url("../images/sub/img_ja03.png") no-repeat 50% 0;*/background-size:cover;padding-top:30%;width:100%;height:0;position:relative;}
.joinagent-main .submain-box.ja04 {background:url("../images/sub/img_ja04.png") no-repeat 50% 0;background-size:cover;padding-top: 45%;width:100%;height:0;position:relative;}
.joinagent-main .submain-box.ja05 {background:url("../images/sub/img_ja05.png") no-repeat 50% 0;background-size:cover;padding-top: 34%;width:100%;height:0;position:relative;}
.joinagent-main .submain-box.ja06 {padding-top:10%;width:100%;height:0;position:relative;}
.joinagent-main .submain-box.ja07 {padding-top:18%;width:100%;height:0;position:relative;background:#791d04}
.joinagent-main .submain-box .row {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);text-align:center}
.joinagent-main .submain-box.ja02 .row {top:35%}
.joinagent-main .submain-box.ja03 .row {top:50%}
.joinagent-main .submain-box.ja04 .row {top:35%}
.joinagent-main .submain-box.ja04 .row .sc_tit, .joinagent-main .submain-box.ja04 .row .sc_dec, .joinagent-main .submain-box.ja05 .row .sc_dec, .joinagent-main .submain-box.ja05 .row .sc_tit {color:#fff}
.joinagent-main .submain-box .row .sc_tit {font-size:36px}
.joinagent-main .submain-box.share-imgbox04 {background:#eaeaea;padding-bottom:30px}
/* .quickbn {width:165px;height:240px;background:url(../images/sub/img_quickbn.png) no-repeat;position:fixed;right:20px;top:360px;z-index:5;text-indent:-9999px;cursor:pointer} */
.joinagent-main .sc_dec {font-weight:400;font-size:16px;padding:16px 0 24px}
.sc_dec .add {display:block;margin-top:15px;font-size:14px;color:#000}
.sc_dec .add:before {display:inline-block;content:'*';margin-right:3px;vertical-align: middle;font-size:14px}
.sc_dec .add strong {font-weight:500}
.submain-img-box .btn-area-bottom p {color:#fff;font-size:16px;}
.submain-wrap {position:relative}
/* .quickbn.top {background:url(../images/sub/img_quickbn05.png) no-repeat;height:300px;top:145px;background-size:contain;} */
.joinagent-main .btn-xsmall {font-size:14px;margin-top:8px;}
.joinagent-main .submain-box:last-child .row .sc_dec span {display:block;margin-bottom:8px;}
/* .quickbn.bn_start {background:url(../images/sub/img_quickbn04.png) no-repeat;height:200px;top:410px;} */
.joinagent-main .submain-box.ja07 .row .sc_dec span {display:block;margin-bottom:8px;color:#fff;}

/*창업지원프로그램*/
.joinagent-main .submain-img-box .ja-slide03 {height:100%;width:100%;background-image: url(../images/sub/bg_jastartup.png);background-repeat:no-repeat;background-position:50% 0;background-size: cover;/*position:relative;*/text-align:center;}
.ja-slide03 .box-content {text-align:center;width:832px;height:380px;margin:0 auto;text-align:center;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.ja-slide03 .box-content p {width:785px;height: 357px;background: url(../images/sub/img_submain19.png) no-repeat;margin-bottom:40px;display:inline-block;}
.joinagent-main .submain-box.stup01 {background:#fff;padding-top: 28%;width:100%;height:0;position:relative;max-width:800px;margin:0 auto;padding-bottom:100px;}
.stup01 .stuplst {display:flex;flex-direction:row;flex-wrap:nowrap;}
.stup01 .stuplst li {width:50%;text-align:center;padding-bottom:30px}
.stup01 .stuplst li .title {font-size:22px;font-weight:bold;padding-top:150px;position:relative}
.stup01 .stuplst li:first-child .title:before {display:block;content:'';background:url(../images/sub/ico_bk01.png) no-repeat;height:100px;width:100px;background-size:contain;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.stup01 .stuplst li .text01 {font-size:17px;margin-top:10px;}
.stup01 .stuplst li:last-child .title:before {display:block;content:'';background:url(../images/sub/ico_bk02.png) no-repeat;height:100px;width:100px;background-size:contain;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}


@media screen and (max-width: 768px) {
.sell-imgbox01 .box-content {width:600px;height:260px;background-size:contain;background-position:center}
.box-inner .sc_tit {font-size:24px}
.box-inner .sc_dec {font-size:18px}
.sc_dec_img {width:800px}
.sc_dec_img img {width:100%}
.ag-imgbox02 {min-height:750px;box-sizing:border-box;background:#fff url(../images/sub/img_joinag02_m.png) no-repeat;background-position:50% 0;background-size:cover;}
.ag-imgbox03 {min-height:750px;box-sizing:border-box;background:#fff url(../images/sub/img_joinag03_m.png) no-repeat;background-position:50% 0;background-size:cover;}
.ag-imgbox04 {min-height:750px;box-sizing:border-box;background:#fff url(../images/sub/img_joinag04_m.png) no-repeat;background-position:50% 0;background-size:cover;}
.submain-box .box-inner .sc_tit, .submain-box .box-inner .sc_dec {text-align:center}
.joinagent .ag-imgbox02 .box-inner, .joinagent .ag-imgbox03 .box-inner, .joinagent .ag-imgbox04 .box-inner  {position:static;transform:none;padding-top:50px}
.ag-imgbox01 .box-content {width:660px;height:180px;background-size:contain}

.share-imgbox04 ul {display:block;flex-direction:row}  
.share-imgbox04 ul li {width:100%;padding:0 20px;box-sizing:border-box;} 

.joinagent-main .submain-box .row {max-width:960px;display:block;margin:0 auto;box-sizing:border-box;padding:100px 0 100px;text-align:center}
.joinagent-main .submain-box .row .col-left {display:block;width:100%;padding:0 50px 20px;margin:0 auto}
.joinagent-main .submain-box .row .col-left p {text-align:center}
.joinagent-main .submain-box .row .col-right {display:block;width:100%;padding:0 50px;margin:0 auto}
}

/* 중개사회원가입 */
.ag-sub-header {height:160px;background-image:url(../images/sub/img_broker.png);background-repeat:no-repeat;background-position:50%;background-size:cover;position:relative;}
.sub-header-inner {max-width:1050px;padding:0 20px;/*min-width:750px;*/height:100%;box-sizing:border-box;padding-top:60px;margin:0 auto}
.cont_wrap {padding-bottom:100px}
.cont_inner {max-width:1050px;height:100%;box-sizing:border-box;padding-top:60px;margin:0 auto;position:relative}
.cont_tit {font-size:30px;color:#fff;text-align:center;padding-bottom:36px;font-weight:500;}
h3.tit {font-size:25px;font-weight:600;margin-bottom:20px;color:#1e1e1e;letter-spacing:-1.5px;}
h2.subj_tit {font-size:25px;font-weight:600;margin-bottom:20px;color:#1e1e1e;letter-spacing:-1.5px;}
.process {margin-top:10px;padding:0 0 50px;text-align:center}
.process .tab {overflow:hidden;display:inline-block}
.process li {display:inline-block;float:left;position:relative;width:60px;height:60px;margin:0 70px;padding-bottom:30px;vertical-align:top;}
.process li:before {content: '';display:inline-block;position:absolute;right:100%;top:30px;margin-right:40px;width:70px;height:1px;background-color:#eee;}
.process li:first-child:before {display:none}
.process li a {display:block;position:absolute;left:50%;font-size:15px;white-space:nowrap;font-weight:500;text-align:center;-webkit-transform:translateX(-50%);transform: translateX(-50%);color:#ccc;cursor:default;}
.process li a:before {display:block;width:60px;height:60px;content:'';margin:0 auto;margin-bottom:10px} 
.process li a.on {color:#222}
.process li .proc01:before {background:url(../images/sub/img_jb_proc01.png) no-repeat;}
.process li .proc02:before {background:url(../images/sub/img_jb_proc02.png) no-repeat;}
.process li .proc03:before {background:url(../images/sub/img_jb_proc03.png) no-repeat;}
.process li .proc04:before {background:url(../images/sub/img_jb_proc04.png) no-repeat;}
.process li .proc01.on:before {background:url(../images/sub/img_jb_proc01on.png) no-repeat;}
.process li .proc02.on:before {background:url(../images/sub/img_jb_proc02on.png) no-repeat;}
.process li .proc03.on:before {background:url(../images/sub/img_jb_proc03on.png) no-repeat;}
.process li .proc04.on:before {background:url(../images/sub/img_jb_proc04on.png) no-repeat;}
.tab_cont {padding:0 30px;}
.agtype-tit {font-size:25px;padding:30px 0;text-align:center}
.selec-agtype {margin:0 auto;text-align:center;}
.selec-agtype .radio-box02 {display:inline-block;padding-right:1px;width:100%;}
.selec-agtype .radio-box02>.rd01 {width:calc(100% / 3);margin:0 -1px -1px 0;padding-bottom:1px}
.selec-agtype .radio-box02>.rd01>input[type=radio] + label {width:100%;height:100px;font-size:22px;line-height:95px;padding:0}
.selec-agtype .radio-box02>.rd01>input[type=radio] + label small {font-size:15px;}
.agtype-notice {text-align:left;font-size:17px;;border:1px solid #cdcdcd;background:#f9f9f9;padding:40px}
.check-box .impt {font-size:18px;line-height:20px;}
.term_box {border:1px solid #b9b9b9;padding:20px;font-size:13px;margin-bottom:20px;margin-top:20px;}
.term_box .t_cont {max-height:300px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);line-height:1.6}
.term_box + .check-box {margin-top:30px}
.term_box .chk_box {margin-top:0}
.term_box .btn-box {margin-top:5px;}
.t_wrap .check-box {padding-bottom:10px}
.phar {padding-left:15px}
.term_tbl {padding:10px 0;}
.term_tbl table {font-size:13px;}
.term_tbl .tbl_type01 td {line-height:1.5}
.cont_inner .btn-horiz {padding-top:60px}
.inp-group + .inp-textbox {margin-top:10px}
.add_txt, .m_exp {margin-bottom:15px;font-size:14px;line-height:1.6}
.add_text {font-size:13px;color:#888;margin-top:5px;}
.term_tbl .add_txt {font-size:12px;margin-top:5px;margin-bottom:0}
h2 .m_exp {font-weight:400;margin-top:20px}
.inquery {padding-top:0;padding-bottom:0}
.row {display:table;border-bottom:1px solid #d9d9d9;padding:12px 10px}
.row:last-child {border-bottom:none}
.row .btn-primary {width:300px;}
.btn-file-wrap .btn-del {position:absolute;top:5px;right:5px;z-index:3}
.ins .add_t {color:#888;display:block}
.add_t {font-size:13px;color:#CF1C3E;margin-top:5px}
.btn_s_sch {position:absolute;right:10px;top:14px;background:url(../images/sub/btn_s_sch.png) no-repeat;width:20px;height:17px}
.file_txt {position:absolute;left:15px;top:9px}
.lbl .exp {color:red;font-size:12px;font-style:normal;display:block}
.finish_text, .compl_text {padding:50px 0 100px}
.ins .box-list {max-width:100%;background:#fff;z-index:10;top:45px;border:1px solid #b9b9b9;padding:10px}
.ins .box-list .sch_word {padding:0;margin:4px 0}
.btn-file-round .preview {border-radius:300px}
.btn-file-round .preview img {width:100%;height:100%;border-radius:300px;}
.ins .notice {font-size:12px;color:#666;margin-top:10px}
.btn-area {text-align:center;}
/*.btn-area .btn-group {display:inline-block}*/
.add-text {font-size:13px;color:#afafaf;margin:5px 0}
.finish_text .ntc_p {font-size:18px}
.finish_text .ntc_p big {font-size:34px}
.regi .row .radio-box02>.rd01>input[type=radio] + label, .regi .row .radio-box02.three>.rd01>input[type=radio] + label {width:inherit}

.joinagent .row .inp-horiz + .inp-group {margin-top:10px;}
.joinagent .row .inp-textbox .btn-img {top:14px;}
.joinagent .row .maparea {margin-bottom:10px;}
.joinagent .row #address_detail {margin-top:10px;}

/* 집내놓기_매물정보 */
.sell-sub-header {height:160px;background-image:url(../images/sub/img_regi.png);background-repeat:no-repeat;background-position:50%;background-size:cover;position:relative;}
.regi-process li .proc01:before {background:url(../images/sub/img_jb_proc01.png) no-repeat;}
.regi-process li .proc02:before {background:url(../images/sub/img_jb_proc02.png) no-repeat;}
.regi-process li .proc03:before {background:url(../images/sub/img_regi_proc03.png) no-repeat;}
.regi-process li .proc04:before {background:url(../images/sub/img_regi_proc05.png) no-repeat;}
.regi-process li .proc01.on:before {background:url(../images/sub/img_jb_proc01on.png) no-repeat;}
.regi-process li .proc02.on:before {background:url(../images/sub/img_jb_proc02on.png) no-repeat;}
.regi-process li .proc03.on:before {background:url(../images/sub/img_regi_proc03on.png) no-repeat;}
.regi-process li .proc04.on:before {background:url(../images/sub/img_regi_proc05on.png) no-repeat;}
.tab-box {margin-bottom:50px}
.datepick {position:absolute;left:235px;top:0}
.maparea {position:relative;width:100%;height:400px;border:1px solid #b9b9b9;box-sizing:border-box;margin-top:10px}
.maparea img {width:100%;height:100%;object-fit:contain;object-position:50%}
.maparea .map_bg {background:url(../images/sub/img_map_ex02.png) no-repeat;width:100%;height:100%;background-size:cover;position:absolute;top:0;left:0;right:0;bottom:0}
.map-pointer {position:absolute;top:100px;left:300px;z-index:50;display:block;background:url(../images/sub/ico_pointer.png) no-repeat;background-size:contain;width:25px;height:35px}
.host_contact {margin-top:10px;overflow:hidden}
.host_contact .sellinp:last-child {width:400px}
.host_contact .sellinp:last-child .inpout {width:400px}
.spantxt {width:100px;display:inline-block;float:left;vertical-align:middle;line-height:45px}
.add_t_area {width:100%;margin-top:10px;padding:20px;background:#f9f9f9;position:relative}
.fix_t {position:absolute;right:16px;top:18px}
.inp-horiz .inp + .addinp_t {margin-left:-5px;line-height:46px;margin-right:5px}
.go-real {position:absolute;right:0;top:5px;width:auto}
.go_area {position:absolute;right:0;top:13px;display:inline-block;font-size:14px;padding-right:20px;background: url(../images/main/btn_arr_rights.png) no-repeat center right}
.t_kr {display:inline-block;color:#bababa;line-height:46px;white-space:nowrap;text-overflow:ellipsis;text-align:right;}
.ins .maparea img {object-fit:contain;}
.ins form {position:relative}
.row .col-box .col:first-child {width:15%}
.col-box {display:flex;flex:auto;flex-wrap:wrap;align-items:center;}
.col-box .col {display:inline-block;vertical-align:middle}
.col-box + .col-box {margin-top:10px}
.col .inp, .col .addinp_t, .col .t_kr {display:inline-block;vertical-align:middle;}
.col .addinp_t {margin:0 5px}
.regi .col .check {margin-bottom:0}
.regi h3.subj_tit {font-size:22px;margin-bottom:5px;}
.inquery .col .inpbox, .inquery .col .inp-group {display:flex}
.inquery .col .inp-textbox {flex:none}
.inquery .kr {line-height:46px;margin-left:10px;color:#bababa}
.addinp_t {line-height:46px;padding:0 5px;}
.regi .panel-group {position:fixed;top:0;z-index:1000;height:100%;border-left:1px solid #efefef}
.fix .unit {z-index:-1}

@media screen and (max-width: 800px) {
.go-real {position:static;display:block;margin-top:10px}
}

/* 집내놓기_매물옵션 */
.ins .dateinp {margin-top:10px;width:300px;height:46px;display:inline-block}
.datepick .inp {width:180px}
.datepick::after {display:inline-block;content: '이후';margin-left:5px}
.devide01 {display:inline-block;float:left;padding:0 7px;line-height:35px;margin:5px 0}
.regi .check {display:block;margin-bottom:10px}
.check-box.chk-block .check {display:block;margin-bottom:8px}
.process + .tab_cont .check-box.chk-block {margin-bottom:0;width:100%}


.check-box03.chk_opt > .chk01 > input[type=checkbox] + label {height:auto;text-align:center;padding:10px;line-height:2}
.check-box03.chk_opt > .chk01 > input[type=checkbox] + label::before {display:block;content:'';height:38px;width:100%;margin-top:5px;} 
#opt01 + label::before {background:url(../images/sub/opt01.svg) no-repeat 50%;background-size:contain;}
#opt01:checked + label::before {background:url(../images/sub/opt01_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt02 + label::before {background:url(../images/sub/opt02.svg) no-repeat 50%;background-size:contain;}
#opt02:checked + label::before {background:url(../images/sub/opt02_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt03 + label::before {background:url(../images/sub/opt03.svg) no-repeat 50%;background-size:contain;}
#opt03:checked + label::before {background:url(../images/sub/opt03_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt04 + label::before {background:url(../images/sub/opt04.svg) no-repeat 50%;background-size:contain;}
#opt04:checked + label::before {background:url(../images/sub/opt04_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt05 + label::before {background:url(../images/sub/opt05.svg) no-repeat 50%;background-size:contain;}
#opt05:checked + label::before {background:url(../images/sub/opt05_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt06 + label::before {background:url(../images/sub/opt06.svg) no-repeat 50%;background-size:contain;}
#opt06:checked + label::before {background:url(../images/sub/opt06_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt07 + label::before {background:url(../images/sub/opt07.svg) no-repeat 50%;background-size:contain;}
#opt07:checked + label::before {background:url(../images/sub/opt07_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt08 + label::before {background:url(../images/sub/opt08.svg) no-repeat 50%;background-size:contain;}
#opt08:checked + label::before {background:url(../images/sub/opt08_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt09 + label::before {background:url(../images/sub/opt09.svg) no-repeat 50%;background-size:contain;}
#opt09:checked + label::before {background:url(../images/sub/opt09_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt10 + label::before {background:url(../images/sub/opt10.svg) no-repeat 50%;background-size:contain;}
#opt10:checked + label::before {background:url(../images/sub/opt10_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt11 + label::before {background:url(../images/sub/opt11.svg) no-repeat 50%;background-size:contain;}
#opt11:checked + label::before {background:url(../images/sub/opt11_on.svg) no-repeat 50% 50%;background-size:contain;}
#opt16 + label::before {background:url(../images/sub/opt16.svg) no-repeat 50%;background-size:contain;}
#opt16:checked + label::before {background:url(../images/sub/opt16_on.svg) no-repeat 50% 50%;background-size:contain;}



.check-box03 > .chk01 > input[type=checkbox].chk_danjiinfo + label {height:auto;text-align:center;padding:10px;line-height:2}
.chk_danjiinfo + label::before {display:block;content:'';height:38px;width:100%;margin-top:5px;} 
#facility_FIT + label::before {background:url(../images/sub/img_danjiinfo01.svg) no-repeat 50%;background-size:contain;}
#facility_FIT:checked + label::before {background:url(../images/sub/img_danjiinfo01_on.svg) no-repeat 50% 50%;background-size:contain;}
#facility_GX + label::before {background:url(../images/sub/img_danjiinfo02.svg) no-repeat 50%;background-size:contain;}
#facility_GX:checked + label::before {background:url(../images/sub/img_danjiinfo02_on.png) no-repeat 50% 50%;background-size:contain;}
#facility_STU + label::before {background:url(../images/sub/img_danjiinfo03.svg) no-repeat 50%;background-size:contain;}
#facility_STU:checked + label::before {background:url(../images/sub/img_danjiinfo03_on.svg) no-repeat 50% 50%;background-size:contain;}
#facility_LIB + label::before {background:url(../images/sub/img_danjiinfo04.svg) no-repeat 50%;background-size:contain;}
#facility_LIB:checked + label::before {background:url(../images/sub/img_danjiinfo04_on.svg) no-repeat 50% 50%;background-size:contain;}
#facility_POO + label::before {background:url(../images/sub/img_danjiinfo05.svg) no-repeat 50%;background-size:contain;}
#facility_POO:checked + label::before {background:url(../images/sub/img_danjiinfo05_on.svg) no-repeat 50% 50%;background-size:contain;}
#facility_SAU + label::before {background:url(../images/sub/img_danjiinfo06.svg) no-repeat 50%;background-size:contain;}
#facility_SAU:checked + label::before {background:url(../images/sub/img_danjiinfo06_on.svg) no-repeat 50% 50%;background-size:contain;}
#facility_GOL + label::before {background:url(../images/sub/img_danjiinfo07.svg) no-repeat 50%;background-size:contain;}
#facility_GOL:checked + label::before {background:url(../images/sub/img_danjiinfo07_on.svg) no-repeat 50% 50%;background-size:contain;}   
#facility_GUH + label::before {background:url(../images/sub/img_danjiinfo08.svg) no-repeat 50%;background-size:contain;}
#facility_GUH:checked + label::before {background:url(../images/sub/img_danjiinfo08_on.svg) no-repeat 50% 50%;background-size:contain;} 
/*
.danjiinfo09 + label::before {background:url(../images/sub/img_danjiinfo09.svg) no-repeat 50%;background-size:contain;}
.danjiinfo09:checked + label::before {background:url(../images/sub/img_danjiinfo09_on.svg) no-repeat 50% 50%;background-size:contain;}
*/
#facility_DLB + label::before {background:url(../images/sub/img_danjiinfo10.svg) no-repeat 50%;background-size:contain;}
#facility_DLB:checked + label::before {background:url(../images/sub/img_danjiinfo10_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_CAF + label::before {background:url(../images/sub/img_danjiinfo11.svg) no-repeat 50%;background-size:contain;}
#facility_CAF:checked + label::before {background:url(../images/sub/img_danjiinfo11_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_KCF + label::before {background:url(../images/sub/img_danjiinfo12.svg) no-repeat 50%;background-size:contain;}
#facility_KCF:checked + label::before {background:url(../images/sub/img_danjiinfo12_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_WP + label::before {background:url(../images/sub/img_danjiinfo13.svg) no-repeat 50%;background-size:contain;}
#facility_WP:checked + label::before {background:url(../images/sub/img_danjiinfo13_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_MUL + label::before {background:url(../images/sub/img_danjiinfo14.svg) no-repeat 50%;background-size:contain;}
#facility_MUL:checked + label::before {background:url(../images/sub/img_danjiinfo14_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_THE + label::before {background:url(../images/sub/img_danjiinfo15.svg) no-repeat 50%;background-size:contain;}
#facility_THE:checked + label::before {background:url(../images/sub/img_danjiinfo15_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_RES + label::before {background:url(../images/sub/img_danjiinfo16.svg) no-repeat 50%;background-size:contain;}
#facility_RES:checked + label::before {background:url(../images/sub/img_danjiinfo16_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_LOU + label::before {background:url(../images/sub/img_danjiinfo17.svg) no-repeat 50%;background-size:contain;}
#facility_LOU:checked + label::before {background:url(../images/sub/img_danjiinfo17_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_SEN + label::before {background:url(../images/sub/img_danjiinfo18.svg) no-repeat 50%;background-size:contain;}
#facility_SEN:checked + label::before {background:url(../images/sub/img_danjiinfo18_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_BAD + label::before {background:url(../images/sub/img_danjiinfo19.svg) no-repeat 50%;background-size:contain;}
#facility_BAD:checked + label::before {background:url(../images/sub/img_danjiinfo19_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_TAT + label::before {background:url(../images/sub/img_danjiinfo20.svg) no-repeat 50%;background-size:contain;}
#facility_TAT:checked + label::before {background:url(../images/sub/img_danjiinfo20_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_BAS + label::before {background:url(../images/sub/img_danjiinfo21.svg) no-repeat 50%;background-size:contain;}
#facility_BAS:checked + label::before {background:url(../images/sub/img_danjiinfo21_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_TAN + label::before {background:url(../images/sub/img_danjiinfo22.svg) no-repeat 50%;background-size:contain;}
#facility_TAN:checked + label::before {background:url(../images/sub/img_danjiinfo22_on.svg) no-repeat 50% 50%;background-size:contain;} 
#facility_STO + label::before {background:url(../images/sub/img_danjiinfo23.svg) no-repeat 50%;background-size:contain;}
#facility_STO:checked + label::before {background:url(../images/sub/img_danjiinfo23_on.svg) no-repeat 50% 50%;background-size:contain;} 


/* 매물사진등록 */
.lst_attached {display:flex;margin:5px -5px -5px;flex-wrap:wrap;flex:auto}
.lst_attached li {margin:5px;width:122px;height:122px;}
.inside.lst_attached li {margin-bottom:30px}
li.btn-file-wrap {border:1px solid rgb(208, 210, 214);margin:5px;}
.btn_file_wrap.requi {border:1px solid #e44332}
.btn_file_pic {position:absolute;top:0;right:0;bottom:0;left:0;width:120px;height: 120px;opacity:0;filter:alpha(opacity=0);-ms-filter:alpha(opacity=0);cursor:pointer;z-index:1}
.btn_file_wrap label  {display:inline-block;width:120px;height:120px;background:url(../images/sub/img_file_photo.png) no-repeat}
.att02 .btn_file_wrap {border-color:#e44332}
.att02 .btn_file_wrap:last-child {border-color:#b9b9b9}
.rep_tag {padding:3px 5px;background:#b9342a;color:#fff;position:absolute;left:0;top:0;z-index:3;font-size:12px}
.red_t {color:#e44332}
.ch_ag {/*margin-bottom:30px;*/display:inline-block}
.ch_ag .radio_box .radio:last-child {padding-right:0}
.btn-file-wrap.living {background:url(../images/sub/img_pic01.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.kitchen {background:url(../images/sub/img_pic02.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.bathroom {background:url(../images/sub/img_pic03.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.room01 {background:url(../images/sub/img_pic04.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.room02 {background:url(../images/sub/img_pic05.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.room03 {background:url(../images/sub/img_pic06.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.entrance {background:url(../images/sub/img_pic07.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.balcony {background:url(../images/sub/img_pic08.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.etc01 {background:url(../images/sub/img_pic09.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.etc02 {background:url(../images/sub/img_pic10.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.etc03 {background:url(../images/sub/img_pic11.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.etc04 {background:url(../images/sub/img_pic12.png) no-repeat;background-size:cover;background-position:center}


/* 등록성공 */
.finish_text .p_txt01, .compl_text .p_txt01 {margin-bottom:20px}

/* 책임중개사 선택 */
.sel_agent_wrap.top {padding-top:30px}
.sel_agent_wrap.bottom {padding-top:60px;clear:both}
.sel_agent_wrap.top .tit, .sel_agent_wrap.bottom .tit {margin-bottom:20px}
.pop .map-container {overflow:hidden;margin-top:20px;position:relative;}
.pop .map {background:url(../images/sub/img_map_ex02.png) no-repeat;width:100%;height:100%;background-size:cover;}
.btn_sort {position:absolute;right:0;top:-30px;z-index:20}
.btn_sort span {font-size:13px;line-height:2}
.btn_sort span:before {display:inline-block;width:1px;height:8px;margin:0 10px;background:#999;opacity:0.9;content: '';vertical-align:-1px}
.btn_sort span:first-child:before {display:none}
.agent-card {display:block;width:258px;/*height:140px;*/border:1px solid #e0e0e0;box-sizing:border-box;margin:5px;background:#fff;position:relative}
.agent-card:hover {border-color:#333}
.agent-card.on {border-color:#e44332}
.agent-card:last-child {margin-bottom:0}
.agent-card .itm_inner {padding:8px 37px 8px 8px;position:relative}
.agent_info p {line-height:1.3;font-size:14px;word-break:break-all}
.thumbnail_area {position:absolute;top:50%;transform:translatey(-50%);left:13px;width:50px;height:50px;display:inline-block}
.thumbnail_area .thumbnail {width:50px;height:50px;border-radius:60px;overflow:hidden;border:1px solid #b0b2b6}
.thumbnail_area .thumbnail img {width:100%;height:100%;object-fit:cover;object-position:50%;border-radius:60px}
.itm_inner .agent_info {display:block;padding-left:70px;/*height:90px;*/text-align:left;padding-top:5px;padding-bottom:5px}
.itm_inner .bk_name {font-size:16px;font-weight:600}
.itm_inner .p_num {font-size:14px}
.star_score {margin-bottom:2px}
.star_score span.st_off {display:inline-block;background: url(../images/sub/point0.png) no-repeat;vertical-align:middle;position:relative;width:90px;height:15px;}
.star_score span.st_on {position:absolute;z-index:10;height:19px;overflow:hidden;top:0;left:0;text-indent:-9999px;display:inline-block;background: url(../images/sub/point5.png) no-repeat;}
.selec_agent_wrap {border:1px solid #b9b9b9;width:100%;height:100%;text-align:center;padding:30px 41px;box-sizing:border-box;background:#f7f7f7;margin:0}
.selec_agent_wrap .btn-box {margin:20px 0 10px}
.agent-list {overflow:hidden;display:flex;flex-wrap:wrap;justify-content:center; margin:-5px;padding-top:30px;padding-bottom:30px}
.agent-list li {float:left;position:relative}
.agent-list li.item {display:inline-block;}
.agent-card .btn-del-small {position:absolute;top:10px;right:10px;z-index:101}
.agent-card .check-box02 i {margin-right:0}
.txt_red {color:#c22800;}
.popxlg .txt_red {font-size:12px;}
.star_score .date {display:block;margin-left:0;margin-top:8px;}
.selec_agent_wrap .rd-block .radio {display:table-cell}


/* 전화로 집내놓기 */
.regi-phone .box-inner {padding:0}
.sell-imgbox02 {width:100%;height:640px;background: url(../images/sub/img_submain17.png) no-repeat 50% 0;background-size:cover;min-height:550px}
.sell-imgbox02 .box-content {width:632px;height:275px;background:url("../images/sub/txt_sv02.png") no-repeat;background-size:contain;margin-bottom:40px}
.regi-phone .submain-box {width:100%;position: relative;padding-bottom:0}
.regi-phone .box-inner {display:flex;flex:auto;flex-direction:row;flex-wrap:wrap;max-width:100%;min-width:100%;margin:0 auto}
.regi-phone .box-inner .m_text {}
.regi-phone .box-inner .p03 {width:40%;padding:100px 40px 120px;text-align:right;background-color: #fff;float:left;font-size:25px;line-height:1.3;padding-top:80px;padding-bottom:80px;box-sizing:border-box}
.regi-phone .box-inner form {width:60%;background:#eaeaea;padding:100px 40px 120px;text-align:left;float:left;font-size:16px;line-height:1.3;padding-top:80px;padding-bottom:80px;padding-right:20%;box-sizing:border-box}
.regi-phone .box-inner .radio-box {margin-bottom:20px;font-size:18px}
.regi-phone .box-inner .p03 b {font-size:50px}
.regi-phone .box-inner .inp {height:55px;font-size:16px}
.regi-phone .box-inner .small {font-size:14px;color:#222;text-align:left;width:100%;margin-bottom:5px;margin-top:10px}
.regi-phone .box-inner .check-box {margin-top:20px;height:auto}
.regi-phone .box-inner .check-box .btn {display:inline-block;vertical-align: middle;margin-left:-20px}
.regi-phone .box-inner .btn-box {margin-top:20px;}
.regi-phone .box-inner .btn-box .btn-basic {width:200px;}
.regi-phone .check-box + div {margin-top:5px;font-size:13px;color:red}
.regi-phone .check-box strong {line-height:1.2}
.regi-phone .btn-horiz {justify-content:left;margin-top:30px}
.regi-phone .lyr .btn-horiz {justify-content:center}

@media screen and (max-width: 768px) {
.regi-phone .box-inner {flex-direction:column}
.regi-phone .box-inner .p03, .regi-phone .box-inner form {width:100%;box-sizing:border-box;padding-right:40px}
.regi-phone .box-inner .p03 {text-align:left}
.sell-imgbox02 .box-content {width:600px;background-size:contain}
	.find .analysis_lyr {top:20px;}
}

/* 집구하기 */
.find .header {}
.find .analysis_lyr {position:absolute;top:60px;left:80px;z-index:20;}

/** 필터 **/
.filter-section {position:relative;left:0;width:100%;height:44px;background:#fff;box-sizing:border-box;padding:6px 90px 4px 20px;z-index:104;border-bottom:1px solid #d9d9d9;display:flex;flex:auto;flex-wrap:wrap;}
.sch-container, .filter-container {display:inline-block;margin-right:10px;position:relative}
.filter_wrap {display:flex;flex:auto;flex-wrap:wrap;align-items:center;}
.filter_lst {position:relative;margin-right:10px;flex:0 1 auto;}
.filter_lst .btn_space_box {top:18px}
.inp-horiz .search .inp {margin-right:0}
.search .inp {width:380px;height:32px;padding-right:35px;}
.sch-container .box-list {border:1px solid #d9d9d9;max-height:350px;top:31px;padding:10px 0;max-width:550px;width:550px;z-index:1000}
.sch-container .box-list .sch_word {font-size:14px;padding:6px 16px;text-align:left}
.sch-container .box-list .sch_word:hover {background:#f0f0f0}
.sch-container .box-list a .address {font-size:12px;margin-top:2px;right:16px;top:13px}
.filter-item {display:block;border:1px solid #d9d9d9;height:32px;padding:6px 30px 8px 10px;box-sizing:border-box;font-size:13px;background:#fff url(../images/main/btn_arr_down.png) no-repeat 90%}
.ft_refresh {border:1px solid #d9d9d9;height:32px;padding-left:10px;box-sizing:border-box;padding:0 10px 0 28px;font-size:13px;background:#f9f9f9 url(../images/sub/ico_refresh.png) no-repeat 8px 8px;text-indent:inherit;line-height:2.2}
.sub_filter_group {background:#fff;border:1px solid #d9d9d9;position:absolute;top:31px;left:0;padding:50px 20px 40px;z-index:107;overflow:hidden;width:221px;}
.fd-cate {padding-bottom:30px;}
/*.fd-cate, .fd-type, .fd-area {width:221px;}*/
/*.filter_lst:nth-child(2) .sub_filter_group {width:309px;}*/
.sub_filter_group h3 {position:absolute;top:15px;left:20px;font-size:14px;font-weight:400}
.filter-group .refresh {position:absolute;bottom:15px;left:20px;display:inline-block;z-index:11;font-size:13px;padding-left:18px;background:url(../images/sub/ico_refresh02.png) no-repeat 0}
.sub_filter_group .btn-del-small {position:absolute;top:10px;right:10px;}

/** 지도영역 **/
.map-container {height:100%;width:100%;display:block;/*overflow:hidden;*/background-size:cover;background-position:50%;position:relative}
.map-container .map {height:100%;background:url(../images/sub/bg_map_full.png) no-repeat 50% 50%;background-size:cover;position:relative}
.map-btn-group-left {position:absolute;top:60px;left:20px;z-index:203;margin-bottom:10px;}
.map-btn-group-right {position:absolute;top:60px;right:400px;z-index:2;margin-bottom:10px;}
.ico_exp {display:inline-block;background:url(../images/sub/ico_exp.png) no-repeat;width:40px;height:40px;text-indent:-9999px;margin-bottom:5px;}
.map-btn-group-right .icon-help {position:absolute;top:-5px;right:-5px}
.map-btn-group-right .icon-help.icon-help[data-tooltip-text]:hover {position:absolute;} 
.icon-help[data-tooltip-text]:hover:after {width:300px;right:0;white-space: pre-line;}
.map_location {display:inline-block;background:#fff;border:1px solid rgb(0,0,0,0.5);padding:3px 12px;position:absolute;bottom:20px;left:50%;transform:translatex(-50%);font-size:13px;border-radius:30px}
.around_lst {position:absolute;right:45px;top:90px;display:inline-block;width:90px;padding:10px;box-sizing:border-box;border:1px solid #b9b9b9;background:#fff;color:#1e1e1e;font-size:13px;padding-bottom:50px}
.around_lst .al_item {display:block;width:100%;padding:3px 0;font-size:13px;text-align:center}
.around_lst .al_item:hover, .around_lst .al_item.on {color:#7d1e00;font-weight:600}
.around_lst .filter-btn-area {left:0;bottom:0;border:none}
.around_lst .filter-btn-area .refresh {position:absolute;font-size:12px;left:50%;top:50%;transform:translate(-50%, -50%)}
.around_lst .filter-btn-area {width:100%;}
.mapview_tag {font-size:11px;background:#fff;padding:5px 10px;border:1px solid #b9b9b9;background-color:rgb(255,255,255,0.9);color:#2b2c2e;display:inline-block;position:absolute;top:20px;left:50%;transform:translatex(-50%)}
.tip-box {position:absolute;top:18px;right:0;display:block;padding:10px;box-sizing:border-box;background-color:#3a3a3a;color:#c1c1c1;border-radius:5px;width:250px;font-size:13px;z-index:10}
.btn-map-control {margin-bottom:5px;}
/*.map-btn-group-right .btn-map-control {font-size:1vw}*/
.map-btn-group-right .btn-map-control:last-child i, .map-btn-group-right button:nth-of-type(3) i {font-size:11px;letter-spacing:-0.9px}
.map-btn-group-right button:nth-of-type(1) +  .around_lst {top:45px}
.map-btn-group-right button:nth-of-type(2) +  .around_lst {top:87px}
.allmap .map-btn-group-right button:nth-of-type(1) +  .around_lst {top:0px}
.allmap .map-btn-group-right button:nth-of-type(2) +  .around_lst {top:45px}
.btn-map-control i {font-size:12px;}

// .map-btn-group-left .btn-map-control{background:#0052cc;color:#fff;border:1px solid #1e5c8d}
/*
.map-btn-group-left .btn-map-control:nth-child(2) {background:#3174a8;color:#fff;border-color:#1e5c8d}
.map-btn-group-left .btn-map-control:nth-child(3) {background:#3174a8;color:#fff;border-color:#1e5c8d}
*/


@media screen and (max-width: 1225px) {
.find .container {height:100%;margin-top:90px;}
.filter-section {position:absolute;top:50px;left:0;height:90px;/*top:16px;*/padding-right:20px;padding-top:8px}
.sch-container, .filter-container {display:block;float:inherit;margin-bottom:8px;width:100%}
.search .inp {width:100%}
.filter_lst {flex:auto}
.filter_lst:last-child {flex-basis:90px;flex-grow:0;margin-right:0}
.find .map-container {height:100%}
.find .map-container .map {height:100%}
.panel-box .filter_lst:last-child {flex:none;width:64px}
	.map-btn-group-left, .map-btn-group-right {top:20px}
	// .panel-group .filter_lst {flex:inherit;display:inline-block;width:90px}
}

/* 줌인아웃 */
.new_zoom_control {position:absolute;left:20px;top:50%;transform:translatey(-50%);z-index:19}
.new_zoom_control span {display:block;width:40px;height:40px;cursor:pointer;}
.zoom_in_cls {margin-bottom:5px;background:url(../images/sub/ico_zoomin.png);}
.zoom_out_cls {background:url(../images/sub/ico_zoomout.png);}
.my_location {position:absolute;left:20px;bottom:100px;z-index:19;width:40px;height:40px;cursor:pointer;background:url(../images/sub/btn_my_location.png);margin-top:20px}

/* 주변item */
.ar_posi {position:absolute;width:22px;height:32px;display:inline-block;z-index:10;text-align:center;background-size:contain;font-size:0}
.posi_sch01 {background:url(../images/sub/ico_school01.png) no-repeat;}
.posi_sch01.on {background:url(../images/sub/ico_school01_on.png) no-repeat;}
.posi_sch02 {background:url(../images/sub/ico_school02.png) no-repeat;}
.posi_sch02.on {background:url(../images/sub/ico_school02_on.png) no-repeat;}
.posi_sch03 {background:url(../images/sub/ico_school03.png) no-repeat;}
.posi_sch03.on {background:url(../images/sub/ico_school03_on.png) no-repeat;}
.posi_sch04 {background:url(../images/sub/ico_school04.png) no-repeat;}
.posi_sch04.on {background:url(../images/sub/ico_school04_on.png) no-repeat;}
.posi_sch05 {background:url(../images/sub/ico_school05.png) no-repeat;}
.posi_sch05.on {background:url(../images/sub/ico_school05_on.png) no-repeat;}
.ar_posi .type, .posi .type {position:absolute;bottom:-16px;left:-5px;right:0;font-size:11px;display:inline-block;font-weight:bold;letter-spacing:-.7px}
.posi_sch01 .type {color:#ffe400}
.posi_sch02 .type {color:#ffa800}
.posi_sch03 .type {color:#85c253}
.posi_sch04 .type {color:#4d8520}
.posi_sch05 .type {color:#326509}
.posi_hospt {width:28px;height:28px;background:url(../images/sub/ico_hos.png) no-repeat;background-size:contain}
.posi_mart {width:28px;height:28px;background:url(../images/sub/ico_mart.png) no-repeat;background-size:contain}
.posi_bank {width:28px;height:28px;background:url(../images/sub/ico_bank.png)  no-repeat;background-size:contain}
.posi_public {width:28px;height:28px;background:url(../images/sub/ico_public.png) no-repeat;background-size:contain}
.surr_name {display:none;background:#fff;border:1px solid #ddd;padding:3px 5px;font-size:12px;box-sizing:border-box;max-width:200px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
.ar_posi.on .surr_name {display:block;position:absolute;top:-30px;box-sizing:border-box;transform: translatex(-50%);left: 50%;}

/* 매물집합 - 동단위 */
.cluster {position:absolute;background-color:#c73009;border:1px solid #BE3000;z-index:11;overflow:visible!important;text-align:center;border-radius:3px;padding:10px;box-shadow:1px 1px 2px 1px rgba(0, 0, 0, 0.2);opacity:.95}
.cluster > .info01 {color:#f1f1f1;font-size:12px;font-weight:normal;white-space:nowrap;padding:0;line-height:1.2;}
.cluster > .info02 {color:#fff;display:block;font-weight:500;font-size:15px;height:auto;line-height:1;text-align:center;white-space:nowrap;padding:0;margin-top:3px;}
.cluster.on {background:#fff;opacity:.85;border:3px solid #444}

/* 매물 집합_아파트 */
.danji_count .real-pin {position:absolute;z-index:1}
.real-pin {position:relative;display:inline-block;width:50px;height:39px}
.real-pin .like {display:block;background:url("../images/sub/icon_like.png") no-repeat;width:20px;height:20px;background-size:contain;position:absolute;top:-8px;left:-8px;}
.pin-type01 {background:url("../images/sub/ico_price.png") no-repeat;background-size:contain;}
.pin-type02 {background:url("../images/sub/ico_price04.png") no-repeat;background-size:contain;}
.pin-type03 {background:url("../images/sub/ico_price03.png") no-repeat;background-size:contain;}
.pin-type04 {background:url("../images/sub/ico_price05.png") no-repeat;background-size:contain;}
.pin-type01.on, .pin-type02.on, .pin-type03.on, .pin-type04.on {background:url("../images/sub/ico_price_on.png") no-repeat;background-size:contain;color:#222;font-weight:600}
.real-pin.pin-type02 .competi {line-height:3.5;}
.pin-type06 {background:url("../images/sub/ico_price_s.png") no-repeat;background-size:contain;width:20px;height:26px;}
.pin-type06.on {background:url("../images/sub/ico_price_s_on.png") no-repeat;background-size:contain;}
.pin-type06 .info_s {width:20px;height:26px;}
/*201022 단지명 마우스오버*/
.real-pin.pin-type06 .info02 {display:none}
.real-pin.pin-type06:hover > .info02, .real-pin.pin-type06:active > .info02 {display:block}
.pin-type07 {background:url("../images/sub/ico_price_sgrey.png") no-repeat;background-size:contain;width:40px;height:41px;}
.pin-type07.on {background:url("../images/sub/ico_price_s_on.png") no-repeat;background-size:contain;}
.real-pin.pin-type07 .info01 {width:40px}


.pin-type02 .caption {display:block;font-size:8px;padding-top:2px;font-weight:400;color:#fff;margin-top:-12px;}
.sell-sale01 {background:url("../images/sub/ico_price02.png") no-repeat;background-size:contain;}
.sell-sale02 {background:url("../images/sub/ico_sale02.png") no-repeat;background-size:contain;}
.sell-sale03 {background:url("../images/sub/ico_sale03.png") no-repeat;background-size:contain;}
.real-pin .info01 {width:50px;/*height:39px;*/text-align:center;color:#fff;/*line-height:30px;*/margin-top:6px;font-size:13px;letter-spacing:-1px}
.real-pin.on .info01 {color:#222}
.real-pin .info02 {position:absolute;top:102%;background-color:rgba(61,53,46,.7);color:#fff;padding:2px 5px;left:50%;border-radius:2px;font-size:11px;max-width:120px;/*min-width:60px;*/text-align:center;transform:translateX(-90%);font-weight:300;-o-text-overflow:ellipsis;text-overflow:ellipsis; overflow:hidden;white-space:nowrap;line-height:1.4}
.real-pin .info03 {position:absolute;top:20px;left:44px;text-align:center;justify-content:center;min-width:16px;height:17px;line-height:1.2;opacity:.9;border-radius:12px;background-color:#444;border:1px solid rgba(43,43,43,.8);box-sizing:border-box;color:#fff;transform: translate(-20%);font-size:11px;font-weight:400;padding:0 3px}
.real-pin.pin-type06 .info03 {left:18px;top:12px;}
.real-pin .info04 {position:absolute;top:-10px;left:0;font-size:10px;padding:0 4px;background:#fff;border-radius:20px;border:1px solid #444;box-sizing:border-box;line-height:15px;/*height:15px;*/font-weight:600;letter-spacing:-.6px}
.real-pin .info05 {position:absolute;top:-10px;left:0px;font-size:8px;padding:0 4px;background:#ff0000;color:#fff;border-radius:20px;box-sizing:border-box;height:15px;line-height:15px;letter-spacing:-.6px}
.danji_prev {z-index:200}
.high {display:inline-block !important;background:red;border-radius:40px;font-size:11px;color:#fff;padding:1px 5px 2px;font-weight:400;/*margin-right:3px;*/vertical-align:middle}
.danji {display:inline !important;vertical-align:middle;margin-bottom:-1px}
.info01 .danji, .high {vertical-align:middle;}
.cluster .info01 small {color:#fff}
.real-pin .info01 small {display:block;font-size:11px;letter-spacing:0;line-height:1;margin-bottom:-1px;margin-top:-10px;padding-top:4px;font-weight:300}
.sell-type-tag {display:inline-block !important;border-radius:40px;font-size:11px;color:#fff;padding:1px 5px 2px;margin-right:3px;text-align:center;box-sizing:border-box;vertical-align:middle;font-weight:400}
.tag01 {background:#85c23b}
.tag02 {background:#249b58}
.tag03 {background:#1f874d}
.tag04 {background:#34b5e0}
.tag05 {background:#ff8d44}
.tag06 {background:#5827d8}
.tag07 {background:#ffd200}
.danji_hover {animation: bounce 0.6s infinite alternate;-webkit-animation: bounce 0.6s infinite alternate;}
@keyframes bounce {
from {
	transform: translateY(0px);
}
to {
	transform: translateY(-10px);
}
}
@-webkit-keyframes bounce {
from {
	transform: translateY(0px);
}
to {
	transform: translateY(-10px);
}
}
/* 주변단지리스트 */
.panel-group {height:100%;width:380px;position:absolute;background:#fff;z-index:100;right:0;box-sizing:border-box;top:0;overflow-y:hidden}
.panel-group.normal {width:360px;}
.panel-group.wide {width:750px;}
.panel-group .btn-left {position:absolute;top:6px;left:5px;z-index:1002}
.panel-group .btn-right {position:absolute;top:10px;right:5px;z-index:1002}
.panel-header {height:61px;text-align:center;box-sizing:border-box;position:relative;z-index:1001;background:#fff;padding:0 30px}
.panel-header .btn_space_box {right:10px;top:15px;}
.panel-header .btn_space {border-color:rgb(255,255,255,0.5);background-color:none;color:rgb(255,255,255,0.8)}
.panel-header .btn_space.on {z-index:10;border-color:#fff;background-color:#fff;color:#2b2c2e;}
.danjilst .btn_moreview {height:auto;padding:5px 0;margin-top:5px;}
.danjilst .btn_moreview span {display:inline-block;padding:0 30px 0 20px;background:url(../images/main/btn_arr_down.png) no-repeat 90%;}
.btn-viewpanel {position:absolute;right:0;top:0;z-index:101}

@media screen and (max-width: 1225px) {
.panel-group {top:0;z-index:2}
.btn-viewpanel {top:0;z-index:2}
}

.panel-group.itemlist {height:100%}
.panel-group.itemlist .panel-box {height:calc(100% - 84px)}
.panel-header .mlst_tit .tit {color:#222}
.panel-header .mlst_tit span {color:#222}
.danjiname {font-size:16px;}
.item-card .danjiname {display:inline-block;vertical-align:middle;font-size:18px;font-weight:600;max-width:270px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.item-card .danjiname + .favo_area {margin-left:5px;}
.mlst_tit {display:inline-block;vertical-align:middle;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:100%;padding:0 30px}
.panel-box {padding:10px 10px 70px;background:#f2f2f2;box-sizing:border-box;width:100%;/*height:100%;position:absolute;top:0;bottom:0;margin-top:50px;*/height:calc(100% - 44px);overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.danjilst .panel-box {padding-bottom:15px}
.d_info01, .d_info02, .d_info03 {display:block;}
.d_info01 {margin-bottom:5px;}
.d_info03 span {font-size:14px;display:inline-block}
.lstsort .sort a {display:inline-block;font-size:12px;color:#909090}
.lstsort .sort:before {display:inline-block;width:1px;height:9px;margin:0 10px;background:#999;opacity:0.9;content: '';vertical-align:-1px}
.lstsort .sort:first-child:before {display:none;}
.lstsort .sort a:hover, .lstsort .sort a.on {color:#000;font-weight:600}
.itm_alert_box {width:295px;}
.itm_alert_box .tit {font-size:15px;text-align:left;margin-bottom:5px;}
.itm_alert_box .btn-del-small {position:absolute;right:10px;top:10px}
.itm_alert_box .tit_exp {text-align:left;padding:0;font-size:13px;margin-bottom:10px;}
.itm_alert_box form + form {margin-top:10px}
.itm_alert_box form + form strong {font-size:14px}
.item-card-container {display:flex;flex-wrap:wrap;flex:auto;margin:-5px;}
.item-card {display:flex;flex-direction:column;width:360px;background:#fff;border:0;padding:12px;box-sizing:border-box;position:relative;margin:5px;}
.nonlaunching .item-card {padding:12px;}
.sale-step {font-size:16px;color:#c22800;margin-top:5px;display:block;}
.nonlaunching .m_lst_item {padding-bottom:15px}
.item-card:hover {border:0;cursor:pointer}
.item-card .favo_area {/*position:absolute;right:21px;top:21px;z-index:105;*/display:inline-block;vertical-align:middle}
.item-card.apt .favo_area {position:absolute;top:20px;right:22px;z-index:10}
.item_info {display:block;width:calc(100% - 117px);min-height:105px;position:relative;margin-bottom:5px;}
.item-card.onsale {padding-bottom:12px;/*padding-right:44px*/}
.item-card.onsale .item_info {width:100%;min-height:inherit}
.item-card.movein .item_info {width:100%;min-height:52px}
.item-card.onsale .favo_area {top:15px;right:15px}
.onsalelst .onsale .sale-step {font-size:15px;}
.onsalelst .onsale .caption {margin-top:5px;color:#c22800;position:static;transform:none}
.onsalelst .onsale .caption .extension {font-size:18px;font-weight:300;letter-spacing:-1px;display:inline-block;vertical-align:middle}
.onsalelst .onsale .caption .cont {font-size:18px;font-weight:600;display:inline-block;vertical-align:middle;margin-left:3px;}
.item_info span.step {font-size:15px;margin-bottom:-2px}
.d_info03 .addinfo {text-overflow:inherit;overflow:inherit;white-space:inherit;font-size:13px;margin-top:2px}
.d_info03 .addinfo b {font-weight:400}
.item_info .name {font-size:18px;font-weight:bold;text-overflow:inherit;white-space:inherit}
.item_info .area {font-size:14px;/*margin-bottom:10px*/}
.deal_type {font-size:15px;}
.deal_type b {display:inline-block;margin-right:5px;}
.deal_type b:last-child {margin-right:0}
.tag_type {margin-right:5px;}
.deal_type .type01, .real-price .type01 {color:#bf3433;}
.deal_type .type02, .real-price .type02 {color:#5d69bf}
.deal_type .type03, .real-price .type03 {color:#43c750}
.deal_type i, .deal_price i {color:#222;font-weight:600}
.deal_price i {margin-left:3px;}
.real-price i {margin-right:6px;}
.deal_price b {display:block;font-weight:400;font-size:15px}
.mlst_tit .deal_price .mark {display:inline-block;font-size:9px;text-indent:inherit;margin-bottom:0;width:inherit;height:inherit;vertical-align:middle;margin-right:5px;line-height:1;font-weight:300;padding:1px 3px 2px;background:#bebebe;color:#fff}
.deal_price {font-size:14px}
.mlst_tit .deal_price b {display:inline-block;font-weight:600;vertical-align:middle;line-height:12px;}
.item_count {/*position:absolute;bottom:12px;*/font-weight:400;/*width:185px*/margin-top:auto}
.item_thumb {position:absolute;top:15px;right:15px;border:1px solid #d9d9d9;width:35%;height:100px;box-sizing:border-box;z-index:9;overflow:hidden}
.item_thumb img {width:100%;height:100%;object-fit:cover;object-position:50%}
.graph-box {position:absolute;top:45px;right:15px;width:130px;height:110px;display:inline-block;box-sizing:border-box}
.graph-box img {width:100%;height:100%;object-fit:contain;object-position:50%}
.graph-box .tag {display:inline-block;font-size:11px;background:#8e8e8e;border-radius:2px;color:#fff;padding:1px 4px;position:absolute;right:0;top:0;font-weight:200}
.itm_msg {width:100%;text-align:center;position:absolute;top:200px;left:0}
.itm_msg img {display:inline-block}
.itm_msg p {text-align:center;margin-top:5px;font-size:16px}
.itm_alert_box {position:absolute;padding:15px;background:#fff;border:1px solid #666;font-size:15px;font-weight:500;text-align:center;left:0;min-width:309px;max-width:400px;z-index:1000;margin-top:5px}
.price .itm_alert_box {left:50px}
.itm_alert_box .favo_tit {text-align:left;font-weight:400;font-size:15px;margin-bottom:5px;}
.panel-box .txt_noti {display:inline-block;background:none;opacity:.7;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);z-index:120;border-radius:50px;box-sizing:border-box;width:250px;text-align:center;height:40px;vertical-align:middle}
.panel-box .txt_noti span {color:#222;font-size:15px;;margin-top:30px;font-weight:bold}
.itm_alert_box .check-box03>.chk01>input[type=checkbox] + label {width:70px;height:30px;line-height:29px;font-size:12px}
/*.itm_alert_box .check-box03>.chk01>input[type=checkbox]:checked + label {background:#b9342a;border:1px solid #a31d13;color:#fff;}*/
.itm_alert_box .btn-horiz {margin-top:10px}
.itm_alert_box .btn-horiz .btn {flex:1;margin:0;height:30px;font-size:13px}
.itm_alert_box p {padding:10px}
.panel-group .btn-horiz-bottom {width:100%;}
.panel-group .btn-horiz-bottom .btn-primary {width:100%;}

/* 단지정보 */
.panel-header .favo_area {/* position:absolute;top:50%;transform:translatey(-50%);right:10px;*/display:inline-block;color:#222} 
.panel-header .itm_alert_box {right:0;left:inherit}
.panel-header .itm_alert_box .tit {color:#222}
.danjidetail .panel-box {padding:0 0 60px;/*height:calc(100% - 100px)*/}
.panel-header .mlst_tit {color:#222;width:280px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:14rem;}
.panel-header .mlst_tit .danjiname {color:#222;display:inline-block;vertical-align:middle;font-size:17px;}
.panel-header .mlst_tit .subtitle {color:#222;}
.mlst_tit .d_info01 {margin-bottom:0}
.mlst_tit .tit {font-size:15px;}
.mlst_tit .addinfo {margin-top:-3px}
.mlst_tit .addinfo b, .mlst_tit .address {font-size:12px;font-weight:400}
.mlst_tit .address {display:block;margin-top:-2px;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;}
.mlst_tit .deal_price {font-size:16px;margin-bottom:2px;display:block;line-height:1}
.danji_img {background:#fff}
.slider-container {position:relative}
.slider-container .slider {width:100%;height:200px;}
.slider-container .slider li {display:block;width:100%;height:100%}
.slider-container .slider img {width:100%;height:100%;object-fit:cover;object-position:50%;}
.slide_thumb {overflow:hidden;margin-top:5px;width:100%}
.slide_thumb ul {margin-top:2px;width:3000px;overflow:hidden;}
.slide_thumb ul:after {display:block;content:'';clear:both}
.slide_thumb ul li {display:inline-block;float:left;width:60px;height:45px;overflow:hidden;margin-right:2px}
.slide_thumb ul li:last-child {margin-right:0}
.slide_thumb ul li img {width:100%;height:100%;object-fit:cover;object-position:50%}
.itmbox {position:relative;padding:20px 15px;margin-bottom:5px;background:#fff;}
.itmbox h3.tit {font-size:16px;margin-bottom:15px;color:#222;font-weight:500;letter-spacing:-.9px;text-align:left}
.itmbox.danjibasic {background:#fff;}
.itmbox.danjibasic span {font-size:15px}
.itmbox.danjibasic .deal_price {display:block;margin-top:5px}
.itmbox.danjibasic .deal_price b {font-size:18px;font-weight:400}
.itmbox.recent-price b {font-size:18px;}
.itmbox_inner .value {font-size:20px;position:absolute;right:18px;top:20px;font-weight:600}
.pic_danji {width:100%;height:220px;box-sizing:border-box;overflow:hidden}
.pic_danji img {width:100%;height:100%;object-fit:cover;object-position:50%}
.tbl_type01.calendar th {background:none}
.calendar .expired th, .calendar .expired td {color:#ACACAC}
.calendar .dday {background:#c22800}
.calendar .dday th, .calendar .dday td {color:#fff;font-size:14px}
.itmbox_inner {position:relative}
.itmbox_inner span.area, .itmbox_inner span.addinfo {font-size:16px;margin-top:0}
.itmbox_inner span.date {position:inherit;font-size:20px}
.itmbox_inner .basic {font-size:15px;line-height:1.6}
.itmbox .btn_space_box {position:absolute;right:15px;top:22px}
.itmbox_inner .btn_space_box {top:13px;z-index:10}
.itmbox .price {margin-bottom:20px}
.panel-box .scroll-menu-container {background:#fff;margin-bottom:8px;padding:0}
.scroll-menu-container {position:relative;width:100%;padding:15px 70px 15px 10px;}
.scroll-menu-container ul {margin-bottom:0;}
.scroll-menu-container .btn_left {background:url(../images/sub/btn_left.png) no-repeat;left:-16px}
.scroll-menu-container .btn_right {background:url(../images/sub/btn_right.png) no-repeat;right:-16px}
.scroll-menu-container ul li {position:relative;}
.scroll-menu-container ul .high {width:5px;height:5px;position:absolute;top:0;left:0;padding:0;margin-right:0;text-indent:-9999px;}
.scroll-menu-container ul li:first-child .high {left:0}
.scroll-menu-container .ul-container {white-space:nowrap;box-sizing:border-box;/*display:flex;width:320px;*/overflow-x:overlay;@include overflow-scroll-custom (dark, medium, x);}
.scroll-menu-container .ul-container li {display:inline-block;float:left;margin-right:15px;position:relative;/*height:35px;line-height:35px;*/}
.scroll-menu-container .ul-container li a {display:inline-block;font-size:14px;color:#a3a3a3;padding:0 5px;/*line-height:1.7*/;height:30px}
.scroll-menu-container .ul-container li a:hover, .scroll-menu-container .ul-container li a.select {color:#000;font-weight:600}
/*.scroll-menu-container .ul-container li a:before {display:inline-block;width:1px;height:11px;margin:0 10px;background:#999;opacity:0.9;content: '';vertical-align:-1px}*/
.scroll-menu-container .ul-container li:first-child a:before {display:none}
.scroll-menu-container .btn_space_box {top:30px;right:10px;}
.scroll-link {display:block;height:40px;box-sizing:border-box;padding-bottom:10px;padding-top:3px;color:#A2A2A2}
.scroll-link .noh {display:block;position:absolute;bottom:8px;left:50%;transform:translatex(-50%);font-size:11px;line-height:1;}
.scroll-link.select {font-weight:bold;color:#000;border-bottom:2px solid #333;}
.itmbox .tab_con {position:relative;margin-top:10px}
.itmbox .price {font-size:24px;font-weight:600;display:block;margin:3px 0 15px;}
.itmbox .tab_con .price>span {font-size:20px;font-weight:300;margin-right:5px}
.itmbox .tab_con .price>span.high {font-size:13px;}
.itmbox .tab_con .price .type {font-weight:400}
.itmbox .tab_con .graph_area img {width:100%;}
.itmbox .graph_area {/*margin-top:10px;*/width:100%;/*height:250px;*/margin-bottom:20px}
.btn-horiz-bottom .btn-primary {width:400px;height:50px;border-radius:0;font-size:16px;margin:0}
.danji_info .tab_style04 {position:absolute;left:0;top:45px;z-index:4;margin-top:0}

.real_detail {margin-bottom:20px}
.real_detail table {border:1px solid #e5e5e5;border-collapse:separate;border-top:0;border-spacing:0;}
.real_detail tr {height:32px;background-color:#fafafa;}
.real_detail tbody tr {background:#fff;}
.real_detail tr td {position:relative;text-align:center;font-size:12px;color:#444;vertical-align:middle;border-top:1px solid #e5e5e5;}
.real_detail tr td.real-price {padding:0;display:table-cell;font-weight:500}
.real_detail .btn_more-price {position:relative;display:block;height:36px;line-height:36px;text-align:center;background-color:#fff;border:1px solid #e5e5e5;border-top:0;}
.real_detail .high {font-size:8px;padding:0 5px 2px;margin-right:4px;}

.itmbox .go_naver {display:block;text-align:center;padding:10px 0;border:1px solid #ccc;background:#f1f0ed}
.vGraph{padding:20px 0;}
.vGraph ul{margin:0;padding:0;height:60px;border:1px solid #ddd;border-top:0;border-right:0;border-left:0;font-size:11px;}
.vGraph ul:after{content:"";display:block;clear:both;}
.vGraph li{float:left;display:inline-block;width:calc(100%/6);height:100%;position:relative;text-align:center;white-space:nowrap;padding:0 3%;box-sizing:border-box;}
.vGraph .gTerm{position:relative;display:inline-block;width:100%;height:20px;line-height:20px; margin:0 -100% -20px 0;padding:60px 0 0 0; vertical-align:bottom;color:#767676;font-weight:bold;}
.vGraph .gBar{position:relative;display:inline-block; width:100%;margin:-1px 0 0 0;background:#c22800;vertical-align:bottom;}
.vGraph .gBar span{position:absolute;width:100%;top:-20px;left:0; color:#767676;font-size:14px}
.public_price {margin-bottom:10px;height:25px}
.public_price:after {display:block;content:'';clear:both}
.public_price span {}
.public_price .value {font-size:16px;position:static;margin-top:-5px;margin-left:15px;}
.info_align {display:block;width:100%}
.tax-info .info_align {margin-top:0}
.tax-info .public_price {margin-bottom:0}
.tax-info .public_price .value {font-weight:400;margin-left:5px}
.info_align.align02 {margin-top:15px}
.info_align li {display:flex;flex:auto;align-items:center;width:100%;font-size:15px}
.info_align.align02 li{display:inline-block;width:50%;font-size:15px}
.info_align li:last-child .content {font-weight:400;font-size:16px;}
.info_align li:last-child .content b {font-size:16px;font-weight:400}
.info_align li span {display:inline-block;vertical-align:middle}
.info_align .content {/*display:block;*/font-size:16px;font-weight:400;margin-left:20px}
.tax-info .selec {height:38px;width:90px}
.tax-info .icon-help[data-tooltip-text]:hover:after {right:inherit}
.tax-info .itmbox_inner .value {right:0;top:-42px;}

.box-basicinfo ul {display:block;}
.box-basicinfo ul:after {display:block;content:'';clear:both}
.box-basicinfo li {display:inline-block;float:left;width:50%;box-sizing:border-box;margin-bottom:3px;}
.box-basicinfo li:before {display:inline-block;content:'';width:4px;height:4px;background:#a40000;margin-right:10px;margin-bottom:3px}
.itmnaver_info .list {border:1px solid #eaeaea;}
.itmnaver_info .list .t_header {background:#44cc5b;}
.itmnaver_info .list .t_header td {color:#fff;}
.itmnaver_info .list tr {border-bottom:1px solid #eaeaea;height:36px;}
.itmnaver_info .list td {text-align:center}
.itmnaver_info .list tbody tr td:nth-child(4) {font-weight:bold}
.itmbox h3 b {font-weight:500}
.btn-itmnaverdt {color:#44cc5b;width:40% !important;margin-top:20px !important;height:40px;font-size:14px;}
.btn-itmnaverdt:before {display:inline-block;content:'';background:url(../images/sub/ico_n.png) no-repeat;background-size:contain;width:14px;height:14px;margin-right:5px;margin-top:-3px;vertical-align:middle}
.btn-itmnaverdt:hover {
background:unset;color:#44cc5b}
.pop .btn.btn-xsmall {display:inline-block;width:auto;margin-top:0;margin-left:3px;}

/*210223 네이버아이템 상세보기*/
.npop {padding:0;min-height:200px;max-height:500px}
.npop header {padding:20px;background:#44cc5b}
.npop .itmtitle_group h2 {font-weight:400;font-size:15px;color:#fff}
.npop .itmtitle_group h2.info01 {font-size:20px;font-weight:600;}
.npop .itmtitle_group h2.info01:before {display: inline-block;content:'';width:12px;height:12px;background:url(../images/sub/ico_n02.png) no-repeat;background-size:contain;margin-right:6px;vertical-align:middle;padding-top:4px;}
.npop .itmtitle_group h2.info02, .npop .itmtitle_group h2.info03 {display:inline-block;font-size:17px;font-weight:400;}
.npop .itmtitle_group h2.info03:before {display:inline-block;margin:0 5px 0 2px;content:'/';font-size:14px;color:#fff}
.npop .itmtitle_group h2.info02 span, .npop .itmtitle_group h2.info03 span {margin-right:3px;}
.npop .itmtitle_group h2.info02 span:last-child, .npop .itmtitle_group h2.info03 span:last-child {margin-right:0}
.pop.npop .container {padding:0;margin-top:0}
.npop .nitm_link {display:block;border-bottom:1px solid rgba(0,0,0,0.1);padding:12px 20px 14px;text-align:left;}
.npop .nitm_link:hover {background:#f3fff5}
.npop .nitm_link.selected {background:#f0f0f0}
.npop .nitm_link .row {padding:0;border:none}
.npop .nitm_link .row:first-child {font-size:17px;}
.npop .nitm_link .row .price {color:#4c94e8;font-weight:600}
.npop .nitm_link .row .price:after {display:none}
.npop .nitm_link .row span:after {display:inline-block;content:',';margin-right:5px}
.npop .txt_emp {padding:10px 20px;text-align:left;border-bottom:1px solid rgba(0,0,0,0.1)}





.school-info {display:flex;flex:auto;justify-content:space-between;align-items:flex-end;line-height:1.4}
.school-info + .school-info {margin-top:15px}
.school-info .caption {position:static;transform:none;font-size:13px;color:#666;padding-bottom:3px}
.school-info .content span:first-child {display:block;font-size:13px;font-weight:400;}
.school-info .count {font-size:20px;}
.school-info small.detail {font-size:13px;font-weight:400;}
.school-info .value {position:static}
.school-info .content span:first-child.s_detail {display:inline-block}

small.small {font-weight:400;font-size:14px;}
.icon-help {display:inline-block;width:14px;height:14px;background:url("../images/sub/ico_tooltip.png") no-repeat;background-size:contain;margin-left:2px;vertical-align:middle}
[data-tooltip-text]:hover {position:relative;}
[data-tooltip-text]:hover:after {content:attr(data-tooltip-text);position:absolute;top:-100%;background-color:#3a3a3a;color:#c1c1c1;border-radius:5px;margin-top:2px;padding:5px;font-size:13px;font-weight:normal;max-width:230px;min-width:100px;z-index:9999;}
.left [data-tooltip-text]:hover:after {left:0;}
.right [data-tooltip-text]:hover:after {right:0;}
.center [data-tooltip-text]:hover:after {left:0;}
.price-group {position:relative}
.price-group .caption {margin-top:-5px;}
.l_price {position:absolute;right:0;top:0}
.l_price:after {display:block;content:'';clear:both}
.l_price span {float:left;font-size:22px;}
.l_price span:last-child {margin-top:4px;font-weight:bold}
.l_price span:first-child {font-size:15px;margin-right:5px}
.l_price span i {display:block;font-size:11px;margin-bottom:-3px}

.ag-group .ag-li {display:flex;flex:auto;align-items:flex-start;justify-content:space-between;margin:5px 0}
.ag-group .ag-li span {display:block;vertical-align:middle}
.ag-group .ag-li .address {font-size:13px;color:#afafaf}
.ag-info .name {font-size:16px;font-weight:500;line-height:1.6}
.btn-call  {display:inline-block;padding:2px 8px 6px;width:55px;box-sizing:border-box;background:#b9342a;border-radius:50px;text-align:center;position:relative}
.ag-group .ag-li .btn-call span {background:url("../images/sub/ico_phone.png") no-repeat;display:inline-block;width:12px;height:12px;background-size:contain;text-indent:-9999px;vertical-align:middle}
.alert-cont strong, .alert-cont span {display:block}
.lyr .name-office {display:block;font-size:20px;margin-bottom:0}
.lyr .phone-office {display:block;font-size:24px;margin-bottom:10px;}
.ag-group .ag-li .ag-info {width:calc(100% - 55px)}

.edu-group .edu-li {width:100%;margin-bottom:5px}
.edu-group .edu-li a {display:flex;flex:auto;align-items:flex-end}
.edu-group .edu-li a span {display:inline-block;vertical-align:middle;font-weight:400;}
.edu-group .edu-li a span.name {overflow:hidden;white-space:nowrap;text-overflow:ellipsis}
.edu-group .edu-info {width:85%;}
.edu-info .name {font-size:16px;margin:0 5px}
.edu-info .distance {font-size:14px;color:#9d9d9d}
.edu-info .type {display:inline-block;vertical-align:middle;font-size:11px;padding:0 5px 1px;border-radius:20px;border:1px solid #484848;background:#fff}
.edu-group .edu-right {width:20%;text-align:right;position:relative;font-size:18px;font-weight:500;position:absolute;right:15px}
.edu-group .caption {position:absolute;right:0;top:-8px;font-size:12px;color:#666;font-weight:200}
.daycare .edu-group .edu-li .edu-right {font-size:16px;font-weight:500}
.btn_moreview {font-size:14px;width:100%;height:22px;line-height:22px;box-sizing:border-box;display:block;text-align:center;margin-top:10px}

/*201228 입지분석추가 */
.itmbox.location {padding-left:0;padding-right:0}
.itmbox.location>.tit {padding-left:15px;}
.itmbox.location .maparea {height:220px;border:0}
.itmbox.location .content {margin-top:10px;padding:0 15px}
.itmbox.location .content li {display:block;margin:12px 0;}
.itmbox.location .content li:last-child {margin-bottom:0}
.itmbox.location .content li .tit {display:block;cursor:pointer;margin-bottom:8px;background: url(../images/main/btn_arr_down.png) no-repeat top 9px right 0;}
.itmbox.location .content li .tit.active {background: url(../images/main/btn_arr_up.png) no-repeat top 9px right 0;}
.itmbox.location .content li .tit .txt_loc {font-size:15px;font-weight:400;margin-left:3px}
.itmbox.location .content li .detail {display:block;margin-bottom:20px}
.itmbox.location .content li .detail .sb_detail {display:block;font-size:14px;margin:2px 0;position:relative}
.itmbox.location .content .loc01 .img_loc {display:inline-block;background:url(../images/sub/ico_loc01.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .loc02 .img_loc {display:inline-block;background:url(../images/sub/ico_loc02.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .loc03 .img_loc {display:inline-block;background:url(../images/sub/ico_loc03.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .loc04 .img_loc {display:inline-block;background:url(../images/sub/ico_loc04.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .loc05 .img_loc {display:inline-block;background:url(../images/sub/ico_loc05.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .img_loc, .itmbox.location .content .txt_loc {display:inline-block;vertical-align:middle;line-height:1.5}
.itmbox.location .content li .detail .more_view {position:absolute;top:0;right:0;font-size:14px;}
.itmbox.location .content li .more_cont {padding:10px 10px 15px;background:#f3f3f3;margin-top:8px;margin-bottom:5px;width:100%;}
.itmbox.location .content li .detail .more_cont {display:inline-block}
.itmbox.location .content li .detail span .more_cont span {display:inline-block}
.itmbox.location .content li .detail span .more_cont .edu-li {margin:0}
.itmbox.location .content li .detail span .more_cont .edu-li .type, .location .content li .detail span .more_cont .edu-li .name {margin-right:5px;}
.location .content li .detail span .more_cont .edu-li .name {font-size:15px;}
.itmbox.location .content li .detail span .more_cont .edu-li .edu-right {font-size:16px;}
.itmbox.location .content li .detail .more_cont .btn_moreview {margin-top:5px}
.itmbox.location .content .sb_detail li {margin:3px 0;}
.itmbox.location .content .sb_detail li a {position:relative}

/*분양단지상세*/
.onsaledetail .panel-header {height:50px;}
.onsaledetail .panel-box {padding:0 0 20px;}
.onsaledetail .danji_img {height:200px;position:relative}
.onsaledetail .danji_img .danji-location img {width:100%;height:200px;object-fit:cover;object-position:50%;}
.onsaledetail .item_info {min-height:auto;width:100%}
.onsaledetail .sale-step {font-size:18px;color:#c22800;}
.caption {position:absolute;right:15px;top:50%;transform:translateY(-50%);color:#c22800}
.caption span {display:block;text-align:right}
.caption .extension {font-size:13px;}
.caption .cont {font-size:20px;font-weight:600;line-height:1.2}
.onsaledetail .tbl_type01 {border-top:none}

/*입주예정상세*/
/*.moveindetail .panel-header {height:100px}*/
.moveindetail .panel-box {padding:0 0 60px;/*height:calc(100% - 100px)*/}
.moveindetail .sale-step {margin-top:0;font-size:20px}

/*재건축리스트*/
.recontr-sub-header {height:160px;background-image:url(../images/sub/img_recontr.png);background-repeat:no-repeat;background-position:50%;background-size:cover;position:relative;}
.reconstrlst .item-card.reconstr {padding-bottom:12px;padding-right:44px}
.reconstrlst .item-card.reconstr .item_info {width:100%;min-height:inherit}
.reconstrlst .item-card.reconstr .favo_area, .reconstrlst .item-card.reconstr .btn-favo {/*top:15px;right:15px;*/margin-left:5px;}
.re_step {margin-bottom:5px;font-size:15px;}
.re_step b {color:#c22800}
.reconstrlst .reconstr .sale-step {font-size:16px;color:#666}
.warning {padding:5px;background:#ffecec;color:#c71616;font-size:12px;margin-top:7px;text-align:center;border-radius:2px;}
.warning:before {display:inline-block;content:'';background:url("../images/sub/ico_wrarn.png") no-repeat;width:12px;height:12px;background-size:contain;margin-right:5px;vertical-align:middle}

/*재건축상세*/
/*.reconstrdetail .panel-header {height:100px}*/
.reconstrdetail .panel-box {padding:0;/*height:calc(100% - 100px)*/}
.emphasis {background:#b52500;color:#fff;background-image:linear-gradient(-90deg, #b52500, #e47732);margin-bottom:8px;margin-top:8px;min-height:50px;padding:8px 15px}
.emphasis .tit {color:#fff;font-size:16px;}
.go_score .small {margin-top:-8px;color:#f9f9f9}
.go_score .small i {color:#ffe400;font-style:italic}
.go_score {display:block}
.go_score .value {font-size:22px;font-weight:500;color:#ffe400;top:18px}
.go_score .blind {display:inline-block;padding:3px 15px 5px;border-radius:50px;background:#8c2106;}
.emphasis.other {background:#b9b9b9;padding:15px 20px}
.emphasis.other .small {margin-top:0}
.schedule-box {padding-left:0;padding-right:0}
.schedule-box .tit {padding-left:15px;padding-right:15px}
.schedule-box .info {padding:0 15px;}
.schedule-box .info span {float:inherit;width:100%;display:block;margin-bottom:3px;font-size:15px;}
.line {height:1px;display:block;width:100%;margin:10px 0;background:#e5e5e5;}
.schedule-lst {width:100%;}
.schedule-lst-group {display:table;width:100%;padding:3px 20px 5px;box-sizing:border-box}
.schedule-lst span {display:table-cell;line-height:1.5;height:24px}
.schedule-lst.expired span {color:#ACACAC}
.schedule-lst.dday {background:#c22800}
.schedule-lst.dday span {color:#fff}
.schedule-lst .date {width:35%;}
.itmbox_inner .schedule-lst .date {font-size:15px;}
.schedule-lst .detail {width:45%}
.schedule-lst .today {width:20%}
.schedule-lst .stage {width:20%}
.schedule-box .info span:first-child {display:inline-block;width:auto;}
.schedule-box .info span:nth-child(2) {display:inline-block;width:auto;margin-left:20px;float:inherit}

/*분양리스트*/
.onsale-sub-header {background-image:url(../images/sub/img_onsale.png);background-repeat:no-repeat;background-position:50%;background-size:cover;position:relative;}
.onsalelst .cont_inner {padding:80px 15px 0}
.onsalelst .cont_inner .tbl-tit {margin-bottom:20px;font-size:15px;}
.onsalelst .cont_inner .tbl-tit b {color:#CD0003}
.onsalelst .item-card-container {margin:0;margin-bottom:50px}
.onsalelst .item-card-container .item-card {width:100%;border:none;border-bottom:1px solid #eaeaea;padding:0;height:50px;margin:0}
.onsalelst .item-card-container .item-card:first-child {border-top:1px solid #777;background:#fafafa}
.onsalelst .item-card-container .item-card .favo_area {margin-left:5px;}
.onsalelst .item-card-container .item-card .item_info {display:flex;flex-direction:row;flex-wrap:wrap;width:100%;align-items:center;height:50px;min-height:50px}
.onsalelst .item-card-container span {display:inline-block;text-align:center;flex:1;}
.onsalelst .item-card-container .d_info01 {flex-grow: 3;margin-bottom:0;}
.onsalelst .item-card-container .d_info01 .danjiname {font-size:16px}
.onsalelst .item-card-container .address {flex-grow: 2;}
.onsalelst .cont_inner .filter-container {position:absolute;right:15px;top:65px;margin-right:0}
.onsalelst .cont_inner .filter-container li:last-child {margin-right:0}
.onsalelst .cont_inner .sub_filter_group {width:310px;}
.onsalelst .cont_inner .filter_lst:first-child .sub_filter_group {width:310px}
/*.onsalelst .cont_inner .filter_lst:last-child .sub_filter_group, .onsalelst .cont_inner .filter_lst:nth-child(3) .sub_filter_group {left:inherit;right:0}*/
.onsalelst .cont_inner .sub_filter_group {width:310px;padding-bottom:20px;}
.onsalelst .cont_inner .filter_lst:nth-child(4) .sub_filter_group {right:0;left:inherit}


.paging_area {margin-top:50px;text-align:center; }
.paging_area .paging, .paging_area .paging_web {display:inline-block;}
.paging_area .paging a{display:inline-block;overflow:hidden;width:34px;height:34px;}
.paging_area .paging a.a01_l {background:url(../images/sub/left_j.png) no-repeat;text-indent:-9999px;}
.paging_area .paging a.a02_l {background:url(../images/sub/left.png) no-repeat;text-indent:-9999px;margin-left:-1px;}
.paging_area .paging a.a01_r {background:url(../images/sub/right.png) no-repeat;text-indent:-9999px;}
.paging_area .paging a.a02_r {background:url(../images/sub/right_j.png) no-repeat;text-indent:-9999px;margin-left:-1px;}
.paging_area .paging a.on {color:#2a2a2a;}
.paging_area .paging_web {padding:0 10px;}
.paging_area .paging_web a {display:inline-block;width:34px;height:34px;overflow:hidden;text-align:center;line-height:34px;font-size:12px;color:#666666;font-weight:bold;text-decoration:none;background:url(../images/sub/pg_bg.png) no-repeat;margin-right:-1px;}
.paging_area .paging_web a.on, .paging_	area .paging_web a:hover {background:url(../images/sub/pg_bg_on.png) no-repeat;color:#FFF;}
.onsalelst .panel-group {position:fixed;top:0;z-index:1000;height:100%;border-left:1px solid #efefef}
.panel-header .btn-close {position:absolute;top:50%;left:10px;transform:translatey(-50%)}
.onsalelst .filter-group .fd-check, .reconstrlst .filter-group .fd-check {max-height:400px;overflow-y:scroll;padding-bottom:1px}

@media screen and (max-width: 944px) {
.onsalelst .filter-container {position:static;margin-bottom:15px;}
}

/* 재건축리스트 */
.reconstrlst .cont_inner {padding:80px 15px 0}
.reconstrlst .cont_inner .tbl-tit {margin-bottom:20px;font-size:15px;}
.reconstrlst .cont_inner .tbl-tit b {color:#CD0003}
.reconstrlst .item-card-container {margin:0;margin-bottom:50px}
.reconstrlst .item-card-container .item-card {width:100%;border:none;border-bottom:1px solid #eaeaea;padding:0;height:50px;margin:0}
.reconstrlst .item-card-container .item-card:first-child {border-top:1px solid #777;background:#fafafa}
.reconstrlst .item-card-container .item-card .item_info {display:flex;flex-direction:row;flex-wrap:wrap;width:100%;align-items:center;height:50px;min-height:50px}
.reconstrlst .item-card-container span {display:inline-block;text-align:center;flex:1;line-height:1.2}
.reconstrlst .item-card-container .reconstr:first-child span {margin-top:-3px}
.reconstrlst .item-card-container .d_info01 {flex-grow:3;margin-bottom:0;position:relative}
.reconstrlst .item-card-container .d_info01 .danjiname {font-size:16px}
.reconstrlst .item-card-container .address {flex-grow: 2;}
.reconstrlst .cont_inner .filter-container {position:absolute;right:15px;top:65px;margin-right:0}
.reconstrlst .cont_inner .filter-container li:last-child {margin-right:0}
.reconstrlst .cont_inner .sub_filter_group {width:310px;padding-bottom:20px;}
.reconstrlst .cont_inner .filter_lst:nth-child(4) .sub_filter_group {right:0;left:inherit}
.reconstrlst .panel-group {position:fixed;top:0;z-index:1000;height:100%;border-left:1px solid #efefef}

/* 학교상세 */
.schooldetail .panel-header .btn-left {position:absolute;top:8px;left:inherit;right:5px;}
.schooldetail .panel-header .btn-left .btn-back-wh {background:url(../images/sub/btn_close_wh.png) no-repeat center center;}
.schooldetail .panel-box {padding:0 0 20px;}
.mlst_tit .schoolname {font-size:16px;display:inline-block;vertical-align:middle}
.info_align {position:relative;margin-top:15px;}
.info {margin-bottom:10px}
.info:after {display:block;content:'';clear:both}
.info span {float:left}
.info span:nth-child(2) {float:right}
.tblbox li {padding-right:130px;position:relative;margin-bottom:7px;width:100%;box-sizing:border-box}
.tblbox li:last-child {margin-bottom:0}
.tblbox li a {display:block;width:100%;}
.tblbox li span {margin-right:5px;display:inline-block}
.tblbox li span:first-child {font-size:15px;max-width:200px;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;vertical-align:middle}
.tblbox li span:nth-child(2) {font-size:13px;color:#9d9d9d;margin-right:0;vertical-align:middle}
.tblbox li .right {position:absolute;right:0;top:0;}
.tblbox li span.area {font-size:13px;color:#9d9d9d;margin-right:5px}
.tblbox li span.price {color:#222;font-size:16px;font-weight:bold;display:inline-block;margin-bottom:0;margin-top:0}
.schooldetail .basicinfo {padding-bottom:0;margin-bottom:0;border-bottom:none}
.basicinfo + .school-info {padding-top:20px;margin-top:20px;border-top:1px solid #ededed}
.go_link {color:#7a1d05;font-size:15px;text-align:center;display:block}
.panel-header .tag06, .panel-header .tag07 {background:none;border:1px solid #fff;}
.schooldetail .basicinfo li span:last-child {width:75%}
.schooldetail .basicinfo li span .go_link {text-align:left}

/* 아파트 매물리스트 */
.panel-header .name-apt {color:#000;font-weight:400;font-size:20px;}
.item-card.apt {padding:12px;min-height:150px}
.item-card.apt .item_info {margin-bottom:0;min-height:125px;}
.name-apt {font-size:16px;font-weight:bold;line-height:1.4;}
.item-card .name-apt {font-weight:500}
.real-price {font-size:20px;font-weight:bold;display:block;padding:3px 0}
.item-card.apt .d_info03 .addinfo {font-size:14px;}
.panel-group .filter-container {width:100%;background:#fff;height:40px;padding:0 16px;border-bottom:1px solid #0000000d;box-sizing:border-box;margin-bottom:0}
.panel-group .filter-container .filter-item.sort {display:block;line-height:36px;font-size:14px;/*margin-left:20px;*/background:none;padding:0;height:40px;line-height:38px;height:40px;line-height:38px}
.panel-group .filter-container .selec02 {float:left;display:inline-block;padding-right:20px;background:url(../images/sub/ico_arrow_down.png) right center no-repeat;}
.panel-group .filter-container .filter-item {background:url(../images/main/btn_arr_down.png) no-repeat 95%;border:none;font-size:14px;height:40px;line-height:38px;padding:0 20px 0 10px;}
.filter_lst:last-child {/*flex:none;*//*margin-left:auto;*//*margin-right:60px*//*margin-right:0*/}
.complete:hover {border:1px solid #d9d9d9;cursor:default}
.item_flag {width:100%;height:100%;background:rgb(0,0,0,0.4);z-index:10;position:absolute;top:0;left:0;right:0;bottom:0;box-sizing:border-box;}
.sta_flag {position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);display:inline-block;}
.sta_flag .ico_check {display:inline-block;background:url(../images/sub/ico_check.png) no-repeat;width:20px;height:14px;margin-right:3px}
.sta_flag .flag_text01 {font-size:24px;color:#fff;display:block;}
.sta_flag .flag_text02 {font-weight:300;font-size:16px;color:#fff;text-align:center;display:block;}
.panel-group .filter-container .btn_space_box {left:55px;top:16px}
.panel-group .filter-container .sub_filter_group {padding:50px 20px 20px}
.item-card.apt .itm_alert_box {right:0;left:inherit;z-index:2000}
.item_naver {margin-top:20px}
.item_naver .h4_area {padding:10px 10px 10px 15px;;background:#44cc5b;}
.item_naver .h4_area h4 {display:inline-block;font-size:15px;color:#fff;font-weight:400;}
.item_naver .h4_area h4:before {display:inline-block;content:'';width:12px;height:12px;background:url(../images/sub/ico_n02.png) no-repeat;background-size:contain;margin-right:5px}
.item_naver .item-card-container {margin-top:0}
.item_naver .item-card-container li {margin-top:0;margin-bottom:10px}
.item_naver .item-card-container li:last-child {margin-bottom:0px}
.item_naver .cardrow span {font-size:15px;}
.item_naver .cardrow .deal_price {font-weight:600}
.item_naver .cardrow .d_info03 {font-size:14px;}
.go_naveritm {position:absolute;right:10px;top:6px;display:inline-block;font-size:12px;padding:3px 10px;border-radius:50px;border:1px solid #dadada;color:#44cc5b;}
.go_naveritm:before {display:inline-block;content:'';background:url(../images/sub/ico_n03.png) no-repeat;width:10px;height:10px;background-size:contain;margin-right:3px;}


/* 매물 상세 */
.bg_mask {width:100%;height:100%;background:#000;opacity:.4;z-index:1000;}
.pop.itemdetail {width:90%;max-width:900px;height:100%;padding:0;border:none;overflow-y:scroll;}
.pop_detail .close {position:absolute;top:10px;right:10px;width:50px;height:50px;display:inline-block;text-indent:-9999px;background:url(../images/sub/btn_close.png) no-repeat;z-index:5001;cursor:pointer;background-size:contain}
.itemdetail .panel-box {display:flex;flex:auto;position:relative;background:#fff;padding:0;height:100%}
.itemdetail .panel-box .itmbox {padding:20px 0 20px 30px ;background:#fff;margin-bottom:0}
.itemdetail .panel-box .itmbox:last-child {margin-bottom:50px}
.itemdetail .info_summury  {position:relative;}
.itemdetail .info_summury  {height:auto;border-bottom:0;padding:0;background:none;text-align:inherit}
.itemdetail .info_summury .btn_space_box {position:inherit;display:inline-block;margin-left:8px;margin-right:5px;top:inherit;right:inherit;vertical-align:middle}
.itemdetail .info_summury .d_info01 {display:block;margin-bottom:0}
.itemdetail .info_summury .d_info03 {display:inline-block;}
.itemdetail .info_summury .d_info03 .addinfo {font-size:15px;font-weight:400}
.itemdetail .info_summury .d_info03 .addinfo:before {display:inline-block;width:4px;height:4px;background:#444;margin:0 11px 4px 6px;content: '';border-radius:4px;}
.itemdetail .info_summury .d_info03 .addinfo:first-child:before {display:none}
.itemdetail .info_summury .real-price {font-size:25px;display:block;padding:0}
.itemdetail .info_summury .real-price span {display:inline-block}
.itemdetail .info_summury .favo_area {display:inline-block;position:relative;margin-left:5px;transform:none;right:inherit;}
.itemdetail .info_summury .itm_alert_box {left:0}
.itemdetail .tab_style04 {padding:0 0 20px 30px;background:#fff}
.pop.itemdetail .danji_img {padding-left:30px}
.img_box {width:100%;/*height:560px;*/margin-bottom:30px;position:relative}
.img_box .slide_imgbox {width:630px;height:350px;margin-bottom:10px;overflow:hidden}
.img_box .slide_imgbox img {width:100%;height:100%;object-fit:cover;object-position:50%}
.img_box .slide_thumb {overflow:hidden;width:630px;}
.img_box .slide_thumb ul {width:3000px;overflow:hidden;margin:-4px}
.img_box .slide_thumb ul li {display:inline-block;float:left;width:90px;height:60px;margin:4px;overflow:hidden}
.img_box .slide_thumb ul li img {width:100%;height:100%;object-fit:cover;object-position:50%;}
.info_summ {position:relative}
.info_summ .item_addr {position:relative}
.btn_rpt {display:inline-block;width:20px;height:20px;background:url(../images/sub/ico_report.png) no-repeat;cursor:pointer;position:absolute;right:0;bottom:10px;text-indent:-9999px;}
.tabcon_style03 .btn_rpt {top:0}
.btn_rpt:hover, .btn_rpt.on {background:url(../images/sub/ico_report_on.png) no-repeat}
.info_summ .item_price {position:relative;margin-bottom:20px}
.info_summ .address {font-size:16px;}
.summ_box {width:100%;/*padding:25px 0;border:1px solid #b9b9b9;*/box-sizing:border-box;margin-top:10px;text-align:left;margin-bottom:60px;position:relative}
.summ_box .summ {display:inline-block;font-size:16px;font-weight:600;color:#1e1e1e;width:400px}
.summ_box span:before {display:inline-block;width:4px;height:4px;background:#1e1e1e;border-radius:50px;margin:0 11px 0 6px;content: '';vertical-align:3px}
.summ_box span:first-child:before {display:none}
.summ_box span b {font-weight:600}
.itemdetail .name-apt {font-size:22px;font-weight:500;color:#000;vertical-align:middle;/*margin-left:5px*/}
.pop.itemdetail .danji_img + .itmbox {text-align:right;padding-top:0}
.pop.itemdetail .go_naver {display:inline-block;text-align:right;border:none;background:none;padding-right:15px;background: url(../images/main/btn_arr_rights.png) no-repeat 99% 14px;}
.pop.itemdetail .noimg {padding:0}
.pop.itemdetail .noimg .go_naver {position:absolute;top:-50px;right:0}
.itmbox.dealprice {padding:20px 0}
.itmbox.dealprice .tit {padding:0 15px}
.itmbox.dealprice .scroll-menu-container {padding:15px 15px 0;margin-bottom:20px;border-bottom:1px solid #eaeaea}
.itmbox.dealprice .itmbox_inner {padding:0 15px}
.itmbox.dealprice .scroll-menu-container .btn_space_box {top:-29px}

.itemdetail .panel-box .slider {height:350px;width:100%;}
.itemdetail .panel-box .slider img {width:100%;height:100%;object-fit:cover;object-position:50%}
.detail_left {width:calc(100% - 300px);display:inline-block;height:100%;overflow-y:scroll}
.itemdetail .panel-box .box-basicinfo li {font-size:16px;}
.itemdetail .panel-box .itmbox h3.tit {margin-bottom:15px;font-weight:300}
.info_detail .tab_style03 {margin-top:30px;}
.tabcon_style03 {position:relative;}
.tabcon_style03 .btn_space_box {right:0}
.tabcon_style03 .info_wrap {padding-top:30px;padding-bottom:30px;position:relative;}
.tabcon_style03 .info_wrap:first-child {padding-top:0}
.tabcon_style03 .info_wrap:last-child {border-bottom:none}
.opt_grp {overflow:hidden;display:table;}
.opt_grp span {display:table-cell;width:25%;float:left;/*padding:5px 15px;*/text-align:center;/*margin:2px;border:1px solid #efefef;*/border-radius:30px;font-size:14px}
.opt_grp span.yes {padding-top:40px;/*padding-bottom:15px;*/margin:10px 0}
.opt_grp span.opt01.yes {background: url(../images/sub/opt01.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt02.yes {background: url(../images/sub/opt02.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt03.yes {background: url(../images/sub/opt03.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt04.yes {background: url(../images/sub/opt04.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt05.yes {background: url(../images/sub/opt05.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt06.yes {background: url(../images/sub/opt06.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt07.yes {background: url(../images/sub/opt07.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt08.yes {background: url(../images/sub/opt08.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt09.yes {background: url(../images/sub/opt09.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt10.yes {background: url(../images/sub/opt10.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt11.yes {background: url(../images/sub/opt11.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt12.yes {background: url(../images/sub/opt12.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt13.yes {background: url(../images/sub/opt13.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt14.yes {background: url(../images/sub/opt14.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt15.yes {background: url(../images/sub/opt15.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt16.yes {background: url(../images/sub/opt16.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt17.yes {background: url(../images/sub/opt17.svg) no-repeat center 2px;background-size:35px}
.detail_right {position:relative;width:300px;height:100%;/*overflow-y:auto;*/}
.noti_text .p01 {font-size:24px;font-weight:400;letter-spacing:-2px}
.noti_text .p02 {font-size:14px;padding-top:5px}
.itemdetail .panel-box .contactag {padding-left:20px;/*padding-top:73px;position:fixed;right:0*/overflow:hidden;padding-top:210px}
.itemdetail .panel-box .contactag .cont-wrap {overflow-y:scroll;height:100%;position:relative;z-index:100}
.itemdetail .panel-box .contactag .noti_text {position:fixed;top:142px}
.contactag .agent-list {justify-content:left;/*padding-top:55px*/overflow-y:scroll;height:auto;padding-top:0}
.contactag .agent-card {min-width:144px;max-width:100%;}
.contactag .agent-card .itm_inner {padding:8px}
.contactag .agent-list .thumbnail_area {position:absolute;top:8px;left:20px;width:60px;height:60px;display:inline-block;transform:inherit}
.contactag .itm_inner .agent_info {padding-left:90px;padding-top:0}
.contactag .star_score {display:block;margin-top:-5px}
.contactag .itm_inner .ct_review {display:inline-block}
.contactag .broker_info p {line-height:1.35}
.contactag .broker_info p.bk_office {font-size:14px;color:#222;margin-bottom:2px;margin-top:2px}
.contactag .broker_info p.name {font-size:16px;}
.ct_review {font-size:13px;color:#4a70c7;margin-left:3px;letter-spacing:-1px;line-height:13px;display:inline-block;vertical-align:middle}
.contactag .btn-box {text-align:center;position:absolute;left:14px;top:62px;z-index:100;}
.pop .contactag button:disabled {background-color:#C9C9C9;color:#fff;pointer-events:none}
.pop .btn.btn-question {display:inline-block;font-size:13px;line-height:25px;border-radius:30px;padding:0 10px;height:25px;cursor:pointer;margin-top:0;border:1px solid #e5e5e5;background:#fff;color:#1e1e1e;transition-duration:0.4s;}
.pop .btn.btn-question:hover {background:#7a1d05;color:#fff;}
.contactag .btn-box +.item_inq {padding:0 15px 15px}
.contactag .btn-box +.item_inq.hide {padding:0}
.item_inq .txtareaout {border:1px solid #ccc;height:100px;margin-bottom:-1px}
.contactag .item_inq .btn-box {position:static;left:inherit;top:inherit}
.contactag .item_inq .btn.btn-question {width:100%;height:35px;font-size:14px;border-radius:0;background:#7a1d05;color:#fff;margin-top:0;}
.state {font-size:26px;margin-bottom:10px;text-align:center}
.state:before {background:#fc384a;border-radius:50%;content: "";height:8px;width:8px;display:inline-block;margin-right:7px;vertical-align:4px}
.contactag .btn-box .compl_apply button {width:100%;margin-right:0}
.cancel {margin-top:5px;text-align:right}
.cancel a {display:inline-block;font-size:12px}
.item_basic .tbl_type01 {text-align:center}
.tbl_type01 th.impt {background:#efefef}
.pee_wrap {text-align:left}
.pee_wrap .p_txt {text-align:left;font-size:18px}
.bk_pee {padding:20px 0;display:inline-block;width:200px;text-align:center}
.bk_pee ul{height:60px;margin:0;padding:0;font-size:12px;list-style:none;display:inline-block}
.bk_pee ul:after{display:block;clear:both;content:""}
.bk_pee li{display:inline-block;float:left;position:relative;width:80px;height:100%;margin:0 8px;text-align:center;white-space:nowrap;}
.bk_pee li:first-child {margin-left:0}
.bk_pee .gTerm{height:20px;margin:0 -100% -20px 0;padding: 60px 0 0;font-weight:400;color:#767676;line-height:20px;}
/*itm{display:inline-block;position:relative;width:100%;margin:-1px 0 0 0;background:#bdbdbd;vertical-align:bottom}*/
.bk_pee .gBar {display:inline-block;position:relative;width:100%;vertical-align:bottom;margin: -1px 0 0;background:#dadada;}
.bk_pee .gTerm {display:inline-block;position:relative;width:100%;vertical-align:bottom;}
.bk_pee .gBar.dawinpee {background:#9f2100;font-size:15px;}
.bk_pee .gBar.dawinpee span {font-size:17px;color:#9f2100;top:-28px;font-weight:bold}
.bk_pee .gBar span{position:absolute;top:-20px;left:50%;transform:translateX(-50%);display:block;/*width:100%;*/color:#767676}
.bk_pee li + li .gTerm {font-size:13px;color:#9f2100}
.p_txt {text-align:center;margin-bottom:10px;font-size:15px;}
.plan {text-align:right}
.go_naver {display:inline-block;font-size:13px;font-weight:400;padding-top:5px;padding-right:14px;background:url(../images/main/btn_arr_rights.png) no-repeat 99% 10px}
.itemdetail .panel-box .itmbox.peebox {position:absolute;right:0;top:0;width:300px;padding-left:20px;}
.peebox .tit {display:none}
.contactag .itm_msg {top:0}

@media screen and (max-width: 768px) {
.itemdetail .panel-box {display:block}
.pop.itemdetail {width:100%}  
.itemdetail .panel-box .itmbox {padding:20px;background:#fff}  
.itemdetail .tab_style04 {padding:0 20px 20px;background:#fff}
.info_detail {display:block;}
.detail_left {width:100%;padding-right:0}
.detail_right {width:100%;overflow-y:visible}
.ag-request .agent-card {flex-basis:48%;}

}

/* 210302 단지시설 추가 */
.danjiinfo_grp {overflow:hidden;display:table;}
.danjiinfo_grp span {display:table-cell;width:25%;float:left;/*padding:5px 15px;*/text-align:center;/*margin:2px;border:1px solid #efefef;*/border-radius:30px;font-size:13px}
.danjiinfo_grp span.yes {padding-top:45px;/*padding-bottom:15px;*/margin:10px 0}
.danjiinfo_grp #facility_FIT.yes {background: url(../images/sub/img_danjiinfo01.svg) no-repeat center 2px;background-size:52px;}
.danjiinfo_grp #facility_GX.yes {background: url(../images/sub/img_danjiinfo02.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_STU.yes {background: url(../images/sub/img_danjiinfo03.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_LIB.yes {background: url(../images/sub/img_danjiinfo04.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_POO.yes {background: url(../images/sub/img_danjiinfo05.svg) no-repeat center 4px;background-size:42px}
.danjiinfo_grp #facility_SAU.yes {background: url(../images/sub/img_danjiinfo06.svg) no-repeat center 2px;background-size:45px}
.danjiinfo_grp #facility_GOL.yes {background: url(../images/sub/img_danjiinfo07.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_GUH.yes {background: url(../images/sub/img_danjiinfo08.svg) no-repeat center 4px;background-size:36px}
/*.danjiinfo_grp #facility_DLB.yes {background: url(../images/sub/img_danjiinfo09.svg) no-repeat center 2px;background-size:38px}*/
.danjiinfo_grp #facility_DLB.yes {background: url(../images/sub/img_danjiinfo10.svg) no-repeat center 5px;background-size:38px}
.danjiinfo_grp #facility_CAF.yes {background: url(../images/sub/img_danjiinfo11.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_KCF.yes {background: url(../images/sub/img_danjiinfo12.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_WP.yes {background: url(../images/sub/img_danjiinfo13.svg) no-repeat center 2px;background-size:36px}
.danjiinfo_grp #facility_MUL.yes {background: url(../images/sub/img_danjiinfo14.svg) no-repeat center 2px;background-size:34px}
.danjiinfo_grp #facility_THE.yes {background: url(../images/sub/img_danjiinfo15.svg) no-repeat center 6px;background-size:37px}
.danjiinfo_grp #facility_RES.yes {background: url(../images/sub/img_danjiinfo16.svg) no-repeat center 2px;background-size:36px}
.danjiinfo_grp #facility_LOU.yes {background: url(../images/sub/img_danjiinfo17.svg) no-repeat center 2px;background-size:32px}
.danjiinfo_grp #facility_SEN.yes {background: url(../images/sub/img_danjiinfo18.svg) no-repeat center 2px;background-size:30px}
.danjiinfo_grp #facility_BAD.yes {background: url(../images/sub/img_danjiinfo19.svg) no-repeat center 4px;background-size:32px}
.danjiinfo_grp #facility_TAT.yes {background: url(../images/sub/img_danjiinfo20.svg) no-repeat center 2px;background-size:30px}
.danjiinfo_grp #facility_BAS.yes {background: url(../images/sub/img_danjiinfo21.svg) no-repeat center 3px;background-size:36px}
.danjiinfo_grp #facility_TAN.yes {background: url(../images/sub/img_danjiinfo22.svg) no-repeat center 2px;background-size:30px}
.danjiinfo_grp #facility_STO.yes {background: url(../images/sub/img_danjiinfo23.svg) no-repeat center 6px;background-size:41px}
.danjiinfo .btn-box .btn-basic {width:100%;height:40px;margin-top:10px;}
.danjifc-cont {padding:10px 20px 50px;}
.danjifc-cont form {margin-bottom:20px;}
.danjifc-cont .lbl {margin-bottom:8px;display:block;font-size:14px;}
.pop .danjifc-cont {text-align:left;padding:0}
.pop .danjifc-cont .check-box03 > .chk01 > input[type=checkbox].chk_danjiinfo + label {width:105px;}

/* 매물상세단지정보 */
.itemdetail .map {position:relative;height:400px;border:1px solid #b9b9b9;box-sizing:border-box;margin-top:10px;margin-bottom:100px;background:url(../images/sub/img_map_ex02.png) no-repeat;background-size:cover;}
.map_around_navi {position:absolute;bottom:-38px;left:50%;margin-left:-258px}
.map_around_navi ul li {display:inline-block;float:left;padding:0 8px;position:relative;height:30px;}
.posi_lbl {position:absolute;bottom:-20px;left:50%;width:50px;text-align:center;margin-left:-25px;font-size:12px;}
.map_around_navi .posi {background-position:top;}
.pop_detail .danji_info01 {padding:0}
.pop_detail .danji_info01 .tab_con .price {margin:10px 0 20px}
.pop_detail .maparea .agent_card {position:absolute}
.detail_left .danji_info01 ul:after {display:inline-block;content:'';clear:both}
.detail_left .commt_box {color:#333;font-size:14px;font-weight:400;padding:20px;background:#f9f9f9}
.pop_detail .tab_style02 {top:65px}
.detail_left .graph_area {width:100%;margin:10px 0}
.detail_left .graph_area img {width:100%;height:100%;object-fit:cover;object-position:50%;}

/* 중개사 페이지 */
.agent-content {max-width:1000px;padding:60px 20px 0;margin:0 auto;position:relative;min-height:1000px;display:flex;flex-wrap:wrap;flex-basis:auto}
.agent-content .left {width:70%;padding-right:60px}
.agent-content .right {width:30%;}
.agentpage .itmbox {position:relative;margin-bottom:50px;padding:0}
.agentpage .itmbox h2.tit {font-size:24px;margin-bottom:10px;font-weight:500}
.agentpage .itmbox h3.tit {font-size:20px;padding-left:0}
.intro .thumbnail_area {width:160px;height:160px;display:inline-block;border-radius:200px;overflow:hidden;border:1px solid #eaeaea;left:0;top:0;transform:none}
.intro .thumbnail_area .thumbnail {width:100%;height:100%;border-radius:200px;background:url(../images/sub/thumb_agent02.png) no-repeat 50%;background-size:contain;/*text-indent:-9999px*/}
.intro .agent_info {padding-left:190px;}
.intro .broker_info {font-size:25px}
.intro .broker_info .bk_name {font-size:36px}
.intro .star_score {margin-top:2px} 
.intro .count_review {font-size:16px;vertical-align:-2px;margin-left:5px;pointer-events:none;cursor:default;}
.intro .p_num {font-size:36px;line-height:1.4}
.intro .offi_name {display:block;font-weight:bold;font-size:16px}
.intro .offi_addr {font-size:15px;font-weight:300}
.intro .rating_area {padding:0;margin:0}
.greeting .subj {font-size:24px;font-weight:500;}
.greeting .cont {font-size:15px;line-height:1.6}
.career p {font-size:15px;}
.business-img {padding-bottom:30px;margin-top:40px}
.business-img .lst_attached {margin:10px 0 0}
.business-img .lst_attached .react_lightgallery_item {width:130px;height:165px;margin-right:10px}
.business-img .lst_attached .react_lightgallery_item li {width:100%;height:100%;margin:0}
.business-img .btn-file-wrap {width:100%;height:100%}
.business-img .preview:hover:after {position:absolute;left:0;right:0;top:0;bottom:0;z-index:10;display:inline-block;width:129px;height:165px;content:'';background: url(../images/sub/img_file.hover.png) no-repeat;cursor:pointer;}
.empl_lst {display:flex;justify-content:left;padding:0}
.empl_lst li {display: block;margin-right:5px;width:190px;border:1px solid #e0e0e0;box-sizing:border-box;background:#fff;position:relative;padding:10px}
.empl_lst li:last-child {margin-right:0}
.empl_lst li .agent_info {display:block;padding-left:70px;/*height:90px;*/text-align:left;padding-top:5px;padding-bottom:5px}
.empl_lst li .bk_name {font-size:16px;font-weight:600}
.empl_lst li .p_num {font-size:14px}
.review_lst .star_score {padding-top:0;margin-bottom:10px}
.review_lst li {padding:15px 0;border-bottom:1px solid #d0d0d0}
.review_lst .cont {font-size:14px;margin-bottom:1px}
.date {font-size:13px;color: color(normal, 030);}
.f_text {font-size:14px;width:128px;text-align:center;position:absolute;bottom:-28px;left:0}
.agentpage .map {width:100%;height:300px;position:relative;border:1px solid #b9b9b9;box-sizing:border-box;margin-top:10px;background: url(../images/sub/img_map_ex02.png) no-repeat;background-size: cover;}
.movie_box .con_box {border:none;padding:0;}
.movie_box .media {position:relative;width:100%;height:0;padding-bottom:63.25%;}
.movie_box .media iframe {position:absolute;top:0;left:0;width:100%;height:100%;}
.agentpage .slider-container {border:1px solid #ccc;position:relative}
.img_slider {width:100%;height:200px;}
.img_slider li {display:block;width:100%;height:100%}
.img_slider img {width:100%;height:100%;object-fit:cover;object-position:50%;}
.pagination a {width:30px;height:30px;position:absolute;top:50%;transform:translatey(-50%);background-size:contain;z-index:100}
.pagination .btn_prev {background-image:url(../images/sub/btn_prev.png) ;background-repeat:no-repeat;left:15px;}
.pagination .btn_next {background-image:url(../images/sub/btn_next.png);background-repeat:no-repeat;right:15px;}
.ag_qna .btn-horiz .btn {margin:0;width:100%;border-radius:0}
.agentpage .intro {width:70%;padding-right:40px;float:left}
.agentpage .ag_qna {width:30%;float:right;}
.agentpage .greeting {order:3;width:70%;padding-right:40px}
.agentpage .pic_office {order:6;width:30%}
.agentpage .career {order:5;width:70%;padding-right:40px}
.agentpage .location {order:4;width:30%}
.agentpage .employee {order:7;width:70%;padding-right:40px}
.agentpage .video {order:8;width:30%}
.agentpage .review {order:9;width:70%;padding-right:40px}


.panel-group.agentintro .panel-box .itmbox {
	margin-bottom:10px;
}
.panel-group.agentintro .panel-box .itmbox.intro {
	display:flex;
	flex-flow:row wrap;
	justify-content: center;
	row-gap:8px
}
.panel-group.agentintro .panel-box .agent_info {
	padding-left:0;
	text-align:center
}
.panel-group.agentintro .panel-box .itmbox.intro .intro .thumbnail_area {
	width:100px;
	height:100px;
}
.panel-group.agentintro .panel-box .itmbox.intro .p_num {
	font-size:30px;
}
.panel-group.agentintro .panel-box .thumbnail_area {
	position:unset;
	transform:unset
}
.business-img .lst_attached .react_lightgallery_item {
	width: calc(33.3333% - 6px);
}
.panel-group.agentintro .panel-box .empl_lst li {
	width:inherit;
	border:none;
	text-align: center;
}
.panel-group.agentintro .panel-box .review_lst li:last-child {
	border-bottom:0;
}
.panel-group.agentintro .panel-box .review_lst li+div {
	text-align: center;
}


@media screen and (max-width: 768px) {
.agent-content {flex-direction: column}
.intro, .ag_qna, .greeting, .pic_office, .career, .location, .employee, .video, .review {display:block;width:100%}
}

/* mypage */
.mp-sub-header {height:160px;background-image:url(../images/sub/img_mp.png);background-repeat:no-repeat;background-position:50%;background-size:cover;position:relative}
.mp .cont_inner {display:flex;max-width:1100px;padding-top:54px;position:relative}
.snb {width:200px;margin-right:50px;box-sizing:border-box}
.sub_cont {width:100%;max-width:767px}
.snb ul {border:1px solid #ccc;padding-top:20px;padding-bottom:20px;}
.snb ul li a {display:block;width:198px;padding:15px 0 15px 30px;box-sizing:border-box;}
.snb ul li a:hover, .snb ul li a.on {background:#7a1d05;color:#fff;}
.mp .lyr .radio {display:block;margin:10px 0}
.mp .lyr .radio:last-child {margin-bottom:10px}

@media screen and (max-width: 768px) {
.mp .cont_inner {display:block;padding-top:80px}
.snb {width:100%;position:absolute;left:0;top:0}
.snb ul {padding:0;border:none;border-bottom:1px solid #ccc}
.snb ul:after {display:block;content:'';clear:both}
.snb li {float:left;width:calc(100% / 4)}
.snb ul li a {width:100%;padding:15px;text-align:center}
}

/* 개인정보 */
.mybox {position:relative;padding-bottom:50px}
.mpwrap .add_t {color:#ec1b1b}
.mpwrap .adt02 {color:#888;margin-bottom:10px}
.tit .m_exp {display:inline-block;font-size:16px;font-weight:400}
.tit .m_exp:before {display:inline-block;margin:0 20px;width:1px;height:11px;background-color:#e5e5e5;vertical-align:-1px;content:""}
.btn-leaveid {position:absolute;bottom:10px;right:10px;color:#b7b7b7;padding:0;font-size:13px;}
.btn-logout {position:absolute;bottom:10px;left:10px;color:#b7b7b7;padding:0;font-size:13px;}
.mp .inquery .add_text {color:red}
.mp .lyr .inp-verti {margin-bottom:10px}

/* 내가내놓은매물 */
.mp .cont_inner .sub_cont {max-width:100%;position:relative}
.mp .msg {text-align:center;padding:50px}
.mybox .btn-box {margin-top:20px}
.mybox .bolder {font-size:17px;margin-bottom:10px}
.mp .item-card {width:calc(100% / 2 - 10px);min-width:300px}
.mp .cont_inner .sub_cont .item_info {padding-bottom:10px;min-height:100px;}
.mp .sub_cont>.tab_style03 {position:relative;margin-bottom:30px;}
.mp .sub_cont>.tab_style03 .tab {overflow: visible;display:block;width:100%;height:40px;}
.mp .sub_cont>.tab_style03 .tab:after {display:block;content:'';clear:both;}
.mp .sub_cont>.tab_style03 .tab li {float:left;box-sizing:border-box;width:calc(100% / 4);margin-right:0}
.mp .sub_cont>.tab_style03 li a {display:block;overflow:hidden;border:1px solid #e1e1e1;border-width:1px 1px 0 0;color:#757575;font-size:14px;text-align:center;padding-top:10px;padding-bottom:9px;background:#f8f8f8;border-bottom:1px solid #1e1e1e;}
.mp .sub_cont>.tab_style03 li.on a {border-top:1px solid #1e1e1e;border-right:1px solid #1e1e1e;border-left:1px solid #1e1e1e;border-bottom:1px solid #fff;color:#000;background-color:#fff;margin-left:-1px;}
.mp .sub_cont>.tab_style03 li:first-child a {border-left-width:1px}
.mp .sub_cont>.tab_style03 li:first-child.on a {margin-left:0}
.mp .sub_cont>.tab_style03 li.on a:before {display:none;}
.mp .sub_cont>.tab_style03 li + li a {position:relative}
.lyr_inner .form-rdbk {padding:25px 10px 0}
.lyr_inner .form-rdbk .radio-box strong {font-size:16px;}
.avr_area .star_score ouput {font-size:20px;margin-left:10px;vertical-align:middle;}
.mp .item_thumb {height:98px}
.mp .panel-group {position:fixed;top:0;z-index:2001;height:100%;border-left:1px solid #efefef;}
.mp .panel-group.itemlist .item-card {width:360px;}
.deleteitm .selec_option {border:none;position:inherit;padding-bottom:0;padding-top:0}

@media screen and (max-width: 650px) {
.mp .item-card {width:100%}
}
.item-card.contr-card, .item-card.cancel-card {padding-bottom:0}
.mybox .contr-card .item_flag {height:calc(100% - 60px)}
.mybox .cancel-card .item_flag {height:calc(100% - 60px)}
.mysell_btn {border-top:1px solid #d9d9d9;text-align:center;padding:15px 0 15px;width:100%;}
.mysell_btn .inp-horiz {justify-content:center}
.mysell_btn .btn {width:100%;height:40px;margin-right:10px}
.mysell_btn .btn:last-child {margin-right:0}
.cancel-card .mysell_btn .btn {width:100%}

/*추천history*/
.recomm-card span {display:block;}
.item-card.recomm-card {padding-bottom:12px;}
.item-card.recomm-card .item_info {width:100%;min-height:auto;margin-bottom:0;padding-bottom:0}
.recomm-date {font-size:15px;color:#BBBBBB;margin-bottom:5px;}
.recomm-selec b {font-size:16px;}
.recomm-Priority {font-size:16px;}
.recomm-Priority b {font-size:18px;}
.recomm-Priority .selected01 {color:#c43713}
.recommhitory .panel-box {margin-top:0}
.recomm_rnk {font-size:18px;color:#c43713;display:inline-block;vertical-align:middle;margin-right:5px;font-weight:600}
.recommlst {position:fixed;top:65px;height:calc(100% - 65px)}
.mp .recommlst .item-card {width:394px}
.recomm-Priority b:after {display:inline-block;content:'>';font-size:16px;color:#666;margin:0 5px;font-weight:400;}
.recomm-Priority b:last-child:after {display:none}
.mlst_tit .recomm-selec {display:block;margin-bottom:-2px}
.mlst_tit .recomm-selec b {font-size:14px;font-weight:400}
.mlst_tit .recomm-Priority b {font-size:15px;font-weight:500}
.mlst_tit .recomm-Priority b:after {display:inline-block;content:'>';font-size:15px;color:#fff;margin:0 5px;font-weight:300;}
.mlst_tit .recomm-Priority b:last-child:after {display:none}
.mlst_tit .recomm-Priority .selected01 {color:#ffc000}

/* 1:1문의내역 */
.mp ul.myfaq li .qitem, .mp ul.myfaq li .aitem {padding-left:140px;position:relative}
.mp ul.accordian li .aitem {background:none}
.mp ul.accordian li .aitem:hover, .mp ul.accordian li .aitem.on {background:none}
.mp ul.accordian li:first-child .qitem {border-top:1px solid #c3c4c8}
.flag_qa {position:absolute;display:inline-block;left:10px;top:15px;color:#fff;padding:1px 5px 3px;font-size:12px;font-weight:100;border-radius:2px}
.flag_qa.no {background:#e0e0e0;border:1px solid #c2c2c2}
.flag_qa.yes {background:#912c0c;border:1px solid #631800}
ul.myfaq li .aitem {border-top: 1px solid #d9d9d9;background:#fff}
ul.myfaq li .qitem b, ul.myfaq li .aitem b {position:absolute;left:-35px;top:2px;color:#1e1e1e}
ul.myfaq li .qitem .qbox, ul.myfaq li .aitem .abox {position:relative}
.myfaq .name-apt {color:#222;display:block;font-size:14px;font-weight:500}
.myfaq .address {font-weight:400;color:#767676;display:block;padding-top:2px;font-size:13px;margin-bottom:5px;}
.myfaq .q_cont, .myfaq .a_cont, .myfaq .c_cont {display:block;margin-bottom:5px}
.myfaq .ag_info {display:block;font-size:13px;margin-bottom:2px;color:#202020;font-weight:500}
.myfaq .date {font-size:13px;color: color(normal, 030);font-weight:400}
.myfaq .abox {padding-left:60px;position:relative}
.myfaq .ad_ans {padding-left:0}
.myfaq .abox .thumbnail_area {position:absolute;top:5px;left:0;width:40px;height:40px;display:inline-block;transform:inherit}
.myfaq .abox .thumbnail {width:100%;height:100%;background: url(../images/sub/thumb_agent02.png) no-repeat center center;background-size:contain}
ul.myfaq li .aitem:hover, ul.myfaq li .aitem.on {background:none}
.q_cate {font-size:14px;font-weight:500;}
.q_contdetail {display:block;font-weight:500;font-size:14px;margin-bottom:10px}

/* 새로운 비밀번호 설정 */
/*.setcontainer {min-height:600px;box-sizing:border-box;}*/
.setcontainer .vt-area {width:500px;padding:100px 0 80px;text-align:center;margin:0 auto}
.setcontainer .vt-area .ntc_p {font-size:34px;text-align:center;padding-bottom:30px}
.newp_area {display:inline-block;width:500px;;margin-bottom:30px}
.newp_area .pwinp {margin-bottom:10px;padding:0 20px;height:50px}
.setcontainer .vt-area .btn-area {margin-top:40px}
.setcontainer .vt-area .tit + p {font-size:24px;text-align:center}
.set_noti .tit small {font-size:24px}
.set_noti {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.alert_msg {font-size:13px;text-align:left;padding-top:5px;color:#888888}
.alert_msg.red {color:#B60003}
.inp.error {border-color:#B60003}

/* 평형전환 */
.btn_space_box {position:absolute;right:30px;top:16px}
.btn_space_box:after {display:block;content:'';clear:both}
.btn_space {font-size:12px;line-height:18px;letter-spacing:-.5px;position:relative;top:-1px;width:21px;height:19px;margin-left:-1px;border:1px solid #d9d9d9;background-color:rgba(0,0,0,0.02);text-align:center;vertical-align:middle;color:#777;float:left}
.btn_space.on {/*z-index:10;*/border-color:#999;background-color:#fff;color:#2b2c2e;}

/*매물대행서비스 배너*/
.ban_area {position:fixed;right:50px;top:350px;z-index:300}
.ban_area a.link_sv {display:inline-block;background:url(../images/sub/img_banner.png) no-repeat;width:140px;height:142px;text-indent:-9999px}
.ban_area a.link_sellph {display:inline-block;background:url(../images/sub/img_banner02.png) no-repeat;width:130px;height:183px;text-indent:-9999px}

/* 404error */
#dawinWrap.fullpage {padding-top:0}
.fullpage {width:100%;height:100vh}
.fullpage .err_content {width:100%;height:100vh;position:relative}
.err_txtarea {width:800px;height:350px;position:absolute;top:20%;left:50%;margin-left:-400px;text-align:left;z-index:2;font-size:80px}
.err_txt {display:inline-block;width:461px;height:266px;background:url(../images/sub/img_errortxt.png) no-repeat;text-indent:-9999px}
.err_bg {width:100%;height:750px;background:url(../images/sub/img_errorbg.png) no-repeat 50% 0;position:absolute;bottom:50px;left:0;right:0;z-index:1;background-position:50% 50%;background-size:contain;background-repeat:no-repeat}
.err_content .double {display:block;margin-top:30px}
.err_content .double button {padding:0 20px}
.err_content .double button:first-child {margin-right:10px}

.m_exp .em {border-bottom:1px solid #ffd200;box-shadow:inset 0 -7px #ffd200}

.lyr.appdown {width:450px;padding:40px}
.lyr.appdown .ntc_p {font-size:30px}
.lyr.appdown .ntc_p strong {font-size:30px}
.lyr.appdown .btn-box {margin-top:10px}
.lyr.appdown .btn {width:100%}

/*.bnbox {width:100%;height:70px;position:absolute;top:50px;left:0;z-index:10}*/
.bnbox .btn-right {top:20px;right:50px}
.bnbox .bn_open {display:block;width:100%;height:70px;background:url(../images/main/bn_open.png) no-repeat;background-size:cover;background-position:50%;}

.lyrpop_analy.infopop {border:none}
.lyrpop_analy.infopop .lyrpop_wrap {background:#483430;height:630px}
.lyrpop_analy.infopop .lyrpop_wrap .btn-close-wh {position:absolute;right:10px;top:10px}
.lyrpop_analy.infopop .lyr_inner {height:100%}
.lyrpop_analy.infopop .lyr_inner .txt {width:420px;box-sizing:border-box;position:absolute;top:50px;left:40px;letter-spacing:-.7px}
.lyrpop_analy.infopop .lyr_inner .txt p {color:#fff;font-size:12px;line-height:1.6;padding:0}
.lyrpop_analy.infopop .lyr_inner .txt p .yellow {color:#e3ca48;font-weight:bold}
.lyrpop_analy.infopop .lyr_inner .txt .p01 {font-size:24px;font-weight:bold;line-height:1.4}
.lyrpop_analy.infopop .lyr_inner .txt .p02 {font-size:38px;font-weight:bold;line-height:1.3}
.lyrpop_analy.infopop .lyr_inner .txt .p03 {font-size:44px;font-weight:bold;line-height:1.2;margin-bottom:10px}
.lyrpop_analy.infopop .lyr_inner .content {background:url(../images/sub/img_pop.png) no-repeat;width:450px;height:380px;position:absolute;bottom:0;right:0;background-size:100% auto}

.ico_map:before {}

/* 뉴스 */
.news .sub-header {height:160px;background-image:url(../images/sub/img_newsheader.png);background-repeat:no-repeat;background-position:50%;background-size:cover;position:relative;}
.news .m_exp {font-size:15px;margin-bottom:10px;}
.news .catebox {padding:15px;background:#eaeaea;margin-bottom:20px;}
.news .catebox .selec, .news .catebox .inp {height:40px;}
.news .item_container {width:100%;display:flex;align-items: center;justify-content:flex-start;flex-flow: row wrap;}
.news .item {flex-basis:calc((100% - 6%) / 5);box-sizing:border-box;border:1px solid #e0e0e0;margin-bottom:8px;padding-top:calc((100% - 6%) / 5);margin:0.5%;align-items:center;position:relative}
.news .react_lightgallery_item {display:inline-block;width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0}
.news .react_lightgallery_item:hover {transform: scale(1.1);box-shadow: 0 1px 2px rgba(0,0,0,0.15);transition: box-shadow 300ms ease-in-out, transform 300ms ease-in-out;-webkit-box-shadow: -8px 11px 20px 2px rgba(0,0,0,0.44); 
box-shadow: -8px 11px 20px 2px rgba(0,0,0,0.44);z-index:1}
.news .react_lightgallery_item img {width:100%;height:100%;object-fit:cover;}
.lyr.popnews {max-width:600px;max-height:80%;padding:0;min-height:auto;}
.lyr.popnews .lyr_inner {padding:0}
.lyr.popnews .lyr_inner .box_news {height:auto;}
.lyr.popnews .lyr_inner .box_news img {display:inline-block;vertical-align:middle;max-width:100%;max-height:100%;width:100%;height:auto;}
.lyr.popnews .btn-right {right:-30px;top:-30px;}

.news .catebox option {position:relative}
.news .catebox option[value="maburi_on"] {width:15px;height:15px;background:url(../images/sub/ico_maburi02.png) no-repeat;} 
.news .ban_area {position:relative;top:unset;left:unset;right:unset;min-height:80px;box-sizing:border-box;margin:0 0 15px;border-radius:5px;background:rgb(231, 239, 251);padding:20px;}
.news .ban_area .ban_inner {position:relative;display:block;padding-left:120px;padding-right:100px;}
.news .ban_area .maburi:before {display:block;content:'';width:110px;height:45px;background:url(../images/sub/ico_maburi.png) no-repeat;background-size:contain;position:absolute;left:10px;top:0;} 
.news .ban_area .subject {font-weight:600;}
.news .link_maburi {display:inline-block;padding:5px 25px 5px 14px;border-radius:50px;background:rgb(74, 137, 233);color:#fff;position:absolute;right:20px;top:50%;transform:translatey(-50%);font-size:13px;}
.news .link_maburi:after {display:inline-block;content:'';background:url(../images/sub/ico_arrowr.png) no-repeat;background-size:contain;width:9px;height:9px;position:absolute;right:10px;top:9px;}
.main .bnbox .quickbn {width:201px;height:247px;background:url(../images/sub/img_quickbn07.png) no-repeat;position:fixed;right:30px;top:330px;z-index:500;text-indent:-9999px;cursor:pointer}

/* 210402 중개사회언가입, 로그인 버튼수정*/
.bklogin_wrap .btn-group {display:flex;flex:1;flex-direction: row;justify-items: flex-end}
.bklogin_wrap .p01 {margin-top:35px;font-size:16px;margin-bottom:15px;display:flex;flex-direction:row;}
.bklogin_wrap .p01:before, .bklogin_wrap .p01:after {content:"";flex: 1 1;border-bottom:1px solid #b9b9b9;margin:auto;margin-right:10px;margin-left:10px;}
.pop .bklogin_wrap .btn-group .btn {margin:5px;font-size:14px;}

.bkjoin {background:#7a1d05;border-radius:46px;height:44px;color:#fff;margin:0;}
.bklogin {background:#fff;border-radius:46px;height:44px;color:#222;border:1px solid #b9b9b9;margin:0}

.btn.btn-bkjoin {background:#7a1d05;border-radius:46px;height:44px;color:#fff;}
.btn.btn-bklogin {background:#fff;border-radius:46px;height:44px;color:#222;border:1px solid #b9b9b9;}

/*210406 중개사리스트 sort*/
.contactag .filter_wrap {position:fixed;top:176px;width:290px}
.contactag .filter_lst {float:left;display:inline-block;/*width:20%;*/flex:none;margin-right:0}
.contactag .filter_lst .filter-item {border:none;background:none;padding:6px;color:#666}
.contactag .filter_lst .filter-item:hover, .contactag .filter_lst .filter-item.on, .contactag .filter_lst .filter-item:active {color:#7a1d05;font-weight:600}

/* 210511 단지상세 header */
.header_dandji {height:65px;width:100%;background:#9d3115;color:#fff;}
.header_dandji .title {font-size:20px;font-weight:500;line-height:1.2;padding-top:8px;}
.header_dandji .sum_info {display:block;font-size:12px;font-weight:300;line-height:1;}
.header_dandji .tit_dept02, .header_dandji .tit_dept03 {font-size:14px;font-weight:300;line-height:1.3;}
.header_dandji .btn-left, .header_dandji .btn-right {position:absolute;top:50%;transform:translatey(-50%)}
.header_dandji .favo_area {position:inherit;top:inherit;transform:none;right:inherit}

/* 210511 단지상세컨텐츠 */
.complex_info .panel-box {padding:10px 0 70px;margin-top:0;padding-bottom:60px;height:calc(100% - 64px);border-left:1px solid #d9d9d9}
.complex_info .mlst_tit {top:48%}
.complex_info .itmbox {padding:0;margin-bottom:8px;}
.complex_info .itmbox .emphasis {margin:0 -20px 10px;padding:8px 15px;}
.danjidetail_link {border-bottom:1px solid #d9d9d9;padding:12px 0 0;width:100%;background:#fff}
.danjidetail_link_inner {display:flex;flex-direction:row;flex-wrap:wrap;padding:0 16px;width:100%;box-sizing:border-box;}
.danjidetail_link_inner .link_itmbox {-webkit-box-flex: 1;-webkit-flex:1 0 auto;-ms-flex:1 0 auto;flex:1 0 auto;text-align:center;/*padding-bottom:x8px:padding-top:2px;*/color:#444;/*margin:0 10px;*/font-size:16px;position:relative;}
.danjidetail_link_inner .link_itmbox:hover, .danjidetail_link_inner .link_itmbox.on {font-weight:600px;/*border-bottom:3px solid #222;*/font-weight:bold;color:#222}
.danjidetail_link_inner .link_itmbox:hover:after, .danjidetail_link_inner .link_itmbox.on:after {display:block;width:100%;content:'';height:3px;background:#222;position:absolute;bottom:0;left:50%;transform:translatex(-50%);} 
.complex_info .itmbox_inner {padding:20px;overflow:hidden}
.complex_info .danjiprice .scroll-li {margin-right:0}
.complex_info .scroll-menu-container {margin-bottom:18px;}
.complex_info .scroll-menu-container .ul-container {width:2000px;background:none;padding-left:6px;}
.complex_info .danjiprice .scroll-link {font-size:13px;color:#444;line-height:1.3;text-align:center;padding:3px 12px;height:38px;box-sizing:border-box;}
.complex_info .scroll-link span {display:block;font-size:11px;font-weight:400;line-height:1;}
.complex_info .danjiprice .scroll-link.select {background:#a50f00;color:#fff;border-radius:50px;border-bottom:0;}
.complex_info .btn_scroll_prev {display:block;width:26px;height:38px;background:#fff url(../images/sub/ico_arr_prev.png) no-repeat center;text-indent:-9999px;position:absolute;left:-20px;top:0}
.complex_info .btn_scroll_next {display:block;width:26px;height:38px;background:#fff url(../images/sub/ico_arr.png) no-repeat center;text-indent:-9999px;position:absolute;right:-20px;top:0}
.complex_info .danjiprice .tab_style02 {position:relative;top:inherit;left:inherit;z-index:inherit}
.complex_info .danjiprice .tab_style02 .tab {overflow:hidden;}
.complex_info .danjiprice .tab_style02 ul li a {padding:2px 10px 3px;background:#fff}
.complex_info .danjiprice .btn_space_box {top:5px;right:0;}
.complex_info .danjiprice .graph_area {height:300px;margin-top:10px;position:relative}
.complex_info .danjiprice .graph_area.monthly {height:auto;padding:30px 0;margin-bottom:10px;}
.complex_info .graph_area .btn_view_prev {position:absolute;left:10px;top:50%;transform:translatey(-50%);display:block;width:38px;height:38px;border-radius:38px;border:1px solid #aeaeae;font-size:20px;font-weight:300;color:#aeaeae;background:rgb(255,255,255,0.8)}
.complex_info .graph_area .btn_view_prev:after {content:'전체기간보기';font-size:10px;font-weight:500;color:#aeaeae;position:absolute;bottom:-18px;left:50%;transform:translateX(-50%)}
.view_gesture {width:56px;height:59px;position:absolute;right:0;top:70px}
.view_gesture img {width:100%;height:100%}
.complex_info .real_detail {margin-bottom:0}
.complex_info .box-basicinfo {padding:0 0 20px 0;}
.complex_info .itmbox_inner .danji_img {margin:0 -20px 10px;}
.complex_info .itmbox_inner .go_naver {display:block;height:20px;line-height:20px;text-align:center;background-color:#fff;border:1px solid #222;font-size:13px;margin-top:15px;}
.danji_school, .facility_info {margin-top:25px;}
.danji_school .info_align {display:flex;margin-top:0}
.danji_school .info_align span {flex:1;}
.danji_school .info_align .value {position:static;margin-left:auto;right:inherit;top:inherit;font-size:16px;text-align:right;}
.facility_info .btn {font-size:14px;height:42px;/*background: url(../images/ico_arr02.png) no-repeat 80% center;*/}
.complex_info .itmbox.location .itmbox_inner {padding:0;}
.complex_info .itmbox.location .content {padding:0 20px 20px;}
.complex_info .itmbox.location .content li .detail .complex_info .sb_detail {display:block;font-size:14px;line-height:1.4;margin:4px 0;position:relative}
.danji_aptlst {background:#e6e6e6;z-index:100}
.danji_aptlst .deal_price {font-size:17px}
.danji_aptlst .item-card-container {margin:0;}
.danji_aptlst .item-card {min-height:50px;width:100%;margin:2px 0;border:none}
.danji_aptlst .item-card:first-child {margin-top:0}
.danji_aptlst .item-card.apt .item_info {min-height:40px;width:100%;/*padding:12px 20px;*/}
.itm_typeinfo .type01 {color:#bf3433;font-weight:bold;font-size:15px;}
.itm_typeinfo .type02 {color:#5d69bf;font-weight:bold;font-size:15px;}
.itm_typeinfo .type03 {color:#43c750;font-weight:bold;font-size:15px;}
.itm_typeinfo .area {font-size:14px;}
.danji_aptlst .item-card .real-price {margin:0;padding:0;font-size:18px}
.danji_aptlst .filter-list:last-child .filter-item {background:none;padding-right:0}
.go_my {display:inline-block;background:#fff;padding:5px 10px;border-radius:30px;-webkit-box-shadow:8px 12px 15px 2px rgb(0 0 0 / 25%);box-shadow:8px 12px 15px 2px rgb(0 0 0 / 25%);position:absolute;right:5px;bottom:10px;font-size:14px;}
.complex_info .btn-basic {width:100%;font-size:14px;height:42px;border-radius:0}
.complex_info .danjiinfo_grp span {font-size:13px;}
.lyr_inner .share {text-align:center;margin-top:-10px}
.lyr_inner .share .cont {text-align:left;margin-bottom:15px;}
.lyr_inner .share li {box-shadow:none;}
.danji_aptlst {right:380px;/*width:340px;*/}
.danji_aptlst .panel-header, .aptdetail .panel-header{background:#7a1d05;z-index:unset;}
.danji_aptlst .panel-box {padding:0 0 70px}
.danji_aptlst .filter-container {background:none;border-bottom:none}
.danji_aptlst .filter-container .filter-item {padding-right:25px;}
.danji_aptlst .filter-container .btn_space_box {left:inherit;top:10px;right:10px}
.btn_go_monthly {width:100%;height:44px;background:#a50f00 url(../images/sub/ico_arrowr.png) no-repeat 90% center;color:#fff;}
.complex_info .schedule-box {margin:20px 0}
.complex_info .schedule-bo
x .tit, .complex_info .schedule-box .info {padding:0}
.complex_info .schedule-box .itmbox_inner {padding:0;margin:0 -20px;}
.complex_info .schedule-box .schedule-lst-group {padding:2px 20px 2px;}
.complex_info .itmbox.schedule-box span {font-size:14px;}
.complex_info .check-box03>.chk01>input[type=checkbox].chk_danjiinfo+label {width:85px;font-size:12px;letter-spacing:-.9px}
.complex_info .chk_danjiinfo+label:before {height:34px}

.complex_info .item-card-container {margin:0}
.complex_info .item-card {min-height:30px;width:100%;margin:0;padding:12px 20px;border: none;box-sizing:border-box;border-top:1px solid #d9d9d9}
.complex_info .item-card.apt .item_info {min-height:30px;width:100%;}
.panel-group.complex_info .filter-container {background:none;border-bottom:none}
.complex_info .item-card .real-price {display:inline-block;font-size:16px;padding-bottom:0;vertical-align: middle;margin-left:5px;padding:0}
.panel-group.complex_info .filter-container .btn_space_box {left:inherit;top:10px;right:20px}
.complex_info .itmbox.itmlist {background:none}
.complex_info .item-card:first-child {margin-top:0}
.complex_info .item-card:last-child {margin-bottom:0}
.complex_info .itmbox .tab_con {margin-top:20px;}

.complex_info .itm_msg {top:50%;transform:translatey(-50%)}
.complex_info .itmlist .filter_lst .tab_style02 {position:unset;}

.complex_info .item-card .real-price .deal_price {font-size:inherit;}
.complex_info .item-card .itm_typeinfo {display:inline-block;vertical-align: middle}
.complex_info .item-card .itm_typeinfo .type01, .complex_info .item-card .itm_typeinfo .area {font-size:16px;}
.complex_info .item-card .itm_typeinfo .type01 {margin-right:5px;}

.complex_info .panel-box .filter_lst:first-child {flex:initial}

/* 매물상세 */
.aptdetail {right:380px;/*width:400px;*/z-index:100}
.aptdetail .panel-box {padding:0;border-left: 1px solid #d9d9d9;}
.aptdetail .item-card-container {margin:0}
.aptdetail .item-card {padding:0;min-height:80px;width:100%;margin:4px 0;border:0;border:1px solid #fff;}
.aptdetail .item-card:first-child {margin-top:0}
.aptdetail .item-card.apt .item_info {min-height:100px;width:65%;padding-right:8px;box-sizing:border-box}
.aptdetail .item-card .item_info_area {padding:20px;position:relative;}
.aptdetail .item_thumb {height:100px;position:inherit;width:35%;top:inherit;right:inherit;box-sizing:border-box;z-index:unset}
.aptdetail .item-card.apt .favo_area {top:25px;right:25px;z-index:1}
.aptdetail .item_info_area {display:flex;/*margin-bottom:16px;*/}
.aptdetail .item_info {padding:0}
.aptdetail .real-price {margin-top:0;font-size:18px;color:#222;line-height:1.2;margin-bottom:8px;margin-top:2px;padding:0;/*opacity:.8*/}
.aptdetail .sell-type {color:#fff;font-size:14px;line-height:1.2;display:block;}
.aptdetail .d_info01 {font-size:15px;margin-bottom:0}
.info_dong, .info_floor, .info_type {font-size:16px;}
/*.aptdetail .d_info01 .info_floor {margin-left:5px;}*/
.aptdetail .d_info01 .info_dong, .aptdetail .d_info01 .info_floor b {font-weight:500}
.d_date, .d_comment {display:block;}
.d_date {font-size:14px;font-weight:600}
.d_comment {font-size:14px;margin-top:3px;margin-bottom:12px;}
.aptdetail .item-card .bk_commt_area {padding:0 20px 25px;position:relative}
.bk_commt_list {position:relative;padding-left:50px;padding-right:50px;padding-bottom:10px;margin-bottom:10px;border-bottom:1px solid #ededed;}
.bk_commt_list:last-child {border-bottom:0;margin-bottom:0;padding-bottom:0}
.bk_commt_list .thumbnail_area {width:40px;height:40px;top:0;left:0;transform:none}
.bk_commt_list .thumbnail_area .thumbnail {width:100%;height:100%;border:none}
.bk_commt_list .agent_info {display:block;color:#515254;font-size:14px;}
.bk_commt_list .bk_office {font-weight:600;}
.bk_commt_list .bk_office .score {color:#ff6c00;margin-left:3px;}
.aptdetail .bk_commt_list .bk_name {font-size:14px;font-weight:400;margin-top:1px;}
.bk_commt_list .bk_comment {margin-top:4px;padding-right:25px;box-sizing:border-box;position:relative;font-size:13px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.bk_commt_list .bk_comment.fold {height:20px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.bk_commt_list .bk_comment.open {height:auto}
.bk_commt_list .btn-box {position:absolute;right:0;top:0;/*transform:translatey(-50%);*/margin-top:0}
.bk_commt_list .btn-box .btn-question {width:45px;height:45px;padding:0;background:#af300f;color:#fff;border-radius:60px;font-size:14px}
.bk_commt_list .icon-angle-down {position:absolute;right:5px;top:0;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 
.bk_commt_list .icon-angle-up {position:absolute;right:5px;top:0;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 
.item_detail_area .itmbox {border-top:1px solid #dedede;margin-bottom:0;font-size:14px;padding:20px;background:none}
.aptdetail .naveritm .item_detail_area .itmbox_itmlst {padding:0 0 20px 0}
.item_detail_area .itmbox:first-child {border-top:none}
.aptdetail .peebox .tit {display:block;}
.aptdetail .itmbox .pee_wrap {text-align:left;display:flex;flex:1;align-items: flex-end;justify-content:center}
.aptdetail .itmbox .bk_pee {max-width:70%;min-width:55%;width:inherit;padding:30px 10px 20px;}
.aptdetail .itmbox .maparea {height:240px;border:0;position:relative;}
.aptdetail .pointer02 {position:absolute}
.aptdetail .pointer02 button {display:inline-block;background: url(../images/sub/ico_pointer02.png) no-repeat;width:16px;height:25px;text-indent:-9999px;background-size:contain;}
.aptdetail .pointer02 button.on {background: url(../images/sub/ico_pointer02_on.png) no-repeat;background-size:contain;}
.pointer02 .nm_score {display:inline-block;position:absolute;left:50%;top:-20px;transform:translateX(-50%);color:#fff;background:#eb6100;padding:1px 5px 1px;font-size:11px;border-radius:30px;}
.aptdetail .opt_grp span {font-size:14px;}
.aptdetail .info_align.tax {display:inline-block;max-width:40%;min-width:30%;vertical-align:bottom;padding-bottom:5px;}
.aptdetail .info_align.tax li {margin-bottom:0}
.aptdetail .info_align.tax span {font-size:12px;margin-left:3px;}
.aptdetail .info_align.tax .content {margin-left:3px;font-weight:600;letter-spacing:-.5px;}
.panel-box .agent-card {margin:-2px 0 0;width:auto;min-width:inherit;max-width:inherit;border:none;padding:15px 70px 20px 95px;position:relative;/*border-top:1px solid #dbdbdb;*/height:120px;box-sizing:border-box;}
.aptdetail .aroundbk .agent-card .agent_info {display:block;position:absolute;top:50%;transform:translatey(-50%);}
.aptdetail .agent-card .thumbnail_area {width:65px;height:65px;position:absolute;top:50%;left:20px;}
.aptdetail .aroundbk .agent-card .thumbnail_area {top:50%;transform:translatey(-50%)}
.aptdetail .agent-card .thumbnail_area .thumbnail {width:100%;height:100%;}
.agent-card .btn-question {width:50px;height:50px;right:20px;background:#af300f;color:#fff;border-radius:60px;}
.aptdetail .agent-card .btn-box {position:absolute;right:15px;top:50%;transform:translatey(-50%)}
.aptdetail .agent-card .bk_office {font-weight:600;max-width:99%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.aptdetail .agent-card .bk_name {font-size:15px;font-weight:400;text-align:left;margin-bottom:5px;}
.aptdetail .agent-card .p_num {display:inline-block}
.aptdetail .agent-card .star_score {margin-bottom:0;text-align:left}
.aptdetail .aroundbk {position:relative;padding-bottom:0;}
.aroundbk .itmbox_inner {margin:0 -20px}
.item_detail_area {position:relative;background:#f9f9f9;/*box-shadow: inset -1px 3px 5px -3px rgb(0 0 0 / 30%);padding-bottom:25px;*/border-top:1px solid #cecece;z-index:1;/*border-bottom: 1px solid #d57a63;*/}
.item_detail_area:before {display:inline-block;content:'';background:url(../images/sub/img_tri.png) no-repeat;background-size:contain;width:20px;height:20px;position:absolute;top:-15px;left:50%;transform:translatex(-50%);z-index:2;}
.item_detail_area .btn-fold[role="detailclose"]  {position:absolute;top:-19px;left:50%;transform:translatex(-50%);width:50px;height:20px;box-shadow:none;}
.item_detail_area .btn-fold {position:absolute;bottom:-1px;left:50%;top:inherit;transform:translatex(-50%);display:inline-block;width:101%;height:26px;background:#fff url(../images/sub/btn_open.png) no-repeat 50%;background-size:contain;border:1px solid #dbdbdb;padding:0;border-radius:0;/*box-shadow: -1px -3px 4px -3px rgb(0 0 0 / 20%);*/}
.item_info_area .btn-fold {position:absolute;bottom:-1px;left:50%;top:inherit;transform:translatex(-50%);display:inline-block;width:50px;height:20px;background:#fff url(../images/sub/btn_open.png) no-repeat 50%;background-size:contain;border:1px solid #dbdbdb;padding:0;border-radius:0;}
.bk_commt_area .btn-fold {position:absolute;bottom:-1px;left:50%;top:inherit;transform:translatex(-50%);display:inline-block;width:50px;height:20px;background:#fff url(../images/sub/btn_open.png) no-repeat 50%;background-size:contain;border:1px solid #dbdbdb;padding:0;border-radius:0;}
.aptdetail .icon-help[data-tooltip-text]:hover:after {width:300px;left:50%;transform:translatex(-50%);white-space: pre-line;}

.map_bkcard_wrap {position: absolute;white-space:nowrap;}
.map_bkcard_list {cursor:pointer;background:#fff; overflow-y:scroll;max-height:150px;max-width:150px;border-radius:5px;margin-bottom: 2px;}
.map_bkcard_list li {padding:10px 12px;border-bottom: 1px solid #eaeaea;display:flex;flex:1;align-items: center;}
.map_bkcard_list li:last-child {border-bottom:0}
.map_bkcard_list li:hover {background-color: #f9eae6}
.map_bkcard_list li .bk_info {font-size: 13px; text-overflow: ellipsis; display: inline-block; width: 78%;overflow: hidden;vertical-align: middle;}
.map_bkcard_list li .nm_score {font-size:12px;color:#eb6100;text-align:right;display:inline-block;vertical-align:middle;width:20%}

.item-card.noimg .item_info_area {display:block;}
.item-card.apt.noimg .item_info {width:100%;}
.d_comment {padding-right:15px;position:relative;display:-webkit-box; -webkit-box-orient:vertical;word-wrap:break-word;text-overflow:ellipsis;overflow:hidden;-webkit-line-clamp: 3;max-height:60px;}
.d_comment .icon-angle-down {position:absolute;right:0;bottom:2px;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 
.d_comment .icon-angle-up {display:none;position:absolute;right:0;bottom:2px;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 

.btn_go_monthly {width:100%;height:46px;background:#a50f00 url(../images/sub/ico_arrowr.png) no-repeat 90% center;color:#fff;}
.item-card.naveritm {min-height:60px}
.aptdetail .item-card.naveritm .item_info {min-height:50px;width:100%;}
.aptdetail .item-card.naveritm .item_info .d_info01 {font-size:15px;}
.info_dong:after, .info_type:before {display:inline-block;content: '';width:2px;height:2px;background:#222;margin:0 5px;vertical-align:middle;margin-bottom:4px}
.item-card.naveritm  .info_type:before {display:none;}
.aptdetail .item-card .info_type {display:inline-block;margin-right:5px;}
/*.item-card.naveritm .info_type:before {display:inline-block;content:"";width:16px; height:16px;background:url(../images/sub/ico_n.png) no-repeat;background-size:contain;margin-right:2px;vertical-align:middle;padding-top:4px;margin-right:4px;}*/
.aptdetail .naveritm .item_info_area {padding:15px 20px}
.aptdetail .naveritm .real-price {margin-bottom:0;font-size:19px;opacity:.8;margin-top:3px}
.item_detail_area .itmbox_banner {/*background:#eaeaea !important;*/padding:8px 20px;color:#fff;font-size:12px;}
.aptdetail .itmbox_itmlst {padding:0}
.itmbox_itmlst .itmbox_banner {background:#f9f9f9;color:#222;font-size:13px;}
.itmbox_itmlst .itmbox_banner strong {color:#e83848;font-weight:500;font-size:12px;}
.nitm_lst {/*padding:15px 125px 15px 20px;*/padding:20px;border-bottom:1px solid #dedede;/*background:#fff;*/position:relative;padding-right:125px;}
/*:first-child {border-top:1px solid #eaeaea;}*/
.nitm_lst:last-child {border-bottom:0}
.nitm_lst .info01 span {font-size:16px;font-weight:600}
.nitm_lst .info01 span.price {color:#222;display:inline-block;margin:0;opacity:.8;font-size:16px}
.nitm_lst .info02 {padding-right:15px;color:#7c7c7c;margin-top:3px;position:relative;display:-webkit-box;-webkit-box-orient:vertical;word-wrap:break-word;text-overflow:ellipsis;overflow:hidden;-webkit-line-clamp:3;max-height:60px;font-size:12px;}
.nitm_lst .info02 .icon-angle-down {position:absolute;right:0;bottom:0;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 
.nitm_lst .info02 .icon-angle-up {display:none;position:absolute;right:0;bottom:0;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 
.aptdetail .bk_pee li {width:50px;}
.view_all {display:inline-block;font-size:12px;padding:3px 10px;border-radius:50px;border:1px solid #dadada;color:#929292;position:absolute;right:20px;top:20px;background:#fff;}
.nitm_lst .btn-box {position:absolute;right:20px;top:50%;transform:translatey(-50%);display:flex;}
/*
.nitm_lst .btn-box .btn {display:flex;align-items:center;justify-content:center;font-size:12px;color:#666;width:100%;height:28px;border-radius:0;border-color:#aaa;}
.nitm_lst .btn-box .btn:hover {background:#fff;transition-duration:none}
.nitm_lst .btn-box .btn:last-child {margin-left:-1px;}
*/
.nitm_lst .btn-question {width:45px;height:45px; right:20px;background:#af300f;color:#fff;border-radius:60px;font-size:12px;display:flex;align-items:center;justify-content:center;line-height:1.2;padding:6px 0}
.nitm_lst .btn-gonaver {padding:6px;width:45px;height:45px;font-size:12px;background:#00cd39;border-radius:50%;margin-left:5px;line-height:1.2;color:#fff;text-align:center;padding-top:20px;position:relative}
.nitm_lst .btn-gonaver:before {display:block;content:'';background:url(../images/sub/ico_n04.png) no-repeat;width:10px;height:10px;background-size:contain;margin-right:0;vertical-align:middle;margin-top:0;position:absolute;top:10px;left:50%;transform:translatex(-50%)}
.panel-box .filter_lst {display:flex;align-items: center;flex:0 1 auto;margin-right:0}
.panel-box .filter_lst:first-child {flex-basis:40%}
.panel-box .filter_lst:nth-child(2) {flex-basis:20%}
.panel-box .filter_lst:nth-child(3) {flex-basis:20%}
.aptdetail .tab_style02 {position:relative;top:inherit;left:inherit;z-index:inherit}
.aptdetail .tab_style02 .tab {overflow:hidden;}
.aptdetail .tab_style02 ul li a {padding:2px 10px 3px;background:#fff}
.aptdetail .tab_style02 .tab li a:hover, .aptdetail .tab_style02 .tab li a.on {background:#a50f00;color:#fff;border-color:#a50f00;}
.item-card.is-selectd, .item-card:hover {background:#fdf9f8;/*border-top:1px solid #dd846c;border-bottom:1px solid #dd846c;*/box-sizing:border-box}

.pop.aptdetail {width:400px;height:700px}
.pop.aptdetail .container {padding:30px 0 0}
.pop.aptdetail .itmbox {padding:0;border-top:none}
.pop.aptdetail .itmbox .maparea {margin:0}
.view_agent .agent-list {display:block;margin:0;width:100%;position:fixed;top:270px;padding-top:32px;height:399px}
.view_agent .agent-card {margin:0;position:relative;border-top:0;width:100%;border-bottom:1px solid #eaeaea;height:auto;}
.view_agent .agent-card .thumbnail_area {transform:translatey(-50%)}
.view_agent .agent_info {width:100%;text-align:left;display:block;font-size:14px}
.view_agent .agent-card .btn-box {left:inherit}
.view_agent .filter_wrap {width:100%;position:inherit;background:#f9f9f9;padding-left:20px;box-sizing:border-box;top:inherit;height:43px}
.panel-group.aptdetail .filter-container {background:none;position:relative;border-bottom:none}
.panel-group.aptdetail .filter-container .btn_space_box {left:inherit;top:12px;right:20px;}

.link_office {font-size:11px;font-weight:500;color:#888;padding:2px 5px;border:1px solid #aeaeae;border-radius:30px;display:inline-block;margin-left:5px;vertical-align:middle;cursor:pointer;}

/* 210629 30만원으로 창업_메인 */
.joinagent-main .submain-img-box .ja-slide04 {height:100%;width:100%;background-image: url(../images/sub/bg_ja01.jpg);background-repeat:no-repeat;background-position:50% 0;background-size: cover;/*position:relative;*/text-align:center;}
.ja-slide04 .box-content {text-align:center;width:832px;height:500px;margin:0 auto;text-align:center;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.ja-slide04 .box-content:after {display:block;content:'';clear:both}
.ja-slide04 .box-content p:first-child {width:563px;height:352px;background:url(../images/sub/img_submain20.png) no-repeat;margin-bottom:40px;display:inline-block}
.ja-slide04 .box-card {position:absolute;bottom:0;left:50%;transform:translatex(-50%);width:833px;}
.ja-slide04 .box-card:after {display:block;content:'';clear:both;}
.ja-slide04 .box-card p:nth-child(1){width:416px;height:175px;background:url(../images/sub/ja01.png) no-repeat;display:inline-block;float:left}
.ja-slide04 .box-card p:nth-child(2){width:416px;height:175px;background:url(../images/sub/ja02.png) no-repeat;display:inline-block;float:left}
.joinagent-main .submain-box.ja08 {background:url("../images/sub/img_ja08.jpg") no-repeat 50% 0;background-size:cover;padding-top: 35%;width:100%;height:0;position:relative;}
.joinagent-main .submain-box.ja09 {background:url("../images/sub/img_ja09.jpg") no-repeat 50% 0;background-size:cover;padding-top: 35%;width:100%;height:0;position:relative;}
.joinagent-main .submain-box.ja02 {background:url("../images/sub/img_ja10.jpg") no-repeat 50% 0;background-size:cover;padding-top: 42%;width:100%;height:0;position:relative;}
.quickbn.bn_academy {background:url(../images/sub/img_quickbn05.png) no-repeat;width:165px;height:270px;background-size:contain;text-indent:-9999px;}

.joinagent-main .submain-img-box .ja-slide05 {height:100%;width:100%;background-image: url(../images/sub/bg_ja02.jpg);background-repeat:no-repeat;background-position:50% 0;background-size: cover;/*position:relative;*/text-align:center;}
.ja-slide05 .box-content {text-align:center;width:832px;height:500px;margin:0 auto;text-align:center;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.ja-slide05 .box-content:after {display:block;content:'';clear:both}
.ja-slide05 .box-content p:first-child {width:563px;height:330px;background:url(../images/sub/img_submain21.png) no-repeat;margin-bottom:40px;display:inline-block}
.ja-slide05 .box-card {position:absolute;bottom:0;left:50%;transform:translatex(-50%);width:833px;}
.ja-slide05 .box-card:after {display:block;content:'';clear:both;}
.ja-slide05 .box-card p:nth-child(1){width:416px;height:175px;background:url(../images/sub/ja01.png) no-repeat;display:inline-block;float:left}
.ja-slide05 .box-card p:nth-child(2){width:416px;height:175px;background:url(../images/sub/ja02.png) no-repeat;display:inline-block;float:left}
.quickbn.bn_startup {background:url(../images/sub/img_quickbn06.png) no-repeat;width:165px;height:283px;background-size:contain;text-indent:-9999px;}
.joinagent-main .submain-box.stup02 {background:#fff;padding-top:500px;width:100%;height:0;position:relative;max-width:800px;margin:0 auto;padding-bottom:290px;margin-bottom:80px;}
.stup02 .stuplst {display:flex;flex-direction:row;flex-wrap:wrap;}
.stup02 .stuplst li {width:25%;text-align:center;padding-bottom:30px}
.stup02 .stuplst li .title {font-size:22px;font-weight:bold;padding-top:150px;position:relative}
.stup02 .stuplst li .title:before {display:block;content:'';height:100px;width:100px;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.stup02 .stuplst li.lst01 .title:before {background:url(../images/sub/img_curri01.png) no-repeat;background-size:contain;}
.stup02 .stuplst li .text01 {font-size:17px;margin-top:10px;}
.stup02 .stuplst li.lst02 .title:before {background:url(../images/sub/img_curri02.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst03 .title:before {background:url(../images/sub/img_curri03.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst04 .title:before {background:url(../images/sub/img_curri04.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst05 .title:before {background:url(../images/sub/img_curri05.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst06 .title:before {background:url(../images/sub/img_curri06.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst07 .title:before {background:url(../images/sub/img_curri07.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst08 .title:before {background:url(../images/sub/img_curri08.png) no-repeat;background-size:contain;}
.joinagent-main .submain-box.stup02 .btn-horiz {bottom:0;display:block;width:100%;}
.joinagent-main .submain-box.stup02 .row {border-bottom:0}
.joinagent-main .submain-box.stup02:last-child .row .sc_tit {color:#222;padding-bottom:30px;}
.stup02 .btn-big {width:300px;}
.stup02 .add-text {margin-top:20px;font-size:14px;color:#aeaeae;}
.stup02 .add-text b {font-size:15px;color:#c22800;font-weight:500;display:block;margin-bottom:5px}
/* .quickbn.top + .quickbn.bn_startup {top:410px;} */
.ban_wrap {width:165px;position:fixed;top:200px;right:20px;z-index:200;}

.quickbn {cursor: pointer;}

/* 210803 매물리스트 수정 */
.apt .item_thumb {width:117px}
.per_price {display:inline-block;padding:2px 7px;height:16px;line-height:15px;background:#efefef;color:#8e8e8e;border-radius:30px;font-size:11px;font-weight:500;vertical-align:middle;margin-top:-5px;margin-left:4px}
.item-card.apt .real-price {font-size:19px}

/* 도움말 */
.pfaq_wrap {z-index:2001}
.pfaq_wrap .container {padding-top:0}

/* 마이페이지 1:1 문의 */
.myfaq .abox {padding-left:0;}
.mp .cont_inner .sub_cont {padding: 0 30px;}

/* 집내놓기 공동중개 동의 */
.chk_agree {margin-top:25px;margin-bottom:20px;}
.chk_agree .impt {font-size:17px;}
.chk_agree .btn-xsmall {margin-left:10px;margin-top:-3px;}
.chk_agree .m_exp {margin-top:10px;padding-bottom:30px;font-size:13px;}
.regi .chk_agree .check {height:auto;}

/* 다윈중개에만 있는 매물 리스트 */
.dawinapt .panel-box {padding:0;} 
.dawinapt .panel-box .item-card-container {margin:0}
.dawinapt .panel-box .item-card {margin:4px 0;width:100%;}
.dawinapt .panel-box .item-card:first-child {margin-top:8px}
.dawinapt .item-card .item_info_area {padding:20px;position:relative;}
.dawinapt .item-card {padding:0}
.dawinapt .item_detail_area .itmbox {background:#f9f9f9;/*padding-bottom:0*/}
.dawinapt .item_detail_area .itmbox.aroundbk {padding-bottom:0}
.dawinapt .itmbox {padding:20px}
.dawinapt .itmbox .maparea {height:240px;border:0;position:relative;}
.dawinapt .aroundbk .agent-card .agent_info {display:block;position:absolute;top:50%;transform:translatey(-50%);}
.dawinapt .agent-card .thumbnail_area {width:65px;height:65px;position:absolute;top:50%;left:20px;}
.dawinapt .aroundbk .agent-card .thumbnail_area {top:50%;transform:translatey(-50%)}
.dawinapt .agent-card .thumbnail_area .thumbnail {width:100%;height:100%;}
.dawinapt .agent-card .btn-question {width:50px;height:50px;right:20px;background:#af300f;color:#fff;border-radius:60px;}
.dawinapt .agent-card .btn-box {position:absolute;right:15px;top:50%;transform:translatey(-50%)}
.dawinapt .agent-card .bk_office {font-weight:600}
.dawinapt .agent-card .bk_name {font-size:15px;font-weight:400;text-align:left;margin-bottom:5px;}
.dawinapt .agent-card .p_num {display:inline-block}
.dawinapt .agent-card .star_score {margin-bottom:0;text-align:left}
.dawinapt .peebox .tit {display:block;}
.itmbox .pee_wrap {text-align:left;display:flex;flex:1;align-items: flex-end;justify-content:center}
.itmbox .bk_pee {max-width:70%;min-width:55%;width:inherit;padding:30px 10px 20px;}
.itmbox .bk_pee li {width:50px;}
.itmbox .info_align.tax {display:inline-block;max-width:40%;min-width:30%;vertical-align:bottom;padding-bottom:5px;}
.itmbox .info_align.tax li {margin-bottom:0}
.itmbox .info_align.tax span {font-size:12px;margin-left:3px;}
.itmbox .info_align.tax .content {margin-left:3px;font-weight:600;letter-spacing:-.5px;}
.dawinapt .pointer02 {position:absolute}
.dawinapt .pointer02 button {display:inline-block;background: url(../images/sub/ico_pointer02.png) no-repeat;width:16px;height:25px;text-indent:-9999px;background-size:contain;}
.dawinapt .pointer02 button.on {background: url(../images/sub/ico_pointer02_on.png) no-repeat;background-size:contain;}
.pointer02 .nm_score {display:inline-block;position:absolute;left:50%;top:-20px;transform:translateX(-50%);color:#fff;background:#eb6100;padding:1px 5px 1px;font-size:11px;border-radius:30px;}
.panel-group.dawinapt .filter-container {display:flex;align-items:center;}
.dawinapt .panel-box .filter_lst:first-child, .dawinapt .panel-box .filter_lst:nth-child(2) {flex-basis:15%;}
.dawinapt .fd-radio > .rd01 > input[type=radio] + label {font-size:13px;border-radius:30px;width:auto;padding:5px 10px;height:auto;line-height:1.3;margin:2px;background:#eaeaea}
.dawinapt .rd01 > input[type=radio]:checked + label {background:#b9342a;}
.panel-group.itemlist.dawinapt .panel-box {height:calc(100% - 205px);overflow-x:hidden;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.go_detail {position:absolute;right:15px;top:125px;font-size:12px;padding:3px 15px 3px 5px;border:1px solid #eaeaea;background:#fff;border-radius:30px;}
.go_detail::after {display:inline-block;content:'';width:9px;height:9px;background:url(../images/sub/ico_bullet.png) no-repeat;background-size:contain;position:absolute;right:2px;top:50%;transform:translatey(-50%)}
.noimg .go_detail {top:100px;}
.dawinapt .item-card.apt.noimg .item_info {margin-bottom:10px;min-height:100px}
.pop-complete .panel-box .agent-card {margin: 5px;width: 258px;min-width:inherit;max-width:inherit;border:1px solid #e0e0e0;padding:15px 70px 20px 95px;position:relative;/*border-top:1px solid #dbdbdb;*/height:auto;box-sizing:border-box;}

/* 중개사아카데이 개요 추가 */
.joinagent-main .submain-box.stup01.academy {background:#f9f9f9;max-width:unset;padding-top:500px}
.joinagent-main .submain-box.stup01.academy .row {width:800px;}
.summary_group {margin-top:50px;padding:0 80px;}
.summary_lst {display:flex;font-size:20px;align-items:baseline;flex-wrap:wrap;margin-bottom:10px;}
.summary_lst span:first-child {display:block;padding:5px 8px;width:70px;background:#9d3115;color:#fff;border-radius:50px;margin-right:15px;font-size:16px;text-align:center;}
.summary_lst span {text-align:left;}
.summary_lst span small {color:#aeaeae}

/* 다윈중개매물 탭추가 */
/* .dawinapt .danjidetail_link {position:fixed;top:54px;padding-top:10px;}
.panel-group.dawinapt .filter-container {top:97px;}
.dawinapt .panel-box {margin-top:97px;height: calc(100% - 216px)!important;}
.panel-group.dawinapt .filter-container:nth-child(5) {border-bottom:1px solid #ccc;} */

.addview .fd-radio > .rd01 > input[type=radio] + label {font-size:13px;border-radius:30px;width:auto;padding:5px 10px;height:auto;line-height:1.3;margin:2px;background:#eaeaea}
.addview .rd01 > input[type=radio]:checked + label {background:#b9342a;}
.panel-group.dawinapt .filter-container .filter-group {display:flex;}

/* 전화로 집내놓기 텍스트 추가 */
.regi-phone .box-inner .p03 small {font-size:17px;color:#666;display:block;margin-top:5px;}

/* 210927 마이페이지 내집정보 */
.mp .aptdetail .panel-box .mysell .bk_pee {min-width:unset;max-width:unset;width:inherit;}
.mp .aptdetail .panel-box .mysell .add-info {position:absolute;right:0;top:-45px;width:150px;}
.mp .aptdetail .panel-box .mysell .add-info .btn-basic {height:32px;font-size:14px;border-radius:3px;}
.mp .aptdetail .panel-box .mysell .add-info .btn-primary {width:150px;height:32px;font-size:14px;border-radius:3px;}
.mp .aptdetail .panel-box .mysell .add-info p {font-size:12px;position:absolute;right:0;top:36px;width:180px;color:#919191}
.mp .aptdetail .panel-box .mysell .add-info .btn-primary + p {width:214px}
.mp .aptdetail .panel-box .mysell .pee_wrap {padding-top:30px}
.mp .aptdetail .panel-box .mysell .add_text {margin-top:15px;font-size:12px;}
.mp .panel-box .mysell .bk_pee .gBar.dawinpee span {font-size:22px;}

/* 211018 중개사아카데미신청하기 결제 팝업 */
.joinagent-share .add_noti {font-size:15px;margin-bottom:10px;}
.lyr_inner .align_l {text-align:left;}
.info_summ {margin-top:20px;margin-bottom:20px;font-size:16px;}
.info_summ span {display:block;text-align:left;}
.info_summ small {display:block;color:#666;margin-top:10px;text-align:left;}
.info_summ form {margin-top:10px;}
.lyr_inner .cont {margin-bottom:20px;}

/* 211020 마이페이지 툴팁 */
.mp .sub_cont .icon-help[data-tooltip-text]:hover:after {left:50%;transform:translatex(-50%)}

/* 211124 중개사추천 이벤트 */
.joinagent-main .submain_event {height:70vh;width:100%;}
.joinagent-main .submain_event .ja-slide {height:100%;width:100%;background-image:url(../images/sub/bg_ja03.png);background-repeat:no-repeat;background-position: 50% 0;
background-size:cover;text-align:center;}
.joinagent-main .submain_event .row {text-align:left;height:400px;position:absolute;top:50%;left:0;transform:translatey(-50%)}
.joinagent-main .submain_event .ja-slide .box-content {width:789px;height:400px;background:url(../images/sub/bg_jatext01.png) no-repeat;display: inline-block;
	background-size:contain;}
	
/* 단지상세 단지톡 추가 */
.complex_info .danjitalk .itmbox_inner {padding:0;background:#e6e6e6}
.complex_info .danjitalk .card-community-list {margin:0 0 10px;}
.itmbox-btnarea {padding:0 20px 20px;display:flex;flex-flow:row wrap;justify-content:center;}
.itmbox-btnarea .btn-line {padding:8px 20px;background:#fff;border:1px solid #7A1600;color:#7A1600;border-radius:50px;display:inline-block;width:150px;height:auto;margin:5px;font-size:14px;}
.comment-wrap {display:inline-block;padding:0 5px;position:relative}
.real-price-comment {display:inline-block;content:'';width:20px;height:20px;background:url(../images/sub/ico_comment01.svg) no-repeat center center;vertical-align: middle;margin-top:-4px;}
.comment-count {display:inline-block;width:15px;height:15px;font-size:10px;background:#c22800;color:#fff;border-radius:10px;position:absolute;right:0;top:-6px;}

/* 중개사 메인수정 */
.joinagent-main .submain-box.ja10 {
	background:url("../../assets/images/img_ja07.png") no-repeat 50% 0;
	background-size:cover;
	padding-top:39.270833333333333%;
	width:100%;
	height:0;
	position:relative;
}
.joinagent-main .submain-box.ja10 .row {
	top:40%;
}
.sc_dec_blue {
	color:#3e70c7;
}
.joinagent-main .submain-box.ja02 {
	padding-top:50%;
}
.joinagent-main .submain-box.ja02 .row {
	top:30%;
}

/* 220711 중개사회원가입 중개지역선택 추가 */
.area-box {
	display:flex;
	flex-flow:row wrap;
	column-gap: 8px;
	row-gap: 8px;
	align-items: center;
}
.area-list {
	display:flex;
	padding:0 16px;
	height:32px;
	font-size:14px;
	border:1px solid #D0D2D6;
	border-radius: 32px;
	align-items: center;	
	position:relative
}
.area-list.area-my {
	border-color:#222;
}
.area-list .btn-del {
	display:inline-block;
	width:16px;
	height:16px;
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EGroup 3%3C/title%3E%3Cdefs%3E%3Cpath d='M6,1.875 C6.20710678,1.875 6.375,2.04289322 6.375,2.25 L6.375,5.25 L9.375,5.25 C9.58210678,5.25 9.75,5.41789322 9.75,5.625 C9.75,5.83210678 9.58210678,6 9.375,6 L6.375,6 L6.375,9 C6.375,9.20710678 6.20710678,9.375 6,9.375 C5.79289322,9.375 5.625,9.20710678 5.625,9 L5.625,6 L2.625,6 C2.41789322,6 2.25,5.83210678 2.25,5.625 C2.25,5.41789322 2.41789322,5.25 2.625,5.25 L5.625,5.25 L5.625,2.25 C5.625,2.04289322 5.79289322,1.875 6,1.875 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='중개사회원가입' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='중개사회원가입_중개지역추가' transform='translate(-122.000000, -1497.000000)'%3E%3Cg id='Group-6' transform='translate(20.000000, 1489.000000)'%3E%3Cg id='Group-3' transform='translate(102.000000, 8.000000)'%3E%3Ccircle id='Oval' fill='%23D8D8D8' cx='8' cy='8' r='8'%3E%3C/circle%3E%3Cg id='COLOR/-black' transform='translate(2.000000, 2.000000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Mask' fill='%23000000' transform='translate(6.000000, 5.625000) rotate(-315.000000) translate(-6.000000, -5.625000) ' xlink:href='%23path-1'%3E%3C/use%3E%3Cg id='Group' mask='url(%23mask-2)' fill='%23000000'%3E%3Cg id='COLOR/-black' transform='translate(6.000000, 6.000000) rotate(90.000000) translate(-6.000000, -6.000000) '%3E%3Crect id='Rectangle' x='0' y='0' width='12' height='12'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size:contain;
	text-indent:-9999px;
	margin-left:8px
}

// root-dawin
:root.root-dawin {
	// .mark {display:inline-block;width:104px;height:19px;background:url(../images/sub/img_tag.png) no-repeat;text-indent:-9999px;margin-bottom:-7px;background-size:contain;line-height:1}
}