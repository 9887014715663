@charset "UTF-8";

/* CSS Document */
/* reset */
html,body,div,span,object,iframe,abbr,address,cite,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,label,table,caption,tbody,tfoot,thead,tr,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button,a{margin:0;padding:0;border:0;background:transparent;}
body {font-family: 'Open Sans', 'Noto Sans KR', sans-serif;font-size:14px;color:#444;letter-spacing:-.9px}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {display:block;}
dl,ul,ol,menu,li{list-style:none}
blockquote, q{quotes: none}
blockquote:before, blockquote:after,q:before, q:after{content:'';content:none}
input,select,textarea,button{vertical-align:middle}
input::-ms-clear{display:none}
button{border:0 none;background-color:transparent;cursor:pointer}
body{background:#fff}
body,th,td,input,select,textarea,button{line-height:1.5;color:#333} /* color값은 디자인가이드에 맞게사용 */
a{color:#333;text-decoration:none}
a:hover, a.on, a:active{cursor:pointer}
/*a:active, a:hover{text-decoration:underline}*/
/*a:active{background-color:transparent}*/
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal}
::-webkit-input-placeholder {color:#aaa;font-weight:400}
::-moz-placeholder {color:#aaa;font-weight:400}
:-ms-input-placeholder {color:#aaa;font-weight:400}
:-moz-placeholder {color:#aaa;font-weight:400}
// html {-ms-overflow-style:none}
// ::-webkit-scrollbar {display:none}
// element::-webkit-scrollbar {display:none}   

		
.normal{font-weight:400}
.bold{font-weight:700}
.bolder{font-weight:800}
.light{font-weight:300}
textarea, button/*, select*/{-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-appearance:none;appearance:none;-moz-appearance:none;letter-spacing:-.9px}
textarea {resize:none;}
input[type="file"]/*,input[type="text"], input[type="password"]*/ {-webkit-appearance:none;-moz-appearance:none;appearance:none;width:100%;text-align:left;background:none;border:none;display:block;outline:none;border-radius:0;}
input[type="file"] {opacity:0}
input[type="date"]::-webkit-clear-button {display:none;}
input[type="date"] {appearance:none;-webkit-appearance:none;/*letter-spacing:-.15em*/font-size:14px;letter-spacing:0}
i {font-style:normal}
table{border-collapse:collapse;width:100%;}
select { border-radius:0; /* 아이폰 사파리 보더 없애기 */ -webkit-appearance:none; /* 화살표 없애기 for chrome*/ -moz-appearance:none; /* 화살표 없애기 for firefox*/ appearance:none /* 화살표 없애기 공통*/ }
select::-ms-expand{ display:none /* 화살표 없애기 for IE10, 11*/ }
input:read-only {background:#eaeaea;}
input:read-only:focus {box-shadow:none;border-color: rgb(208, 210, 214);}
button:disabled {border-color:#e5e5e5;color:#e5e5e5;cursor:default !important;-webkit-transition-duration:0s !important;transition-duration:0s !important;}
:root.root-dawin {
}