@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */

/* popup 공통 */
.mask {position:fixed; top:0; left:0; background:rgba(0,0,0,0.4); width:100%;height:100%; z-index:100;}
.pop {background:#fff;z-index:101;border:1px solid #cdcdcd;-webkit-box-shadow:0 2px 4px 0 rgba(0,0,0,.08);box-shadow:0 2px 4px 0 rgba(0,0,0,.08);-webkit-box-sizing:border-box;box-sizing:border-box;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);width:540px}
.popbasic {width:400px;min-height:300px;max-height:600px}
.poplg {width:500px;max-height:650px;overflow-y:hidden;}
.popxlg {width:600px;max-height:600px;overflow-y:hidden;}
#dawinWrap #wrap .pop #wrap {margin-top:0;}
.pop .header {position:relative;width:100%;height:auto;padding:30px 40px 0;box-sizing:border-box;border-bottom:0;transition:none}
.pop-cont {padding:40px;text-align:center;position:relative;width:100%;min-height:300px;}
.pop .container {padding:30px 40px 50px;text-align:center;position:relative;width:100%;min-height:240px;box-sizing:border-box;max-height:550px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);margin-top:0}
.pcont_inner {display:inline-block;width:100%;box-sizing:border-box;position:relative;height:100%;}
.pop .tit {font-size:24px;color:#1e1e1e;text-align:center;font-weight:400}
.p_exp {font-size:14px;padding:10px 0 0}
.pop form {display:block}
.pop .btn {display:block;width:100%;margin-top:10px}
.pop .btn-right {position:absolute;top:20px;right:20px;z-index:1}
.lg_link {text-align:center;/*height:80px;position:absolute;bottom:40px;left:50%;transform:translateX(-50%);*/width:100%;margin-top:30px;}
.lg_link span {display:inline-block;}
.lg_link a {color:#444;font-size:13px;}
.lg_link a:hover {text-decoration:underline;}
.lg_link a:before {display:inline-block;width:1px;height:8px;margin:0 15px;background:#999;opacity:0.9;content: '';}
.lg_link a:first-child:before {display:none;}
.lg_link .btn_loginphone {text-decoration: underline;}
.p01 {font-size:20px;}
.p02 {font-size:30px;}
.p02 + .login-group {margin-top:50px}
.pop .cont_wrap {padding-bottom:0}
.pop h2.title {font-size:24px;font-weight:500}

/* 로그인팝업 */
.popbasic .check_box {/*margin-top:10px;*/height:40px}
.btn_login {width:318px;font-size:15px;margin-top:10px;/*position:absolute;bottom:0;left:0*/border-radius:2px}
.login-group-dawin .check-box {margin-top:10px}
.easylogin .btn-group .btn {margin-top:0;}
.pop .btn-group .btn {margin:10px 0 0;}
.login-group-dawin .inp {margin-top:10px}
.btn-login {margin:15px 0;background:#a42302;background: -webkit-linear-gradient(to right, #a42302, #7a1d05);background:linear-gradient(to right, #a42302, #7a1d05);}

/* 로그인아이디선택 */
.unit.t_red {color:#fa5252;top:13px;}
.checkdiv {padding:12px 15px;border:1px solid #ededed;font-size:16px;margin-top:10px;position:relative;text-align:left}
.checkdiv:first-child {margin-top:0}
.checkdiv.selected {background:#e24f28;color:#fff;border-color:#e24f28}
.checkdiv.selected:after {display:inline-block;content:'';background:url("../images/main/ico_check.png") no-repeat;background-size:contain;width:15px;height:15px;position:absolute;right:15px;top:15px}
.v_nt {padding-top:25px}

/* 패스워드찾기 */
.fdinfo {padding-top:40px;}
.ntc_txt {padding:0 0 20px;font-size:16px;text-align:center}
.ntc_p {font-size:17px;text-align:center;line-height:1.4}
.ntc_p strong, .ntc_p big {display:block;font-size:24px;}
.inp-textbox .btn {margin-top:0;width:auto}

/* 중개사신고하기 */
.report {height:700px}
.report .header .tit {text-align:left}
.report .pop .container {padding-bottom:30px}
.report .pop-cont {padding-top:10px;overflow-x:hidden}
.report .m_exp {font-size:15px;text-align:left}
.report .agent-list {flex-wrap:nowrap;width:1000px;justify-content:left;padding:0}
.report .selec-result {padding:15px 0;text-align:left;margin-right:-40px;/*overflow-x:scroll*/}
.report .selec-area .radio-box {display:table;width:100%}
.selec-area .radio-box .radio {font-size:15px}
.selec-area .rd-block .radio {display:block;}
.selec-option .radio-box .radio {display:block;margin-bottom:5px;}
.report .check-box02 {position:absolute;right:10px;top:50%;transform:translatey(-50%);display:block;height:auto}
.report .agent-card:last-child {margin-bottom:5px}
.sel_area {padding-bottom:15px}
.sel_area .radio_box .radio {padding-right:50px;}
.report .box-list {max-width:100%;background:#fff;z-index:10;top:45px;border:1px solid #b9b9b9;padding:10px;text-align:left}
.report .box-list .sch_word {padding:5px 0;}
.report .box-list .address {display:block;position:unset;top:unset;transform:unset}
.bk_sch .sch_result {width:100%;max-height:200px;padding:10px 20px;box-sizing:border-box;background:#fff;border:1px solid #ccc;position:absolute;left:0;overflow-y:auto;z-index:10;top:45px;text-align:left}
.report .result_area {display:inline-block;padding:6px 15px;color:#fff;border-radius:50px;background-color:#c43713;font-size:14px;text-align:left;margin-top:10px;margin-bottom:5px}
.report .result_area .del {display:inline-block;background:url(../images/sub/ico_del.png) no-repeat;width:13px;height:13px;text-indent:-9999px;vertical-align:middle;margin-left:10px}
.agent_lst_blank {height:50px}
.agent_lst_blank p {text-align:center;font-size:16px;margin-top:10px;margin-bottom:10px;font-weight:500}
.report .btn-horiz .btn {margin-left:0;margin-right:0}
.report .inp-horiz {width:518px;text-align:left;margin:0}
.report .inp-textbox {width:518px}
.report .agent-card {height:105px;}

/* 매물삭제요청 */
.deleteitm .title {text-align:left}
.deleteitm .container {padding-top:10px}
.deleteitm .m_exp {text-align:left;font-size:15px;margin:0}
.rd-block .radio {display:block;font-size:15px;margin-bottom:5px}

/* 중개평가하기 */
.rate .header .tit {text-align:left}
.rate .container {padding-top:20px}
.rate .pop-cont {max-height:500px;padding-top:20px}
.rate .p_exp {padding-left:0;padding-bottom:10px;text-align:left;padding-top:0}
.rt_items {overflow:hidden;/*margin-bottom:3px*/height:35px}
.rt_items .p_area {display:inline-block;float:left;font-size:14px;line-height:36px;width:300px;text-align:left;}
.rt_items .radio-box {margin-top:5px}
.avr_area {padding:0 0 20px;text-align:left}
.avr_area .star_score {margin-bottom:0;display:inline-block}
.avr_area .star_score span {text-align:left}
.avr_area .star_score span.st_off {background: url(../images/sub/point0_2.png) no-repeat;width:209px;height:35px;}
.avr_area .star_score span.st_on {background:url(../images/sub/point5_2.png) no-repeat;width:209px;height:35px}
.t_score {display:inline-block;margin-left:15px;font-size:20px;vertical-align:-4px}
.rt_items + .txtarea {margin-top:5px;}
.rt_items .radio-box .radio {padding:0 10px 0 0}
.rt_items .radio-box .radio:last-child {padding:0}
.rt_items .radio-box i {margin-right:3px;}
.rt_items:last-child {height:auto;}
.rt_items form {display:inline-block;float:right;vertical-align:middle;text-align:right}
.rate .pop .btn {margin-right:0;margin-left:0}
.avr_area .star_input {font-size:20px;margin-left:10px;vertical-align:middle}
.rt_items:last-child .p_area {width:100%;}
.rt_items:nth-child(5) {height:70px;}
.rt_items:nth-child(5) .p_area {line-height:1.6}

/* 중개사선택하기 */
.pop_selcbk {width:800px;height:650px;max-height:650px}
.pop_selcbk .header {height:60px}
.pop_selcbk .pop-header {padding-left:30px;padding-right:30px}
.pop_selcbk .p_exp {padding-top:0;text-align:left;}
.pop_selcbk .container {height:590px;max-height:590px;overflow-y:hidden}
.pop_selcbk .map-container {height:444px;}
.pop_selcbk .map-container .map {float:left;width:calc(100% - 290px);height:100%;border:1px solid #b9b9b9;margin-right:10px;box-sizing:border-box;margin-top:0}
.map-container .map img {width:100%;height:100%}
.agentwrap {float:left;height:100%;overflow-y:auto;width:280px;border:1px solid #b9b9b9;padding:10px;box-sizing:border-box}
.pop_selcbk .agent-list {padding:0;margin:-5px;width:268px}
.pop_selcbk .agent-list .agent-card {width:280px}
.agent-card-small {width:150px}
.ag-pointer {position:absolute;z-index:100;display:block;background:url(../images/sub/ico_pointer02.png) no-repeat;background-size:contain;width:20px;height:23px;}
.ag-pointer:hover, .ag-pointer.on {display:block;background:url(../images/sub/ico_pointer02_on.png) no-repeat;background-size:contain;width:20px;height:23px}
.pop_selcbk .tit {text-align:left;margin-bottom:5px}
.pop_selcbk .add_txt {margin-bottom:0}
.pop_selcbk .pop-cont {padding:10px 30px 0;height:auto;min-height:auto;max-height:100%;}
.pop_selcbk .lstsort {position:absolute;right:60px;top:35px}
.pop_selcbk .itm_inner .agent_info {padding-top:0;padding-bottom:0}
.pop_selcbk .itm_inner .agent_info .bk_info p:first-child {font-size:13px;}
.pop_selcbk .sort01, .pop_selcbk .sort02 {cursor:pointer}
.pop_selcbk .check-box02 {position:absolute;right:10px;top:50%;transform:translatey(-50%);height:auto;display:inline-block}
.pop_right {width:415px;height:100%;background:#fff;padding-top:3.5rem;padding-right:2.75rem;padding-bottom:4rem;padding-left:2.75rem;position:absolute;top:0;right:0;bottom:0}
.pop_selcbk .btn {width:200px;display:inline-block;margin-top:10px}

@media screen and (max-width: 768px) {
.pop_selcbk {width:100%;}
.pop_selcbk .map-container {display:block;width:100%;}
.pop_selcbk .map-container .map {display:block;width:100%;height:250px;margin-bottom:5px;}
.pop_selcbk .map-container .agentwrap {display:block;width:100%;height:220px;overflow-y:auto}
.pop_selcbk .map-container .agent-list {display:flex;flex:auto;width:100%;margin:0}
.pop_selcbk .map-container .agent-list li {width:calc((100% - 20px)/2)}
.pop_selcbk .map-container .agent-card:last-child {margin-bottom:5px}
}

/*공유오피스신청*/
.joinagent-share h2 .m_exp {margin-top:0;text-align:left;font-size:15px}
.joinagent-share .inquery {border:none}
.radio-box02.three>.rd01>input[type=radio] + label {width:133px;}
.radio-career .lbl, .rdloc .lbl {display:block;margin-bottom:10px}
.radio-career {overflow:hidden;margin-top:10px;margin-bottom:5px}
.radio-career .radio {display:block;float:left;width:50%;padding-right:10px}
.radio-date .radio {display:block;float:none;width:100%;padding:0;}
.joinagent-share .row {border:none;padding:6px 0}
.joinagent-share .row .lbl {display:block;width:100%;text-align:left;padding-bottom:5px}
.joinagent-share .row .ins {display:block;width:100%;text-align:left;position:relative;margin-bottom:8px}
.joinagent-share .row .ins .selec {line-height:43px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
/*.joinagent-share .row:nth-child(3) .ins {position:static}*/
.joinagent-share .row:nth-child(3) .ins .filter_selec {width:418px;max-height:260px;position:absolute;top:0;left:0;z-index:20;background:#fff;padding:20px;border:1px solid #eaeaea}
.joinagent-share .row:nth-child(3) .ins .filter_selec h3 {margin-bottom:10px;font-weight:400;font-size:14px;}
.joinagent-share .row:nth-child(3) .ins .filter_selec .filter-group {max-height:150px;overflow-y:scroll}
.joinagent-share .check-box03>.chk01>input[type=checkbox] + label {width:126px;padding:0}
.joinagent-share .radio-box02>.rd01>input[type=radio] + label {width:140px;padding:0}
.joinagent-share .fd-radio>.rd01>input[type=radio]+label {width:94px;letter-spacing:-1px}
.joinagent-share .fd-radio.three>.rd01>input[type=radio]+label {width:126px;letter-spacing:-1px}
.joinagent-share .filter_selec .filter-btn-area {width:418px;position:absolute}
.joinagent-share .vt-area .ntc_p {font-size:24px;padding-top:40px}
.joinagent-share .vt-area .addexp {padding-top:40px;padding-bottom:40px}
.joinagent-share .row + .check-box {padding-bottom:10px;padding-top:10px}
.joinagent-share .row + .check-box .check {padding-right:10px}
.joinagent-share .row + .check-box .btn-xsmall {margin-top:0}
.joinagent-share .pop .btn {margin-left:0;margin-right:0;margin-bottom:0}
.joinagent-share .fd-check>.chk01>input[type=checkbox] + label {width:97px}
.lyr.viewoffice {width:650px;padding:0}
.viewoffice .lyr_inner {padding:0}
.viewoffice .lyr_inner .top {padding:20px 25px;text-align:left;}
.viewoffice .lyr_inner .top .name {font-size:28px;font-weight:500}
.viewoffice .lyr_inner .top .address {font-size:16px;}
.viewoffice .lyr_inner .bottom {width:100%;height:350px;}
.viewoffice .lyr_inner .bottom .map {width:100%;height:100%;background: url(../images/sub/bg_map_full.png) no-repeat 50% 50%;background-size: cover;}
.lyr.viewoffice .btn-close-lg {position:static;}
.lyr.viewoffice .btn-right {top:15px;right:15px}
.viewoffice .bottom .office_img {width:60%;float:left}
.viewoffice .lyr_inner .bottom .map {width:40%;float:left;position:relative} 
.office_img .slider-container, .office_img  .slider-container ul {height:350px;}
.office_img .slider-container ul li {height:350px;}
.swiper {position:absolute;bottom:15px;left:50%;margin-left:-30px;display:inline-block;background:#000;opacity:.7;border-radius:20px;width:60px;padding:5px 0;color:#fff;font-size:12px;font-weight:100;text-align:center}

/*term*/
.term .pop .header {height:85px}
.term .container {height:562px;overflow-y:auto;padding:30px;text-align:left;min-height:inherit;max-height:inherit}
.poplg .pop-cont {height:calc(100% - 64px);overflow-y:auto;padding:30px;text-align:left;min-height:inherit;max-height:inherit}
.poplg .box {margin-top:30px}
.poplg .box:first-child {margin-top:0}
.poplg .tblwrap {margin-top:10px}
.poplg .tblwrap .tbl_type01 {font-size:13px;}
.poplg .tblwrap .tbl_type01 + p {font-size:13px;margin-top:5px;color:#666}
.term .poplg .pop-cont {height:500px;padding-top:10px;padding-bottom:10px;}
.term .poplg .pop-cont .btn-horiz .btn {margin:0}
.term .poplg .btn-horiz {position:absolute;bottom:0;width:100%;}
.term .poplg .btn-horiz .btn {margin:0}


/* alert */
.lyr {background:#fff;z-index:101;border:1px solid #cdcdcd;-webkit-box-shadow:0 2px 4px 0 rgba(0,0,0,.08);box-shadow:0 2px 4px 0 rgba(0,0,0,.08);-webkit-box-sizing:border-box;box-sizing:border-box;position:fixed;left:50%;top:50%;transform:translate(-50%,-50%);width:350px;min-height:100px;max-height:500px;/*padding-bottom:20px;padding-top:20px*/padding:20px}
.lyr_l {width:500px;}
.lyr .btn-close-lg {position:absolute;right:20px;top:20px}
.lyr .close {display:block;position:absolute;top:20px;right:20px;width:25px;height:25px;background:url(../images/main/btn_close02.png) no-repeat}
.lyr_inner {padding:30px 10px;width:100%;height:100%;box-sizing:border-box;text-align:center}
h4 + .lyr_inner {padding:50px 0 40px;}
.lyr_inner p {text-align:center;font-size:16px;line-height:1.5;}
.alert_s02 {width:350px;height:250px;min-height:200px}
.alert_s02 .alt_cont {padding:20px 20px 0}
p.alt_txt {font-size:16px;line-height:1.6}
.lyr.popalertnonesale {min-height:220px;width:300px}
.lyr h4 {font-size:22px;font-weight:400;}
.lyr .content {padding-top:10px;}
.lyr_l .content {font-size:15px;padding-top:20px}
.lyr .btn-right {position:absolute;right:10px;top:10px}
/*.lyrpop01 .lyr_inner p {text-align:left}*/
.lyr .btn-close-small {position:absolute;right:20px;top:20px}

/*거래완료처리*/
.pop-complete {min-height:200px;width:600px;max-height:600px}
.pop-complete .panel-box {background:#fff;padding:10px 0 40px}
.pop-complete .title {text-align:center;font-size:24px}
.pop-complete .selec-area {padding:50px 0;text-align:center}
.pop-complete .selec-area form {display:inline-block;}
.pop-complete .btn {width:150px;margin:0 auto;}
.pop-complete .check-box02 {position:absolute;right:10px;top:50%;transform: translatey(-50%);}
.pop-complete .agent-list {padding-bottom:40px}

.joinagent-share .pop .btn-horiz .btn:first-child {margin-right:10px;}
.ja_apply .radio-career .radio {float:none;width:100%;display:flex;align-items:center;margin-bottom:5px;}

// root-dawin
:root.root-dawin {
	.unit {font-size:14px;position:absolute;right:15px;top:12px}
}