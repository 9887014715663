.bui-tab-target {
	&:not(.active) {
		@include hidden;
	}
}


/* tab-display */
.tab-display {
	.tab-list {
		display: flex;
		margin: 0;
		padding: 0;

		.tab-item {
			box-sizing: border-box;
			list-style: none;

			&.current {
				z-index: 1;
			}
		}
	}

	// tab type
	&.type-a { 
		.tab-list{
			justify-content: flex-start;
		}
	}

	&.type-b { 
		.tab-list{
			justify-content: flex-end;
		}
	}

	&.type-c { 
		.tab-list{
			justify-content: center;
		}
	}

	&.type-d { 
		.tab-list{
			.tab-item {
				flex: 1 1 100%;
				width: 100%;
			}
		}
	}
	&.type-e {
		// justify-content: space-between;
		// display: flex;
	}
	&.module-b.style-b {
		background-color: color(normal, 000);
		position: sticky;
		top: 0;
		z-index: 10;
		.tab-list {
			flex-wrap: wrap;
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				z-index: 1;
				bottom: 0;
				width: 100vw;
				height: 1rem;
				background-color: color(normal, 050);
				left: calc(50% - 50vw);
			}
			.tab-item {
				padding: 0 16rem;
				position: relative;
				cursor: pointer;
				&:not(.current) {
					.tab-text {
						display: block;
						color: color(normal, 030);						
					}
				}
				&.current {
					.tab-text {
						position: relative;
						color: color(normal, 010);							
					}
					&::after {
						content: '';
						display: block;
						position: absolute;
						z-index: 1;
						right: 0;
						bottom: 0;
						left: 0;
						height: 3rem;
						background-color: color(normal, 010);
					}
				}
			}			
		}
	}
	&.module-a.style-i {
		.tab-list {
			flex-wrap: wrap;
			.tab-item {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				cursor: pointer;
				.tab-text {
					color: color(normal, 030);
					background-color: color(normal, 000);
					border: 1rem solid color(normal, 030);
					border-radius: 100em;
					padding: 4rem 16rem;
					@include font((outline: body, size: small-1x, weight: regular)...);
				}
				+.tab-item {
					margin-left: 8rem;
					gap: 2rem;
				}
				&.current {
					.tab-text {
						color: color(normal, 000);
						border: 1rem solid color(normal, 010);
						background-color: color(normal, 010);
					}
				}
				&:last-child {
					position: relative;
					img {
						position: absolute;
						left: 100rem;
						top: 0;
					}
				}
			}
		}
		&.large-3x {
			.tab-list {
				.tab-item {
					.tab-text {
						padding: 8rem 24rem;
						@include font((outline: body, size: small-1x, weight: regular)...);
					}
				}
			}
		}
	}
}

/*
 * module-a outline
 * 
 * @ $line-height
 * @ $line-height
 * @ $font-size
 * @ $font-weight
 * @ $column-gap
 */
 $tab-module-a-outline: (
	'small-1x'	24rem 24rem calc(24rem/1.5) 700 8rem,
	'small-2x'	20rem 20rem calc(20rem/1.5) 700 8rem,
);

.tab-display.module-a {
	.tab-list {
		.tab-item {
			display: flex;
			align-items: center;

			.tab-text {
				position: relative;
				color: color(normal, 040);
				text-decoration: none;
			}

			&.current {
				.tab-text {
					color: color(normal, 100);
				}
			}

		}
	}

	&.style-i {
		.tab-list {
			.tab-item {
				&.current {
					.tab-text {
						color: color(normal, 000);
						border: 1rem solid color(normal, 010);
						background-color: color(normal, 010);
					}
				}
			}
		}
	}

	// outline
	@each $name, $height, $line-height, $font-size, $font-weight, $column-gap in $tab-module-a-outline {

		&.#{$name} {
			.tab-list {
				column-gap: $column-gap;
				line-height: $line-height;
				font-size: $font-size;
				font-weight: $font-weight;

				.tab-item {
					display: block;

					.tab-text {
						display: block;
					}
				}
			}
		}
	}
}
/*
 * module-b outline
 * 
 * @ $line-height
 * @ $line-height
 * @ $font-size
 * @ $font-weight
 * @ $column-gap
 */
 $tab-module-b-outline: (
	'large-2x'	56rem 56rem calc(32rem/1.5) 500,
	'large-1x'	48rem 48rem calc(24rem/1.5) 500,
	'large'	40rem 20rem calc(20rem/1.5) 500,
);

.tab-display.module-b {
	.tab-list {
		.tab-item {
			display: flex;
			align-items: center;

			.tab-text {
				position: relative;
				color: color(normal, 030);
				text-decoration: none;
			}

			&.current {
				.tab-text {
					color: color(normal, 100);
				}
			}

		}
	}
	&.type-e {
		padding: 0 20rem;

	}

	// outline
	@each $name, $height, $line-height, $font-size, $font-weight in $tab-module-b-outline {

		&.#{$name} {
			.tab-list {
				.tab-item {
					display: block;

					.tab-text {
						height: $height;
						line-height: $line-height;
						font-size: $font-size;
						font-weight: $font-weight;
						border-top-left-radius: $height * 0.1;
						border-top-right-radius: $height * 0.1;
					}
				}
			}

			// mobile
			@include respond-below(tablet) {

				// .tab-list {
				// 	.tab-item {				
				// 		.tab-text {
				// 			@include font((outline: body, size: small-1x)...);
				// 		}
				// 	}
				// }
			}
		}
		
	}
}


/*
 * module-c outline
 * 
 * @ $line-height
 * @ $line-height
 * @ $font-size
 * @ $font-weight
 */
$tab-module-c-outline: (
	'large-2x'	56rem 24rem calc(24rem/1.5) 700,
	'large-1x'	48rem 24rem calc(24rem/1.5) 700,
	'large'	40rem 20rem calc(20rem/1.5) 700,
);

.tab-display.module-c {
	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;		
		left: 0;
		right: 0;
		width: 100%;
		height: 1rem;
		background-color: color(base, 080);
	}

	.tab-list {
		.tab-item {
			display: flex;
			justify-content: center;
			flex: 1 1 100%;
			
			.tab-text {
				flex: 1 1 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				white-space: nowrap;
				@include font((outline: body, size: small-1x)...);
				color: color(normal, 040);
				// background-color: color(base, 000);
				background-color: unset;
				border: 1px solid transparent;			
				cursor: pointer;	

				&::after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 0;
					height: 2rem;
					background-color: color(normal, 010);
				} 
			}
			
			&:not(.current) {
				color: color(normal, 030);
			}

			&.current {
				color: color(normal, 010);
				@include font((weight: bold)...);

				.tab-text {
					color: color(normal, 010);
					background-color: #fff;
					border-top-color: color(normal, 010);
					border-right-color: color(normal, 010);
					border-left-color: color(normal, 010);
				}
			}
		}
	}
	

	// outline
	@each $name, $height, $line-height, $font-size, $font-weight in $tab-module-c-outline {

		&.#{$name} {
			.tab-list {
				.tab-item {
					display: block;

					.tab-text {
						height: $height;
						line-height: $line-height;
						font-size: $font-size;
						font-weight: $font-weight;
						border-top-left-radius: $height * 0.1;
						border-top-right-radius: $height * 0.1;
					}
				}
			}

			// mobile
			@include respond-below(tablet) {

				.tab-list {
					.tab-item {				
						.tab-text {
							@include font((outline: body, size: small-2x)...);
						}
					}
				}
			}
		}
		
	}

	&.type-d {
		.tab-list {
			.tab-item {
				flex:0 1 196rem;

				.tab-text {					
					background-color: #f4f4f4;
				}
			}
			&.curent {
				.tab-text {
					background-color: #f4f4f4;
				}
			}
		}
		// mobile
		@include respond-below(tablet) {
			width:100%;
			
			.tab-list {
				.tab-item {
					flex:100%;
				}
			}
		}
	}
	
}

