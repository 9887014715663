@charset "UTF-8";
/* CSS Document */
html,body,div,span,object,iframe,abbr,address,cite,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,label,table,caption,tbody,tfoot,thead,tr,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button,a{margin:0;padding:0;border:0;background:transparent;font-size-adjust:none;-webkit-font-size-adjust:none}
body {font-weight:400;color:#333;height:100%;font-size:15px;line-height:1.4;letter-spacing:-.3px;-webkit-touch-callout:none;-webkit-text-size-adjust:none;-webkit-user-select:none;position: relative;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {display:block;}
dl,ul,ol,menu,li{list-style:none}
blockquote, q{quotes: none}
blockquote:before, blockquote:after,q:before, q:after{content:'';content:none}
input,select,textarea,button{vertical-align:middle}
input::-ms-clear{display:none}
button{border:0 none;background-color:transparent;cursor:pointer}
body{background:#fff;margin:0}
body,th,td,input,select,textarea,button{/*line-height:1.5;*/color:#333} /* color값은 디자인가이드에 맞게사용 */
a{color:#333;text-decoration:none}
/*a:active, a:hover{text-decoration:underline}*/
/* a:active{background-color:transparent} */
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal}
::-webkit-input-placeholder {color:#aaa;font-weight:400}
::-moz-placeholder {color:#aaa;font-weight:400}
:-ms-input-placeholder {color:#aaa;font-weight:400}
:-moz-placeholder {color:#aaa;font-weight:400}
// html {-ms-overflow-style:none}
// ::-webkit-scrollbar {display:none}
// element::-webkit-scrollbar {display:none}

.normal{font-weight:400}
.bold{font-weight:700}
.bolder{font-weight:800}
.light{font-weight:300}
textarea, button, select{-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-appearance:none;appearance:none;-moz-appearance:none;}
textarea {resize:none;font-family: Open Sans,Noto Sans KR,sans-serif;}
/*input[type="text"], input[type="password"] {-webkit-appearance:none;-moz-appearance:none;appearance:none;position:relative;width:100%;text-align:left;background:none;border:none;display:block;outline:none;border-radius:0;}*/

i {font-style:normal}
table{border-collapse:collapse;width:100%;}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {-webkit-appearance:none;margin:0;}
/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {display:none;}
/* A few custom styles for date inputs */
input[type="date"] {appearance:none;-webkit-appearance:none;/*letter-spacing:-.15em*/font-family: Open Sans,Noto Sans KR,sans-serif;font-size:14px;letter-spacing:0;border-radius:0}
* {
	-webkit-overflow-scrolling: touch;
}
input:read-only {background:#eaeaea;}

/* reset */
:root.root-dawin {
}