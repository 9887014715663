@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-signupbroker {
	#wrap {
		display: flex;
		flex-direction: column;
	}
	
	.page-body {
		#local {
			.local-util {
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}
	
	.content-body {
		.section.module-a {
			.section-wrap {
				display: flex;
				flex-direction: column;
				row-gap: 12rem;
				
				.section-head {
					.section-subject {
						margin: 0;
						@include font((outline: body, size: small-1x, weight: bold)...);
						color: color(normal, 100);
		
						.section-name {
							display: block;
						}
					}
				}
			}
		
			&:not([class*=type-]) {
				.section-wrap {
					.section-head {
						@include hidden;
					}
				}
			}
		}
	}

	.purchase-data {
		.data-list {
			display:flex;
			column-gap:8rem;
			align-items:center;

			.data-item {
				color: color(normal, 100);

				&.discount {
					color: color(accent, 010);

					.body {
						@include font((outline: body, size: medium, weight: bold)...);
					}
				}

				.head {
					display:none
				}

				.body {
					@include font((outline: body, size: small)...);
				}
			}
		}
	}

	.section {
		.subsection.academy {
			&.module-a {
				position: relative;				
			}

			&.type-a {
				background-color: color(accent, 050);
				background-image: url(../images/@temp/img_bggradient01.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top right;
			}
			&.type-b {
				background-color: #181818
			}
			&.type-c {
				background-color: color(normal, 100);
			}
			&.type-d {
				background-color: color(primary, 040);
				background-image: url(../images/@temp/img_bggradient02.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: bottom;
			}
			&.type-e {
				background-color: color(accent, 050);
			}
			&.type-f {
				background-color: color(normal, 050);

				.display-board,
				.board-wrap,
				.board-infom {
					width:100%;
				}		

				.board-subject {
					color: color(normal, 100);
				}	
			}
			&.type-g {
				background-color: color(normal, 000);

				.board-subject {
					color: color(normal, 100);
				}
			}
			&.attr-bgpoint::before {
				display: none;
				content:'';
				background-image: url(../images/@temp/img_bggradient01.png);
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translatex(-50%);
				width: 100%;
				height: 200rem;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}			
		}

		.board-util {
			.button-area {
				display: flex;
				justify-content: center;
			}
		}

		.data-list {

			&.type-d {
				.data-item {
					display: flex;
					align-items: center;
					justify-content: center;
				}

			}
		}

		.data-display {
			position: relative;

			&.style-c {
				.data-list {
					column-gap: 24rem;
					row-gap: 24rem;
					flex-wrap: wrap;
				}
			}
		}

		.data-display {

			.data-list {
				
				&.style-d {

					.data-item {
						border-radius: 16rem;
						flex-shrink: 1;
						background-color: color(normal, 010);
						padding: 32rem;
						

						&:nth-child(2),
						&:nth-child(4) {
							background-color: initial;
							display:flex;
							align-items:center;
						}

						.data-area {
							display:flex;
							flex-direction: column;
							row-gap:16rem;

							.data-caption {
								color: color(accent, 010);
								@include font((weight: bold)...);
								@include font((outline: body, size: small-1x)...);
							}
			
							.data-head {
								color: color(normal, 000);
								@include font((outline: body, size: medium)...);

								.em {
									color: color(accent, 010);	
								}

								.wbr {
									display:block;
								}
							}			
						}
					}
				}

				&.style-e {
					.data-item {
						border-radius: 16rem;
						background-color: color(normal, 000);
						padding: 32rem;
						display: flex;
						align-items: flex-end;
						flex-direction: row;
						flex: 1;
						justify-content: space-between;

						.data-area {
							display:flex;
							flex-direction: column;
							row-gap:16rem;
							justify-content: left;
							text-align:left;

							.data-caption {
								color: color(accent, 010);
								@include font((weight: bold)...);
								@include font((outline: body, size: small-1x)...);
							}
			
							.data-head {
								color: color(normal, 010);
								@include font((outline: body, size: medium, weight: bold)...);

								.em {
									color: color(accent, 010);	
								}

								.wbr {
									display:block;
								}
							}								
						}

						.data-figure {
							width: 80rem;
							height: 80rem;
							object-fit: contain;							
						}	
						
					}
				}

				&.style-f {

					.data-item {
						border-radius: 16rem;
						background-color: color(normal, 010);
						padding: 32rem;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						flex-direction: column;
						flex: 1;
						row-gap:24rem;

					.data-area {
						display:flex;
						flex-direction: column;
						row-gap:8rem;
						justify-content: left;
						text-align:left;

						.data-caption {
							color: color(normal, 000);
							@include font((weight: bold)...);
							@include font((outline: body, size: small-2x)...);
						}
		
						.data-head {
							color: color(normal, 000);
							@include font((outline: body, size: medium)...);

							.em {
								color: color(accent, 010);	
							}

							.wbr {
								display:block;
							}
						}	

						.data-body {
							.para {
								@include font((outline: body, size: small)...);
								display:flex;
								row-gap:4rem;
								flex-direction:column;

								.wbr {
									display:block;
									text-align:left;
										
									&:before {
										display:inline-block;
										content:'';
										width:16rem;
										height:16rem;
										background-image: icon(bullet-dot, color(base, 000));
										margin-right:8rem;
									}
								}
							}
						}		
					}
					
					&.afterservice {
						background-color:#431205;
						
						.data-body {

							.para {
								@include font((outline: body, size: small)...);
								display:flex;
								row-gap:4rem;
								flex-direction:column;

								.wbr {
									display:block;
									text-align:left;
									color: color(accent, 010);
											
									&:before {
										display:inline-block;
										content:'';
										width:16rem;
										height:16rem;
										background-image: icon(bullet-dot, color(accent, 010));
										margin-right:8rem;
									}
								}	
							}
						}
					}

					.process-data {
						margin: 8rem 0 16rem;
						.data-list {
							&.module-b {
								justify-content: flex-start;
								
								.data-item {
									width: 104rem;
									height: 104rem;	
									box-sizing: border-box;
									padding: 0;		
									flex-direction: row;
									align-items:center;
									justify-content: flex-start;
									flex-shrink: 0;		

									.data-wrap {										
										display: flex;
										align-items: center;
										justify-content: center;	
										width: 104rem;
										height: 104rem;	
										border-radius: 104rem;	
										flex-shrink: 0;							

										.head {
											display:none;
										}
									}							

									&:nth-child(1) {
										.data-wrap {
											background-color: color(accent, 050, 0.2);
											color: color(base, 000);
										}
										&::after {
											display:flex;
											content:'';
											width: 24rem;
											height: 32rem;
											background-image: icon(chevron-right, color(accent, 050, 0.2));
											background-repeat: no-repeat;
											background-size: contain;
											background-position: center;
											flex-shrink:0;
										}
									}
									&:nth-child(2) {
										.data-wrap {
											background-color: color(accent, 050, 0.4);
											color: color(base, 000);
										}
										&::after {
											display:flex;
											content:'';
											width: 24rem;
											height: 32rem;
											background-image: icon(chevron-right, color(accent, 050, 0.4));
											background-repeat: no-repeat;
											background-size: contain;
											background-position: center;
											flex-shrink: 0;	
										}
									}
									&:nth-child(3) {
										.data-wrap {
											background-color: color(accent, 050, 0.6);
											color: color(base, 000);
										}
										&::after {
											display:flex;
											content:'';
											width: 24rem;
											height: 32rem;
											background-image: icon(chevron-right, color(accent, 050, 0.6));
											background-repeat: no-repeat;
											background-size: contain;
											background-position: center;
											flex-shrink: 0;	
										}
									}
									&:nth-child(4) {
										.data-wrap {
											background-color: color(accent, 050, 0.8);
											color: color(base, 000);
										}
										&::after {
											display:flex;
											content:'';
											width: 24rem;
											height: 32rem;
											background-image: icon(chevron-right, color(accent, 050, 0.8));
											background-repeat: no-repeat;
											background-size: contain;
											flex-shrink: 0;	
										}
									}
									&:nth-child(5) {
										.data-wrap {
											background-color: color(accent, 050, 1);
											color: color(base, 000);
										}
									}
								}
								

								
							}
						}
					}
						

					.data-figure {
						width: 100%;
						height: 100%;
						object-fit: contain;							
					}	
					}
				}

				&.style-g {

					.data-item {
						border-radius: 16rem;
						flex-shrink: 1;
						padding: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						width: calc((100% - 24rem) / 2);

						.data-figure {
							img {
								width:100%;
								height:100%;
								object-fit: contain;
							}
						}
					}
				}

				&.style-h {
					column-gap: 16rem;
					row-gap: 0;
					flex-direction: column;
					flex-wrap: wrap;	

					.data-item {
						border-radius: 16rem;
						flex-shrink: 1;
						padding: 32rem;
						display: flex;
						align-items: center;
						justify-content: flex-start;	

						&:nth-child(1) {
							background-color: color(normal, 010);

							.data-head {
								color: color(normal, 000)
							}
						}	
						&:nth-child(2) {
							background-color: color(normal, 000);
							flex-direction: column;
							align-items: flex-start;
							row-gap: 16rem;

							.data-head {
								color: color(normal, 100)
							}
						
						}		
					}
					.guide-data {
						display:flex;
						column-gap:24rem;
						justify-content:flex-start;

						&.module-a {
							.head,
							.body {
								@include font((outline: body, size: small)...);
								color: color(normal, 000);
								text-align:left;

								.para {
									@include font((outline: body, size: small)...);
									display:flex;
									row-gap:4rem;
									flex-direction:column;

									.wbr {
										display:block;
										text-align:left;
										
										&:before {
											display:inline-block;
											content:'';
											width:16rem;
											height:16rem;
											background-image: icon(bullet-dot, color(base, 000));
											margin-right:8rem;
										}
									}
								}
							}
						}

						&.module-b {
							.head,
							.body {
								@include font((outline: body, size: small)...);
								color: color(normal, 100);
								text-align:left;

								.para {
									@include font((outline: body, size: small)...);
									color: color(normal, 100);
									display:flex;
									row-gap:4rem;
									flex-direction:column;
									
									.wbr {
										display:block;
										text-align:left;
										
										&:before {
											display:none;
											content:'';
											width:16rem;
											height:16rem;
											background-image: icon(bullet-dot, color(base, 000));
											margin-right:8rem;
										}
									}
								}
							}
							.data-side {
								@include font((outline: body, size: small-2x)...);
								color: color(normal, 030);
								margin-top: 8rem;
							}
							.data-list {
								&.date {
									display: flex;
									flex-direction: column;
									gap: 6rem;
									.data-item {
										padding: 0;
										background-color: unset;
										flex-direction: column;
										align-items: flex-start;
										gap: 0;
										.data-area {
											.head,
											.body {
												color: color(normal, 020);
												@include font((outline: body, size: small-1x)...);
											}
											&.title {
												.head {
													@include hidden;
												}	
												.body {
													@include font((outline: body, size: small, weight: bold,)...);
													color: color(normal, 010);
												}										
											}
										}
									}
								}
							}
						}

						.purchase-data {
							.data-list {
								display:flex;
								column-gap:8rem;
								// align-items:center;

								.data-item {
									color: color(normal, 000);
									background-color:initial;
									border-radius: initial;
									flex-shrink: initial;
									justify-content: initial;
									align-items: initial;
									padding: initial;
									display: initial;

									&.discount {
										color: color(accent, 010);

										.body {
											@include font((outline: body, size: medium, weight: bold)...);
											color: color(accent, 010);
										}
									}

									.head {
										display:none
									}

									.body {
										@include font((outline: body, size: small)...);
									}
								}
							}							
						}
						
					}
					.data-util {
						width:100%;
						margin-top:32rem;
					}
				}
			}
		}
		.data-display.advantages {
			&::after {
				display: flex;
				content: '';
				background-image: url(../images/@temp/img_arrow.png);
				background-repeat: no-repeat;
				background-size: contain;
				width: 400rem;
				height: 177rem;
				justify-content: center;
				align-items: center;
				margin: 0 auto;
			}
				
		}

		.data-display.benefit {
			.data-list {
				justify-content: space-between;				
			}						
		}

		.slide-display {
			.data-list.module-b {
				column-gap:24rem;
			}
		}

		.video-display {
			width:100%;
			position: relative;
			padding-bottom: 56.25%;
			padding-top: 30rem; 
			height: 0; 
			overflow: hidden;

			iframe,
			object,
			embed {
				position:absolute; top:0; left:0; width:100%; height:100%;
			}
		}

		.data-display.review {
			.data-list {
				&::after {
					display: block;
					content: '';
					background-image: url(../images/@temp/img_hidden.png);
					position: absolute;
					bottom:0;
					left:0;
					width:100%;
					height:120rem;
				}
				
			}
		}

		.section-util {
			padding:16rem 64rem;
			background-color: color(base, 090);
			position:fixed;
			bottom:0;
			left:0;
			width:100%;
			z-index:10;

			.purchase-display {
				max-width:680rem;
				margin:0 auto;
				display:flex;
				column-gap:32rem;
				justify-content:space-between;
				align-items:center;	

				.purchase-data {
					.data-list {
						.data-item {
							color: color(normal, 000);

							&.discount {
								color: color(accent, 010);

								.body {
									@include font((outline: body, size: medium, weight: bold)...);
								}
							}
						}
					}
				}				

				.button-area {
					column-gap:8rem;
				}
			}
			
		}		
	}
	


	#footer,
		.page-foot {
			// display: none;
		}

	
	
	@include respond-below(tablet) {
		.page-head {
			display:none;
		}
		.page-head.type-d {
			@include hidden;
		}
		.page-body {
			display: flex;
			flex-direction: column;
	
			#local {
				display: flex;
				flex-direction: row;
				
				.local-head {
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
	
						+.local-control {
							order: 3;
						}
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
	
							.btn.share::before {
								background-color: color(base, 000);
								background-image: icon(share, color(base, 100));
							}
	
							.btn.search {
								&::before {
									background-color: color(base, 000);
									background-image: icon(dawin-search, color(base, 100));
								}
								&.selected::after {
									content: "";
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									width: 4rem;
									height: 4rem;
									border-radius: 2rem;
									background-color: color(accent, 010);
								}
							}
						}
					}
				}
			
				.local-body {
					padding: 0;
	
					#content {
						margin: 0;
					}
				}
	
				.local-util {
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
		}
	
		#page.page-intro {
			flex: 1 1 100%;
			padding: 64rem 0 0 0;
			overflow: hidden;
	
			.page-body {
				flex: 1 1 100%;
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
	
					.local-head {
						position: absolute;
						z-index: 1;
						top: 0;
						right: 0;
						left: 0;
						background-color: color(base, 000);
					}
					.local-body {
						@include most-outer-selector('.mobile') {
							@include overflow-scroll-system(dark, medium, y);
						}
				
						@include most-outer-selector('.laptop') {
							@include overflow-scroll-custom(dark, medium, y);
						}
					}
				}
			}
		}
	
		#page.page-front {
			.page-head {
				display: none;
			}
	
			.page-body {
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
					
					.local-head {
						position: relative;
						z-index: 10;
						background-color: color(base, 000);
					}
				}
	
				.local-body {
					flex: 1 1 100%;

					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
				}
			}
	
		}
		
		#footer,
		.page-foot {
			display: none;
		}

		.section {
			.subsection.academy.module-a {
				.subsection-body {
					display:flex;
					width:100%;
					padding:80rem 32rem;
					box-sizing: border-box;
					justify-content: center;
					.display-board.module-a.type-c {
						width:100%;
						.board-wrap {
							width:100%;
							.board-infom {
								width:100%;
							}
						}
					}
				}
			}
			.subsection.academy.module-b {
				.subsection-body {
					display:flex;
					width:100%;
					padding:48rem 24rem 40rem;
					box-sizing: border-box;
					justify-content: flex-start;
					@include overflow-scroll-custom (dark, medium, x);

					.slide-display {
						width:1000rem;
					}
				}
			}

			.data-display.advantages {
				.data-list {
					flex-direction: column;
					row-gap: 0;
					justify-content: center;
					align-items: center;
				}
				&::after {
					width: 100%;
				}
			}

			.data-display.review {
				.data-list {
					column-gap: 16rem;
					row-gap:16rem;
					flex-direction: column;
					
					.data-item {
						width:100%;
					}
				}
			}
			.section-util {
				padding:24rem;
				box-sizing:border-box;

				.purchase-display {
					flex-direction:column;
					row-gap:8rem;
					justify-content:flex-start;
					align-items:center;		
					
					// .purchase-data {
					// 	.data-list {
					// 		.data-item {
					// 			color: color(normal, 000);
					// 		}
					// 	}
					// }			

					.button-area {
						column-gap:8rem;
					}
				}
			}
			.guide-data {
				display:flex;
				flex-direction:column;
				row-gap:8rem;
				justify-content:flex-start;

				.purchase-data {
					.data-list {
						flex-direction:column;
						row-gap:4rem;
						align-items:flex-start;
					}
				}
			}

			
		}
		

	}
	
	
	@include respond-above(tablet) {
		#page {
			margin: 56rem 0 0 0;
			
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, medium, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, medium, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
	
		}		
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				padding: 0;
	
				.local-head {
					display: none;
					flex-direction: column;
					align-items: center;
					margin: 48rem 0;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large, weight: medium)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0;
				}
			}
		}

		.section {
			.subsection.academy.module-a {
				display: flex;
				padding: 0;

				.subsection-body {
					padding: 108rem 0;
					width: 680rem;
					margin: 0 auto;
				}
			}
			.subsection.academy.module-b {
				display: flex;
				padding: 24rem 48rem;
				justify-content: flex-start;

				.subsection-body {
					padding: 48rem 0;
					max-width: 100%;
					margin: 0 auto;
				}
			}
		}
	}
	
	
	.local-body {
		#content {	
			.content-body {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 100%;
			}
		}
	}
	
	
	
	
	@keyframes posts-func-fab {
		0% {
			visibility: hidden;
			opacity: 0;
			transform: translate(0, 48rem);
		}
	
		100% {
			visibility: visible;
			opacity: 1;
			transform: translate(0, 0);
		}
	}


	/* 중개사 프로모션 */
	.section {
		.subsection.promotionbk {
			display:flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.subsection-wrap {
				padding: 108rem 0;
				row-gap: 56rem;
			}

			.subsection-head {
				// padding: 108rem 0 0;
				width: 640rem;
				margin: 0 auto;

				.subsection-subject {
					color: color(normal, 000);
					@include font((outline: body, size: large-1x, weight: bold)...);
				}
				.subsection-summary {
					color: color(normal, 000);
					@include font((outline: body, size: small-1x, weight: bold)...);
				}
			}
			// type none
			&:not([class*=type-]) {
				.subsection-head {
					@include hidden();
				}
				.subsection-body {
					// padding: 108rem 0;
				}
			}
			.subsection-body {
				// padding: 0 0 108rem;
				width: 640rem;
				margin: 0 auto;

				~.subsection-body {
				margin-top: 56rem;
				}
			}
			&.module-a {
				// height:calc(100vh - 56rem);
				position: relative;				
			}

			&.style-a {
				background-color: color(accent, 010);
				background-image: url(../images/@temp/img_promotionbkbg01.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: bottom center;

				.board-wrap {
					.board-head {
						row-gap: 16rem;
						align-items: center;
						.board-caption {
							// width: 400rem;
							// height: 40rem;
							// background-image: url(../images/@temp/img_promotion_text02.png);
							// background-size: contain;	
							// background-repeat: no-repeat;	
							// text-indent: -9999rem;
						}
						// .board-subject {
						// 	width: 640rem;
						// 	height: auto;
						// 	background-image: url(../images/@temp/img_promotion_text01.png);
						// 	background-size: contain;	
						// 	background-repeat: no-repeat;	
							
						// 	strong, span {
						// 		text-indent: -9999rem;
						// 	}
						// }
						.board-side {
							// width: 422rem;
							// height: 60rem;
							// background-image: url(../images/@temp/img_promotion_text03.png);
							// background-size: contain;	
							// background-repeat: no-repeat;	
							// text-indent: -9999rem;
							margin-bottom: 24rem;
							@include hidden;
						}
					}
				}
			}
			&.style-b {
				background-color: #181820;
				
				.subsection-head {
					.subsection-summary {
						font-weight: inherit;
						.em {
							@include font((weight: bold)...);
						}
					}
				}
				.subsection-body {
					max-width: 100%;
					width: 100%;
					padding: 0;
				}
			}
			&.style-c {
				// height: 1643rem;
				background-color: color(accent, 050);
				background-image: url(../images/@temp/img_promotionbkbg02.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top center;

				.info-board {
					.board-subject {
						flex-wrap: wrap;
						row-gap: 2rem;
					}
					.board-summary {
						.em {
							color: color(base, 030)
						}
					}
					.board-util {
						position: relative;
						z-index: 1
					}
				}
			}
			&.style-d {
				background-color: #f1f1f1;

				.subsection-subject {
					color: color(base, 090);
				}
				.subsection-summary {
					color: color(base, 090);
					text-align: center;
					font-weight: inherit;
				}

				.subsection-body {
					width: 840rem;

					.data-list.style-e {
						gap: 24rem;
	
						.data-item {
							padding: 36rem 24rem;
							align-items: center;
							justify-content: center;		
							
							.data-area {
								flex-direction: column;
								row-gap: 8rem;
								text-align: center;
	
								.data-name {
									color: color(base, 090);
								}
								.data-body {
									display: flex;
									flex-direction: column;
									row-gap: 6rem;
									.data-price {
										@include font((outline: body, size: small, weight: bold)...);
										display: flex;
										flex-direction: column;

										.em {
											@include font((weight: regular)...);
										}
									}
									.data-count {
										@include font((outline: body, size: small-1x)...);
									}
								}								
							}
	
							&.mem-plus {
								background-color: color(accent, 010);
	
								.data-name {
									color: color(base, 000)
								}
								
								.data-price,
								.data-count {
									color: color(base, 000);
									
								}
							}
						}
					}
				}			
				
			}
			&.attr-bgpoint::before {
				display: none;
				content:'';
				background-image: url(../images/@temp/img_bggradient01.png);
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translatex(-50%);
				width: 100%;
				height: 200rem;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}		
			&.style-e {
				background-color: color(accent, 050);
				background-image: url(../images/@temp/img_bggradient01.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top right;

				.subsection-body {
					padding: 0;
				}
			}	
		}

		.board-util {
			.button-area {
				display: flex;
				justify-content: center;
			}
		}

		.data-list {

			&.type-d {
				.data-item {
					display: flex;
					align-items: center;
					justify-content: center;
				}

			}
		}

		.data-display {
			position: relative;

			&.style-c {
				.process-data {
					.data-list {
						flex-wrap: wrap;
					}
				}
				.data-list {
					column-gap: 24rem;
					row-gap: 24;
					flex-wrap: wrap;
				}
			}
		}

		@include respond-below(tablet) {
			.subsection.promotionbk {	
				width: 100%;
				.subsection-wrap {
					width: 85%;
					padding: 88rem 0;
				}
				.subsection-head {
					width: 100%;
					text-align: center;
				}
				.subsection-body {
					width: 100%;
				}
				.subsection-util {
					.button-area {
						.btn {
							flex: auto;
						}
					}
				}
				&.style-a {
					.subsection-wrap {
						width:80%;
					}
					.board-wrap {
						.board-head {
							row-gap: 16rem;
							align-items: center;
							.board-side {
								margin-bottom: 24rem;
							}
						}
					}
				}
				&.style-b {
					background-color: #181820;
					
					.subsection-head {
						.subsection-summary {
							font-weight: inherit;
							.em {
								@include font((weight: bold)...);
							}
						}
					}
					.subsection-body {
						max-width: 100%;
						width: 100%;
						padding: 0;	
					}
				}
				&.style-c {
					
					.display-board {
						.board-list {
							width: 100%;
								
							&.benefit {
								&::after {
									display: none;
								}
							}
						}
					}
	
					.info-board {
						padding: 24rem;
						.board-summary {
							.em {
								color: color(base, 030)
							}
						}
						.board-body {
							&[class*=attr-]::after {
								position:unset;
								margin: 0 auto;
							}
						}
						.board-util {
							.button-area {
								width: 100%;
								flex-wrap: wrap;
								.btn {
									flex: auto;
								}
							}
						}
					}
				}
				&.style-d {
					.subsection-body {
						width: 100%;

						.data-display {
							.data-list {
								&.style-e {
									flex-wrap: wrap;
								}
							}
						}
	
						.post-display {
							.post-item {
								padding:24rem;
							}
						}
					}			
					
				}
				&.attr-bgpoint::before {
					display: none;
					content:'';
					background-image: url(../images/@temp/img_bggradient01.png);
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translatex(-50%);
					width: 100%;
					height: 200rem;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}		
				&.style-e {
					background-color: color(accent, 050);
					background-image: url(../images/@temp/img_bggradient01.png);
					background-repeat: no-repeat;
					background-size: cover;
					background-position: top right;
	
					.subsection-body {
						padding: 0;
					}
				}	
		
				&.style-c {
					.process-data {
						.data-list {
							flex-wrap: wrap;
						}
					}
				}
			}
		}
	}
}
