@import '_variables';

// button reset 임시
@mixin button-reset {
	overflow: visible;
	margin: 0;
	padding: 0;
	cursor: pointer;
	border: hidden;
	background-color: transparent;
	appearance: none;
	@include font((family: default)...);
}

@mixin most-outer-selector($new-class) {
	$current-selector: &;
	$new-selector: ();

	@each $item in $current-selector {
		$first-item: nth($item, 1);

		$appended-item: $first-item + $new-class;

		$new-item: set-nth($item, 1, $appended-item);
		$new-selector: append($new-item, $new-selector);
	}

	@at-root #{$new-selector} {
		@content;
	}
}

@mixin root-selector($new-class) {
	$root-selector: html;
	$current-selector: &;
	$new-selector: ();

	@each $item in $current-selector {
		$first-item: nth($item, 1);

		$appended-item: $root-selector + $new-class + ' ' + $first-item;

		$new-item: set-nth($item, 1, $appended-item);
		$new-selector: append($new-item, $new-selector);
	}

	@at-root #{$new-selector} {
		@content;
	}
}

// 빈 오브젝트
@mixin white-space {
	background-color: rgba(255,0,0,0.125);
}

// A/S
@mixin white-space {
	background-color: rgba(255,0,0,0.125);
}

@mixin material-icons($style: 'Material Icons', $type: 0, $weight: 400, $size: 24rem, $color: &) {
	font-family: $style;
	font-weight: normal;
	font-style: normal;
	font-size:  $size;
	line-height: 1;

	@if $color != & {
		color: $color;
	}

	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	text-decoration: none;
	// Support for all WebKit browsers.
	-webkit-font-smoothing: antialiased;
	// Support for Safari and Chrome.
	text-rendering: optimizeLegibility;
	// Support for Firefox.
	-moz-osx-font-smoothing: grayscale;
	// Support for IE.
	font-feature-settings: 'liga';
}

@mixin material-symbols-outlined($style: 'Material Symbols Outlined', $type: 0, $weight: 400, $size: 24rem, $color: #000) {
	font-family: $style;
	font-weight: normal;
	font-style: normal;
	font-size: $size;
	line-height: 1;
	color: $color;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;

	font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

@mixin font-awesome {
	display: inline-block;
	vertical-align: top;
	width: 1.5em;
	height: 1.5em;
	line-height: 1.5;
	font-size: inherit;
	// font-family: FontAwesome;
    font-family: "Font Awesome 5 Pro";
	font-weight: 900;
	text-align: center;
	
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin clear {
	content: '';
	display: block;
	clear: both;
}

@mixin hidden {
	display: block;
	position: absolute;
	overflow: hidden;
	visibility: hidden;
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	line-height: 1px;
	font-size: 1px;
	border: 0;
	clip: rect(0 0 0 0);
	clip: rect(0,0,0,0);
	pointer-events: none;
}

@mixin image-replace {
	animation-name: text-fadein;
    animation-duration: 6s;
}

@keyframes text-fadein {
	0% {opacity: 0;}
	90% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes motion {
	0% {margin-top: 0px;}
	100% {margin-top: 10px;}
}

@mixin align-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// @inlcude ellipsis(1);
// @inlcude ellipsis(3);
@mixin ellipsis($line-clamp) {
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $line-clamp;
	max-width: 100%;
	text-overflow: ellipsis;
	max-height: 1.5em * $line-clamp;
	word-wrap: break-word;
	overflow: hidden;
}


// @mixin toggle-hide($duration) {
// 	visibility: hidden;
// 	opacity: 0;
// 	z-index: -1;
// 	transition: visibility $duration ease, opacity $duration ease, z-index $duration ease;
// }
// @mixin toggle-show {
// 	visibility: visible;
// 	opacity: 1;
// }


@mixin container {
	left: 50%;
	transform: translateX(-50%);
	width: 100vw;
	min-width: calc(100% + 128rem);
	// position: absolute;
	// @if ($min-width) {
	// 	min-width: $min-width;
	// }
}

@mixin display-column($column: 1, $column-gap: 2rem, $row-gap: $column-gap) {
	@if $column == 1 {
		width: 100%;
		&:nth-child(n+1) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 2 {
		width: calc((100% - #{$column-gap}) / 2);
		&:nth-child(2n) {
			margin-left: $column-gap;
		}
		&:nth-child(n+3) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 3 {
		width: calc((100% - (#{$column-gap} * 2)) / 3);
		&:not(:nth-child(3n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+4) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 4 {
		width:calc((100% - (#{$column-gap} * 3)) / 4);
		&:not(:nth-child(4n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+5) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 5 {
		width: calc((100% - (#{$column-gap} * 4)) / 5);
		&:not(:nth-child(5n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+6) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 6 {
		width: calc((100% - (#{$column-gap} * 5)) / 6);
		&:not(:nth-child(6n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+7) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 7 {
		width: calc((100% - (#{$column-gap} * 6)) / 7);
		&:not(:nth-child(7n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+8) {
			margin-top: $row-gap;
		}
	}
	@else if $column == 8 {
		width: calc((100% - (#{$column-gap} * 7)) / 8);
		&:not(:nth-child(8n+1)) {
			margin-left: $column-gap;
		}
		&:nth-child(n+9) {
			margin-top: $row-gap;
		}
	}
}

// overflow scroll
@mixin overflow-scroll-custom($theme: dark, $outline: medium, $scroll: &) {
	-webkit-overflow-scrolling: touch;

	// root-dawin을 위한 reset
	&::-webkit-scrollbar {
		display: initial;
	}
	
	&::-webkit-scrollbar-thumb {
		border-width: 4rem;
		border-style: solid;
		border-color: transparent;
		background-clip: content-box;

		@if $theme == dark {
			background-color: rgba(0,0,0,0.2);
			&:hover	{background-color: rgba(0,0,0,0.4)}
			&:active{background-color: rgba(0,0,0,0.6)}
		}
		@if $theme == light {
			background-color: rgba(255,255,255,0.4);
			&:hover	{background-color: rgba(255,255,255,0.6)}
			&:active{background-color: rgba(255,255,255,0.8)}
		}
	}

	@if $outline == large {
		&::-webkit-scrollbar { 
			width: 16rem;
			height: 16rem;
		}
		
		&::-webkit-scrollbar-thumb {
			border-radius: 8rem;
		}
	}

	@if $outline == medium {
		&::-webkit-scrollbar {
			width: 12rem;
			height: 12rem;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 6rem;
		}
	}

	@if $scroll == & {
		overflow: auto;
		overflow: overlay;
	}

	@if $scroll == x {
		overflow: hidden;
		overflow-x: auto;
		overflow-x: overlay;
	}

	@if $scroll == y {
		overflow: hidden;
		overflow-y: auto;
		overflow-y: overlay;
	}
}

// overflow scroll
@mixin overflow-scroll-system($theme: dark, $outline: medium, $scroll: &) {

	// root-dawin을 위한 reset
	// &::-webkit-scrollbar {
	// 	display: initial;
	// }

	@if $scroll == & {
		overflow: auto;
	}
	
	@if $scroll == x {
		overflow: hidden;
		overflow-x: auto;
	}
	
	@if $scroll == y {
		overflow: hidden;
		overflow-y: auto;
	}
}

// overflow scroll
@mixin overflow-scroll($mode: default, $theme: dark, $outline: medium, $scroll: &) {
	// &::-webkit-scrollbar {}
	// &::-webkit-scrollbar-button {}
	// &::-webkit-scrollbar-button:start {}
	// &::-webkit-scrollbar-button:end {}
	// &::-webkit-scrollbar-button:vertical:increment {}
	// &::-webkit-scrollbar-button:vertical:decrement {}
	// &::-webkit-scrollbar-track {}
	// &::-webkit-scrollbar-track-piece {}
	// &::-webkit-scrollbar-corner {}
	// &::-webkit-resizer {}
	
	// root-dawin을 위한 reset
	// &::-webkit-scrollbar {
	// 	display: block;
	// }

	// @if $mode == default {

	// 	// mobile
	// 	@include most-outer-selector('.mobile') {
	// 		@if $scroll == & {
	// 			overflow: auto;
	// 		}
			
	// 		@if $scroll == x {
	// 			overflow: hidden;
	// 			overflow-x: auto;
	// 		}
			
	// 		@if $scroll == y {
	// 			overflow: hidden;
	// 			overflow-y: auto;
	// 		}
	// 	}
		
	// 	// laptop
	// 	@include most-outer-selector('.laptop') {
	// 		-webkit-overflow-scrolling: touch;

	// 		.content-popup {
	// 			border: 20rem solid red;
	// 		}

	
	// 		&::-webkit-scrollbar-thumb {
	// 			border-width: 4rem;
	// 			border-style: solid;
	// 			border-color: transparent;
	// 			background-clip: content-box;
	
	// 			@if $theme == dark {
	// 				background-color: rgba(0,0,0,0.2);
	// 				&:hover	{background-color: rgba(0,0,0,0.4)}
	// 				&:active{background-color: rgba(0,0,0,0.6)}
	// 			}
	// 			@if $theme == light {
	// 				background-color: rgba(255,255,255,0.4);
	// 				&:hover	{background-color: rgba(255,255,255,0.6)}
	// 				&:active{background-color: rgba(255,255,255,0.8)}
	// 			}
	// 		}
	
	// 		@if $outline == large {
	// 			&::-webkit-scrollbar { 
	// 				width: 16rem;
	// 				height: 16rem;
	// 			}
				
	// 			&::-webkit-scrollbar-thumb {
	// 				border-radius: 8rem;
	// 			}
	// 		}
	
	// 		@if $outline == medium {
	// 			&::-webkit-scrollbar {
	// 				width: 12rem;
	// 				height: 12rem;
	// 			}
	
	// 			&::-webkit-scrollbar-thumb {
	// 				border-radius: 6rem;
	// 			}
	// 		}
	
	// 		@if $scroll == & {
	// 			overflow: auto;
	// 			// overflow: overlay;
	// 		}
	
	// 		@if $scroll == x {
	// 			overflow: hidden;
	// 			overflow-x: auto;
	// 			// overflow-x: overlay;
	// 		}
	
	// 		@if $scroll == y {
	// 			overflow: hidden;
	// 			overflow-y: auto;
	// 			// overflow-y: overlay;
	// 		}
	// 	}
	// }
}

@mixin tooltipArea($area) {
	@if $area == 'top' {
		top: 0;
		padding-bottom: 1rem;
		.tooltip-area {
			&::before {
				content: '';
				position: absolute;
				top: calc(100% - .0625rem);
				left: calc(50% - (.375rem + .0625rem));
				border-top: calc(.5rem + .0625rem) solid #d2d1d0;
				border-right: calc(.375rem + .0625rem) solid transparent;
				border-bottom: 0 none;
				border-left: calc(.375rem + .0625rem) solid transparent;
			}
		
			&::after {
				content: '';
				position: absolute;
				top: calc(100% - .0625rem);
				left: calc(50% - .375rem);
				border-top: .5rem solid #fff;
				border-right: .375rem solid transparent;
				border-bottom: 0 none;
				border-left: .375rem solid transparent;
			}
		}
	}

	@if $area == 'right' {
		right: -100%;
		.tooltip-area {
			.tooltip-area {
				&::before {
					content: '';
					position: absolute;
					// bottom: calc(100% - 0.0625rem);
					// left: calc(50% - (0.375rem + 0.0625rem));
					border-top: 0 none;
					border-right: calc(0.375rem + 0.0625rem) solid transparent;
					border-bottom: calc(0.5rem + 0.0625rem) solid color(base, 060);
					border-left: calc(0.375rem + 0.0625rem) solid transparent;
				}
				
				&::after {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - 0.375rem);
					border-top: 0 none;
					border-right: 0.375rem solid transparent;
					border-bottom: 0.5rem solid #fff;
					border-left: 0.375rem solid transparent;
				}
			}
		}
	}

	@if $area == 'bottom' {
		bottom: -100%;
		.tooltip-area {
			.tooltip-area {
				&::before {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - (0.375rem + 0.0625rem));
					border-top: 0 none;
					border-right: calc(0.375rem + 0.0625rem) solid transparent;
					border-bottom: calc(0.5rem + 0.0625rem) solid color(base, 060);
					border-left: calc(0.375rem + 0.0625rem) solid transparent;
				}
				
				&::after {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - 0.375rem);
					border-top: 0 none;
					border-right: 0.375rem solid transparent;
					border-bottom: 0.5rem solid #fff;
					border-left: 0.375rem solid transparent;
				}
			}
		}
	}

	@if $area == 'left' {
		left: -100%;
		.tooltip-area {
			.tooltip-area {
				&::before {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - (0.375rem + 0.0625rem));
					border-top: 0 none;
					border-right: calc(0.375rem + 0.0625rem) solid transparent;
					border-bottom: calc(0.5rem + 0.0625rem) solid color(base, 060);
					border-left: calc(0.375rem + 0.0625rem) solid transparent;
				}
				
				&::after {
					content: '';
					position: absolute;
					bottom: calc(100% - 0.0625rem);
					left: calc(50% - 0.375rem);
					border-top: 0 none;
					border-right: 0.375rem solid transparent;
					border-bottom: 0.5rem solid #fff;
					border-left: 0.375rem solid transparent;
				}
			}
		}
	}
}



@mixin front-dawin-footer {
	#footer {
		position: relative;
		flex:1;
		-ms-flex:1 0 auto;
		-webkit-flex:1;

		.btn_help {
			position: absolute;
			right: 40px;
			top: 95px;
		}
		
		.btn_help a {
			display: inline-block;
			background: #ffa800;
			color: #fff;
			width: 60px;
			height: 60px;
			text-align: center;
			padding-top: 20px;
			box-sizing: border-box;
		}
		
		.footer_link {
			min-height: 50px;
			border: 1px solid #d9d9d9;
			box-sizing: border-box;
			text-align: center;
		}
		
		.footer_link ul {
			display: inline-block;
			margin: 0;
			padding: 0;
			list-style: none;
		}
		
		.footer_link ul li {
			display: inline-block;
		}
		
		.footer_link ul li a {
			display: inline-block;
			line-height: 49px;
			font-size: 13px;
			color: #333;
			text-decoration: none;
		}
		
		.footer_link ul li:before {
			display: inline-block;
			width: 1px;
			height: 11px;
			margin: 0 30px;
			background: #999;
			opacity: 0.9;
			content: '';
			vertical-align: -1px;
		}
		
		.footer_link ul li:first-child:before {
			display: none;
		}

		.footer_link ul li a.btn-xsmall {
			height: auto;
			width: auto;
			padding: 5px 6px 3px;
			line-height:1;
			font-size:12px;
			margin-left:8px;
			display:inline-block
		}
		
		.footer_link ul li a.btn-xsmall:before {
			display:inline-block;
			content:'';
			width:14px;
			height:14px;
			background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eico_save%3C/title%3E%3Cg id='메인' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='main_map(x)' transform='translate(-148.000000, -383.000000)' fill='%23222222'%3E%3Cg id='ico_save' transform='translate(159.000000, 394.000000) scale(1, -1) rotate(-90.000000) translate(-159.000000, -394.000000) translate(148.000000, 383.000000)'%3E%3Cpath d='M10.9555788,6.57538403 L14.6125,10.25 L14.6125,10.25 L6.25,10.25 C5.83578644,10.25 5.5,10.5857864 5.5,11 C5.5,11.4142136 5.83578644,11.75 6.25,11.75 L14.6125,11.75 L14.6125,11.75 L10.9635239,15.3943985 C10.6679823,15.6895694 10.6676818,16.1684364 10.9628526,16.463978 C10.9631515,16.4642772 10.9634506,16.4645762 10.96375,16.464875 C11.2600037,16.7605072 11.7397446,16.7602554 12.0356878,16.4643122 L17.5,11 L17.5,11 L12.029358,5.52935796 C11.7358861,5.23588609 11.2610428,5.23277443 10.96375,5.522375 C10.6707372,5.80780638 10.6645912,6.27672806 10.9500226,6.56974088 C10.9518646,6.57163182 10.9537167,6.5735129 10.9555788,6.57538403 Z M11,21.5 C5.20101013,21.5 0.5,16.7989899 0.5,11 C0.5,5.20101013 5.20101013,0.5 11,0.5 C16.7989899,0.5 21.5,5.20101013 21.5,11 C21.4934695,16.7962827 16.7962827,21.4934695 11,21.5 Z M11,2 C6.02943725,2 2,6.02943725 2,11 C2,15.9705627 6.02943725,20 11,20 C15.9705627,20 20,15.9705627 20,11 C19.9943376,6.03178457 15.9682154,2.00566235 11,2 Z' id='Fill'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
			background-size:contain;
			vertical-align: middle;
			margin-right:4px;
			background-position:center;
			margin-top:-2px
		}

		.footer_link ul li a.btn-xsmall:hover {
			transition-duration: unset;
			background-color: #fff;
			color:#222;
		}
		
		@media screen and (max-width: 767px) {
			.footer_link ul li a {
				display:inline-block;
			}
			.footer_link ul li a.btn-xsmall {
				background: none;
				border:1px solid #fff;
				color:#fff
			}
			.footer_link ul li a.btn-xsmall:before {
				background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eico_save_wh%3C/title%3E%3Cg id='메인' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='main_map(x)' transform='translate(-177.000000, -383.000000)' fill='%23ffffff'%3E%3Cg id='ico_save_wh' transform='translate(188.000000, 394.000000) scale(1, -1) rotate(-90.000000) translate(-188.000000, -394.000000) translate(177.000000, 383.000000)'%3E%3Cpath d='M10.9555788,6.57538403 L14.6125,10.25 L14.6125,10.25 L6.25,10.25 C5.83578644,10.25 5.5,10.5857864 5.5,11 C5.5,11.4142136 5.83578644,11.75 6.25,11.75 L14.6125,11.75 L14.6125,11.75 L10.9635239,15.3943985 C10.6679823,15.6895694 10.6676818,16.1684364 10.9628526,16.463978 C10.9631515,16.4642772 10.9634506,16.4645762 10.96375,16.464875 C11.2600037,16.7605072 11.7397446,16.7602554 12.0356878,16.4643122 L17.5,11 L17.5,11 L12.029358,5.52935796 C11.7358861,5.23588609 11.2610428,5.23277443 10.96375,5.522375 C10.6707372,5.80780638 10.6645912,6.27672806 10.9500226,6.56974088 C10.9518646,6.57163182 10.9537167,6.5735129 10.9555788,6.57538403 Z M11,21.5 C5.20101013,21.5 0.5,16.7989899 0.5,11 C0.5,5.20101013 5.20101013,0.5 11,0.5 C16.7989899,0.5 21.5,5.20101013 21.5,11 C21.4934695,16.7962827 16.7962827,21.4934695 11,21.5 Z M11,2 C6.02943725,2 2,6.02943725 2,11 C2,15.9705627 6.02943725,20 11,20 C15.9705627,20 20,15.9705627 20,11 C19.9943376,6.03178457 15.9682154,2.00566235 11,2 Z' id='Fill'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
			}
			.footer_link ul li a.btn-xsmall:hover {
				background:none;
			}
		}
		
		.footer_info {
			padding: 35px 0 50px;
			text-align: center;
			max-width: 1200px;
			margin: 0 auto;
		}
		
		.footer_info .f_info {
			display: block;
			text-align: center;
		}
		
		.cs_info {
			display:inline-block;
			border: 1px solid #e1e1e1;
			padding: 10px 30px;
			box-sizing: border-box;
			margin: 30px 0 0 0;
		}
		
		.cs_info span {
			display: inline-block;
			padding-left: 25px;
			background: url(../../assets_prev/web/images/main/ico_cs.png) no-repeat 0 0;
			vertical-align: middle;
		}
		
		.cs_info strong {
			display: inline-block;
			font-size: 23px;
			margin-left: 10px;
			vertical-align: middle;
		}

		.f_info {
			margin: 0;
		}

		.f_info span {
			font-size: 12px;
			line-height: 2;
			display: inline-block;
		}
		
		.f_info span:before {
			display: inline-block;
			width: 1px;
			height: 8px;
			margin: 0 10px;
			background: #999;
			opacity: 0.9;
			content: '';
			vertical-align: -1px;
		}
		
		.f_info span:first-child:before {
			display: none;
		}
		
		.p_txt01 {
			font-size: 32px;
			text-align: center;
			line-height: 1.8;
		}
		
		.p_txt02 {
			font-size: 20px;
			text-align: center;
			line-height: 1.8;
		}
		
		@media screen and (max-width: 870px) {
			.btn_help {
				bottom: -30px;
				left: 50%;
				transform: translatex(-50%);
				top: inherit;
				right: inherit;
			}
		}
	}
}


@mixin grade($size: 24rem) {
	display: flex;
	justify-content: center;
	align-items: center;

	.graph {
		display: block;
		width: calc($size * 5);
		height: $size;
		background-image: icon(star, color(normal, 040));

		&::before {
			content: '';
			display: block;
			width: var(--width);
			height: 100%;
			background-image: icon(star, #FFCE21);
		}
	}
}

@mixin gradeAV($size: 24rem) {
	display: flex;
	justify-content: center;
	align-items: center;

	.graph {
		display: block;
		width: $size;
		height: $size;
		background-image: icon(star, color(normal, 040));

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background-image: icon(star, #FFCE21);
		}
	}
}

@mixin gradeStar($size: 32rem) {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.form-elem {
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		width: $size;
		height: $size;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: icon(star, color(normal, 040));
		}
	}
}