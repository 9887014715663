/**
 * @type module
 * @name info-board
 * @modle a
 */
 .info-board.module-a {
	.board-wrap {
		.board-head {
			display: flex;
			flex-direction: column;
			row-gap: 8rem;
			
			.board-subject {
				margin: 0;
				color: color(base, 100);
				@include font((outline: body, size: small, weight: bold)...);

				.board-name {
					display: block;
					
					.wbr {
						display: block;
					}
				}
			}

			.board-summary {
				margin: 0;
				color: color(base, 060);
				@include font((outline: body, size: small-1x)...);

				.wbr {
					display: block;
				}
			}

			~.board-body {
				margin-top: 16rem;
			}
		}

		.board-body {
			.para {
				margin: 0;
				color: color(base, 080);
				@include font((outline: body, size: small-2x)...);

				+.para {
					margin-top: 16rem;
				}

				.wbr {
					display: inline-block;
					vertical-align: top;
				}
			}
		}

		.board-util {
			margin-top: 24rem;
			width: 100%;
		}
	}

	&[class*="attr-"] {
		.board-wrap {
			&::before {
				content: '';
				display: block;
				width: 120rem;
				height: 120rem;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
			}
		}
	}

	&.attr-done {
		.board-wrap::before {
			// background-image: url(./../images/svg/infoboard-done.svg);
		}
	}

	&.type-c { 
		display: flex;
		justify-content: center;

		.board-wrap {
			display: flex;
			flex-wrap: wrap;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}
}

/**
 * @type module
 * @name info-board
 * @modle b
 */
 .info-board.module-b {
	.board-wrap {
		.board-head {
			display: flex;
			flex-direction: column;
			row-gap: 8rem;
			
			.board-subject {
				margin: 0;
				color: color(base, 100);
				@include font((outline: head, size: small, weight: bold)...);
			}

			.board-summary {
				margin: 0;
				color: color(base, 060);
				@include font((outline: body, size: small-1x)...);

				.wbr {
					display: block;
				}
			}

			~.board-body {
				margin-top: 16rem;
			}
		}

		.board-body {
			.para {
				margin: 0;
				color: color(base, 080);
				@include font((outline: body, size: small-2x)...);

				+.para {
					margin-top: 16rem;
				}
			}
		}

		.board-util {
			margin-top: 28rem;
			width: 100%;

			// laptop
			@include respond-below(laptop) {
				margin-top: 48rem;
			}

			.button-area {
				// mobile
				@include respond-below(laptop) {
					.btn {
						flex-grow: 1;
					}
				}
			}
		}
	}

	&[class*="attr-"] {
		.board-wrap {
			&::before {
				content: '';
				display: block;
				width: 120rem;
				height: 120rem;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
			}
		}
	}

	&.attr-done {
		.board-wrap::before {
			// background-image: url(./../images/svg/infoboard-done.svg);
		}
	}

	&.type-c { 
		display: flex;
		justify-content: center;

		.board-wrap {
			display: flex;
			flex-wrap: wrap;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}
}

/**
 * @type module
 * @name info-board
 * @modle c
 */
 .info-board.module-c {
	.board-wrap {
		.board-head {
			display: flex;
			flex-direction: column;
			row-gap: 8rem;
			
			.board-subject {
				margin: 0;
				color: color(base, 100);
				@include font((outline: body, size: small, weight: bold)...);

				.board-name {
					display: block;
					
					.wbr {
						display: block;
					}
				}
			}

			.board-summary {
				margin: 0;
				color: color(base, 060);
				@include font((outline: body, size: small-1x)...);

				.wbr {
					display: block;
				}
			}

			~.board-body {
				margin-top: 16rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}

		.board-body {
			.para {
				margin: 0;
				color: color(base, 080);
				@include font((outline: body, size: small-2x)...);

				+.para {
					margin-top: 4rem;
				}

				.wbr {
					display: inline-block;
					vertical-align: top;
				}
			}
		}

		.board-util {
			margin-top: 24rem;
			width: 100%;
		}
	}

	&[class*="attr-"] {
		.board-wrap {
			&::before {
				content: '';
				display: block;
				width: 120rem;
				height: 120rem;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
			}
		}
	}

	&.attr-done {
		.board-wrap::before {
			// background-image: url(./../images/svg/infoboard-done.svg);
		}
	}

	&.type-c { 
		display: flex;
		justify-content: center;

		.board-wrap {
			display: flex;
			flex-wrap: wrap;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			white-space: pre-wrap;
			word-break: break-all;
		}
	}

	&.type-a {
		display: flex;
		justify-content: flex-start;

		.board-wrap {
			display: flex;
			flex-wrap: wrap;
			flex-flow: column;
			justify-content: flex-start;
			text-align: left;
			white-space: pre-wrap;
			word-break: break-all;
		}
	}
}

/**
 * @type module
 * @name guide-board
 * @modle a
 */
 .guide-board.module-a {
	.board-wrap {
		.board-head {
			.board-subject {
				margin: 0;

				.board-name {
					display: block;
					color: color(normal, 020);
					@include font((outline: body, size: small-2x, weight: bold)...);
				}

				.mark {
					vertical-align: top;
				}
			}

			.board-summary {
				margin: 0;
				color: color(normal, 030);
				@include font((outline: body, size: small-2x)...);

				.wbr {
					display: block;
				}
			}

			~.board-body {
				margin-top: 8rem;
			}
		}

		.board-body {

		}
	}

	&[class*="attr-"] {
		.board-wrap {
			.board-head {
				.board-subject {
					.board-name {
						display: flex;
						column-gap: 4rem;

						&::before {
							content: '';
							flex-shrink: 0;
							display: block;
							width: 1.5em;
							height: 1.5em;
							background-repeat: no-repeat;
							background-position: center;
							background-size: 100%;
						}
					}
				}
			}
		}
	}

	&.attr-info {
		.board-wrap {
			.board-head {
				.board-subject {
					.board-name::before {
						background-image: icon(info-line-small, color(normal, 020));
					}
				}
			}
		}
	}

	&.style-b {
		.board-wrap {
			.board-head {
				.board-subject {
					margin: 0;
	
					.board-name {
						display: flex;
						@include font((outline: body, size: small, weight: bold)...);
						color: color(normal, 010);
					}
	
					.mark {
						vertical-align: top;
					}
				}
			}
		}
	}

	&.attr-comment {
		.board-wrap {
			.board-head {
				.board-subject {
					.board-name::before {
						display: inline-block;
						vertical-align: middle;
						margin-right: 4rem;
						background-image: icon(verified, color(accent, 010));
					}
				}
			}
		}
	}
}




.info-board.no-data {
	margin: 48rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 24rem;

	&[class*="attr-"] {
		&::before {
			content: '';
			display: block;
			width: 72rem;
			height: 72rem;
			border-radius: 50%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 48rem;
			background-color: color(base, 010);
		}
	}

	&.attr-news {
		&::before {
			background-image: icon(data-news, color(base, 000));
		}
	}

	&.attr-posts {
		&::before {
			background-image: icon(data-posts, color(base, 000));
		}
	}

	&.attr-comment {
		&::before {
			background-image: icon(data-comment, color(base, 000));
		}
	}

	&.attr-comment-delete {
		&::before {
			background-image: icon(data-comment-delete, color(base, 000));
		}
	}

	&.attr-estate {
		&::before {
			background-image: icon(data-estate, color(base, 000));
		}
		.lottie {
			width:20rem;
			height:20rem;
		}
	}

	&.attr-complete {
		&::before {
			display:none;
		}
		.lottie {
			width:180rem;
			height:180rem;
		}		
	}

	&.attr-broker {
		&::before {
			background-image: icon(data-broker, color(base, 000));
		}
	}

	&.attr-chat {
		&::before {
			background-image: icon(data-chat, color(base, 000));
		}
	}

	&.attr-area {
		&::before {
			background-image: icon(data-area, color(base, 000));
		}
	}

	.board-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		row-gap: 24rem;

		.board-head {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			row-gap: 8rem;

			.board-subject {
				margin: 0;
				@include font((outline: body, size: small-1x)...);
				color: color(normal, 010);

				.wbr {
					display: block;
				}
			}

			.board-summary {
				margin: 0;
				@include font((outline: body, size: small-2x)...);
				color: color(normal, 030);

				.wbr {
					display: block;
				}
			}
		}
	}
}

/**
 * @type module
 * @name display-board
 * @modle a
 */
 .display-board.module-a {
	.board-wrap {
		.board-head {
			display: flex;
			flex-direction: column;
			row-gap: 24rem;

			.board-caption {
				color: color(accent, 010);
				@include font((outline: head, size: small-1x)...);
			}
			
			.board-subject {
				margin: 0;
				color: color(base, 000);
				@include font((outline: body, size: large-1x, weight: bold)...);

				.wbr {
					display: block;
				}

				.board-name {
					display: block;
					
					.wbr {
						display: block;
					}
				}
			}

			.board-summary {
				margin: 0;
				color: color(base, 010);
				@include font((outline: body, size: small-1x)...);

				.wbr {
					display: block;
				}
			}

			~.board-body {
				margin-top: 56rem;
			}
		}

		.board-body {
			.para {
				margin: 0;
				color: color(base, 010);
				@include font((outline: body, size: small-2x)...);

				+.para {
					margin-top: 16rem;
				}

				.wbr {
					display: inline-block;
					vertical-align: top;
				}
			}
		}

		.board-util {
			margin-top: 24rem;
			width: 100%;
		}
	}

	&[class*="attr-"] {
		.board-wrap {
			&::before {
				content: '';
				display: block;
				width: 120rem;
				height: 120rem;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
			}
		}
	}

	&.attr-done {
		.board-wrap::before {
			// background-image: url(./../images/svg/infoboard-done.svg);
		}
	}
	&.type-a {
		.board-wrap {
			display: flex;
			flex-direction: row;
			gap:96rem;
			justify-content: center;
			align-items: center;
			text-align: left;
		}
	}

	&.type-c { 
		display: flex;
		justify-content: center;

		.board-wrap {
			display: flex;
			flex-wrap: wrap;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
		.board-list {
			display: flex;
			flex-direction: column;
			gap: 24rem;
			flex: 100%;

			&.benefit {
				&::after {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top:0;
				left:-40rem;
				background-image: url(../images/@temp/img_decoration.png);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;	
				z-index: 0;
				}			
			}
		}
	}
}

.display-board.style-b {
	.board-wrap {
		align-items: center;
		overflow: hidden;
		text-decoration: none;
		background-repeat: no-repeat;
		background-position: center right;
		background-size: auto 100%;

		.board-inform {
			display: block;

			.board-head {
				display: block;
				.board-title {
					display: block;
					.board-name {
						display: block;
						font-weight: 700;
						.wbr {
							display: inline-block;
							vertical-align: top;
						}
					}
				}
				.board-intro {
					display: block;
					.board-summary {
						display: block;
						.wbr {
							display: block;
						}
					}
				}
			}
			.board-data {
				display: block;
				.data-list {
					display: block;
					.data-item {
						display: block;
						opacity: 0.5;
						.head {
							@include hidden;
						}
					}
				}
			}
		}
	}

	&.large {
		.board-wrap {
			padding: 0 18rem 0 5rem;
			height: 24rem;
			border-radius: 0.5rem;

			.board-inform {
				.board-head {
					display: block;
					.board-title {
						display: block;
						.board-name {
							display: block;
							@include font((outline: body, size: large-2x, weight: bold)...);
						}
					}
					.board-intro {
						margin-top: 0.5rem;
						.board-summary {
							@include font((outline: body, size: medium, weight: medium)...);
						}
					}
				}
				.board-data {
					margin-top: 1.5rem;
					.data-list {
						.data-item {
						}
					}
				}
			}
		}
	}
}

.display-board.style-c {
	.board-list {
		display: flex;
		gap: 16rem;

		.board-item {
			flex: 100;
			
			.board-inform {
				display: block;
	
				.board-head {
					display: block;

					.board-subject {
						display: block;
					}
				}
			}
		}
	}
	
}

.info-board.style-e {
	padding: 48rem;
	display: flex;
	flex-direction: column;
	border-radius: 8rem;
	background-color: color(normal, 000);
	gap: 24rem;
	justify-content: flex-start;
	position: relative;

	.board-head {
		display: flex;
		align-items: center;

		.board-subject {
			display: flex;
			align-items: center;
			gap: 8rem;
			margin: 0;
			color: color(base, 100);
			@include font((outline: body, size: large, weight: medium)...);

			.board-name {
				display: block;
					
				.wbr {
					display: block;
				}
			}
		}
	}
	.board-body {
		display: flex;
		flex-direction: column;
		gap: 4rem;

		.board-summary {
			display: block;
			@include font((outline: body, size: small, weight: bold)...);

			.wbr {
				display: block;
			}

			+.para {
				margin-top: 8rem;
			}
		}
		.para {
			margin: 0;
			color: color(base, 050);
			@include font((outline: body, size: small-2x)...);

			+.para {
				margin-top: 16rem;
			}

			.wbr {
				display: inline-block;
				vertical-align: top;
			}
		}
		&[class*=attr-]::after {
			content: '';
			display: block;
			position: absolute;
			background-repeat: no-repeat;
			background-position: right bottom;
		}
		&.attr-discount::after {
			content: '';
			right: 24rem;
			top: 56rem;
			width: 220rem;
			height: 150rem;
			background-image: url(../images/@temp/img_coupon01.png);
			background-size: contain;
		}
		&.attr-point::after {
			content: '';
			right: 24rem;
			top: 56rem;
			width: 220rem;
			height: 150rem;
			background-image: url(../images/@temp/img_coupon02.png);
			background-size: contain;
		}
		&.attr-supplies::after {
			content: '';
			right: 40rem;
			top: 120rem;
			width: 140rem;
			height: 150rem;
			background-image: url(../images/@temp/img_coupon03.png);
			background-size: contain;
			margin-top: 16rem;
		}

	}
	.board-util {
		.button-area {
			display: flex;
			gap: 8rem;
		}
	}

	
}



/**/
.subsection {
	.subsection-wrap {
		// padding: 108rem 0;
		display: flex;
		flex-direction: column;
		// row-gap: 56rem;
	}
	.subsection-head {
		display: flex;
		flex-direction: column;
		row-gap: 24rem;
		align-items: center;

		.subsection-caption {
			color: color(accent, 010);
			@include font((outline: head, size: small-1x)...);
		}
			
		.subsection-subject {
			margin: 0;
			color: color(base, 000);
			@include font((outline: body, size: large-1x, weight: bold)...);

			.wbr {
				display: block;
			}

			.board-name {
				display: block;
					
				.wbr {
					display: block;
				}
			}
		}

		.subsection-summary {
			margin: 0;
			color: color(base, 010);
			@include font((outline: body, size: small-1x)...);

			.wbr {
				display: block;
			}
		}

		~.subsection-body {
			// margin-top: 56rem;
		}
	}
	&.style-d {
		.subsection-subject {
			color: color(base, 100);
		}
		.subsection-summary {
			color: color(base, 100);
		}
	}

	.subsection-util {
		.button-area {
			justify-content: center;
		}
	}
}

.board-display {
	.info-board {
		.board-wrap {
			&::before {
				display:inline-block;
				content:'';
				width: 24rem;
				height: 24rem;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin-right: 4rem;
			}				
		}
		&.type-notice {
			.board-wrap {
				display:flex;
				flex-direction: row;
				gap:4rem;
				&::before {
					background-image: url(../images/@temp/img_typenotice.png);
				}				
			}
		}
		&.type-tip {
			.board-wrap {
				&::before {
					background-image: url(../images/@temp/img_typetip.png);
				}				
			}
		}
		&.type-launching {
			.board-wrap {
				&::before {
					background-image: url(../images/@temp/img_typelaunching.png);
				}				
			}
		}
		&.type-default {
			.board-wrap {
				&::before {
					background-image: url(../images/@temp/img_typedefault.png);
				}				
			}
		}
	}	
	&.type-c {
		.info-board {
			.board-wrap {
				display: flex;
				justify-content: center;
				align-items: center;
			}			
		}
	}				
}

.info-ico {
	flex-shrink: 1;
	display: inline-flex;
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: cover;

	.btn-text {
		@include hidden;
	}

	svg {
		width: 100%;
		height: 100%;
	}

	&.small-1x {
		width: 24rem;
		height: 24rem;
	}
	&.small-2x {
		width: 20rem;
		height: 20rem;
	}

	&.normal-02 {
		background-color: color(normal, 020);
	}

	&.attr-check {
		mask-image: icon(check, color(normal, 100));
	}
}

.pagination {
	a {
		width: unset;
		z-index: unset;
		background-size: unset;
		position: unset;
		top: unset;
		transform: unset;
	}
	&.style-a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		.page-util {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			.util-item {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				min-width: 24rem;
				height: 24rem;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				border-radius: 4rem;
				overflow: hidden;
				background-color: color(normal, 040);

				&:not(:hover) {
					background-color: color(normal, 040);
				}
				svg {
					display: block;
					width: 24rem;
					height: 24rem;
					fill: #605d5b;
				}
				+.util-item {
					margin-left: 4rem;
				}
			}
		}
		.page-list {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;	
			margin: 0 4rem;

			.page-item {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				min-width: 24rem;
				height: 24rem;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				border-radius: 4rem;
				overflow: hidden;
				padding: 0 4rem;
				@include font((outline: body, size: small-2x, weight: regular)...);
				text-decoration: none;

				&.current {
					color: #fff;
					background-color: color(accent, 010);
				}
				+ .page-item {
					margin-left: 4rem;
				}	
				&:not(.current) {
					color: color(normal, 030);
    				background-color: unset;
				}
			}
		}
	}
	&.type-c {
		-webkit-box-pack: center;
		-ms-flex-pack: center;	
		justify-content: center;
	}
}

/**
 * @type module
 * @name flag-board
 * @modle a
 */
 .flag-board.module-a {
	.board-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8rem;
		padding: 20rem;
		border-radius: 4rem;
		background-color: color(base, 005);
		
		.board-head {
			display: flex;
			flex-direction: column;
			row-gap: 8rem;
			.board-subject {
				margin: 0;
				color: color(base, 100);
				@include font((outline: body, size: small-1x, weight: bold)...);

				.board-name {
					display: block;
					
					.wbr {
						display: block;
					}
				}
			}

			.board-summary {
				margin: 0;
				color: color(base, 060);
				@include font((outline: body, size: small-1x)...);

				.wbr {
					display: block;
				}
			}

			~.board-body {
				margin-top: 16rem;
			}
		}

		.board-body {

		}

		.board-util {
			margin-top: 16rem;
			width: 100%;
			.button-area {
				.btn.module-a {
					padding: 0;
					height: unset;
				}
			}
		}
	}
	&:not([class*=type-]) {
		.board-wrap {
			.board-util {
				@include hidden;
			}
		}
	}

	&[class*="attr-"] {
		.board-wrap {
			.board-head {
				.board-subject {
					.board-name {
						display: flex;
						column-gap: 4rem;

						&::before {
							content: '';
							flex-shrink: 0;
							display: block;
							width: 1.5em;
							height: 1.5em;
							background-repeat: no-repeat;
							background-position: center;
							background-size: 100%;
						}
					}
				}
			}
		}
	}

	&.attr-info {
		.board-wrap {
			.board-head {
				.board-subject {
					.board-name::before {
						background-image: icon(info-fill-small, color(normal, 020));
					}
				}
			}
		}
	}

	&.action {
		.board-wrap {
			border-color: color(secondary, 080);
			background-color: color(secondary, 080);
			.board-head {
				.board-subject {
					color: color(base, 010);	
					.board-name::before {
						background-image: icon(info-fill-small, color(base, 010));
					}
				}
	
				.board-summary {
					color: color(base, 010);	
				}
			}
		}
	}

	&.warning {
		color: unset;
		text-align: unset;
		margin-top: unset;
		font-size: unset;
		background: unset;
		border-radius: unset;
		padding: unset;
		.board-wrap {
			border-style: solid;
			border-width: 1rem;
			border-color: color(fourth, 020);
			background-color: color(fourth, 005);
			.board-head {
				.board-subject {
					color: color(base, 080);
					.board-name::before {
						background-image: icon(info-fill-small, color(fourth, 050));
					}
				}
	
				.board-summary {
					color: color(base, 080);
				}
			}
		}
		&::before {
			content: unset;
		}
	}
	
	&.error {
		color: unset;
		text-align: unset;
		margin-top: unset;
		font-size: unset;
		background: unset;
		border-radius: unset;
		padding: unset;
		.board-wrap {
			border-style: solid;
			border-width: 1rem;
			border-color: color(primary, 020);
			border-color: color(primary, 020);
			background-color: color(primary, 005);
			.board-head {
				.board-subject {
					color: color(base, 080);
					.board-name::before {
						background-image: icon(info-fill-small, color(primary, 050));
					}
				}
	
				.board-summary {
					color: color(base, 080);
				}
			}
		}
		&::before {
			content: unset;
		}
	}
}