@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-tsreport {

	#wrap {
		display: flex;
		flex-direction: column;
		background-color: color(base, 000);
	}
	
	.page-body {
		overflow: hidden;

		#local {
			.local-util {
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
						gap: 16rem;
					}
				}
			}

			.local-body {
				@include most-outer-selector('.mobile') {
					@include overflow-scroll-system(dark, medium, y);
				}

				@include most-outer-selector('.laptop') {
					@include overflow-scroll-custom(dark, medium, y);
				}

				.section.module-a {
					.section-wrap {
						display: flex;
						flex-direction: column;
						row-gap: 16rem;
						
						.section-head {
							.section-subject {
								margin: 0;
								@include font((outline: body, size: small-1x, weight: bold)...);
								color: color(normal, 100);
				
								.section-name {
									display: block;
								}
							}
						}
					}
				
					&:not([class*=type-]) {
						.section-wrap {
							.section-head {
								@include hidden;
							}
						}
					}
				}
			}
		}
	}
	
	
	@include respond-below(tablet) {
		.page-body {
			flex:100%;
			#local {
				flex: 1 1 100%;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				
				.local-head {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
					background-color: color(base, 000);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
						}
					}
				}
			
				.local-body {
					padding: 0 20rem;
					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
	
					#content {
						margin: 40rem 0 80rem 0;
					}
				}
	
				.local-util {
					.button-display {
						.button-area {
							gap: 0;
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
		}
		#footer,
			.page-foot,
			.page-head {
				display: none;
			}
	
		#page.page-intro {
			flex: 1 1 100%;
			padding: 64rem 0 60rem 0;
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, medium, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, medium, y);
			}
	
			.page-head {
				position: fixed;
				right: 0;
				bottom: 0;
				left: 0;
			}
	
			.page-body {
				#local {
					.local-head {
						position: absolute;
						z-index: 10;
						top: 0;
						right: 0;
						left: 0;
						flex-shrink: 0;
					}
				}
			}
		}
	
		#page.page-front {
			.page-head {
				display: none;
			}
	
			.page-body {
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
					
					.local-head {
						position: relative;
						z-index: 10;
						background-color: color(base, 000);
	
						&::after {
							content: '';
							order: 3;
							display: block;
							width: 24rem;
							height: 24rem;
						}
					}
				}
	
				.local-body {
					flex: 1 1 100%;
					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
				}
			}
	
			#footer,
			.page-foot,
			.page-head {
				display: none;
			}
		}
	}
	
	
	@include respond-above(tablet) {
		#page {
			// 임시 선언
			display: block;
			margin: 56rem 0 0 0;
			
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, large, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, large, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
		}
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				padding: 40rem 0 80rem 0;
	
				.local-head {
					display: flex;
					flex-direction: column;
					align-items: center;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0 40rem;
				}
			}
		}		
		
	}
	
	
	.local-body {
		#content {	
			.content-body {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;	
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 520rem;

				.section {
					background-color: color(base, 000);
					position: relative;

					+ .section {
						padding-top: 40rem;
						padding-bottom: 40rem;

						&:before {
							content: "";
							width: 100vw;
							height: 1rem;
							background-color: #f2f2f2;
							display: block;
							position: absolute;
							top: 0;
							left: calc(50% - 50vw);
						}
					}

					&.mysellgoods-info {
						
						background: #126dff;
						padding:40rem 0;	
    					width: 100vw;
    					margin-left: calc(-50vw + 50%);				

						.section-wrap {
							width: 520rem;
							gap: 24rem;
							display: flex;
							flex-direction: column;
							margin: 0 auto;

							.goods-display.module-b {
								padding: 24rem;
								background: color(base, 000);
								border-radius: 8rem;
								// margin-top:24rem;
							}

							.info-board {
								margin-top:8rem;

								&:first-child {
									margin-top:0;
								}

								+.goods-display.module-b {
									margin-top: 24rem;
								}
							}
							
						}

						&:before {
							display:none;
						}
					}
					+ .section {
						margin-top: -40rem;
					}

					&.designated-broker {
						padding-bottom: 40rem;
						
						.goods-extra {
							margin-bottom: -12rem;
							margin-top: 8rem;

							.data-list {
								.data-item {
									display: flex;
									gap: 4rem;
									@include font((outline: body, size: small-1x, weight: regular)...);
									color: color(base, 040);

									.head {
										display:none;
									}
									.body {
										span {
											@include font((outline: body, size: small-1x, weight: regular)...);
											color: color(base, 090);
										}
									}
								}
							}
						}
						.goods-item {
							.goods-wrap {
								.goods-inform {
									.data-list {
										.data-item {
											&.brokerinfo {
												margin-top: 4rem;
											}
										}
									}
								}
							}
						}
						.data-display {
							&.linkbox-grey {
								.data-list {
									.data-item {
										.data-wrap {
											.data-body {
												.data-list {
													padding: 0;
													gap: 8rem;
													.data-item {
														display: flex;
														gap: 8rem;
														align-items: center;
														flex: 0 0 auto;
														.body {
															@include font((outline: body, size: medium, weight: bold)...);
														}
														&:last-child {
															.body {
																@include font((outline: body, size: small-1x, weight: regular)...);
															}
															&:before {
																display: inline-flex;
																content: '/';
																margin-right : 4rem;
															}
														}
													}
													&:not([class*=type-]) {
														.data-item {
															.head {
																@include hidden;
															}
															&:last-child {
																&:before {
																	@include hidden;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}

					&.advertising-status {
						.goods-display.module-b {
							&.style-b {
								.goods-list {
									.goods-item {
										&:first-child {
										padding-top: 0;
										}
										.goods-wrap {
											.goods-inform {
												.goods-head {
													.goods-subject {
														gap: 0rem;
														.goods-name {
															&.attr-broker {
																&::before {
																	content: "다윈중개사";
																	width: 69rem;
																	height: 24rem;
																	vertical-align: middle;
																	background-position: left center;
																	background-repeat: no-repeat;
																	background-size: contain;
																	margin-right: 4rem;
																	display: inline-block;
																	padding-left: 26rem;
																	font-size: 15rem;
																	line-height: 24rem;
																	color: color(primary, 070);
																	margin-top: -2rem;
																}
															}
														}
														.em {
															margin-left: 4rem;
														}
													}
												}
											}
										}
									}
								}
							} 
						}
					}
				}
			}
		}
	}
	
	.info-board.trade-status {
		background: #001231;
		border-radius: 8rem;

		.board-wrap {
			align-items: center;
			column-gap: 4rem;
			padding: 24rem;
			display: flex;

			.board-head {
				flex: 1;
				color: color(normal, 000);
			}

			&::after {
				flex-shrink: 0;
				content: '';
				display: block;
				width: 24rem;
				height: 24rem;
				background: icon(chevron-right, color(normal, 000)) no-repeat center/cover;
			}
		}
		&[class*=status-] {
			.board-wrap {
				&::before {
					content: '';
					display: block;
					margin-right: 4rem;
					width: 24rem;
					height: 24rem;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
			}
		}

		&.status-error {
			.board-wrap {
				&::before {
					background-image: url(./../images/common/icon_error.svg);
				}
			}
		}

		&.status-warning {
			.board-wrap {
				&::before {
					background-image: url(./../images/common/icon_warning.svg);
				}
			}
		}

		&.status-info {
			.board-wrap {
				&::before {
					background-image: url(./../images/common/icon_idea.svg);
				}
			}
		}
		+.goods-display.module-b {
			.goods-list {
				.goods-item {
					.goods-wrap {
						.goods-inform {
							.goods-price {
								.data-list {
									// flex-shrink: 1;
								}
							}
						}
					}
				}
			}
		}

	}


	.goods-display.module-b {
		.goods-list {
			.goods-item {
				&:first-child {
					padding-top:0;
				}
				&:last-child {
					// padding-bottom: 40rem;
				}
				.goods-data {
					>.data-list {
						.data-item {
							.body {
								flex-wrap: wrap;
							}
						}
					}
				}
				.goods-wrap {
					.goods-inform {
						.goods-price {
							.data-list {
								.data-item {
									flex-wrap: wrap;
									&.chartered {

									}
								}
							}
						}
					}
				}
			}
		}
		.goods-flatform {
			margin-bottom: 8rem;

			.data-list {
				display: flex;
				gap: 8rem;
				align-items: center;

				.data-item {
					@include font((outline: body, size: small-1x, weight: bold)...);

					.head {
						display: none;
					}

					&.posted {
						@include font((outline: body, size: small-2x, weight: bold)...);
					}
				}
			}

			&.naver {
				.data-item {
					color: #19CE60;
				}
			}

			&.kb {
				.data-item {
					color: #FFC700;
				}
			}
		}
		.goods-subject {
			.goods-name {
				&[class*=attr-] {
					&::before {
						content: '';
						display: inline-block;
						margin-right: 4rem;
						width: 24rem;
						height: 24rem;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						vertical-align: middle;
					}
				}
		
				&.attr-broker {
					// text-decoration: underline;
					
					&::before {
						background-image: url(./../images/common/icon_broker.svg);
					}
				}
				.mark {
					margin-right: 4rem;
				}
			}
		}

		&.type-d {
			.goods-head {
				.goods-subject {
					.goods-name {
						cursor: default;
					}
					span {
						@include font((outline: body, size: small-1x, weight: regular)...);
					}
				}
				.goods-util{
					margin-top: 0;
					margin-bottom: 0;
					margin-left: auto;
				}
			} 
		}

		.goods-util {
			margin-bottom: 8rem;

			.button-area {
				gap: 6rem;
			}
		}
		
	}
	.data-table {
		.data-head {
			> a {
				align-items: center;
				column-gap: 8rem;
				display: flex;

				&.data-more {
					&::after {
						flex-shrink: 0;
						content: '';
						display: block;
						width: 24rem;
						height: 24rem;
						background: icon(chevron-bottom, color(normal, 040)) no-repeat center/cover;
						margin-left: auto;
					}
				}		
				&.data-less {
					&::after {
						flex-shrink: 0;
						content: '';
						display: block;
						width: 24rem;
						height: 24rem;
						background: icon(chevron-top, color(normal, 040)) no-repeat center/cover;
						margin-left: auto;
					}
				}				
			}
		}
		td {
			.em {
				word-break: break-all;
			}
			.goods-price {
				padding: 6rem;
				.data-list {
					justify-content: flex-start;

					.data-item {
						display:flex;
						gap: 4rem;
						justify-content: flex-start;
						flex-wrap: wrap;
						
						.body {
							.em {
								margin-left: 6rem;

								// &.etc-01::before {
								// 	display:inline-block;
								// 	content:'';
								// 	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.35319 16C7.76411 16 7.40498 15.352 7.71719 14.8525L11.364 9.0176C11.6578 8.5476 12.3422 8.5476 12.636 9.0176L16.2828 14.8525C16.595 15.352 16.2359 16 15.6468 16H8.35319Z' fill='%23FF0000'/%3E%3C/svg%3E%0A");
								// 	width:24rem;
								// 	height:24rem;
								// 	vertical-align: middle;
								// 	margin-top: -2rem;
								// }
								// &.etc-02::before {
								// 	display:inline-block;
								// 	content:'';
								// 	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.35319 9C7.76411 9 7.40498 9.64796 7.71719 10.1475L11.364 15.9824C11.6578 16.4524 12.3422 16.4524 12.636 15.9824L16.2828 10.1475C16.595 9.64796 16.2359 9 15.6468 9H8.35319Z' fill='%23001DEE'/%3E%3C/svg%3E%0A");
								// 	height:24rem;
								// 	width:24rem;
								// 	vertical-align: middle;
								// 	margin-top: -2rem;
								// }
							}
						}
					}
				}				
			} 
		}
	}
	.graph-display {
		.graph-wrap {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			position: relative;
			padding-top: 72rem;
			margin-bottom: 32rem;
			margin-top: 16rem;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;

			&::before {
				content: "";
				width: 100%;
				height: 12rem;
				position: absolute;
				top: 72rem;
				right: 0;
				background-color: color(base, 005);
			}
			.graph-area {
				width: 50%;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				margin-left: auto;
				margin-right: auto;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				position: relative;
				z-index: 1;

				&::before {
					content: "";
					width: 100%;
					height: 12rem;
					display: block;
					position: relative;
					z-index: 1;
					margin-bottom: 10rem;
					border-left: 2rem solid #657280;
					border-right: 2rem solid #657280;
					background-color: #7F8B9A;
				}

				.graph-item.value {
					width: 100%;
					height: 18rem;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					position: relative;
					-webkit-box-pack: justify;
					-ms-flex-pack: justify;
					justify-content: space-between;

					.em {
						position: absolute;
						top: 0;
						line-height: 18rem;
						letter-spacing: -0.1rem;
						@include font((outline: body, size: small-2x, weight: bold)...);

						&:first-child {
							left: 0;
						}
						&:last-child {
							right: 0;
						}
					}
				}

				.graph-item.sale {
					display: table;
					position: absolute;
					top: -57rem;
					left: 50%;
					transform: translateX(-50%);
					z-index: 1;
					padding-top: 8rem;
					padding-left: 15rem;
					padding-right: 15rem;
					padding-bottom: 7rem;
					white-space: nowrap;
					border-radius: 4rem;

					&.accent-01 {
						background-color: color(accent, 010);

						&::before {
							content: "";
							display: block;
							width: 2rem;
							height: 33rem;
							position: absolute;
							bottom: -30rem;
							background-color: color(accent, 010);
							left:50%;
						}
					}
					&.accent-02 {
						background-color: color(accent, 020);

						&::before {
							content: "";
							display: block;
							width: 2rem;
							height: 33rem;
							position: absolute;
							bottom: -30rem;
							background-color: color(accent, 020);
							left:50%;
						}
					}

					&.accent-03 {
						background-color: color(accent, 030);

						&::before {
							content: "";
							display: block;
							width: 2rem;
							height: 33rem;
							position: absolute;
							bottom: -30rem;
							background-color: color(accent, 030);
							left:50%;
						}
					}

					.head {
						display: inline-block;
						vertical-align: top;
						@include font((outline: body, size: small-1x, weight: normal)...);
						color: #fff;
					}
					.body {
						display: inline-block;
						margin-left: 4rem;
						vertical-align: top;
						@include font((outline: body, size: small-1x, weight: bold)...);
						color: #fff;
					}
					&::after {
						content: "";
					}

				}
			}

		}
	}

	@include respond-below(tablet) {
		.local-body {
			#content {	
				.content-body {
					.section {	
						&.mysellgoods-info {		
							margin-top: -40rem;
							padding: 40rem 20rem;		
							box-sizing: border-box;		
	
							.section-wrap {
								width: unset; 

								.info-board.trade-status {
	
									.board-wrap {
										padding: 16rem;
									}	
								}
							}
						}	
						.goods-display.module-b {	
							margin-top: 20rem;

							&.type-d {
								.goods-head {
									flex-direction: column;
									gap: 4rem;
									align-items: flex-start;
									
									.goods-util{
										margin-left: 0;
									}
								} 
							}
						}						
					}
				}
			}
		}	
	}
}