@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-news {
	#wrap {
		display: flex;
		flex-direction: column;
	}
	
	.page-body {
		#local {
			.local-util {
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}
	
	.section.module-a {
		.section-wrap {
			display: flex;
			flex-direction: column;
			row-gap: 12rem;
			
			.section-head {
				.section-subject {
					margin: 0;
					@include font((outline: body, size: small-1x, weight: bold)...);
					color: color(normal, 100);
	
					.section-name {
						display: block;
					}
				}
			}
		}
	
		&:not([class*=type-]) {
			.section-wrap {
				.section-head {
					@include hidden;
				}
			}
		}
	}
	
	@include respond-below(tablet) {
		.page-body {
			flex: 1 1 100%;
			overflow: hidden;

			#local {
				flex: 1 1 100%;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				
				.local-head {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
	
						+.local-control {
							order: 3;
						}
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
	
							.btn.share::before {
								background-color: color(base, 000);
								background-image: icon(share, color(base, 100));
							}
						}
					}
				}
			
				.local-body {
					padding: 0 20rem;

					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, large, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, large, y);
					}
	
					#content {
						margin: 0 0 80rem 0;
					}
				}
	
				.local-util {
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
	
			&.page-intro {
				flex: 1 1 100%;
				margin: 64rem 0 60rem 0;
				@include most-outer-selector('.mobile') {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@include most-outer-selector('.laptop') {
					@include overflow-scroll-custom(dark, medium, y);
				}
		
				.page-head {
					position: absolute;
					right: 0;
					bottom: 0;
					left: 0;
				}
		
				.page-body {
					#local {
						.local-head {
							position: absolute;
							z-index: 1;
							top: 0;
							right: 0;
							left: 0;
							background-color: color(base, 005);
						}
					}
				}
			}
		
			&.page-front {
				.page-head {
					display: none;
				}
		
				.page-body {
					overflow: hidden;
					
					#local {
						flex: 1 1 100%;
						overflow: hidden;
						
						.local-head {
							position: relative;
							z-index: 10;
							background-color: color(base, 000);
						}
					}
		
					.local-body {
						flex: 1 1 100%;
						@include most-outer-selector('.mobile') {
							@include overflow-scroll-system(dark, medium, y);
						}
				
						@include most-outer-selector('.laptop') {
							@include overflow-scroll-custom(dark, medium, y);
						}
					}
				}
			}
		}
		
		#footer,
		.page-foot,
		.page-head {
			display: none;
		}
	}

	@include respond-above(tablet) {
		#page {
			margin: 56rem 0 0 0;

			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, large, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, large, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
	
		}
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				padding: 0 0 80rem 0;
	
				.local-head {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 48rem 0;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large, weight: medium)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0 40rem;
				}
			}
		}
	}
	
	
	.local-body {
		#content {	
			.content-body {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 640rem;
			}
		}
	}
	
	.posts-display.module-b {
		a.posts-name {
			text-decoration: initial;
		}	
	}
		
	.posts-display.module-b {
		a {
			text-decoration: initial;
		}
		
	
		&.style-a {
			.posts-list {
				.posts-item {
					padding: 20rem 0;
					border-bottom: 1rem solid color(base, 005);
				}
			}
	
			@include respond-below(tablet) {
				position: relative;
				left: calc(50% - 50vw);
				width: 100vw;
		
				.posts-list {	
					.posts-item {
						padding: 20rem;
					}
				}
			}
		}
	
		.data-sort {
			.btn {
				display: initial;
				width: initial;
				text-align: initial;
				white-space: initial;
				vertical-align: initial;
				cursor: initial;
				box-sizing: initial;
				margin-bottom: initial;
				padding: initial;
				font-size: initial;
				font-weight: initial;
				line-height: initial;
		
				.btn-text {
					height: initial;
					line-height: initial;
				}
			}
			
			@include respond-below(tablet) {
				position: relative;
				left: calc(50% - 50vw);
				width: 100vw;
				border-bottom: 1rem solid color(base, 010);
			}
			
			@include respond-above(tablet) {
				border: 4rem solid color(base, 005);
				border-radius: 8rem;
			}
		
			.data-date {
				display: flex;
				justify-content: center;
				column-gap: 8rem;
				padding: 16rem 0;
	
				@include respond-above(tablet) {
					padding: 20rem 0;
				}
			
				.current-date {
					display: block;
					position: relative;
	
					@include font((outline: body, size: medium, weight: medium)...);
					text-align: center;
				}
			
				.btn {
					@include button-reset;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					margin: 0;
					padding: 0;
					width: 32rem;
					height: 32rem;
					overflow: hidden;
					@include font((outline: body, size: small-3x)...);
					cursor: pointer;
	
					&.disabled {
						opacity: 0.25;
						pointer-events: none;
					}
		
					.btn-text {
						display: flex;
						justify-content: center;
						align-items: center;
		
					}
			
					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 32rem;
						height: 32rem;
						background-color: color(normal, 000);
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
					}
					
					&.prev::after {
						background-image: icon(navigate-prev, color(normal, 020));
					}
					&.next::after {
						background-image: icon(navigate-next, color(normal, 020));
					}
				}
			}
		
			.data-filter {
				padding: 12rem 20rem;
				border-top: 1rem solid color(base, 005);
		
				.submit-form {
					margin: 0;
					padding: 0;
					border: hidden;
	
					legend {
						@include hidden;
					}
		
					.form-head {
						@include hidden;
		
					}
		
					.form-body {
						.option-list {
							display: flex;
							column-gap: 4rem;
						}
					}
				}
			}
		}
	
		.posts-list {
			margin: 0;
			padding: 0;
			list-style: none;
	
			.posts-item {
				.posts-wrap {
					display: flex;
					column-gap: 16rem;
					
					.posts-figure {
						flex-shrink: 0;
						position: relative;
						z-index: 1;
						width: 100rem;
						height: 100rem;
						
						.posts-cover {
							display: block;
							overflow: hidden;
							width: 100%;
							height: 100%;
							border-radius: 8rem;
							box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);
							
							img {
								display: block;
								position: relative;
								z-index: -1;
								object-fit: cover;
								width: 100%;
								height: 100%;
							}
						}
					}
					
					.posts-inform {
						flex: 1 1 100%;
						display: flex;
						flex-direction: column;
						row-gap: 8rem;
						
						.posts-head {
							order: 2;
							.posts-subject {
								margin: 0;
		
								.posts-name {
									@include ellipsis(3);
									@include font((outline: body, size: small-1x)...);
									color: color(normal, 010);
	
									&:not(:hover) {
										text-decoration: none;
									}
	
									&:hover {
										text-decoration: underline;
									}
								}
							}
						}
	
						.posts-data {
							order: 1;
			
							.data-list {
								display: flex;
								justify-content: space-between;
								
								
								.data-item {
									.head {
										@include hidden;
									}
	
									.body {
										display: block;
										@include font((outline: body, size: small-2x)...);
									}
	
									&.type {
										.body {
											@include font((weight: bold)...);
											color: color(accent, 020);
										}
									}
	
									&.media {
										.body {
											color: color(normal, 030);
	
											a {
												color: inherit;
	
												&:not(:hover) {
													text-decoration: none;
												}
	
												&:hover {
													text-decoration: underline;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}