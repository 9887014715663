@charset "UTF-8";
/* CSS Document */

html {    
    font-size: 1px;
}
body,
html {
    height: 100%; /*position:fixed;overflow-y:scroll;*/
    -webkit-overflow-scrolling: touch;
}
body {
    font-family: Open Sans, Noto Sans KR, sans-serif;
    color: #333;
    height: 100%;
    height: -webkit-fill-available;
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: -.5px;
    overscroll-behavior: contain;
    -webkit-overflow-behavior: contain;
    background-color: transparent !important;
}
#dawinWrap {
    display: flex; /*display:-webkit-box;*/
    display: -ms-flexbox;
    flex-direction: column; /*webkit-box-orient:vertical;*/
    -ms-flex-direction: column;
    position: relative;
    height: 100vh;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
}
#wrap {
    position: relative;
    min-width: 280px;
    height: 100%;
    flex: 1;
    -webkit-flex: 1;
}
/*.container {height:100vh}*/
/** {overflow-y:scroll;-webkit-overflow-scrolling:touch;}*/
#wrap.banner {
    padding-top: 70px;
}
#wrap.banner .header {
    top: 70px;
}

:root {
    --main-color-1: #7a1d05;
    --content-color-1: #c22800;
    --content-color-2: #3c251f;
    --content-color-3: #d1d1d1;
    --content-color-4: #ffd200;
    --disabled-color-1: #e7e7e7;
    --bg-color-1: #fff;
    --bg-color-2: #e1e1e1;
    --text-color-1: #333;
    --text-color-2: #666;
    --text-color-3: #d2d2d2;
    --text-color-4: #fff;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 1.6em;
    white-space: nowrap;
}
.line_clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.hidden {
    display: none !important;
    visibility: hidden;
}
.clearfix {
    zoom: 1;
}
.clearfix:after,
.clearfix:before {
    content: '';
    display: table;
}
.clearfix:after {
    clear: both;
}
.hide {
    font-size: 0;
}
.lbl {
    color: #444;
    font-size: 13px;
}
.pst_l {
    left: 0;
}
.mgt {
    margin-top: 10px;
}
.mgt02 {
    margin-top: 15px;
}
.bolder {
    font-weight: 600;
}
.mask {
    background: #000;
    opacity: 0.6;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
}
.mask02 {
    background: #000;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
}
small.t_red {
    color: #D02E31;
    margin-top: 3px;
    margin-left: 0;
}

/* 레이어 */
.lyr {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001; /*border:1px solid #dedede;*/
    box-shadow: rgba(0,0,0,.2) 0 1px 2px 0;
    background: #fff;
    transform: translate(-50%, -50%);
    -webkit-overflow-scrolling: touch;
    width: 90%;
    min-height: 82px;
    box-sizing: border-box;
    /*border:1px solid #6c6c6c*/
}
.lyr .btn-close-small {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1001;
}
.lyr_inner {
    padding: 30px;
    box-sizing: border-box;
}
.lyr h4 {
    font-size: 22px;
    font-weight: 400;
}
.lyr_inner h2.tit {
    display: block;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 500;
}
.lyr_inner h1 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
}
.lyr_inner .cont {
    text-align: center;
    font-size: 16px;
}
.lyrpop01 .exp {
    font-size: 14px;
    text-align: left;
}
.lyr .content {
    margin-top: 10px;
}
.lyr .btn {
    width: 100%;
    height: 50px !important;
    margin-top: 0;
}
.lyrpop01 button {
    width: 100%;
    height: 100%;
    font-size: 15px;
    border: none;
}
.lyr .inpbox {
    padding-top: 10px;
}
.lyr_fav .inpbox {
    padding-top: 0 !important;
    margin-top: -5px;
}
.lyr_fav .lbl {
    font-size: 15px;
}
.lyr_fav p {
    text-align: center;
}
.lyrpop02 {
    width: 90%;
    height: calc(100% - 100px);
    box-sizing: border-box;
}
.lyrpop02 .title {
    font-size: 20px;
    text-align: center;
    padding-bottom: 10px;
}
.lyrpop02 .bkpee {
    overflow-y: scroll;
}
.lyrpop02 .lyr_inner {
    height: 100%;
    box-sizing: border-box;
}
.lyr_inner .cont.align_l {
    text-align: left;
    margin-top: 15px;
}

/* 테이블 */
.tbl_type01 {
    width: 100%;
    font-size: 14px;
    table-layout: fixed;
    vertical-align: middle;
    border-top: 1px solid #1e1e1e;
    border-bottom: 1px solid #1e1e1e;
    text-align: left;
}
.tbl_type01 tr {
    border-bottom: 1px solid #d9d9d9;
    height: 40px;
}
.tbl_type01 tr:last-child {
    border-bottom: none;
}
.tbl_type01 th {
    background: #fafafa;
}
.tbl_type01 td,
.tbl_type01 th {
    padding: 10px 5px 10px 10px;
}

/*로딩*/
.lds_wrap {
    text-align: center;
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 20000;
}
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #b62217;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

/* 베가스 로딩 안보이게 */
.vegas-timer,
.vegas-timer-running {
    display: none !important;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .m_lst_box > ul > li:last-child {
        margin-bottom: 60px;
    }
}

.svg-sch path {
    stroke: #5827d8;
    stroke-width: 2px;
    stroke-opacity: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    fill: #5827d8;
    fill-opacity: 0.20;
    pointer-events: visiblePainted;
}
.svg-sch {
    position: absolute;
    overflow: visible;
    margin: 0;
    padding: 0;
    border: 0;
    pointer-events: none;
    z-index: 0;
}

.banner .main-header {
    position: absolute;
}
.bnbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
}
/* .quickbn {display:block;width:100%;height:70px;background:url(../images/img_quickbn03.png) no-repeat;background-size:cover;background-position:50%;text-indent:-9999px} */
.bnbox .btn-right {
    top: 5px;
}

:root.root-dawin {
    .scroll {
        overflow: hidden !important;
    }
}
