@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-selling {
	#wrap {
		display: flex;
		flex-direction: column;
	}
	
	.page-body {
		#local {
			.local-util {
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}
	
	.section.module-a {
		.section-wrap {
			.section-head {
				margin: 12rem 0;

				.section-subject {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					column-gap: 8rem;
					margin: 0;
					@include font((outline: body, size: small-1x, weight: bold)...);
					color: color(normal, 100);
	
					.section-name {
						display: block;
					}
				}
			}

			.section-body {
				margin: 12rem 0;
			}

			.section-util {
				margin: 12rem 0;
			}
		}
	
		&:not([class*=type-]) {
			.section-wrap {
				.section-head {
					@include hidden;
				}
			}
		}
	}

	.subsection.module-a {
		.subsection-wrap {
			.subsection-head {
				margin: 8rem 0;
				align-items: flex-start;

				.subsection-subject {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					column-gap: 8rem;
					margin: 0;
					@include font((outline: body, size: small-2x, weight: medium)...);
					color: color(normal, 100);
	
					.subsection-name {
						display: block;
					}
				}
			}

			.subsection-body {
				.para {
					@include font((outline: body, size: small-2x)...);
					color: color(normal, 030);	
				}
			}

			.section-body {
				margin: 8rem 0;
			}

			.section-util {
				margin: 8rem 0;
			}
		}
	
		&:not([class*=type-]) {
			.subsection-wrap {
				.subsection-head {
					@include hidden;
				}
			}
		}
	}
	
	@include respond-below(tablet) {
		.page-body {
			flex: 1 1 100%;
			overflow: hidden;

			#local {
				flex: 1 1 100%;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				
				.local-head {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					column-gap: 8rem;
					position: relative;
					z-index: 1;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
						min-width: 24rem;
	
						+.local-control {
							order: 3;
						}
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
	
							.btn.share::before {
								background-color: color(base, 000);
								background-image: icon(share, color(base, 100));
							}
						}
					}
				}
			
				.local-body {
					flex: 1 1 100%;
					padding: 0 20rem;

					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, large, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, large, y);
					}
	
					#content {
						margin: 0 0 80rem 0;

						.content-body {
							>.tab-display.module-c {
								display: flex;
								left: -20rem;
								padding: 0 20rem;
								width: 100vw;
								box-sizing: border-box;
								overflow-x: scroll;

								-ms-overflow-style: none; /* IE and Edge */
								scrollbar-width: none; /* Firefox */
								&::-webkit-scrollbar {
									display: none; /* Chrome, Safari, Opera*/
								}

								.tab-list {
									flex: 1 1 100%;
								}
							}
						}
					}
				}
	
				.local-util {
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}		
		}

		
		#page {
		&.page-intro {
			flex: 1 1 100%;
			margin: 64rem 0 60rem 0;
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, medium, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, medium, y);
			}
	
			.page-head {
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
			}
	
			.page-body {
				#local {
					.local-head {
						position: absolute;
						z-index: 1;
						top: 0;
						right: 0;
						left: 0;
						// background-color: color(base, 005);
						.local-control {
							display:none;
						}
					}
				}
			}
			.page-util {
				display: none;
			}
		}
	
		&.page-front {
			.page-head {
				display: none;
			}
	
			.page-body {
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
					
					.local-head {
						position: relative;
						z-index: 10;
						background-color: color(base, 000);

						.local-control {
							// &+.local-control {
							// 	display:none;
							// 	// &::after {
							// 	// 	display:none;
							// 	// }
							// }
						}						
					}
				}
	
				.local-body {
					flex: 1 1 100%;
					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
				}
			}
		}
		}
		
		#footer,
		.page-foot{
			display: none;
		}

		// #page.page-intro {
		// 	flex: 1 1 100%;
		// 	padding: 64rem 0 60rem 0;
		// 	@include most-outer-selector('.mobile') {
		// 		@include overflow-scroll-system(dark, medium, y);
		// 	}
	
		// 	@include most-outer-selector('.laptop') {
		// 		@include overflow-scroll-custom(dark, medium, y);
		// 	}
	
		// 	.page-head {
		// 		position: fixed;
		// 		right: 0;
		// 		bottom: 0;
		// 		left: 0;
		// 	}
	
		// 	.page-body {
		// 		#local {
		// 			.local-head {
		// 				position: absolute;
		// 				z-index: 10;
		// 				top: 0;
		// 				right: 0;
		// 				left: 0;
		// 				flex-shrink: 0;
	
		// 				.local-control {
		// 					display:none;
		// 				}
		// 			}
		// 		}
		// 	}
		// 	.page-util {
		// 		display: none;
		// 	}
		// }
	
		// #page.page-front {
		// 	.page-head {
		// 		display: none;
		// 	}
	
		// 	.page-body {
		// 		overflow: hidden;
				
		// 		#local {
		// 			flex: 1 1 100%;
		// 			overflow: hidden;
					
		// 			.local-head {
		// 				position: relative;
		// 				z-index: 10;
		// 				background-color: color(base, 000);
	
		// 				&::after {
		// 					content: '';
		// 					order: 3;
		// 					display: block;
		// 					width: 24rem;
		// 					height: 24rem;
		// 				}
	
		// 				.local-control {
		// 					&+.local-control {
		// 						display:none;
		// 						&::after {
		// 							display:none;
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
	
		// 		.local-body {
		// 			flex: 1 1 100%;
		// 			@include most-outer-selector('.mobile') {
		// 				@include overflow-scroll-system(dark, medium, y);
		// 			}
			
		// 			@include most-outer-selector('.laptop') {
		// 				@include overflow-scroll-custom(dark, medium, y);
		// 			}
		// 		}
		// 	}
	
		// 	#footer,
		// 	.page-foot,
		// 	.page-head {
		// 		display: none;
		// 	}
		// }
	}

	

	@include respond-above(tablet) {
		#page {
			margin: 56rem 0 0 0;

			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, large, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, large, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
	
		}
	
		.page-body {
			#local {
				.local-head {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 48rem 0;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large, weight: medium)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0 40rem;
				}
			}
		}
	}
	
	
	.local-body {
		#content {	
			.content-body {
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 640rem;


				.goods-option {
					margin: 16rem 0;
				
					.data-list {
						display: flex;
						flex-wrap: wrap;
						gap: 8rem;
						margin: 0;
					}
				}

				.section.module-a {
					margin: 40rem 0;
				}

				.subsection.module-a {
					margin: 24rem 0;
				}

				>.guide-board.module-a {
					margin: 40rem 0;
				}

				.submit-form.module-a {
					margin: 40rem 0;
				}

				.goods-display.module-b {
					margin: 40rem 0;
				}
			}
		}
	}


	.local-util {
		@include respond-above(tablet) {
			margin: 80rem 0;

			.button-display {
				.button-area {
					column-gap: 8rem;
				}
			}
		}
	}
}


.section.designated-broker {
	.goods-display.module-c {
		.goods-list {
			.goods-item {
				.goods-wrap {
					
					@include respond-above (mobile) {
						.goods-inform {
							position: relative;
							padding-right: 96rem;
				
							.goods-util {
								// position: absolute;
								// top: 0;
								// right: 0;
								// bottom: 0;
								// display: flex;
								// justify-content: right;
								// margin: 0;
								// width: 96rem;
				
								.button-area {
									// flex-direction: column;
									// row-gap: 6rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

// 옵션에 옵션추가
.option-display.module-a {
	.option-wrap {
		.option-head {
			+.submit-form.module-a {
				padding: 16rem 0rem 24rem;
			}
		}
	}

}

@include respond-above(tablet) {
	.option-display.module-a {
		.option-wrap {
			.option-head {
				+.submit-form.module-a {
					padding: 16rem 16rem 24rem;
				}
			}
		}

	}
}