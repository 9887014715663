/* post-display */
.post-display {
	.post-list {
		margin: 0;
		padding: 0;

		.post-item {
			list-style: none;
		}
	}

	.post-info {
		background-color: red;
		.info-list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 12rem;
	
			.info-item {
				.data-list {
					display: flex;
					align-items: center;
			
					.data-item {
						display: block;
						@include font((outline: body, size: medium, weight: bold)...);
						color: color(base, 090);
						.body,
						.head {
							display: inline-block;
							vertical-align: top;
						}
						&.grade {
							.body {
								@include grade(20rem);
	
								.point {
									@include hidden;
								}
							
								.count {
									display:none;
								}
							}
						}
					}
					
				}

				.paging {
					display: flex;
					align-items: center;
	
					.paging-util {
						display: flex;
	
						.btn {
							width: 20rem;
							height: 20rem;
	
							svg {
								width: 20rem;
								height: 20rem;
								fill: color(base, 030);
							}
						}
					}
	
					.paging-temp {
						margin: 0 4rem;
						@include font((outline: body, size: small)...);
						color: color(base, 040);
					}
				}

				.button-display {
					.button-area {
						display: flex;
						.btn.module-a {
							+.btn.module-a {
								margin-left: 12rem;
							}
						}
					}
				}
			}
		}
	}
}

// post-display
.post-display.module-a {
	.post-list {
		.post-item {
			display: flex;
			flex-direction: column;
			border-radius: 16rem;
			background-color: color(base, 000);
			
			+.post-item {
				margin-top: 16rem;
			}

			.post-wrap {
				order: 1;

				.post-inform {
					display: flex;
					flex-direction: column;

					padding: 0 20rem;

					.data-list {
						.data-item {
							&.grade {
								.body {
									@include grade(20rem);
		
									.point {
										@include hidden;
									}								
									.count {
										display:none;
									}
								}
							}
						}
					}				

					.post-type {
						order: 2;
						.data-list {
							display: flex;
							flex-wrap: wrap;

							.data-item {
								display: block;
								margin-right: 4rem;
							}
						}

						~.post-head {
							margin-top: 8rem;
						}
					}

					.post-head {
						order: 3;
						.post-title {
							.post-name {
								@include font((outline: body, size: medium, weight: bold)...);
								color: color(base, 070);							
							}
						}

						p.post-title {
							margin: 0;
						}
					}

					.post-body {
						order: 4;
						margin: 20rem 0;
						@include font((outline: body, size: x-small, weight: light)...);
						color: color(base, 060);
					}

					.post-data {
						order: 1;
						margin-bottom: 20rem;
						padding: 12rem 0;
						border-bottom: 1rem solid color(base, 010);

						>.data-list {
							display: flex;
							align-items: center;

							margin: 0;
							padding: 0;
							list-style: none;

							.data-item.writer {
								flex-shrink: 0;

								.head {
									@include hidden;
								}

								.body {
									display: flex;
									align-items: center;

									&::before {
										content: '';
										flex-shrink: 0;
										display: block;
										margin-right: 8rem;
										width: 20rem;
										height: 20rem;
										border-radius: 50%;
										overflow: hidden;
										box-shadow: 0 0 0 1rem rgba(0,0,0,0.2);
										background-repeat: no-repeat;
										background-position: 0 0;
										background-size: cover;
										background-image: var(--default-picture);
									}

									.name {
										display: block;
										@include font((outline: body, size: small)...);
										color: color(base, 070);
									}
								}

								&.unknown {
									.body {
										a {
											color: color(base, 040);
											&:hover {
												text-decoration: none;
												cursor: default;
											}
										}
									}
								}

								~.data-item.posted {
									margin-left: 4rem;
								}
							}

							.data-item.posted {
								flex-shrink: 0;

								.head {
									@include hidden;
								}

								.body {
									display: block;
									@include font((outline: body, size: xx-small)...);
									color: color(base, 050);
								}
							}

							.data-item.attachments {
								flex: 1 1 100%;

								display: flex;
								justify-content: flex-end;
								position: relative;
								margin-left: 8rem;
								width: 100%;

								.head {
									display: flex;
									align-items: center;
									@include font((outline: body, size: x-small)...);
									color: color(base, 040);

									&::after {
										content: '';
										display: block;
										width: 20rem;
										height: 20rem;
										background: icon(fill-download, color(base, 040)) no-repeat center/cover;
									}
								}
								
								.body {
									position: absolute;
									top: 100%;
									right: 0;
									z-index: 10;
									width: 192rem;

									.btn {
										svg {
											width: 20rem;
											height: 20rem;
											fill: color(base, 020);
										}

										&.active {
											svg {
												fill: color(base, 050);
											}
										}
									}
								}

								&:not(:focus-within) {
									.body {
										display: none;
									}
								}
							}
						}
					}
				}
			}

			.post-util {
				order: 3;
				border-top: 1rem solid color(base, 010);
			}

			.post-side {
				order: 2;
				margin-bottom: 20rem;
				padding: 0 20rem;
			}

			&:not(.active) {
				.post-wrap {
					.post-inform {
						.post-body {
							@include ellipsis(3);
						}
					}
				}

				.post-util {
					.button-display {
						.button-area {
							.btn {
								.btn-text::after {
									transform: rotate(180deg);
								}
							}
						}
					}
				}
			}
		}
	}

	&.style-b {
		.post-list {
			display:flex;
			flex-direction: column;
			gap:1rem;

			.post-item {
				padding: 48rem;
				background-color: color(base, 000);
				border-radius: 8rem;				

				+.post-item {
					margin-top: 0;
				}

				.post-wrap {
					display: flex;
					flex-direction: row;
					gap: 24rem;
					text-align:left;

					.post-data {
						.body {
							@include font((outline: body, size: small, weight: bold)...);
						}
					}

					.post-infom {
						display:flex;
						flex-direction: column;
						gap: 8rem;

						.post-head {
							.post-subject {
								@include font((outline: body, size: small, weight: bold)...);
							}
						}
						.post-body {
							.para {
								color: color(base, 090);
								@include font((outline: body, size: small-1x)...);
							}
						}
					}
				}
			}			
		}

		&.type-a {
			.post-list {
				.post-item {
					justify-content: flex-start;
				}
			}
		}
	}	
}

// comment-display
.comment-display.module-a {

	.comment-info {
		
		.info-list {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.info-item {
				.data-list {
					display: flex;
					align-items: center;
			
					.data-item {
						display: flex;
						@include font((outline: body, size: x-small)...);
						color: color(base, 040);
			
						.head {
							display: flex;
							align-items: center;
							margin-right: 4rem;
		
							&::before {
								content: '';
								display: block;
								margin-right: 2rem;
								width: 16rem;
								height: 16rem;
								background: icon(line-comment, color(base, 040)) no-repeat center/cover;
							}
						}	
						.body {
							display: flex;
							align-items: center;
						}
					}
				}
			}
		}

		~.comment-write {
			margin-top: 8rem;
		}
	}
		
	.comment-write {
		~.comment-list {
			margin-top: 24rem;
		}

		.submit-form {
			.form-util {
				margin-top: 8rem;
			}
		}
	}

	.comment-list {
		.comment-item {
			position: relative;

			+.comment-item {
				margin-top: 16rem;
				padding-top: 16rem;
				border-top: 1rem solid color(base, 010);
			}

			.comment-wrap {
				position: relative;
				
				.comment-inform {
					.comment-type {
						.data-list {
							display: flex;

							.data-item {
								display: block;

								+.data-item {
									margin-left: 4rem;
								}
							}
						}

						~.comment-head {
							margin-top: 8rem;
						}
					}

					.comment-head {
						.comment-title {
							@include font((outline: body, size: medium, weight: bold)...);
							color: color(base, 070);							
						}
					}

					.comment-body {
						@include font((outline: body, size: x-small, weight: light)...);
						color: color(base, 060);
					}

					.comment-data {
						margin-bottom: 12rem;

						.data-list {
							display: flex;
							align-items: center;

							margin: 0;
							padding: 0;
							list-style: none;


							.data-item {
								.head {
									@include hidden;
								} 
							}

							.data-item.writer {
								.body {
									display: flex;
									align-items: center;

									&::before {
										content: '';
										display: block;
										margin-right: 8rem;
										width: 20rem;
										height: 20rem;
										border-radius: 50%;
										overflow: hidden;
										box-shadow: 0 0 0 1rem rgba(0,0,0,0.2);
										background-repeat: no-repeat;
										background-position: 0 0;
										background-size: cover;
										background-image: var(--default-picture);
									}

									.name {
										display: block;
										@include font((outline: body, size: small)...);
										color: color(base, 090);
									}
								}

								~.data-item.posted {
									margin-left: 4rem;
								}
							}

							.data-item.posted {
								.body {
									display: block;
									@include font((outline: body, size: xx-small)...);
									color: color(base, 040);
								}
							}
						}
					}

					.comment-util {
						position: absolute;
						top: 0;
						right: 0;
		
						.button-display {
							.button-area {
								display: flex;
								.btn {
									.btn-text {
										display: block;
										@include font((outline: body, size: small)...);
										color: color(base, 040);
									}
									+.btn {
										margin-left: 12rem;
									}
								}
							}
						}
					}
				}
			}
			.comment-side {
				.reply-display {
					.reply-write {
						~.reply-list {
							margin-top: 24rem;
						}
				
						.submit-form {
							.form.textarea {
								min-height: 80rem;
							}

							.form-util {
								margin-top: 8rem;
							}
						}
					}
				}
			}
		}
	}
}


// post-display module-b
.post-display.module-b {	
	&.style-b {
		.post-list {
			.post-item {
				padding: 24rem 20rem;
				border-radius: 16rem;
				background-color: color(base, 000);

				+.post-item {
					margin-top: 16rem;
				}
			}
		}
	}

	.post-list {
		.post-item {
			display: flex;
			flex-direction: column;

			.post-wrap {
				order: 1;
				position: relative;

				.post-select {
					display: flex;
					position: absolute;
					top: 0;
					right: 0;

					~.post-inform {
						.post-type {
							padding-right: 28rem;
						}
					}
				}

				.post-inform {
					display: flex;
					flex-direction: column;

					.post-type {
						.data-list {
							display: flex;
							flex-wrap: wrap;

							.data-item {
								display: block;
								margin-right: 4rem;
							}
						}

						~.post-head {
							margin-top: 16rem;
						}
					}

					.post-head {
						.post-title {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							@include font((outline: body, size: small, weight: bold)...);
							color: color(base, 070);

							.post-name {
								display: block;
								margin-right: 4rem;
								color: inherit;
							}

							a.post-name:not(:hover) {
								text-decoration: none;
							}
						}

						p.post-title {
							margin: 0;
						}

						~.post-data {
							margin-top: 8rem;
						}
					}

					.post-data {
						>.data-list {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							align-items: center;
							margin: 0;
							padding: 0;
							list-style: none;

							.data-item.writer {
								margin-right: 4rem;
								.head {
									@include hidden;
								}

								.body {
									display: flex;
									align-items: center;

									.name {
										display: block;
										@include font((outline: body, size: xx-small)...);
										color: color(base, 070);
									}

									.department,
									.team {
										display: flex;
										@include font((outline: body, size: xx-small)...);
										color: color(base, 050);

										&::before {
											content: '';
											flex-shrink: 0;
											display: block;
											width: 12rem;
											height: 16rem;
											background: icon(bullet-dot, color(base, 020)) no-repeat center/cover;
										}
									}
								}

								&.unknown {
									.body {
										a {
											color: color(base, 040);
											&:hover {
												text-decoration: none;
												cursor: default;
											}
										}
									}
								}
							}

							.data-item.posted {
								display: flex;
								justify-content: flex-end;

								.head {
									@include hidden;
								}

								.body {
									display: block;
									@include font((outline: body, size: xx-small)...);
									color: color(base, 050);
								}
							}

							.data-item.status {
								display: flex;
								justify-content: flex-end;

								.head {
									@include hidden;
								}

								.body {
									display: block;
									@include font((outline: body, size: xx-small)...);
									color: color(base, 040);
								}
							}
						}
					}
				}
			}
		}
	}
}


// post-display module-c
.post-display.module-c {
	.post-list {
		display: flex;

		.post-item {
			position: relative;
			
			
			+.post-item {
				margin-left: 12rem;
			}

			.post-wrap {
				.post-figure {
					.post-thumbnail {
						display: block;
						overflow: hidden;
						margin: 0;
						width: 240rem;
						height: 240rem;
						border-radius: 16rem;
						box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);

						img {
							display: block;
							position: relative;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
		}
	}

	&.attr-scroll {
		display: flex;
		position: relative;
		left: calc(50% - 50vw);
		width: 100vw;
		overflow-x: scroll;
		box-sizing: border-box;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: none;
		scrollbar-width: none;
		
		&::-webkit-scrollbar {
			display: none;
		}
		
		.post-list {
			padding: 0 $container-gap;
		}
	}
}

@mixin posts-write($prefix: comment) {

	.#{$prefix}-write {
		&[data-bui-expand=postsCommentWrite]:not(.active) {
			.form-util {
				@include hidden;
			}
		}

		.submit-form {
			display: flex;
			flex-direction: column;
			gap: 16rem;
	
			margin: 0;
			padding: 0;
			border: hidden;
	
			legend {
				@include hidden;
			}
	
			.form-list {
				.form-item {
					.form-wrap {
						.form-head {
							@include hidden;
						}
	
						.form-body {
							.form-area {
								display: flex;
	
								.flex {
									flex: 1 1 100%;
								}
							}
						}
					}
				}
			}
			.form-util {
				.button-display {
					display: flex;
					justify-content: flex-end;
	
					.button-area {
						display: flex;
						gap: 4rem;
					}
				}
			}
		}
	}
}


@mixin posts-body($prefix: posts) {
	
	.#{$prefix}-body {
		// display: flex;
		// flex-direction: column;
		// align-items: flex-start;
		// gap: 8rem;
	
		.para {
			margin: 0;
			width: 100%;
			color: color(normal, 010);
			@include font((outline: body, size: small-1x)...);
			
			.wbr {
				text-decoration: inherit;
			}
		}

		.btn.expand {
			@include button-reset;
			display: inline-flex;
			align-items: center;
			vertical-align: top;
			color: color(normal, 030);
			@include font((outline: body, size: small-2x, family: default)...);
	
			&::after {
				content: '';
				display: block;
				width: 20rem;
				height: 20rem;
				background: icon(chevron-bottom, color(normal, 030)) no-repeat center/cover;
			}
	
			&.active {
				&::after {
					transform: rotate(180deg);
				}
			}
		}
		.#{$prefix}-picture {
			margin-top: 16rem;
		}
	}
}

@mixin posts-item($prefix: posts) {

	.#{$prefix}-item.author {
		.#{$prefix}-info {
			.data-list {
				.data-item.writer {
					.body {
						color: color(accent, 020);
					}
				}
			}
		}
	}

	.#{$prefix}-item.deleted {

		.#{$prefix}-body {
			// padding-top: 40rem;
			padding-top: 0;

			.para {
				color: color(normal, 030);
			}
		}

	}
	
	.#{$prefix}-info {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 8rem;
	
		.data-list {
			display: flex;
			flex-wrap: wrap;
			gap: 8rem;
			margin: 0;

			.data-item.writer {
				display: flex;
				align-items: center;
	
				.head {
					@include hidden;
				}
				
				.body {
					display: flex;
					align-items: center;
					gap: 4rem;
					@include font((outline: body, size: small-1x, weight: bold)...);
					color: color(normal, 010);

					a {
						display: block;
						color: inherit;

						&:not(:hover) {
							text-decoration: none;
						}

						&:hover {
							text-decoration: underline;
						}
					}
				}

				&[class*=attr-] {
					&::before {
						content: '';
						display: block;
						margin-right: 4rem;
						width: 24rem;
						height: 24rem;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
					}
				}

				&.attr-broker {
					&::before {
						background-image: url(./../images/common/icon_broker.svg);
					}
				}

				&.attr-bot {
					&::before {
						background-image: url(./../images/common/icon_bot.svg);
					}
				}

				&.unknown {
					.body {
						a {
							color: color(base, 030);
							&:hover {
								text-decoration: none;
								cursor: default;
							}
						}
					}
				}
			}

			.data-item.author {
				border-radius: 12rem;

				.body {
					color: color(accent, 020);
				}
			}
	
			.data-item.posted {
				display: flex;
				align-items: center;
	
				.head {
					@include hidden;
				}
				
				.body {
					display: block;
					color: color(normal, 030);
					@include font((outline: body, size: small-2x)...);
				}
			}

			.data-item.advice {
				display: flex;
				align-items: center;
	
				.head {
					@include hidden;
				}
				
				.body {
					display: flex;
					column-gap: 4rem;
					color: color(normal, 030);
					@include font((outline: body, size: small-2x)...);
				}
			}
			.data-item.grade {
				.head {
					display: none;
				}
				.body {
					@include grade(20rem);

					.point {
						@include hidden;
					}								
					.count {
						display:none;
					}
				}
			}		
			
		}
	
		.button-area {
			display: flex;
			align-items: center;
			gap: 8rem;
			min-height: 24rem;
	
			.btn {
				@include button-reset;
				display: flex;
				gap: 4rem;
				align-items: center;
				color: color(normal, 020);
				@include font((outline: body, size: small-2x)...);
	
				&:not(:hover) {
					text-decoration: none;
				}
	
				.btn-text {
					display: block;
				}
			}
	
			.btn.report {
				&::before {
					content: '';
					display: block;
					width: 20rem;
					height: 20rem;
					background: icon(emergency, color(normal, 020)) no-repeat center/cover;
				}
			}
		}
	}
	
	.#{$prefix}-head {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8rem;
	
		.#{$prefix}-subject {
			flex: 1 1 100%;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 8rem;

			margin: 0;
			width: 100%;
			color: color(normal, 010);
			@include font((outline: body, size: small-1x)...);


			a {
				&:not(:hover) {
					text-decoration: none;
				}

				&:hover {
					text-decoration: underline;
				}
			}
			
			.#{$prefix}-name {
				display: block;
				color: inherit;
	
				.wbr {
					text-decoration: inherit;
				}
			}

			.#{$prefix}-type {
				display: block;
				@include font((outline: body, size: small-1x)...);
				color: color(normal, 030);
			}
		}


		.btn.expand {
			@include button-reset;
			display: inline-flex;
			align-items: center;
			vertical-align: top;
			color: color(normal, 030);
			@include font((outline: body, size: small-2x, family: default)...);
	
			&::after {
				content: '';
				display: block;
				width: 20rem;
				height: 20rem;
				background: icon(chevron-bottom, color(normal, 030)) no-repeat center/cover;
			}
	
			&.active {
				&::after {
					transform: rotate(180deg);
				}
			}
		}
	}

	.related-items {
		.button-area {
			display: flex;
			flex-wrap: wrap;
			gap: 8rem 4rem;
		}
	}
	
	.#{$prefix}-data {
		display: flex;
		align-items: center;

		.data-list {
			flex: 1 1 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 16rem;
			margin: 0;
	
			.data-item {
				display: flex;

				.btn {
					@include button-reset;
					display: flex;
					align-items: center;
					gap: 4rem;
					color: color(normal, 020);
					@include font((outline: body, size: small-2x)...);
					
					.btn-text {
						display: flex;
						align-items: center;
						gap: 4rem;

						&::before {
							display: block;
							width: 20rem;
							height: 20rem;
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
						}

						.em {
							display: block;
							// @include font((weight: bold)...);
						}
					}
				}
			}
	
			.data-item.type {
				display: flex;

				.head {
					@include hidden;
				}
			}
			
			.data-item.reply,
			.data-item.comment,
			.data-item.likeit {
				display: flex;
				align-items: center;
				gap: 4rem;
				@include font((outline: body, size: small-2x)...);
				color: color(normal, 020);
				
				.head {
					display: block;
				}
				
				.body {
					display: block;
					// @include font((weight: bold)...);
				}
			}

			.data-item.type {
				flex: 1 1 100%;
			}

			.data-item.likeit {
				flex-shrink: 0;

				.btn {
					.btn-text {
						&::before {
							content: '';
							background-image: icon(thumb-up, color(normal, 020));
						}
					}
				}
			}
			
			.data-item.reply {
				.btn {
					.btn-text {
						&::before {
							content: '';
							background-image: icon(comment, color(normal, 020));
						}
					}
				}
			}
		}
	}
	
	.#{$prefix}-tags {
		.data-list {
			display: flex;
			flex-wrap: wrap;
			gap: 4rem 8rem;
			margin: 0;
	
			.data-item {
				display: flex;
				align-items: center;
				gap: 4rem;
				color: color(normal, 030);
				@include font((outline: body, size: small-2x)...);
			}
		}
	}
}

.posts-read.module-a {
	// (팝업으로 변경하여 임시 삭제)
	// padding-top: 24rem;
	// laptop
	// @include respond-above(tablet) {
	// 	border-top: 1rem solid color(normal, 010);
	// }

	@include posts-item($prefix: posts);

	.posts-list {
		.posts-item {
			// display: flex;
			// flex-direction: column;
			// row-gap: 24rem;

			.posts-wrap {
				.posts-inform {
					.posts-info {
						margin: 24rem 0;
					}

					.posts-body {
						margin: 24rem 0;
						color: color(normal, 010);
						@include font((outline: body, size: small)...);
					}

					.related-items {
						margin: 24rem 0;
					}

					.posts-tags {
						margin: 24rem 0;
					}

					.posts-data {
						margin: 48rem 0 16rem 0;

						.data-item.comment {
							flex: 1 1 100%;
							@include font((outline: body, size: small-1x, weight: bold)...);
							color: color(normal, 010);
						}
					}
				}
				.posts-comment {
					margin-left: 24rem;
					padding: 24rem;
					border-radius: 4rem;
					background-color: color(normal, 050);
					position: relative;
					.posts-wrap {
						.posts-inform {
							.posts-body {
								margin: 8rem 0;
								p {
									margin: 0;
								}
							}
							.data-list {
								.data-item {
									&.posted {
										display: block;
										@include font((outline: body, size: small-2x)...);
										color: color(base, 040);
										.head {
											@include hidden;
										}
									}
								}
							}
						}
						+.posts-wrap {
							margin-top: 16rem;
						}
					}
					&::before {
						display: inline-flex;
						content: "";
						display: block;
						position: absolute;
						left: -24rem;
						top: 24rem;
						color: #afafaf;
						width: 12rem;
						height: 12rem;
						border-left: 2rem solid #c6c6c6;
						border-bottom: 2rem solid #c6c6c6;						
					}
				}
			}
		}
	}
}

.posts-display.module-a {
	.data-util {
		margin-bottom: 24rem;
		.util-item {
			.data-total {
				display: flex;
				gap: 4rem;
				flex-wrap: wrap;
				.head {
					@include font((outline: body, size: small-1x)...);
					line-height: 28rem;
				}
				.body {
					@include font((outline: body, size: small, weight: bold)...);
				}
			}
		}
		@include respond-below(tablet) {
			padding-top: 40rem;
		}

	}
	.data-find {
		
		@include respond-below(tablet) {
			position: relative;
			left: calc(50% - 50vw);
			width: 100vw;
			border-bottom: 1px solid color(base, 010);
		}

		@include respond-above(tablet) {
			border-radius: 16rem;
			border: 4px solid color(base, 005);
		}

		.data-form {
			padding: 12rem 20rem;

			@include respond-above(tablet) {
				padding: 20rem;
			}

			.submit-form {
				margin: 0;
				padding: 0;
				border: hidden;
				
				legend {
					@include hidden;
				}

				.form-list {
					.form-item {
						.form-wrap {
							.form-head {
								@include hidden;
							}
							.form-body {
								position: relative;

								.form-area {
									display: flex;
									gap: 8rem;
		
									.flex {
										flex: 1 1 100%;
									}

									.btn {
										@include button-reset;

										flex-shrink: 0;
										display: flex;
										justify-content: center;
										align-items: center;

										position: relative;
										width: 48rem;
										height: 48rem;
										border-radius: 4.8rem;
										@include font((outline: body, size: small-3x)...);
										color: color(normal, 000);


										&::after {
											content: '';
											display: block;
											position: absolute;
											top: 2rem;
											right: 2rem;
											bottom: 2rem;
											left: 2rem;
											background-repeat: no-repeat;
											background-position: center;
											background-size: 24rem;
										}

										&.replay {
											background-color: color(normal, 050);

											&::after {
												background-image: icon(replay, color(normal, 020));
												background-color: color(normal, 050);
											}
										}

										&.search {
											background-color: color(accent, 010);

											&::after {
												background-image: icon(dawin-search, color(normal, 000));
												background-color: color(accent, 010);
											}
										}
									}
								}

								.data-board.data-finder-autocomplete {
									position: absolute; 
									z-index: 2;
									top: calc(100% + 8rem);
									left: 0;
									right: 0;

									.board-wrap {
										padding: 16rem;
										box-sizing: border-box;
										border-radius: 4.8rem;
										border: 1rem solid  color(normal, 040);
										background-color: color(base, 000);
										box-shadow: 0 2rem 4rem 0 rgba(0,0,0,0.15);
									}
								}
							}
						}
					}
				}
			}
			+.data-sort {
				border-top: 1rem solid color(base, 005);
			}
		}
	}

	.data-sort {
		padding: 12rem 20rem;
		
		@include respond-below(tablet) {
			display: flex;
			overflow-y: hidden;
			overflow-x: scroll;
			box-sizing: border-box;
			min-inline-size: initial;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: none;
			scrollbar-width: none;
			
			&::-webkit-scrollbar {
				display: none;
			}
		}
		
		.submit-form {
			flex-shrink: 0;
			margin: 0;
			padding: 0;
			border: hidden;

			legend {
				@include hidden;
			}

			.form-head {
				@include hidden;
			}

			.form-body {
				.option-list {
					display: flex;
					column-gap: 4rem;

					.option-item {
						flex-shrink: 0;
					}
				}
			}
		}
	}
	
	.posts-list {
		@include posts-body($prefix: posts);
		@include posts-item($prefix: posts);

		.posts-data {
			justify-content: space-between;
		}

		.posts-item {
			display: flex;	
			flex-direction: column;
			row-gap: 24rem;
			margin-top: 20rem;
			padding-top: 20rem;

			+.posts-item {
				position: relative;
				box-shadow: inset 0 1rem 0 0 color(base, 005);
		
				@include respond-below(tablet) {
					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: calc(50% - 50vw);
						width: 100vw;
						height: 1rem;
						background-color: color(base, 005);
					}
				}
			}

			.posts-wrap {
				.posts-inform {
					display: flex;
					flex-direction: column;
					gap: 16rem;

					.posts-head {
						display: flex;
						flex-direction: row;
						gap: 16rem;

						.posts-picture {
							order: 2;
							flex-shrink: 0;
							display: block;
							position: relative;
							z-index: 1;
							margin: 0;
							width: 72rem;
							height: 72rem;

							.posts-thumbnail {
								display: block;
								width: 100%;
								height: 100%;
								overflow: hidden;
								border-radius: 4rem;
								box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);

								.image {
									display: block;
									position: relative;
									z-index: -1;
									object-fit: cover;
									width: 100%;
									height: 100%;
								}
							}
							~.posts-subject {
								max-width: calc(100% - 88rem);
							}
						}

						.posts-subject {
							order: 1;

							.posts-title {
								@include font((outline: body, size: small-1x, weight: bold)...);
							}
							
							.posts-name {
								@include ellipsis(3);
								&.expand {
									display: block;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: unset;
									max-width: 100%;
									text-overflow: unset;
									max-height: unset;
									word-wrap: unset;
									overflow: unset;
									
									+.button-display {
										display:none;
									}
								}
							}
						}
					}

					.posts-tags {
						display: flex;
						flex-wrap: wrap;
						gap: 4rem;
						cursor: pointer;
					}
				}
			}
		}
	}
	&.style-c {
		.posts-list {
			display: flex;
			flex-direction: column;
			gap: 24rem;

			.posts-item {
				padding: 20rem;
				background-color: #F1F2F4;
				border-radius: 16rem;
				margin-top: 0;

				.posts-wrap {
					display: flex;
					flex-direction: column;
					gap: 16rem;

					.posts-label {
						.data-list {
							display: flex;
							flex-direction: column;
							gap: 8rem;
							.data-item {
								.head {
									@include hidden;
								}
								.body {
									display: flex;
									gap: 8rem;
									align-items: center;
									@include font((outline: body, size: small-1x, weight: bold)...);
								}
							}
						}
					}
					.posts-inform {
						padding: 20rem;
						background-color: color(base, 000);
						border-radius: 16rem;

						.posts-info {
							.data-list {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								.data-item{
									&.grade.style-b {
										.body {
											gap: 8rem;
										}
	
									}
								}
							}
						}
						
						.posts-data {
							.data-list {
								justify-content: flex-start;
								.data-item {
									&.posted {
										.head {
											@include hidden;
										}
										.body {
											display: block;
											color: color(normal, 030);
											@include font((outline: body, size: small-2x)...);
										}
									}
								}
							}
						}
					}
				}
				&::before {
					display: none;
				}
			}
		}
		> .button-display {
			margin-bottom: 48rem;
		}
	}

	>.button-display {
		margin-top: 48rem;
	}
}

.comment-display {
	@include posts-write($prefix: comment);
	@include posts-write($prefix: reply);
	@include posts-body($prefix: comment);
	@include posts-item($prefix: comment);

	.comment-list {
		position: relative;
		box-shadow: inset 0 1rem 0 0 color(base, 005);

		@include respond-below(tablet) {
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: calc(50% - 50vw);
				width: 100vw;
				height: 1rem;
				background-color: color(base, 005);
			}
		}

		.comment-item {
			position: relative;
			margin-top: 20rem;
			padding-top: 20rem;
			box-shadow: inset 0 1rem 0 0 color(base, 005);

			&[data-bui-expand=postsCommentItem]:not(.active) {
				.comment-side {
					@include hidden;
				}
			}

			@include respond-below(tablet) {
				&::before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: calc(50% - 50vw);
					width: 100vw;
					height: 1rem;
					background-color: color(base, 005);
				}
			}

			.comment-wrap {
				.comment-inform {
					display: flex;
					flex-direction: column;
					gap: 16rem;

					.comment-data {
						justify-content: flex-end;
					}
				}


				// @include posts-write($prefix: comment);
			}
	
			&.expand-less:not(.active-expand) {
				.comment-body {
					.para {
						@include ellipsis(3);
					}
				}
			}
		
			&:not(.expand-less) {
				.comment-body {
					>.button-area {
						display: none;
					}
				}
			}
		
			&.expand-less {
				.comment-body {
					>.button-area {
						display: flex;
						margin-top: 8rem;
					}
				}
			}

			.comment-side {
				.reply-display {
					margin-top: 20rem;
				}
			}
		}
	}
}

.reply-display {
	@include posts-write($prefix: reply);
	@include posts-body($prefix: reply);
	@include posts-item($prefix: reply);

	border-radius: 8rem;
	background-color: color(base, 005);
	
	.reply-write {
		padding: 16rem;
		@include posts-write;
	}

	.reply-list {
		border-top: 1rem solid color(base, 000);

		.reply-item {
			padding: 16rem;

			+.reply-item {
				border-top: 1rem solid color(base, 000);
			}

			.reply-wrap {
				.reply-inform {
					display: flex;
					flex-direction: column;
					gap: 16rem;

					.reply-data {
						justify-content: flex-end;
					}
				}

				.reply-body {
					flex: 1 1 100%;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 4rem;
					margin: 0;
					width: 100%;
					color: color(normal, 010);
					@include font((outline: body, size: small-1x)...);

					.reply-name {
						@include ellipsis(3);
						
						&.expand {
							display: block;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: unset;
							max-width: 100%;
							text-overflow: unset;
							max-height: unset;
							word-wrap: unset;
							overflow: unset;
							
							+.button-display {
								display:none;
							}
						}

					}
				}
			}

			&.expand-less:not(.active-expand) {
				.reply-body {
					.para {
						@include ellipsis(3);
					}
				}
			}
		
			&:not(.expand-less) {
				.reply-body {
					>.button-area {
						display: none;
					}
				}
			}
		
			&.expand-less {
				.reply-body {
					>.button-area {
						display: flex;
						margin-top: 8rem;
					}
				}
			}

			.reply-data {
				justify-content: flex-end;
			}
		}
	}
}

.posts-latest.module-a {
	
	.posts-list {
		display: flex;
		flex-direction: column;
		row-gap: 16rem;
		margin: 24rem 0;

		.posts-item {
			.posts-wrap {
				display: flex;
				flex-direction: column;
				row-gap: 8rem;

				.posts-inform {
					.posts-head {
						.posts-subject {
							display: flex;

							.posts-name {
								display: block;
								color: color(normal, 010);
								@include font((outline: body, size: small-1x)...);

								&:not(:hover) {
									text-decoration: none;
								}

								&:hover {
									text-decoration: underline;
								}

								.wbr {
									display: block;
								}
							}
						}

						.posts-summary {
							color: color(normal, 030);
							@include font((outline: body, size: small-2x)...);
						}
					}
					.posts-info {					
						.data-list {
							display: flex;
							flex-wrap: wrap;
							column-gap: 8rem;
							margin: 0;
				
							.data-item {
								display: flex;
								align-items: center;
								column-gap: 4rem;
								color: color(normal, 030);
								@include font((outline: body, size: small-2x)...);
					
								.head {
									display: block;
								}
								
								.body {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}

.posts-display.module-a {
	&.style-d {
		.posts-list {
			.posts-item {
				.posts-wrap {
					cursor: pointer;
					.posts-inform {
						.posts-type {
							order: 2;
							.data-list {
								.data-item {
									.head {
										@include hidden;
									}
									&.category {
										color: color(base, 050)
									}
								}
							}
						}
						.posts-head {
							order: 3;
							.btn {
								margin-left: auto;
								&.expand {									
									&:after {
										display: none;
									}
								}
							}
						}
						.posts-body {
							order: 4;
						}	
						.posts-data {
							order: 1;
							.data-list {
								justify-content: flex-start;
								.data-item {
									.head {
										@include hidden;
									}
									&.date {
										.body {
											color: color(normal, 030)
										}
									}
								}
							}
							.button-area {
								gap: 8rem;
								.btn {
									cursor: pointer;
									appearance: none;
									color: #6a6a6a;
									background-color: #0000;
									border: hidden;
									align-items: center;
									gap: 4rem;
									margin: 0;
									padding: 0;
									font-family: Open Sans, Noto Sans KR, sans-serif;
									font-size: 13.3333rem;
									line-height: 20rem;
									display: flex;
									overflow: visible;
								}
							}
						}
					}
				}
				&:first-child {
					margin-top:0;
					padding-top:0;
				}

				.reply-display {
					background-color: color(base, 010);

					.reply-list {
						border-top: none;

						.reply-item {
							padding: 16rem;

							+.reply-item {
								border-top: 1rem solid color(base, 050);
							}

							.reply-wrap {
								.reply-inform {
									display: flex;
									flex-direction: column;
									gap: 16rem;

									.reply-data {
										justify-content: flex-end;
									}
								}
							}

							&.expand-less:not(.active-expand) {
								.reply-body {
									.para {
										@include ellipsis(3);
									}
								}
							}
						
							&:not(.expand-less) {
								.reply-body {
									>.button-area {
										display: none;
									}
								}
							}
		
							&.expand-less {
								.reply-body {
									>.button-area {
										display: flex;
										margin-top: 8rem;
									}
								}
							}

							.reply-data {
								justify-content: flex-end;
							}
						}
					}
				}
				+.posts-item {
					position: relative;
					&:before {
						content: "";
						width: 100vw;
						height: 1rem;
						background-color: color(base, 010);
						display: block;
						position: absolute;
						top: 0;
						left: calc(50% - 50vw);
					}    				
				}
				&[data-bui-expand] {
					&:not(.active) {
						.posts-wrap {
							.posts-inform {
								.posts-body {
									display: none;
								}
							}							
						}
						.reply-display {
							display: none;
						}
					}
				}
			}
			
		}
	}
}

.posts-display.module-a {
	.posts-list {

		.posts-info {

			.data-list {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				column-gap: 4rem;
				margin: 0;

				.data-item {
					display: flex;

					.head {
						@include hidden;
					}

					.body {
						display: block;
					}

					&.name {
						@include font((outline: body, size: small-1x, weight: bold)...);
						color: color(normal, 010);
					}
					
					&.writer {
						align-items: flex-start;
						.body {
							a {
								word-break: break-all;
							}
						}
					}

					&.grade {
						.body {
							@include grade(20rem);

							.point {
								@include hidden;
							}
						
							.count {
								display: block;
								margin-left: 8rem;
								@include font((outline: head, size: small-3x)...);
								color: color(normal, 010);
							}
						}
					}
					&.grade {
						&.style-b {
							.body {
								@include gradeAV(20rem);

								.point {
									display: block;
									margin-left: -5rem;
									@include font((outline: head, size: small-3x)...);
									color: color(normal, 010);
									visibility: visible;
									width: unset;
									height: unset;
									clip: unset;
									clip: unset;
									pointer-events: unset;
									position: unset;
								}
							
								.count {
									display: inline-flex;
									margin-left: 8rem;
									@include font((outline: head, size: small-3x)...);
									color: color(normal, 010);
									&::before {
										display:inline-block;
										content:'(리뷰';
										margin-right: 4rem;
									}
									&::after {
										display:inline-block;
										content:')';
									}
								}
							}
						}
					}
				}
			}
		}
	}
}