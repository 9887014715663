/**
 * @type module
 * @name data-table
 */

 .data-table.module-a {
	display: flex;
	flex-direction: column;
	gap: 16rem;
	margin-bottom: 40rem;

	>table {
		table-layout: fixed;
		margin: 0;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		
		caption {
			overflow: hidden;
			margin: -1px;
			padding: 0;
			width: 1px;
			height: 1px;
			line-height: 1px;
			font-size: 1px;
			color: transparent;
			border: 0;
			clip: rect(0 0 0 0);
			clip: rect(0,0,0,0);
		}

		>thead {
				>tr {
					>th {
						padding: 0.5em 0.25em;
						color: color(normal, 010);
						@include font((weight: regular)...);
						// white-space: nowrap;
						border: 1rem solid color(base, 010);
					}
				}
		}
		>tbody {
			>tr {
				>td {
					padding: 0.5em 0.25em;
					color: color(normal, 010);
					border: 1rem solid color(base, 010);
				}
			}
		}
	}

	+ .data-table.module-a {
		margin-top: 48rem;
	}

	&.type-c {
		>table {
			>tbody {
				>tr {
					>td {
						text-align: center;
					}
				}
			}
		}
	}

	&.style-b {
		>table {
			>thead {
				>tr {
					>th {
						background-color: color(base, 005);
					}
				}
			}
		}
	}

	&.small-2x {
		>table {
			@include font((outline: body, size: small-2x)...);
		}
	}
}


/**
 * @type module
 * @name data-table
 */

.data-table.module-c {
	border-top: 2px solid color(base, 020);
	border-bottom: 1px solid color(base, 060);
	
	@at-root html.mobile & {
		@include overflow-scroll-system(dark, medium, y);
	}

	@at-root html.laptop & {
		@include overflow-scroll-custom(dark, medium, y);
	}
	
	>table {
		table-layout: fixed;
		width: 100%;
		min-width: 1200rem;
		@include font((outline: body, size: x-small)...);
		border-spacing: 0;
		border-collapse: collapse;
		border-right: hidden;
		border-left: hidden;
		
		caption {
			overflow: hidden;
			margin: -1px;
			padding: 0;
			width: 1px;
			height: 1px;
			line-height: 1px;
			font-size: 1px;
			color: transparent;
			border: 0;
			clip: rect(0 0 0 0);
			clip: rect(0,0,0,0);
		}

		// laptop
		@include respond-below(laptop) {
			@include font((outline: body, size: small)...);
		}

		>thead {
				>tr {
					>th {
						padding: 1em 1.5em;
						color: color(base, 010);
						@include font((weight: bold)...);
						border-right: 1px solid color(base, 070);
						border-bottom: 1px solid color(base, 060);
						border-left: 1px solid color(base, 070);
					}
				}
		}
		>tbody {
			>tr {
				>td {
					padding: 1em 1.5em;
					color: color(base, 030);
					border-right: 1px solid color(base, 070);
					border-left: 1px solid color(base, 070);
				}
			}

			>tr:nth-child(even) {
				background-color: color(etc, 010);
			}
		}
	}
}
