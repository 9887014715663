@import '_variables';
@import '_mixin';


/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2022-04-11
 */

html.active-front-popup {
	body {
		overflow: hidden;
	}
}

.front-popup.module-a {
	display: flex;
	flex-direction: column;
	justify-content: center;	
	position: fixed;
	z-index: 110;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	background-color: rgba(0,0,0,0);
	transition: visibility 0.25s ease, opacity 0.25s ease, background-color 0.25s ease;

	@include respond-below(tablet) {
		align-items: stretch;

		.popup-page-body {
			flex: 1 1 100%;
		}
	}

	@include respond-above(tablet) {
		justify-content: center;
		align-items: center;
		padding: 48rem;

		&.medium {
			.popup-page-body {
				max-width: 480rem;
			}
		}

		&.large {
			.popup-page-body {
				max-width: 640rem;
			}
		}
	}

	&.active {
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
		background-color: rgba(0,0,0,0.4);
	}

	.popup-page-body {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		position: relative;
		width: 100%;
		overflow: hidden;
		background-color: color(base, 000);
		filter: drop-shadow(0 2rem 4rem rgba(0,0,0,0.25));

		.popup-local {
			flex: 1 1 100%;
			overflow: hidden;
			display: flex;
			flex-direction: column;

			.popup-local-head {
				flex-shrink: 0;
				padding: 20rem 80rem;
				box-shadow: inset 0 -1rem 0 0 color(base, 005);

				.popup-local-subject {
					display: flex;
					justify-content: center;
					margin: 0;
					@include font((outline: head, size: small-1x, weight: bold)...);
					color: color(base, 100);

					.popup-local-name {
						display: flex;
					}
				}

				.popup-local-control {
					position: absolute;
					top: 20rem;
					right: 16rem;
			
					.button-area {
						.btn {
							display: block;
							position: relative;
							height: 24rem;
							width: 24rem;
							color: color(base, 100);
							text-align: center;
			
							.btn-text {
								@include font((outline: body, size: small-4x)...);
							}
			
							&::before {
								content: '';
								display: block;
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
							}
						}
			
						.btn.back::before {
							background-color: color(base, 000);
							background-image: icon(arrow-back, color(base, 100));
						}

						.btn.share::before {
							background-color: color(base, 000);
							background-image: icon(share, color(base, 100));
						}

						.btn.search {
							&::before {
								background-color: color(base, 000);
								background-image: icon(dawin-search, color(base, 100));
							}
							&.selected::after {
								content: "";
								display: block;
								position: absolute;
								top: 0;
								right: 0;
								width: 4rem;
								height: 4rem;
								border-radius: 2rem;
								background-color: color(accent, 010);
							}
						}
					}
				}
			}
	
			.popup-local-body {
				flex: 1 1 100%;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				align-items: stretch;
			
				.popup-content {
					flex: 1 1 100%;
					display: flex;
					flex-direction: column;

					@at-root html.mobile & {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@at-root html.laptop & {
						@include overflow-scroll-custom(dark, medium, y);
					}
					
					.popup-content-head {
						margin: 40rem 0 0;
						padding: 0 20rem;
					} 

					.popup-content-body {
						flex: 1 1 100%;
						margin: 40rem 0;
						padding: 0 20rem;
					}
				}
			}

			.popup-local-func {
				position: absolute;
				top: 0;
				left: 16rem;
				height: 64rem;

				display: flex;
				align-items: center;
				justify-content: center;

				.button-area {
					display: flex;
					align-items: center;

					.btn.popup-close {
						width: 24rem;
						height: 24rem;
						
						svg {
							width: 24rem;
							height: 24rem;
							fill: color(base, 100);
						}
					}
				}
			}
		}
	}
}


/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2022-04-11
 */

html.active-content-popup {	
	body {
		overflow: hidden;
	}
}

.content-popup.module-a {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	z-index: 120;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 24rem;
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
	background-color: rgba(0,0,0,0);
	transition: visibility 0.25s ease, opacity 0.25s ease, background-color 0.25s ease;

	&.active {
		visibility: visible;
		pointer-events: auto;
		opacity: 1;
		background-color: rgba(0,0,0,0.4);
	}

	.full-height {
		height: 100%;
	}

	.popup-page-body {
		display: flex;
		position: relative;
		opacity: 1;
		visibility: visible;
		overflow: hidden;
		width: 100%;
		max-width: 400rem;
		border-radius: 8rem;
		box-shadow: 0 4rem 8rem 0 rgba(0,0,0,0.15), 0 1rem 2rem 0 rgba(0,0,0,0.1);
		transition: visibility 0.25s ease, opacity 0.25s ease;
		background-color: color(base, 000);
	
		@include respond-above(tablet) {
			max-height: 640rem;
		}

		.popup-local {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			width: 100%;
			
			.popup-local-head {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				z-index: 1;
				padding: 0 64rem;
				min-height: 64rem;
				box-shadow: 0 1rem 0 0 color(base, 010);
				
				.popup-local-subject {
					margin: 0;
					
					.popup-local-name {
						display: block;
						color: color(base, 100);
						@include font((outline: head, size: small-1x)...);
						@include ellipsis(1);
					}
				}
			}

			.popup-local-func {
				display: flex;
				align-items: center;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				padding: 0 20rem;
				height: 64rem;

				.button-area {
					.btn.popup-close {
						svg {
							fill: color(base, 100);
						}
					}
				}
			}
	
			.popup-local-body {
				flex: 1 1 100%;
				// overflow: hidden;
				// display: flex;
				// flex-direction: column;

				@at-root html.mobile & {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@at-root html.laptop & {
					@include overflow-scroll-custom(dark, medium, y);
				}
				
				.popup-content {
					flex: 1 1 100%;
					padding: 0 20rem;
					// display: flex;
					// flex-direction: column;

					.popup-content-body {
						// flex: 1 1 100%;
						// height: 100%;
						margin: 32rem 0;

						.section.module-a {
							margin: 40rem 0;

							.section-wrap {
								.section-head {
									.section-subject {
										display: flex;
										gap: 4rem;
										margin: 0;
										@include font((outline: body, size: small-1x, weight: bold)...);
										color: color(normal, 010);

										.section-name {
											display: block;
										}

										.section-data {
											@include font((weight: regular)...);
										}
									}
								}
							}
						}
					}
					
					.popup-content-util {
						margin: 24rem 0;

						.button-display {
							.button-area {
								.btn {
									border-radius: 4rem;
								}
							}
						}
					}
				}
			}

			.popup-local-util {
				flex-shrink: 0;
				position: relative;
				padding: 24rem 20rem;
				box-shadow: 0 -1rem 0 0 color(base, 005);

				.button-display {
					.button-area {
						.btn {
							border-radius: 4rem;
						}
					}
				}
			}
		}
	}
}

/**
 * @type		component
 * @name		info-popup
 * @moudle		module-a
 * @update		2022-04-11
 */

html.active-info-popup {	
	body {
		overflow: hidden;
	}
}

.info-popup.module-a {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 24rem;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	background-color: rgba(0,0,0,0);
	// transition: visibility 0.25s ease, opacity 0.25s ease, background-color 0.25s ease;

	&.active {
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
		background-color: rgba(0,0,0,0.4);
	}

	.popup-page-body {
		display: flex;
		position: relative;
		opacity: 1;
		visibility: visible;
		overflow: hidden;
		width: 280rem;
		box-shadow: 0 4rem 8rem 0 rgba(0,0,0,0.15), 0 1rem 2rem 0 rgba(0,0,0,0.1);
		// transition: visibility 0.25s ease, opacity 0.25s ease;
		background-color: color(base, 000);

		.popup-local {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			width: 100%;
			align-items: center;

			.popup-local-head {
				// @include hidden;
				.popup-local-subject {
					margin: 0;
					padding: 0;
					@include font((outline: body, size: small, weight: bold)...);
					color: color(normal, 010);

					.popup-local-name {
						display: block;
					}
				}
			}
	
			.popup-local-body {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				@at-root html.mobile & {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@at-root html.laptop & {
					@include overflow-scroll-custom(dark, medium, y);
				}
				
				.popup-content {
					flex: 1 1 100%;
				}
			}
		}
	}

	.popup-local-util {
		flex-shrink: 0;
		display: flex;
		width: 100%;
		border-top: 1rem solid color(base, 010);

		.button-display {
			flex: 1 1 100%;
			
			.button-area {
				display: flex;

				.btn {
					flex: 1 1 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 48rem;
					@include font((outline: body, size: small-1x)...);
					color: color(normal, 010);

					.btn-text {
						display: block;
					}
				}
			}
		}
	}

	.popup-local-func {
		display: flex;
		align-items: center;
		position: absolute;
		z-index: 1;
		top: 20rem;
		right: 20rem;

		.button-area {
			.btn.popup-close {
				svg {
					fill: color(base, 100);
				}
			}
		}
	}

	&.small {
		.popup-page-body {
			max-width: 280rem;

			.popup-local {
				.popup-local-head {
					margin-top: 32rem;
				}
				.popup-local-body {
					margin: 32rem 0;
					padding: 0 24rem;
				}
			}
		}
	}

	&.type-b {
		.popup-page-body {
			max-width: 280rem;

			.popup-local {
				.popup-local-head {
					margin-top: 32rem;
				}
				.popup-local-body {
					margin: 48rem 0 32rem;
					padding: 0 24rem;
				}
			}
		}
	}

	&.style-b {
		.popup-page-body {
			border-radius: 8rem;
		}
	}
}


/**
 * @type		component
 * @name		dialog-popup
 * @moudle		module-a
 * @update		2022-10-25
 */

 html.active-dialog-popup {	
	body {
		overflow: hidden;
	}
}

.dialog-popup.module-a {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 24rem;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	background-color: rgba(0,0,0,0);
	// transition: visibility 0.25s ease, opacity 0.25s ease, background-color 0.25s ease;

	&.active {
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
		background-color: rgba(0,0,0,0.4);
	}

	.popup-page-body {
		display: flex;
		position: relative;
		opacity: 1;
		visibility: visible;
		overflow: hidden;
		width: 280rem;
		box-shadow: 0 4rem 8rem 0 rgba(0,0,0,0.15), 0 1rem 2rem 0 rgba(0,0,0,0.1);
		// transition: visibility 0.25s ease, opacity 0.25s ease;
		background-color: color(base, 000);

		.popup-local {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			width: 100%;
			align-items: center;

			.popup-local-head {
				// @include hidden;
				.popup-local-subject {
					margin: 0;
					padding: 0;
					@include font((outline: body, size: small, weight: bold)...);
					color: color(normal, 010);

					.popup-local-name {
						display: block;
					}
				}
			}
	
			.popup-local-body {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				@at-root html.mobile & {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@at-root html.laptop & {
					@include overflow-scroll-custom(dark, medium, y);
				}
				
				.popup-content {
					flex: 1 1 100%;
				}
			}
		}
	}

	.popup-local-util {
		flex-shrink: 0;
		display: flex;
		width: 100%;
		border-top: 1rem solid color(base, 010);

		.button-display {
			flex: 1 1 100%;
			
			.button-area {
				display: flex;
				.btn {
					flex: 1 1 100%;
				}
			}
		}
	}

	.popup-local-func {
		display: flex;
		align-items: center;
		position: absolute;
		z-index: 1;
		top: 20rem;
		right: 20rem;

		.button-area {
			.btn.popup-close {
				svg {
					fill: color(base, 100);
				}
			}
		}
	}

	&.small {
		.popup-page-body {
			max-width: 280rem;

			.popup-local {
				.popup-local-head {
					margin-top: 32rem;
				}
				.popup-local-body {
					margin: 32rem 0 24rem 0;
					padding: 0 24rem;
				}
			}
		}
	}

	&.style-b {
		.popup-page-body {
			border-radius: 8rem;
		}
	}

	&.type-b {
		.popup-page-body {
			max-width: 280rem;

			.popup-local {
				.popup-local-head {
					margin-top: 32rem;
				}
				.popup-local-body {
					margin: 48rem 0 32rem;
					padding: 0 24rem;
				}
			}
		}
	}
}

/**
 * @type		component
 * @name		toast-popup
 * @moudle		module-a
 * @update		2022-03-27
 */
@keyframes toast-popup-active {
	0% {
		transform: translate(0, 120%);
	}
	100% {
		transform: translate(0, 0);
	}
}

@keyframes toast-popup-inactive {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(0, 120%);
	}
}

.toast-popup.module-a.style-b {
	display: flex;
	justify-content: center;
	align-items: flex-end;

	position: absolute;
	z-index: 50;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	outline: none;
	background-color: rgba(0,0,0,0);
	transition: background-color 0.5s ease;

	&:not(.enabled) {
		display: none;
	}

	&:not(.active) {
		.popup-page-body {
			animation-name: toast-popup-inactive;
			animation-duration: 0.25s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(.2,0,0,1);
		}
	}
	
	&.active {
		background-color: rgba(0,0,0,0.2);

		.popup-page-body {
			animation-name: toast-popup-active;
			animation-duration: 0.25s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(.2,0,0,1);
		}
	}

	.popup-page-body {
		flex: 0 0 100%;
		width: 100%;

		.popup-local {
			overflow: hidden;
			border-top-right-radius: 16rem;
			border-top-left-radius: 16rem;
			background-color: #fff;

			.popup-local-head {
				@include hidden;
			}

			.popup-local-body {
				margin: 56rem 0 24rem 0;
				padding-right: $container-gap;
				padding-left: $container-gap;
			}

			.popup-local-func {
				display: flex;
				align-items: center;
				position: absolute;
				top: 8rem;
				right: 8rem;
		
				.button-area {
					display: block;
					
					.btn.popup-close {
						width: 24rem;
						height: 24rem;

						svg {
							fill: color(base, 100);
							width: 20rem;
							height: 20rem;
						}
					}
				}
			}
		}
	}
}

.toast-popup.module-a.style-c {
	display: flex;
	justify-content: flex-end;

	position: absolute;
	z-index: 50;
	top: 0;
	bottom: 16rem;
	right: 0;
	left: 0;
	outline: none;
	// background-color: rgba(0,0,0,0);
	transition: background-color 0.5s ease;
	flex-direction: column;
	align-items: center;
	gap: 8rem;
	pointer-events: none;

	&:not(.enabled) {
		display: none;
	}

	&:not(.active) {
		.popup-page-body {
			animation-name: toast-popup-inactive;
			animation-duration: 0.25s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(.2,0,0,1);
		}
	}
	
	&.active {
		// background-color: rgba(0,0,0,0.2);

		.popup-page-body {
			animation-name: toast-popup-active;
			animation-duration: 0.25s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(.2,0,0,1);
		}
	}

	.popup-page-body {
		max-width: 100%;
		width: 400rem;
		pointer-events: auto;

		.popup-local {
			overflow: hidden;
			border-radius: 60rem;
			background-color: rgb(0,0,0,0.8);

			.popup-local-head {
				@include hidden;
			}

			.popup-local-body {
				margin: 24rem 0 24rem 0;
				padding-right: $container-gap;
				padding-left: $container-gap;

				.popup-content {
					margin-left: 16rem;
					margin-right: 8rem;

					.info-board {
						.board-body {
							margin-top: 0;
							
							.goods-display.module-a.type-a {
								.goods-inform {
									width: 90%;
									flex-wrap: wrap;
									flex-direction: column;
    								align-items: flex-start;
									text-align: left;
									.goods-head {
										flex: 0;
										width: 90%;
										.goods-subject {
											width: 100%;
											.goods-name {
												width: 100%;
												color: color(base, 000);
												@include font((outline: body, size: small-1x, weight: regular)...);
											}
										}										
									}
									.goods-price {
										.data-item.sales {
											display: flex;
											gap: 6rem;
											align-items: center;
											.body {
												color: color(base, 000);
											}
										}
									}
								}
							}
							.para {
								color: color(base, 000);
								@include font((outline: body, size: small-1x, weight: regular)...);
							}

							.chat-display.module-c {
								.chat-list {
									.chat-item {
										padding: 0;
										.chat-wrap {
											.chat-inform {
												.chat-head {
													.chat-subject {
														color: color(base, 000);
														.chat-name,
														.goods-name {
															color: color(base, 000);
														}
													}
												}
												.chat-info {
													.data-list {
														.data-item {
															&.name {
																color: color(base, 000);
															}
														}
													}
												}
												.chat-body {
													margin-bottom: 0;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.popup-local-func {
				display: flex;
				align-items: center;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 16rem;
		
				.button-area {
					display: block;
					
					.btn.popup-close {
						width: 24rem;
						height: 24rem;

						svg {
							fill: color(base, 000);
							width: 20rem;
							height: 20rem;
						}
					}
				}
			}
		}

		&+.popup-page-body {

		}
	}

	@include respond-below(tablet) {
		bottom: 56rem;
		.popup-page-body {
			width: 90%;
		}
	}
}

/**
 * @name		지도/실거래가
 * @update		2022-05-04
 */
.info-popup#tradePriceGuide {
	.board-body {
		.wbr {
			display: block;
		}
	}
}

/**
 * @type		component
 * @name		front-popup
 * @moudle		module-a
 * @update		2022-06-20
 * @name		signUp, 회원가입
 */
.front-popup#signUp {
	.popup-content-body {
		display: flex;
		flex-direction: column;
		row-gap: 40rem;
		margin: 40rem 0 80rem 0;
	}
}



/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2022-04-11
 * @name		myHouseInfo, 우리집 정보 등록하기
 */
.content-popup#reportPosts,
.content-popup#changeMobileNumber,
.content-popup#changeNickname,
.content-popup#myHouseInfo,
.content-popup#changePassword,
.content-popup#registerInterestComplex,
.content-popup {
	.popup-content-body {
		// display: flex;
		// flex-direction: column;
		// row-gap: 20rem;
		// padding: 32rem 20rem;
	}
}

.content-popup {
	.popup-content-body {
		>.info-board.module-a.style-a.type-c {
			margin: 32rem 0;
		}

		>.submit-form.module-a {
			margin: 32rem 0;
			
			.form-area {
				min-height: auto;
			}
		}

		>.goods-display.module-c {
			margin: 32rem 0;
		}

		>.tab-display.module-a {
			margin: 8rem 0;

			~.submit-form.module-a {
				margin-top: 0;
			}
		}

		.docs-policy {
			.section-head {
				margin: 32rem 0;
				@include font((outline: body, size: small-1x)...);
			}
		
			.subsection-head {
				margin: 32rem 0 16rem 0;
				@include font((outline: body, size: small-1x)...);
			}
		
			.subsection-body {
				margin: 16rem 0;
				@include font((outline: body, size: small-2x)...);
				color: color(normal, 010);
			}
		
			.attr-inddent {
				padding-left: 16rem;
			}
		
			.data-table {
				margin: 16rem 0;
			}
		}
	}
}


/**
 * @type		component
 * @name		front-popup
 * @moudle		module-a
 * @update		2022-08-08
 * @name		postsWriteHelpDawin, 구해줘 다윈 등록하기
 */
.front-popup#postsWriteHelpDawin {
	.info-board.consult-request {
		.board-wrap {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 8rem;
			padding: 24rem;
			border-radius: 4rem;
			background-color: color(base, 005);
			
			.board-head {
				text-align: center;

				.board-summary {
					margin: 0;
					@include font((outline: body, size: small-1x)...);
					color: color(normal, 010);
				}
			}
		}
	}
}


/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2022-08-08
 * @name		postsWriterInfo, 글쓴이 정보
 */
.content-popup#postsWriterInfo {
	.popup-content-body {

		.info-board.user-info {
			position: relative;
			left: -20rem;
			padding: 40rem 20rem;
			width: calc(100% + 40rem);
			box-sizing: border-box;
			border-bottom: 1rem solid color(base, 010);

			.board-wrap {
				.board-head {
					display: flex;
					flex-direction: column;
					gap: 8rem;

					.board-subject {
						margin: 0;
						@include font((outline: head, size: small, weight: medium)...);
						color: color(normal, 010);
						text-align: center;
					}
				}

				.board-data {
					margin-top: 16rem;

					.data-list {
						margin: 0;

						.data-item.grade {
							.head {
								@include hidden;
							}

							.body {
								@include gradeAV(28rem);

								.point {
									display: block;
									margin-left: 8rem;
									@include font((outline: head, size: small)...);
									color: color(normal, 010);
							
									&~.count {
										margin-left: 4rem;
									}
								}
							
								.count {
									display: block;
									@include font((outline: head, size: small-2x)...);
									color: color(normal, 010);
								}
							}
						}
					}
				}

				.board-util {
					margin-top: 24rem;
				}
			}
		}

		.posts-display {
			>.button-display {
				margin: 24rem 0;
			}
		}
	}
}


.front-popup#postsRead {
	.popup-content {
		display: block;
	}
}


.content-popup#brokerInfo {
	.popup-content-body {
		.goods-inform {
			gap: 8rem;
			.goods-info {
				.data-list {
					gap: 6rem;
					margin-bottom: 4rem;
					.data-item {
						.body {
							@include font((outline: head, size: small, weight: medium)...);
							color: color(normal, 010);
							text-align: center;
						}
						&.grade {
							.head {
								@include hidden;
							}
		
							.body {
								@include gradeAV(28rem);
		
								.point {
									display: block;
									margin-left: 8rem;
									@include font((outline: head, size: small)...);
									color: color(normal, 010);
									
									&~.count {
										margin-left: 4rem;
									}
								}
									
								.count {
									display: block;
									@include font((outline: head, size: small-2x)...);
									color: color(normal, 010);
								}
							}
						}
						&.brokerinfo {
							.body {
								display: flex;
								gap: 8rem;
								.mark-text {
									.mark {
										strong {
											margin-left: 4rem;
										}
									}
								}
							}
						}
					}
				}
			}
			.goods-util {
				.button-area {
					justify-content: center;
				}
			}
		}

	}
}

.front-popup#finddawinBroker {
	.popup-local-body {
		overflow-y:hidden;
	}
	.popup-content {
		padding: 0;
		display:flex;
		height:100%; 
		.popup-content-body {
			margin: 0;
			overflow-y: hidden;
			height: 100%;
			flex: 100%;
			display: flex;
			flex-direction: column;
			padding: 0;
			.submit-form.module-a {
				padding: 20rem;
			}
			.tab-display {
				padding: 8rem 20rem;
				margin: 0;
				border-top: 1rem solid color(base, 010);
				border-bottom: 1rem solid color(base, 010);
				
				&.style-b {
					display:flex;
					justify-content: space-between;
				}
				.guide-board {
					&::after {

					}
				}
			}
			.goods-display {
				padding: 20rem;
				margin: 0;
				overflow-y: overlay;

				@at-root html.mobile & {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@at-root html.laptop & {
					@include overflow-scroll-custom(dark, medium, y);
				}
			}
		}
	}
	.map-area {
		max-height: 180rem;
		flex: 1 0 180rem;
	}
}

.front-popup#finddawinBrokerChat {
	.popup-page-body {
		height: 100vh;
	}
	.popup-local-body {
		overflow-y:hidden;
	}
	.popup-content {
		padding: 0;
		display:flex;
		height:100%; 
		.popup-content-body {
			margin: 0;
			overflow-y: hidden;
			height: 100%;
			flex: 100%;
			display: flex;
			flex-direction: column;
			padding: 0;
			.submit-form.module-a {
				padding: 20rem;
				box-shadow: inset 0 -1rem #f2f2f2;

				>.form-list {
					>.form-item {
						>.form-wrap {
							flex-direction: row;
							gap: 12rem;
							.form-func {
								display: inline-flex;
								align-items: center;
								.btn.close {
									width: 24rem;
									height: 24rem;
									position: relative;
									.btn-text {
										visibility: hidden;
										width: 1px;
										height: 1px;
										clip: rect(0 0 0 0);
										clip: rect(0, 0, 0, 0);
										pointer-events: none;
										border: 0;
										margin: -1px;
										padding: 0;
										font-size: 1px;
										line-height: 1px;
										display: block;
										position: absolute;
										overflow: hidden;
									}
									&::after {								
										width: 24rem;
										height: 24rem;
										content: "";
										background-position: center;
										background-repeat: no-repeat;
										background-size: cover;
										display: block;
										position: absolute;
										inset: 0;
										background-image: icon(cancel, color(base, 100));
									}
								}
							}
						}
					}
				}
			}
			.tab-display {
				padding: 8rem 20rem;
				margin: 0;
				box-shadow: inset 0 -1rem #f2f2f2;
				
				&.style-b {
					display:flex;
					justify-content: space-between;
				}
				.tab-util {
					.btn {
						padding-right:0;
						padding-left:0;
						.btn-text {
							@include font((outline: body, size: small-1x, weight: regular)...);
							&::after {
								width: 24rem;
								height: 24rem;
							}
						}
						&.search {
							width: 24rem;
							height: 24rem;
							position: relative;
							margin-left: 8rem;
							.btn-text {
								visibility: hidden;
								width: 1px;
								height: 1px;
								clip: rect(0 0 0 0);
								clip: rect(0, 0, 0, 0);
								pointer-events: none;
								border: 0;
								margin: -1px;
								padding: 0;
								font-size: 1px;
								line-height: 1px;
								display: block;
								position: absolute;
								overflow: hidden;
							}
							&::after {								
								width: 24rem;
    							height: 24rem;
								content: "";
								background-position: center;
								background-repeat: no-repeat;
								background-size: cover;
								display: block;
								position: absolute;
								inset: 0;
								background-image: icon(dawin-search, color(base, 100));
							}
						}
					}
				}
			}
			.goods-display {
				padding: 20rem;
				margin: 0;
				overflow-y: overlay;

				@at-root html.mobile & {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@at-root html.laptop & {
					@include overflow-scroll-custom(dark, medium, y);
				}
				.goods-list {
					.goods-item {
						.goods-wrap {
							.goods-inform {
								.keyword {
									@include font((outline: body, weight: bold)...);
									color: color(accent, 010);
								}
							}
						}
					}
				}
			}
		}
	}
	.map-area {
		max-height: 180rem;
		flex: 1 0 180rem;
	}
}
/**
 * @type		component
 * @name		info-popup
 * @moudle		module-a
 * @update		2022-10-05
 * @name		landlordCertification, 집주인 인증
 */
.info-popup#landlordCertification {
	.popup-local-func {
		display: none;
	}
}



.content-popup#sellingRegisterMoreInfo {

}

/**
 * @type		component
 * @name		design-popup
 * @moudle		module-a
 * @update		2023-02-08
 */

 html.active-design-popup {	
	body {
		overflow: hidden;
	}
}

.design-popup.module-a {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	z-index: 120;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 24rem;
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
	background-color: rgba(0,0,0,0);
	transition: visibility 0.25s ease, opacity 0.25s ease, background-color 0.25s ease;

	&.active {
		visibility: visible;
		pointer-events: auto;
		opacity: 1;
		background-color: rgba(0,0,0,0.4);
	}

	.popup-page-body {
		display: flex;
		position: relative;
		opacity: 1;
		visibility: visible;
		overflow: hidden;
		width: 100%;
		max-width: 400rem;
		border-radius: 8rem;
		box-shadow: 0 4rem 8rem 0 rgba(0,0,0,0.15), 0 1rem 2rem 0 rgba(0,0,0,0.1);
		transition: visibility 0.25s ease, opacity 0.25s ease;
		background-color: color(base, 000);
	
		@include respond-above(tablet) {
			max-height: 640rem;
		}

		.popup-local {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			width: 100%;
			
			.popup-local-head {
				@include hidden;
			}

			.popup-local-func {
				display: flex;
				align-items: center;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				padding: 0 20rem;
				height: 64rem;

				.button-area {
					.btn.popup-close {

						&.style-a {
							svg {
								fill: color(base, 100);
							}
						}
						&.style-b {
							svg {
								fill: color(base, 000);
							}
						}
					}
				}
			}
	
			.popup-local-body {
				flex: 1 1 100%;
				// overflow: hidden;
				// display: flex;
				// flex-direction: column;

				@at-root html.mobile & {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@at-root html.laptop & {
					@include overflow-scroll-custom(dark, medium, y);
				}
				
				
				.popup-content {
					flex: 1 1 100%;
					padding: 0;
					// display: flex;
					// flex-direction: column;

					.popup-content-body {
						// flex: 1 1 100%;
						// height: 100%;
						margin: 0;

						.section.module-a {
							margin: 40rem 0;

							.section-wrap {
								.section-head {
									.section-subject {
										display: flex;
										gap: 4rem;
										margin: 0;
										@include font((outline: body, size: small-1x, weight: bold)...);
										color: color(normal, 010);

										.section-name {
											display: block;
										}

										.section-data {
											@include font((weight: regular)...);
										}
									}
								}
							}
						}
					}
					
					.popup-content-util {
						margin: 24rem 0;

						.button-display {
							.button-area {
								.btn {
									border-radius: 4rem;
								}
							}
						}
					}
				}
			}

			.popup-local-util {
				flex-shrink: 0;
				position: relative;
				padding: 24rem 20rem;
				box-shadow: 0 -1rem 0 0 color(base, 005);

				.button-display {
					.button-area {
						.btn {
							border-radius: 4rem;
						}
					}
				}
			}
		}
	}
}

/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2023-04-05
 * @name		academyPayment, 실무교육아카데이신청하기 정보
 */

.content-popup {
	.info-board.application-info {
		.board-body {
			gap:16rem;

			.data-display {
				.data-list {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 24rem;
					border-radius: 4rem;
					background-color: color(base, 005);
					gap: 4rem;

					.data-wrap {
						gap: 4rem;					
					}
				}
				.data-note {
					margin-top: 16rem;
					@include font((outline: body, size: small-2x)...);
					color: color(normal, 030);
				}
			}
		}
	}	
}

.btn.module-b:not(:disabled).social-01.type-fill.symbol-ltr-brand-kakao {
	color: #191919;

	.btn-text {
		&::before {
			background: #191919;
		}
	}
}


/**
 * @type		component
 * @name		info-popup
 * @moudle		module-a
 * @update		2023-04-05
 * @name		academyPaymentComplete, 실무교육아카데미결제완료
 */
.info-popup {
	.info-board.application-info {
		.board-body {
			gap:16rem;

			.data-display {
				.data-list {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 24rem;
					border-radius: 4rem;
					background-color: color(base, 005);
					margin-top:16rem;

					.data-wrap {
						gap:4rem;		

						.data-head,
						.data-body {
							@include font((outline: body, size: small-1x)...);
						}			
					}
				}
				.data-note {
					margin-top: 16rem;
					@include font((outline: body, size: small-2x)...);
					color: color(normal, 030);
				}
			}
		}
	}
}


/**
 * @type		component
 * @name		front-popup
 * @moudle		module-a
 * @update		2023-05-04
 * @name		loginBroker, 중개사로그인 (이메일)
 */
.front-popup#loginBroker {
	.tab-display.module-a {
		margin-bottom:24rem;
	}
	.submit-form.module-a {
    	.form-list {
			.form-item {
				.form-wrap {
					.form-body {
						gap: 16rem;
					}
				}
			}
		}
	}
	.button-display.module-a {
		margin-top: 16rem;
		margin-bottom: 24rem;

		.button-area {
			align-items: center;
			gap: 12rem;
			display: flex;

			.btn-text {

			}
		}
	}
}

/**
 * @type		component
 * @name		front-popup
 * @moudle		module-a
 * @update		2023-05-04
 * @name		loginBrokerphone, 중개사로그인 (전화번호)
 */
 .front-popup#loginBrokerphone {
	.tab-display.module-a {
		margin-bottom:24rem;
	}
	.submit-form.module-a {
    	.form-list {
			.form-item {
				.form-wrap {
					.form-body {
						gap: 16rem;
					}
				}
			}
		}
	}
	.button-display.module-a {
		margin-top: 16rem;
		margin-bottom: 24rem;

		.button-area {
			align-items: center;
			gap: 12rem;
			display: flex;

			.btn-text {

			}
		}
	}
}

/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2023-09-01
 * @name		Calculator, 중개수수료계산기
 */
 .content-popup#Calculator {
	.popup-page-body {
		height: 100vh;
		max-height:760rem;
	}
	.popup-local-body {
		overflow-y:hidden;
	}
	.popup-content {
		display:flex;
		height:100%; 
		padding: 0;

		.popup-content-body {
			overflow-y: hidden;
			flex: 100%;
			display: flex;
			flex-direction: column;
			margin: 24rem 0;

			.submit-form.module-a {
				gap: 16rem;
				overflow-y: overlay;
				@at-root html.mobile & {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@at-root html.laptop & {
					@include overflow-scroll-custom(dark, medium, y);
				}
				margin: 0;
				padding: 0 20rem;

				.form-list {
					gap: 16rem;
				}
				
			}
			.tab-display {
				margin: 16rem 20rem 0;
				.tab-text {
					flex-direction: column;
					em.small-2x {
						font-size: 14rem;
					}
				}
			}
			.info-board.calculation-result {
				margin: 0;
				padding: 20rem;
				// box-shadow: 0 -1rem #e6e6e6;

				.board-wrap {
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 4rem;
					padding: 16rem 24rem;
					height: 148rem;
					border: 1rem solid #D4D8DD;
					border-radius: 4rem;
					background-color: #F1F2F4;

					.board-head {
						text-align: center;

						.board-summary {
							margin: 0;
							@include font((outline: body, size: small-1x)...);
							color: color(normal, 010);
							.em.large {
								line-height: 32rem;
							}							
						}
					}
				}
				+.option-display {
					padding: 0 20rem;
					margin-bottom: 8rem;
				}
			}
			.guide-board {
				padding: 0 20rem;
			}
		}
	}
}

/**
 * @type		component
 * @name		front-popup
 * @moudle		module-a
 * @update		2023-09-30
 * @name		brokerReview, 중개사리뷰등록
 */
 .front-popup#brokerReview {
	.popup-content-body {
		gap: 40rem;
		.goods-display {
			margin: 0 0 40rem 0;
			padding-bottom: 24rem;
			border-bottom: 1rem solid color(base, 010)
		}
		.submit-form.module-a {
			.form-body {
				justify-content: flex-start;
				.form-area {
					.flex {
						flex-direction: column;
						align-items: flex-end;
					}
				}
			}
		}
	}
}

/**
 * @type		component
 * @name		front-popup
 * @moudle		module-a
 * @update		2024-02-15
 * @name		다윈중개사찾기등
 */
 .front-popup#itemVeiwdawinBroker {
	.goods-display.module-c {
		+.button-display.module-a {
			margin-top: 16rem;
		}
	}
	
}

/**
 * @type		component
 * @name		content-popup
 * @moudle		module-a
 * @update		2023-09-30
 * @name		brokerReviewAnswer, 중개사리뷰답변작성
 */
 .content-popup#brokerReviewAnswer {
	.popup-content-body {
		.submit-form.module-a {
			.form-body {
				.info-board {
					.board-wrap {
						background-color: color(normal, 050);
						border-radius: 4rem;
						flex-direction: column;
						justify-content: center;
						gap: 8rem;
						padding: 24rem;
						display: flex;
					}
				}
				.form-area {
					.flex {
						flex-direction: column;
						align-items: flex-end;
					}
				}
			}
		}
	}
}

/**
 * @type		component
 * @name		bottomsheet-popup
 * @moudle		module-a
 * @update		2022-03-27
 */

.bottomsheet-popup.module-a.style-a {
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: start; // 자식 div의 width를 각 content크기만큼
	position: absolute;
	z-index: 10;
	bottom: 0;
	outline: none;

	.popup-page-body {
		// flex: 0 0 100%;
		width: 428px;
    	max-width: 100%;
		
		@include respond-below(tablet) {
			width: 100%;
		}

		.popup-local {
			box-shadow: 0 0 40px rgba(0,0,0,.1);
			overflow: visible;

			@include respond-below(tablet) {
				box-shadow: none;
			}

			.popup-local-head {
				@include hidden;
			}

			.popup-local-body {
				.popup-content-body {
					display: flex;
					flex-direction: column;

					.info-board.type-c {
						padding: 12rem 16rem;
						border-radius: 16rem 16rem 0 0;
						background-color: color(base, 005);
						border-top: 1px solid color(base, 010);
	
						.board-wrap {
							display: flex;
							align-items: center;

							.board-head {
								.board-summary {
									@include font((outline: body, size: small-2x)...);
								}
							}
							.board-util {
								margin-left: auto;

								.button-area {
									// .refresh {
									// 	.btn-text {
									// 		text-indent: -9999rem;
									// 		width: 32rem;
									// 		height: 32rem;
									// 		justify-content: center;
									// 		align-items: center;
									// 		display: flex;
									// 		position: relative;
									// 		border-radius: 32rem;

									// 		&::before {
									// 			content: '';
									// 			justify-content: center;
									// 			align-items: center;
									// 			display: flex;
									// 			position: absolute;
									// 			top: 0;
									// 			bottom: 0;
									// 			left: 0;
									// 			right: 0;
									// 			border-radius: 32rem;
									// 			background: color(base, 005) icon(refresh, color(base, 040)) no-repeat center/24rem;
									// 		}
									// 	}
									// }
									// .btn {
									// 	.btn-text {
									// 		&::before {
									// 			content: '';
									// 			background: icon(list, color(base, 050)) no-repeat center/24rem;
									// 			width: 24rem;
									// 			height: 24rem;
									// 		}
									// 	}
									// }
								}
							}
						}
					}

					.goods-display.module-c.type-a {
						background-color: #fff;
						border-bottom: 1px solid #efefef;
						padding: 16rem;

						.goods-list {
							.goods-item .goods-wrap {
								.goods-figure .goods-thumbnail {
									width: 52rem;
									height: 52rem;
								}
								.goods-inform {
									.goods-head {
										order: 2;
									}
									.goods-info {
										order: 1;
									}
								}
							}
						}
					}
				}	
			}

			.popup-local-func {
				display: flex;
				align-items: center;
				position: absolute;
				top: 8rem;
				right: 8rem;
		
				.button-area {
					display: block;
					
					.btn.popup-close {
						width: 24rem;
						height: 24rem;

						svg {
							fill: color(base, 100);
							width: 20rem;
							height: 20rem;
						}
					}
				}
			}
		}
	}
}

/**
 * @type		component
 * @name		design-popup
 * @moudle		module-a
 * @update		2024-03-18
 * @name		guideBrokerMembership, 중개사멤버십가이드팝업
 */
	
 .design-popup#guideBrokerMembership {
	.popup-content-body {
		position: relative;
		img {
			margin-bottom: -5rem;
		}
		form {
			position: absolute;
			bottom: 20rem;
			left: 20rem;
			.form-text {
				color: color(base, 010);
			}
		}
	}
}
