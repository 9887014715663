@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-promotion {
	#wrap {
		display: flex;
		flex-direction: column;
	}
	
	.page-body {
		#local {
			.local-util {
				// position:fixed;
				// left:0;
				// right:0;
				// bottom:0;
				// height:80rem;
				// background-color: color(accent, 010);
				display: none;
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
						// .btn {
						// 	height: 80rem;
						// 	line-height: 80rem;
						// }
					}
				}
			}
		}
	}
	
	.content-body {
		.section.module-a {
			.section-wrap {
				display: flex;
				flex-direction: column;
				row-gap: 12rem;
				
				.section-head {
					.section-subject {
						margin: 0;
						@include font((outline: body, size: small-1x, weight: bold)...);
						color: color(normal, 100);
		
						.section-name {
							display: block;
						}
					}
				}
			}
		
			&:not([class*=type-]) {
				.section-wrap {
					.section-head {
						@include hidden;
					}
				}
			}
		}
	}


	#footer,
		.page-foot {
			// display: none;
		}

	
	
	@include respond-below(tablet) {
		.page-head {
			display:none;
		}
		.page-head.type-d {
			@include hidden;
		}
		.page-body {
			display: flex;
			flex-direction: column;
	
			#local {
				display: flex;
				flex-direction: column;
				
				.local-head {
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
	
						+.local-control {
							order: 3;
						}
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
	
							.btn.share::before {
								background-color: color(base, 000);
								background-image: icon(share, color(base, 100));
							}
	
							.btn.search {
								&::before {
									background-color: color(base, 000);
									background-image: icon(dawin-search, color(base, 100));
								}
								&.selected::after {
									content: "";
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									width: 4rem;
									height: 4rem;
									border-radius: 2rem;
									background-color: color(accent, 010);
								}
							}
						}
					}
				}
			
				.local-body {
					padding: 0;
	
					#content {
						margin: 0;
					}
				}
	
				.local-util {
					display: block;
					margin: 0;
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
		}
	
		#page.page-intro {
			flex: 1 1 100%;
			padding: 64rem 0 0 0;
			overflow: hidden;
	
			.page-body {
				flex: 1 1 100%;
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
	
					.local-head {
						position: absolute;
						z-index: 1;
						top: 0;
						right: 0;
						left: 0;
						background-color: color(base, 000);
					}
					.local-body {
						@include most-outer-selector('.mobile') {
							@include overflow-scroll-system(dark, medium, y);
						}
				
						@include most-outer-selector('.laptop') {
							@include overflow-scroll-custom(dark, medium, y);
						}
					}
				}
			}
		}
	
		#page.page-front {
			.page-head {
				display: none;
			}
	
			.page-body {
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
					
					.local-head {
						position: relative;
						z-index: 10;
						background-color: color(base, 000);
					}
				}
	
				.local-body {
					flex: 1 1 100%;

					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
				}
			}
	
		}
		
		#footer,
		.page-foot {
			display: none;
		}

	}
	
	
	@include respond-above(tablet) {
		#page {
			margin: 56rem 0 0 0;
			
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, medium, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, medium, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
	
		}		
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				padding: 0;
	
				.local-head {
					display: none;
					flex-direction: column;
					align-items: center;
					margin: 48rem 0;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large, weight: medium)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0;
				}
			}
		}		
	}
	
	
	.local-body {
		#content {	
			.content-body {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 100%;
			}
		}
	}	
	


	/* 중개사 프로모션 */
	.section {
		.subsection.promotion-chu {
			display:flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.subsection-wrap {
				padding: 108rem 0;
				row-gap: 56rem;
			}

			.subsection-head {
				// padding: 108rem 0 0;
				width: 800rem;
				margin: 0 auto;

				.subsection-subject {
					color: color(normal, 000);
					@include font((outline: body, size: large-1x, weight: bold)...);
				}
				.subsection-summary {
					color: color(normal, 000);
					@include font((outline: body, size: small-1x, weight: bold)...);
				}
			}
			// type none
			&:not([class*=type-]) {
				.subsection-head {
					@include hidden();
				}
				.subsection-body {
					// padding: 108rem 0;
				}
			}
			.subsection-body {
				// padding: 0 0 108rem;
				width: 880rem;
				margin: 0 auto;

				~.subsection-body {
				margin-top: 56rem;
				}
			}
			&.module-a {
				// height:calc(100vh - 56rem);
				position: relative;				
			}

			&.style-a {
				background-color: color(accent, 010);
				background-image: url(../images/@temp/img_promotionchu_bg01.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: bottom center;

				.subsection-wrap {
					padding-top: 24rem;
				}

				.board-wrap {
					.board-head {
						row-gap: 16rem;
						align-items: center;
						.board-caption {
							// width: 400rem;
							// height: 40rem;
							// background-image: url(../images/@temp/img_promotion_text02.png);
							// background-size: contain;	
							// background-repeat: no-repeat;	
							// text-indent: -9999rem;
						}
						// .board-subject {
						// 	width: 640rem;
						// 	height: auto;
						// 	background-image: url(../images/@temp/img_promotion_text01.png);
						// 	background-size: contain;	
						// 	background-repeat: no-repeat;	
							
						// 	strong, span {
						// 		text-indent: -9999rem;
						// 	}
						// }
						.board-side {
							// width: 422rem;
							// height: 60rem;
							// background-image: url(../images/@temp/img_promotion_text03.png);
							// background-size: contain;	
							// background-repeat: no-repeat;	
							// text-indent: -9999rem;
							margin-bottom: 24rem;
							@include hidden;
						}
					}
				}
			}
			&.style-b {
				background-color: color(accent, 010);
				background-image: url(../images/@temp/img_promotionchu_bg02.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: bottom center;
				
				.subsection-head {
					.subsection-summary {
						font-weight: inherit;
						.em {
							@include font((weight: bold)...);
						}
					}
				}
				.subsection-body {
					max-width: 100%;
					width: 100%;
					padding: 0;
				}
			}
			&.style-c {
				// height: 1643rem;
				background-color: color(normal, 000);

				.subsection-wrap {
					position: relative;
					.display-board.module-a.type-c {
						.board-list {
							&.benefit {
								flex-direction: row;
	
								.info-board {
									padding:0;
	
									.board-subject {
										flex-wrap: wrap;
										row-gap: 2rem;
									}
									.board-summary {
										.em {
											color: color(base, 030)
										}
									}
									.board-util {
										position: relative;
										z-index: 1
									}
								}
							}	
							&::after {
								content: none;
							}					
						}
					}	
									
					&::after {
						content: "";
						width: 800rem;
						height: 100%;
						z-index: 0;
						background-image: url(../images/@temp/img_benefit_deco.png);
						background-position: top;
						background-repeat: no-repeat;
						background-size: contain;
						position: absolute;
						top: 0;
						left: -0;
					}			
				}				
			}
			&.style-d {
				background-color: #0153cf;

				.subsection-subject {
					color: color(base, 090);
				}
				.subsection-summary {
					color: color(base, 090);
					text-align: center;
					font-weight: inherit;
				}

				.subsection-body {
					width: 840rem;

					.data-list.style-e {
						gap: 24rem;
	
						.data-item {
							padding: 36rem 24rem;
							align-items: center;
							justify-content: center;		
							
							.data-area {
								flex-direction: column;
								row-gap: 8rem;
								text-align: center;
	
								.data-name {
									color: color(base, 090);
								}
								.data-body {
									display: flex;
									flex-direction: column;
									row-gap: 6rem;
									.data-price {
										@include font((outline: body, size: small, weight: bold)...);
										display: flex;
										flex-direction: column;

										.em {
											@include font((weight: regular)...);
										}
									}
									.data-count {
										@include font((outline: body, size: small-1x)...);
									}
								}								
							}
	
							&.mem-plus {
								background-color: color(accent, 010);
	
								.data-name {
									color: color(base, 000)
								}
								
								.data-price,
								.data-count {
									color: color(base, 000);
									
								}
							}
						}
					}
				}			
				
			}
			&.style-e {
				background-color: color(normal, 060);
				.subsection-wrap {
					padding: 56rem 0;
					.subsection-body {
						padding: 0;
					}
				}
			}	
		}

		.board-util {
			.button-area {
				display: flex;
				justify-content: center;
			}
		}

		.data-list {

			&.type-d {
				.data-item {
					display: flex;
					align-items: center;
					justify-content: center;
				}

			}
		}

		.data-display {
			position: relative;

			&.style-c {
				.data-list {
					column-gap: 24rem;
					row-gap: 24rem;
					flex-wrap: wrap;
				}
			}
		}

		@include respond-below(tablet) {
			.subsection.promotion-chu {	
				width: 100%;
				.subsection-wrap {
					width: 85%;
					padding: 88rem 0;
					gap: 40rem;
				}
				.subsection-head {
					width: 90%;
					text-align: center;
				}
				.subsection-body {
					width: 100%;
				}
				.subsection-util {
					.button-area {
						.btn {
							flex: auto;
						}
					}
				}
				&.style-a {
					.subsection-wrap {
						width:80%;
					}
					.board-wrap {
						.board-head {
							row-gap: 16rem;
							align-items: center;
							.board-side {
								margin-bottom: 24rem;
							}
						}
					}
				}
				&.style-b {
					.subsection-head {
						width: 80%;
					}
				}
				&.style-c {
					.subsection-wrap {
						.display-board.module-a.type-c {
							.board-list {
								&.benefit {
									flex-direction: row;
									flex-wrap: wrap;

									.board-item {
										width: 45%;
										justify-content: space-between;
										.info-board {
											padding:0;
			
											.board-subject {
												flex-wrap: wrap;
												row-gap: 2rem;
											}
											.board-summary {
												.em {
													color: color(base, 030)
												}
											}
											.board-util {
												position: relative;
												z-index: 1
											}
										}
									}	
								}	
								&::after {
									content: none;
								}					
							}
						}	
						&::after {
							width: 800rem;
							background-position: top;
							padding-top: 88rem
						}	
					}		
				}
				
				&.style-d {
					.subsection-body {
						width: 600rem;

						.data-display {
							.data-list {
								&.style-e {
									flex-wrap: wrap;
								}
							}
						}
	
						.post-display {
							.post-item {
								padding:24rem;
							}
						}
					}
				}
			}
		}
	}
	/* 회사소개 프로모션 */
	.section {
		.subsection.promotion-cp {
			display:flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.subsection-wrap {
				padding: 108rem 0;
				row-gap: 56rem;
				width: 100%;
			}

			.subsection-head {
				// padding: 108rem 0 0;
				max-width: 1200rem;
				margin: 0 auto;

				.subsection-subject {
					color: color(normal, 000);
					@include font((outline: body, size: large-1x, weight: bold)...);
				}
				.subsection-summary {
					color: color(normal, 000);
					@include font((outline: body, size: small-1x, weight: bold)...);
				}
			}
			// type none
			&:not([class*=type-]) {
				.subsection-head {
					@include hidden();
				}
				.subsection-body {
					// padding: 108rem 0;
				}
			}
			.subsection-body {
				// padding: 0 0 108rem;
				max-width: 1200rem;
				margin: 0 auto;
				padding: 0 24rem;
				box-sizing: border-box;

				~.subsection-body {
				margin-top: 56rem;
				}

				.info-board {
					display: flex;
					flex-direction: column;
					gap: 40rem;
					.board-wrap {
						.board-head {
							.board-subject {
								@include font((outline: body, size: large-1x, weight: bold)...);
							}
						}
					}
					&.module-c {
						&.type-b {
							max-width: 1200rem;
							margin-bottom: 168rem;
							.board-wrap {
								display: flex;
								gap: 64rem;
								align-items: center;
								.board-figure {
									order: 2;	
									width: 700rem;
									height: auto;
									flex-shrink: 0;
								}
								.board-inform {
									order: 1;
									flex: 100;
									.board-head {
										gap: 48rem;
										.board-subject {
											@include font((outline: body, size: large-3x, weight: bold)...);
											.board-name {
												@include font((outline: body, size: large-3x, weight: bold)...);
											}
										}
										.board-summary {
											@include font((outline: body, size: medium)...);
										}
									}
								}
							}
						}
						&.type-a {
							margin-bottom: 168rem;
							max-width: 1120rem;
							.board-wrap {
								display: flex;
								gap: 64rem;
								align-items: center;
								.board-figure {
									order: 1;	
									width: 700rem;
									height: auto;
									flex-shrink: 0;
								}
								.board-inform {
									order: 2;
									flex: 100;
									.board-head {
										gap: 48rem;
										.board-subject {
											@include font((outline: body, size: large-3x, weight: bold)...);
											.board-name {
												@include font((outline: body, size: large-3x, weight: bold)...);
											}
										}
										.board-summary {
											@include font((outline: body, size: medium)...);
										}
									}
								}
							}
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
			&.module-a {
				// height:calc(100vh - 56rem);
				position: relative;				
			}

			&.style-b {
				background-color: #0093E9;
    			background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
				.subsection-body {
					.info-board {
						max-width: 720rem;
						.board-wrap {
							display: flex;
							flex-direction: column;
							gap: 40rem;
							.board-head {
								display: flex;
								flex-direction: column;
								gap: 32rem;			
								.board-subject {
									order: 2;
								}			
								.board-summary {
									order: 1;
								}		
							}
						}
					}
				}
			}
			.data-display.type-a {
				.board-wrap {
					.data-list {
						display: flex;
						flex-direction: row;
						gap: 48rem;

						.board-head {
							gap: 48rem;
						}
					}
				}
				
			}
			&.headline {
				.subsection-wrap {
					padding: 108rem 0 172rem;
				}
				.subsection-body {
					padding-top: 56rem;
					padding-bottom: 56rem;
				}
				.display-board.module-a.type-a {
					.board-wrap {
						gap: 112rem;
					}
				}
				.subsection-util {
					position: absolute;
					bottom: 108rem;
					left: 50%;
					transform: translatex(-50%);
					z-index: 2;
					// animation: fade-in .2s ease-in;
					&.floating-bar-util {
						z-index: 10;
						position: fixed;
						bottom: 8rem;
						left: 50%;
						transform: translatex(-50%);
						animation: fade-in .8s ease-in;
						// animation: floating-bar-active .8s ease-out forwards;
						.button-area {
							.btn {
								width: 560rem;
							}
						}
					}
				}
			}
			&.calcpee {
				.subsection-body {
					width: 900rem;
					.display-board {
						.board-wrap {
							display: flex;
							flex-direction: column;
							gap: 48rem;
							align-items: center;
							.board-head {
								.board-subject {
									@include font((outline: body, size: large-2x, weight: bold)...);
								}

							}
							.board-body {
								width:100%;
								.data-list {
									display: flex;
									flex-direction: column;
									gap: 24rem;
									align-items: center;
									padding: 56rem 32rem;
									background-color: #f9f9f9;
									border-radius: 16rem;
									.data-item {
										flex: 100;
										.submit-form {
											width: 500rem;
											.form-list {
												.form-item {
													.form-wrap {
														display: flex;
														gap: 16rem;
														.form-head {
															flex-shrink: 0;
															.form-subject {
																height: 56rem;
															}
														}
														.form-body {
															gap: 8rem;
															flex: 100%;
															.form-noti {
																margin-bottom: 8rem;
															}
														}
													}
												}
											}
										}
										.info-board {
											.board-wrap {
												gap: 32rem;
												.board-head {
													display: flex;
													justify-content: center;
													align-items: center;
													gap: 16rem;
													.board-subject {
														order: 2;
														@include font((outline: body, size: large-3x, weight: bold)...);
														color: color(accent, 010);
														display: flex;	
														align-items: center;			

														&:after {
															display: inline-flex;
															content:'';
															width: 30rem;
															height: 56rem;
															background-image: url("data:image/svg+xml,%3Csvg width='20' height='31' viewBox='0 0 20 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.849 0H6.151V16.9082H0L10 30.4348L20 16.9082H13.849V0Z' fill='%23F64E23'/%3E%3C/svg%3E%0A");
															background-repeat: no-repeat;
															background-position: 50%;
															background-size: contain;
															margin-left: 8rem;														
														}
													}
													.board-summary {
														order: 1;
														@include font((outline: body, size: large)...);
													}
												}
												
											}
										}
									}
								}
							}
							.board-util {
								.button-area {
									display: flex;
									gap: 16rem;
								}
							}
						}
					}					
				}
			}
			&.review {
				background-color: #22262B;
				align-items: flex-start;
				.subsection-body {
					max-width: 100%;
					width: 100%;
					.display-board {
						.board-wrap {
							display: flex;
							flex-direction: column;
							gap: 48rem;
							.board-head {	
								display: flex;
								align-items: center;
								justify-content: center;
								.board-subject {
									color: color(base, 000);
									@include font((outline: body, size: large-3x, weight: bold)...);
								}
							}
						}
					}
				}
				.review-display {
					position: relative;
					display: flex;
					margin-top: 40px;
					white-space: nowrap;
					animation: rtl 60s linear infinite;
					animation-play-state: running;
					.review-list {
						flex-direction: row;
						box-sizing: border-box;
						justify-content: flex-start;
						gap: 24rem;
						.review-item {
							background-color: color(base, 000);
							border-radius: 16rem;
							margin-top: 0;
							padding: 24rem;
							width: 300rem;
							height: unset;
							flex-shrink: 0;
							flex-grow: 0;
							.review-wrap {
								background-color: none;
								.review-inform {
									gap: 8rem;
									.review-body {
										.review-content {
											.para {
												@include font((outline: body, size: small-2x)...);
												white-space: wrap;
												word-break: break-all;
												.em {
													position: relative;
													background: linear-gradient(to top, #bfffa1 40%, transparent 40%);													
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			&.recommdservice {
				.display-board {
					.board-wrap {
						display: flex;
						flex-direction: column;
						gap: 48rem;
						.board-head {
							.board-subject {
								display: flex;
								align-items: center;
								justify-content: center;
								text-align: center;
								@include font((outline: body, size: large-3x, weight: bold)...);
							}
						}
						.board-body {
							.data-display {
								&.type-c {
									.data-list {
										display: flex;
										gap: 32rem;
									}
								}
							}
						}
					}
				}
			}			
		}	
		@include respond-below(tablet) {
			.subsection.promotion-cp {
				.subsection-wrap {
					padding: 72rem 0;
					row-gap: 48rem;
					width: 100%;
					.subsection-body {
						padding: 0 24rem;
						max-width: 100%;
						width: 100%;
						margin: 0 auto;
						box-sizing: border-box;

						~.subsection-body {
							margin-top: 48rem;
						}

						.info-board {
							display: flex;
							flex-direction: column;
							gap: 40rem;
							.board-wrap {
								.board-head {
									.board-subject {
										@include font((outline: body, size: large-1x, weight: bold)...);
									}
								}
							}
							&.module-c {
								&.type-b {
									max-width: 100%;
									margin-bottom: 72rem;
									.board-wrap {
										display: flex;
										flex-direction: column;
										gap: 48rem;
										align-items: flex-start;
										.board-figure {
											width: 500rem;
										}
										.board-inform {
											order: 1;
											flex: 100;
											.board-head {
												gap: 24rem;
												.board-subject {
													@include font((outline: body, size: large-2x, weight: bold)...);
													.board-name {
														@include font((outline: body, size: large-2x, weight: bold)...);
													}
												}
												.board-summary {
													@include font((outline: body, size: small)...);
												}
											}
										}
									}
								}
								&.type-a {
									margin-bottom: 72rem;
									max-width: 100%;
									.board-wrap {
										display: flex;
										flex-direction: column;
										gap: 48rem;
										align-items: flex-start;
										.board-figure {
											order: 2;	
											width: 500rem;
										}
										.board-inform {
											order: 1;
											.board-head {
												gap: 48rem;
												.board-subject {
													@include font((outline: body, size: large-2x, weight: bold)...);
													.board-name {
														@include font((outline: body, size: large-2x, weight: bold)...);
													}
												}
												.board-summary {
													@include font((outline: body, size: small)...);
												}
											}
										}
									}
								}
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
						.board-subject {
							.wbr {
								display: block;
							}
						}
					}
				}
				&.headline {
					.subsection-wrap {
					padding: 108rem 0;
					}
					.subsection-body {
						padding-bottom: unset;
					}
					.display-board.module-a.type-a {
						.board-wrap {
							flex-direction: column;
							gap: 32rem;
						}
					}
					.subsection-util {
						@include hidden;
						.floating-bar-util {
							@include hidden;
						}
					}
				}
				&.style-b {
					.subsection-body {
						.info-board {
							.board-wrap {
								.board-head {
									gap: 24rem;
								}
								.board-util {
									@include hidden;
								}
							}
						}
					}
				}
				&.calcpee {
					.subsection-body {
						.display-board {
							.board-wrap {
								align-items: flex-start;
								gap: 24rem;
								.board-head {
									align-items: flex-start;
									.board-subject {
										@include font((outline: body, size: large-2x, weight: bold)...);
									}
								}
								.board-body {
									width:100%;
									.data-list {
										display: flex;
										flex-direction: column;
										gap: 32rem;
										align-items: flex-start;
										padding: 40rem 24rem;
										background-color: #f9f9f9;
										border-radius: 16rem;
										.data-item {
											.submit-form {
												width: 100%;
												.form-list {
													.form-item {
														.form-wrap {
															display: flex;
															flex-direction:column;
															gap: 8rem;
															.form-head {
																.form-subject {
																	height: unset;
																}
															}
															.form-body {
																.form-noti {
																}
															}
														}
													}
												}
											}
											.info-board {
												.board-wrap {
													gap: 32rem;
													.board-head {
														display: flex;
														justify-content: flex-start;
														align-items: center;
														gap: 16rem;
														flex-wrap: wrap;
														.board-subject {
															order: 2;
															@include font((outline: body, size: large-3x, weight: bold)...);
															color: color(accent, 010);
															display: flex;	
															align-items: center;			

															&:after {
																display: inline-flex;
																content:'';
																width: 30rem;
																height: 36rem;
																background-image: url("data:image/svg+xml,%3Csvg width='20' height='31' viewBox='0 0 20 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.849 0H6.151V16.9082H0L10 30.4348L20 16.9082H13.849V0Z' fill='%23F64E23'/%3E%3C/svg%3E%0A");
																background-repeat: no-repeat;
																background-position: 50%;
																background-size: contain;
																margin-left: 8rem;														
															}
														}
														.board-summary {
															order: 1;
															@include font((outline: body, size: large)...);
														}
													}
													
												}
											}
										}
									}
								}
							}
						}
					}
				}
				&.review {
					.subsection-body {
						.display-board {
							.board-wrap {
								.board-head {
									justify-content: flex-start;
									.board-subject {
										@include font((outline: body, size: large-2x, weight: bold)...);
									}
								}
							}
						}
					}
				}
				&.recommdservice {
					.subsection-body {
						.display-board {
							.board-wrap {
								.board-head {
									.board-subject {
										@include font((outline: body, size: large-2x, weight: bold)...);
										text-align:left;
									}
								}
								.board-body {
									.data-display {
										.data-list {
											flex-direction: column;
										}
									}
								}
							}
						}
					}					
				}
			}	
		}
	}

	@keyframes rtl {
		0% {
			transform: translateX(0);
		}
	
		100% {
			transform: translateX(-2680px);
		}
	}
	
	@keyframes ltr {
		0% {
			transform: translateX(-2893px);
		}
	
		100% {
			transform: translateX(0);
		}
	}

	@keyframes floating-bar-hide {
		0% {
			position: fixed;
			bottom: 1.85rem;
			flex-direction: row;
			justify-content: space-between;
			width: 96rem;
			opacity: 1
		}

		to {
			position: fixed;
			bottom: 8rem;
			flex-direction: row;
			justify-content: space-between;
			width: 96rem;
			opacity: 0
		}
	}
	@keyframes floating-bar-active {
		0% {
			opacity: 1
		}

		25% {
			opacity: 0;
			transform: translate3d(0,20%,0)
		}

		30% {
			transform: translateZ(0)
		}

		60% {
			opacity: 0
		}

		to {
			position: fixed;
			bottom: 8rem;
			flex-direction: row;
			justify-content: space-between;
			width: 56rem;
			opacity: 1
		}
	}
	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}


}
