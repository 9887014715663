/*
 * mark system reset
 */
.mark svg {
	display: block;
	position: relative;
	top: 0;
	left: 0;
}

/*
* @ mark module accent
*
* @ $text-color
* @ $symbol
* @ $border-color
* @ $background-color
*/
$mark-module-a-accent: (
	'normal-00': (
		'none' color(base, 100) color(base, 100) transparent transparent,
		'line' color(base, 100) color(base, 100) color(base, 100) color(base, 000),
		'fill' color(base, 000) color(base, 100) color(base, 100) color(base, 100),
	),
	'normal-01': (
		'none' color(base, 100) color(base, 100) transparent transparent,
		'line' color(base, 080) color(base, 080) color(base, 080) color(base, 000),
		'fill' color(base, 000) color(base, 000) color(base, 080) color(base, 080),
	),
	'normal-02': (
		'none' color(base, 070) color(base, 070) transparent transparent,
		'line' color(base, 070) color(base, 070) color(base, 070) color(base, 000),
		'fill' color(base, 000) color(base, 000) color(base, 070) color(base, 070),
	),
	'normal-03': (
		'none' color(base, 060) color(base, 060) transparent transparent,
		'line' color(base, 040) color(base, 040) color(base, 030) color(base, 000),
		'fill' color(base, 000) color(base, 000) color(base, 030) color(base, 030),
	),
	'normal-04': (
		'none' color(base, 040) color(base, 040) transparent transparent,
		'line' color(base, 040) color(base, 040) color(base, 010) color(base, 000),
		'fill' color(base, 050) color(base, 050) color(base, 010) color(base, 010),
	),
	'normal-05': (
		'none' color(base, 040) color(base, 040) transparent transparent,
		'line' color(base, 040) color(base, 040) color(base, 005) color(base, 000),
		'fill' color(base, 040) color(base, 040) color(base, 005) color(base, 005),
	),
	'normal-10': (
		'none' color(base, 080) color(base, 080) transparent transparent,
		'line' color(base, 080) color(base, 080) color(base, 000) color(base, 000),
		'fill' color(base, 080) color(base, 080) color(base, 000) color(base, 000),
	),
	'normal-11': (
		'none' color(base, 080) color(base, 080) transparent transparent,
		'line' color(base, 080) color(base, 080) color(base, 000) color(base, 000),
		'fill' color(base, 080) color(base, 080) color(base, 010) color(base, 010),
	),
	'accent-01': (
		'none' color(accent, 010) color(base, 010) transparent transparent,
		'line' color(accent, 010) color(base, 010) color(accent, 010) color(accent, 000),
		'fill' color(accent, 000) color(base, 000) color(accent, 010) color(accent, 010),
	),
	'accent-02': (
		'none' color(accent, 020) color(base, 020) transparent transparent,
		'line' color(accent, 020) color(base, 020) color(accent, 020) color(accent, 000),
		'fill' color(accent, 000) color(base, 000) color(accent, 020) color(accent, 020),
	),
	'accent-03': (
		'none' color(accent, 030) color(base, 030) transparent transparent,
		'line' color(accent, 030) color(base, 030) color(accent, 030) color(accent, 000),
		'fill' color(accent, 000) color(base, 000) color(accent, 030) color(accent, 030),
	),
	'accent-04': (
		'none' color(accent, 040) color(base, 040) transparent transparent,
		'line' color(accent, 040) color(base, 040) color(accent, 040) color(accent, 000),
		'fill' color(accent, 000) color(base, 000) color(accent, 040) color(accent, 040),
	),
	'accent-05': (
		'none' color(accent, 040) color(base, 040) transparent transparent,
		'line' color(accent, 040) color(base, 040) color(accent, 050) color(accent, 000),
		'fill' color(accent, 000) color(base, 000) color(accent, 050) color(accent, 050),
	),
	'primary-01': (
		'none' color(accent, 010) color(base, 010) transparent transparent,
		'line' color(accent, 010) color(base, 010) color(accent, 010) color(accent, 000),
		'fill' color(primary, 060) color(primary, 010) color(primary, 010) color(primary, 010),
	),
	'attr-distressed-sale': (
		'none' #0e5ed6 #f8c513 transparent transparent,
		'line' #0e5ed6 #f8c513 #0e5ed6 #ffffff,
		'fill' #ffffff #f8c513 #0e5ed6 #0e5ed6,
	)
	
);

/*
 * mark module a outline
 *
 * @ $height
 * @ $line-height
 * @ $font-size
 * @ $font-weight
 * @ $border-width
 */
$mark-module-a-outline: (
	'small-3x'		18rem 14rem 1.5 400 1rem,
	'small-2x'		20rem 16rem 1.5 400 1rem,
	'small-1x'		24rem 18rem 1.5 400 1rem,
	'small'			28rem 20rem 1.5 400 1rem,
	'medium'		32rem 20rem 1.5 400 1rem,
);


.mark.module-a {
	// general
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@include font((family: default)...);
	box-sizing: border-box;
	border-style: solid;

	.mark-text {
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		text-align: center;
	}
	
	// icon
	// 1. 아이콘의 유무는 특정 클래스명으로 한다.
	// 2. 아이콘의 위치는 특정 클래스명으로 한다.
	// 3. 아이콘의 종류는 지정한 아이콘셋(클래스명)으로 한다.
	// 4. 아이콘의 사이즈는 outline별로 달라야 한다.
	//		1) 행간에 맞춘다.
	//		2) 별도의 사이즈를 지정한다.
	// 5. 무조건 중앙에 또는 상단 기준을 택일
	// 6. accent에 따라 컬러를 다르게 한다.
	
	&[class*=symbol-ltr-] .mark-text::before,
	&[class*=symbol-rtl-] .mark-text::after {
		content: '';
		display: block;
		mask-repeat: no-repeat;
		mask-size: cover;
	}

	// accent
	@each $accent-name in $mark-module-a-accent {
		&.#{nth($accent-name, 1)} {
			// @debug "divider offset: #{nth($accent-name, 1)}";
			@each $type-name, $color, $symbol-color, $border-color, $background-color in map-get($mark-module-a-accent, nth($accent-name, 1)) {
				&.type-#{$type-name} {
					color: $color;
					border-color: $border-color;
					background-color: $background-color;

					&[class*=symbol-ltr-] .mark-text::before,
					&[class*=symbol-rtl-] .mark-text::after {
						content: '';
						background-color: $symbol-color;
					}
				}
			}
		}
	}

	// outline
	@each $name, $height, $line-height, $font-size, $font-weight, $border-width in $mark-module-a-outline {
		&.#{$name} {
			padding-right: calc($height / 3);
			padding-left: calc($height / 3);
			height: $height;

			line-height: $line-height;
			font-weight: $font-weight;
			font-size: calc($line-height / $font-size);
			border-width: $border-width;

			&.style-b {
				border-radius: $height * 0.2;
			}

			&.style-c {
				border-radius: $height * 0.5;
			}

			// icon
			&[class*=symbol-ltr-] .mark-text::before,
			&[class*=symbol-rtl-] .mark-text::after {
				width: $line-height;
				min-width: $line-height;
				height: $line-height;
			}

			
			&[class*=symbol-ltr-] {
				padding-left: ($height * 0.5) - ($line-height * 0.25);
				.mark-text {
					&::before {
						margin-right: $line-height * 0.1;
					}
				}
			}

			&[class*=symbol-rtl-] {
				padding-right: ($height * 0.5) - ($line-height * 0.25);
				.mark-text {
					&::after {
						margin-left: $line-height * 0.1;
					}
				}
			}
		}
	}
}


$mark-module-b-accent: (
	'normal-00': (
		'none' color(base, 100) transparent transparent,
		'line' color(base, 100) color(base, 100) color(base, 000),
		'fill' color(base, 000) color(base, 100) color(base, 100),
	),
	'normal-01': (
		'none' color(base, 100) transparent transparent,
		'line' color(base, 80) color(base, 80) color(base, 000),
		'fill' color(base, 000) color(base, 80) color(base, 80),
	),
	'normal-02': (
		'none' color(base, 070) transparent transparent,
		'line' color(base, 070) color(base, 070) color(base, 000),
		'fill' color(base, 000) color(base, 070) color(base, 070),
	),
	'normal-03': (
		'none' color(base, 060) transparent transparent,
		'line' color(base, 040) color(base, 030) color(base, 000),
		'fill' color(base, 000) color(base, 030) color(base, 030),
	),
	'normal-04': (
		'none' color(base, 040) transparent transparent,
		'line' color(base, 040) color(base, 010) color(base, 000),
		'fill' color(base, 050) color(base, 010) color(base, 010),
	),
	'normal-05': (
		'none' color(base, 040) color(base, 040) transparent transparent,
		'line' color(base, 040) color(base, 040) color(base, 005) color(base, 000),
		'fill' color(base, 040) color(base, 040) color(base, 005) color(base, 005),
	),	
	'accent-01': (
		'none' color(accent, 010) transparent transparent,
		'line' color(accent, 010) color(accent, 010) color(accent, 000),
		'fill' color(accent, 000) color(accent, 010) color(accent, 010),
	),
	'accent-02': (
		'none' color(accent, 020) transparent transparent,
		'line' color(accent, 020) color(accent, 020) color(accent, 000),
		'fill' color(accent, 000) color(accent, 020) color(accent, 020),
	),
	'accent-03': (
		'none' color(accent, 030) transparent transparent,
		'line' color(accent, 030) color(accent, 030) color(accent, 000),
		'fill' color(accent, 000) color(accent, 030) color(accent, 030),
	),
	'accent-04': (
		'none' color(accent, 040) transparent transparent,
		'line' color(accent, 040) color(accent, 040) color(accent, 000),
		'fill' color(accent, 000) color(accent, 040) color(accent, 040),
	),
	'etc-01': (
		'none' color(etc, 020) transparent transparent,
		'line' color(etc, 020) color(etc, 020) color(normal, 000),
		'linenone' color(etc, 020) color(etc, 020) transparent,
		'fill' color(normal, 000) color(etc, 020) color(etc, 020),
	),
);

/*
 * mark module b outline
 *
 * @ $height
 * @ $line-height
 * @ $font-size
 * @ $font-weight
 * @ $border-width
 */
 $mark-module-b-outline: (
	'small-3x'		18rem 16rem 1.5 700 1rem,
	'small-2x'		20rem 16rem 1.5 700 1rem,
	'small-1x'		24rem 18rem 1.5 700 1rem,
	'small'			28rem 20rem 1.5 700 1rem,
	'medium'		32rem 24rem 1.5 700 1rem,
);


.mark.module-b {
	// general
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@include font((family: default)...);
	box-sizing: border-box;
	border-style: solid;

	.mark-text {
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		text-align: center;
	}

	&[class*=symbol-ltr-] .mark-text::before,
	&[class*=symbol-rtl-] .mark-text::after {
		content: '';
		display: block;
		mask-repeat: no-repeat;
		mask-size: cover;

		// @each $icon-name, $icon in $symbol-list {
		// 	&.symbol-ltr-#{$icon-name} {.mask-text {&::before {background-image: icon($icon-name, $color);}}}
		// 	&.symbol-rtl-#{$icon-name} {.mask-text {&::after {background-image: icon($icon-name, $color);}}}
		// }
	}

	// accent
	@each $accent-name in $mark-module-b-accent {
		&.#{nth($accent-name, 1)} {
			@each $type-name, $color, $border-color, $background-color in map-get($mark-module-b-accent, nth($accent-name, 1)) {
				&.type-#{$type-name} {
					color: $color;
					border-color: $border-color;
					background-color: $background-color;

					&[class*=symbol-ltr-] .mark-text::before,
					&[class*=symbol-rtl-] .mark-text::after {
						content: '';
						background-color: $color;
					}
				}
			}
		}
	}

	// outline
	@each $name, $height, $line-height, $font-size, $font-weight, $border-width in $mark-module-b-outline {
		&.#{$name} {
			padding-right: calc($height / 3);
			padding-left: calc($height / 3);
			height: $height;

			line-height: $line-height;
			font-weight: $font-weight;
			font-size: calc($line-height / $font-size);
			border-width: $border-width;

			&.style-b {
				border-radius: $height * 0.2;
			}

			&.style-c {
				border-radius: $height * 0.5;
			}

			// icon
			&[class*=symbol-ltr-] .mark-text::before,
			&[class*=symbol-rtl-] .mark-text::after {
				width: $line-height;
				min-width: $line-height;
				height: $line-height;
			}

			
			&[class*=symbol-ltr-] {
				padding-left: ($height * 0.5) - ($line-height * 0.25);
				.mark-text {
					&::before {
						margin-right: $line-height * 0.1;
					}
				}
			}

			&[class*=symbol-rtl-] {
				padding-right: ($height * 0.5) - ($line-height * 0.25);
				.mark-text {
					&::after {
						margin-left: $line-height * 0.1;
					}
				}
			}
		}
	}
}

/*
 * mark module c
 */
.mark.module-c {
	display: flex;
	align-items: center;

	&::after {
		content: '';
		display: block;
		width: 12rem;
		height: 20rem;
		mask-repeat: no-repeat;
		mask-position: right center;
		mask-size: auto 100%;
		mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 240 24'%3E %3Cpath fill='none' d='M0,0h24v24H0V0z'/%3E %3Cpath fill='rgba(0,0,0,1)' d='M236.4,9l-5-6.2C230,1,227.9,0,225.6,0H0v24h225.6c2.3,0,4.4-1,5.8-2.8l5-6.2C237.8,13.2,237.8,10.8,236.4,9zM229.2,14.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4C231.6,13.3,230.5,14.4,229.2,14.4z'/%3E%3C/svg%3E");
	}
	
	.mark-text {
		display: flex;
		align-items: center;
		height: 20rem;
		padding: 0 6rem 0 4rem;
		border-top-left-radius: 3rem;
		border-bottom-left-radius: 3rem;
		color: color(normal, 000);
		@include font((outline: body, size: small-2x, weight: medium)...);
	}

	&.normal-03 {
		.mark-text {background-color: color(normal, 030);}
		&::after {background-color: color(normal, 030);}
	}
	
	&.accent-01 {
		.mark-text {background-color: color(accent, 010);}
		&::after {background-color: color(accent, 010);}
	}

	&.accent-02 {
		.mark-text {background-color: color(accent, 060);}
		&::after {background-color: color(accent, 060);}
	}

	&.accent-03 {
		.mark-text {background-color: color(accent, 070);}
		&::after {background-color: color(accent, 070);}
	}
}


$mark-module-d-accent: (
	'normal-00': (
		'none' color(base, 100) transparent transparent,
		'line' color(base, 100) color(base, 100) color(base, 000),
		'fill' color(base, 000) color(base, 100) color(base, 100),
	),
	'normal-01': (
		'none' color(base, 100) transparent transparent,
		'line' color(base, 80) color(base, 80) color(base, 000),
		'fill' color(base, 000) color(base, 80) color(base, 80),
	),
	'normal-02': (
		'none' color(base, 070) transparent transparent,
		'line' color(base, 070) color(base, 070) color(base, 000),
		'fill' color(base, 000) color(base, 070) color(base, 070),
	),
	'normal-03': (
		'none' color(base, 060) transparent transparent,
		'line' color(base, 040) color(base, 030) color(base, 000),
		'fill' color(base, 000) color(base, 030) color(base, 030),
	),
	'normal-04': (
		'none' color(base, 040) transparent transparent,
		'line' color(base, 040) color(base, 010) color(base, 000),
		'fill' color(base, 050) color(base, 010) color(base, 010),
	),
	'normal-05': (
		'none' color(base, 040) color(base, 010) transparent transparent,
		'line' color(base, 040) color(base, 010) color(base, 005) color(base, 000),
		'fill' color(base, 040) color(base, 005) color(base, 005) color(base, 005),
	),
	'normal-06': (
		'none' color(base, 100) transparent transparent,
		'line' color(base, 80) color(base, 80) color(base, 000),
		'fill' color(base, 000) color(etc, 040) color(etc, 040),
	),
	'accent-01': (
		'none' color(accent, 010) transparent transparent,
		'line' color(accent, 010) color(primary, 010) color(primary, 000),
		'fill' color(accent, 010) color(primary, 005) color(primary, 005),
	),
	'accent-02': (
		'none' color(accent, 020) transparent transparent,
		'line' color(accent, 020) color(secondary, 010) color(secondary, 000),
		'fill' color(accent, 020) color(secondary, 005) color(secondary, 005),
	),
	'accent-03': (
		'none' color(accent, 030) transparent transparent,
		'line' color(accent, 030) #E6F7F1 color(accent, 000),
		'fill' color(accent, 030) color(tertiary, 005) color(tertiary, 005),
	),
	'accent-04': (
		'none' color(accent, 010) transparent transparent,
		'line' color(accent, 010) #E6F7F1 color(accent, 010),
		'fill' color(base, 010) color(accent, 010) color(accent, 010),
	),
	'accent-05': (
		'none' color(accent, 050) transparent transparent,
		'line' color(accent, 050) #E6F7F1 color(accent, 050),
		'fill' color(base, 010) color(accent, 050) color(accent, 050),
	),
	'accent-06': (
		'none' color(accent, 010) transparent transparent,
		'line' color(accent, 010) #E6F7F1 color(accent, 050),
		'fill' color(base, 000) color(accent, 010) color(accent, 010),
	),
	'accent-07': (
		'none' #008aff transparent transparent,
		'line' #bae0ff #eef8ff #008aff,
		'fill' #008aff #bae0ff #e8f5ff,
	),
	'accent-08': (
		'none' color(accent, 030) transparent transparent,
		'line' #bae0ff #eef8ff #008aff,
		'fill' #42526e #d1d1d1 #e9e9e9,
	),
	'accent-09': (
		'none' color(accent, 030) transparent transparent,
		'line' #bae0ff #eef8ff #008aff,
		'fill' #fff #001dee #001dee,
	),
	'accent-10': (
		'none' color(accent, 030) transparent transparent,
		'line' #bae0ff #eef8ff #008aff,
		'fill' #fff #ff0000 #ff0000,
	),
	'accent-11': (
		'none' #008aff transparent transparent,
		'line' #bae0ff #eef8ff #008aff,
		'fill' #fff #4a8eff #4a8eff,
	),
	'accent-12': (
		'none' color(accent, 030) transparent transparent,
		'line' #F0F4FF #F0F4FF #2257DF,
		'fill' #2257DF #F0F4FF #F0F4FF,
	),
	'accent-13': (
		'none' color(accent, 030) transparent transparent,
		'line' #4D515C #4D515C #F2F2F2,
		'fill' #F2F2F2 #4D515C #4D515C,
	),
	'etc-01': (
		'none' color(etc, 020) transparent transparent,
		'line' color(etc, 020) color(etc, 020) transparent,
		'linenone' color(etc, 020) color(etc, 020) transparent,
		'fill' color(normal, 000) color(etc, 020) color(etc, 020),
	),
	'etc-02': (
		'none' color(etc, 030) transparent transparent,
		'line' color(etc, 030) color(etc, 030) transparent,
		'linenone' color(etc, 030) color(etc, 030) transparent,
		'fill' color(normal, 000) color(etc, 030) color(etc, 030),
	),
);

/*
 * mark module d outline
 *
 * @ $height
 * @ $line-height
 * @ $font-size
 * @ $font-weight
 * @ $border-width
 */
 $mark-module-d-outline: (
	'small-3x'		18rem 16rem 1.5 400 1rem,
	'small-2x'		20rem 16rem 1.5 400 1rem,
	'small-1x'		24rem 18rem 1.5 400 1rem,
	'small'			28rem 20rem 1.5 400 1rem,
	'medium'		32rem 24rem 1.5 400 1rem,
);


.mark.module-d {
	// general
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@include font((family: default)...);
	box-sizing: border-box;
	border-style: solid;

	.mark-text {
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		text-align: center;
	}

	&[class*=symbol-ltr-] .mark-text::before,
	&[class*=symbol-rtl-] .mark-text::after {
		content: '';
		display: block;
		mask-repeat: no-repeat;
		mask-size: cover;

		// @each $icon-name, $icon in $symbol-list {
		// 	&.symbol-ltr-#{$icon-name} {.mask-text {&::before {background-image: icon($icon-name, $color);}}}
		// 	&.symbol-rtl-#{$icon-name} {.mask-text {&::after {background-image: icon($icon-name, $color);}}}
		// }
	}

	// accent
	@each $accent-name in $mark-module-d-accent {
		&.#{nth($accent-name, 1)} {
			@each $type-name, $color, $border-color, $background-color in map-get($mark-module-d-accent, nth($accent-name, 1)) {
				&.type-#{$type-name} {
					color: $color;
					border-color: $border-color;
					background-color: $background-color;

					&[class*=symbol-ltr-] .mark-text::before,
					&[class*=symbol-rtl-] .mark-text::after {
						content: '';
						background-color: $color;
					}
				}
			}
		}
	}

	// outline
	@each $name, $height, $line-height, $font-size, $font-weight, $border-width in $mark-module-d-outline {
		&.#{$name} {
			padding-right: calc($height / 3);
			padding-left: calc($height / 3);
			height: $height;

			line-height: $line-height;
			font-weight: $font-weight;
			font-size: calc($line-height / $font-size);
			border-width: $border-width;

			&.style-b {
				border-radius: $height * 0.2;
			}

			&.style-c {
				border-radius: $height * 0.5;
			}

			// icon
			&[class*=symbol-ltr-] .mark-text::before,
			&[class*=symbol-rtl-] .mark-text::after {
				width: $line-height;
				min-width: $line-height;
				height: $line-height;
			}

			
			&[class*=symbol-ltr-] {
				padding-left: ($height * 0.5) - ($line-height * 0.25);
				.mark-text {
					&::before {
						margin-right: $line-height * 0.1;
					}
				}
			}

			&[class*=symbol-rtl-] {
				padding-right: ($height * 0.5) - ($line-height * 0.25);
				.mark-text {
					&::after {
						margin-left: $line-height * 0.1;
					}
				}
			}
		}
	}
}

.lamp.module-a {
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	.lamp-text {
		@include hidden;
	}

	&.small {
		width: 20rem;
		height: 20rem;
	}

	&.normal-10 {
		background-image: icon(lamp-dot, color(base, 000));
	}

	&.normal-04 {
		background-image: icon(lamp-dot, color(base, 030));
	}

	&.accent-03 {
		background-image: icon(lamp-dot, color(secondary, 040));
	}
}



