
@import 'partials/_variables';
@import 'partials/_mixin';

// 기존 운영 CSS 재설정 관련
// @import 'partials/_migration';

@import 'partials/_fonts';
@import 'partials/_typography';
@import 'partials/_icons';
@import 'partials/_button';
@import 'partials/_mark';
@import 'partials/_form';
@import 'partials/_fieldset';
@import 'partials/_list';
@import 'partials/_board';
@import 'partials/_table';
@import 'partials/_tab';
@import 'partials/_tooltip';
@import 'partials/_post';
@import 'partials/_goods';

// 외부 컴퍼넌트 CSS
// @import 'partials/_vendor';
// @import 'partials/_temp';

.hidden {
	@include hidden;
}