@import 'partials/_variables';
@import 'partials/_mixin';
@import 'partials/_contents';
@import 'partials/_popup';
@import 'partials/_widget';

:root[class*=root-front] {
	height: 100%;
	font-size: 0.75px;
	background-color: color(normal, 000);

	// 이상
	@include respond-above(mobile-2x) {
		font-size: 0.875px;
	}

	// 이상
	@include respond-above(mobile-1x) {
		font-size: 1px;
	}

	body {
		margin: 0;
		padding: 0;
		height: 100%;
		word-break: keep-all;
		word-wrap: break-word;
		// overflow-wrap: break-word;
		@include font((family: default, size: small-1x)...);
		-webkit-text-size-adjust: none;
	}
	
	hr {
		display: none;
	}
	
	img {
		max-width: 100%;
	}
	
	#wrap {
		position: relative;
		overflow: hidden;
		height: 100%;
	}
	
	#page {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	.page-skip {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 100;
		top: -120rem;
		left: calc(50% - 50vw);
		width: 100vw;
		height: 56rem;
		background-color: color(base, 090);
	
		.skip-item {
			color: color(base, 000);
			@include font((outline: body, size: small-1x)...);
		}
		
		&:focus-within {
			top: 0;
		}
	}
	
	.page-head {
		z-index: 20;
		background-color: color(base, 000);
	
		.section.page-util {
			.section-head {
				@include hidden;
			}
	
			.section-body {
				.navi-list {
					display: flex;
					align-items: center;
					column-gap: 16rem;
					margin: 0;
					padding: 0;
					list-style: none;
	
					.navi-item {
						.navi-name {
							display: flex;
							align-items: center;
							column-gap: 4rem;
	
							@include font((outline: body, size: small-2x)...);
	
							&.alarm {
								color: color(base, 080);
								&:not(:hover) {
									text-decoration: none;
								}
	
								&::before {
									content: '';
									flex-shrink: 0;
									width: 20rem;
									height: 20rem;
									background: icon(dawin-alarm, color(base, 080)) no-repeat center/cover;
								}
	
								.count {
									display: flex;
									padding: 0 6rem 0 5rem;
									height: 16rem;
									@include font((outline: body, size: small-4x)...);
									color: color(base, 000);
									border-radius: 8rem;
									text-align: center;
									background-color: color(accent, 010);
								}
							}
	
							&.my-page {
								color: color(base, 080);
								&:not(:hover) {
									text-decoration: none;
								}
	
								&::before {
									content: '';
									flex-shrink: 0;
									width: 20rem;
									height: 20rem;
									background: icon(dawin-user, color(base, 080)) no-repeat center/cover;
								}
							}
							
							&.login {
								color: color(base, 080);
								&:not(:hover) {
									text-decoration: none;
								}
							}
	
							&.agent {
								height: 24rem;
								padding: 0 12rem;
								color: color(base, 000);
								text-decoration: none;
								border-radius: 12rem;
								background-color: color(primary, 060);
							}
						}
					}
				}
			}
			.navi-list {
				.navi-item {
					&:nth-child(2) {
						// display:none;
					}
				}
			}
		}
	
		// mobile
		@include respond-below(tablet) {
			order: 3;
			flex-shrink: 0;
			bottom: 0;
	
			.page-title {
				@include hidden;
			}
	
			.page-navi {
				position: relative;
				z-index: 20;
				width: 100%;
				box-shadow: 0 -1rem 0 0 rgba(0,0,0,0.06);
			
				.navi-list {
					display: flex;
					margin: 0;
					padding: 0;
					list-style: none;
			
					.navi-item:nth-child(4) {
						display: none;
					}
			
					.navi-item {
						flex: 1 1 100%;
						position: relative;
						width: 100%;
			
						.navi-name {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							position: relative;
							height: 60rem;
							@include font((outline: body, size: small-2x)...);
							color: color(base, 080);
							text-align: center;
							text-decoration: none;
			
							&::before {
								content: '';
								display: block;
								width: 24rem;
								height: 24rem;
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
							}

							.count {
								display: flex;
								padding: 0 6rem 0 5rem;
								height: 16rem;
								@include font((outline: body, size: small-4x)...);
								color: color(base, 000);
								border-radius: 8rem;
								text-align: center;
								background-color: color(accent, 010);
								position: absolute;
								top: 4rem;
								right: 16rem;
							}
						}
			
						&.current {
							.navi-name {
								background-color: color(base, 005);
							}
						}
			
						&:nth-child(1) {
							order: 1;
							.navi-name::before {
								background-image: icon(dawin-logo, color(base, 090));
							}
						}
						&:nth-child(2) {
							order: 3;
							.navi-name::before {
								background-image: icon(dawin-map, color(base, 090));
							}
						}
						&:nth-child(3) {
							order: 2;
							.navi-name::before {
								background-image: icon(dawin-sell, color(base, 090));
							}
						}
						&:nth-child(4) {
							display: none;
						}
						&:nth-child(5) {
							display: none;
						}
						&:nth-child(6) {
							order: 4;
							.navi-name::before {
								background-image: icon(dawin-chat, color(base, 090));
							}
						}
						&:nth-child(7) {
							order: 5;
							.navi-name::before {
								background-image: icon(dawin-user, color(base, 090));
							}
						}

						// &:nth-child(4) {
						// 	.navi-name::before {
						// 		background-image: icon(dawin-graph, color(base, 090));
						// 	}
						// }
						// &:nth-child(5) {
						// 	.navi-name::before {
						// 		background-image: icon(dawin-board, color(base, 090));
						// 	}
	
						// 	&::after {
						// 		content: "Beta";
						// 		display: block;
						// 		position: absolute;
						// 		top: 8rem;
						// 		left: 50%;
	
						// 		padding: 0 4px;
						// 		height: 14px;
						// 		line-height: 14px;
						// 		font-size: 9px;
						// 		color: color(base, 000);
						// 		letter-spacing: 0;
						// 		border-radius: 7px;
						// 		background: color(secondary, 040);
						// 	}
						// }
						// &:nth-child(5) {
						// 	.navi-name::before {
						// 		background-image: icon(dawin-user, color(base, 090));
						// 	}
						// }
					}
				}
			}
	
			.section.page-util {
				.navi-list {
					.navi-item {
						&:nth-child(2) {
							display:block;
						}
						&:nth-child(3) {
							display:none;
						}
					}
				}			
			}
			
		}
	
		// tablet
		@include respond-above(tablet) {
			z-index: 20;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 24rem;
			box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
	
			.page-title {
				margin: 0;
	
				.page-name {
					display: inline-block;
	
					svg {
						fill: color(primary, 070);
					}
				}

				.page-subname {
					display: inline-block;
					margin-left: 4rem;
	
					svg {
						fill: color(base, 040);
					}
				}
			}
	
			.page-navi {
				display: flex;
				padding: 0 48rem;
				width: 520rem;
	
				.navi-list {
					flex: 1 1 100%;
					display: flex;
					justify-content: space-between;
					margin: 0;
					padding: 0;
					list-style: none;
	
					.navi-item {
						position: relative;
						
						.navi-name {
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							height: 56rem;
							@include font((outline: body, size: small-1x, weight: bold)...);
							color: color(base, 080);
							text-decoration: none;
							text-align: center;
							gap: 4rem;

							.count {
								display: flex;
								padding: 0 6rem 0 5rem;
								height: 16rem;
								@include font((outline: body, size: small-4x)...);
								color: color(base, 000);
								border-radius: 8rem;
								text-align: center;
								background-color: color(accent, 010);
							}
						}
	
						&.current {
							.navi-name::after {
								content: '';
								display: block;
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								height: 4rem;
								background-color: color(base, 080);
							}
						}
	
						&:nth-child(1) {
							display: none;							
						}
						&:nth-child(2) {
							order: 2;							
						}
						&:nth-child(3) {
							order: 1;							
						}
						&:nth-child(4) {
							order: 3;						
						}
						&:nth-child(5) {
							order: 5;						
						}
						&:nth-child(6) {
							order: 4;					
						}
						&:nth-child(7) {
							display: none;						
						}
	
						// &:nth-child(5) {
						// 	&::after {
						// 		content: "Beta";
						// 		display: block;
						// 		position: absolute;
	
						// 		top: 50%;
						// 		right: 0;
						// 		transform: translate(18rem, -22rem);
	
						// 		padding: 0 4px;
						// 		height: 14px;
						// 		line-height: 14px;
						// 		font-size: 9px;
						// 		color: color(base, 000);
						// 		letter-spacing: 0;
						// 		border-radius: 7px;
						// 		background: color(secondary, 040);
						// 	}
						// }
					}
				}
			}
		}

		// 중개사용 header 
		&.type-d {
			justify-content: flex-start;
			gap: 16rem;
			height: 56rem;

			.page-title {
				display: flex;
				align-items: center;
			}

			.page-name {
				svg {
					fill: #7a1d05;
				}
			}
			.page-subname {
				margin-left: 2rem;
				margin-top: 4rem;
				
				svg {
					fill: #9d9d9d;
				}
			}

			.page-control {
				// display:none;

				.button-area {
					.btn {
						display: block;
						position: relative;
						height: 24rem;
						width: 24rem;
						color: color(base, 100);
						text-align: center;
		
						.btn-text {
							@include font((outline: body, size: small-4x)...);
						}
		
						&::before {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
						}
					}
		
					.btn.menu::before {
						background-color: color(base, 000);
						background-image: icon(menu, color(base, 100));
					}
				}
			}

			.page-util {
				margin-left: auto;				
			}	

		}
		// mobile
		@include respond-below(tablet) {
			&.type-d {
				order: 1;
				height: 56px;
				padding: 0 16rem;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				justify-content: flex-start;

				.page-control {
					// display: none;
				}
				.page-title {
					visibility: inherit;
					width: inherit;
					height: inherit;
					clip: inherit;
					pointer-events: inherit;
					border: inherit;
					margin: -1rem;
					padding: 0;
					font-size: inherit;
					line-height: inherit;
					display: flex;
					align-items: center;
					position: inherit;
					overflow: inherit;

					.page-name {
						display:flex;
						align-items: center;

						svg {
							width: auto;
							height: 22rem;
							fill: #7a1d05;
						}
					}
					.page-subname {
						display:flex;
						align-items: center;
						margin-left: 4rem;	

						svg {
							width: auto;
							height: 22rem;
							fill: #9d9d9d;
						}
					}
				}
				.page-util {
					display: block;
					margin-left: auto;

					.navi-name {
						align-items: flex-start;
						&.login {
							flex-direction: column;							
						}
					}
				}
			}	
		}
	}
	
	.page-body {
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
		
		
		@include respond-below(tablet) {
			order: 2;
			overflow: hidden;
			flex-shrink: 0;
			flex-grow: 1;
			flex-basis: auto;
		}
	
		@include respond-above(tablet) {
			// z-index: 10;
		}
	}
	
	/**
	 * @type layout
	 * @name local-foot
	 */
	.page-foot {
		order: 3;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 32rem 0 48rem 0;
		// background-color: color(base, 000);
		background-color: #f7f7f7;
		border-top: 1rem solid rgba(0,0,0,0.05);
	
		.section.site-info {
			order: 1;
			padding: 0 40rem;;
			box-sizing: border-box;
				
			.section-wrap {
				.section-head {
					@include hidden;
				}
	
				.section-body {
					.navi-list {
						display: flex;
						flex-wrap: wrap;
						column-gap: 16rem;
						row-gap: 8rem;
						justify-content: center;
	
						@include respond-above(tablet) {
							column-gap: 24rem;
						}
	
						.navi-item {
							display: flex;
							align-items: center;
							column-gap: 4rem;
	
							.navi-name {
								@include font((outline: body, size: small-2x)...);
								color: color(normal, 010);
	
								&:not(:hover) {
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
	
		.section.company-info {
			order: 2;
			width: 860rem;
			max-width: 100%;
			margin-top: 24rem;
			padding: 0 40rem;;
			box-sizing: border-box;
	
			.section-wrap {
				.section-head {
					@include hidden;
				}
	
				.section-body {
					.data-list {
						display: flex;
						flex-wrap: wrap;
						column-gap: 8rem;
						row-gap: 4rem;
						justify-content: center;
	
						@include respond-above(tablet) {
							column-gap: 16rem;
						}
	
						.data-item {
							@include font((outline: body, size: small-3x)...);
							color: color(normal, 030);
						}
					}
				}
			}
		}
	
		.section.dawin-share {
			order: 4;
			margin-top: 32rem;
	
			.section-wrap {
				.section-head {
					@include hidden;
				}
	
				.section-body {
					.navi-list {
						display: flex;
						flex-wrap: wrap;
						column-gap: 8rem;
						justify-content: center;
	
						.navi-item {
							.navi-name {
								display: flex;
								align-items: center;
								justify-content: center;
								position: relative;
								overflow: hidden;
								height: 36rem;
								width: 36rem;
								@include font((outline: body, size: small-5x)...);
								color: color(normal, 010);
	
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									border-radius: 50%;
									border: 1rem solid color(normal, 040);
									background-color: color(normal, 000);
	
									background-repeat: no-repeat;
									background-position: center;
									background-size: 24rem;
								}
							}
	
							&.kakao {
								.navi-name {
									&::before {
										background-image: icon(brand-kakao, color(normal, 030));
									}
								}
							}
							&.facebook {
								.navi-name {
									&::before {
										background-image: icon(brand-facebook, color(normal, 030));
									}
								}
							}
							&.link {
								.navi-name {
									&::before {
										background-image: icon(link, color(normal, 030));
									}
								}
							}
						}
					}
				}
			}
		}
	
		.copyright {
			order: 3;
			margin: 16rem 0 0 0;
			@include font((outline: body, size: small-3x)...);
			color: color(normal, 030);
			text-align: center;
		}
	}
	
	#local {	
		.local-util {
			flex-shrink: 0;
			.request-amount {
				display: flex;
				justify-content: space-between;
				padding: 14rem $container-gap;
				@include font((outline: head, size: small-2x)...);
				color: color(base, 080);
		
				.head {
					display: flex;
				}
		
				.body {
					display: flex;
					@include font((weight: bold)...);
					.value,
					.unit {
						display: block;
					}
				}
			}
		}
	}
	
	#content {
		.content-body {
			// display: flex;
			// flex-direction: column;
			padding: 0 $container-gap;
	
			.button-display.module-fab {
				position: fixed;
				bottom: 92rem;
				right: $container-gap;
	
				.button-area {
					display: flex;
					flex-direction: column;
	
					.btn+.btn {
						margin-top: 8rem;
					}
				}
				&.type-c {
					left: 50%;
					transform: translate(-50%);
					right: unset;
				}
			}
	
			.content-body {
				&:last-child {
					flex: 1 1 100%;
				}
			}
		}
	
		.content-side {
			flex: 1 1 100%;
			padding: 0 $container-gap;
			background-color: color(base, 005);
		}
	}

	// @include front-dawin-footer;
}