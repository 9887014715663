@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-blank {
	#wrap {
		display: flex;
		flex-direction: column;
	}
	.page-head {
		@include hidden;
	}
	.page-body {
		#local {
			.local-head {
				@include hidden;
			}
			.local-util {
				margin: 0 0 80rem 0;

				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}
	.page-foot {
		@include hidden;
	}
	
	@include respond-below(tablet) {
		.page-body {
			flex: 1 1 100%;
			overflow: hidden;

			#local {
				flex: 1 1 100%;
				display: flex;
				flex-direction: column;
				overflow: hidden;
									
				.local-body {
					padding: 0;

					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, large, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, large, y);
					}
	
					#content {
						margin: 0;
					}
				}
	
				.local-util {
					margin:0;
					
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
	
			&.page-intro {
				flex: 1 1 100%;
				margin: 0;
				@include most-outer-selector('.mobile') {
					@include overflow-scroll-system(dark, medium, y);
				}
		
				@include most-outer-selector('.laptop') {
					@include overflow-scroll-custom(dark, medium, y);
				}
		
				.page-head {
					position: absolute;
					right: 0;
					bottom: 0;
					left: 0;
				}
		
				.page-body {
					#local {
						.local-head {
							position: absolute;
							z-index: 1;
							top: 0;
							right: 0;
							left: 0;
							background-color: color(base, 005);
						}
					}
				}
			}
		
			&.page-front {
				.page-head {
					display: none;
				}
		
				.page-body {
					overflow: hidden;
					
					#local {
						flex: 1 1 100%;
						overflow: hidden;
						
						.local-head {
							position: relative;
							z-index: 10;
							background-color: color(base, 000);
						}
					}
		
					.local-body {
						flex: 1 1 100%;
						@include most-outer-selector('.mobile') {
							@include overflow-scroll-system(dark, medium, y);
						}
				
						@include most-outer-selector('.laptop') {
							@include overflow-scroll-custom(dark, medium, y);
						}
					}
				}
			}
		}
		
		#footer,
		.page-foot,
		.page-head {
			display: none;
		}
	}

	@include respond-above(tablet) {
		#page {
			margin: 0;

			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, large, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, large, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
	
		}
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				padding: 0;
	
				.local-head {
					@include hidden;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large, weight: medium)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0;
				}
			}
		}
	}
		
	.local-body {
		#content {	
			.content-body {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 480rem;
			}
		}
	}	
}