@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-mypage {
	.content-body {
		.apple,
		.kakao,
		.naver {
			color: initial;
			height: initial;
			background: initial;
			border-radius: initial;
			border: initial;
			font-weight: initial;
			position: initial;
		}
	
		.section {
			.navi-item {
				min-width: initial;
				max-width: initial;
				text-align: initial;
				height: initial;
				box-sizing: initial;
				cursor: initial;
				margin-left: initial;
				margin-right: initial;
				padding: initial;
				position: initial;
				margin-bottom: initial;
			}
		}
	}
	
	@include respond-below(tablet) {
	
	}
	
	#wrap {
		display: flex;
		flex-direction: column;
		background-color: color(base, 005);
	}
	
	.page-body {
		overflow: hidden;

		#local {
			.local-util {
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
					}
				}
			}

			.local-body {
				@include most-outer-selector('.mobile') {
					@include overflow-scroll-system(dark, medium, y);
				}

				@include most-outer-selector('.laptop') {
					@include overflow-scroll-custom(dark, medium, y);
				}

				.section.module-a {
					.section-wrap {
						display: flex;
						flex-direction: column;
						row-gap: 12rem;
						
						.section-head {
							.section-subject {
								margin: 0;
								@include font((outline: body, size: small-1x, weight: bold)...);
								color: color(normal, 100);
				
								.section-name {
									display: block;
								}
							}
						}
					}
				
					&:not([class*=type-]) {
						.section-wrap {
							.section-head {
								@include hidden;
							}
						}
					}
				}
			}
		}
	}
	
	
	@include respond-below(tablet) {
		.page-body {
			#local {
				flex: 1 1 100%;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				
				.local-head {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
						}
					}
				}
			
				.local-body {
					padding: 0 20rem;
					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
	
					#content {
						margin: 40rem 0 80rem 0;
					}
				}
	
				.local-util {
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
		}
	
		#page.page-intro {
			flex: 1 1 100%;
			padding: 64rem 0 60rem 0;
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, medium, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, medium, y);
			}
	
			.page-head {
				position: fixed;
				right: 0;
				bottom: 0;
				left: 0;

				.page-util {
					display: none;
				}
			}
	
			.page-body {
				#local {
					.local-head {
						position: absolute;
						z-index: 10;
						top: 0;
						right: 0;
						left: 0;
						flex-shrink: 0;
						background-color: color(base, 005);

						.local-control {
							display: none;
						}
					}
				}
			}

			.page-foot {
				display:none;
			}
		}
	
		#page.page-front {
			.page-head {
				display: none;
			}
	
			.page-body {
				overflow: hidden;
				flex: 100%;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
					
					.local-head {
						position: relative;
						z-index: 10;
						background-color: color(base, 000);
	
						&::after {
							content: '';
							order: 3;
							display: block;
							width: 24rem;
							height: 24rem;
						}
					}
				}
	
				.local-body {
					// flex: 1 1 100%;
					flex:100%;
					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
				}
			}
	
			.page-foot {
				display: none;
			}
		}
	}
	
	
	@include respond-above(tablet) {
		#page {
			// 임시 선언
			display: block;
			margin: 56rem 0 0 0;
			
			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, large, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, large, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
		}
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				padding: 40rem 0 80rem 0;
	
				.local-head {
					display: flex;
					flex-direction: column;
					align-items: center;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0 40rem;
				}
			}
		}
	}
	
	
	.local-body {
		#content {	
			.content-body {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
	
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 520rem;
			}
		}
	}
	
	.menu-display.module-a {
		display: flex;
		flex-direction: column;
		row-gap: 16rem;
	
		.menu-list {
			.menu-item {
				+.menu-item {
					border-top: 1rem solid color(base, 005);
				}
	
				&:first-child {
					.menu-wrap {
						border-top-left-radius: 8rem;
						border-top-right-radius: 8rem;
					}
				}
	
				&:last-child {
					.menu-wrap {
						border-bottom-left-radius: 8rem;
						border-bottom-right-radius: 8rem;
					}
				}
	
				.menu-wrap {
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 16rem;
					background-color: color(base, 000);
					
					.menu-head {
						flex: 1 1 100%;
	
						display: flex;
						flex-direction: column;
						row-gap: 4rem;
	
						.menu-subject {
							display: flex;
							column-gap: 8rem;
							margin: 0;
	
							.menu-name {
								display: block;
								@include font((outline: body, size: small-1x, weight: regular)...);
								color: color(normal, 100);
							}
	
							.mark {
								flex-shrink: 0;
							}
	
						}
						.menu-summary {
							display: block;
							margin: 0;
							@include font((outline: body, size: small-2x)...);
							color: color(normal, 030);
	
							.wbr {
								display: inline-block;
								vertical-align: top;
							}
						}
					}
	
					.menu-body {
						display: flex;
						flex-shrink: 0;
	
						.mark {
							flex-shrink: 0;
							
							.mark-text {
								min-width: 2em;
							}
						}
					}
				}
	
				a.menu-wrap {
					text-decoration: none;
	
					&::after {
						flex-shrink: 0;
						content: '';
						display: block;
						width: 24rem;
						height: 24rem;
						background: icon(chevron-right, color(normal, 040)) no-repeat center/cover;
					}
				}

				&.beta {
					.menu-wrap {
						.menu-head {
							.menu-subject {
								.menu-name {
									display: flex;
									align-items: center;
									&::after {
										content: "Beta";
										height: 14px;
										color: #fff;
										letter-spacing: 0;
										background: #5a67be;
										border-radius: 7px;
										padding: 0 4px;
										font-size: 9px;
										line-height: 14px;
										display: inline-flex;
										margin-left:4rem
									}
								}
							}
						}
					}					
				}
			}
		}
	}
	
	.info-board.user-area {
		.board-wrap {
			display: flex;
			flex-direction: column;
			row-gap: 16rem;
	
			.board-head {
				display: flex;
				flex-direction: column;
				align-items: center;
				row-gap: 4rem;
				
				.board-subject {
					margin: 0;
					text-align: center;

					.board-name {
						display: block;
						@include font((outline: body, size: medium, weight: bold)...);
						color: color(normal, 100);
					}
				}
				.board-summary {
					margin: 0;
					@include font((outline: body, size: small-2x)...);
					color: color(normal, 030);
					text-align: center;
				}
			}
	
			.board-util {
				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
						column-gap: 6rem;
	
						.btn {
							min-width: 88rem;
						}
					}
				}
			}
		}
	}
	
	.data-list.social-account {
		.data-item {
			display: flex;
			align-items: center;
			column-gap: 8rem;
			height: 48rem;
			@include font((outline: body, size: small-1x)...);
			color: color(normal, 010);
	
			&::before {
				content: '';
				flex-shrink: 0;
				display: block;
				width: 32rem;
				height: 32rem;
				border-radius: 50%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 24rem;
			}
	
			&.kakao {
				&::before {
					background-color: #ffe501;
					background-image: icon(brand-kakao, #3a2020);
				}
			}
	
			&.naver {
				&::before {
					background-color: #06be34;
					background-image: icon(brand-naver, color(base, 000));
				}
			}
	
			&.apple {
				&::before {
					background-color: color(base, 100);
					background-image: icon(brand-apple, color(base, 000));
				}
			}
		}
	}	

	.posts-picture {
		.atch-file {
			.data-list {
				.data-item {
					.data-wrap {
						cursor: pointer;
					}
				}
			}
		}
	}

	.posts-display.module-a {
		.posts-list {
			.posts-item {
				.posts-wrap {
					cursor: default;
					.posts-inform {
						.posts-head {
							.posts-title {
								display: flex;
								flex-direction: column;
								flex-grow: 1;
								.posts-picture {
									width: unset;
									height: unset;
									margin-top: 16rem;
									.atch-file {
										.data-list {
											.data-item {
												.data-wrap {
													cursor: pointer;
												}
											}
										}
									}
								}
							}							
						}
					}
				}
				&.ans-complete {
					.posts-wrap {
						cursor: pointer;
					}
				}
			}
		}
	}

	.submit-form.module-a.review {
		padding-top: 32rem;
		position: relative;

		.form-body {
			justify-content: flex-start;
			.form-area {
				.flex {
					flex-direction: column;
					align-items: flex-end;
				}
			}
		}

		&:before {
			content: "";
			width: 100vw;
			height: 1rem;
			background-color: color(base, 010);
			display: block;
			position: absolute;
			top: 0;
			left: calc(50% - 50vw);
		}
	}
}