@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */

.find .container.analysis {margin-top:0}
.analysis .map-container {height:100%}
.analysis .map_location {left:50%}
.analysis .map-btn-group-left {top:20px;z-index:61;display:flex;flex-direction:column;}
.analysis .map-btn-group-right {right:20px;top:20px}
.btn-fold {width:30px;height:30px;background:url("../images/sub/btn_back.png") no-repeat;position:absolute;right:10px;top:10px;z-index:1000}

/*분석*/
.analysis .analysis_lyr {position:absolute;top:20px;left:80px;z-index:60;/*height:100%;*/}
.analy_menu_top {width:400px;background:#fff;box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.075), -1px 1px 1px rgba(0, 0, 0, 0.03), 1px 1px 1px rgba(0, 0, 0, 0.03);position:relative}
.analy_menu_top h4 {font-size:18px;font-weight:500;margin-left:18px;padding-top:15px;display:inline-block}
.analy_menu_top .add_text {color:#9a9a9a;font-size:13px;margin-left:8px}
.analy-menu {width:100%;padding:12px 18px 16px;box-sizing:border-box;}
.lyr_box {width:100%;height:100%;padding:18px 18px 16px;box-sizing:border-box;}
.analy-menu:after {display:block;content:'';clear:both;}
.analy-menu .add {font-size:12px;}
.menu-group {float:left;width:100%;}
.menu-group h5 {font-size:15px;font-weight:500;margin-bottom:15px}
.menu-group h5:before {display:inline-block;content:'';width:5px;height:5px;background:#791808;margin-right:8px;margin-bottom:3px}
.mn_analysis {/*display:flex;flex-wrap:wrap;align-items:stretch;align-content:stretch;*/display:block;margin:0 -3px 0 -3px }
.mn_analysis:after {display:block;content:'';clear:both}
.mn_analysis>li {position:relative;/*flex:0 0 33.333333%;*/float:left;width:calc(100% / 3);box-sizing:border-box;padding: 0 3px 6px 3px;text-align:center;font-size:15px;height:42px;background-color: #fff;}
.mn_analysis>li>a {position:relative;display:block; width:100%;background:#f1f1f1;color:#222;line-height:35px;border-radius:6px;letter-spacing:-1px;font-size:14px;}
.mn_analysis>li>a.on {background:#7686eb;color:#fff}
.mn_analysis+p, .mn_analysis+p+p {font-size:13px;color:#666}
.analy_menu_bottom {width:400px;background:#fff;margin-top:10px;box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.075), -1px 1px 1px rgba(0, 0, 0, 0.03), 1px 1px 1px rgba(0, 0, 0, 0.03);/*overflow-y:auto;*/position:relative;max-height:70%;}
.analy_menu_bottom h4 {font-size:18px;font-weight:500}
.analy_menu_top .btn-close, .analy_menu_bottom .btn-close {position:absolute;right:15px;top:15px;z-index:1}
.analysis_lyr .mn_analysis>li>a.prep {color:#CCCCCC;background:#f9f9f9}
.analysis_lyr .mn_analysis>li>a small {color:#ccc;vertical-align:middle;margin-top:-3px}
.analysis .analysis_lyr.compr_lyr {top:65px}
.analysis .analysis_lyr.rnk_lyr {top:110px}
.analysis .analysis_lyr.rnk_lyr .lyrpop_analy, .analysis .analysis_lyr.compr_lyr .analy_menu_bottom{margin-top:0}
.analy_menu_bottom .btn-share {position:absolute;right:40px;top:20px;} 
.analy_menu_bottom .share {padding:10px;text-align:center}
.itmbox.share.fix {width:380px;position:fixed;bottom:0;margin-left:-10px}
.analysis_lyr .btn-close {position:absolute;right:15px;top:18px;z-index:100}
.analysis_lyr .btn-hidden {position:absolute;right:-24px;top:78px}
.analysis .btn-open {position:absolute;left:60px;top:20px}

.analy_ftgroup {margin-top:20px;/*max-height:400px;overflow-y:auto;*/}
.slider-wrap {position:relative;margin:0px 15px;}
.slider {display:inline-block;position:relative;box-sizing:border-box;width:100%;  height:23px;vertical-align:middle;}
.slider .track {position:relative;top:50%;margin-top: -4.5px;}
.slider .track span {display:inline-block;width:calc((100% - 6px) / 4);background-color:#d8d8d8;height:5px;float:left;margin-right:2px;}
.slider .track span:last-child {margin-right:0;/*border-bottom-right-radius:11px;border-top-right-radius:11px;*/}
/*.slider .track span:first-child {border-bottom-left-radius:11px;border-top-left-radius:11px;}*/
.slider .thumb-group {position:absolute;width:100%;height:100%;top:0;}
.slider .thumb-group .thumb {z-index:3;position:absolute;box-sizing:border-box;top:0;left:0;height:18px;width:18px;vertical-align:middle;-webkit-border-radius: 12.5px;-moz-border-radius: 12.5px;-ms-border-radius: 12.5px;-o-border-radius: 12.5px;border-radius: 12.5px;margin-left:-9px;background-color:#c22800;}
.slider.range .thumb-bar {position:absolute;box-sizing:border-box;top:40%;left:0;transform:translatey(-50%);height:5px;background-color:#c22800;z-index:1;}
.slider-wrap .slider-range {position:relative;height:20px;padding:5px 0;}
.slider-wrap .slider-range li {position:absolute;font-size:12px;display:block;white-space:nowrap;text-align:center;transform:translateX(-50%);color:#333;z-index:2}
.slider-wrap .slider-range li:before {background-color:#fff;width:2px;height:9px;line-height:1;content:'';position:absolute;display:block;top:-22px;left:50%;}
.slider-wrap .slider-range li:first-child:before, .slider-wrap .slider-range li:last-child:before {display:none}
.slider-wrap .slider-range li:nth-child(2) {left:25%;}
.slider-wrap .slider-range li:nth-child(3) {left:50%;}
.slider-wrap .slider-range li:nth-child(4) {left:75%;}
.slider-wrap .slider-range li:nth-child(5) {left:100%;}
.slider-wrap .slider-range.quart li:nth-child(2) {left:33.333333%;}
.slider-wrap .slider-range.quart li:nth-child(3) {left:66.666666%;}
.slider-wrap .slider-range.quart li:nth-child(4) {left:100%;}
.slider .thumb-group.right-side .thumb {width:4px;right:0;left:inherit}

.icon-help {display:inline-block;width:14px;height:14px;background:url("../images/sub/ico_tooltip.png") no-repeat;background-size:contain;margin-left:5px}
[data-tooltip-text]:hover {position:relative;}
[data-tooltip-text]:hover:after {content:attr(data-tooltip-text);position:absolute;top:100%;background-color:#3a3a3a;color:#c1c1c1;border-radius:5px;margin-top:8px;padding:10px 10px;font-size:13px;font-weight:normal;/*max-width:340px;min-width:100px;*/width:180px;z-index:9999;}
.left [data-tooltip-text]:hover:after {left:0;}
.right [data-tooltip-text]:hover:after {right:0;}
.center [data-tooltip-text]:hover:after {left:0;}
.analy_menu_bottom .tab02 {position:absolute;top:16px;right:45px}
.analy_menu_bottom .f_d_close {top:20px;right:15px}
.visual-info {display:block;background-color:rgba(194, 40, 0, 0.4);position:absolute;left:50%;top:50%;transform:translate(-50%, -57%);width:66px;height:66px;border-radius:100px;z-index:11;}
.visual-info.decrease {background-color:rgba(36, 81, 196, 0.4);}

.analysis .cluster > .info01 {padding:6px 8px}
.cluster .info01 span {display:block;font-size:14px;position:relative;z-index:12}
.cluster .info01 .a_area {font-size:12px;padding:2px 0}
.pin-type05 {width:54px;height:58px;}
.pin-type05.on {background:none}
.pin-type05.on .info01 {width:54px;height:52px;background:url("../images/sub/ico_analy_on.png") no-repeat;background-size:contain;color:#222;font-weight:600}
.real-pin.on .visual-info {z-index:-1}
.summ_graph {display:inline-block;width:40px;height:auto;}
.summ_graph img {object-fit:contain;width:100%;height:100%;}
.cluster .visual-info {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.supply .cluster{width:70px;height:48px;}
.anal_high .real-pin .info01 {height:40px}
.anal_high .real-pin.on .info01 {color:#222}
.pin-type05 .info01 {width:54px;height:52px;text-align:center;color:#fff;line-height:31px;font-size:14px;position:relative;background:url("../images/sub/ico_analy.png") no-repeat;background-size:contain;}
.pin-type05 .info01 .a_count {position:absolute;bottom:6px;left:50%;transform:translatex(-50%);z-index:12;font-size:12px;}
.pin-type05 .info01 .summ_graph {position:relative;z-index:12}
.analysis .cluster .info01 span {display:block;font-size:13px;position:relative;z-index:12}
.analysis .cluster .info01 .a_area {font-size:12px;padding:2px 0}
.analysis .cluster .info01 .a_area + .a_count {padding:2px 0 6px}
.real-pin .info04 + .info05 {top:-21px;}
.txt_noti {display:inline-block;background:#444;opacity:.7;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);z-index:120;border-radius:50px;box-sizing:border-box;width:250px;text-align:center;height:40px;vertical-align:middle}
.txt_noti span {color:#fff;font-size:13px;opacity:1;width:100%;text-align:center;vertical-align:middle;line-height:1.3;display:inline-block;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.analysis .cluster .info01 .summ_graph {display:inline-block;width:100%}
.pin-big-orange, .pin-big-blue {width:54px;height:58px;}
.pin-big-orange .info01 {display:flex;flex-flow:column wrap;justify-content:center;align-items:center;line-height:1.2;padding-bottom:5px;width:54px;height:52px;text-align:center;color:#fff;font-size:13px;position:relative;background: url(../images/sub/ico_analy03.png) no-repeat;background-size:contain;}
.pin-big-blue .info01 {display:flex;flex-flow:column wrap;justify-content:center;align-items:center;line-height:1.2;padding-bottom:5px;width:54px;height:52px;text-align:center;color:#fff;font-size:13px;position:relative;background: url(../images/sub/ico_analy02.png) no-repeat;background-size:contain;}
.pin-big-orange.on, .pin-big-blue.on {background:none}
.pin-big-orange.on .info01, .pin-big-blue.on .info01 {width:54px;height:52px;background:url("../images/sub/ico_analy_on.png") no-repeat;background-size:contain;color:#222;font-weight:600}

/*분석설명창*/
.lyrpop_analy {min-height:300px;max-height:650px;width:500px;padding:0}
.lyrpop_analy .btn-close {position:absolute;top:20px;right:20px;width:25px;height:25px;background: url(../images/main/btn_close02.png) no-repeat;}
.lyrpop_analy .lyr_inner {text-align:left;font-size:14px;max-height:650px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);padding:30px 20px}
.lyrpop_analy .lyr_inner .content {padding-top:0}
.lyrpop_analy .lyr_inner h4 {font-size:25px;font-weight:500;margin-bottom:15px}
.lyrpop_analy .lyr_inner p {text-align:left;font-size:14px;}
.lyrpop_analy .box {padding:15px;background:#f4f4f4;margin-top:10px;margin-bottom:10px;font-size:15px;}
.lyrpop_analy .p02 {display:block;text-align:left !important;padding:10px 0 0}
.lyrpop_analy .p01 {padding:10px 0}
.lyrpop_analy .p03 {padding-top:10px}
.lyrpop_analy img {object-fit:contain;width:90%;height:auto;display:block;margin:0 auto;margin-bottom:10px;margin-top:10px;}
.lyr_box .tab_style02 {position:absolute;top:17px;right:45px}
.lyrpop_analy .tab_style04 {margin-bottom:14px;margin-top:15px;}
.lyrpop_analy .tab_style04 .tab {height:35px;border-bottom:1px solid #1e1e1e}
.lyrpop_analy .tab_style04 .tab li {width: calc(100% / 4);}
.lyrpop_analy .tab_style04 li a {padding-top:7px;padding-bottom:7px;background:#f8f8f8;font-size:14px;}
.lyrpop_analy .tab_style04 li.on a {background:#fff}

.analysis .panel-group {top:0;height:100%}

/*학군*/
.school-pin {width:48px;height:44px;}
.school-pin.highschool {background:url("../images/sub/ico_analy_school.png") no-repeat;background-size:contain;}
.school-pin.highschool.on {background:url("../images/sub/ico_analy_school_on.png") no-repeat;background-size:contain;}
.school-pin.middleschool {background:url("../images/sub/ico_analy_school02.png") no-repeat;background-size:contain;}
.school-pin.middleschool.on {background:url("../images/sub/ico_analy_school02_on.png") no-repeat;background-size:contain;}
.school-pin .info01 {width:48px;height:44px;line-height:3.7}
.school-pin.on .info01 {color:#5827d8;font-weight:bold}
.school-pin .info02 {transform:translatex(-50%);margin-top:2px}
.analy_ftgroup .mn_analysis>li {flex:0 0 33.33333333%}
.analy_ftgroup .mn_analysis>li>a {background:#eeeeee;color:#222}
.analy_ftgroup .mn_analysis>li>a.on {background:#9e1500;color:#fff}
.float_menu_bottom .analy_ftgroup {margin-top:10px}
.float_menu_bottom .mn_analysis {padding:0 50px}
.float_menu_bottom .mn_analysis a {font-size:14px}
.legend-wrap {padding:10px 25px 10px 10px;background:#fff;border:1px solid #d2d2d2;position:relative;width:120px;margin-top:10px;;}
.legend-wrap .btn-close-xs {position:absolute;right:8px;top:8px;}
.legend-wrap .legend .academy-pin {display:inline-block;vertical-align:middle}
.legend-wrap .legend span {font-size:12px;display:inline-block;vertical-align:middle;margin-left:3px;}
.academy-pin .visual-info {background:rgb(255, 0, 114, 0.4);border:1px solid rgb(0, 0, 0, 0.7);}
.visual-name {display:block;font-size:12px;background:rgb(0,0,0,0.7);border:1px solid #000;color:#fff;position:absolute;left:50%;transform:translateX(-50%);top:-30px;border-radius:3px;padding:2px;width:80px;text-align:center;}

/*공시지가*/
.analysis .real-pin .info01 .a_price {position:absolute;top:44%;left:50%;transform:translate(-50%, -60%);z-index:12;width:55px;text-align:center;}
/*.analysis .pin-type01.on .info01, .analysis .pin-type02.on .info01, .analysis .pin-type03.on .info01, .analysis .pin-type04.on .info01 {background:url(../images/sub/ico_price_on.png) no-repeat;background-size:contain;color:#222;font-weight:600;}*/

/*비교*/
.danjicompare {height:550px;width:400px;padding:0;box-sizing:border-box;z-index:100}
.danjicompare .cont_wrap {padding-bottom:0;box-sizing:border-box;height:calc(100% - 56px);overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.danjicompare .header {position:static;top:inherit;height:auto;border-bottom:0}
.danjicompare .header .title {padding:18px 18px 10px;font-size:20px;text-align:left;font-weight:500;}
.danjicompare .search-container {width:100%;margin:0}
.danjicompare .search .inp {width:100%;background:#fff;height:36px;}
.danjicompare .inp-textbox .btn-img {top:10px}
.danjicompare .seleclst {padding:0 18px}
.danjicompare .seleclst + .lyr_box {height:auto;padding-top:0;padding-bottom:15px;background:#eaeaea;}
.lyr_box .sch_result {border:1px solid rgb(208, 210, 214);top:119px;left:18px;width:374px;padding:10px 0;max-height:150px}
.lyr_box .comparelst {z-index:100;padding:10px 0 40px 15px;position:relative;top:0;border:1px solid rgb(208, 210, 214);margin-top:-1px;max-height:200px;overflow-y:hidden;}
.lyr_box .comparelst ul {max-height:150px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.lyr_box .search-container .box-list .check-box {margin:10px 0}
.lyr_box .search-container .box-list .sch_word {display:inline-block;width:auto;padding:0}
.lyr_box .search-container .box-list .sch_word .address {display:block;font-size:12px;color:#888}
.filter-btn-area {position:absolute;bottom:0;left:0;width:362px;height:40px;background:#f1f1f1;border:none;border-top:1px solid #d9d9d9;}
.btn-filter_cancel {position:absolute;left:15px;top:10px;font-size:13px}
.btn-filter_close {position:absolute;right:15px;top:10px;font-size:13px;}
.seleclst {margin:0 -3px -3px;display:block;overflow:hidden;padding:10px 0}
.seleclst:after {display:inline-block;content:'';clear:both}
.seleclst li {display:inline-block;float:left;margin:3px 18px 3px 3px;border:1px solid #d4d4d4;border-radius:30px;position:relative;background:#fff;box-sizing:border-box}
.seleclst li span {display:block;padding:3px 15px;font-size:15px}
.seleclst li.selected {background:#c43713;color:#fff;border:1px solid #c43713}
.seleclst li .del {position:absolute;right:-15px;top:8px;left:inherit;display:inline-block;width:10px;height:10px;background:url("../images/sub/btn_close02.png") no-repeat;background-size:contain;text-indent:-9999px;transform:none}
/*.seleclst li.selected .del {position:absolute;right:12px;top:7px;left:inherit;display:inline-block;width:12px;height:12px;background:url("../images/sub/btn_close02_wh.png") no-repeat;background-size:contain;text-indent:-9999px;transform:none}*/
.btn_group {margin-top:8px;text-align:center;margin-bottom:8px}
.analysis_lyr .btn_group a {display:inline-block;font-size:14px;padding:5px 30px;color:#888;line-height:20px;height:34px;box-sizing:border-box;vertical-align:middle;box-shadow: 0 2px 2px rgba(0,0,0,.08), -1px 1px 1px rgba(0,0,0,.04), 1px 1px 1px rgba(0,0,0,.04);border-radius:40px;}
.analysis_lyr .btn_group .btn-delete {border:1px solid #d3d3d3;background:#fff;margin-right:5px}
.analysis_lyr .btn_group .btn-compare {background:#7a1d05;border:1px solid #7a1d05;color:#fff;}
/*.btn_group .btn-compare {font-weight:bold;color:#222;font-size:18px;line-height:20px;margin-left:10px}*/
.danjicompare .analy_ftgroup {margin-top:10px}
.danjicompare .itmbox {padding:0 18px;margin-top:20px}
.danjicompare .itmbox.share {margin-top:0;border-bottom:0}
/*.danjicompare .itmbox:last-child {margin-top:30px}*/
.danjicompare .itmbox h3 {font-weight:500;margin-bottom:10px}
.box_result {margin-top:15px;padding-bottom:10px;border-top:1px dotted #e9e9e9;}
.box_result .graph_area {width:100%;height:300px;}
.box_result .graph_area img {width:100%;height:100%;object-fit:contain;object-position:50%}
.lyr_box .search-container + .analy_ftgroup {margin-top:10px}
.itmbox.share {height:auto;text-align:center;background:#7a1d05;/*margin-top:-10px;*/margin-bottom:0;padding:20px;}
.share li {display:inline-block;width:45px;margin-right:8px;border-radius:50px;/*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), -1px 1px 1px rgba(0, 0, 0, 0.04), 2px 2px 2px rgba(0, 0, 0, 0.04);*/}
.share li:last-child {margin-right:0}
.share li a {display:block;height:45px;width:45px;margin-top:0;text-indent:-9999px;}
.share li a.kakao {background:url("../images/sub/share_kakao.png") no-repeat;background-size:contain;vertical-align:top;}
.share li a.facebook {background:url("../images/sub/share_fb.png") no-repeat;background-size:contain;vertical-align:top;}
.share li a.band {background:url("../images/sub/share_band.png") no-repeat;background-size:contain;vertical-align:top;}
.share li a.link {background:url("../images/sub/share_link.png") no-repeat;background-size:contain;vertical-align:top;}
.danjicompare .tab {margin-bottom:30px}
.danjicompare .tab_style03 ul li {margin-right:15px}
.danjicompare .cont_wrap .seleclst {padding:10px 20px;background:#eaeaea;}
.danjicompare .cont_wrap .seleclst li {display:block;margin:4px 20px 4px 3px;border:none;background:none;}
.danjicompare .cont_wrap .seleclst li span {display:inline-block;vertical-align:middle}
.danjicompare .cont_wrap .seleclst li span:first-child {padding:0;font-size:16px;font-weight:500}
.danjicompare .cont_wrap .seleclst .link_danji {display:inline-block;padding:2px 10px;border:1px solid #d0d2d6;border-radius:50px;background:#fff;margin-left:5px;font-size:13px;cursor:pointer}
.danjicompare .cont_wrap .seleclst .link_danji.selected {background:#c43713;color:#fff;border:1px solid #c43713}
.danjicompare .check-box .check {padding-right:0}
  
.lyrpop_analy .list {border:1px solid #e5e5e5;border-collapse:separate;border-top:0;border-spacing:0;margin-top:10px}
.lyrpop_analy .list tr {height:32px;}
.lyrpop_analy .list thead tr {background-color:#fafafa;}
.lyrpop_analy .list thead td {text-align:center}
.lyrpop_analy .list td {position:relative;text-align:center;font-size:13px;font-weight:300;color:#222;vertical-align:middle;border-top:1px solid #e5e5e5;padding:5px;}
.lyrpop_analy .list tbody td {background:#fff;}
.lyrpop_analy .list tbody td:nth-child(2) {font-weight:500}
.lyrpop_analy .list tbody td:nth-child(3) {text-align:left}

.danjicompare .itmbox {border-bottom:8px solid #efefef;margin-bottom:0;}
.danjicompare .itmbox.itmbox-term + .itmbox {margin:15px;border-bottom:none}
.go_report {display:inline-block;width:100%;box-sizing:border-box;text-align:center;font-weight:400;font-size:16px;padding:15px 10px}
.btn_link[data-tooltip-text]:hover:after {width:200px;top:-9px;left:19px;white-space:pre-line;}
.itmbox>.go_report {padding:0}


.itmbox .moreview {display:block;/*border-top:1px solid #e9e9e9;*/width:100%;text-align:center;margin:20px -18px 20px;padding:0 18px}
.itmbox .moreview span {display:inline-block;padding:3px 30px 3px 15px;border:1px solid #d4d4d4;border-radius:30px;font-size:13px;}
.itmbox .moreview span.open {background: url(../images/sub/ico_arrow_down.png) no-repeat 80% center;}
.itmbox .moreview span.close {background: url(../images/sub/ico_arrow_up.png) no-repeat 80% center;}
.cont_more {/*border-top:1px solid #e9e9e9;*/padding-top:15px;margin:0 -18px;padding-bottom:15px;}
.cont_more .tit {padding:0 20px 10px 20px;}
.cont_more .add-text {padding:0 20px 10px;margin-top:-10px;}
.cont_more table.list thead td {font-weight:400;padding:5px 2px}
.cont_more table.list tr {background:#fafafa;color:#222;cursor:pointer}
.cont_more table.list tbody tr:nth-child(2n) {background:#fafafa}
.cont_more table.list tbody tr:nth-child(odd) {background:#fff}
.cont_more .list {text-align:center;border:none;font-size:13px;}
.cont_more .list td {border:none}
.cont_more table.list tbody td {padding:4px 2px;position:relative}
.cont_more table.list tbody td:nth-child(2) {text-align:left;color:#3565c9;position:relative;font-size:12px;}
.cont_more table.list tbody td:nth-child(2) .btn_link {display:block;width:14px;height:14px;background:url(../images/sub/btn_link.png) no-repeat;background-size:contain;position:absolute;right:-15px;top:50%;transform:translatey(-50%);z-index:1}
.cont_more table.list tbody td:nth-child(n+3) {font-weight:400}
.cont_more table.list tbody td:first-child {padding-left:18px;font-size:12px}
/*.cont_more table.list tbody td:last-child, .cont_more table.list thead td:last-child {padding-right:18px}*/
/*.cont_more table.list tbody td:hover:before {content:'';display:block;width:23px;height:5px;background:#ffd200;opacity:.6;position:absolute;bottom:20%;left:50%;transform:translate(-50%, -50%);transition:all .35s;cursor:pointer}*/
.cont_more table.list col.selected tbody td {background:#e1e1e1}
.cont_more table.list + .m_exp {margin:10px 18px 0;color:#BCBCBC;font-size:13px}
.expbox li {padding:15px 25px;margin:0 -20px}
.expbox li:nth-child(2n+1) {background:#f8f8f8}
.lyrpop_analy .graph_area {padding:10px 20px 20px;height:250px;text-align:center;box-sizing:border-box}
.lyrpop_analy .graph_area img {object-fit:contain;width:100%;height:190px;margin:0}
.lyrpop_analy .vGraph{padding:20px 0;}
.lyrpop_analy .vGraph ul{display:flex;flex:1;justify-content:center;margin:0;padding:0;height:170px;border:0;font-size:11px;}
.lyrpop_analy .vGraph li{float:inherit;display:inline-block;width:40px;height:100%;position:relative;text-align:center;white-space:nowrap;padding:0 5%;box-sizing:border-box;margin:0 20px}
.lyrpop_analy .vGraph .gTerm{/*position:relative;display:inline-block;width:100%;height:60px;line-height:60px; margin:0 -100% -20px 0;padding:110px 0 0 0; vertical-align:bottom;*/position:absolute;bottom:0;left:50%;transform:translatex(-50%);color:#767676;font-weight:bold;padding:0;margin:0}
.lyrpop_analy .vGraph .gBar{position:absolute;bottom:0;left:0;width:100%;display:inline-block; width:100%;margin:-1px 0 0 0;background:#c22800;vertical-align:bottom;}
.lyrpop_analy .vGraph li:nth-child(1) .gBar {background:#ff4f59}
.lyrpop_analy .vGraph li:nth-child(2) .gBar {background:#9a6dd5}
.lyrpop_analy .vGraph li:nth-child(3) .gBar {background:#a6df7d}
.lyrpop_analy .vGraph li:nth-child(4) .gBar {background:#33b3ed}
.lyrpop_analy .vGraph li:nth-child(5) .gBar {background:#f4a76d}
.lyrpop_analy .vGraph li:nth-child(1) .gBar span {color:#ff4f59}
.lyrpop_analy .vGraph li:nth-child(2) .gBar span {color:#9a6dd5}
.lyrpop_analy .vGraph li:nth-child(3) .gBar span {color:#a6df7d}
.lyrpop_analy .vGraph li:nth-child(4) .gBar span {color:#33b3ed}
.lyrpop_analy .vGraph li:nth-child(4) .gBar span {color:#f4a76d}
.lyrpop_analy .vGraph .gBar span{position:absolute;width:90px;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;top:-25px;left:50%;transform:translatex(-50%);color:#767676;font-size:13px}
.lyrpop_analy .vGraph .gTerm span{position:absolute;width:70px;overflow: hidden;white-space:nowrap;text-overflow:ellipsis;bottom:-25px;left:50%;transform:translatex(-50%);color:#222;font-weight:500;font-size:12px}
.analysis_lyr .inp, .compr_lyr .inp {width:364px;}

/*일자리수*/
.pop_analy .ul-container {padding:0;height:40px}
.pop_analy .ul-container li {height:40px;}
.pop_analy .ul-container .scroll-li:first-child:after {display:none}
.pop_analy .tab_style03 {margin-bottom:10px;}
.pop_analy .tab_style03 ul {border-bottom:1px solid #1e1e1e;height:35px}
.pop_analy .tab_style03 li {width:auto}
.pop_analy .tab_style03 li a {padding:7px 20px}

/* 학군 */
.academy-pin {width:13px;height:13px;border-radius:20px;z-index:3}
.academy-pin.type01 {background:#ff0099;border:1px solid #222;}
.academy-pin.type02 {background:#fff100;border:1px solid #222;}

/*어린이집상세*/
.chart_area {text-align:center;padding:20px 0 10px}
.pie-chart {display:inline-block;position:relative;width:150px;height:150px;background:conic-gradient(#c22800 0% 25%, #ef6845 25% 56%, #edb1b1 56% 100%);border-radius:50%;}
.pie-chart .center{position:absolute;width:80px;height:80px;background:#fff;border-radius:50%;top:50%;left:50%;transform:translate(-50%, -50%)}
.bar-chart {height:30px;background:linear-gradient(to right, #ababab 80%, #eaeaea 20%);}
.chart_value {overflow:hidden;margin-bottom:5px}
.value_left {float:left;font-size:14px;color:#9d9d9d}
.value_right {float:right;font-size:14px;color:#9d9d9d}
.basicinfo {padding-bottom:10px;margin-bottom:15px;border-bottom:1px solid #e9e9e9}
.basicinfo li {width:100%;display:block;margin-bottom:5px;}
.basicinfo li:after {display:block;content:'';clear:both}
.basicinfo li span {display:inline-block;float:left;font-size:15px}
.basicinfo li span:first-child 
{width:25%;color:#9d9d9d;font-size:14px}

/*재건축사업성 점수*/
.score .panel-box {padding:0;/*height:calc(100% - 100px)*/margin-top:0}
.score .itmbox_g {background:#eaebed;height:160px;padding:0}
.score .itmbox_g img {width:100%;height:auto;object-fit:contain;}
.subtitle {display:block;margin-top:-3px;}
.emphasis h3.tit {color:#fff;font-size:15px;font-weight:500;margin-bottom:4px}
.go_score .small {margin-top:-8px;color:#f9f9f9;font-size:11px;}
.go_score .small i {color:#ffe400;font-style:italic}
.go_score {display:block}
.go_score .value {font-size:22px;font-weight:500;color:#ffe400;position:absolute;right:15px;top:50%;transform:translatey(-50%)}
.go_score .blind {display:inline-block;padding:1px 15px 2px;border-radius:50px;background:#951f00;}
.score .tbl_type01 small {display:block;margin-top:0;color:#999}
.score .basicinfo li span:first-child {width:50%;color:#222}
.score .basicinfo li span:first-child:before {display:inline-block;content:'';width:4px;height:4px;background:#a40000;margin-right:10px;margin-bottom:3px;}
.score .basicinfo li span:last-child {font-weight:600}
.score .basicinfo {border-bottom:none;padding-bottom:0;margin-bottom:0}
.score .basicinfo li span {font-size:16px;}
.score .basicinfo small {margin-left:12px;display:block;color:#666}
.score .basicinfo+.t_red {margin-top:14px;font-size:15px;}
.score .invest-comm {padding:10px 15px;background:#eaeaea;color:#c22800;line-height:1.4;font-size:14px;margin-top:15px}
.score .scroll-menu-container {padding:10px 65px 0 0;margin-bottom:20px}
.score .scroll-menu-container .tab li a {padding-top:0}
.score .scroll-menu-container .ul-container li a {padding:0}
.score .scroll-menu-container .btn_space_box {top:13px;}
.score .graph_area {height:200px}

/*랭킹*/
.ranking {/*height:500px;*/width:100%;padding:18px 18px 16px;box-sizing:border-box;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.lyrpop_analy.ranking {width:400px;background:#fff;margin-top:10px;border:none;position:relative;height:54vh;top:0;transform:none;left:0;overflow-y:hidden}
.lyrpop_analy.ranking .btn-right {top:0;right:0}
.lyrpop_analy.ranking .btn-close-small {width:17px;height:17px;position:absolute;right:15px;top:15px;background:url(../images/sub/ico_close.png) no-repeat;}
.ranking .header {position:static;top:inherit;height:auto;border-bottom:0}
.ranking .header .title {font-size:20px;text-align:left;font-weight:500;}
.ranking .subj_tit {margin-bottom:20px;margin-top:20px;font-weight:500;font-size:20px}
.ranking .add-text {font-size:16px;color:#222;margin-bottom:10px}
.ranking .inp-box {position:relative;margin-top:20px}
.ranking .filter_selec {margin-top:0;position:absolute;width:100%;box-sizing:border-box;z-index:2;background:#fff;min-height:106px;max-height:300px;padding-bottom:40px;border: 1px solid #d9d9d9;padding:0}
.ranking .filter_selec form {max-height:280px;padding-bottom:40px;overflow-y:scroll;box-sizing:border-box}
.ranking .filter_selec .filter-btn-area {margin-top:0;position:absolute;width:364px;left:-1px;box-sizing:border-box;}
.ranking .cont_wrap {padding-bottom:50px;box-sizing:border-box;height:380px;overflow-y:scroll}
.analysis_lyr .selec {height:40px;line-height:37px;padding:0 5px;letter-spacing:-.9px;background:#fff url(../images/main/btn_arr02.png) no-repeat top 50% right 8px;}
.ranking .selec {border-color:#9e1500}
.badge {position:absolute;top:-5px;left:-28px;display:inline-block;width:35px;height:35px;text-indent:-9999px;}
.ranking01 {background:url(../images/sub/ico_ranking01.png) no-repeat;background-size:contain;}
.ranking02 {background:url(../images/sub/ico_ranking02.png) no-repeat;background-size:contain;}
.ranking03 {background:url(../images/sub/ico_ranking03.png) no-repeat;background-size:contain;}
.ranking04 {background:url(../images/sub/ico_ranking04.png) no-repeat;background-size:contain;}
.ranking05 {background:url(../images/sub/ico_ranking05.png) no-repeat;background-size:contain;}
.rankingbox {height:360px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);position:relative;margin-top:5px;}
.itmbox .rankingbox {height:unset;}
.ranking_item {margin:7px 0}
.ranking_item a {background:#f4f4f4;display:block;padding:10px;text-align:center;border-radius:3px;font-size:14rem;}
.ranking_item a.on {background:#9e1500;color:#fff;}
.ranking_item a .radio-box strong {font-size:14rem;}
.analy_ftgroup .radio-box02>.rd01>input[type=radio] + label {height:38px;line-height:37px;}
.ranking .check-box03>.chk01>input[type=checkbox] + label {width:114px;}
.analy_ftgroup .filter_selec {min-height:87px;/*max-height:400px;overflow-y:scroll;*/box-sizing:border-box}
.filter_selec .filter-btn-area {margin-top:15px;position:relative}
.item-card.rank {padding:12px;}
.item-card.rank .item_info {min-height:auto;width:100%;padding-right:70px;box-sizing:border-box}
.rank .d_info01 {font-size:20px;font-weight:600}
.rank .d_info01 i {color:#c22800;display:inline-block;vertical-align:middle;margin-right:5px;}
.rank .d_info01 .danjiname {font-size:20px;}
.rank .d_info02 {margin-top:5px;}
.rank .value {font-size:18px;font-weight:600;margin-top:5px;position:absolute;top:0;right:6px}
.rankin-subfilter {padding:15px 15px 0;text-align:center;}
.rankin-subfilter form {display:inline-block}
.rankin-subfilter .radio-box02 {display:inline-block}
.rankin-subfilter .radio-box02>.rd01>input[type=radio] + label {width:150px;}
.rankin-subfilter:after {display:inline-block;content:'';clear:both;}
.analysis .mlst_tit .area {display:block;font-size:13px;}
.ranking .lyr {width:400px;background:#fff;margin-top:10px;box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.075), -1px 1px 1px rgba(0, 0, 0, 0.03), 1px 1px 1px rgba(0, 0, 0, 0.03);/*overflow-y:auto;*/position:relative;max-height:70%;}
.analy_menu_bottom h4 {font-size:18px;font-weight:500}
.analy_menu_top .btn-close, .analy_menu_bottom .btn-close {position:absolute;right:15px;top:22px;}
/*.btn_moreview + .itmbox.share {padding:15px;width:100%;position:absolute;bottom:0;margin-left:-10px}*/
.ranking .filter_selec .check-box03, .ranking .filter_selec .radio-box02 {display:flex;flex-wrap:wrap;align-items:stretch;}
.ranking .filter_selec .check-box03 .chk01, .ranking .filter_selec .radio-box02 .rd01 {flex-shrink:1;flex-grow:0;display:flex;}
.ranking .filter_selec .check-box03>.chk01>input[type=checkbox]+label {width:112px;height:unset;line-height:2.2;padding:0;}
.ranking .filter_selec .radio-box02>.rd01>input[type=radio]+label {width:112px;height:unset;line-height:2.2;padding:0;}

/*추천받기*/
.analysis_lyr.recomm_lyr {top:155px}
.recomm {width:400px;height:640px;background:#fff;box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.075), -1px 1px 1px rgba(0, 0, 0, 0.03), 1px 1px 1px rgba(0, 0, 0, 0.03);padding:18px 18px 16px;box-sizing:border-box;position:absolute;top:155px;left:80px;z-index:100}
.recomm .header {position:static;top:inherit;height:auto;border-bottom:0}
.recomm .header .title {font-size:20px;text-align:left;font-weight:500;}
/*.recomm h4 {font-size:18px;font-weight:500;margin-left:0;padding:0}*/
.recomm .btn-close {position:absolute;right:15px;top:15px;}
/*.recomm .wrap {width:100%;height:100%;padding:18px 18px 16px;box-sizing:border-box;}*/
 /* {padding-bottom:0} */
.recomm .process {padding:10px 0 20px}
.recomm .process:after {display:block;content:'';clear:both}
.recomm .subj_tit {margin-bottom:20px;font-weight:500;font-size:20px}
.recomm .btn-horiz .btn {border-radius:0;margin:0;width:100%}
.bul_proc {display:inline-block;width:6px;height:6px;border-radius:30px;background:#ccc;float:left;margin-right:5px;vertical-align:middle;line-height:12px;margin-top:2px}
.bul_proc:last-child {margin-right:0}
.bul_proc:last-child:after {clear:both;content:''}
.bul_proc.prev {background:#922219;}
.bul_proc.on {width:10px;height:10px;background:#922219;margin-top:0}
.recomm .btn-bottom {width:100%;position:absolute;bottom:0;left:0}
.link-history {color:#b9342a;position:absolute;bottom:15px;right:10px;display:inline-block;padding:10px;font-size:15px;}
.link-history:after {display:inline-block;content:'>';margin-left:7px}
.recomm .filter_selec {margin-top:10px;position:relative}
.recomm .filter_selec form {height:310px;overflow-x:overlay;@include overflow-scroll-custom (dark, medium, x);padding-bottom:45px;box-sizing:border-box}
.recomm .filter_selec .check-box03>.chk01>input[type=checkbox] + label {width:100%;padding:0 10px;height:40px;line-height:37px;}
.recomm .filter-btn-area {position:absolute;bottom:0;width:364px}
.recomm-tradetype02 {display:block;margin-top:10px}
.recomm-tradetype03 {display:block;margin-top:30px}
.recomm-tradetype03 .lbl {display:block;margin-bottom:10px;}
.slider-value {text-align:center;padding:15px 0 20px;}
.slider-value span {display:inline-block;font-size:30px;color:#c22800}
.slider-adjust {position:absolute;top:15px;width:100%;}
.btn.btn-adjust-minus {position:absolute;left:-10px;top:8px;width:30px;height:30px;border:1px solid #cdcdcd;padding:0;color:#cdcdcd;font-size:20px;line-height:1}
.btn.btn-adjust-plus {position:absolute;right:-10px;top:8px;width:30px;height:30px;border:1px solid #cdcdcd;padding:0;color:#cdcdcd;font-size:20px;line-height:1}
.recomm button.btn-adjust-minus, .recomm button.btn-adjust-plus {border-radius:35px;}
.order-container li {display:block;border:1px solid #e9e9e9;margin-bottom:8px}
.order-container li a {display:block;width:100%;padding:8px 15px;text-align:center;position:relative;box-sizing:border-box;font-size:17px}
.order-container li a.selected01:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'1';position:absolute;left:20px;font-size:12px;line-height:22px}
.order-container li a.selected02:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'2';position:absolute;left:20px;font-size:12px;line-height:22px}
.order-container li a.selected03:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'3';position:absolute;left:20px;font-size:12px;line-height:22px}
.order-container li a.selected04:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'4';position:absolute;left:20px;font-size:12px;line-height:22px}
.order-container li a.selected05:before {display:inline-block;width:24px;height:24px;border-radius:30px;background:#c43713;color:#fff;content:'5';position:absolute;left:20px;font-size:12px;line-height:22px}
.btn-reset {position:absolute;bottom:65px;right:15px;display:inline-block;padding:10px;font-size:14px}
.btn-reset:before {display:inline-block;vertical-align:middle;content:'';background:url("../images/sub/ico_refresh.png") no-repeat;width:14px;height:14px;background-size:contain;margin-right:5px}
.newreomm {height:auto;padding:25px;top:0;left:0;}
.newreomm .btn-horiz {padding:10px 0 0}
.recomm .radio-box02>.rd01 {width:calc(100% / 2.01)}
.recomm .radio-box02>.rd01>input[type=radio] + label {width:100%;padding:0 10px;height:40px;line-height:37px;}
.recomm .radio-box02.three>.rd01 {width: calc(100% / 3);margin: 0 -1px 0 0;}
.recomm .radio-box02.three>.rd01>input[type=radio] + label {padding:0 20px}
.lyrpop_analy img.img_recomm {margin-top:30px}
.recomm .check-box03.three>.chk01 {width: calc(100% / 3);margin: 0 -1px 0 0;}
/*.analysis_lyr .header .btn-right {right:0}*/
.recomm .cont {position:relative;height:420px;overflow-x:overlay;@include overflow-scroll-custom (dark, medium, y);}
.recomm_group {margin-bottom:30px;overflow-y:auto;}
.recomm_group .lbl {font-size:15px;display:block;margin-bottom:10px;}
.recomm_group .recomm-tradetype01 {margin-bottom:8px;}
.recomm_filter ul {overflow:hidden;display:flex;flex-wrap:wrap;}
.recomm_filter li {margin:2px;display:inline-block;height:30px}
.recomm_filter li a {font-size:13px;display:inline-block;padding:6px 25px 6px 12px;max-width:216px;box-sizing:border-box;border-radius:30px;background:#ca0202 url(../images/sub/ico_arrowr.png) center right 10px no-repeat;color:#fff;letter-spacing:-.5px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.recomm_filter li:last-child a {min-width:87px;width:auto;padding-right:25px}
.newreomm + .recomm {padding:0;height:466px;top:175px;left:0;min-height:175px}
.newreomm + .recomm .danjicompare {height:466px;}
/*.recomm .danjicompare {height:500px;padding:0}*/
.recomm .danjicompare .itmbox:first-child {margin-top:0}
.recomm .danjicompare .itmbox.itmbox-term + .itmbox.share {margin:0}
.recomm .selec {height:40px;line-height:37px;padding:0 5px;letter-spacing:-.9px;}
.recomm .danjicompare img {object-fit:contain}
.newreomm .lyr_bottom {position:relative;min-height:150px;max-height:400px;}
.newreomm .lyr_bottom .lyrpop_wrap {padding-bottom:50px;}
.newreomm .lyr_bottom .lyr_inner {padding:20px 0;}
.newreomm .lyr_bottom .btn-close {right:0}
.newreomm .lyr_bottom .subj_tit {font-size:18px;text-align:left;margin-bottom:10px;padding-right:20px;}
.newreomm .lyr_bottom .filter_selec form {height:220px;}
.newreomm .lyr_bottom .filter-btn-area {width:100%;left:0}
.newreomm .lyr_bottom .btn-horiz {padding:0;width:100%;position:absolute;left:0;bottom:0}
.newreomm .lyr_bottom .lyr_inner p {font-size:13px;text-align:left}
.newreomm .recomm_filter + .cont_wrap {margin-top:15px;padding-top:15px;border-top:1px solid #eaeaea;}
.recomm .m_tit small {display:block;font-size:14px;font-weight:300;line-height:1.5;margin-top:10px;}


/* 주간실거래가 */
.weekly {height:500px;width:100%;padding:18px 18px 16px;box-sizing:border-box;}
/*.weekly .wrap {width:100%;height:100%;padding:18px 18px 16px;box-sizing:border-box;}*/
.weekly .header {position:static;top:inherit;height:auto;border-bottom:0}
.weekly .header .title {font-size:20px;text-align:left;font-weight:500;}
.weekly .container {padding-top:55px;}
.weekly .subj_tit {margin-bottom:20px;margin-top:20px;font-weight:500;font-size:18px}
.weekly .add-text {font-size:16px;color:#222;margin-bottom:10px}
.weekly .inp-box {position:relative}
.weekly .filter_selec {margin-top:-1px;position:absolute;width:100%;box-sizing:border-box;z-index:2;background:#fff;/*padding-bottom:40px*/;padding:10px;border:1px solid #d0d2d6;}
.weekly .filter_selec form {height:180px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);box-sizing:border-box}
.weekly .filter_selec .filter-btn-area {margin-top:0;position:absolute;width:362px;left:0;box-sizing:border-box;}
.weekly .cont_wrap {padding-bottom:50px;box-sizing:border-box;height:380px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.weekly .addexp {position:absolute;bottom:80px;left:20px}
.weekly .addexp .week {font-size:17px;}
.weekly .addexp span {display:block;}
.weekly .addexp span .end {display:inline-block;margin-bottom:20px}
.weekly .date {margin-right:5px;font-size:20px}
.weekly .summary {padding:20px 15px 10px}
.weekly .summary h2 small {font-size:16px;font-weight:400;}
.weekly .summary .count {font-size:20px;}
.weekly .btn-bottom {width:100%;}
.wkheader {height:65px}
.item-card.wk .item_info {display:block;width:100%;padding-bottom:10px;margin-bottom:10px;border-bottom:1px solid #efefef;font-weight:500;min-height:0}
.item-card.wk {padding-right:12px;padding-bottom:12px;}
.weekly .dealbox {padding:0 10px}
.deal-lst td:nth-child(1) {width:15%}
.deal-lst td:nth-child(2) {width:15%}
.deal-lst .date {color: color(normal, 030);}
.deal-lst .deal-type span {display:inline-block;margin:0}
.deal-lst .deal-type .real-price {font-size:16px;padding:0;vertical-align:middle}
.deal-lst .deal-type .floor {font-size:14px;font-weight:500;margin-left:5px;vertical-align:middle}
.weekly .check-box03.three>.chk01 {width: calc(100% / 3);margin: 0 -1px 0 0;}
.weekly .check-box03>.chk01>input[type=checkbox] + label {width:100%}
.weekly .btn-bottom {width:100%;position:absolute;bottom:0;left:0}
.weekly .btn-bottom .btn {margin:0;;width:100%;border-radius:0}
.panel-box .summary {padding:0 0 12px}
.panel-box .summary h2 {font-size:20px;}
.panel-box .summary h2 small {font-size:15px;font-weight:400;}
.panel-box .summary .count {font-size:16px;}
.analysis .panel-group.result_wk {left:490px;top:20px;height:705px;box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.075), -1px 1px 1px rgba(0, 0, 0, 0.03), 1px 1px 1px rgba(0, 0, 0, 0.03);}
.result_wk .item-card .danjiname {font-size:17px;font-weight:500}
.result_wk .btn-left {right:5px;left:inherit}
.result_wk .panel-box {padding:10px 0;background:#efefef;height:calc(100% - 44px);padding-bottom:85px}
.result_wk .item-card {width:100%;border:none}
.analysis .panel-group.result_wk .share {position:absolute;width:100%;bottom:0;padding-bottom:20px;margin-left:0}
.panel-group.result_wk .panel-header .title {font-size:16px}
.result_wk .btn_moreview {margin-bottom:10px}

/*개발호재*/
.svg-group {display:flex;flex-wrap:wrap;align-items:stretch;align-content:stretch;margin-top:10px;padding:15px 10px 0 10px;border-top:1px solid #f0f0f0;}
.svg-group .svg {cursor:pointer;flex:0 0 33%;display:inline-block;font-size:13px;text-align:center;color:#747481;position:relative}
.svg-group .svg svg {width:50px;height:20px;}
.svg-group .svg div {color:#aaa;margin-top:3px;}
.svg-group .svg.on div {color:#ba1900;}
.svg-group .svg-struc.on div {color:#8957a1;}
.svg-group .svg-restruc.on div {color:#ff7d29;}
.ico_struc {display:block;width:25px;height:25px;background:url("../images/sub/ico_struc.png") no-repeat;position:absolute;top:-10px;left:50%;transform:translatex(-50%)}
.svg.on .ico_struc {width:25px;height:25px;background:url("../images/sub/ico_struc_on.png") no-repeat;background-size:contain;}
.goodnews div {position:relative}
.goodnews div .nametag {display:inline-block;padding:3px 6px;background:#ba1900;color:#fff;position:absolute;top:-47px;font-size:12px;width:auto;text-align:center;/*right:-10px;*/transform:translatex(-50%);left:50%;white-space:nowrap;opacity:.9}
.goodnews div.struc .nametag {display:inline-block;padding:3px 6px;background:#8957a1;color:#fff;position:absolute;top:-47px;font-size:12px;width:auto;text-align:center;/*right:-10px;*/transform:translatex(-50%);left:50%;white-space:nowrap;opacity:.9}
.goodnews div.train .nametag {position:absolute;background:#fff;font-weight:600;transform:none;right:inherit}
.svgleg {width:20px;}
.goodnews div.restruc .nametag {display:inline-block;padding:3px 6px;background:#fb8538;color:#fff;position:absolute;top:-20px;font-size:12px;width:auto;text-align:center;left:50%;transform:translatex(-50%);white-space:nowrap;opacity:.9}
.goodnews div .nametag span {display:block;}
.goodnews div .nametag .nt02 {font-size:11px;margin-top:-2px}
.goodnews .ico_struc {width:25px;height:25px;background:url("../images/sub/ico_struc_on.png") no-repeat;background-size:contain;}
.goodnews-cont {padding:10px 20px 20px}
.goodnews-cont .inp-group {margin-bottom:15px}
.goodnews-cont .inp-group .lbl {font-size:15px;margin-bottom:5px;display:block;}
.goodnews-cont .txtarea {height:250px;}
.goodnews div.train .nametag {position:absolute;border:3px solid #ba1900;background:#fff;color:#ba1900;font-weight:600;transform:none;right:inherit}
.svgleg {width:35px;}
.btn_addnew {margin-top:15px;width:100%}

.analysis_lyr .lyr_box {position:relative}
.analysis_lyr .link_txt {display:inline-block;position:absolute;right:45px;top:20px;font-size:14px;font-weight:300}
.analysis_lyr .link_txt:hover {text-decoration:underline;}

.pop .goodnews-cont {padding:0}
.pop .goodnews-cont .lbl {text-align:left}
.pop .goodnews-cont .radio-box02>.rd01>input[type=radio] + label {width:105px;padding:0}
.pop .goodnews-cont+.btn-horiz .btn {margin:0}
.goodnews-cont p {text-align:left;margin-bottom:15px}
.goodnews-cont .radio-block .radio {display:block;margin-bottom:5px;}

// root-dawin
:root.root-dawin {
	input[name="url"] {width:100%}
}