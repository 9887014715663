@import '_variables';

/**
 * @type module
 * @name data-list
 */


 $list-accent: (
	normal-10: #000000,
	normal-01: #353535,
	normal-02: #6a6a6a,
	normal-03: #9d9d9d,
	normal-04: #cecece,
	normal-00: #ffffff,
	accent-01: #f64e23,
	accent-02: #5a67be,
	accent-03: #67ad5b,
	accent-04: #ef9b43,
);

$list-outline: (
	'small-3x'	2rem 'small-3x',
	'small-2x'	4rem 'small-2x',
	'small-1x'	4rem 'small-1x',
	'small'		4rem 'small',
	'medium'	4rem 'medium',
	'large'		4rem 'large',
	'large-1x'	4rem 'large-1x',
	'large-2x'	4rem 'large-2x',
	'large-3x'	4rem 'large-3x',
);

p.data-list {
	margin: 0;
}

p.data-item {
	margin: 0;
}

ul.menu-list,
ul.navi-list,
ul.data-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* data-list */
.data-list {
	>li.data-item {
		a.em {
			text-decoration: underline;
		}

		>.para {
			margin: 8rem 0;
		}
		>.button-display.module-a {
			margin: 8rem 0;
		}
		.wbr {
			display:block;
		}
	}
}

.data-list.module-b,
.data-list.module-a {
	// accent
	@each $name, $color in $list-accent {
		&.#{$name} {
			.data-item {
				color: $color;
			}
		}
	}

	// outline
	@each $name, $row-gap, $size in $list-outline {
		&.#{$name} {
			row-gap: $row-gap;
			.data-item {
				@include font((outline: body, size: $size)...);
			}
		}
	}
}

.data-list.module-a {
	display: flex;
	flex-direction: column;

	&.style-a {
		.data-item {
			position: relative;
			padding-left: 1.5em;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 1.5em;
				min-width: 1.5em;
				height: 1.5em;
				min-height: 1.5em;
				mask-repeat: no-repeat;
				mask-position: center;
				mask-size: cover;
				text-align: center;
			}
		}

		&.type-a {.data-item::before {mask-image: icon(bullet-dash, color(base, 100))}}
		&.type-b {.data-item::before {mask-image: icon(bullet-square, color(base, 100))}}
		&.type-c {.data-item::before {mask-image: icon(bullet-dot, color(base, 100))}}
		&.type-d {.data-item::before {mask-image: icon(bullet-star, color(base, 100))}}
		&.type-e {.data-item::before {mask-image: icon(bullet-check, color(base, 100))}}

		// accent
		@each $name, $color in $list-accent {
			&.#{$name} {
				.data-item {
					&::before {background-color: $color;}
				}
			}
		}
	}

	&.style-b {
		.data-item {
			padding-left: 0;
		}
	}
}


.data-list.module-b {
	display: flex;
	
	&.type-a {
		flex-wrap: wrap;
		row-gap: 4rem;
	}

	&.style-a {
		overflow: hidden;

		.data-item {
			position: relative;
			left: -1em;
			padding-left: 1em;

			&::before {
				content: ',';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 1em;
				min-width: 1em;
				height: 1.5em;
				min-height: 1.5em;
				mask-repeat: no-repeat;
				mask-position: center;
				mask-size: cover;
			}
		}
	}
	&.style-e {
		.data-item {
			padding: 24rem;
			
			.data-area {
				flex-direction: column;
			}
		}
	}
	&.style-f {
		gap: 0;
		justify-content: space-around;
		.data-item {
			display: flex;
			flex-direction: column;
			gap: 4rem;	
			align-items: center;
		}
	}
}




/**
 * @type module
 * @name data-display
 */

/*
 * module outline
 *
 * @ $line-height-head
 * @ $line-height-body
 * @ $font-size
 * @ $font-weight
 * @ $margin = $line-height - 4
 */
 $data-display-module-outline: (
	'x-small'		18rem  16rem  1.50 400 14rem,
	'small'			20rem  18rem  1.50 400 16rem,
	'medium'		24rem  20rem  1.50 400 20rem,
	'large'			28rem  24rem  1.50 400 24rem,
	'x-large'		32rem  28rem  1.50 400 28rem,
);

/*
* @ module accent
*
* @ $data-head: text-color
* @ $data-head: icon-color
* @ $data-body: text-color
*/
$data-display-module-accent: (
	'normal-02' color(base, 080) color(base, 020) color(base, 020),
	'normal-03' color(base, 080) color(base, 020) color(base, 020),
	'normal-04' color(base, 040) color(base, 040) color(base, 040),
);

.data-display {
	.data-list {
		.data-item {
			.data-wrap {
				.data-head {
					.data-name {
						&[class*=symbol-ltr-]::before,
						&[class*=symbol-rtl-]::after {
							content: '';
							display: block;
							background-repeat: no-repeat;
							background-size: cover;
						}
					}
				}
			}
		}
	}
	// outline
	@each $name, $line-height-head, $line-height-body, $font-size, $font-weight, $margin in $data-display-module-outline {
		&.#{$name} {
			.data-item {
				.data-wrap {
					.data-head {
						font-weight: $font-weight;
						.data-name {
							line-height: $line-height-head;
							font-size: calc($line-height-head / $font-size);
						}
					}
					.data-body {
					}
				}
			}
			
			&.style-a {
				.data-item {
					.data-wrap {
						.data-head {
							.data-name {
		
							}
						}
						.data-body {
		
						}
					}
				}
			}
		}
	}

	// accent
	@each $name, $head-color, $icon-color, $body-color in $data-display-module-accent {
		&.#{$name} {
			.data-head {
				color: $head-color;
				a.data-name {
					@each $icon-name, $icon in $symbol-list {
						&.symbol-ltr-#{$icon-name}::before {background-image: icon($icon-name, $icon-color);}
						&.symbol-rtl-#{$icon-name}::after {background-image: icon($icon-name, $icon-color);}
					}
				}
			}
		}
	}
}

// module-a
.data-display.module-a {
	.data-list {
		.data-item {
			.data-wrap {
				display: flex;
				// align-items: center;
				.data-head {
					.data-name {
						color: color(base, 040);
					}
				}
				.data-body {
					color: color(base, 070);
				}
			}			
		}
	}
	&.type-c {
		.data-list {
			align-items: center;

			.data-wrap {
				column-gap:4rem;
			}
		}
		
	}
	&.type-d {
		.data-list {
			align-items: center;

			.data-wrap {
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
			}
		}
		
	}

	// outline module-a
	@each $name, $line-height-head, $line-height-body, $font-size, $font-weight, $margin in $data-display-module-outline {
		&.#{$name} {
			.data-item {
				.data-wrap {
					.data-head {
						flex-basis: calc($line-height-head/$font-size) * 5;
						flex-shrink: 0;
					}
					.data-body {
						flex: 1 1 100%;
						// width:100% //pc ex 경우
						line-height: $line-height-head;
						font-size: calc($line-height-head / $font-size);
					}
				}
				& + .data-item {
					margin-top: $margin;
				}
			}
		}
	}
}

// module-b
.data-display.module-b {
	.data-list {
		display: flex;
		flex-wrap: wrap;
		.data-item {
			.data-wrap {
				.data-head {
					display: flex;

					.data-name {
						flex-shrink: 0;
						display: flex;
						color: color(base, 080);
						text-decoration: none;
					}
				}
				.data-body {
					color: color(base, 060);
				}
			}
		}
	}

	// outline module-b
	@each $name, $line-height-head, $line-height-body, $font-size, $font-weight, $margin in $data-display-module-outline {
		&.#{$name} {
			.data-item {
				@include display-column(2, $margin, $margin - 4);
				.data-wrap {
					.data-head {
						.data-name {
							&[class*=symbol-ltr-]::before,
							&[class*=symbol-rtl-]::after {
								width: $line-height-head;
								min-width: $line-height-head;
								height: $line-height-head;
							}
						}
					}
					.data-body {
						line-height: $line-height-body;
						font-size: calc($line-height-body / $font-size);
					}
				}
			}
			// icon
			&[class*=symbol-ltr-] .data-name::before,
			&[class*=symbol-rtl-] .data-name::after {
				width: $line-height-head;
				min-width: $line-height-head;
				height: $line-height-head;
			}
		}
	}	
}



.atch-file.module-b {
	.data-list {
		display: flex;
		flex-wrap: wrap;
		// gap: 8rem;
	
		.data-item {
			@include display-column(3, 8rem, 8rem);

			.data-wrap {
				flex: 1 1 100%;
				display: flex;
				position: relative;	
				z-index: 1;

				.data-body {
					flex: 1 1 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					width: 100%;
					aspect-ratio: 1 / 1;
					border-radius: 8rem;
					overflow: hidden;
					box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);
					
					img {
						display: block;
						position: absolute;
						z-index: -1;
						width: 100%;
						height: 100%;
						max-width: initial;
						object-fit: cover;
					}
				}

				.data-func {
					position: absolute;
					top: 0;
					right: 0;

					.btn.delete {
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 32rem;
						height: 32rem;

						svg {
							width: 24rem;
							height: 24rem;
							fill: rgba(0,0,0.75)
						}
					}
				}
			}
		}
	}
}

// module-c
.data-display.module-c {
	.data-list {
		display: flex;
		flex-wrap: wrap;
		.data-item {
			display: flex;
			gap: 8rem;
		}
	}
}

// module-d
.data-display.module-d {
	.data-list {
		display: flex;
		flex-wrap: wrap;
		gap: 40rem;

		.data-item {
			display: flex;
			gap: 8rem;

			&::before {
				content:'';
				width: 1rem;
				height: 36rem;
				background-color: #cecece;
			}

			.data-wrap {
				display: flex;
				flex-direction: column;
				gap: 8rem;
			}
		}
	}
	&.type-c {
		.data-wrap {
			text-align: center;
		}
	}
}

/* */
.order-detail {
	padding-bottom: 24rem;
	border-bottom: 1rem solid color(normal, 100);

	.data-display {

		&.type-c {
			.data-list {
				display:flex;
				flex-direction: column;
				gap: 8rem;				

				.data-item {
					display:flex;
					justify-content: space-between;
					
					.head {
						display: flex;
    					align-items: center;
						@include font((outline: body, size: small-1x, weight: regular)...);
					}
					.body {
						display: flex;
    					align-items: center;
						@include font((outline: body, size: small-1x, weight: bold)...);
					}
				}
			}
		}
		
	}
}
.total-amount {
	padding: 24rem 0;

	.amount-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	+.display-board {
		margin-bottom: 24rem;

		+.option-select {
			display: flex;
			flex-direction: column;
			row-gap: 8rem;
		}
	}	
}

.review-display {

	&.style-b {
		
		.review-list {
			display: flex;
			gap: 16rem;
			align-items: stretch;

			.review-item {
				flex-shrink: 0;
				width: 300rem;
				height: 360rem;
				display: flex;
				flex-direction: column;
				border-radius: 16rem;
				padding: 24rem;
				background-color: #f7f7f7;
				box-sizing: border-box;

				.review-head {
					display: flex;
					gap: 16rem;
					align-items: center;

					.review-thumbnail {
						display: block;
						position: relative;
						width: 72rem;
						height: 72rem;
						border-radius: 100%;
						overflow: hidden;
						background: icon(no-image, color(normal, 040)) no-repeat center/32rem;
							
						&::after {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							border-radius: 100%;
							box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);
						}
	
						img {
							display: block;
							position: relative;
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}

					.review-info {
						.data-list {
							display: flex;
							flex-direction: column;
							row-gap: 4rem;
							justify-content: center;

							.data-item {
								.head {
									display: none;
								}
								&.name {
									@include font((outline: body, size: small, weight: bold)...);
								}
							}
						}
					}
				}
				.review-body {
					.review-data {
						&.grade {
							.head {
								@include hidden;
							}
							.body {
								@include grade(20rem);
								justify-content: flex-start;
	
								.point {
									@include hidden;
								}							
							}
						}
					}	
					.review-content {
						.para {
							.wbr {
								display: block;
							}
						}
					}				
				}
				
			}
		}
	}
}

.data-display.grade-average {
	margin-bottom: 48rem;

	.data-list {
		display: flex;
		flex-direction: column;
		gap: 8rem;
		.data-item {
			.data-area {
				position: relative;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				.data-head {
					position: absolute;
					left: 8rem;
					z-index: 1;
					display: flex;
					gap: 6rem;
					align-items: center;

					.data-figure {
						display: block;
						width: 24rem;
						height: 24rem;
					}
					.data-name {
						@include font((outline: body, size: small-1x, weight: regular)...);
						color: color(normal, 010);
						white-space: nowrap;
						font-size: 15rem;
					}

				}
				.data-body {
					-webkit-box-flex: 1;
					-ms-flex-positive: 1;
					flex-grow: 1;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					height: 40rem;
					background-color: color(normal, 060);
					border-radius: 8rem;

					.star-score {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						position: absolute;
						top: 0;
						bottom: 0;
						border-radius: 4rem;

						.graph {
							-webkit-box-flex: 1;
							-ms-flex-positive: 1;
							flex-grow: 1;
							display: block;
							height: 40rem;
							background-color: #FFEEEA;
							border-radius: 4rem;
						}						
					}
					.star-point {
						@include font((outline: body, size: small-1x, weight: bold)...);
						color: color(accent, 010);
						position: absolute;
						right: 8rem;
						white-space: nowrap;
					}
				}
			}
			&.first {
				.data-area {
					.data-body {
						.graph {
							background-color: #FFBDAC;
						}
					}
				}
			}
			&.second {
				.data-area {
					.data-body {
						.graph {
							background-color: #FFD2C7;
						}
					}
				}
			}
			&.third {
				.data-area {
					.data-body {
						.graph {
							background-color: #FFE1DA;
						}
					}
				}
			}
		}
	}
}

.data-display.grade-score {
	.data-item {
		display: flex;

		.head {
			@include hidden;
		}

		.body {
			display: block;
		}

		&.name {
			@include font((outline: body, size: small-1x, weight: bold)...);
			color: color(normal, 010);
		}

		&.grade {
			margin-bottom: 24rem;
			.body {
				@include grade(40rem);
				padding-left: 80rem;
				position: relative;
				flex-direction: column;
				align-items: flex-start;
				gap: 2rem;

				.point {
					position: absolute;
					left: 0;
					top: 0;
					@include font((outline: head, size: large-3x, weight: bold)...);
				}
			
				.count {
					display: block;
					margin-left: 8rem;
					@include font((outline: head, size: small-3x)...);
					color: color(normal, 010);

					&::before {
						content:'리뷰';
						display:inline-flex;
						margin-right: 4rem;
						@include font((outline: head, size: small-3x)...);
					}
				}
			}
		}		
	}
}

.data-display {
	&.linkbox-grey {
		.data-list {
			border-radius: 8rem;
			background-color: color(base, 005);
			flex: 100%;
			.data-item {
				flex: 100%;
				.data-wrap {
					padding: 24rem;
					align-items: center;
					column-gap: 8rem;
					display: flex;
					&::after {
						flex-shrink: 0;
						content: '';
						display: block;
						width: 24rem;
						height: 24rem;
						background: icon(chevron-right, color(normal, 040)) no-repeat center/cover;
					}
					.data-body {
						flex-direction: column;
						flex: 100%;
						row-gap: 4rem;
						display: flex;
						flex-grow: 1;
					}
				}
			}
		}
	}
}