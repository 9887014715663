@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */

/* 커뮤니티 */
.community-sub-visual {
	height:160px;
	background-image: url(../images/sub/bg_community.jpg);
	background-repeat:no-repeat;
	background-position:50%;
	background-size:cover;
	position:relative;
	/* margin-top:53px; */
}
.community {
	background:#f3f3f3;
	height: auto;
	width:100%;
	margin:0 auto;
	padding-top:30px;
	padding-bottom:100px;
	min-height:300px;
	position:relative;
}
.community-header {
	width:800px;
	padding:20px 0;
	margin:0 auto;
	display:flex;
	position:relative;
	z-index:1
}
.community-header .search_box {
	background:#fff;
	width:calc(100% - 50px);
	border-radius:0
}
.community-header .search_box .inp {
	background:#fff;
	border-radius:0;
	/* padding-right:70px */
	padding-right:40px
}
.community-header .search_box .btn_sch {
	background:url(../images/ico_search.png) no-repeat;
	background-size: contain;
	width:20px;
	height:20px;
}
.community-header .btn_filter {
	background: #7A1D01 url(../images/sub/ico_filter_wh.svg) no-repeat 50% 28%;
	color:#fff;
	position:relative;
	height:60px;
	font-size:0;
	width:50px
}
.community-header .btn_filter:after {
	bottom:11px;
}
.community-header .btn-mypage {
	right: 51px;
}
.community-header .search_result_area {
	top:80px;
	border-radius: 0;
}
.community-article {
	padding:0;
	width:100% !important;
	min-height:300px;
}
.community-article .check-box {
	display:block;
	height:auto;
	width:800px;
	margin:0 auto;
}
.card-community-box {
	padding:0 !important;
	display:flex;
	flex-flow:row wrap;
	justify-content:left;
	max-width:1200px;
	margin:0 auto;
}
.community-filter-area {
	padding: 0;
	width:800px;
	margin:0 auto;
	box-sizing:border-box;
	position:relative;
}
.community-filter-area .filter-result {
	padding:0 10px;
	display: flex;
	flex-flow: row wrap;
}
.filter-result .hashtag {
	padding:4px 8px 4px 10px;
	background:#901E00;
	color:#fff;
	border-radius:20px;
	font-weight:300;
	margin:3px
}
.hashtag .btn-close {
	background:url(../images/sub/ico_close_whs.svg) no-repeat;
	width:12px;
	height:12px;
	display:inline-block;
	text-indent:-9999px;
	background-size:contain;
	vertical-align: middle;
	margin-top:-3px;
	margin-left:3px;
	padding:0
}
.card-community-list {
	padding:20px;
	background:#fff;
	position:relative;
	margin:10px;
	flex: auto;
	min-width: 380px;
	max-width: 300px;
	box-sizing: border-box;
	display:flex;
	flex-direction: column;
	justify-content: end;
	box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
	transition: box-shadow .3s linear;
	border-radius:10px
}
.card-community-list:hover,
.card-community-list.on {
	box-shadow: -6px 40px 60px rgb(0 0 0 / 6%), -6px 20px 20px rgb(0 0 0 / 10%);
}
.ccl-hashtag-area {
	padding-bottom:10px;
	flex:0
}
.card-community-list .hashtag {
	color:#3E70C7;
	font-size:14px;
	margin-right:5px;
}
.card-community-list .hashtag:first-child {
	font-weight:600
}
.card-community-list .hashtag.keyword {
	color:#901E00
}
.ccl-content {
	padding-bottom:12px;
	font-size:16px;
	flex:1
}
.card-community-list .ccl-content p {
	margin-top:0;
	padding-right:0;
	position:relative;
	font-size:16px;
}
.ccl-content p {
	overflow:hidden;
	text-overflow: ellipsis;
	display:-webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	font-size:16px
}
.ccl-content p a {
	color: #222;
	/* text-decoration: underline; */
	display:inline-block;
}
.ccl-userinfo {
	font-size:15px;
	flex:0
}
.user-nick {
	display:inline-block;
	font-weight:600;
	vertical-align: middle;
	margin-bottom:3px;
	margin-top:3px;;
	display:flex;
	align-items: center;
	/* width:100%; */
	width:100%;
	column-gap: 4px;
}
.user-nick:before {
	display:inline-block;
	content:'';
	width:18px;
	height:18px;
	background:url(../images/sub/ico_user.svg) no-repeat center center;
	vertical-align: middle;
}
.user-location {
	display:inline-block;
	font-size:11px;
	background:#eee;
	color:#666;
	padding:3px 8px;
	border-radius: 20px;
	margin-left:6px;
	vertical-align: middle;
}
.user-agent {
	display:flex;
	flex-grow:row wrap
}
.user-agent .agent-office {
	display:inline-block;
	max-width:47%;
	overflow: hidden;
	white-space:nowrap;
	text-overflow:ellipsis;  
	margin-right:3px;
	vertical-align: middle;  
}
.chat-agent {
	display:inline-block;
	content:'';
	width:20px;
	height:20px;
	background:url(../images/sub/ico_chat01.svg) no-repeat center center;
	background-size:contain;
	vertical-align: middle;
	margin-top:-4px;
	margin-left:6px;
}
.ccl-userinfo .date {
	font-size:13px;
	color: color(normal, 030);
	margin-top:5px;
}
.ccl-tool {
	position:absolute;
	right:20px;
	bottom:20px;
}
.comment-set {
	display:flex;
	flex-direction: row;
	align-items: center;
}
.comment-set .comment {
	font-size:14px;
	display:inline-block;
	margin-right:12px;
	line-height:1.6
}
.comment-set .comment:before {
	display:inline-block;
	content:'';
	width:18px;
	height:18px;
	background:url(../images/sub/ico_comm.svg) no-repeat center center;
	vertical-align: middle;
	margin-top:-2px;
	margin-right:3px;
}
.comment-set .like {
	font-size:14px;
	display:inline-block;
	line-height:1.6
}
.comment-set .like:before {
	display:inline-block;
	content:'';
	width:18px;
	height:18px;
	background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cfilter id='a'%3E%3CfeColorMatrix in='SourceGraphic' values='0 0 0 0 0.329412 0 0 0 0 0.329412 0 0 0 0 0.329412 0 0 0 1.000000 0'/%3E%3C/filter%3E%3Cpath d='M15.512 6a2.424 2.424 0 0 1 1.814.774c.456.498.659 1.16.554 1.815l-1.089 6.748c-.177 1.11-1.187 1.926-2.36 1.913H5.527c-.436 0-.79-.336-.79-.75V8.25c0-.105.024-.209.069-.305l3.158-6.75A.792.792 0 0 1 8.684.75c1.744 0 3.158 1.343 3.158 3V6h3.67Zm-9.196 9.75h8.125c.393.004.73-.268.79-.639l1.088-6.749a.724.724 0 0 0-.184-.604.814.814 0 0 0-.614-.258h-4.468c-.436 0-.79-.336-.79-.75v-3c0-.67-.461-1.236-1.099-1.43L6.316 8.41v7.34ZM4.736 9H3.159c-.436 0-.79.336-.79.75V15c0 .414.354.75.79.75h1.579V9Zm.79 8.25H3.158C1.85 17.25.789 16.243.789 15V9.75c0-1.243 1.06-2.25 2.369-2.25h2.368c.436 0 .79.336.79.75v8.25c0 .414-.354.75-.79.75Z' id='b'/%3E%3C/defs%3E%3Cg transform='translate(-314 -104)' filter='url(%23a)' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(314 104)'%3E%3Cmask id='c' fill='%23fff'%3E%3Cuse xlink:href='%23b'/%3E%3C/mask%3E%3Cuse fill='%23000' fill-rule='nonzero' xlink:href='%23b'/%3E%3Cg mask='url(%23c)' fill='%237A1D05'%3E%3Cpath d='M0 0h18.947v18H0z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	background-repeat:no-repeat;
	background-position: center center;
	vertical-align: middle;
	margin-top:-4px;
	margin-right:3px;
}
.comment-set .like.on {
	color:#e50000
}
.comment-set .like.on:before {
	background:url(../images/sub/ico_likeon.svg) no-repeat center center;
	vertical-align: middle;
}
.comment-set .report {
	margin-left:12px;
	height:auto;
}
.comment-set .report:before {
	display:inline-block;
	content:'';
	width:18px;
	height:18px;
	background:url(../images/sub/ico_report.svg) no-repeat center center;
	vertical-align: middle;
	margin-top:-4px;
}
.link-write-community {
	display: inline-block;
	position: fixed;
	bottom: 80px;
	right: 100px;
	z-index: 100;
	width: 75px;
	height: 75px;
	background-color: #ad2400;
	border-radius: 100px;
	color: #fff;
	padding-top: 38px;
	box-sizing: border-box;
	text-align: center;
	box-shadow: 0 10px 10px rgb(0 0 0 / 20%), 0 4px 4px rgb(0 0 0 / 15%);
}
.link-write-community:before {
	display: inline-block;
	content: '';
	width: 40px;
	height: 40px;
	background: url("../images/sub/ico_write.svg") no-repeat;
	background-size: contain;
	position: absolute;
	top: 12px;
	left: 50%;
	transform: translatex(-50%);
}
.text-moreviw {
	margin-left:0;
	color: rgb(163, 163, 163);
	text-decoration: underline;
	/* position:absolute;
	right:0;
	bottom:1px; */
	font-size:14px;
	display:block
}
.ccl-pic {
	margin:0 -20px 14px;
	height:200px;
	position:relative;
}
.ccl-pic .swipe-item {
	width:100%;
	height:200px
}
.ccl-pic .swipe-item img {
	width:100%;
	height:200px;
	object-fit: cover;
}
.swipe-indicator {
	position: absolute;
	left:50%;
	bottom:0;
	margin: 15px auto;
	height: 9px;
	line-height: 0;
	overflow: hidden;
	text-align:center
}
.swipe-indicator-item {
	display: inline-block;
	width: 7px;
	height: 7px;
	margin: 0 3px;
	background: #ddd;
	-webkit-border-radius: 3.5px;
	-moz-border-radius: 3.5px;
	-ms-border-radius: 3.5px;
	-o-border-radius: 3.5px;
	border-radius: 3.5px;
}
.swipe-indicator-item.current {
	background: #7a1d05
}
.pop-panel .card-community-list .ccl-content p {
	overflow:unset;
	text-overflow: unset;
	-webkit-line-clamp: unset;
	-webkit-box-orient: unset;
	word-break: break-all;
}
.ccl-shortcut-area {
	margin-bottom:20px;
}
.shortcut {
	display:inline-block;
	padding:5px 10px;
	border-radius:50px;
	border:1px solid #666;
	color:#666;
	font-size:12px;
	line-height:1;
	margin-top:-2px;
	margin-right:5px;
}
.shortcut:after {
	/* display:inline-block;
	content:'';
	width:4px;
	height:9px;
	background:url(../images/sub/ico_btnarrow.png) no-repeat center right;
	margin-left:6px;
	vertical-align:middle; */
	display:inline-block;
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: never; 
	text-decoration: inherit;
	margin-right: 0;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1.1em;
	margin-left: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; 
	font-size:14px;
	content: '\e838';
	margin-left:5px;
}
.community-article .ccl-shortcut-area {
	margin-bottom:0;
	padding:12px 20px;
	text-align: right;
	width:800px;
	margin:0 auto;
}
.community-article .ccl-shortcut-area .shortcut {
	padding:6px 10px;
	font-size:13px;
	background:#fff;
}
.writer .shortcut {
	margin-left:3px;
	vertical-align: middle;
	/* border:none; */
	background:#fff;
	/* color:#901E00;     */
	font-weight:500;
	margin-right:0;
	font-size:11px;
	padding:3px 6px;
	display:flex;
	align-items: center;
}
.writer .shortcut::after {
	display:none
}
.writer .shortcut {
	margin-left:5px;
}
.writer {
	display:flex;
	align-items: center;
	flex-grow:row wrap
}
/* 커뮤니티 피드 태블릿 사이즈 추가 */
@media screen and (max-width: 1200px) {
	.card-community-box {
		justify-content: center;
	}
}

@media screen and (max-width: 767px) {
	.community {
		padding-top:0;
		height:100vh;
		min-height:inherit;
		box-sizing: border-box;
		padding-bottom:60px
	}
	.community-header .search_box .inp {
		padding-right:85px
	}
	.community-header .btn_filter {
		background: #fff url(../images/sub/ico_filter.svg) no-repeat 50% 30%;
		color:#222;
		position:relative;
		height:60px;
		font-size:0;
		width:50px
	}
	.community-header .btn_filter.selected {
		background: #fff url(../images/sub/ico_filter_selected.svg) no-repeat 50% 30%;
		color:#7A1D05;
	}
	.community-header .btn_filter:after {
		bottom:6px
	}
	.community-header .search_box .btn_sch {
		right:60px;
		/* right:10px; */
	}
	.community-article {
		padding-top:55px;
		padding-bottom:55px;
		overflow-y:hidden !important;
		min-height:inherit;
	}
	.community-article .check-box {
		width:100%;
		margin:0;
		padding:0 20px;
	}
	.sub-community {
		width:100%;
	}
	.community-header {
		position:fixed;
		top:0;
		left:0;
		padding:0;
		width:100%;
		z-index:10;
		height:55px;
		border-bottom:1px solid #eaeaea;
	}
	.community-header .btn_filter {
		height:55px;
	}
	.community-header .search_result_area {
		top:55px;
	}
	.community-filter-area {
		width:100%;
		padding:0;
	}
	.community-filter-area .filter-result {
		padding-left:0;
		padding:10px 20px 0;
	}
	.card-community-box {
		flex-flow:column;
		width:100%;
	}
	.card-community-list {
		margin:0;
		max-width:100%;
		min-width:100%;
		margin-bottom:10px;
		border-radius:0
	}
	.link-write-community {
		right:20px;
	}
	.community-article .ccl-shortcut-area {
		width:100%;
		box-sizing:border-box;
	}
	.ptr_h {
		height:calc(100% - 50px) !important;
		overflow-y:scroll !important;
	}
}

/* 커뮤니티 글쓰기 팝업 */
.pop-panel {
	width: 500px;
	height:700px;
	background: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2002;
	overflow-y:scroll
}
.pop-panel .header_type01 {
	height: 60px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 201;
	text-align: center;
	background: #fff;
}
.pop-panel .title {
	font-size: 18px;
	line-height: 60px;
}
.pop-panel .container {
	padding-top: 60px;
	height:100%;
	box-sizing:border-box;
	background:#fff;
	overflow-y:scroll
}
.pop-panel .container .wrapper {
	padding:0;
	width:100%;
	height:100%;
	/* overflow:hidden */
}
.pop-panel .community-filter-area {
	width:100%;
	padding:15px 100px 15px 20px;
}
.pop-panel .community-filter-area .filter-result {
	padding:0
}
.tab-link {
	border-bottom: 1px solid #eaeaea;
	background:#fff;
	padding: 10px 0 0;
	width: 100%;
	margin-bottom:10px;
	/* position:fixed;
	top:60px;
	left:0; */
	z-index:2
}
.tab-link-inner {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 20px;
	width: 100%;
	box-sizing: border-box;
}
.tab-link-inner .link_itmbox {
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	text-align: center;
	padding-bottom: 8px;
	padding-top: 2px;
	color: #444;
	/* margin: 0 10px; */
	font-size: 15px;
}
.tab-link-inner .link_itmbox:hover, 
.tab-link-inner .link_itmbox.on {
	font-weight: 600;
	border-bottom: 2px solid #222;
	color: #222;
}
.tab-content-area {
	/*margin-top:55px;*/
	/* padding-bottom:40px; */
	/* height:calc(100% - 160px); */
	max-height:100%;
	overflow-y:scroll
}
.form-inquery {
	padding-bottom:70px;
}
.form-row {
	padding:20px;
	position:relative;  
}
.form-row .form-lbl {
	display:block;
	font-size:14rem;
	margin-bottom:10px;
}
.form-row .form-lbl strong {
	font-weight:600
}
.hashtag-textarea {
	background:#f3f3f3;
	height:100px;
}
.form-row .add_text {
	/* color:#4579F2; */
	margin-top:8px;
}
.link-other {
	display:inline-block;
	position:absolute;
	right:20px;
	top:10px;
	font-size:13px;
}
.tab-content-area .form-inquery {
	padding-bottom:0;
	height:auto;
}
.tab-content-area .form-inquery:last-child {
	padding-bottom:60px;
}
/* .tab-content-area .form-inquery:last-child .form-row:last-child {
	margin-bottom:60px;
} */
.tab-content-area .tab-content-area {
	padding-bottom:0;
	height:auto;
}
.dimmed_lyr_container .pop-panel {
	overflow-y:hidden;
	padding-bottom:60px;
}
.form-row .lst_attached {
	display:flex;
	flex-flow:row wrap;
	justify-content: space-between;
}
.form-row .lst_attached li {
	/* width:auto;
	min-width:120px; */
	flex:auto;
}
.form-row .lst_attached li .btn-file-wrap {
	width:100%;
}
.pop-panel .btn-area-bottom {
	position:fixed;
	bottom:0;
	left:50%;
	transform:translatex(-50%);
	background:#fff;
	width:100%;
}
@media screen and (max-width: 767px) {
	.pop-panel {
		width: 100%;
		height: 100%;
		position: unset;
		top: unset;
		left: unset;
		transform: none;
	}
	.pop-panel .header_type01 {
		height: 52px;
	}
	.pop-panel .header_type01 .title {
		font-size: 16px;
		line-height: 52px;
	}
	.pop-panel .container {
		padding-top:55px;
		/* height: 100vh; */
		min-height:100%;
		background:#fff;
		overflow:unset;
		/* padding-bottom: calc(env(safe-area-inset-bottom) + 60px); */
		padding-bottom:0;
	}
	.pop-panel .container .wrapper {
		padding: 0;
		overflow-y:scroll
	}
	.tab-link {
		top:52px;
	}
	.tab-content-area {
		height:100%;
		/* margin-top:40px; */
	}
	.pop-panel .container .wrapper .tab-content-area + .btn-area-bottom {
		left: 0;
		background:none;
		transform:none;
	}
	.itmbox_inner .pop-panel .container {
		padding-bottom:65px;
	}
	.itmbox_inner .pop-panel .container .wrapper {
		overflow-y:scroll
	}
	.dimmed_lyr_container .pop-panel {
		padding-bottom:0;
	}
}

/* 커뮤니티 필터 설정 */
.community_filter {
	width:500px;
	border:1px solid #eaeaea;
}
.community_filter .a_filter {
	font-size:15px;
	height:40px;
}
.filter_block {
	position:relative
}
.filter_content {
	position:relative;
	width:100%;
}
.filter_content .search_box .inp {
	background:#fff;
	border:1px solid #d0d2d6;
	border-radius: 0;
	height:46px;
}
.filter_content .search_box .btn_sch {
	background: url(../images/ico_search.png) no-repeat;
	background-size: contain;
	width: 20px;
	height: 20px;
}
.filter_content .btn-schaddr {
	top:-40px !important;
	right:0 !important;
	background:#dbdbdb;
}
.filter_content .search_result_area {
	border-radius:0;
	z-index:1;
	top:45px;
	left:0;
	width:100%;
	border-color:#e0e0e0;
	box-sizing:border-box
}   
.reset-btnarea {
	padding:0 30px 0 20px;
	text-align:right;
	margin-bottom:20px;
}
.btn-all-reset {
	display:inline-block;
	font-size:14px;
}
.btn-all-reset:before {
	display:inline-block;
	content:'';
	width:14px;
	height:14px;
	background:url(../images/sub/ico_reset.svg) no-repeat;
	background-size:contain;
	margin-right:5px;
	vertical-align: middle;
}

@media screen and (max-width: 767px) {
	.community_filter {
		width:100%;
		border:none;
	}
}

/* 닉네임 설정하기 */
.set-nick-area {
	padding:10px 20px;
}
.set-nick-area .tit-exp {
	margin-bottom:10px;
}
.set-nick-area .inp-textbox div {
	font-size:13px
}
.set-nick-area .add_text {
	margin-top:0;
	margin-bottom:5px;
}

/* 커뮤니티 댓글 팝업 */
.pop-panel .card-community-list {
	min-width:100%;
	max-width:100%;
	margin:0;
	margin-bottom:20px;
	border-bottom:1px solid #eaeaea;
	padding-top:0;
	text-align:left;
	box-shadow: none;
	transition: none;
}
.pop-panel .card-community-list:hover {
	box-shadow: none;
}
.card-community-list .danji_img {
	margin-bottom:10px;
}
.pop-panel .card-community-list .ccl-content p a {
	text-decoration: underline;
	color: rgb(62, 112, 199);
}
@media screen and (max-width: 767px) {
	.card-community-list .danji_img {
		margin:0 -20px 10px;
	}
}

/* 신고가 댓글 */
.ch-info-name {
	font-size:18px
}
.ch-info-type{
	font-size:18px
}
.ch-info {
	padding-bottom:10px;
}
.card-highprice .ccl-tool,
.card-comment-list .ccl-tool {
	position:unset;
}
.card-highprice .ccl-tool .comment-set {
	justify-content: right;
}
.card-comment-box {
	padding:0 15px 60px;
}
.card-comment-list {
	background:#f6f6f7;
	padding:12px 15px;
	border-radius:5px;
	position:relative;
	margin-bottom:40px;
	text-align:left
}
.card-comment-list .ccl-content,
.card-recomment-list .ccl-content {
	padding-bottom:0
}
.card-comment-list .ccl-content p {
	margin-bottom:7px;
	margin-top:5px;
	font-size:15px;
	padding-right:0;
}
.card-comment-list .ccl-content .date {
	font-size:13px;
	color: color(normal, 030);
}
.card-comment-list .ccl-tool {
	position:absolute;
	left:15px;
	bottom:-30px;
}
.comment_write {
	font-size:14px;
	margin-right:10px;
}
.card-comment-list .report {
	margin-left:auto;
}
.card-comment-list .comment-input-area,
.card-recomment-list .comment-input-area {
	position:unset;
	padding:10px 0 0 0;
	background:none
}
.comment-input-area.recomment-input {
	position:unset;
	padding-top:0;
	padding-bottom:20px;
	padding-right:0;
	padding-left:15px;
}
.comment-input-area {
	padding-top:10px;
	width:100%;
	padding:10px;
	box-sizing:border-box;
	position:fixed;
	left:0;
	bottom:0;
	background:#fff;
}
.comment-input-area .inp-textbox .inp {
	padding-right:50px;
	border-radius:5px;
	padding:12px 50px 15px 15px;
	line-height:1.7;
	resize:none;
}
.btn-send-comment {
	width:50px;
	height:50px;
	/* background:url(../images/sub/ico_write_bl.svg) no-repeat 50%;
	text-indent:-9999px; */
	font-size:14px;
	position:absolute;
	right:0;
	top:0;
}
.ccl-content-img {
	min-height:70px;
	position:relative;
	padding-right:110px;
}
.ccl-conten.ccl-content-img p {
	padding-right:0;
}
.ccl-content-img .item_thumb {
	position:absolute;
	top:0;
	right:0;
	width:100px;
	height:70px;
	z-index: unset
}
.card-recomment-list {
	background: #f6f6f7;
	padding: 12px 15px;
	border-radius: 5px;
	position: relative;
	margin-bottom: 40px;
	margin-left:25px;
}
.card-recomment-list .ccl-tool {
	position:absolute;
	left:15px;
	bottom:-30px;
}
.card-recomment-list .report {
	margin-left:auto;
}
.btn-moreview {
	display:flex;
	width:120px;
	padding:5px 8px;
	justify-content: center;
	margin:0 auto;
	margin-top:20px;
	font-size:15px;
}

@media screen and (max-width: 767px) {
	.btn-moreview {
		display:none
	}
	.btn-send-comment {
		font-size:15px;
	}
}

/* 신고하기 */
.report-box {
	flex:none;
	width:100%;
}
.report-box form {
	margin-top:10px;
}
.report-box .check {
	margin:5px 0;
	display:block;
}
.report-box .check-box strong {
	font-size:15px;
}
.report-box .check-box {
	width:100%;
}

/* 지역톡 레이아웃 */
#complex_itmbox_complexTalk .itmbox_inner {
	padding:0
}
.panel-group .community {
	box-sizing:border-box;
	padding-top:0;
	height:calc(100vh - 90px);
	background:#e4e4e4;
	height:100%;
	overflow-y:overlay;
	@include overflow-scroll-custom (dark, medium, y);
}
.panel-group .community .card-community-box {
	flex-flow:column;
	width:100%;
}
.panel-group .community .card-community-box .btn-moreview {
	margin:0 auto;
}
.panel-group .community .card-community-list {
	margin:10px 0 0 0;
	border-radius:0;
}
.itmbox_inner .community {
	max-height: 500px;
	min-height: 200px;
	padding-bottom: 0;
	padding-top:0;
	background:#f3f3f3
}
.itmbox_inner .community .more {
	display:none
}
@media screen and (max-width: 767px) {

}

/* 집구하기 검색박스 수정 */
.find .search_result_area {
	border-radius:0;
}
.btn-clear {
	position: absolute;
	top: 50%;
	transform: translatey(-50%);
	right: 45px;
	display: inline-block;
	overflow: hidden;
	width: 19px;
	height: 20px;
	background:url(../images/sub/btn-clear.svg) no-repeat;
	line-height: 999em;
	vertical-align: top;
}
.search-tag {
	display:inline-block;
	padding: 4px 8px 4px 10px;
	background: #901E00;
	color: #fff;
	border-radius: 20px;
	font-weight: 300;
	position:absolute;
	left:15px;
	top:50%;
	transform:translatey(-50%)
}
.search-tag .btn-close {
	background: url(../images/sub/ico_close_whs.svg) no-repeat;
	width: 12px;
	height: 12px;
	display: inline-block;
	text-indent: -9999px;
	background-size: contain;
	vertical-align: middle;
	margin-top: -3px;
	margin-left: 3px;
	padding: 0;
}
/* 일반회원 정보 */
.comm-infouser {
	width:100%;
	box-sizing:border-box;
	padding:10px 0 15px;
	text-align:left
}
.comm-infouser .nickname {
	font-size:24px;
	font-weight:600
}
.info-mactivity {
	width:100%;
	text-align:left;
	padding:10px 0 15px
}
.comm-writelist,
.comm-commentlist {
	width:100%;
	padding:10px 0
}
.comm-lbl {
	display:block;
	font-size:14px;
	color:#666
}
.comm-writelist .go-writelist,
.comm-commentlist .go-writelist {
	padding:10px 20px 10px 0
} 
.comm-writelist .btn,
.comm-commentlist .btn {
	font-size:14px;
}

.panel-group .itmbox_inner .community .card-community-list {
	margin-top:0;
	border-radius:0;
}
.comm-lbl,
.writelist-lst-wrap {
	text-align:left;
}
.active-wrap {
	width: 100%;
	padding-top: 10px;
	min-height:auto;
	max-height: calc(100vh - 250px);
	overflow-y: scroll;
	display: flex;
	flex-flow: row wrap;
}
.lyr_inner.lyr_active {
	overflow-y:hidden;
	/* height:100vh */
}
.comm-infouser + .btn-horiz {
	flex: auto;
	flex-grow: 1;
}
.comm-infouser + .btn-horiz .btn {
	width:100%;
	margin:0;
	border-radius:0;
	box-sizing:border-box;
	min-width:unset;
}
.comm-infouser .shortcut {
	position:absolute;
	right:20px;
	top:70px;
	cursor:pointer;
}

/* 220103 구해줘다윈설명 */
.notice-area {
	width:100%;
	text-align:left;
	margin:0 auto;
	padding:20px 20px 0;
	box-sizing:border-box;
}
.notice-area-wrap {
	display:flex;
	flex-flow:column wrap;  
	width:100%;  
	padding:20px 25px;
	box-sizing:border-box;
	background:#f1f1f1;
	border-radius:20px;
}
.notice-list {
	display:flex;
	flex-flow:row wrap;
	width:100%;
	align-items: center;
	/* margin-bottom:20px;
	justify-content: ; */
}
.notice-list picture {
	min-width:30%;
	flex:1;
	justify-content: center;
	text-align: center;
}
.notice-list picture img {
	object-fit: contain;
	width:80%; 
	margin:0 auto;
	display:inline-block
}
.notice-list .notice-text {
	min-width:70%;
	flex:1;
	padding-left:20px;
	box-sizing:border-box;
	font-size:14px;
}
.notice-list .notice-text strong {
	display:block;
	margin-bottom:6px;
	font-size:16px;
}
.notice-list.add {
	font-size:15px;
	margin-top:20px;
	text-align:center;
	display:block
}
.notice-list .btn {
	margin-top:10px;
	display:block;
	width:fit-content;
	font-size:12px;
	height:auto;
	padding:3px 10px;
	box-sizing: border-box;
}
@media screen and (max-width: 767px) {
	.notice-area {
		width:100%;
		text-align:left;
		margin:0;
	}
	.notice-list picture img {
		width:100%
	}
}
.tab-content-area .notice-area {
	padding:0 20px;
	box-sizing: border-box;
	width:100%;
}
.tab-content-area .notice-area-wrap {
	padding:20px;
	display:block;
	background:#f1f1f1;
	border-radius:20px;
}
.tab-content-area .notice-area-wrap .notice-list {
	/* text-align:center; */
	/* display:block; */
	margin:0
}
/* 내활동 커멘트 정렬 */
.dimmed_layer .lyr_inner .pap-panel .card-comment-list .ccl-content p {
	text-align:left
}

/* 커뮤니티 검색결과 관련 */
.keyword {
	display:inline-block;
	color:#901E00;
	/* margin:0 3px; */
	font-weight:400;
}
.search_result_area .result_item span.tag-category,
.search_result_area span.tag-category {
	display:inline-flex;
	padding:1px 7px;
	color:#222;
	font-size:12px;
	float:none;
}
span.tag-category {    
	margin-left:5px;
	background:#ededed;
	padding:3px 5px;
	border-radius:20px;
	width:auto;
	display:flex;
	align-items:center;
}
.item-info01 {
	display:flex;
	align-items: center;
}
.item-info02 {
	color:rgb(121, 121, 121);
	font-size:13px;
	padding-top:3px;
}

.communitylist-blank {
	width:300px;
	position:absolute;
	top:30%;
	left:50%;
	transform:translate(-50%, -50%);
	margin-top:50px
}
.communitylist-blank p {
	font-size:15px;
	color:#8d8d8d;
	position:relative;
	text-align:center
}
.communitylist-blank p:before {
	display:inline-block;
	content:'';
	background:url(../images/sub/img_nolist.png) no-repeat 50% 50%;
	width:64px;
	height:64px;
	position:absolute;
	left:50%;
	top:-71px;
	transform:translatex(-50%);
	background-size:contain;
}
@media screen and (max-width: 767px) {
	.communitylist-blank {
		top:50%;
		margin-top:0
	}
	.communitylist-blank p {
		font-size:15px
	}
}

// root-dawin
:root.root-dawin {
}