@import '_variables';
/*
 * @ text accent
 * @ $text-color
 */
$text-accent: (
	normal-00: color(base, 100),
	normal-01: color(base, 080),
	normal-02: color(base, 060),
	normal-03: color(base, 040),
	normal-04: color(base, 020),
	normal-10: color(base, 000),
	
	accent-01: color(accent, 010),
	accent-02: color(secondary, 050),
	accent-03: color(accent, 030),
	// accent-03: color(secondary, 010),
	accent-04: color(secondary, 020),
	accent-05: color(base, 080),

	etc-01: color(etc, 020),
	etc-02: color(etc, 030),
	etc-03: color(etc, 060),
	etc-04: color(social, 010),


);

.em svg {
	display: block;
	position: relative;
	top: 0;
	left: 0;
}

em.em {
	font-style: normal;
}

a.em {
	text-decoration: underline;
	
	&:not([class*=normal-]):not([class*=accent-]) {
		color: inherit;
	}
}

em.block {
	display: block
}

strong.em,
span.em {
	display: inline-block;
}

.para,
.em {
	&.style-a {
		text-decoration: none;
	}

	&.style-b {
		text-decoration: underline;
	}

	&.style-c {
		text-decoration: line-through;
	}

	&.style-d {
		&:not(:hover) {
			text-decoration: none;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&.type-a {
		text-align: left;
	}

	&.type-b {
		text-align: right;
	}
	&.type-c {
		text-align: center;
	}

	&.attr-onclick {
		cursor: pointer;
	}

	// accent
	@each $name, $font-color in $text-accent {
		&.#{$name} {
			color: $font-color;
		}
	}
	
	&.large-5x {@include font((outline: body, size: large-5x)...)}
	&.large-4x {@include font((outline: body, size: large-4x)...)}
	&.large-3x {@include font((outline: body, size: large-3x)...)}
	&.large-2x {@include font((outline: body, size: large-2x)...)}
	&.large-1x {@include font((outline: body, size: large-1x)...)}
	&.large {@include font((outline: body, size: large)...)}
	&.medium {@include font((outline: body, size: medium)...)}
	&.small {@include font((outline: body, size: small)...)}
	&.small-1x {@include font((outline: body, size: small-1x)...)}
	&.small-2x {@include font((outline: body, size: small-2x)...)}

	&.valid {
		color: color(accent, 020);
	}

	&.invalid {
		color: color(primary, 050);
	}
}