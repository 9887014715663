@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */

/* 채팅 */
// #dawinwrap .chatpage {
// 	min-height: unset;
//     -webkit-overflow-scrolling: touch;
//     overflow-x: hidden;
// 	margin-top: 56px;
// 	overflow-y:overlay;	
// 	@include overflow-scroll-custom (dark, medium, y);
// 	display:flex;
// 	flex-direction: column;
// }
.chatpage .container {
	padding-top:0;
}
.chat-wrapper {
	margin: 40px auto 70px auto;
	width:100%;
	display:flex;
	flex-flow:row;
	justify-content: center;
	height: calc(100vh - 191px);
	/* margin-top:30px; */
	width:1170px;
	position:relative
}

/* .chat-list-wrap {
	width:400px;
	border:1px solid #eaeaea;
	background:#fff;
} */
.chat-list-wrap {
	position:relative;
}
.chat  {
	display:flex;
	flex-direction: column;
	/* height:100vh; */
	width:calc(100% - 400px);
	border:1px solid #eaeaea;
	margin-left:-1px;
}
.header-chat {
	height: 55px;
	position: relative;
	width: 100%;
	text-align: center;
	background: #fff;
}
.header-chat .btn-right {
	display:none;
}
.header-chat .btn-left {
	display:none 
}
.chat-bkinfo-area {
	height:55px;
	margin-left:10px;
	text-align: left;
	display:flex;
	flex-direction: row;
	align-items: center;
}
.chat-article {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	position: relative;
	z-index: 1;
	overflow-x: hidden;
	overflow-y: auto;
}
.chat-section {
	background: #f3f3f3;
	/* border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea; */
	/*    margin-top:20px;*/
	position: relative;
	height:100%;
}
.chat-hashtag-area {
	box-sizing:border-box;
	padding:10px 20px;
	width:100%;
	background:#e7e7e7;
	text-align:left;
}
.hashtag {
	font-size:13px;
	color:#666;
}
.hashtag:before {
	display:inline-block;
	content:'#';
	margin-right:3px;
}
.chat-hashtag-area .hashtag {
	margin-right:6px;
}

.chat-content {
	padding: 20px 10px;
	height: 100%;
	box-sizing: border-box;
	overflow-y:overlay;
	@include overflow-scroll-custom (dark, medium, y);
}

.chat-list {
	padding: 8px 0;
}

.date-check {
	text-align: center;
	margin: 0 0 20px
}

.date_check span {
	display: inline-block;
	padding: 1px 12px 2px;
	border: 1px solid #c9cfd5;
	border-radius: 15px;
	background: #cdd3d8;
}
.chat-partner,
.chat-system {
	padding-left:40px;
	position:relative;
	text-align: left;
}
.chat-profile {
	position:absolute;
	left:0;
	top:10px;
}
.cp-thumb {
	width:40px;
}
.cp-thumb-img {
	width:30px;
	height:30px;
	border-radius:10px;
	object-fit: cover;
}
.cp-thumb-img img {
	width:100%;
	height:100%;
	border-radius: 10px;
	object-fit: cover;
}

.chat-list .name {
	font-weight: 600;
}

.chat-list .date {
	font-size: 12px;
	color: color(normal, 030);
	margin-left: 5px;
}

.chat-balloon {
	display: inline-block;
	min-width: 20px;
	max-width: 73%;
	padding: 8px 15px;
	box-sizing: border-box;
	border-radius: 15px;
	position:relative
}
.chat-balloon:after {
	display:block;
	content:''
}
.chat-balloon + .chat-balloon {
	margin-top:10px;
}
.chat-balloon.chat-typeimg {
	max-width:250px;
	padding:0;
	height:auto;
}
.chat-me .chat-balloon.chat-typeimg,
.chat-partner .chat-balloon.chat-typeimg,
.chat-system .chat-balloon.chat-typeimg {
	background:none;
}
.chat-me .chat-balloon.chat-typeimg:after,
.chat-partner .chat-balloon.chat-typeimg:after,
.chat-system .chat-balloon.chat-typeimg:after {
	display:none;
}
.chat-me:after {
	display:block;
	clear:both;
	content:''
}
.chat-balloon.chat-typeimg img {
	width:100%;
	height:100%;
	object-fit: contain;
	border-radius: 15px;
}
/* .chat-profile + .chat-balloon {
	margin-top: 10px;
} */

.chat-partner .chat-balloon {
	background: #fff;
}
.chat-partner .chat-balloon:after {
	display:inline-block;
	content:'';
	background: url(../images/sub/img_tail02.svg) no-repeat;
	background-size:contain;
	width:8px;
	height:13px;
	position:absolute;
	left:-4px;
	top:0;
}
.chat-partner .chat-balloon + .chat-balloon:after {
	display:none
}

.chat-system .chat-balloon {
	background: #FFDD00;
	color:#901E00;
	font-weight:600;
}
.chat-system .chat-profile {
	font-size:12px;
	font-weight:500
}
.chat-system .chat-balloon:after {
	display:inline-block;
	content:'';
	background: url(../images/sub/img_tail03.svg) no-repeat;
	background-size:contain;
	width:8px;
	height:13px;
	position:absolute;
	left:-4px;
	top:0;
}
.chat-system .chat-balloon + .chat-balloon:after {
	display:none
}
.chat-me {
	text-align: right;
}

.chat-me .chat-balloon {
	background: #901E00;
	color:#fff;
	text-align:left
}
.chat-me .chat-balloon:after {
	display:inline-block;
	content:'';
	background: url(../images/sub/img_tail01.svg) no-repeat;
	background-size:contain;
	width:8px;
	height:13px;
	position:absolute;
	right:-4px;
	top:0;
}
.chat-me .chat-balloon + .chat-balloon:before {
	display:none
}

.chat-balloon a {
	color:#3e70c7;
	text-decoration: underline;
}
.chat-me .chat-balloon a {
	color:rgb(255, 221, 0);
}
.chat-balloon a b {
	word-break: break-all;
	font-weight:400;
}
.chat-footer {
	width: 100%;
	height: 50px;
	background: #fff;
	border-top: 1px solid #eaeaea;
}

.chat-write-wrap {
	display: flex;
	flex-flow:row nowrap;
}

.chat-write-wrap .chat-write-input {
	flex: 1 1 auto;
	border: none;
	height: 50px;
	font-size: 15px;
	padding:15px;
	box-sizing: border-box;
	line-height:1.7;
	resize: none;;
	overflow-y:overlay;
	@include overflow-scroll-custom (dark, medium, y);
}

.chat-write-wrap .btn.chat-write-send {
	height: 50px;
	border-radius: 0;
	width: 70px;
	background:#901E00;
	color:#fff;
}

.upload-btnwrapper {
	position: relative;
	width:50px;
}
.btn-upload {
	display: block;
	content: '';
	width: 50px;
	height: 50px;
	background: url(../images/sub/img_addfile.svg) no-repeat center center;
	text-indent: -9999px;
	cursor: pointer;
}

.upload-btnwrapper input[type="file"] {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 50px;
	height: 50px;
}

@media screen and (max-width: 767px) {
	.chatsub .container {
		padding-top:0
	}
	.chat-wrapper {
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		/* height: 100vh;
		height:-webkit-fill-available; */
		height:100%;
		padding-bottom:60px;
		margin-top: 0;
		position:fixed;
		top:0;
		bottom:0;
		flex:100%;
		/* padding-top:63px; */
		box-sizing:border-box;
		justify-content: flex-start;
		margin:0 auto;
	}
	.chat-wrapper .header {
		border-bottom:1px solid #eaeaea;
		/* 220922 채팅리스트 헤더위치수정*/
		position:relative;
	}
	.chat-list-wrap {
		display:flex;
		flex-direction: column;
		overflow-y:scroll
	}
	.chat {
		position:unset;
		right:unset;
		width:100%;
		border-left:none;
		border:none;
		margin:0;
		height:100%;
		/* margin-bottom:60px; */
	}
	.header-chat .btn-right {
		display:block;
	}
	.header-chat .btn-left {
		display:block;
	}
	.chat-hashtag-area { 
		width:100%;
	}
	.chat-bkinfo-area {
		margin-left:55px;
	}
}

/* 채팅리스트 */
.chat-list-wrap {
	display:flex;
	width:400px;
	justify-content: left;
	border:1px solid #eaeaea;
}
.card-chat-list {
	width:100%;
	box-sizing:border-box;
	padding:15px 20px;
	border-bottom:1px solid #eaeaea;
	position:relative
}
.card-chat-list.on,
.card-chat-list:hover {
	background:#fdf9f8
}
.card-chat-list.on:after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 1px;
	background-color: #ffc6c6;
	content: "";
}
.card-chat-list.on:before {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	height: 1px;
	background-color: #dd846c;
	content: "";
}
.card-chat-box {
	padding:0;
	width:100%;
	background:#fff;
	overflow-y:overlay;
	@include overflow-scroll-custom (dark, medium, y);
}
.ccl-hashtag-area {
	margin-bottom:2px;
}
// 220922 채링리트스 해쉬태그 # 표기되게
// .card-chat-list .hashtag:before {
// 	display:none;
// }
.ccl-agent-area {
	display:flex;
}
.ccl-thumb-img {
	width:22px;
	height:22px;
	object-fit: contain;
	margin-right:5px;
}
.ccl-thumb-img img {
	width:100%;
	height:100%;
	object-fit: cover;
	border-radius: 5px;
}
.ccl-info-office {
	margin-right:5px;
}
.card-chat-list .ccl-content {
	padding-bottom:0
}
.ccl-content p {
	font-size:17px;
	margin-top:8px;
	margin-bottom:4px;
	padding-right:25px;
	font-weight:400
}
.ccl-content .date {
	font-size:13px;
	color: color(normal, 030);
}
.ccl-count {
	width:22px;
	height:22px;
	background:#E54100;
	color:#fff;
	border-radius: 20px;
	position:absolute;
	right:20px;
	bottom:38px;
	display:flex;
	align-items: center;
	justify-content: center;
	font-size:13px;
	line-height:1
}
.link-agent-consut {
	display: inline-block;
	position: fixed;
	bottom: 170px;
	right: 100px;
	z-index: 100;
	width: 75px;
	height: 75px;
	background-color: #ad2400;
	border-radius: 100px;
	color: #fff;
	padding-top: 17px;
	box-sizing: border-box;
	text-align: center;
	box-shadow: 0 10px 10px rgb(0 0 0 / 20%), 0 4px 4px rgb(0 0 0 / 15%);
	line-height:1.3
}
.link-agent-consut b {
	font-size:17px
}

@media screen and (max-width: 767px) {
	.chat-list-wrap {
		display:block;
		border:none;
		width:100%;
	}
	.link-agent-consut {
		right:16px;
		bottom:130px;
	}

}

/* 중개사 상담하기 */
.card-chatbk-list {
	background:#f1f1f1;
	height:100%;
	padding:0
}
.count-rate,
.count-consulting {
	font-size:13px;
	display:inline-block
}
.count-rate:after {
	display:inline-block;
	content:'';
	width:3px;
	height:3px;
	background:#222;
	border-radius:3px;
	margin:-3px 3px 0 3px;
	vertical-align: middle;
}
.btn-bkchat {
	width:50px;
	height:50px;
	border-radius:50px;
	display:inline-block;
	display:flex;
	align-items: center;
	background:#901E00;
	color:#fff;
	font-size:13px;  
	justify-content: center;
	line-height:1.2 
}
.bkset-area {
	padding:20px;
	background:#f1f1f1
}
.bkset-area .text-exp {
	margin-bottom:5px;
}
.bkset-area + .filter-sorting {
	background:#f1f1f1;
}
.card-chatbk-list .cbi-info-selec {
	right:20px;
}
.ccl-info-name .score-star {
	margin-left:5px;
}
@media screen and (max-width: 767px) {
	.card-chatbk-list {
		padding:0 0 20px 0; 
		// height:calc(100% - 220px)
	}
}

.chat-list-wrap .alert-msg {
	position:absolute;
	top:40%;
	left:50%;
	transform:translate(-50%, -50%);
	width:100%;
	padding:20px;
}
.chat-list-wrap .alert-msg .text-exp {
	font-size:17px;
	color:#8d8d8d;
	margin-bottom:20px;
}
.btn-gochatbklist {
	width:100%;
	padding:10px 30px;
	background:#901E00;
	color:#fff;
	border-radius:5px;
}
.chat-blank {
	width:200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
}
.chat-blank p {
	font-size:17px;
	color:#8d8d8d;
	position:relative;
	text-align:center
}
.chat-blank p:before {
	display:inline-block;
	content:'';
	background:url(../images/sub/ico_chat02.svg) no-repeat 50% 50%;
	width:100px;
	height:100px;
	position:absolute;
	left:50%;
	top:-100px;
	transform:translatex(-50%);
}
.bklist-blank {
	width:300px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
}
.bklist-blank p {
	font-size:17px;
	color:#8d8d8d;
	position:relative;
	text-align:center
}
.bklist-blank p:before {
	display:inline-block;
	content:'';
	background:url(../images/sub/img_nouser.svg) no-repeat 50% 50%;
	width:100px;
	height:100px;
	position:absolute;
	left:50%;
	top:-100px;
	transform:translatex(-50%);
}
@media screen and (max-width: 767px) {
	.chat-blank {
		display:none;
	}
	.chat-container {
		padding-top:0
	}
}

// root-dawin
:root.root-dawin {
}