/**
 * 전체 : U+0020-007E
 * 특수 : U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E
 * 영문 : U+0041-005A(대문자), U+0061-007A(소문자)
 * 숫자 : U+0030-0039
 */
@import "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Quicksand:wght@300;400&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Jua&display=swap";
// @import "https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css";
// @import "https://fonts.googleapis.com/icon?family=Material+Icons";
// @import "https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0";
