@charset "UTF-8";
/* Scss Document */
/* header */

@import "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Quicksand:wght@300;400&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Jua&display=swap";


html {
	font-size:1px;
}

body {
	width: 100%;
	height: 100%;
	font-family: 'Open Sans', 'Noto Sans KR', sans-serif;
	font-size: 14px;
	color: var(--text-color-1);
	letter-spacing: -0.02em;
	overflow-x: hidden;
	line-height: 1.4444444444;
	word-wrap: break-word;
	overflow-wrap: break-word;
}

#dawinWrap {
	position: relative;
	width: 100%;
	/* max-width: 1920px; */
	height: 100%;
	margin: 0 auto;
	background: var(--bg-color-1);
	/* padding-top: 50px; */
	box-sizing: border-box;
}

#dawinWrap #wrap {
	display: flex;
	-ms-display: flexbox;
	flex-direction: column;
	min-height: 100%;
	height: auto;
	width: 100%;
}

#dawinWrap #wrap.find {
	height: 100%;
}

.container {
	flex: 1;
	-ms-flex: 1 0 auto;
	-webkit-flex: 1;
	/* padding-top:50px */
}

div {
	box-sizing: border-box;
}

.find .container {
	position: relative;
	height: 100%;
	/*  padding-top:40px*/
	overflow-y: scroll
}

.find .cont_wrap {
	height: 100%;
}

.normal {
	font-weight: 400;
}

.bold {
	font-weight: 700;
}

.bolder {
	font-weight: 800;
}

.light {
	font-weight: 300;
}

.txt_tit {
	font-size: 30px;
}

.more {
	text-align: center;
	margin-top: 30px;
}

.more a {
	display: inline-block;
	font-size: 13px;
	padding: 8px 30px;
}

.ico_down {
	background: url(../images/main/btn_arr02.png) no-repeat;
	display: inline-block;
	width: 9px;
	height: 5px;
	vertical-align: middle;
	margin-left: 5px;
	margin-top: -2px;
}

.ico_up {
	background: url(../images/main/btn_arr03.png) no-repeat;
	display: inline-block;
	width: 9px;
	height: 5px;
	vertical-align: middle;
	margin-left: 5px;
	margin-top: -3px;
}

.readinp {
	background: #fafafa;
}

button:disabled {
	background: #d7d7d7;
	color: #fff;
}

button:disabled:hover {
	background: #d7d7d7;
	color: #fff;
}

.tred {
	color: #e44332;
}

.header {
	width: 100%;
	height: 50px;
	background-color: #fff;
	z-index: 50;
	text-align: center;
	border-bottom: 1px solid #d9d9d9;
	box-sizing: border-box;
	position: fixed;
	transition: top 0.2s ease-in-out;
	top: 0;
	// max-width: 1920px
}

.header .gnb {
	float: left;
	margin-left: 40px;
}

.header .gnb ul {
	overflow: hidden;
}

.header .gnb ul li {
	float: left;
}

.header .gnb ul li a {
	display: inline-block;
	color: #222;
	font-size: 14px;
	padding: 12px 15px 12px;
	font-weight: 400;
	position: relative;
}

.header .gnb ul li.menulst:before {
	display: inline-block;
	content: '';
	font-size: 5px;
	vertical-align: middle;
	color: #C6C6C6;
	margin: 0 8px 3px;
}

.header .gnb ul li.menulst:first-child:before {
	display: none
}

.header .gnb ul li a:hover {
	font-weight: bold;
}

.header .gnb ul li a:hover:after {
	width: 100%;
	left: 0;
}

.header .gnb ul li a:after {
	content: '';
	display: block;
	width: 0;
	margin: 3px auto 0;
	height: 3px;
	background: #444444;
}

.header .gnb ul li a.active {
	font-weight: bold;
	color: #222;
}

.header .gnb ul li a.active.active:after {
	width: 100%;
	left: 0;
}

.header .gnb ul .menulst-sub a {
	color: #999
}

.logo {
	float: left;
	margin-top: 13px;
	margin-left: 22px;
}

.logo a {
	display: block;
	width: 105px;
	height: 30px;
	background: url(../images/main/logo.png) no-repeat;
	text-indent: -9999px;
	background-size: contain;
}

.user_area {
	position: absolute;
	top: 0;
	right: 0;
}

.btn-right {
	position: absolute;
	top: 0;
	right: 0;
}

.loginbefore {
	display: inline-block;
	padding-right: 30px;
}

.loginbefore ul:after {
	display: block;
	content: '';
	clear: both;
}

.loginbefore ul li {
	float: left;
	display: inline-block;
	box-sizing: border-box;
}

.loginbefore ul li.userlst01 a {
	display: inline-block;
	color: #1e1e1e;
	font-size: 13px;
	padding: 15px 0;
	height: 50px;
	box-sizing: border-box;
}

.loginbefore ul li.userlst02 {
	padding-top: 9px;
	padding-right: 0;
	margin-left: 25px;
}

.btn-menu {
	display: none;
}

.btn-menu-close {
	display: none;
}

.user_type {
	font-size: 12px;
	display: inline-block;
	margin-right: 5px;
	line-height: 1.2;
	vertical-align: middle;
}

.user_name {
	display: inline-block;
	line-height: 1.2;
	vertical-align: middle;
}

.userlst01 .join:before {
	display: inline-block;
	width: 1px;
	height: 11px;
	margin: 0 20px;
	background: #ccc;
	opacity: 0.9;
	content: '';
	vertical-align: -1px;
}

.btn-right .btn-logout {
	margin-right: 5px;
}

.loginafter {
	display: inline-block;
	padding: 10px 0
}

@media screen and (max-width: 870px) {
	.header .gnb {
		width: 350px;
		padding: 65px 0 0;
		height: 100vh;
		background: #3a2722;
		position: absolute;
		right: 0;
		z-index: 200;
		margin-left: 0;
	}

	.header .gnb ul {
		overflow: visible;
	}

	.header .gnb ul li {
		float: inherit;
	}

	.header .gnb ul .menulst-sub a {
		color: #ddd
	}

	.header .gnb ul li.menulst:before {
		display: none
	}

	.header .gnb ul li a {
		color: #fff;
		padding: 15px;
		font-weight: 300;
		font-size: 20px;
	}

	.header .gnb ul li a:hover {
		font-weight: 500;
	}

	.header .gnb ul li a:after {
		background: #fff;
	}

	.header .gnb ul li a.active {
		font-weight: 500;
		color: #fff
	}

	.header .btn-menu {
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 6;
		width: 50px;
		height: 50px;
		background: url(../images/main/btn_menu.png) no-repeat center center;
		text-indent: -9999px;
	}

	.header .btn-menu-close {
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 6;
		width: 50px;
		height: 50px;
		background: url(../images/main/btn-menu-close.png) no-repeat;
		text-indent: -9999px;
	}

	.header .btn-right {
		right: 60px;
	}

	.header .btn-right .loginafter {
		margin-right: 20px;
	}
}

/* footer */
#footer {
	position: relative;
	flex: 1;
	-ms-flex: 1 0 auto;
	-webkit-flex: 1;
}

.btn_help {
	position: absolute;
	right: 40px;
	top: 95px;
}

.btn_help a {
	display: inline-block;
	background: #ffa800;
	color: #fff;
	width: 60px;
	height: 60px;
	text-align: center;
	padding-top: 20px;
	box-sizing: border-box;
}

.footer_link {
	min-height: 50px;
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	text-align: center;
}

.footer_link ul {
	display: inline-block;
}

.footer_link ul li {
	display: inline-block;
}

.footer_link ul li a {
	display: inline-block;
	line-height: 49px;
	font-size: 13px;
}

.footer_link ul li:before {
	display: inline-block;
	width: 1px;
	height: 11px;
	margin: 0 30px;
	background: #999;
	opacity: 0.9;
	content: '';
	vertical-align: -1px;
}

.footer_link ul li:first-child:before {
	display: none;
}

.footer_info {
	padding: 35px 0 50px;
	text-align: center;
	max-width: 1200px;
	margin: 0 auto;
}

.footer_info .f_info {
	display: block;
	text-align: center;
}

.cs_info {
	display: inline-block;
	border: 1px solid #e1e1e1;
	padding: 10px 30px;
	box-sizing: border-box;
	margin-top: 30px;
}

.cs_info span {
	display: inline-block;
	padding-left: 25px;
	background: url(../images/main/ico_cs.png) no-repeat 0 0;
	vertical-align: middle;
}

.cs_info strong {
	display: inline-block;
	font-size: 23px;
	margin-left: 10px;
	vertical-align: middle;
}

.f_info span {
	font-size: 12px;
	line-height: 2;
	display: inline-block;
}

.f_info span:before {
	display: inline-block;
	width: 1px;
	height: 8px;
	margin: 0 10px;
	background: #999;
	opacity: 0.9;
	content: '';
	vertical-align: -1px;
}

.f_info span:first-child:before {
	display: none;
}

.p_txt01 {
	font-size: 32px;
	text-align: center;
	line-height: 1.8;
}

.p_txt02 {
	font-size: 20px;
	text-align: center;
	line-height: 1.8;
}

@media screen and (max-width: 870px) {
	.btn_help {
		bottom: -30px;
		left: 50%;
		transform: translatex(-50%);
		top: inherit;
		right: inherit;
	}
}

/*로딩*/
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.lds_wrap {
	text-align: center !important;
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	z-index: 120;
}

.lds-spinner {
	color: official;
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}

.lds-spinner div {
	transform-origin: 32px 32px;
	animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
	content: " ";
	display: block;
	position: absolute;
	top: 3px;
	left: 29px;
	width: 5px;
	height: 14px;
	border-radius: 20%;
	background: #b62217;
}

.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}

@keyframes lds-spinner {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@media all and (-ms-high-contrast: none) {
	.m_lst_box>ul>li:last-child {
		margin-bottom: 60px;
	}
}

@media (-ms-high-contrast: active) {
	.m_lst_box>ul>li:last-child {
		margin-bottom: 60px;
	}
}

.svg-sch path {
	stroke: #5827d8;
	stroke-width: 2px;
	stroke-opacity: 1;
	stroke-linecap: butt;
	stroke-linejoin: miter;
	fill: #5827d8;
	fill-opacity: 0.20;
	pointer-events: visiblePainted;
}

.svg-sch {
	position: absolute;
	overflow: visible;
	margin: 0px;
	padding: 0px;
	border: 0px;
	pointer-events: none;
	z-index: 0;
}

.scroll {
	overflow: hidden
}

/*button:active, button:focus {outline:none;border:none;}*/

:root.root-dawin {
	--main-color-1: #7a1d05;
	--content-color-1: #c22800;
	--content-color-2: #3c251f;
	--content-color-3: #d1d1d1;
	--content-color-4: #ffd200;
	--disabled-color-1: #e7e7e7;
	--bg-color-1: #fff;
	--bg-color-2: #e1e1e1;
	--text-color-1: #333;
	--text-color-2: #666;
	--text-color-3: #d2d2d2;
	--text-color-4: #fff;

	width: 100%;
	height: 100%;
	// font-family: 'SpoqaHanSans', sans-serif;
	// font-weight: 400;
	// font-size: 14px;
	// color: var(--text-color-1);
	// letter-spacing: -0.02em;
}