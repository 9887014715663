@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */
html {
	font-size:1px;
	height:100%;
}

body {
	width: 100%;
	height: 100%;
	font-family: 'Open Sans', 'Noto Sans KR', sans-serif;
	font-size: 14px;
	color: var(--text-color-1);
	letter-spacing: -0.02em;
	overflow: hidden;
	line-height: 1.4444444444;
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: keep-all;
	-webkit-text-size-adjust: none;
	margin: 0;
	padding: 0;
}

#dawinWrap {
	position: relative;
	width: 100%;
	/* max-width: 1920px; */
	height: 100%;
	margin: 0 auto;
	background: var(--bg-color-1);
	/* padding-top: 50px; */
	box-sizing: border-box;
}

.bk .logo {margin-top:13px;margin-left:22px;}
.bk .mydawin .tab_style03 {margin-bottom:20px}
.bk .ellipsis {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.bk .mgr {margin-right:10px}
.bk .mgl {margin-left:10px}
.bk .pdl {padding-left:10px}
.bk .clearfix {clear:both;}
.bk .t_Red {color:#BE1F22}

/* 버튼 */
// .bk .btn {display:inline-block;font-weight:400;}
.bk .btn.btn_line {border:1px solid #1e1e1e;background:#fff;font-size:12px;color:#1e1e1e;padding:8px 20px;}
.bk .btn_type03 {display:inline-block;font-size:11px;color:#fff;background:#1428a0;padding:4px 7px;font-weight:100;box-sizing:border-box;letter-spacing:-.2px}
.bk .btn_type04 {display:inline-block;width:200px;font-size:13px;color:#fff;background:#7a1d05;padding:10px 20px;font-weight:100;box-sizing:border-box}
.bk .btn_type06	{display:inline-block;font-size:12px;color:#fff;background:#f35219;padding:5px 5px;font-weight:100;border:1px solid #f35219;box-sizing:border-box;letter-spacing:-.8px}
.bk .btn-horiz {display:flex;flex:auto;justify-content:center;margin-top:30px;text-align:center;padding-bottom:20px;}
.bk .btn-horiz .btn {width:150px; margin: 5px;}
.bk .btn_type08 {background:#7a1d05;color:#fff}
.bk .btn_type09 {background:#ccc;color:#fff}
.bk .btn-group {margin:0}
.bk .btn-horiz .btn-small {width:80px !important;height:36px}
.bk .align-left {float:left;}
.bk .align-right {float:right;}
.bk .align-left button{float:left;margin-right:5px;}

/* input */
.bk .readinp {background-color:#fafafa}
.bk .inpwrap {position:relative;display:flex}
.bk .inpwrap .inp {height:44px}
.bk .inpwrap + .inpwrap {margin-top:10px}
.bk .inpwrap .selec {/*margin-right:10px;*/background-position:95%;height:44px;}
.bk .inpwrap .selec:last-child {margin-right:0}
.bk .inphoriz {display:flex;}
.bk .inphoriz .inp {flex:1}
.bk .selec {width:100%;height:40px;color:rgb(68, 68, 68);font-size:13px;background-color:rgb(255, 255, 255);box-sizing:border-box;-webkit-appearance:none;padding:0px 15px 0 10px;border-width:1px;border-style:solid;border-color:rgb(208, 210, 214);border-image:initial;border-radius:0px;outline:none;background: #fff url(../images/main/btn_arr02.png) no-repeat 97%;}
.bk .add_t {font-size:12px;color:#e63131;margin-top:5px}
.bk .add_tg {font-size:12px;color:#979797;margin-top:5px}
.bk .add_tg a {text-decoration: underline;color:#438BFF}
// .bk .inpwrap:after, .bk .inp_wbox:after {content:'';clear:both;display:block}
.bk .inp_txt {display:inline-block;/*float:left;width:164px;*/position:relative;width:100%;}
.bk .inp_price {position:relative}
.bk .inp_txt:after, .bk .inp_wbox:after {display:inline-block;content:'만원';position:absolute;right:10px;top:13px;font-size:13px;}

/* 라디오버튼, 체크박스 */
.bk .radio_box, .bk .check_box {display:inline-block;height:100%;}
.bk .check_box .check {height:16px;display:inline-block;vertical-align:middle;position:relative;z-index:1;}
.bk .check_box strong {float:left;vertical-align:middle;font-weight:300}
.bk .check_box i {width:16px;height:16px;display:block;background:url(../images/img_bk/bg_check.png) no-repeat;background-size:contain;box-sizing:border-box;float:left;position:relative;z-index:1;}
.bk .check_box input {opacity:0;width:16px;height:16px;position:absolute;left:0;top:0;z-index:2;cursor:pointer; }
.bk .check_box input:checked + i {display:block;width:16px;height:16px;box-sizing:border-box;background:url(../images/img_bk/bg_check_on.png) no-repeat;background-size:contain}
.bk .radio_box .radio {height:22px;padding:0 100px 0 0;display:table-cell;vertical-align:middle;position:relative;z-index:1;line-height:26px;}
.bk .radio_box strong {float:left;vertical-align:middle;font-weight:500;font-size:13px;margin-top:-3px}
.bk .radio_box i {width:22px;height:22px;margin-right:10px;display:block;border:1px #dcdcdc solid; border-radius:13px;box-sizing:border-box;float:left;position:relative;z-index:1;background:#fff }
.bk .radio_box input {opacity:0;width:22px;height:22px;position:absolute;left:0;top:0;z-index:2;cursor:pointer; }
.bk .radio_box input:checked + i:after {content: "";width:10px;height:10px;display:block; background:#922219;border-radius:5px;position:absolute;left:5px;top:5px;}
.bk .radio_box02 {overflow:hidden;margin-bottom:10px}
.bk .radio_box02>.rd01 {display:inline-block;float:left}
.bk .radio_box02>.rd01>input[type=radio] {display:none}
.bk .radio_box02>.rd01>input[type=radio] + label {display:inline-block;width:100px;padding:0;height:36px;text-align:center;margin: 0 -1px 0 0;line-height:32px;background-color:#fff;border:1px solid #d0d2d6;box-sizing:border-box}
.bk .radio_box02>.rd01>input[type=radio]:checked + label {background:#b9342a;border:1px solid #a31d13;color:#fff;box-sizing:border-box;/*margin-left:-1px*/}
.bk .check_box03>.chk01 {display:inline-block;float:left;}
.bk .check_box03>.chk01>input[type=checkbox] {display:none}
.bk .check_box03>.chk01>input[type=checkbox] + label {font-size:13px;line-height:18px;letter-spacing: -.5px;display:inline-block;width:70px;margin: 0 -1px -1px 0;padding:10px 10px 9px;border:1px solid #d0d2d6;text-align:center;}
.bk .check_box03>.chk01>input[type=checkbox]:checked + label {background:#b9342a;border:1px solid #a31d13;color:#fff;}
.bk .fav_cbox i {background:url(../images/img_bk/ico_favo.png) no-repeat;background-size:contain}
.bk .fav_cbox input:checked + i {background:url(../images/img_bk/ico_favo_on.png) no-repeat;background-size:contain}

/* 탭스타일 */
.bk .tabtype01 {overflow:hidden}
.bk .tabtype01 li {display:inline-block;float:left;}
.bk .tabtype01 li a {display:inline-block;line-height:25px;font-size:15px;color:#666}
.bk .tabtype01 li:before {display:inline-block;width:1px;height:10px;margin:0 20px;background:#999;opacity:0.9;content: '';vertical-align:-1px;}
.bk .tabtype01 li:first-child:before {display:none}
.bk .tabtype01 li a:hover, .bk .tab_wrap li a.on {color:#000;font-weight:bold;border-bottom:1px solid #1e1e1e}
/*
.bk .tab_style04 {text-align:left;margin-top:-5px}
.bk .tab_style04 ul {margin:-3px;display:inline-block}
.bk .tab_style04 ul:after {display:inline-block;content:'';clear:both}
.bk .tab_style04 ul li {display:inline-block;float:left;margin:3px}
.bk .tab_style04 ul li a {display:block;padding:8px 20px;border-radius:50px;background:#f0f0f0;font-size:12px;color:#ccc}
.bk .tab_style04 ul li:hover a, .bk .tab_style04 ul li.on a {background:#ba341a;color:#fff}
*/
.bk .tab_style06 {position:relative;margin-bottom:10px}
.bk .tab_style06 .tab {overflow:visible;display:block;width:100%;height:40px;border-bottom:1px solid #1e1e1e;}
.bk .tab_stype06 .tab:after {display:block;content:'';clear:both;}
.bk .tab_style06 .tab li {float:left;box-sizing:border-box;width:auto;}
.bk .tab_style06 li a {display:block;overflow:hidden;border:1px solid #e1e1e1;border-width:1px 1px 0 0;color:#757575;font-size:13px;text-align:center;padding-top:10px;padding-bottom:10px;background:#f8f8f8;border-bottom:1px solid #1e1e1e;padding-left:15px;padding-right:15px;height:41px;box-sizing:border-box}
.bk .tab_style06 li.on a {border-top:1px solid #1e1e1e;border-right: 1px solid #1e1e1e;border-left: 1px solid #1e1e1e;border-bottom: 1px solid #fff;color: #000;background-color: #fff;margin-left: -1px;}
.bk .tab_style06 li:first-child a {border-left-width:1px}
.bk .tab_style06 li:first-child.on a {margin-left:0}
.bk .tab_style06 li.on a:before {display:none;}
.bk .tab_style06 li + li a {position:relative;}

/* 테이블 */
.bk .tbl_lst_type {line-height:40px;table-layout:fixed;text-align:center;font-size:12px;font-weight:400;border-top:1px solid #eaeaea;border-left:1px solid #eaeaea;max-width:100%}
.bk .tbl_lst_type tbody tr {border-bottom:1px solid #eaeaea;}
.bk .tbl_lst_type tbody td {padding:10px;text-align:left;border-right:1px solid #eaeaea;line-height:1.6}
.bk .tbl_lst_type tbody td .btn {height:40px;box-sizing:border-box;line-height:1.25em;padding:0 30px;font-size:13px;}
.bk .tbl_lst_type tbody td .pass_area {margin-top:10px}
.bk .tbl_lst_type tbody td.file_td {padding:5px;}
.bk .tbltype01 {width:100%;}
.bk .tbltype01 tbody th {text-align:left;padding:10px;color:#444444;font-weight:500;background:#f9f9f9;border-right:1px solid #eaeaea;line-height:1.6;}
.bk .tbltype01 td .inp {height:40px;display:block;font-size:12px;font-weight:600;padding:5px;}
.bk .tbltype01 td .inp + .inp {margin-top:10px}
.bk .tbltype01 td .add_t + .inp {margin-top:10px;}
.bk .inp + .selec {margin-top:10px}
.bk .tbltype02 thead tr {border-bottom:1px solid #eaeaea;background:#fafafa}
.bk .tbltype02 thead th {padding:8px 2px;border-right:1px solid #eaeaea}
.bk .tbltype02 tr td {padding:8px 2px;text-align:center;border-right:1px solid #eaeaea}
.bk i.new {display:inline-block;background:url(../images/img_bk/ico_new.png) no-repeat;width:14px;height:14px;vertical-align:middle;margin-right:2px;text-indent:-9999px}
.bk .new01 {display:inline-block;width:5px;height:5px;border-radius:50px;background:#e83848;margin-right:5px}
.bk .tbltype02 tr td.ellipsis {max-width:90%;}

/*헤더영역*/
.bk {background:#f4f4f4;min-height:100%;min-width:1100px;overflow-x:scroll;display:flex;flex-flow:column nowrap;}
.bk .header {background:#fff;border-bottom:1px solid #dadce0;height:50px;width:100%;position:fixed;z-index:3;text-align:left;}
.bk .header .header_inner {width:100%;height:100%;padding:0 60px;position:relative;box-sizing:border-box}
.bk .header .menu {position:absolute;top:5px;left:10px}
.bk .header .btn_menu {background:url(../images/img_bk/btn_menu.png) no-repeat;width:40px;height:40px}
.bk .header .logo h1 {display:inline-block;background:url(../images/img_bk/logo.png) no-repeat;cursor:pointer;width:106px;height:24px;text-indent:-9999px}
.bk .header .mem_area {position:absolute;right:50px;top:0;/*overflow:hidden;*/display:flex;align-items: center;}
.bk .header .login_info {display:inline-block;position:relative;/*float:left*/}
.bk .header .login_info .pic {display:inline-block;width:24px;height:24px;border-radius:50px;margin-right:7px}
.bk .header .login_info .pic img {width:100%;height:100%;background-size:contain;border-radius:100%;vertical-align:middle;object-fit:cover}
.bk .header .login_info .name {display:inline-block;vertical-align:middle}
.bk .header .login_info .id {display:inline-block;margin-left:5px;}
.bk .header .logout {display:inline-block;/*float:left;*/vertical-align:middle;margin-left:10px}
.bk>header .alarm {display:inline-block;/*float:left;*/vertical-align:middle;}
.bk .header .alarm {padding-top:5px;position:relative}
/* .bk .header .logout {padding-top:5px} */
.bk .header .btn_logout {background:none;color:#ccc;border:1px solid #ccc;font-size:12px;padding:2px 8px;border-radius:50px}
.bk .header .btn_alarm {display:inline-block;width:50px;height:50px;position:relative;right:unset}
.bk .header .btn_alarm .ico_alarm {display:block;background:url(../../assets/images/ico_alarm.png) no-repeat;background-size:contain;width:50px;height:50px;text-indent:-9999px}
.bk .header .alarm .count {position:absolute;top:0;right:5px;width:14px;height:14px;border-radius:15px;background:#f76a4c;text-align:center;}
.bk .header .alarm .count em, .bk .header .alarm .count span {font-size:5px;color:#fff;display:inline-block;vertical-align:middle;letter-spacing:-1px;margin-left:-2px;margin-top:-10px}
.bk .header .container {width:100%;min-height:100%;position:relative;z-index:1}
.bk .container {/*padding-top:50px;*/min-height:100%;position:relative;display:flex;flex-flow: row nowrap;align-items: flex-start;overflow-x:hidden;}
/*알람*/
.bk .alarm_wrap {position:absolute;top:60px;right:20px;bottom:0;width:300px;height:400px;overflow-y:auto;box-sizing:border-box;background:#fff;box-shadow:0 0 0 2px rgba(0,0,0,.06);z-index:1000}
.bk .alarm_wrap .alarm_cont {padding:42px 0 10px;position:relative;text-align:left}
.bk .alarm_wrap .alarm_cont h3 {position:absolute;left:10px;top:10px}
.bk .alarm_wrap .alarm_cont .btn_area {position:absolute;right:10px;top:10px}
.bk .alarm_wrap .alarm_cont .btn_area button {font-size:11px;border:1px solid #ccc;padding:2px 5px}
.bk .alarm_wrap .noalarm {padding:20px 10px 0;text-align:center;font-size:14px;}
.bk .alarm_wrap .al_lst {width:100%;padding:10px 30px 10px 10px;border-bottom:1px solid #d2d2d2;position:relative}
.bk .alarm_wrap .al_lst:first-child {border-top:1px solid #ccc}
.bk .alarm_wrap .alarm_cont .al_lst:last-child {border-bottom:none}
.bk .alarm_wrap .al_lst:hover {background:#f7f7f7}
.bk .alarm_wrap .al_lst.read .cont, .bk .alarm_wrap .al_lst.read .date {color: color(normal, 030) !important}
/*.bk .alarm_wrap .alarm_cont .alfi {border-top:1px solid #ccc}*/
.bk .alarm_wrap .al_lst .cont {display:block;font-size:13px;}
.bk .alarm_wrap .al_lst .date {display:block;font-size:12px;color: color(normal, 030);}
.bk .alarm_wrap .al_lst .close {display:block;background:url(../images/img_bk/ico_close02.png) no-repeat;background-size:contain;width:10px;height:10px;text-indent:-9999px;position:absolute;right:10px;top:50%;margin-top:-5px;}

// /*네비영역*/
// .bk nav {/*position:fixed;top:50px;bottom:0;left:0;*/flex-shrink: 0;align-self: stretch;background:#7a1d05;width:120px;overflow-y: scroll;/*box-shadow:0 0 5px 0 rgba(0,0,0,0.35);z-index:100*/}
// .bk .gnb {padding-bottom:100px}
// .bk .gnb li {display:block;color:#fff;float:inherit;width:100%}
// .bk .gnb>li>a {display:block;width:120px;height:64px;text-indent:-9999px;position:relative;background-position:center center;background-size:contain;}
// .bk .gnb>li>a:hover, .bk .gnb>li>a.on {background-color:#631400;}
// .bk .gnb li.m1>a {background-image:url(../images/img_bk/ico_m01.png);background-repeat:no-repeat;}
// .bk .gnb li.m2>a {background-image:url(../images/img_bk/ico_m02.png);background-repeat:no-repeat;}
// .bk .gnb li.m3>a {background-image:url(../images/img_bk/ico_m03.png);background-repeat:no-repeat;}
// .bk .gnb li.m4>a {background-image:url(../images/img_bk/ico_m08.png);background-repeat:no-repeat;}
// .bk .gnb li.m5>a {background-image:url(../images/img_bk/ico_m09.png);background-repeat:no-repeat;}
// .bk .gnb li.m6>a {background-image:url(../images/img_bk/ico_m05.png);background-repeat:no-repeat;}
// .bk .gnb li.m7>a {background-image:url(../images/img_bk/ico_m06.png);background-repeat:no-repeat;}
// .bk .gnb li.m8>a {background-image:url(../images/img_bk/ico_m10.png);background-repeat:no-repeat;}
// .bk .gnb li.m9>a {background-image:url(../images/img_bk/ico_m11.png);background-repeat:no-repeat;}
// .bk .gnb li.m10>a {background-image:url(../images/img_bk/ico_m12.png);background-repeat:no-repeat;}
// .bk .gnb li .sub {width:120px;padding:8px 0;background:#922219;text-align:center;display:flex;flex-direction: column;flex-wrap: nowrap;row-gap: 2px;}
// .bk .gnb li .sub a {color:#fff;font-weight:100;width:120px;display:inline-block;padding:1px 0;font-size:12px}
// .bk .gnb li .sub li a:hover, .bk .gnb li .sub li a.on {color:#feb900;font-weight:500}
.bk section {width:100%;height:100%;text-align:center;position:relative;overscroll-behavior:none;box-sizing:border-box}
// .bk .gnb li.m4>a.new:before, 
// .bk .gnb li.m5>a.new:before,
// .bk .gnb li.m6>a.new:before,
// .bk .gnb li.m8>a.new:before
//  {display: inline-block;content:'';background: url(../images/img_bk/ico_new.png) no-repeat;width:14px;height:14px;text-indent:-9999px;position:absolute;top:13px;left:25px;}
// .bk .gnb li .sub li a {position:relative}
// .bk .gnb li .sub li a.new {text-indent:0;}
// .bk .gnb li .sub li a.new:after {display: inline-block;content:'';background: url(../images/img_bk/ico_new.png) no-repeat;width:14px;height:14px;text-indent:-9999px;vertical-align:middle;margin-left:3px;background-size:contain;}
// /*.bk .gnb>li>a.new {background:none}*/
// .bk .gnb li .sub li a i.new {display:inline-block;margin-top:-2px;vertical-align:middle;letter-spacing:-1px;background:#e83848;margin-right:0;margin-left:2px;width:auto;height:auto;padding:3px 7px 1px 5px;border-radius:50px;color:#fff;font-size:10px;text-indent:unset}


/*
@media only screen and (max-width: 768px){
	.bk .gnb {display:block;}
	.bk .gnb li {float:none;width:100%}
}
*/
  
/*푸터영역*/
.bk footer {width:100%;border-top:1px solid #d5d5d5;position:relative;background:#fff;}
.bk .footer_inner {font-size:12px}
.bk .footer_link {text-align:center;border:none}
.bk .footer_link ul {overflow:hidden;display:inline-block;}
.bk .footer_link ul li {float:left}
.bk .footer_link ul li a {display:inline-block;line-height:50px;font-size:12px}
.bk .footer_link ul li:first-child .f_txt:before {display:none}
.bk .footer_info {text-align:center;margin:0 auto;padding-top:10px;padding-bottom:50px;max-width:inherit}
.bk .f_info span {font-size:12px;line-height:1.6;font-weight:400}
.bk .f_info span:before {display:inline-block;width:1px;height:8px;margin:0 10px;background:#999;opacity:0.9;content: '';vertical-align:-1px}
.bk .f_info span:first-child:before {display:none}

/*도움말*/
.bk .btn_help {position:fixed;right:40px;bottom:40px;top:inherit;z-index:3;transform:none}
.bk .btn_help>a {display:inline-block;background:#ffa800;color:#fff;width:50px;height:50px;text-align:center;padding-top:17px;box-sizing:border-box;font-size:11px}
/*.bk .btn_help>a:after {font-family:'FontAwesome';content:"\f059";font-size:14px;position:absolute;left:20px;top:9px;color:#fff}*/
.bk .btn_guide {position:fixed;right:0;top:160px;z-index:3}
.bk .btn_guide>a {display:inline-block;background:#3c18a0;color:#fff;font-size:14px;width:70px;height:70px;text-align:center;padding-top:15px;box-sizing:border-box;}
.bk .btn_guide02 {position:fixed;right:0;top:240px;z-index:3}
.bk .btn_guide02>a {display:inline-block;background:#912319;color:#fff;font-size:14px;width:70px;height:70px;text-align:center;padding-top:15px;box-sizing:border-box;}
/*.bk .btn_guide>a:after {font-family:'FontAwesome';content:"\f0eb";font-size:15px;position:absolute;left:20px;top:8px;color:#fff;font-weight:100}*/
.bk .layer_help {position:fixed;width:350px;height:520px;bottom:100px;right:40px;background:#fff;border:1px solid #cdcdcd;}
.bk .layer_help-header {height:50px;width:100%;background:#7a1d05;color:#fff;position:relative}
.bk .layer_help-header h3 {font-size:17px;font-weight:100;line-height:48px}
.bk .btn_close {position:absolute;right:12px;top:18px;background:url(../images/img_bk/btn_del.png) no-repeat;width:15px;height:15px;text-indent:-9999px}
.bk .layer_help-body {height:470px;overflow-y:auto;overscroll-behavior:contain;}
.bk .layer_inner>ul>li {display:block;width:100%;}
.bk .layer_inner>ul>li:last-child>a {border-bottom:none}
.bk .help_link {display:block;width:100%;text-align:left;font-size:14px;font-weight:600;padding:10px 20px;border-bottom:1px solid #ccc;;box-sizing:border-box}
.bk .help_link:hover {color:#e44332}
.bk .help_link:before {display:inline-block;color:#e44332;margin-right:7px;content:'+'}
.bk .help_link.on:before {display:inline-block;color:#e44332;margin-right:7px;content:'-'}
.bk .help_sub_lst li {position:relative}
.bk .help_ques li {position:relative}
.bk .help_sub_link {text-align:left;display:block;padding:10px 30px 10px 32px;font-size:13px;font-weight:400;border-bottom:1px solid #e4e4e4;background:#f5f5f9}
.bk .help_sub_link:after {font-family:'FontAwesome';content:"\f054";font-size:10px;position:absolute;right:12px;top:12px;color:#666}
.bk .help_sub_lst:first-child {padding-top:0}
.bk .help_sub_link:hover {text-decoration:underline}
.bk .help_content {text-align:left;padding:45px 20px 20px;height:470px;overflow-y:auto;box-sizing:border-box;position:relative}
.bk .help_content .btn_back {position:absolute;top:20px;left:20px}
.bk .help_q {font-size:16px;margin-bottom:10px;font-weight:600}
.bk .help_a {font-size:13px;font-weight:400;line-height:1.6}
.bk .btn_ytube {position:fixed;right:40px;top:240px;z-index:3}
.bk .btn_ytube>a {display:inline-block;background:var(--main-color-1);color:#fff;font-size:14px;width:70px;height:70px;text-align:center;padding-top:15px;box-sizing:border-box;}

/* 매물옵션 */
.bk .option_box ul {overflow:hidden;width:100%;padding-bottom:1px;}
.bk .option_box li {display:inline-block;float:left;margin:0 -1px -1px 0}
.bk .option_box li span {display:block;width:90px;height:90px;text-indent:-9999px}
.bk .opt01 {background:url(../images/img_bk/img_option01.png) no-repeat}
.bk .opt02 {background:url(../images/img_bk/img_option02.png) no-repeat}
.bk .opt03 {background:url(../images/img_bk/img_option03.png) no-repeat}
.bk .opt04 {background:url(../images/img_bk/img_option04.png) no-repeat}
.bk .opt05 {background:url(../images/img_bk/img_option05.png) no-repeat}
.bk .opt06 {background:url(../images/img_bk/img_option06.png) no-repeat}
.bk .opt07 {background:url(../images/img_bk/img_option07.png) no-repeat}
.bk .opt08 {background:url(../images/img_bk/img_option08.png) no-repeat}
.bk .opt09 {background:url(../images/img_bk/img_option09.png) no-repeat}
.bk .opt10 {background:url(../images/img_bk/img_option10.png) no-repeat}
.bk .opt11 {background:url(../images/img_bk/img_option11.png) no-repeat}
.bk .opt12 {background:url(../images/img_bk/img_option12.png) no-repeat}
.bk .opt13 {background:url(../images/img_bk/img_option13.png) no-repeat}
.bk .opt14 {background:url(../images/img_bk/img_option14.png) no-repeat}
.bk .opt15 {background:url(../images/img_bk/img_option15.png) no-repeat}
.bk .opt16 {background:url(../images/img_bk/img_option16.png) no-repeat}
.bk .opt17 {background:url(../images/img_bk/img_option17.png) no-repeat}
.bk .opt01.selected {background:url(../images/img_bk/img_option01on.png) no-repeat}
.bk .opt02.selected {background:url(../images/img_bk/img_option02on.png) no-repeat}
.bk .opt03.selected {background:url(../images/img_bk/img_option03on.png) no-repeat}
.bk .opt04.selected {background:url(../images/img_bk/img_option04on.png) no-repeat}
.bk .opt05.selected {background:url(../images/img_bk/img_option05on.png) no-repeat}
.bk .opt06.selected {background:url(../images/img_bk/img_option06on.png) no-repeat}
.bk .opt07.selected {background:url(../images/img_bk/img_option07on.png) no-repeat}
.bk .opt08.selected {background:url(../images/img_bk/img_option08on.png) no-repeat}
.bk .opt09.selected {background:url(../images/img_bk/img_option09on.png) no-repeat}
.bk .opt10.selected {background:url(../images/img_bk/img_option10on.png) no-repeat}
.bk .opt11.selected {background:url(../images/img_bk/img_option11on.png) no-repeat}
.bk .opt12.selected {background:url(../images/img_bk/img_option12on.png) no-repeat}
.bk .opt13.selected {background:url(../images/img_bk/img_option13on.png) no-repeat}
.bk .opt14.selected {background:url(../images/img_bk/img_option14on.png) no-repeat}
.bk .opt15.selected {background:url(../images/img_bk/img_option15on.png) no-repeat}
.bk .opt16.selected {background:url(../images/img_bk/img_option16on.png) no-repeat}
.bk .opt17.selected {background:url(../images/img_bk/img_option17on.png) no-repeat}

/* popup 공통 */ 
.bk .pop_mask {position:fixed; top:0; left:0;right:0;bottom:0;background:rgba(0,0,0,0.4); width:100%;content: "";z-index:4}
.bk .pop {background:#fff;z-index:1005;border:1px solid #cdcdcd;-webkit-box-shadow:0 2px 4px 0 rgba(0,0,0,.08);box-shadow:0 2px 4px 0 rgba(0,0,0,.08);-webkit-box-sizing:border-box;box-sizing:border-box;position:fixed;left:50%;top:50%;transform:translate(-50%,-50%);max-height:600px}
.bk .pop_header {position:relative;width:100%;padding:30px 30px 20px;box-sizing:border-box}
.bk .pop_header .close {display:block;position:absolute;top:20px;right:20px;width:20px;height:20px;background:url(../images/img_bk/btn_close02.png) no-repeat;background-size:contain;}
.bk .pop_tit {font-size:22px;color:#1e1e1e;text-align:center;font-weight:400}
.bk .pop_cont {padding:0 30px 50px;position:relative}
.bk .pop_contarea {display:inline-block;width:100%}
.bk .pop .btn_area01 button {width:164px}
.bk .pop .radio_box {height:50px;padding-bottom:10px;width:100%}
.bk .pop .radio_box .radio {padding-right:50px}
.bk .pop .radio_box .radio:last-child {padding-right:0}
.bk .pop_contarea + .btn-horiz {margin-top:12px;}

.bk .alert_wrap {display:table;/*width:100%;height:100%*/}
.bk .alert {display:table-cell;width:350px;vertical-align:middle;height:auto;background-color:#fff;z-index:1001;}
.bk .alert .pop_cont {padding:50px 30px 30px}
.bk .alert .pop_cont p {text-align:center;font-size:15px;}
.bk .alert .btn_area01 {margin-top:0}
.bk .alert .btn_area01 button {padding:5px 20px;width:inherit}
.bk .alert .btn-horiz {margin-top:0;}
.bk .alert .btn-horiz button {padding:5px 20px;width:inherit;height:30px;margin:0;}
.bk .alert .btn-horiz button:last-child {margin-right:0}
.bk .alert .btn-horiz .btn_type09 {margin-right:10px}
.bk .alert big {font-size:18px;font-weight:bold;display:block;padding-bottom:5px}
.bk .alert .p_footer {height:65px}

/* 툴팁 */
.bk .tip i {display:inline-block;background:url(../images/img_bk/ico_tip.png) no-repeat;width:14px;height:14px;margin-top:-2px;background-size:contain;vertical-align:middle;text-indent:-9999px;margin-left:2px;}
.bk [data-tooltip-text]:hover, .bk [data-tooltip-text].on {position:relative;}
.bk [data-tooltip-text]:hover:after, .bk [data-tooltip-text].on:after {content:attr(data-tooltip-text);display:block;width:auto;min-width:200px;max-width:400px;position:absolute;top:100%;right:0;padding:10px;background-color:#fff;border:1px solid #d2d2d2;color:#666;font-size:12px;z-index:9999;text-align:left;pointer:pointer;font-weight:500;box-sizing:border-box;word-break:normal}
.bk .t_lf[data-tooltip-text]:hover:after, .bk .t_lf[data-tooltip-text].on:after {position:absolute;left:0;top:100%;bottom:inherit;right:inherit;z-index:100}

.bk .btn_s_sch {position:absolute;right:10px;top:9px;background:url(../images/sub/btn_s_sch.png) no-repeat;width:20px;height:17px}
.bk td.sch_bkaddr {position:relative}

.bk td .sch_result {width:540px;top:99px;left:10px;padding:10px;box-sizing:border-box;min-height:65px;max-height:200px;bottom:inherit;height:auto}
.bk .sch_result .sch_word {display:block;width:100%;padding:3px 0;position:relative;font-size:13px;box-sizing:border-box}
.bk .sch_result a:hover {text-decoration:underline}
.bk .sch_result a .address {color:#888;position:absolute;right:0;font-size:12px}

.bk td.sch_bkaddr .sch_result {position:absolute;width:calc(100% - 20px);max-height:300px;@include overflow-scroll-custom (dark, medium, y);padding:10px;box-sizing:border-box;top:103px;left:10px;border:1px solid #d0d2d6;z-index:100;background:#fff;min-height:50px}

/* 수정,삭제 */
.bk .func_area {margin-left:10px}
.bk .func {display:inline-block;font-size:11px;color:#999;}
.bk .func + .func:before {display:inline-block;width:1px;height:9px;margin:0 5px 2px ;content:'';vertical-align:middle;background-color:#a2a2a2;}
.underline {text-decoration:underline}


@media only screen and (max-width: 768px) {
	.bk .header {text-align:left;}
/*	.bk .logo {left:110px;margin-top:19px}*/
/*	.bk nav {display:none;}*/
	.bk .header .header_inner {position:static;}
	.bk .header .mem_area {right:30px}
	.bk .gnb {margin-bottom:100px;margin-top:0;display:block;}
	.bk .gnb li {float:inherit;width:100%}
	/* .bk section {padding-left:0}
	.bk .container {padding-top:50px;} */
/*    .bk .sect01 {width:90%}	*/
	.bk .tbltype01 tbody td .inp {width:90%}
	.bk footer {padding-left:0}
	.bk .bk_card_wrap {padding:60px 0;width:auto;}
	.bk .gnb li > a.new {background-color:inherit;right:inherit;right:inherit;width:100%;border-radius:0}
	.bk .gnb li > a.new:hover, .bk .gnb li > a.new.on {background-color:#631400}
	.bk .avr_area {width:auto;display:inline-block;width:auto}
	.bk .cardlst .stu_count small {font-size:inherit;}
	.bk .cdlst01 small, .bk .cdlst03 small {color:#fff}
	.bk .footer_link ul li a {color:#222}
	.bk .footer_info {color:#222;}
	.bk .mydawin .btn-horiz .btn {margin:5px;}
}

:root.root-dawin {
	a:hover, a.on, a:active{cursor:pointer}
}