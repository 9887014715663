@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */

:root.root-dawin {
	/*.bk .container {width:1200px;}*/
	.scroll {overflow:hidden}
	.bk .tit_exp {font-size:12px;line-height:1.6;text-align:left;font-weight:400}
	.bk .tit + .tit_exp {margin-bottom:10px}
	.bk .sect_box01 .tit_exp {margin-bottom:10px}
	.bk .tabcon_inner .tit_exp:last-child {margin-bottom:15px}
	.bk .pop_cont .tit_exp {font-size:14px;}

	/* 현황판 */
	.bk .bk_card_wrap {display:inline-flex;flex-flow:row wrap;/*padding:30px 100px 60px 100px;*/padding-top:30px;width:1000px;min-height:550px;justify-content:center;}
	.bk .bk_card_wrap .situ_box {text-align:center;margin-top:10px;}
	.bk .bk_card_wrap .bk_card {display:inline-block;display:inline-flex;flex-flow:row wrap;justify-content: space-between;width:1000px;}
	// .bk .bk_card_wrap .bk_card:after {display:block;content:'';clear:both}
	.bk .bk_card_wrap .bk_card li {/*display:inline-block;float:left;min-width:180px;*/max-width:180px;height:160px;padding:20px;box-sizing:border-box;margin:10px;text-align:center;cursor:pointer;position:relative;border-radius:16px;flex-grow:1;flex-shrink:0;flex-basis:150px;}
	.bk .bk_card_wrap .bk_card li:last-child {margin-right:0}
	.bk .bk_card_wrap .bk_card li:first-child {margin-left:0}
	.bk .cdlst01 {background:#ba341a}
	.bk .cdlst02 {background:#fff}
	.bk .cdlst03 {background:#888}
	.bk .cardlst .cd_tit {font-size:16px;font-weight:300;margin-bottom:3px;margin-top:10px;letter-spacing:-.9px}
	.bk .cardlst .stu_count {font-size:24px;font-weight:300;position:relative;text-align:center;display:flex;flex-direction: row;justify-content: center;}
	.bk .cardlst .stu_count:after {display:block;content:'';clear:both}
	.bk .cardlst .stu_count strong {font-size:32px;font-weight:bold;position:relative;float:left;height:50px;line-height:50px;}
	.bk .cardlst .stu_count strong .count {position:absolute;top:2px;right:-10px;min-width:15px;height:15px;color:#fff;letter-spacing:0;text-align:center;background-color:#f64e23;border-radius:15px;justify-content:center;align-items:center;font-size:9px;font-weight:500;line-height:15px;display:inline-flex;}
	.bk .cardlst .stu_count strong .count em {position:absolute;font-size:9px;color:#fff;display:inline-block;vertical-align:middle;letter-spacing:-1px;margin-left:-2px;margin-top:0;}
	.bk .cardlst .stu_count small {position:relative;float:left;height:50px;line-height:50px;margin-left:0;color:#a7a7a7}
	.bk .cardlst .stu_count small:before {display:inline-block;content:'/';font-size:12px;	margin:0 8px;}
	.bk .cardlst .stu_count strong i {position:absolute;top:50px;left:50%;transform:translateX(-50%);display:block;line-height:1.5;font-size:12px;font-weight:300}
	.bk .cardlst .stu_count small i {margin-left:10px;position:absolute;top:50px;bottom:inherit;left:50%;transform:translateX(-50%);line-height:1.5;display:block;min-width:fit-content;font-size:12px;font-weight:300;}
	.bk .cardlst.cdlst01 .stu_count small {color:#fff;}
	.bk .cardlst .stu_count i {position:absolute;bottom:-19px;left:50%;transform:translateX(-50%);/*min-width:46px;*/font-size:12px;font-weight:400}
	.bk .cdlst01 .cd_tit, .bk .cdlst01 .stu_count, .bk .cdlst03 .cd_tit, .bk .cdlst03 .stu_count {color:#fff}
	.bk .cardlst .q_count {font-size:30px;font-weight:300}
	.bk .cardlst .q_count .curr_q {font-size:40px}
	.bk .cardlst .addtxt {font-size:11px;position:absolute;bottom:-25px;text-align:left;left:0;color:#666;font-weight:300}

	/*@media (max-width: 896px){
	.bk section {padding-left:0;padding:0 40px;overflow-x:scroll}
		.bk nav {display:none;}
	.bk .bk_card_wrap {padding:60px 0;width:100%;}
	
	}*/

	.bk .notice-alert {background:#fff;padding:12px 20px;text-align:left;border-bottom:1px solid #dbdde2;width:100vw}
	.bk .notice-alert:before {display:inline-block;content:'[공지]';font-weight:600;vertical-align:middle;}
	.bk .notice-alert a {display:inline-block;vertical-align:middle;text-align:left;padding-left:5px;}
	.bk .notice-alert a span {display:inline-block;font-size:15px;}
	.bk .notice-alert .notice-date {font-size:13px;color:#999;margin-left:3px}
	.bk .notice-alert .btn-close {position:absolute;right:20px;top:15px}


	/* mydawin */
	/* 개인정보 */
	.bk .sect_inner {padding:32px 0 60px;width:95%;margin: 0 auto;min-height:550px;position:relative;overscroll-behavior:none;}
	.bk .sect01 {/*padding-top:30px;width:90%;max-width:950px;*/max-width:1000px;min-width:700px}
	.bk .cont_wrap {width:100%;position:relative;padding-bottom:0}
	.bk .sect_box01 {width:100%;/*max-width:1000px;*/background:#fff;padding:20px;box-sizing:border-box;/*margin:0 auto;*/position:relative;clear:both}
	.bk h2.tit {font-size:18px;/*padding-bottom:10px;*/display:flex;column-gap:4px;align-items:center;text-align:left;margin-bottom:10px;}
	.bk .tabcont {margin-top:10px}
	.bk .tabcont .tabcon_inner {position:relative}
	.bk .btn-changepass {margin-top:10px;}
	.bk .profileWrap {position:relative;display:block;width:148px;height:148px;}
	.bk .uprofile {display:inline-block;position:relative;vertical-align:top;font-size:0;}
	.bk .profileWrap .add_t {position:absolute;left:200px;top:50%;width:300px;transform:translatey(-50%);color:#ADADAd;line-height:1.6}
	.rs_hold {text-align:left;padding:15px;font-size:13px;background:#f9f9f9;margin-top:10px;}
	.bk .tbltype01 .add_t + .btn_area01 {margin-top:10px;}

	/*@media (max-width: 896px){
	.bk .sect01 {width:1000px}
	}*/

	/* 사무소정보 */
	.bk .radio_box + .inp {display:block;margin-top:10px}
	.bk .maparea .map_bg {background:url(../images/img_bk/img_map_ex02.png) no-repeat;width:100%;height:100%;background-size:cover;position:absolute;top:0;left:0;right:0;bottom:0}
	.bk .btn_map_here {position:absolute;top:100px;left:300px;z-index:50}
	.bk .ico_current_position {display:block;background:url(../images/img_bk/ico_pointer.png) no-repeat;background-size:contain;width:32px;height:45px}
	.bk .secession {text-align:right;padding-top:5px}
	.bk .secession a {font-size:13px;color:#666}
	.bk .emp_box ul {overflow:hidden;margin:-5px}
	.bk .emp_box ul .emp_card {display:inline-block;float:left;border:1px solid #ccc;padding:15px;width:320px;height:172px;margin-bottom:10px;box-sizing:border-box;position:relative;margin:5px}
	.bk .emp_card .info_area {padding-left:105px;overflow:hidden}
	.bk .emp_card .info_area .inp {/*display:inline-block;*/padding-right:5px;width:160px;/*float:left;margin-right:10px*/}
	.bk .emp_card .info_area .selec {/*display:inline-block;*/padding-right:10px;width:160px;/*float:left*/background: #fff url(../images/main/btn_arr02.png) no-repeat 95%;}
	.bk .emp_card .pic {width:130px;height:145px;border:1px solid #ccc;box-sizing:border-box;position:absolute;left:20px;right:0}
	.bk .emp_card .profileWrap {position:absolute;display:block;width:80px;height:80px;left:20px;top:50%;transform:translatey(-50%)}
	.bk .emp_card .profileWrap .btn_file_wrap {width:80px;height:80px;border-radius:80px;}
	.bk .emp_card .profileWrap .btn_file_wrap label {line-height:80px;}
	.bk .emp_card .photoSubmit {margin:8px}
	.bk .add_empcard {width:120px !important;height:187px;padding:0 10px!important;text-align:center;cursor:pointer;border:none !important;}
	.bk .add_empcard .add_e {font-size:15px;display:inline-block;padding-left:30px;background:url(../images/img_bk/btn_add.png) no-repeat 0;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%)}
	.bk .delete {display:block;width:17px;height:17px;text-indent:-9999px;background:url(../images/img_bk/ico_close.png) no-repeat;position:absolute;top:10px;right:10px}
	.bk .mydawin .inpwrap + .inpwrap {margin-top:10px}
	.bk .mydawin #form_chk .btn {font-size:13px;}

	.bk .cal_bor {display:block;position:relative;padding:2px 34px 3px 5px;border:1px solid #cacaca;background:#fff;line-height:16px;height:40px;box-sizing:border-box}
	.bk .cal_bor .inp_txt {width:100%;height:34px;margin:-1px 0;border:0 none;background:transparent;font-size:12px;line-height:16px;text-align:center;vertical-align:top;padding-left:5px}
	.bk .cal_bor .inp_txt:after {display:none}
	.bk .cal_bor .btn_cal {position:absolute;right:5px;top:9px;bottom:0;width:20px;background:#fff;text-align:left;cursor:pointer}
	.bk .cal_bor .btn_cal .sp_case {width:18px;height:20px;margin:0;display: block;background:url("../images/img_bk/ico_cal.png") no-repeat;background-size:contain}

	.bk .checkbtn {height:40px;color:#1e1e1e;position:absolute;right:0;top:0;font-size:14px;padding:0 20px;}
	.bk .btn_file_wrap {margin:0;display:block;float:inherit;width:300px;height:150px;border:1px solid rgb(208, 210, 214)}
	.bk .btn_file_wrap label {width:298px;height:148px;display:block;line-height:148px;text-align:center;background:none;font-size:13px;color:#d0d2d6}
	.bk .btn_file_wrap .btn_file_pic {position:absolute;top:0;right:0;bottom:0;left:0;width:298px;height:148px;opacity:0;filter:alpha(opacity=0);-ms-filter:alpha(opacity=0);cursor:pointer;z-index:1}
	.bk .profileWrap .add_pic {width:148px;height:148px;z-index:2;border-radius:100px;display:inline-block;position:relative}
	.bk .profileWrap .add_pic img {width:148px;height:148px;object-fit:cover;object-position:top;border-radius:100px}
	.bk td .preview {width:100%;height:100%;display:inline-block;}
	.bk td .preview img {width:100%;height:100%;object-fit:cover;object-position:top;}
	.bk .btn_del01 {display:block;width:22px;height:22px;background:url(../images/sub/btn_del02.png) no-repeat;position:absolute;top:5px;right:5px;z-index:3}
	.bk .profileWrap .btn_file_wrap {width:150px;height:150px;border-radius:150px}
	.bk .profileWrap .btn_file_wrap label {width:100%;height:100%;}
	.bk .profileWrap .btn_file_wrap .btn_file_pic {width:100%;height:100%;}

	.bk .inp-textbox {position:relative}
	.bk .inp-textbox .box-list {top:39px;max-height: 300px;overflow-y:scroll;padding:10px;box-sizing:border-box;border: 1px solid #d0d2d6;z-index:100;background:#fff;min-height:50px;}
	.bk .inp-textbox .box-list .sch_word {display:block;width:100%;padding:3px 0;position:relative;font-size:13px;box-sizing:border-box;}
	.bk .mydawin .tbltype01 .maparea {margin:0;height:260px}

	.bk td.row_addr {position:relative}
	.bk td.row_addr .sch_result {position:absolute;z-index:10;background:#fff;width:100%;border:1px solid #d0d2d6;top:66px;left:0;min-height:50px}

	/*비빌번호 수정*/
	.bk .pmodi {width:400px;}
	.bk .pmodi .pop_cont {padding-bottom:0}
	.bk .pmodi .btn-horiz .btn_line {width:100px;margin-right:10px}
	.bk .pmodi .btn-horiz .btn_line:last-child {margin-right:0}
	.bk .pmodi .inp + .txtarea {margin-top:10px}
	.bk .pmodi .inp + .inp {margin-top:10px}

	.bk .file_td .btn_file_wrap {width:138px;height:120px;vertical-align:middle;float:left;margin:5px;}
	.bk .file_td .file_group {margin:-5px}
	.bk .file_td .btn_file_wrap label {width:136px;height:118px;line-height:118px}

	/*사용자추가*/
	.bk .p_footer {width:100%;position:relative;height:80px;text-align:center}
	.bk .p_addu {width:500px}
	.bk .p_addu .pop_cont {max-height:400px;overflow-y:auto;padding-bottom:20px}
	.bk .p_addu .tit_exp {margin-bottom:10px}
	.bk .pop .inp-horiz .inp + .inp {margin-top:0}
	.bk .pop .inp-horiz .inp:first-child {margin-right:10px}
	.bk .p_addu .ulst + .ulst {margin-top:10px}
	.bk .td_btn {font-size:11px;padding:3px 5px;letter-spacing:-2}
	.bk .p_addu .add_box {margin-bottom:20px}
	.bk .p_addu d_box:last-child {margin-bottom:0}
	.bk .p_addu .add_box .add {font-size:12px;color:#666;text-align:right;margin-top:5px}
	.bk .p_addu .btn_area02 {position:absolute;bottom:30px;left:50%;transform:translateX(-50%);right:inherit;top:inherit}
	.bk .commt_box {background:none;font-size:12px;color:#666}

	/* 업무관리 */
	/** 매물관리 **/
	.bk .sect_box02 {width:100%;background:#fff;border-top:#ccc;padding:30px 30px 40px;box-sizing:border-box;margin:0 auto;position:relative;clear:both;}
	.bk .sect_box02 .topbtn {position:absolute;right:30px;top:18px;margin-top:0;padding-bottom:0;z-index:2;}
	.bk .sect_box02 .topbtn .btn_line {margin-right:8px;padding:8px 10px;}
	.bk .sect_box02 .topbtn .btn_line:last-child {margin-right:0}
	.bk .sectask {/*width:95%;*/min-width:1000px;max-width:1400px;padding-top:30px;padding-bottom:30px;overflow-x:scroll}
	.bk .sectask .search_area {position:inherit;/*padding-bottom:10px;height:60pxmargin-top:10px;*/margin-bottom:15px;top:inherit}
	.bk .search_area .btninp {margin-top:0}
	.bk .search_area .btninp .inp {width:70%;height:44px}
	.bk .search_area .btn-refresh {margin-top:9px;margin-left:5px;}
	.bk .search_area .btn_line {height:44px;width:50px;padding:0 10px;margin-left:-1px}
	.bk .radio_box02.mfilter .rd01>input[type=radio] + label {width:auto;padding:0 20px;font-size:13px;cursor:pointer}
	.bk .radio_box02.mfilter  .rd01 input[type=radio]:checked + label {background:#b9342a;border:1px solid #a31d13;color:#fff;box-sizing:border-box;}
	.bk .radio_box02.mfilter {overflow:visible}
	.bk .imp {color:#D30F13}
	.bk .sectask .tblcontainer {width:100%;/*overflow-x:scroll;*/margin-top:10px;position:relative;min-height:180px}
	.bk .sectask .tblcontainer tr {cursor:pointer}
	.bk .sectask .tblcontainer thead tr {cursor: auto;}
	.bk .radio_box02.mfilter>.rd01>input[type=radio].selec_ft + label {background:#b9342a;border:1px solid #a31d13;color:#fff;box-sizing:border-box;}

	.bk .tbltype02 tbody td .cal_bor .btn_cal {position:absolute;right:0;top:3px;bottom:0;width:auto;background:#fff;text-align:left;cursor:pointer}
	.bk .tbltype02 tbody td .cal_bor .sp_case {width:18px;height:20px;margin:0 5px 0 0;display: block;}
	.bk .tbltype02 tbody td .cal_bor .inp_txt {height:24px;text-align:left;font-size:11px;padding-left:0}
	.bk .tbltype02 tbody td .inp {height:30px;font-size:12px;padding:0 5px;border-radius:0}
	.bk .tbltype02 tbody td .inp_price {padding-right:20px}

	.bk .f_deal td {/*opacity:.4;*/color:#ccc !important}
	.bk .f_deal td a {color:#ccc !important}
	.bk .tbltype02 tbody tr.modi_info {background:#f7f7f7}
	.bk .itmtype {display:inline-block;width:19px;height:19px;text-indent:-9999px;}
	.bk .itmtype.my {position:unset;background:url("../images/img_bk/ico_type01.png") no-repeat}
	.bk .f_deal .itmtype.my {background:url("../images/img_bk/ico_type01_off.png") no-repeat}
	.bk .itmtype.public {background:url("../images/img_bk/ico_type02.png") no-repeat}
	.bk .f_deal .itmtype.public {background:url("../images/img_bk/ico_type02_off.png") no-repeat}
	.bk .itmtype.favorite {background:url("../images/img_bk/ico_type03.png") no-repeat}
	.bk .f_deal .itmtype.favorite {background:url("../images/img_bk/ico_type03_off.png") no-repeat}
	.bk .paging_area {margin-top:50px;text-align:center; }
	.bk .paging_area .paging, .bk .paging_area .paging_web {display:inline-block;}
	.bk .paging_area .paging a{display:inline-block;overflow:hidden;width:34px;height:34px;}
	.bk .paging_area .paging a.a01_l {background:url(../images/img_bk/left_j.png) no-repeat;text-indent:-9999px;}
	.bk .paging_area .paging a.a02_l {background:url(../images/img_bk/left.png) no-repeat;text-indent:-9999px;margin-left:-1px;}
	.bk .paging_area .paging a.a01_r {background:url(../images/img_bk/right.png) no-repeat;text-indent:-9999px;}
	.bk .paging_area .paging a.a02_r {background:url(../images/img_bk/right_j.png) no-repeat;text-indent:-9999px;margin-right:-1px;}
	.bk .paging_area .paging a.on {color:#2a2a2a;}
	.bk .paging_area .paging_web {padding:0 10px;}
	.bk .paging_area .paging_web a {display:inline-block;width:34px;height:34px;overflow:hidden;text-align:center;line-height:34px;font-size:12px;color:#666666;font-weight:bold;text-decoration:none;background:url(../images/img_bk/pg_bg.png) no-repeat;margin-right:-1px;}
	.bk .paging_area .paging_web a.on, .bk .paging_	area .paging_web a:hover {background:url(../images/img_bk/pg_bg_on.png) no-repeat;color:#FFF;}

	/*.bk .btn_fakeitm .tip {position:absolute;right:4px;top:8px}*/

	.bk td .set_price + .set_price {margin-top:5px;}
	.bk td .cal_bor {height:30px;padding-right:3px}
	.bk td .inpwrap + .inpwrap {margin-top:3px}
	.bk td .cal_bor + .inpwrap {margin-top:3px}
	/*.bk td .inp, .bk td .inpwrap .inp {height:30px;font-size:12px;padding-left:5px;padding-right:5px;border-radius:0}*/
	.bk td .inp_price {padding-right:20px}
	.bk td .fix_t {font-size:11px;right:3px;top:7px;color:#1e1e1e;font-weight:500}
	.bk .t_kr {display:block;text-align:right;color:#bababa;line-height:1.8}
	.bk .filter_area {position:relative}
	.bk .itm_alert_box {position:absolute;padding:15px;background:#fff;border:1px solid #d0d2d6;font-size:15px;font-weight:500;text-align:center;left:0;min-width:120px;width:300px;z-index:3;top:30px;}
	.bk .itm_alert_box .tit {text-align:left;font-weight:400;font-size:12px;margin-bottom:5px;}
	.bk .itm_alert_box .close {display:block;position:absolute;top:8px;right:8px;width:15px;height:15px;background:url(../images/main/btn_close02.png) no-repeat;background-size:contain;}
	.bk .price_adjust_wrap {margin-top:5px}
	.bk .price_adjust_box {display: inline-block;vertical-align: middle;}
	.bk .price_adjust_wrap .price_adjust_box:after {display:block;content:'';clear:both}
	.bk .price_adjust_wrap .btn_minus, .bk .price_adjust_wrap .btn_plus {width: 32px;height:34px;border:1px solid #dedede;background-color: #f9fafb;text-align:center;vertical-align:top;float:left}
	.bk .price_adjust_box button span {font-size:15px;line-height:1}
	.bk .price_adjust_wrap .adjust_input {font-size: 13px;line-height: 20px;width: 50px;height: 34px;padding: 6px 10px 5px;border: 1px solid #d9d9d9;border-right: 0;border-left: 0;text-align: right;float:left;box-sizing:border-box}
	.bk .price_adjust_wrap .price_adjust_symbol {display: inline-block;position: relative;top: -1px;margin: 0 11px 0 12px;font-size: 13px;vertical-align: middle;}
	.bk .price_wrap {margin-bottom:10px}
	.bk .price_wrap:last-child {margin-bottom:0}
	.bk .itm_alert_box .btn_area:after {display:inline-block;content:'';clear:both}
	.bk .itm_alert_box .btn_area button {display:inline-block;font-size:12px;padding:2px 3px 1px;}
	.bk .itm_alert_box .btn_area button:first-child {float:left}
	.bk .itm_alert_box .btn_area button:last-child {float:right}
	.bk .radio_box02 .rd01:nth-child(2) .itm_alert_box {left:88px;}
	.bk .radio_box02 .rd01:nth-child(3) .itm_alert_box {left:176px;}
	.bk .radio_box02 .rd01:nth-child(4) .itm_alert_box {left:252px;}
	.bk .radio_box02 .rd01:nth-child(5) .itm_alert_box {left:316px;}

	.bk .btn_left {position:absolute;left:70px;top:18px}
	.bk .btn_sort {display:inline-block;margin-left:5px;vertical-align:middle;width:12px;height:12px;margin-top:-2px;text-indent:-9999px;position:static}
	.bk .sort_default {background:url(../images/img_bk/btn_default.png) no-repeat;background-size:contain;}
	.bk .sort_des {background:url(../images/img_bk/btn_des.png) no-repeat;background-size:contain;}
	.bk .sort_asc {background:url(../images/img_bk/btn_asc.png) no-repeat;background-size:contain;}
	.bk .tbl_lst_type .pre_tag {position:inherit;left:inherit;top:inherit;padding:0px 5px 2px;border-radius:10px;font-size:10px;margin-right:2px;color:#fff}
	.bk .pre_tag.tag01 {background:#28b968}
	.bk .pre_tag.tag02 {background:#249b58}
	.bk .pre_tag.tag03 {background:#1f874d}
	.bk .pre_tag.tag04 {background:#00ace5}
	.bk .pre_tag.tag05 {background:#ff8401}
	.bk .pre_tag.tag06 {background:#5827d8}
	.bk .pre_tag.tag07 {background:#ffd200}
	.bk .filter_area form {display:flex;align-items:center;margin-top:10px}
	.bk .residence_area {text-align:left}
	.bk .mfilter {display:inline-block;width:408px;margin-bottom:0;vertical-align:middle;position:relative}
	.bk .fd_itmtype {width:202px}
	.bk .fd_tradetype {width:208px}
	.bk .fd_dealtype {width:210px}
	.bk .fd_situ {width:246px}
	.bk .itm_alert_box .radio_box02>.rd01>input[type=radio] + label, .bk .itm_alert_box .check_box03>.chk01>input[type=checkbox] + label {width:auto;padding:0 10px;height:33px;line-height:32px;font-size:13px}
	.bk .fd_dealtype .check_box03 {margin-bottom:10px;overflow:hidden;}
	.bk .fd_dealtype .check_box03>.chk01>input[type=checkbox] + label {margin:0 -1px 0 0;}
	.bk .filter_area .btn_refresh {position:absolute;top:0;left:340px;height:34px;}
	.bk .btn-refresh {display:inline-block;background:url("../images/img_bk/btn_refresh.png") no-repeat;width:24px;height:24px;text-indent:-9999px;vertical-align: middle}

	/*메모팝업*/
	.bk .tbltype02 tbody td.modi {color:#FF0004}
	.bk td .info_up span {display:inline-block;position:relative}
	.bk td .as {color:#A4A4A4}
	.bk td .as .line {position:absolute;left:0;top:50%;transform:translatey(-50%);width:100%;;height:1px;background-color:#000;margin:0}
	.bk td span .date {font-size:12px;color: color(normal, 030);margin-left:5px}
	.bk .info_up {display:block;}
	.bk td .is {margin-right:5px;font-weight:bold}

	/*계약정보입력*/ 
	.bk .p_coni {max-height:700px;min-height:200px;width:460px}
	.bk .p_coni .content {margin-top:10px;}
	.bk .p_coni .content .inp + .inp {margin-top:10px}
	.bk .p_coni .content .inp + form {margin-top:10px}
	.bk .inphoriz .inp_txt  {flex:1;margin-right:10px}
	.bk .inphoriz .inp_txt:last-child {margin-right:0}
	.bk .inphoriz + .inphoriz, .bk .inphoriz + .inp_txt {margin-top:10px}
	.bk .p_coni .cal_bor {/*margin-bottom:10px;*/padding:0 0 0 10px;height:43px;}
	.bk .p_coni .cal_bor .inp_txt {text-align:left;height:43px;box-sizing:border-box}
	.bk .pop .btn_area {text-align:center}
	.bk .pop .btn_text {display:inline-block;padding:10px 15px;text-align:center;font-size:13px}
	.bk .inp_txt.cal:after {display:none}
	.bk .p_coni .pop_cont {padding-bottom:30px;max-height:600px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);text-align:left}
	.bk .p_coni .p_footer {height:50px}
	.bk .p_coni .radio_box02>.rd01>input[type=radio] + label {width:113px;height:40px;line-height:38px;text-align:center}
	.bk .p_coni .btn-addprice {width:100%;padding:10px 0}
	.bk .p_coni .add_info {margin-top:5px;}
	.bk .p_coni h3 {display:block;font-size:13px;font-weight:400;margin-bottom:5px;margin-top:10px;}
	.bk .p_coni .addlbl {display:flex;align-items:center;margin-bottom:10px}
	.bk .p_coni .addlbl label {flex:1;flex-basis:160px}
	.bk .p_coni .addlbl + .addtxt {text-align:right;color:#999;font-size:13px} 
	.bk .inp_txt.per:after {content:'%'}
	.per label {color:#999}


	/*고객관리*/
	.bk .mngcus .sect_box01 {background:none;padding-left:0;padding-right:0;min-height:550px;max-width:100%;display:flex;flex:1}
	.bk .mngcus .cont_left {display:inline-block;width:30%;background:#fff;padding:20px;min-height:550px;max-height:800px;overflow-y:auto;position:relative}
	.bk .mngcus .cont_left .inpwrap {margin-bottom:10px}
	.bk .mngcus .cont_left .btn_line {padding:8px;width:60px;margin-left:-1px;}
	.bk .schsult_area {display:block;border:1px solid #ccc;padding:10px 0;max-height:500px;overflow-y:scroll}
	.bk .schsult_area li {display:block;text-align:left;font-size:12px;padding:5px 10px;position:relative}
	.bk .schsult_area li:hover, .bk .schsult_area li.on {background:#f4f4f4;cursor:pointer}
	.bk .schsult_area li .modi {position:absolute;right:10px;top:5px;font-size:12px;color:#B8B8B8}
	.bk .schsult_area .func_area {position:absolute;right:10px}
	.bk .mngcus .cont_right {display:inline-block;margin-left:20px;float:left;width:70%;background:#fff;padding:20px;min-height:550px;max-height:800px;overflow-y:auto;text-align:left;}
	.bk .mngcus .add_newuser {position:absolute;top:-30px;right:0;}
	.bk .mngcus .add_newuser button {width:120px;font-weight:300}
	.bk .mngcus .cont_right .selec_user {font-size:14px;text-align:left;margin-bottom:10px;display:inline-block;border-radius:30px;background:#5b413b;color:#fff;padding:5px 15px}
	.bk .mngcus .cont_right .inpwrap {position:relative;margin-bottom:10px}
	.bk .mngcus .cont_right .inpwrap .txtarea {height:50px;padding:10px 15px}
	.bk .mngcus .cont_right .inpwrap .btn_line {height:50px;margin-left:-1px;width:50px;box-sizing:border-box;padding:8px 10px}
	.bk .commt_lst .cont_commt {font-size:14px;}
	.bk .commt_lst .date {font-size:12px;color: color(normal, 030);display:block;margin-top:5px;}
	.bk .mngcus .cont_right .commt_lst .inpwrap {margin-bottom:0}
	.bk .cus_box {width:100%;padding:10px;border:1px solid #eaeaea;margin-bottom:10px}
	.bk .cus_box .commt_lst {display:block;font-size:12px;text-align:left;margin-bottom:0}
	.bk .cus_box .date {display:block}

	/*허위매물신고*/
	.bk .p_fr {width:500px}
	.bk .p_fr h1 {text-align:left}
	.bk .p_fr .pop_cont {height:250px;overflow-y:auto}
	.bk .p_fr .radio_box {display:block}
	.bk .p_fr .radio_box .radio {display:block;padding:0;margin-bottom:10px;height:22px}
	.bk .p_fr .radio_box strong {font-size:15px}

	/*매물상세보기*/
	.bk .p_detail {width:800px;min-width:768px;background:#fff;height:100vh;position:fixed;right:0;top:0;left:inherit;bottom:0;transform:none;overflow-y:scroll;max-height:100vh}
	.bk .p_detail .pop_header {height:70px;position:fixed;z-index:100;background:#fff;width:800px}
	.bk .p_detail .pop_cont {padding-top:70px}
	.bk .p_detail .btn-horiz {position:absolute;right:45px;top:18px;padding-bottom:0;margin-top:0}
	.bk .p_detail .btn-horiz .btn_line {margin-right:10px}
	.bk .p_detail .close {left:20px}
	.bk .p_detail .pop_contarea {width:100%;padding:0 20px 30px;text-align:center;}
	.bk .p_detail .tbltype01 {width:100%;border-top:1px solid #1e1e1e;border-bottom:1px solid #1e1e1e;position:relative;font-size:12px}
	/*.bk .p_detail .g_seller, .bk .p_detail .g_buyer {overflow:hidden}*/
	.bk .p_detail .g_seller span, .bk .p_detail .g_buyer span {display:inline-block;vertical-align:middle;margin-right:10px}
	.bk .p_detail .g_buyer .g_item {padding:2px 0}
	.bk .p_detail .g_seller .g_item .s_phone {margin-right:0}
	.bk .p_detail td .btn_line {padding:1px 10px;font-size:11px;color:#a1a1a1;}
	.bk .p_detail tbody td {padding:10px 20px 10px 20px;font-size:13px}
	.bk .p_detail tbody td .btn_space_box {vertical-align:middle}
	.bk .p_detail .lst_attached {overflow:hidden;margin-top:-5px;margin-left:0}
	.bk .p_detail .lst_attached .add_pic {width:120px;height:120px;}
	.bk .p_detail .btn_file_wrap {position:relative;display:inline-block;float:left;width:122px;height:122px;margin:5px 10px 5px 0;border:1px solid #b9b9b9;box-sizing:border-box}
	.bk .p_detail .btn_file_wrap .btn_file_pic {position:absolute;top:0;right:0;bottom:0;left:0;width:120px;height: 120px;opacity:0;filter:alpha(opacity=0);-ms-filter:alpha(opacity=0);cursor:pointer;z-index:1}
	.bk .p_detail .btn_file_wrap img {width:100%;height:100%;object-fit:cover;object-position:50%;}
	.bk .p_detail .no_img {display:inline-block;width:120px;height:120px;background:url(../images/img_bk/img_file_photo.png) no-repeat}
	.bk .p_detail .tbltype01 .tbltype02 th {text-align:center}
	.bk .p_detail .tbltype01 .tbltype02 td {padding:10px;text-align:left}
	.bk .p_detail .tbltype01 .tbltype02 td:first-child {text-align:center}
	.bk .btninp {display:flex;margin-top:10px}
	.bk .tbltype01 td .btninp .inp {width:calc(100% - 40px);border-right:none}
	.bk .tbltype01 td .btninp.buyer .inp:first-child {width:25%}
	.bk .tbltype01 td .btninp.buyer .inp {width:calc(100% - 25% - 40px);margin-top:0}
	.bk .tbltype01 td .btninp.buyer .btn_line {width:40px}
	.bk .tbltype01 td .tbltype02 tbody td .btninp {margin-top:0}
	.bk .panel-group {position:fixed;top:0;right:800px;z-index:10}
	.bk .panel-box {padding:0;text-align:left}
	.bk .panel-group .btn_space_box {position:absolute;right:15px;top:30px}
	.bk .panel-group .itmbox_inner .value .selec {width:80px}
	.bk .p_detail .btn-xsmall {height:unset;width:unset;padding:2px 4px;font-size:11px;margin-left:0;}

	/*문의관리*/
	.bk .search_area .inpwrap .selec {width:20%}
	.bk .search_area .inpwrap .btninp {width:60%}
	.bk .p_qna {width:650px;}
	.bk .p_qna .pop_header {border-bottom:1px solid #ccc}
	.bk .p_qna .pop_cont {height:550px;padding-bottom:30px;overflow-y:auto}
	.bk .p_qna .itm_info {text-align:center;}
	.bk .p_qna .itm_addr {display:inline-block;font-size:18px;margin-bottom:5px}
	.bk .p_qna .s_info .g_item span {font-size:14px;}
	.bk .p_qna .s_info .g_item span:after, .bk .p_qna .itm_addr .addr:after {margin:0 8px;content:"/";font-size:12px;}
	.bk .p_qna .s_info .g_item span:last-child:after {content:none;margin:0}
	.bk .p_qna .qna_lst .q_wrap:first-child {border-top:none}
	.bk .p_qna .qna_lst li {border-bottom:1px solid #ccc;padding:15px 0;text-align:left}
	.bk .p_qna .qna_lst li:last-child {border-bottom:none}
	.bk .p_qna .qna_lst li span {display:block;margin-bottom:5px;}
	.bk .p_qna .qna_lst li .q_info {font-size:14px;font-weight:500}
	.bk .tag_type {display:inline-block;padding:3px 8px;background:#6e7590;border-radius:50px;color:#fff;font-size:12px;font-weight:100;margin-left:5px;}
	.bk .p_qna .qna_lst li .q_cont, .bk .p_qna .qna_lst li .a_cont {font-size:13px;line-height:1.6}
	.bk .p_qna .qna_lst li .date {color: color(normal, 030);font-size:12px;}
	.bk .p_qna .commt {margin-top:15px;position:relative}
	.bk .p_qna .commt .inp {height:40px;padding-right:60px}
	.bk .p_qna .ans_wrap {padding:10px 15px;background:#f7f7f7;text-align:left;margin-bottom:8px;position:relative}
	.bk .p_qna  .ans_wrap.q_commt {background:#eaeaea}
	.bk .p_qna .ans_wrap:last-child {margin-bottom:0}
	.bk .p_qna .ans_wrap .commt {margin-top:0}
	.bk .p_qna .ans_wrap .func_area {position:absolute;right:15px;bottom:10px}
	.bk .t_btn button {display:inline-block;font-size:11px;color:#666}
	.bk .t_btn button.modi:after {display:inline-block;margin:0 5px;content:"|";font-size:10px;color:#9C9C9C}
	.bk .qna_lst .commt button, .bk .commt .commt button {position:absolute;top:0;right:0;width:50px;font-size:13px;padding:0 10px;height:50px}
	.bk .qnatbl tr:hover {background:#f9f9f9;cursor:pointer}
	.bk .qna_lst .commt .txtarea {height:50px;padding:6px 60px 6px 10px}
	.bk .sectask .summary + .sect_box02 .tblcontainer tr {cursor: default}

	/*평가관리*/
	.bk .summ_box {margin-top:5px;margin-bottom:15px;overflow:hidden;display:flex;flex-direction:row;flex-wrap:wrap}
	.bk .summ_box li {display:inline-block;float:left;margin:5px;height:100px;background:#fff;flex:1;flex-grow:1;padding:10px 10px 10px;box-sizing:border-box}
	.bk .summ_box li:first-child {width:300px;flex:auto;flex-grow:0;margin-left:0}
	.bk .summ_box li:last-child {margin-right:0} 
	.bk .summ_box li .cd_tit {font-size:14px;font-weight:400;text-align:center;margin-top:5px;}
	.bk .summ_box li .score {font-size:20px;text-align:center;font-weight:bold;margin-top:12px}
	.bk .avr_area {margin-top:10px;padding:0;text-align:center;}
	.bk .avr_area .star_score {margin-bottom:0;display:inline-block;overflow:hidden}
	.bk .avr_area .star_score span {display:inline-block;text-align:left;}
	.bk .avr_area .star_score span.st_off {background: url(../images/img_bk/point0_2.png) no-repeat;width:180px;height:30px;}
	.bk .avr_area .star_score span.st_on {background:url(../images/img_bk/point5_2.png) no-repeat;width:180px;height:30px}
	.bk .t_score {display:inline-block;margin-left:15px;font-size:25px;vertical-align:6px}
	.bk .star_score {margin-bottom:2px}
	.bk .star_score span.st_off {display:inline-block;background: url(../images/img_bk/sub/point0.png) no-repeat;vertical-align:middle;position:relative;width:90px;height:15px;}
	.bk .star_score span.st_on {position:absolute;z-index:10;height:19px;overflow:hidden;top:0;left:0;text-indent:-9999px;display:inline-block;background: url(../images/img_bk/sub/point5.png) no-repeat;}
	.bk .summ_box span:before {display:none}
	.bk .viewitm_area {position:absolute;right:0;top:0}
	.bk .viewitm_area button {font-size:12px;letter-spacing:-1px;}
	.bk .viewitm {position:absolute;top:20px;right:0;display:inline-block;padding:10px;border:1px solid #1e1e1e;background:#fff;width:300px;z-index:1000;text-align:left}
	.bk .rptip[data-tooltip-text]:hover:after, .bk .rptip[data-tooltip-text].on:after {width:330px;left:0;/*white-space: pre;*/}

	/*매물정보관리*/
	.bk .btn_file_wrap .del {display:inline-block;background:url(../images/img_bk/btn_del02.png) no-repeat;width:22px;height:22px;text-indent:-9999px;position:absolute;top:7px;right:7px}
	.bk .itmpic table tr td {text-align:center;padding:5px 10px}
	.bk .itmpic table tr td:last-child {text-align:left}
	.bk .itmpic table tr td .btn_file_wrap {width:138px;height:120px;vertical-align:middle;float:left;margin:5px;}
	.bk .itmpic table tr td .btn_file_wrap .btn_file_pic, .bk .itmpic table tr td .btn_file_wrap label {width:138px;height:120px}
	.bk .itmpic table tr td .btn_file_wrap label {line-height:120px}
	.bk .btn_file_wrap .add_pic {display:block;}
	.bk .btn_file_wrap {position:relative;overflow:hidden;display:inline-block;}
	.bk .btn_file_wrap .up_btn {color:gray;background-color:white;padding:50px 20px;border-radius:8px;font-size:12px;font-weight:bold;}
	.bk .btn_file_wrap input[type=file] {font-size:100px;position:absolute;left:0;top:0;opacity:0;height:100%}

	/*공지사항*/
	.bk .board .search_area {margin-bottom:15px;margin-top:15px}
	.bk .board .search_area .inpwrap {margin-top:0}
	.bk .board .search_area .inpwrap .inp {height:44px}
	.bk .board .btn_back {font-size:11px;padding-left:15px;position:absolute;top:12px;left:12px;background:url(../images/img_bk/btn_back02.png) no-repeat 0 center}
	.bk .board tbody tr td:nth-child(2) {font-size:14px;text-align:left}
	.bk .board .tbltype02 tr td {padding:5px;font-weight:400}
	.bk .board .tbltype02 thead tr {background:#f9f9f9}
	.bk .board .tbltype02 {border-top:1px solid #b5b5b5;border-left:none;}
	.bk .board .tbltype02 tr td:last-child {border-right:none}
	.bk .board .dtail {width:100%;border-top:1px solid #b5b5b5;border-bottom:1px solid #b5b5b5;margin-top:10px}
	.bk .board .dtail .subj {text-align:left;font-size:16px;padding:15px 10px;position:relative;border-bottom:1px solid #eaeaea;padding-right:150px}
	.bk .board .dtail .subj .date {position:absolute;top:17px;right:10px;font-size:12px;}
	.bk .board .dtail .cont {padding:20px 10px;font-size:15px;line-height:1.6}
	.bk .board .dtail .article {padding-bottom:30px;margin-bottom:30px;text-align:left}
	/* 공지사항 이미지 영역 넘침 수정 */
	.bk .board .dtail .article p {width:100%;height:100%;max-width:800px;}
	.bk .board .dtail .article p img {width:100%;object-fit:contain;}
	.bk .board .addf_area {text-align:left;margin-bottom:-10px}
	.bk .board .addf_area span {display:block;margin-bottom:10px;font-weight:bold}
	.bk .board .addf_lst {display:block;width:500px;font-size:14px;box-sizing:border-box;padding:3px 0 3px 15px;text-align:left;background:url(../images/img_bk/ico_file02.png) no-repeat 0 9px;}
	.bk .board .addf_lst:hover {text-decoration:underline}
	.bk .board .prev {padding:10px;text-align:left;border-bottom:1px solid #eaeaea}
	.bk .board .next {padding:10px;text-align:left;border-bottom:1px solid #b5b5b5}
	.bk .board .prev span {display:inline-block;padding-left:30px;margin-right:20px;background:url(../images/img_bk/ico_prev.png) no-repeat 0 5px}
	.bk .board .prev a, .bk .board .next a {display:inline-block;font-size:13px}
	.bk .board .next span {display:inline-block;padding-left:30px;margin-right:20px;background:url(../images/img_bk/ico_next.png) no-repeat 0 5px}
	.bk .board .btn-horiz .btn_line {width:150px;}
	/* 221024 공지사항 new icon, 읽음/안읽음 구분 */ 
	.bk .board .tbltype02 tr.unread td {font-weight:bold}
	.bk .board .tbltype02 tr.new td a:before {display:inline-flex;content:'N';min-width:15px;height:15px;background-color:#f64e23;-webkit-border-radius:15px;border-radius:15px;font-size:8px;line-height:15px;color:#fff;font-weight:500;letter-spacing:0;align-items:center;margin-right:4px;text-align:center;justify-content:center;}

	/*자료실*/
	.bk .board .file {display:inline-flex;align-items:center;background:url(../images/img_bk/ico_file.png) no-repeat;width:9px;height:16px;background-size:contain;text-indent:-9999px}
	.bk .board .s_tab01 {display:block;width:100%;margin-bottom:10px}
	.bk .board .s_tab01 ul:after {display:block;content:'';clear:both;}
	.bk .board .s_tab01 ul li {display:inline-block;float:left}
	.bk .board .s_tab01 ul li:after {display:inline-block;margin:0 7px;content:' |';font-size:5px;color:#ccc;}
	.bk .board .s_tab01 ul li:last-child:after {display:none}
	.bk .board .s_tab01 ul li a {display:inline-block;vertical-align:middle;font-size:13px;}
	.bk .board .s_tab01 ul li a:hover, .bk .board .s_tab01 ul li a.on {font-weight:bold}
	.bk .board .go_site {display:inline-block;padding:3px 20px 3px 10px;border:1px solid #1e1e1e;background:url(../images/img_bk/btn_arr_rights.png) no-repeat 90% center;font-size:12px;}

	/*건의사항*/
	.bk .search_area.sch_date {overflow:hidden;margin-bottom:10px}
	.bk .sf_right{display:inline;float:left;margin-bottom:10px;margin-right:10px}
	.bk .sf_right .btn_line {height:40px}
	.bk .sf_right {margin-right:10px}
	.bk .sf_month {display:inline;float:left}
	.bk .sf_month li {display:inline;float:left;position:relative;line-height: 24px }
	.bk .sf_month li label {display:inline-block;min-width:56px;margin:0 -1px 0 0;padding-top:1px;border:1px solid #dfdfdf;background:#fcfcfc;font-size:11px;line-height:32px;color:#666;text-align:center;vertical-align:top;cursor:pointer}
	.bk .sf_month li.check {z-index:10}
	.bk .sf_month li.check label {border-color:#5d5d5d;background-color:#747474;color:#fff}
	.bk .sf_month li .inp_radio {position:absolute;left:0;top:0;z-index:-1;width:13px;height:13px;vertical-align: top}
	.bk .cal_fl {display:inline;float:left;width:160px}
	.bk .cal_hipen {display:inline;float:left;width:17px;text-align:center;padding-top:14px;}
	.bk .day_link {display:inline-block;overflow:hidden;vertical-align:middle;zoom: 1;}
	.bk .day_link li {float:left;}
	.bk .day_link li a {display:block;border:1px solid #ccc;border-width:1px 1px 1px 0;background-color:#fff;font-size:12px;line-height:38px;color:#666;height:40px;width:46px;box-sizing:border-box}
	.bk .day_link li:first-child a {border-left-width:1px;}
	.bk .day_link li a .blind {position:relative;width:auto;height:auto;margin:0;}
	.bk .blind {overflow:hidden;position:absolute;width:1px;height:1px;margin:-1px;padding:0;border:0;clip:rect(0,0,0,0);}
	.bk .day_link li.on a {z-index:10;margin-left:-1px;border:1px solid #473332;background:#473332;color:#fff;font-weight:100}

	.bk .p_wri {min-height:300px;}
	.bk .p_wri .inp_area {
		display:flex;
		flex-direction:column;
		row-gap:10px;
	}
	.bk .p_wri .inp_area .selec {margin-top:0;font-size:14px;}
	.bk .p_wri .inp_area .selec + .inp {margin-top:0}
	.bk .p_wri .inp_area .inp + .txtarea {margin-top:0;height:200px}
	.bk .p_wri .inp_area + .inpwrap {margin-top:10px}
	.bk .p_wri .selec {height:46px}
	.bk .ans {display:inline-block;padding:3px 0;width:60px;border-radius:50px;color:#fff;font-size:11px;text-align:center;vertical-align:middle}
	.bk .ans.compl {background:#b62217;}
	.bk .ans.noncompl {background:#e1e1e1}
	.bk .p_wri input[type="file"] {position:absolute;left:0;top:0;}
	.bk .p_wri label {width:100%;height:40px;display:block;border:1px solid rgb(208, 210, 214);background:#f9f9f9;line-height:40px;padding-left:10px;font-size:13px;color:#B7B7B7}
	.bk .p_wri .btn_line {position:absolute;right:0;top:-1px;height:44px}
	.bk .board .commt {margin-top:15px;padding-bottom:15px;border-bottom: 1px solid #b5b5b5;}
	.bk .board .commt .ans_wrap {padding:15px;background:#f9f9f9;margin-bottom:10px;position:unset;width:100%;height:auto;border-left: none; }
	.bk .board .commt .writer {display:block;text-align:left;color:#3353B0;margin-bottom:5px;font-size:14px}
	.bk .board .commt .a_cont {display:block;text-align:left;margin-bottom:5px;font-size:15px}
	.bk .board .commt .date {display:block;text-align:left;font-size:13px}
	.bk .board .func_area {display:inline-block}
	.bk .board .commt {position:relative}
	.bk .board .inpwrap .btn_type04 {width:50px;font-size:13px;padding:0 10px;height:44px}
	.bk .p_wri .btn-horiz {padding-bottom:0}
	.bk .p_wri .info {font-size:14px;}
	.bk .p_wri .info span {display:inline-block;float:inherit}
	.bk .p_wri .info span:after {display:inline-block;content:'';width:1px;height:10px;background:#aeaeae;margin:0 7px;}
	.bk .p_wri .info span:last-child:after {display:none}

	/*중개수수료요율표*/
	.bk .pop_bkpee {width:500px;height:600px}
	.bk .pop_bkpee .box {margin-bottom:20px}
	.bk .pop_bkpee h3.exp {font-size:13px;font-weight:300;text-align:left;margin-bottom:13px}
	.bk .pop_bkpee .box p {font-size:13px;text-align:left;margin-bottom:10px;}
	.bk .pop_bkpee table thead + tbody + thead tr:first-child {border-top:1px solid #1e1e1e}
	/*term*/
	.bk .pop_term {width:700px;height:600px;line-height:1.5}
	.bk .pop_term .tit, .bk .pop_bkpee .tit {font-size:24px;color:#1e1e1e;text-align:center;font-weight:400;}
	.bk .pop_term .pop_cont, .bk .pop_bkpee .pop_cont {padding:20px 40px 20px;position:relative;width:100%;height:calc(100% - 120px);overflow-y:auto;text-align:left;font-size:13px;}
	.bk .pop_term .pcont_inner {display:inline-block;width:100%;box-sizing:border-box;position:relative;height:100%}
	.bk .term_tbl .tbl_type01, .bk .pop_bkpee .tbl_type01 {width:100%;font-size:12px;table-layout:fixed;vertical-align:middle;border:1px solid #e5e5e5;text-align:center}
	.bk .term_tbl .tbl_type01 tr, .bk .pop_bkpee .tbl_type01 tr {height:30px;border-top:1px solid #e5e5e5}
	.bk .term_tbl .tbl_type01 th, .bk .pop_bkpee .tbl_type01 th {background:#fafafa;font-weight:200;color:#000}
	.bk .term_tbl .tbl_type01 th, .bk .tbl_type01 td {padding:5px 5px 5px 7px;line-height:1.4}
	.bk .term_tbl {padding:10px 0;}
	.bk .term_tbl table {font-size:13px;}
	.bk .term_tbl .tbl_type01 td {line-height:1.5}

	/* 공동중개 */
	.bk .bkboard .tbltype02 tbody td:first-child {font-size:12px;text-align:center}
	.bk .bkboard .tbltype02 tbody td:nth-child(2) {font-size:14px;text-align:left}
	.bk .bkboard .tbltype02 tbody td .file {margin:0 2px;vertical-align:middle}
	.bk .bkboard .tbltype02 tbody td .re {color:#FF2125;font-size:13px;margin:0 2px}
	.bk .board .dtail .s_subj {text-align:left;font-size:14px;padding:15px 10px;position:relative;border-bottom:1px solid #eaeaea;padding-right:150px}
	.bk .board .dtail .s_subj span:after {display:inline-block;content:'';width:1px;height:10px;background:#aeaeae;margin:0 7px;}
	.bk .board .dtail .s_subj span:last-child:after {display:none}
	.bk .board .commt .info {text-align:left;display:block;}
	.bk .board .commt .info span {display:inline-block;float:inherit;color:#b2b2b2;}
	.bk .board .commt .info span:after {display:inline-block;content:'';width:1px;height:10px;background:#aeaeae;margin:0 7px;}
	.bk .board .commt .info span:last-child:after {display:none}

	/* 상담관리 */
	.bk .sect_box01.feed {padding:0;background:none}
	.bk .feed .search_area {margin:20px 0}
	.bk .feed .card {margin-bottom:10px;padding:15px;background:#fff;text-align:left}
	.bk .feed .card .tag_client {padding:3px 10px;border-radius:30px;color:#fff;display:inline-block;font-size:12px;margin-bottom:5px}
	.bk .feed .card .tag_client.sell {background:#5b413b;}
	.bk .feed .card .tag_client.buy {background:#ffa800;}
	.bk .feed .card .itminfo {display:block;text-align:left;}
	.bk .feed .card .itminfo span {display:inline-block;font-size:15px;}
	.bk .feed .card .itminfo span:after {display:inline-block;content:'';width:1px;height:10px;background:#aeaeae;margin:0 7px;}
	.bk .feed .card .itminfo span:last-child:after {display:none}
	.bk .feed .card .itminfo a {font-weight:600}
	.bk .feed .card .itminfo a:hover {text-decoration:underline}
	.bk .feed .card .buyerinfo {display:block;text-align:left}
	.bk .feed .card .cont {display:block;text-align:left;padding:5px 0}
	.bk .feed .card .date {display:block;text-align:left;color: color(normal, 030);}
	.bk .feed .more_view {display:block;width:100%;padding:10px;margin-top:10px;text-align:center;box-sizing:border-box}
	.bk .feed .search_area {display:flex}
	.bk .feed .search_area .inpwrap {width:80%;margin-right:20px}
	.bk .feed .search_area .btn-horiz {width:20%;margin:0;padding:0}
	.bk .feed .search_area .btn-horiz .btn-primary {width:100%;height:44px;}
	.bk .p_wri .radio_box02>.rd01>input[type=radio] + label {width:239px;height:44px;line-height:43px;font-size:14px}
	.bk .btn-horiz .btn-second {width:150px;margin-right:10px}

	/* 1:1문의 */
	// .bk ul.myfaq li {margin-bottom:15px}
	.bk ul.myfaq li .qitem, .bk ul.myfaq li .aitem {padding-left:140px;position:relative;background-color:#fff;border-top:none}
	.bk ul.myfaq li .aitem {background:#eaeaea}
	.bk ul.myfaq li .qitem .qbox, .bk ul.myfaq li .aitem .abox {text-align:left}
	.bk .flag_qa {left:15px}

	/* 평형전환 */
	.bk .btn_space_box {display:inline-block;margin-left:5px;z-index:1;position:static;margin-top:2px;}
	.bk .btn_space {font-size:11px;line-height:15px;letter-spacing:-.5px;position:relative;top:-1px;width:18px;height:18px;margin-left:-1px;border:1px solid #d9d9d9;background-color:rgba(0,0,0,0.02);text-align:center;vertical-align:middle;color:#777;}
	.bk .btn_space.on {border-color:#999;background-color:#fff;color:#2b2c2e;z-index:1}

	/*검색결과*/
	.bk .itmsch_result{width:70%;border-width:1px;border-style:solid;border-color:rgb(208, 210, 214);max-height:350px;top:43px;padding:10px;box-sizing:border-box;background:#fff;position:absolute;left:0;overflow-y:auto;z-index:1200;overscroll-behavior:contain}
	.bk .itmsch_result .sch_word {display:block;width:100%;padding:5px;position:relative;font-size:12px;box-sizing:border-box;text-align:left}
	.bk .itmsch_result .sch_word:hover {background:#f7f7f7}
	.bk .itmsch_result a .address {color:#888;position:absolute;right:5px;font-size:12px;}

	/*Tablet View*/
	/*@media (max-width: 1024px){
		.bk_card_wrap {display:inline-block;padding:40px 70px;text-align:center;}
		.bk_card_wrap .bk_card li {width:calc(50% - 10px)}	
		
		/**/
		/*.bk .sect_box01 {padding:30px;width:100%}
		.bk .tbltype01 {width:100%}
		
		.bk .p_contr, .bk .p_detail {width:90%}
	}*/

	.bk .term .pop .header {height:70px;border-bottom:none}
	.bk .term .container {padding-top:90px}

	.bk .row .lbl {width:10%;font-size:13px;text-align:left}
	.bk .s_row {width:100%;text-align:left;margin-bottom:8px;display:table;}
	.bk .s_row .s_lbl {display:table-cell;width:15%;text-align:left;vertical-align:middle;font-size:13px;}
	.bk .s_row:last-child {margin-bottom:0}
	.bk .s_row .formbox {display:table-cell;vertical-align:middle}
	.bk .row .radio-box02 > .rd01 > input[type=radio] + label {height:36px;line-height:35px;font-size:13px;}
	.bk .row .txtarea {height:80px;}
	.bk .row .check-box03 + .txtarea {margin-top:8px;}
	.bk .box_danjiinfo .btn-horiz .btn_line {height:40px;font-size:14px;}
	.bk .box_danjiinfo .btn-horiz .btn_line:first-child {margin-right:10px;}
	.bk .tbl-cell .formbox {height:30px;line-height:30px;margin-top:-2px}
	.bk .box_danjiinfo .inp {height:40px;border-radius:0}
	.bk .box_danjiinfo .tit_exp {margin-bottom:8px;}

	.bk .tbl {border-top:1px solid rgb(208, 210, 214);border-left:1px solid rgb(208, 210, 214);}
	.bk .tbl .tbl-row {display:flex;flex-direction:row;flex-wrap:wrap;border-bottom:1px solid rgb(208, 210, 214);height:30px;line-height:30px;font-size:13px;}
	.bk .tbl .tbl-row:first-child {background:#f9f9f9}
	.bk .tbl .tbl-row .tbl-cell {flex:1;border-right:1px solid rgb(208, 210, 214);height:30px;}
	.bk .tbl form {display:block;height:30px;margin-top:-2px;}
	.bk .tbl .star_input > .input,
	.bk .tbl .star_input > .input > label:hover,
	.bk .tbl .star_input > .input > input:focus + label,
	.bk .tbl .star_input > .input > input:checked + label 
	{display:inline-block;vertical-align:middle;text-align:left;background:url(../images/sub/inp_star01.png) no-repeat;}
	.bk .tbl .star_input {display:inline-block;white-space:nowrap; width:100px;height:20px;line-height:20px;}
	.bk .tbl .star_input > .input {display:inline-block;width:100px; background-size:100px;height:16px;white-space:nowrap;overflow:hidden;position:relative;}
	.bk .tbl .star_input > .input > label {padding:16px 0 0 0}
	.bk .tbl .star_input > .input > label:hover,
	.bk .tbl .star_input > .input > input:focus + label,
	.bk .tbl .star_input > .input > input:checked + label 
	{background-size:100px;background-position:0 bottom;}
	.bk .tbl .star_input > .input > label:hover ~ label {background-image:none;}
	.bk .tbl .star_input > .input > .lbl01 {width:20px;z-index:5;}
	.bk .tbl .star_input > .input > .lbl02 {width:40px;z-index:4;}
	.bk .tbl .star_input > .input > .lbl03 {width:60px;z-index:3;}
	.bk .tbl .star_input > .input > .lbl04 {width:80px;z-index:2;}
	.bk .tbl .star_input > .input > .lbl05 {width:100px;z-index:1;}
	.bk .tbl .star_input > output {display:inline-block;width:40px;font-size:15px;text-align:right;vertical-align:middle;}
	.bk .tbl .radio-box {display:inline-block;margin-top:5px;}
	.bk .tbl .radio-box .radio {padding-right:10px}
	.bk .tbl .radio-box .radio:last-child {padding-right:0}
	.bk .tbl .radio-box i {margin-right:5px;}

	/* 매물정보수정 */
	.bk .modiinfo form label {margin-bottom:5px;display:block;text-align:left}
	.bk .modiinfo .itm_info {padding:10px 0 15px;text-align:center}
	.bk .modiinfo .itm_info .itm_name {font-size:16px;}
	.bk .modiinfo .itm_info .itm_infodetail {margin-left:5px;font-size:15px;}
	.bk .modiinfo .btn-horiz .btn_line {margin-right:10px;width:100px;}
	.bk .modiinfo .addlbl label {height:46px;text-align:left;}

	/* 중개사매물등록 */
	.bk .bk-regi {min-height:450px;margin-top:15px;text-align:left;background:#fff;padding:10px 20px}
	.bk .item-card .item_info {text-align:left;margin-bottom:6px;}
	.bk .item-card .item_info .d_info01 {margin-bottom:0}
	.bk .item-card-container + .btn-box {margin-top:20px;}
	.bk .item_flag {height: calc(100% - 55px);}
	.bk .bk-regi .btn {font-size:13px;;height:35px;}
	.bk .bk-regi .mysell_btn {padding:10px 0}
	.bk .bk-regi .item_thumb {z-index:1;top:13px;right:13px}
	.bk .bk-regi .process {padding:10px 0 30px}
	.bk .bk-regi .tab_cont {background:#fff;padding:0 20px}
	.bk .bk-regi h2.subj_tit {font-size:21px}
	.bk .bk-regi .process li a {font-size:14px;}
	.bk .tab-box h3 {margin-bottom:10px;}
	.bk .bk-regi .inp {height:40px;font-size:13px;line-height:39px}
	.bk .bk-regi .inquery .kr {line-height:40px}
	.bk .bk-regi .selec.selec_inp {background:none}
	.bk .bk-regi .selec_inp .selec_btn {top:9px;}
	.bk .bk-regi .unit {top:8px;z-index:0}
	.bk .bk-regi .inp-horiz.fix inp-textbox inp {padding-left:20px}
	.bk .bk-regi .check-box03 > .chk01 > input[type=checkbox] + label {font-size:13px;}
	.bk .itm_opt .check-box03 > .chk01 > input[type=checkbox] + label {height:40px;line-height:39px;font-size:13px;}
	.bk .finish_text .ntc_p {font-size:16px;}
	.bk .finish_text .ntc_p big {font-size:26px;}
	.bk .finish_text {padding:60px 0 50px}

	.bk option[data-finished="true"] {color:rgb(255, 255, 255, 0.2)}

	/*중개지역설정*/
	.bk .selec_wrap {border:1px solid #b9b9b9;width:100%;height:100%;text-align:center;padding:30px 41px;box-sizing:border-box;background:#f7f7f7;margin:0;margin-top:20px;}
	.bk .selec_wrap .btn-basic {height:auto;width:auto;font-size:12px;padding:3px 10px 2px;margin-left:7px;display:inline-block;vertical-align:middle;margin-top:-7px;}
	.bk .setarea .tit_exp {font-size:13px;}
	.bk .setarea .box_selec {margin-top:25px;}
	.bk .setarea .filter_area form {margin-top:10px}
	.bk .setarea .fd-check .layout:first-child .chk01 input[type=checkbox] + label {width:120px;}
	.bk .setarea .fd-check .chk01 input[type=checkbox] + label {margin:3px;width:76px;font-size:12px;height:32px;line-height:31px;}
	.bk .setarea .btn-horiz {padding-bottom:0}
	.bk .academy-pin .visual-info {border:none;}
	.bk .setarea .fd-check .chk01 input[type=checkbox].fix + label {background:#1b9e3d;color:#fff;border-color:#008322}
	.bk .setarea .fd-check {display:flex;flex-flow: column wrap;}
	.bk .setarea .btn-del {display:flex;width:22px;height:22px;align-items:center;border:1px solid #ccc;border-radius:22px;background:#fff url(../images/img_bk/btn_close04.png) no-repeat;background-size:contain;margin-top:5px;text-indent:-9999px}

	/*210405 현황판에 내평가점수추가*/
	.bk .bk_card_wrap .score_box {background:#fff;margin:10px 10px 10px 0;padding:20px;text-align:left;/*border-radius:10px;*/max-width:460px;/*min-width:50%;*/height:140px;border-radius:16px;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;flex-shrink:0;flex-basis:450px;}
	.bk .bk_card_wrap .avr_area {display:inline-block;vertical-align:middle;margin-top:0;margin-left:8px;}
	.bk .bk_card_wrap .score_box .my {font-size:15px;width:100%;height:auto;position:unset;border-left:none;text-align:center}

	/*210412 네이버매물계약등록 추가*/
	.pop_contarea .add_t {margin-top:0}
	.bk .p_coni .selec_inp {background:none;padding-right:5px;}
	.bk .pop.p_coni .selec_inp .inp {height:39px;margin-right:0}
	.bk .p_coni .unit {top:9px;}
	.bk .p_coni .selec_btn {top:9px;right:0}
	.bk .contr_rd01 .rd01 {width:calc(100%/2);height:40px;}
	.bk .contr_rd01 .radio-box02 > .rd01 > input[type=radio] + label{width:100%;height:40px;line-height:38px}
	.bk .p_coni .fix + .inp {margin-top:10px;}
	.bk .p_coni .selec_inp .selec_option {top:38px;}

	.bk .pop.itemdetail {width:700px;}
	.bk .pop .header {border-bottom:none}
	.bk .pop .btn-area-bottom {padding-bottom:0}
	.bk .panel-group.onsaledetail {right:0}

	/* 210414 dailyreport 추가 */
	.bk .sec_dr .datepick {position:unset;text-align:left;margin-bottom:10px;}
	.bk .sec_dr .datepick:after {display:none}
	.bk .sec_dr .datepick .inp {width:200px;height:35px;font-size:12px;}
	.bk .sec_dr .inp_txt:after {display:inline-block;content:'건';position:absolute;top:5px;right:12px;}
	.bk .sec_dr .tbltype02 td {padding:10px;}
	.bk .sec_dr .tbltype02 tr .txtarea {height:30px;border:none;padding:5px;font-size:13px;box-sizing:border-box}

	.bk .btn-file-wrap label {font-size:15px;}
	.bk .add_pic .react_lightgallery_item {width:100%;height:100%;}

	/* 210421 현황판 공지사항 수정 */
	.box-notice {padding:15px 20px;background:#ffff;margin:10px 0 10px 10px;text-align:left;/*border-radius:10px;*/position:relative;max-width:530px;/*min-width:46%;*/flex-shrink:0;flex-basis:530px;height:140px;border-radius:16px;box-shadow: -6px 20px 20px rgb(0 0 0 / 6%), -6px 20px 20px rgb(0 0 0 / 6%);
	}
	.box-notice a {color:#222;display:flex;margin:5px 0}
	.box-notice .notice-title:hover {text-decoration:underline}
	.box-notice .notice-title {flex:1;font-weight:400;display:block;width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
	.box-notice .notice-date {font-weight:300;padding-left:15px;font-size:12px;line-height:1.5}
	.box-notice h5 {font-size:15px;color:#222;font-weight:300;padding-bottom:5px;border-bottom:1px solid rgb(255,255,255,0.2)}
	.box-notice .view_more {color:#222;position:absolute;right:20px;top:17px;margin:0;font-size:12px;font-weight:300;}

	.bk .p_coni.pre h1 {text-align:left;margin-bottom:10px;}
	.bk .p_coni.pre .inp_area p:first-child {font-size:16px;margin-bottom:20px;margin-top:10px;}
	.bk .p_coni.pre .radio_box {height:auto;margin-bottom:20px;}
	.bk .p_coni.pre .radio_box .radio {display:block;padding:0;padding-right:0;height:30px;}
	.bk .p_coni.pre .radio_box .radio label:before {display:block;content:'';clear:both;}
	.bk .p_coni.pre .radio_box .radio strong {font-size:15px;}
	.bk .p_coni .content .inp + form.form_agreesms {display:block;margin-bottom:10px;}

	/* 210429 공지사항 상단고정 */
	.bk .board .tbl_lst_type tbody tr.fix {background:#f5f5f5;}
	.bk .board .tbl_lst_type tbody tr.fix em {color:#e83848;display:inline-block;padding:1px 5px;border-radius:20px;border:1px solid #e83848;line-height:1.3;font-size:12px;background:#fff;}
	// .bk .board .tbl_lst_type tbody tr.fix td {font-weight:600}

	/* 210526 단지상세 위치수정 */
	.bk .panel-group.complex_info.danjidetail {position:fixed;top:0;right:800px;z-index:10}
	.bk .panel-group.danji_aptlst, .bk .panel-group.aptdetail  {right:380px;} 
	.bk .panel-group .btn-horiz {padding-bottom:0}
	.bk .panel-group .btn-horiz .btn {width:100%;}

	/* 210825 중개사매물등록 버튼크기 수정 */
	.bk .bk-regi .btn.btn-xsmall {height:auto;}

	/* 210927 동영상가이드클립 */
	.bk .video_box {text-align:left;margin:40px 10px 10px;width:100%;}
	.bk .video_box .title {font-size:15px} 
	.bk .video_box .article {margin-top:10px;width:100%;overflow-x:overlay;@include overflow-scroll-custom (dark, medium, x);height:auto;}
	.bk .video_box .lst_prvideo {display:flex;width:1500px;flex-flow:row nowrap;padding:0;column-gap:16px;}
	.bk .video_box .lst_in_li {display:inline-block;width:180px;height:120px;box-sizing:border-box;text-align:center;cursor:pointer;position:relative;flex-shrink: 0;flex-grow:1;margin:0;}
	.bk .video_box .lst_in_li:first-child {margin-left:0}
	.bk .video_box .lst_in_li:last-child {margin-right:0}
	.bk .video_box .lst_in_li a {display:block;}
	.bk .lst_prvideo .lst_in_li .thumb {width:100%;height:120px;}
	.bk .lst_prvideo .lst_in_li .thumb01 {background: url(../images/img_bk/thumb_guide01.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb01:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide01_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb02 {background: url(../images/img_bk/thumb_guide02.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb02:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide02_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb03 {background: url(../images/img_bk/thumb_guide03.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb03:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide03_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb04 {background: url(../images/img_bk/thumb_guide04.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb04:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide04_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb05 {background: url(../images/img_bk/thumb_guide05.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb05:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide05_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb06 {background: url(../images/img_bk/thumb_guide06.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb06:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide06_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb07 {background: url(../images/img_bk/thumb_guide07.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb07:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide07_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb08 {background: url(../images/img_bk/thumb_guide08.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb08:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide08_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb09 {background: url(../images/img_bk/thumb_guide09.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb09:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide09_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb10 {background: url(../images/img_bk/thumb_guide10.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb10:hover {width:100%;height:120px;background:url(../images/img_bk/thumb_guide10_hover.png) no-repeat 50%;background-size:cover;}
	.bk .lst_prvideo .lst_in_li .thumb:hover:after {content: "";position:absolute;top:50%;left:50%;width:26px;height:32px;transform: translate(-50%, -50%);background:url(../images/img_bk/ico_play.svg) no-repeat 0 0;background-size:contain;}
	.bk .lst_prvideo .lst_in_li .thumb:before {display:block;white-space:pre;width:100%;position:absolute;top:130px;left:50%;transform:translatex(-50%);font-size:13px;letter-spacing:-.9px;}
	.bk .lst_prvideo .lst_in_li .thumb04:before {content:'다윈중개 집 내놓기';}
	.bk .lst_prvideo .lst_in_li .thumb05:before {content:'다윈중개 집 구하기';}
	.bk .lst_prvideo .lst_in_li .thumb06:before {content:'다윈중개 네이버 매물 \A 공동중개 방법';}
	.bk .lst_prvideo .lst_in_li .thumb07:before {content:'다윈중개 별점 획득방법';}
	.bk .lst_prvideo .lst_in_li .thumb08:before {content:'중개지역 설정 방법';}
	.bk .lst_prvideo .lst_in_li .thumb09:before {content:'마이페이지 매물관리 1편';}
	.bk .lst_prvideo .lst_in_li .thumb10:before {content:'마이페이지 매물관리 2편';}
	.bk .lst_prvideo .lst_in_li .thumb01:before {content:'구해줘 다윈';}
	.bk .lst_prvideo .lst_in_li .thumb02:before {content:'다윈 채팅';}
	.bk .lst_prvideo .lst_in_li .thumb03:before {content:'다윈 커뮤니티';}

	/* 211014 동영상가이드 보러가기 버튼 추가 */
	.go_guide {display:inline-block;vertical-align:middle;font-weight:400;/*margin-top:-2px;margin-left:10px;*/padding:3px 10px;border-radius:100px;font-size:11px;background:#1a8ac8;color:#fff;}
	.go_guide:before {display:inline-block;content:'';background:url(../images/img_bk/ico_play.svg) no-repeat;background-size:contain;width:8px;height:8px;margin-right:3px;}

	/* 211028 중개지역설정 검색버튼 추가 */
	.ch_ag + .tit_exp {margin-top:10px;}

	/* 211129 중개사추천이벤트 */
	.bn_event_bkrecomm {position:fixed;right:0;top:320px;z-index:3;}
	.bn_event_bkrecomm a {display:block;background:url(../images/img_bk/bn_event.png) no-repeat;width:150px;height:170px;background-size:contain;text-indent:-9999px}
	.bn_event_acarecomm {position:fixed;right:0;top:490px;z-index:3;}
	.bn_event_acarecomm a {display:block;background:url(../images/img_bk/bn_event02.png) no-repeat;width:150px;height:170px;background-size:contain;text-indent:-9999px}

	/* 커뮤니티 */
	.bk .community {
		width:1100px;
		margin:0 auto;
		padding-bottom:100px;
		padding-top:0;
	}
	.bk .community-header {
		width:90%;
		display:flex;
		position:relative;
		z-index:1;
		justify-content: left;
		margin-left:6px;
		padding-bottom:0;
		align-items: center;
	}
	.bk .community-header .search_box {
		background:#fff;
		min-width:650px;
		width:auto;
		border-radius:0;
		position:relative;
		height:50px;
		border:1px solid #d0d2d6
	}
	.bk .community-header .search_box .inp {
		background:#fff;
		border-radius:0;
		border:none;
		height:100%;
		font-size:15px;
	}
	.bk .community-header .search_box .btn_sch {
		background:url(../images/img_bk/ico_search.png) no-repeat;
		background-size: contain;
		width:20px;
		height:20px;
		position:absolute;
		right:10px;
		top:50%;
		transform:translatey(-50%);
		text-indent:-9999px;
	}
	.bk .community-header .btn_filter {
		background:#7a1d05 url(../images/img_bk/ico_filter_wh.svg) no-repeat 50% 28%;
		color:#fff;
		position:relative;
		height:50px;
		font-size:0;
		min-width:50px
	}
	.bk .community-header .btn_filter:after {
		display: inline-block;
		content: '필터';
		position: absolute;
		bottom: 5px;
		left: 50%;
		transform: translatex(-50%);
		font-size: 12px;
	}
	.bk .community-header .search_result_area {
		border-radius: 0;
		background: #fff;
		padding: 20px 0;
		box-sizing:border-box;
		min-width:650px;
		width: auto;
		position: absolute;
		left: 0;
		top:69px;
		border-radius: 0;
		max-height: 250px;
		overflow-y: auto;
		border: 1px solid #eaeaea;
	}
	.bk .community-header .search_result_area ul li {
		padding: 4px 20px;
		text-align:left
	}
	.bk .community-header .search_result_area ul li a {
		display:block;
		font-size:14px;
	}
	.bk .community-header .search_result_area ul li:hover {
		background:#f9f9f9
	}
	.bk .sub-article.community-article {
		padding:0;
		width:100%;
		margin-top:0
	}
	.bk .card-community-box {
		padding:0;
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;
		width:1100px;
		margin:0 auto;
	}
	.bk .community-filter-area {
		padding: 20px 0 12px;
		width:1100px;
		margin:0 auto;
		box-sizing:border-box;
		position:relative;
		text-align:left;
	}
	.bk .community-filter-area .filter-result {
		padding:0 10px;
	}
	.bk .community-filter-area .check-box {
		position:unset;
		/* right:0;
		top:20px; */
		width:220px;
		margin-top:20px;
		margin-left:10px;
	}
	.bk .filter-result .hashtag {
		padding:4px 8px 4px 10px;
		background:#901E00;
		color:#fff;
		border-radius:20px;
		font-weight:300;
		margin:3px
	}
	.bk .card-community-list {
		padding:20px;
		background:#fff;
		position:relative;
		margin:6px;
		flex: auto;
		min-width: 32%;
		max-width: 32%;
		box-sizing: border-box;
		display:flex;
		flex-direction: column;
		justify-content: end;
		text-align:left;
	}
	.bk .ccl-content {
		padding-bottom:12px;
		font-size:14px;
		flex:1
	}
	.bk .card-community-list .ccl-content p {
		font-size:15px;
	}
	.bk .ccl-userinfo {
		font-size:14px;
		flex:0
	}
	.bk .user-agent {
		display:inline-block
	}
	.bk .writer .shortcut {
		display:inline-block;
	}
	.bk .chatbtn-wrap {
		position:relative;
	}
	.bk .chat-agent {
		display:inline-block;
		content:'';
		width:20px;
		height:20px;
		background:url(../images/img_bk/ico_chat01.svg) no-repeat center center;
		background-size:contain;
		vertical-align: middle;
		margin-top:-4px;
		margin-left:0;
	}
	.bk .chat-count {
		display: inline-block;
		width: 15px;
		height: 15px;
		font-size: 10px;
		background: #c22800;
		color: #fff;
		border-radius: 10px;
		position: absolute;
		right: 0;
		top: -7px;
	}
	.bk .chat {
		border:1px solid rgb(208, 210, 214)
	}
	.bk .chat-list-wrap {
		border:1px solid rgb(208, 210, 214);
		width:40%;
		max-width:400px;
	}
	.bk .chat-blank p:before {
		background: url(../images/img_bk/ico_chat02.svg) no-repeat 50% 50%;
		background-size:contain;
		width: 80px;
		height: 80px;
	}
	.bk .chat-wrapper {
		width:100%;
		margin:0;
	}
	.bk .chat-wrapper .container {
		padding-top:0;
		overflow-y:unset
	}
	.bk .chat-wrapper .sub-content {
		padding-left:0;
		padding-top:0;
	}
	.bk .user-nick {
		display:inline-block;
		font-weight:600;
		vertical-align: middle;
	}
	.bk .user-nick:before {
		display:inline-block;
		content:'';
		width:18px;
		height:18px;
		background:url(../images/img_bk/ico_user.svg) no-repeat center center;
		vertical-align: middle;
		margin-top:-2px;
		margin-right:3px;
	}
	.bk .user-location {
		display:inline-block;
		font-size:11px;
		background:#eee;
		color:#666;
		padding:3px 8px;
		border-radius: 20px;
		margin-left:6px;
		vertical-align: middle;
	}
	.bk .ccl-agent-area {
		align-items: center;
	}
	.bk .ccl-hashtag-area {
		padding-bottom:5px;
	}
	.btn_zzim {
		position:relative;
		width:20px;
		height:20px;
		text-indent: -9999px;
		margin-left:3px;
	}
	.btn_zzim:before {
		display:inline-block;
		content:'';
		width:20px;
		height:20px;
		background:url(../images/img_bk/ico_zzim.svg) no-repeat center center;
		background-size:contain;
		position:absolute;
		left:0;
		top:0;
	}
	.btn_zzim.select:before {
		display:inline-block;
		content:'';
		width:20px;
		height:20px;
		background:url(../images/img_bk/ico_zzim_on.svg) no-repeat center center;
		background-size:contain;
		position:absolute;
		left:0;
		top:0;
	}
	.bk .btn-upload {
		display: block;
		content: '';
		width: 50px;
		height: 50px;
		background: url(../images/img_bk/img_addfile.svg) no-repeat center center;
		text-indent: -9999px;
		cursor: pointer;
	}
	.bk .filter-sorting {
		padding:10px 20px 5px 0;
		border-bottom:none;
		text-align: left;
	}
	.bk .filter-sorting .filter-sorting-list {
		font-size:14px;
		color:#666;
		font-weight:400;
		display:inline-block;
		margin-right:10px;
	}
	.bk .filter-sorting .filter-sorting-list:hover, 
	.bk .filter-sorting .filter-sorting-list.active {
		font-weight:600;
		color:#222;
	}
	.bk .community .btn_line {
		padding:8px;
		box-sizing:border-box;
		height:50px;
		margin-left:8px;
		min-width:100px
	}
	.bk .community .add_text {
		text-align:left;
		margin-top:8px;
		margin-left:5px
	}
	.bk .card-chat-list {
		text-align:left
	}
	.bk .dimmed_layer .lyr_inner .pop_panel .container {
		padding-left:0;
	}
	.bk .card-comment-list .ccl-content p {
		text-align:left
	}
	.bk .chk_register {
		display:inline-block;
		width:20px;
		height:20px;
		background:url(../images/img_bk/img_docu.svg) no-repeat center center;
		background-size:contain;
		vertical-align: middle;
	}
	@media screen and (max-width: 768px) {
		.bk .chat-list-wrap {
			width:100%;
			max-width:100%;
		}
	}

	/* 220124 중개사 집내놓기 */
	.bk .sub-article {width:800px;margin:0 auto;height: 100%;position: relative;padding:50px 20px 100px;margin-top:30px;}
	.bk .sell-article {width:640px;padding:30px 30px 100px;background:#fff;text-align:left;font-size:13px;}
	.bk .article_exp {font-size:18px;margin-bottom:20px;padding:0 20px}
	.bk .sell-inquery {padding-bottom:70px;}
	.bk .sell-inquery .filter_block {padding:0;margin-bottom:0;}
	.bk .sell-inquery .filter_block + .filter_block {margin-top:30px;}
	.bk .sell-inquery .filter_block + .fliter_box {margin-top:30px;}
	.bk .sell-ins .search_box .inp {background:#fff;border:1px solid #d0d2d6;border-radius:0;height:40px;}
	.bk .sell-row {padding:20px 0;position:relative;}
	.bk .sell-row .sell-lbl {display:block;font-size:1rem;margin-bottom:10px;}
	.bk .sell-row-type .sell-lbl {font-size:1rem}
	.bk .sell-row .btn-schaddr {position:absolute;top:15px;right:0;background:#dbdbdb;font-size:11px;padding: 5px 10px;width: auto;height: auto;border-radius:30px;border:none;}
	.bk .sell-row .radio-box02 {display:flex;flex-flow:row wrap;padding-right:1px;padding-bottom:1px;}
	.bk .sell-row .radio-box02 > .rd01 {flex:auto;width:auto;margin:0 -1px -1px 0}
	.bk .sell-row .check-box03 {display:flex;flex-flow:row wrap;padding-right:1px;padding-bottom:1px;}
	.bk .sell-row .check-box03 > .chk01 {flex:auto;width:auto;margin: 0 -1px -1px 0}
	.bk .sell-row .check-box03 > .chk01 > input[type=checkbox] + label {height:40px;line-height:40px;font-size:13px;width:100%;margin:0;}
	.bk .sell-article .btn-horiz {display: flex;flex: auto;text-align: center;margin: 0 auto;flex-grow: 0;justify-content: center;}
	.bk .sell-article .btn-horiz .btn {width:250px;height:46px;border-radius:4px}
	.bk .sell-article .btn-area-bottom {position:unset;padding:0 20px;width:100%;box-sizing:border-box}
	.bk .sell-row .search_result_area {z-index:1;position:absolute;top:89px;left:0;width:100%;border:1px solid #e0e0e0;background:#fff;box-sizing:border-box;padding:10px 0;max-height: 250px;overflow-y: auto;}   
	.bk .sell-row .search_result_area ul li {padding: 6px 20px;}
	.bk .sell-row .search_result_area ul li:hover {background: #f7f7f7;}
	.bk .sell-row .search_result_area .address {display:block;color: rgb(136, 136, 136);font-size: 13px;}
	.bk .sell-row .search_box .btn_sch {background:url(../images/img_bk/ico_search.png) no-repeat;background-size: contain;width:20px;height:20px;text-indent: -9999px;position:absolute;right:10px;top:60px}
	.bk .add_text {margin-top:5px;}
	.bk .sell-row-trade .radio-box02 > .rd01 {width:33.333333%;}
	.bk .sell-row-trade .check-box.chk-block {margin-top:14px;}
	.bk .sell-row-trade .check-box strong {font-size:15px;}
	.bk .sell-row-price .sell-ins {display:block;margin-top:25px;}
	.bk .sell-row-price .col-box {display:flex;flex-flow:row wrap;align-items: center;padding-bottom:25px;position:relative;}
	.bk .sell-row-price .col-box:after {display:none;}
	.bk .sell-row-price .col-box .col {flex:auto;}
	.bk .sell-row-price .col-box .col:first-child {flex:20%;}
	.bk .sell-row-price .col-box .col:last-child {flex:80%;}
	.bk .sell-row-price .col-box .col .kr {position:absolute;right:0;top:45px;color:#666}
	.bk .sell-row-price .col .addinp_t {margin:0 5px;}
	.bk .sell-row-price .inp-group {display:flex;align-items: center;}
	.bk .sell-row-price .inp-textbox {flex:1 0 auto;margin-top:0}
	.bk .sell-row.sell-row-agree {padding-top:0}
	.bk .sell-row-agree .check-box strong {float:none;}
	.bk .sell-row-agree .add_text {margin-top:10px;}
	.bk .sell-row-date .radio-box02 {display:flex;flex-flow:row wrap}
	.bk .sell-row-date .radio-box02 > .rd01 {flex:auto;width:auto;}
	.bk .sell-row-date .radio-box02 > .rd01 > input[type=radio] + label {height:40px;line-height:40px;width:100%;}
	.bk .sell-row-option .check-box03 > .chk01 {flex:33.33333%;width:auto;}
	.bk .sell-row .check-box {display: flex;flex-flow: row wrap;}
	.bk .sell-row .check-box .check {flex: 33.3333%;width:auto;margin:5px 0;box-sizing:border-box}
	.bk .sell-row .check-box strong {font-size:13px}
	.bk .sell-row-dong .selec > .inp {height:40px;line-height:40px;}
	.bk .sell-row-dong .inp-horiz .selec-totalfloor .inp {height:40px;width:100px}
	.bk .sell-row-dong .inp-horiz > div {background:none}
	.bk .unit {top:11px;font-size:13px;}
	.bk .sell-row-dong .selec_btn {top:11px;}
	.bk .sell-row-dong .selec_option {top:38px}
	.bk .sell-row .datepick {width:100%;margin-top:10px;position:relative;padding-right:50px;box-sizing: border-box;top:unset;right:unset;left:unset;}
	.bk .sell-row .datepick::after {display: inline-block;content: "이후";margin-left: 5px;position: absolute;right: 13px;top: 14px;}
	.bk .sell-row .datepick .inp {width:100%;height:40px;font-size:13px;padding-right:10px;}
	.bk .sell-row .radio-box02>.rd01>input[type=radio]+label {margin:0;width:100%;padding:0 10px;font-size:13px;height:40px;line-height:40px;}
	.bk .sell-row .lst_attached li {display: inline-block;margin: 3px;width: calc(33.33333% - 6px);height: 120px;vertical-align: middle;position: relative;}
	.bk .sell-row .inp-textbox .inp {height:40px;font-size:13px;}
	.bk .sell-article .tab-box {margin-bottom:30px;margin-top:30px;}
	.bk .sell-article .inquery .formGroup {display:flex;align-items: center;margin:5px 0}
	.bk .sell-article .inquery .row {padding:15px;}
	.bk .sell-article .inquery .row .lbl {width:20%}
	.bk .sell-article .tit_exp {font-size:14px;}

	/* 매물내놓기 매물리스트 */
	.bk .sub_cont {margin:0 auto;width:816px;max-width:816px;}
	.bk .sub_cont .mybox {display:flex;flex-flow:row wrap;justify-content: space-between;margin-top:20px;}
	.bk .mybox-mysell {background:#fff;border:1px solid #eaeaea;width:400px;margin:8px 0;text-align:left;row-gap:16x;display:flex;flex-flow:column;}
	.bk .mysell-row {padding:20px;position:relative;font-size:14px}
	.bk .mysell-row .lbl {font-size:1rem;display:block;/* padding:0 20px; */margin-bottom:10px}
	.bk .mysell-rateinfo {margin-bottom:10px}
	.bk .mysell-rateinfo .lbl {padding:0}
	.bk .bar-holder {width: 100%;background-color: rgb(231, 231, 231);border-radius: 20px;-webkit-border-radius: 20px;-moz-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius: 20px;margin: 0 auto;height:18px;position:relative;}
	.bk .bar-progress {width: 20%;height:100%;background-color:#7a1d05;color: #fff;border-radius: 20px;-webkit-border-radius: 20px;-moz-border-radius: 20px;-ms-border-radius: 20px;-o-border-radius: 20px;text-align: center;margin-top: 15px;font-weight: 400;}
	.bk .bar-value {position:absolute;right:0;top:-30px;color:#7a1d05;font-weight:600;font-size:18px;}
	.bk .cbi-info-date {font-size:13px;color:#676767;margin-top:2px;}
	.bk .mysell-item {padding:20px;}
	.bk .btn-selecag {display:inline-block;width:auto;position:absolute;top:-5px;right:20px;padding:4px 10px 3px;background:#d9d9d9;border-radius:30px;height:auto;}
	.bk .csi-date {margin-top:5px;}
	.bk .csi-date span {font-size:13px;display:block;color:#676767}
	.bk .csi-detail {margin-top: 5px;}
	.bk .csi-detail .go_detail {position: relative; top: initial; left: initial; right: initial;}
	.bk .mysell-item .lst_attached {width:120px;height:110px;display:block;position:absolute;right:20px;top:20px;margin-top:0}
	.bk .mysell-item .btn-file-wrap {border:none;background:#f9f9f9;height:110px;}
	.bk .mysell-item .btn-file-wrap label {line-height:90px;font-size:22px;height:110px;}
	.bk .mysell-item .btn-file-wrap label:after {display:inline-block;content:'매물사진을 등록해주세요';position:absolute;bottom:25px;left:50%;transform:translatex(-50%);font-size:11px;line-height:1.3;}
	.bk .mysell-item .btn-file-pic {height:110px;}
	.bk .mysell-rateinfo-detail {padding:0 20px 20px 20px;}
	.bk .card-sell-rate {padding:20px;background:#fff;border:1px solid #eaeaea;}
	.bk .csr-info {display:flex;justify-content: space-between;align-items: center;margin-bottom:8px;}
	.bk .csr-info span {font-weight:600}
	.bk .csr-value a {display:flex;padding:5px 15px;border-radius: 20px;font-size:12px;font-weight:500}
	.bk .value-modi {background:#f1f1f1}
	.bk .unregi {color:#ad2400;}
	.bk .value-regi {background:#901E00;color:#fff;}
	.bk .mysell-row .card-bk-info {border:1px solid #eaeaea;}
	.bk .mysell-row.mysell-item .lbl, .bk .mysell-row.mysell-rateinfo-detail .lbl {padding:0}
	.bk .mysell-contact {padding:0 20px;}
	.bk .mysell-contact .lbl {padding:0;}
	.bk .mysell-item .csi-name {font-size:16px;font-weight:600}
	.bk .mysell-item .csi-price {font-size:15px;}
	.bk .mysell-item .csi-name, .bk .mysell-item .csi-price, .bk .mysell-item .csi-date {padding-right:130px;}
	.bk .mysell-button {display: flex;/* flex-flow:row wrap; */justify-content: space-around;padding-top:0;margin-bottom:20px;}
	.bk .mysell-button .btn-basic {font-size:14px;height:40px;flex:1;min-width:140px;max-height:140px;margin:5px;}
	.bk .mysell-row .btn-modi {background:#f1f1f1;color:#9d9d9d;margin-left:4px;font-size: 11px;border-radius: 30px;padding: 2px 10px 2px;display: inline-block;position: unset;vertical-align: middle;height:unset;width:unset;}
	.bk .mysell-row .btn-selecag {background:rgb(217, 217, 217);font-size: 12px;border-radius: 30px;padding: 2px 10px 2px;display: inline-block;top:0;vertical-align: middle;height:unset;width:unset;}
	.bk .card-bk-info {padding:15px 20px;background:#fff;display:flex;flex-flow:row wrap;align-items: center;position:relative;margin-bottom:10px;}
	.bk .cbi-thumb {width:50px;flex:0 0 auto;margin-right:10px;padding-right:0;}
	.bk .cbi-thumb-img {width:50px;height:50px;border-radius:5px;object-fit: contain;}
	.bk .cbi-thumb-img img {width:100%;height:100%;object-fit: cover;}
	.bk .cbi-info {padding-right:40px;flex:1;}
	.bk .cbi-info-office {font-size:16px;}
	.bk .agent-name {font-size:15px;font-weight:600}
	.bk .score-star:before {display:inline-block;content:'';background:url(../images/img_bk/ico_star.svg) no-repeat;width:17px;height:17px;background-size:contain;margin-right:3px;vertical-align: middle;margin-top:-3px;}
	.bk .count-review {color:#4579F2;}
	.bk .cbi-info-address {font-size:14px;margin-top:2px;}
	.bk .cbi-info-selec {position:absolute;right:20px;top:50%;transform:translatey(-50%);}
	.bk .cbi-selec .check-box02 input:checked + i {margin-right:0}
	.bk .btn-bkchat {width:50px;height: 50px;border-radius: 50px;display: inline-block;display: flex;align-items: center;background: #901E00;color: #fff;font-size: 13px;justify-content: center;line-height: 1.2;}
	.bk .btn-basic {font-size:13px;height:40px;}
	.bk .mybox .btn-box {width:100%;text-align:center;margin:0 auto; margin-top:20px;}

	/* 210927 동영상가이드클립 */
	.bk .video_box.familysite {
		margin-top:60px
	}
	.bk .video_box.familysite .lst_prvideo {
		display:flex;
		flex-flow:row nowrap;
		column-gap:16px;
		align-items:center;
		padding:0;
		justify-content: flex-start;
		margin-top:0;
	}
	.bk .video_box.familysite .lst_in_li {
		height:60px;
		margin:0;
		// max-width:150px;
		// width:inherit;
		flex-shrink:0;
		flex-grow:0;
		border-radius:12px;
		background:#fff;
		margin-bottom:0 !important
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb {
		width:100%;
		height:60px;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb:before,
	.bk .familysite .lst_prvideo .lst_in_li .thumb:after {
		display:none;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb:hover:after {
		display:none;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb01 {
		background: url(../../assets/images/img-family01.png) no-repeat 50%;
		background-size:contain;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb02 {
		background: url(../../assets/images/img-family02.png) no-repeat 50%;
		background-size:contain;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb03 {
		background: url(../../assets/images/img-family03.png) no-repeat 50%;
		background-size:contain;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb04 {
		background: url(../../assets/images/img-family04.png) no-repeat 50%;
		background-size:contain;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb05 {
		background: url(../../assets/images/img-family05.png) no-repeat 50%;
		background-size:contain;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb06 {
		background: url(../../assets/images/img-family06.png) no-repeat 50%;
		background-size:contain;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb07 {
		background: url(../../assets/images/img-family07.png) no-repeat 50%;
		background-size:contain;
	}
	.bk .familysite .lst_prvideo .lst_in_li .thumb08 {
		background: url(../../assets/images/img-family08.png) no-repeat 50%;
		background-size:contain;
	}
	@media screen and (max-width: 768px) {
		.bk.bk-resp .sect_box01.feed {
			width: 100%;
			height: 100%;
			flex-direction: column;
			display: flex;
			overflow: hidden;
		}
		.bk.bk-resp .chat-wrapper {
			padding-bottom: 0;
			top: 54px;
		}
		.bk.bk-resp .chat-wrapper .chat {
			height: calc(100% - 54px);
		}
	}
}