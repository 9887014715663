@charset "UTF-8";

/* CSS Document */
.pagewrapper {
	min-height: 100vh;
	position: relative;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-ms-flex-direction: column;
	background: #F2F3F5
}

.ld-header {
	background: #fff;
	height: 50px;
	padding: 12px 30px 0;
	position: relative;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

.ld-header .logo {
	float: left;
	/* margin-left: 30px; */
	width: 111px;
	height: 25px;
	background: url(../../assets/images/logo.svg) no-repeat;
	background-size: contain;
	text-indent: -9999px;
	/* margin-top: 13px; */
}

.view-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	position: relative;
}

.view-content {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	align-items: center;
	float: none;
	margin: 0 auto;
	min-width: 600px;
	max-width: 600px;
}

.view-row {
	width: 100%;
	padding: 0 20px;
	box-sizing: border-box
}

.view-title {
	font-size: 32px;
	font-weight: 500;
	padding-top: 8vh;
	padding-bottom: 20px;
	/* margin-bottom:10px; */
	line-height: 1.3;
	padding-left: 20px;
	letter-spacing: -1px;
}

.view-navi {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	width: 100%;
}

.view-navi .navi-item {
	min-width: 16%;
	max-width: 50%;
	margin-left: 0;
	margin-right: 0;
	text-align: center;
	/* margin-bottom: 10px; */
	position: relative;
	height: 217px;
	padding: 20px;
	box-sizing: border-box;
	cursor: pointer;
}

.navi-icon {
	background: #fff;
	border-radius: 30px;
	min-width: 80px;
	max-width: 145px;
	height: 145px;
	margin: 0 auto;
	position: relative;
	margin-bottom: 12px;
}

.navi-icon img {
	width: 100%;
	height: 145px;
	display: block;
	object-fit: contain;
}

.navi-lable {
	font-size: 19px;
	line-height: 22px;
	width: 145px;
	margin: 0 auto;
	color: #4c4c4c
}

.appdown-row {
	display: block;
	/* position:absolute;
	bottom:50px;
	left:0; */
	padding: 0 40px;
	margin-top: 40px;
}

.navi-appdown {
	width: 100%;
	display: block;
	background: #fff;
	border-radius: 24px;
	padding: 20px 100px 20px 20px;
	box-sizing: border-box;
	position: relative
}

.navi-appdown::after {
	display: block;
	content: '';
	width: 80px;
	height: 80px;
	background: url(../../assets/images/icon-app.svg) no-repeat;
	background-size: contain;
	position: absolute;
	right: 20px;
	top: 20px;
}

.navi-appdown .text {
	font-size: 18px;
}

.navi-appdown .btn-appdown {
	margin-top: 5px;
	display: inline-block;
	color: var(--main-color-1);
}

.navi-appdown .btn-appdown:after {
	display: inline-block;
	content: '';
	width: 10px;
	height: 10px;
	margin-left: 5px;
	background: url(../../assets/images/ico-arr-mc.svg) no-repeat;
	background-size: contain;
	margin-top: -2px;
}

.navi-card {
	min-width: 50%;
	max-width: 50%;
	padding: 0 10px;
	box-sizing: border-box;
}

.navi-sell {
	height: 220px;
	padding-left: 0
}

.navi-card-inner {
	background: #fff;
	height: 100%;
	border-radius: 30px;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
	justify-content: center;
	cursor: pointer;
}

.navi-card-inner .navi-icon {
	width: 50px;
	height: 50px;
	margin: 0;
	background: url(../../assets/images/navi-icon02_s.svg) no-repeat;
	background-size: contain;
	margin-bottom: 10px;
}

.navi-card-inner p.sub {
	font-size: 16px;
}

.navi-card-inner p.main {
	font-size: 20px;
	font-weight: 600;
}

.navi-buy {
	padding-right: 0
}

.navi-buy .navi-card-inner {
	background: #fff;
	height: 90px;
	border-radius: 24px;
	padding: 15px 20px;
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	/* align-items: center; */
	justify-content: left;
	align-items: center;
}

.navi-buy .navi-card-inner {
	margin-bottom: 10px;
}

.navi-buy .navi-card-label {
	display: block;
	text-align: center;
	margin-bottom: 7px;
	letter-spacing: -.9px;
}

.navi-buy .icon-buy01 {
	width: 30px;
	height: 30px;
	margin: 0;
	background: url(../../assets/images/navi-icon01_s.svg) no-repeat;
	background-size: contain;
	margin-right: 10px;
	min-width: unset;
	max-width: unset;
}

.navi-buy .icon-buy02 {
	width: 30px;
	height: 30px;
	margin: 0;
	background: url(../../assets/images/navi-icon07_s.svg) no-repeat;
	background-size: contain;
	margin-right: 10px;
	min-width: unset;
	max-width: unset;
}

.view-navi.news {
	display: block;
	margin-top: 20px;
	padding: 0;
	box-sizing: border-box;
}

.view-navi.news .navi-card {
	width: 100%;
	min-width: unset;
	max-width: unset;
	padding: 0;
}

.view-navi.news .navi-card-inner {
	padding: 30px;
}

.view-navi.news .navi-card-inner .news_article {
	margin-top: 0;
}

.view-navi.news .navi-card-inner .article_card {
	padding: 0;
}

.navi-finc {
	margin-top: 20px;
	padding-left: 0
}

.navi-finc .navi-card-inner {
	background: #fff;
	height: 90px;
	border-radius: 24px;
	padding: 15px 20px;
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	justify-content: left;
	align-items: center;
}

.navi-finc .navi-icon {
	width: 28px;
	height: 28px;
	margin: 0;
	background: url(../../assets/images/navi-icon03_s.svg) no-repeat;
	background-size: contain;
	margin-right: 5px;
	min-width: unset;
	max-width: unset;
}

.navi-finc .navi-card-inner p.main {
	font-size: 19px;
	letter-spacing: -.9px;
}

.navi-finc .addtxt {
	margin-top: 3px;
	font-size: 13px;
	letter-spacing: -.7px;
}

.navi-recomm {
	margin-top: 20px;
	padding-right: 0
}

.navi-recomm .navi-card-inner {
	background: #fff;
	height: 90px;
	border-radius: 24px;
	padding: 15px 20px;
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: left;
}

.navi-recomm .navi-icon {
	width: 25px;
	height: 25px;
	margin: 0;
	background: url(../../assets/images/navi-icon04_s.svg) no-repeat;
	background-size: contain;
	margin-right: 5px;
	min-width: unset;
	max-width: unset;
}

.navi-recomm .navi-card-inner p.main {
	font-size: 17px;
	letter-spacing: -.9px;
}

.col03 {
	margin-top: 20px;
}

.col03 .navi-card {
	min-width: 33.333333%;
	max-width: 33.333333%;
	padding: 0 5px 0 0;
}

.col03 .navi-card:nth-child(2) {
	padding: 0 5px;
}

.col03 .navi-card:nth-child(3) {
	padding: 0 0 0 5px;
}

.col03 .navi-card-inner {
	padding: 25px 20px;
	height: 120px;
	box-sizing: border-box;
}

.col03 .navi-card-inner {
	background: #E5E5E5;
	justify-content: center;
}

.col03 .navi-card:nth-child(2) .navi-card-inner {
	background: #E2EAED;
}

.col03 .navi-card:nth-child(3) .navi-card-inner:nth-child(3) {
	background: #E0E2EA;
}

.col03 .navi-card-inner p.sub {
	font-size: 14px
}

.col03 .navi-card-inner p.main {
	font-size: 19px;
	letter-spacing: -1.2px;
}

.view-card-title {
	margin-bottom: 20px;
	font-size: 30px;
	padding-left: 10px;
	padding-top: 50px;
}

.view-card-title .red {
	color: #850100
}

@media screen and (max-width: 767px) {
	.view-content {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	.view-title {
		font-size: 23px;
		padding-top: 30px;
		padding-bottom: 30px;
		margin-bottom: 0;
		font-weight: 500;
		padding-left: 10px;
	}

	.view-navi .navi-item {
		min-width: 33.333333%;
		max-width: 33.333333%;
		height: auto;
		padding: 5px;
		margin-bottom: 10px;
	}

	.navi-icon {
		width: 100px;
		height: 100px;
		border-radius: 24px;
	}

	.navi-icon img {
		width: 100px;
		height: 100px;
	}

	.navi-lable {
		width: 100px;
		font-size: 16px;
	}

	.appdown-row {
		display: block;
		position: absolute;
		bottom: 50px;
		left: 0;
		padding: 0 30px;
	}

	.navi-appdown {
		width: 100%;
		display: block;
		background: #fff;
		border-radius: 24px;
		padding: 20px 100px 20px 20px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.navi-appdown::after {
		display: block;
		content: '';
		width: 80px;
		height: 80px;
		background: url(../../assets/images/icon-app.svg) no-repeat;
		background-size: contain;
		position: absolute;
		right: 20px;
		top: 20px;
	}

	.navi-appdown .text {
		font-size: 18px;
	}

	.navi-appdown .btn-appdown {
		margin-top: 5px;
		display: inline-block;
		color: var(--main-color-1);
	}

	.navi-appdown .btn-appdown:after {
		display: inline-block;
		content: '';
		width: 10px;
		height: 10px;
		margin-left: 5px;
		background: url(../../assets/images/ico-arr-mc.svg) no-repeat;
		background-size: contain;
		margin-top: -2px;
	}

	.view-navi.news .navi-card-inner {
		padding: 20px;
	}

	.view-card-title {
		font-size: 22px;
	}

	.navi-finc .navi-card-inner p.main,
	.navi-recomm .navi-card-inner p.main,
	.col03 .navi-card-inner p.main {
		font-size: 17px;
	}

	.navi-finc .addtxt {
		font-size: 11px;
	}
}

.btn-horiz .btn small {
	font-size: 14px;
	color: #fff;
	line-height: 1;
	margin-top: 5px;
	margin-left: 0;
	display: block
}

.panel-group .btn-horiz .btn {
	width: 100%;
	margin: 0;
	line-height: 1
}

.btn-green {
	background: #599A62;
	color: #fff;
}

// dawin-root
:root.root-dawin {
}