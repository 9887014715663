@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */

#wrap.regi {overflow-y:scroll}
.cont_inner {margin-bottom:75px;padding-top:10px}

/*집내놓기 메인*/
.header-submain {background:none}
.regi .submain-wrap {padding-bottom:60px;}
.regi .submain-img-box {width:100%;height:100vh;position:relative;background-position:center;/*background-attachment:fixed*/}
.sell-imgbox01 {background:url(../images/new/img_smain01.png) no-repeat;background-size:cover;}
.submain-img-box .box-inner {max-width:100%;min-width:350px;/*position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)*/padding-top:120rem}
.submain-img-box .box-inner:after {display:block;content:'';clear:both}
.submain-img-box .box-content {width:100%;height:250px;}
.sell-imgbox01 .box-content {background:url("../images/img_submain_txt.png") no-repeat;background-size:contain;background-position:center;height:320px;}
.submain-img-box.sell-imgbox01 .box-inner {padding-top:100rem}
.submain-img-box .box-inner p {text-align:center;font-size:14px;color:#fff;margin-top:30px}
.submain-box .box-inner {text-align:center;margin:0 auto;padding:50px 20px 0;color:#222;}
.submain-box .sc_dec {font-size:14rem;line-height:1.6;margin-top:15px;font-weight:400;letter-spacing:-0.9px;padding:0 20px}
.submain-box .sc_dec .add {display:block;text-align:center;font-size:12px;margin-top:5px;color:#666;line-height:1.4}
.submain-box .sc_dec .add:before {display:inline-block;content:'*';font-size:12px;vertical-align:middle;margin-right:3px;}
.submain-box .sc_dec .add strong {font-weight:600;}
.submain-box .sc_dec .add .btn-xsmall {display:inline-block;margin-top:8px;}
.schooldetail .panel-header
.submain-box .sc_dec_s {font-size:14px;line-height:1.4;margin-top:15px;font-weight:300;letter-spacing:-0.9px}
.submain-box .sc_tit {font-size:calc(16px + 2.5vw);color:#222;letter-spacing:-.9px}
.scdec_img {margin-top:15px}
.scdec_img img {width:100%;height:auto}
.submain-img-box .box-inner .addexp b {color:#fec500}
.sc_dec .emp_naver {color:#00cd39}
.sc_dec .emp_naver:before {display:inline-block;content:'';width:18px;height:18px;background:url(../images/ico_n.png) no-repeat;background-size:contain;margin-right:2px;vertical-align:middle;padding-top:4px;}
.joinagent-main .submain-box.ja07 .sc_dec {margin-top:0;padding:0 30px}
.joinagent-main .submain-box.ja07 .sc_dec span {display:block;font-size:16px;margin-bottom:5px}
.joinagent-main .submain-box.ja07 .sc_dec span:last-child {margin-bottom:0}

/*전화로집내놓기*/
.regi-phone .sell-imgbox02 {background:url(../images/bg_sv02.png) no-repeat no-repeat 50% center;min-height:500px;height:70vh;background-size:cover;position:relative;padding:30px}
.regi-phone .sell-imgbox02 .box-inner {min-width:100%;max-width:100%;max-height:280px;background:none;position:static;padding-top:120rem;transform:none}
.regi-phone .box-content {height:250px;background:url("../images/img_sv02.png") no-repeat;background-size:contain;background-position:left;}
.ban_area a.link_sellph {display:block;width:100%;min-height:70px;height:100%;background:url(../images/img_banner02.png) no-repeat 50% center;background-size:cover;text-indent:-9999px}
.regi-phone .submain-box .box-inner {background:#fff;text-align:left;min-height:450px;height:100%;padding:30px 30px 75px;box-sizing:border-box;background-size:cover;position:relative}
/*.regi-phone .submain-box .box-inner .m_text {text-align:left}*/
.regi-phone .submain-box .box-inner p {font-size:16px;line-height:1.8;letter-spacing:-.6px}
.regi-phone .submain-box .box-inner .p03 {font-size:20px;line-height:1.3;margin-bottom:20px;letter-spacing:-1px;}
.regi-phone .submain-box .box-inner .p03 b {font-size:30px;font-weight:600}
.regi-phone .check-box {display:inline-block;vertical-align:middle;margin-top:15px;}
.regi-phone .btn-xsmall {display:inline-block;vertical-align:middle;margin-left:3px;}
.regi-phone .submain-box .box-inner p.small {font-size:13px;margin-top:10px;line-height:1.5}
.regi-phone .inp-verti {margin-top:15px;}
.btn-area-bottom .btn-horiz .btn-second {margin-right:0;width:100%}
.regi-phone .check-box + div {margin-top:5px;font-size:13px;color:red}
.regi-phone .check-box strong {line-height:1.2}

/* 중개사회입가입 */
.joinagent .cont_wrap {display:flex}
.joinagent-main .header {background:none}
.joinagent .submain-wrap .submain-box:last-child {padding-bottom:60px;}
.joinagent .submain-img-box {width:100%;height:100vh;position:relative;background-position:center;background-size:cover;/*background-attachment:fixed*/}
.ag-imgbox01 {background:url(../images/img_agent01.png) no-repeat;}
.ag-imgbox01 .box-content {height:400px;background:url("../images/img_agent05.png") no-repeat;background-size:contain;background-position:center}
.ag-imgbox02 {width:100%;position:relative;min-height:430px;box-sizing:border-box;background:#fff url(../images/img_agent02.png) no-repeat;background-position:center bottom;background-size:contain;}
.ag-imgbox03 {min-height:350px;box-sizing:border-box;background:#fff url(../images/img_agent03.png) no-repeat;background-position:center bottom;background-size:contain;}
.ag-imgbox04 {min-height:510px;box-sizing:border-box;background:#fff url(../images/img_agent04.png) no-repeat;background-position:center bottom;background-size:contain;margin-bottom:30px}
.submain-box .m_exp {margin-top:15px;text-align:center;margin-bottom:30px}
.ag-imgbox05 {padding:50px 0;background:#6c1b05;}
.ag-imgbox05 .box-inner {padding-top:0}
.ag-imgbox05 .sc_tit {color:#fff;font-size:23px}
.ag-imgbox05 .sc_dec {color:#fff}
.joinagent-main .btn-area-bottom .btn sub {display:block;font-size:12px;font-weight:300;color:#fed700}
.selec-agtype .radio-box02 > .rd01 > input[type=radio] + label {height:65px;line-height:60px;font-size:18px;text-align:left;padding:0 15px}
.selec-agtype {margin-top:20px;width:100%;box-sizing:border-box}
.selec-agtype strong {font-size:19px;line-height:1.2}
.agtype-tit {font-size:20px;padding-bottom:15px}
.agtype-notice p {font-size:19px;margin-bottom:20px}
.align-l {text-align:left}
.joinagent .term_box .check_box:first-child {margin-bottom:30px}
.sch_bkoffice {margin-top:10px;position:relative}
.joinagent .sch_inpbox .sch_result {top:184px}
.sch_inpbox .sch_result {top:67px;}
.add_text {font-size:13px;padding:5px 0;color:#AFAFAF;line-height:1.4}
.inquery {margin-top:-10px}
.row {padding:15px 0;position:relative}
.row .lbl {display:block;margin-bottom:10px;font-size:14px}
.row .ins {display:block;position:relative}
.inp-group + .inp-textbox {margin-top:10px}
.subj_tit .m_exp {margin-bottom:0;margin-top:10px;line-height:1.4}
.row.profile {text-align:center}
.ok_up {display:block;width:100%;height:45px;box-sizing:border-box;background:#574945;color:#fff;text-align:center;font-weight:100;font-size:14px;padding-top:12px}
.ok_up i {display:inline-block;width:16px;height:16px;margin-left:10px;background:url(../images/ico_ok.png) no-repeat;vertical-align:middle}
small {font-size:13px;margin-left:5px;color:#A7A7A7;display:inline-block;vertical-align:middle;}
.mem_lst .mem_item {width:100%;padding:15px;display:block;box-sizing:border-box;margin-bottom:15px;}
.itm_cont .name {float:left;font-size:20px;font-weight:400}
.itm_cont .price {float:right;font-size:22px;font-weight:500;}
.itm_cont .exp {display:block;clear:both;color:#666;margin-bottom:20px;}
.prof_area {margin-bottom:10px;}
.btn-file-wrap .btn-del {position:absolute;top:8px;right:8px;z-index:3;}
.danjipic_area {width:100%;margin-top:10px}
.subloc .sub_container {padding-top:0}
.subloc .header {background:none}
input[type="search"] {font-size:16px;}
.inp-box + .btn-box {margin-top:10px}
.joinagent-main .submain-img-box.share-imgbox01 {background:url("../images/img_share01.png") no-repeat;background-size:cover;height:75vh}
.share-imgbox01 .box-content {background:url("../images/img_share02.png") no-repeat;background-size:contain;background-position:center;height:240px}
.joinagent-main .submain-box:nth-child(2).share-imgbox02 {padding:60px 0;background:#505050;}
.share-imgbox02 .box-inner {padding-top:0}
.share-imgbox02 .sc_tit {color:#fff;font-size:24px}
.share-imgbox02 .sc_dec {color:#fff}
.share-imgbox02 .sc_dec span {display:block;margin-bottom:8px}
.share-imgbox02 .sc_dec span:first-child {font-size:20px}
.share-imgbox02 .sc_dec span:last-child {margin-bottom:0}
.share-imgbox02 .sc_dec_s {color:#fff;font-weight:300}
.joinagent-main .submain-box.share-imgbox03 {position:relative;height:400px;width:100%;background:url(../images/img_map.png) no-repeat;background-size:cover;background-position:50% 50%}
.joinagent-main .submain-box.share-imgbox04 {background:#f8f8f8;padding-bottom:40px}
.pin-office {background:url("../images/ico_pointer.png") no-repeat;width:25px;height:36px;background-size:contain;position:absolute;}
.pin-office span {color:#fff;display:inline-block;padding:2px 5px;background:rgb(0,0,0,0.7);position:absolute;font-size:11px;min-width:40px;left:50%;top:-24px;transform:translatex(-50%);border-radius:2px;border:1px solid #000;box-sizing:border-box}
.share-imgbox04 .sc_dec {position:relative;}
.pagination a {width:30px;height:30px;position:absolute;top:40%;transform:translatey(-50%);background-size:contain}
.pagination .btn_prev {background-image:url(../images/btn_prev.png) ;background-repeat:no-repeat;left:-5px;}
.pagination .btn_next {background-image:url(../images/btn_next.png);background-repeat:no-repeat;right:-5px;}
.share-imgbox04 ul {display:flex;flex-direction:row;flex-wrap:wrap;max-width:440px;overflow:hidden;margin:0 -6px}
.share-imgbox04 ul li {display:block;padding:10px;box-sizing:border-box;background:#fff;margin:6px;flex:1;min-width:calc((100% / 2) - 12px);float:left;height:122px}
.share-imgbox04 ul li a {display:inline-block;width:100%;height:100%;vertical-align:middle}
.joinagent-share .header, .ja_apply .header {position:fixed;z-index:201}
.joinagent-main + .find .container {padding-top:0}
.joinagent-share .cont_wrap {padding: 10px 20px;}
.thumb_office {width:100%;height:100%}
.thumb_office.office01 {background:url("../images/img_office01.png") no-repeat;width:100%;height:300px;background-size:cover;background-position:50% 0}
.thumb_office.office02 {background:url("../images/img_office02.png") no-repeat;width:100%;height:300px;background-size:cover;background-position:50% 0}
.thumb_office.office03 {background:url("../images/img_office03.png") no-repeat;width:100%;height:300px;background-size:cover;background-position:50% 0}
/*.info_office {padding:15px 10px;}*/
.info_office span {font-size:19px;display:block;font-weight:600;text-align:center;letter-spacing:-1px}
.info_office span + span {font-size:13px;line-height:1.4;font-weight:400;}
.share-imgbox04 .add_text {font-size:14px;color:#222;padding-bottom:15px;}
.radio-career .radio-box .radio {float:left;width:50%;padding-right:0;padding-bottom:10px}
.joinagent-share .ins {position:relative}
.joinagent-share .filter_selec {z-index:100;top:45px;background:#fff;max-height:250px;overflow-y:scroll;border:1px solid #d0d2d6;padding:0;min-height:65px;}
/*.joinagent-share .filter_selec form {overflow-y:auto;max-height:300px;box-sizing:border-box}*/
.joinagent-share .filter_selec h3 {font-size:15px;font-weight:400;margin-left:15px;margin-bottom:5px}
.joinagent-share .inquery{width:100%}
.radio-date .radio-box {display:block}
.radio-date .radio-box .radio {display:block;margin-bottom:7px;width:100%;margin-bottom:0;}
.joinagent-share .vt-area {position:static;top:inherit;left:inherit;padding:0 20px}
.joinagent-share .vt-area .ntc_p {font-size:30px;margin-bottom:60px;text-align:left;padding-top:30px}
.joinagent-share .vt-area .addexp {position:absolute;left:40px;bottom:140px}
.joinagent-share .chk_agree {/*position:absolute;bottom:100px*/padding-top:20px;padding-bottom:50px}
.joinagent-share .btn-xsmall {display:inline-block;vertical-align:middle;margin-left:3px;}
.find .ja_apply .container {padding-top:55px;}
.ja_apply .inp-horiz .selec {padding:0 10px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.ja_apply .inp-horiz a:last-child {margin-right:0}
.ja_apply .filter_selec .filter-group {padding:10px;max-height:171px;overflow-y:scroll;box-sizing:border-box}
.ja_apply .filter_selec .filter-btn-area {position:static}
.ja_apply .containter {padding-top:55px}
.viewoffice .lyr_inner {padding:0}
.viewoffice .lyr_inner .top {padding:20px}
.viewoffice .lyr_inner .top .name {font-size:22px;font-weight:600}
.viewoffice .lyr_inner .top .address {font-size:16px;}
.viewoffice .lyr_inner .bottom {width:100%;/*height:300px*/}
.viewoffice .lyr_inner .bottom .office_img {height:250px}
.viewoffice .lyr_inner .bottom .map {height:200px;position:relative}
.office_img .slider-container, .office_img  .slider-container ul {height:250px;}
.office_img .slider-container ul li {height:250px;}
.term_box .btn-xsmall {display:inline-block}
.joinagent 
.row .inp-horiz + .inp-group {margin-top:10px;}
.joinagent .row_addr .inp-textbox {margin-bottom:10px;}
.joinagent .row_addr #address_detail {margin-top:10px}
.joinagent .row_addr .maparea {width:100%;height:250px;;border:1px solid  #d0d2d6;box-sizing:border-box;margin-bottom:10px;}




.joinagent-main .submain-img-box {width:100%;height:100vh;position:relative;}
.joinagent-main .submain-box {position:relative}
.joinagent-main .submain-img-box .row {max-width:100%;min-width:350px;/*position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)*/padding-top:100rem}
.joinagent-main .submain-img-box .row:after {display:block;content:'';clear:both}
/*.joinagent-main .submain-img-box .row .box-content {}*/
.joinagent-main .submain-box .row {max-width:960px;display:block;margin:0 auto;box-sizing:border-box;/*padding:60px 0;*/text-align:center;position:absolute;top:7%;left:50%;transform:translatex(-50%);width:100%;}
.joinagent-main .submain-box .row .col-left {padding-right:40px;box-sizing:border-box;width:100%;padding:0 20px;margin:auto 0;position:absolute;top:50px;left:0}
.joinagent-main .submain-box .row .col-left p {text-align:center}
.joinagent-main .submain-box .row .col-right {width:100%;padding:0 40px;box-sizing:border-box;margin:auto 0}
.joinagent-main .submain-box .row .col-right img {object-fit:contain;width:100%;height:100%}
.joinagent-main .submain-box .row .m_exp {width:90%;text-align:center;font-size:16px;color:#9E9E9E;margin:0 auto}
.joinagent-main .submain-box.ja06 {background:#f8f8f8}
.joinagent-main .submain-box.ja07 {background:#6c1b05}
.joinagent .submain-wrap .submain-box.ja06 .row, .joinagent .submain-wrap .submain-box.ja07 .row {position:inherit;padding:30px 0}

/*.joinagent-main .submain-box:nth-child(5) .row .sc_tit {color:#fff;font-size:23px}*/
/*.joinagent-main .submain-box:nth-child(5) .row .sc_dec {color:#fff}*/
/*.joinagent-main .submain-box:nth-child(4) {background:#f8f8f8;padding-bottom:40px}*/
.joinagent-main .submain-box:nth-child(4) .joinagent-main .row, .submain-box:nth-child(3) .row {padding-bottom:60px}
.video_layout {width:250px;height:444.444444444444444px;border-radius:52px;box-shadow:0 40px 61px 58px hsla(0,0%,93.3%,.7);background-color:#fff;display:-webkit-box;display: flex;-webkit-box-pack:center;justify-content:center;-webkit-box-align:center;align-items:center;overflow:hidden;margin:0 auto;margin-top:30px;}
.video_md {margin-top:1px;border-radius:52px;width:100%;height:100%;}
.joinagent-main .submain-box.ja01 {background:#f1f1f1 url("../images/img_jamain01.png") no-repeat;background-size:contain;/*height:620px;*/padding-top:165%;background-position:center bottom}
.joinagent-main .submain-box.ja02 {background:url("../images/img_jamain02.png") no-repeat;background-size:contain;background-position:center bottom;/*height:620px;*/padding-top:155%;}
.joinagent-main .submain-box.ja03 {background:#fff/*url("../images/img_jamain03.png") no-repeat*/;background-size:contain;background-position:center bottom;/*height:520px;*/padding-top:86%;}
.joinagent-main .submain-box.ja04 {background:#fafafa url("../images/img_jamain04.png") no-repeat;background-size:contain;background-position:center bottom;/*height:520px;*/padding-top:140%;}
.joinagent-main .submain-box.ja05 {background:url("../images/img_jamain05.png") no-repeat;background-size:cover;/*height:410px;*/padding-top:110%;}
.ja05 .sc_tit, .ja05 .sc_dec, .ja07 .sc_dec, .ja07 .sc_tit {color:#fff;}
/* .submain-wrap .quickbn.top {width:100%;height:auto;padding:15px;box-sizing:border-box;font-size:12px;position:absolute;bottom:170px;left:0;z-index:100;background:#7a1d05;color:#fff;text-align:left;cursor:pointer;text-indent:0} */
.joinagent-main .submain-wrap:first-child {padding-bottom:0;position:relative;}
.slidebk + .btn-area-bottom {position:absolute}
.slidebk + .btn-area-bottom p {padding:20px 30px;color:#fff;text-align:center;font-size:15px;}
.txt_y {color:#ffd200}
.joinagent-main .submain-box.ja10 {background:#f3f3f3 url("../../assets/images/img_ja07_m.png") no-repeat 50% bottom;background-size:contain;/*height:470px;*/padding-top:122%;}
.sc_dec_blue {color:#3e70c7}
.joinagent-main .submain-box.ja03 .row {top:50%;transform:translate(-50%, -50%)}

.joinagent-main .ja-slide01 {width:100%;height:100vh;background-image:url("../images/img_agent01.png");background-repeat:no-repeat;/*background-attachment:fixed;*/background-position:center;background-size:cover;}
.joinagent-main .ja-slide02 {width:100%;height:100vh;background-image:url("../images/bg_jabk.png");background-repeat:no-repeat;/*background-attachment:fixed;*/background-position:center;background-size:cover;}
.joinagent-main .ja-slide01 .row .box-content {width:100%;height:400px;background:url("../images/img_agent05.png") no-repeat;background-size:contain;background-position:center}
.joinagent-main .ja-slide02 .row {padding-top:84rem}
.ja-slide02 .row .box-content p:first-child {width:100%;height:240px;background:url("../images/img_agent07.png") no-repeat;background-size:contain;background-position:center}
.ja-slide02 .row .box-content p:nth-child(2){width:100%;height:150px;background:url("../images/bk01.png") no-repeat;background-size:cover;background-position:center}
.ja-slide02 .row .box-content p:nth-child(3){width:100%;height:150px;background:url("../images/bk02.png") no-repeat;background-size:cover;background-position:center}

/*창업지원프로그램*/
.joinagent-main .ja-slide03 {width:100%;height:100vh;background-image:url("../images/bg_jastartup.png");background-repeat:no-repeat;/*background-attachment:fixed;*/background-position:center;background-size:cover;}
.ja-slide03 .box-content p{width:100%;height:350px;background:url("../images/img_agent08.png") no-repeat;background-size:contain;background-position:center}
/* .submain-wrap .quickbn.bn_startup {width:100%;padding:15px;box-sizing:border-box;position:absolute;bottom:60px;left:0;z-index:100;background:#2d135c;color:#fff;text-align:left;cursor:pointer;text-indent:0} */
.joinagent-main .submain-box.stup01 {background:#fff;/*padding-top: 28%;*/width:100%;height:0;position:relative;margin:0 auto;padding-bottom:0}
/*.stup01 .stuplst {display:flex;flex-direction:row;flex-wrap:nowrap;}*/
.stup01 .stuplst li {width:100%;text-align:center;padding-bottom:30px}
.stup01 .stuplst li .title {font-size:22px;font-weight:bold;padding-top:150px;position:relative}
.stup01 .stuplst li:first-child .title:before {display:block;content:'';background:url(../images/ico_bk01.png) no-repeat;height:100px;width:100px;background-size:contain;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.stup01 .stuplst li .text01 {font-size:17px;margin-top:10px;}
.stup01 .stuplst li:last-child .title:before {display:block;content:'';background:url(../images/ico_bk02.png) no-repeat;height:100px;width:100px;background-size:contain;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}



/* 중개사신고하기 */
.m_exp {margin-bottom:10px;font-size:14px;line-height:1.4}
.selarea {margin-bottom:15px}
.subg02 {background:#f7f7f7;/*padding:55px 0 0;*/}
.agent-list {margin-top:20px}
.agent-card {width:100%;padding:15px 30px 15px 10px;background:#fff;display:block;box-sizing:border-box;margin-bottom:10px;position:relative;border: 1px solid #ddd;}
.agent-card .itm_inner {position:relative}
.pfbox {width:100%;text-align:center;height:100px;}
.thumbnail_area {position:absolute;top:50%;left:0;transform:translatey(-50%);width:70px;height:70px;display:inline-block}
.thumbnail_area .thumbnail {width:100%;height:100%;border-radius:70px;background-size:contain;background-repeat:no-repeat}
.thumbnail_area .thumbnail img {width:100%;height:100%;border-radius:100%;object-fit:cover;object-position:50%;}
.itm_inner .agent_info {display:block;padding-left:90px;/*height:90px;*/text-align:left;}
.itm_inner .bk_name {font-size:17px;font-weight:600}
.itm_inner .p_num {font-size:15px}
.star_score {margin-bottom:5px}
.star_score span.st_off {display:inline-block;background-image: url(../images/point0.png);background-repeat:no-repeat;vertical-align:middle;position:relative;width:113px;height:20px;}
.star_score span.st_on {position:absolute;z-index:10;width:113px;height:20px;overflow:hidden;top:0;left:0;text-indent:-9999px;display:inline-block;background-image: url(../images/point5.png);background-repeat:no-repeat;}
.ct_review {display:inline-block;vertical-align:middle;font-size:14px}
.agent-card .check_box {position:absolute;right:5px;top:50%;transform:translateY(-50%)}
.agent-card .check_box .check {padding:0}
.agent-card .check_box i {margin-right:0}
.btn_loc {display:inline-block;background-image:url(../images/ico_loc.png);background-repeat:no-repeat;background-size:contain;width:13px;height:16px;cursor:pointer}
.cont_wrap .sch_result {border:1px solid #ccc;position:absolute;width:100%;top:157px;left:0;z-index:10;padding-left:20px}
.star_score .date {display:block;margin-left:0;margin-top:8px;}

/* 집내놓기 */
.regi {height:100vh}
/*.regi .header, .regi .btn-area-bottom {-webkit-transform-style:preserve-3d;-webkit-transform:translateZ(-5px);}*/
/*.regi .header .set .header {-webkit-transform-style:unset;-webkit-transform:unset;}*/
.regi .mask {height:100vh}
h2 + .m_exp {margin-bottom:20px;font-size:15px;}
h3.subj_tit {font-size:18px;color:#000;font-weight:600;padding:0 0 10px}
.t_box {background:#fafafa;padding:15px;margin-top:10px;font-size:14px}
.tag_type.type01 {color:#bf3433;font-weight:600}
.tag_type.type02 {color:#5d69bf;font-weight:600}
.tag_type.type03 {color:#43c750;font-weight:600}
.t_link {display:inline-block;font-size:14px;padding-top:5px;padding-right:20px;background:url(../images/ico_arr02.png) no-repeat 99% 65%;}
.map-container {/*position:absolute;top:55px;left:0;right:0;bottom:0;*/position:relative;width:100%;height:100vh}
.map {width:100%;height:100%;background-repeat:no-repeat;background-position:50% 50%;background-size:cover;background-image:url(../images/bg_map.png);}
.map-pointer {display:inline-block;position:absolute;z-index:10;background:url(../images/ico_pointer.png) no-repeat;width:30px;height:43px;text-indent:-9999px}
.rep_tag {display:inline-block;padding:2px 10px;background:#b62217;color:#fff;font-size:12px;position:absolute;top:-1px;left:0;z-index:100}
.requi {border:1px solid #e44332 !important}
.inp-horiz + .inp-horiz {margin-top:10px}
.inp-horiz .inp-textbox {margin-right:10px}
.inp-horiz .inp-textbox:last-child {margin-right:0}
.col-box {display:table;width:100%;padding:13px 0 12px;position:relative}
.col-box:first-child {padding-top:0;}
.col-box:after {display:block;content:'';height:1px;width:100%;background:#eaeaea;position:absolute;left:0;bottom:0;}
.col-box:last-child:after {display:none}
.col-box .col {display:table-cell;vertical-align:middle;}
.row.date .inp-textbox {margin-top:10px}
.row .check-box strong {font-size:15px;}
.datepick {margin-top:10px}
/*.datepick .inp {background: #fff url(../images/btn_arr01.png) no-repeat center right 8px;}*/
.regi .btn-basic {margin-top:10px}
.regi .tab-box + .tab-box {margin-top:20px}
.regi .container.flexcont {display:flex;flex-direction:column;}
.regi .flexcont .map-container {position:static;flex-grow:1;height:100vh;}
/*.regi .vt-area {top:40%;transform:translateY(-50%)}*/

.ins .inp-horiz:first {}
.inp-horiz.fix {display:flex;flex-wrap:wrap;margin:-5px;margin-top:5px;}
.inp-horiz.fix .selec, .inp-horiz.fix .inp-textbox {display:flex;flex-direction:column;flex:none;flex-basis: calc(100% / 2 - 10px);margin:5px}
.fix .unit {z-index:-1;top:14px}
.inp-horiz.fix .selec_inp + .inp-textbox {height:46px}
.inp-horiz.fix .selec_inp + .inp-textbox .inp {padding-left:40px;}

/* 210427 매물옵션 */
.check-box03.chk_opt > .chk01 > input[type=checkbox] + label {height:85px;text-align:center;padding:12px 5px 8px;line-height:2}
.check-box03.chk_opt > .chk01 > input[type=checkbox] + label::before {display:block;content:'';height:36px;width:100%;} 
#opt01 + label::before {background-image:url(../images/opt01.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt01:checked + label::before {background-image:url(../images/opt01_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt02 + label::before {background-image:url(../images/opt02.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt02:checked + label::before {background-image:url(../images/opt02_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt03 + label::before {background-image:url(../images/opt03.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt03:checked + label::before {background-image:url(../images/opt03_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt04 + label::before {background-image:url(../images/opt04.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt04:checked + label::before {background-image:url(../images/opt04_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt05 + label::before {background-image:url(../images/opt05.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt05:checked + label::before {background-image:url(../images/opt05_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt06 + label::before {background-image:url(../images/opt06.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt06:checked + label::before {background-image:url(../images/opt06_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt07 + label::before {background-image:url(../images/opt07.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt07:checked + label::before {background-image:url(../images/opt07_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt08 + label::before {background-image:url(../images/opt08.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt08:checked + label::before {background-image:url(../images/opt08_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt09 + label::before {background-image:url(../images/opt09.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt09:checked + label::before {background-image:url(../images/opt09_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt10 + label::before {background-image:url(../images/opt10.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt10:checked + label::before {background-image:url(../images/opt10_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt11 + label::before {background-image:url(../images/opt11.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt11:checked + label::before {background-image:url(../images/opt11_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt12 + label::before {background-image:url(../images/opt12.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt12:checked + label::before {background-image:url(../images/opt12_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt13 + label::before {background-image:url(../images/opt13.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt13:checked + label::before {background-image:url(../images/opt13_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt14 + label::before {background-image:url(../images/opt14.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt14:checked + label::before {background-image:url(../images/opt14_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt15 + label::before {background-image:url(../images/opt15.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt15:checked + label::before {background-image:url(../images/opt15_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt16 + label::before {background-image:url(../images/opt16.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}
#opt16:checked + label::before {background-image:url(../images/opt16_on.svg);background-repeat:no-repeat;background-position:50% 50%;background-size:contain;}

.regi .btn-area-bottom .btn-second {background:#311913}


/** 중개사선택 **/
.lyr-bottom.selecag {background:#efefef;padding:15px 0;padding-bottom:65px;z-index:1000}
.selecag .btn-close {position:absolute;top:0;right:0}
.selecag h3 {padding:0 0 5px 20px}
.agent-card .btn-del-small {position:absolute;top:10px;right:10px;z-index:101}
.selecag .agent-card {border:none}
.selecag .agent-card .thumbnail_area {left:10px;}
.selecag .agent-card .check-box02 {right:-10px}

/** 사진올리기 **/
.cont_exp {margin-bottom:20px}
.lbl_exp {font-size:13px;}
.lst_attached {display:flex;flex:auto;flex-wrap:wrap}
.lst_attached li {display:inline-block;margin:3px;width:calc((100% - 18px) / 3);height:120px;vertical-align:middle;position:relative}
.lst_attached.inside li {margin-bottom:30px}
.lst_attached li .up_img {display:block;width:100%;height:100%;border:1px solid #e0e0e0}
.lst_attached li .up_img img {background-size:contain;width:100%;height:100%}
.lst_attached .thumb {width:100%;}
.btn-agpointer {position:absolute;}
.ag-pointer .nm_score {display:inline-block;position:absolute;left:50%;top:-20px;transform: translateX(-50%);color:#fff;background:#eb6100;padding:1px 7px 1px;font-size:11px;border-radius:30px}
.ag-pointer.on .nm_score {background:#db2e2e}
.btn_ques {position:absolute;right:0;top:50%;transform: translateY(-50%)}
.btn_ques button {width:60px;height:60px;background:#af300f;color:#fff;font-size:14px;border-radius:70px;line-height:1.1;font-weight:400}
.btn_ques button:disabled {background:#adadad;border:1px solid #adadad;color:#fff}
.header .inpout {padding-right:50px;}
.btn-file-wrap.living {background:url(../images/img_pic01.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.kitchen {background:url(../images/img_pic02.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.bathroom {background:url(../images/img_pic03.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.room01 {background:url(../images/img_pic04.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.room02 {background:url(../images/img_pic05.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.room03 {background:url(../images/img_pic06.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.entrance {background:url(../images/img_pic07.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.balcony {background:url(../images/img_pic08.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.etc01 {background:url(../images/img_pic09.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.etc02 {background:url(../images/img_pic10.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.etc03 {background:url(../images/img_pic11.png) no-repeat;background-size:cover;background-position:center}
.btn-file-wrap.etc04 {background:url(../images/img_pic12.png) no-repeat;background-size:cover;background-position:center}


.lyr-bottom {width:100%;background:#fff;position:fixed;left:0;bottom:60rem;right:0;margin-bottom:0;z-index:102;padding:20px;min-height:60px;max-height:450px;box-sizing:border-box;overflow-y:auto}
.lyr-bottom .btn-del-small {position:absolute;right:10px;top:10px;}
.lyr-bottom .agent-list {margin-top:8px}
.lyr-bottom .btn-area-bottom {bottom: 60rem;}
.agent-card .check-box02 {position:absolute;right:-20px;top:50%;transform:translatey(-50%);display:block;width:25px;height:25px;}

/** 중개사소개 페이지 **/
.agentpage .container {background:#eaeaea;padding-top:55px !important;}
.agentpage .cont_wrap {display:block}
.agentpage .panel-box {margin-top:0}
.agentintro {background:#eaeaea}
.agentpage .card:first-child {text-align:center;}
.itmbox .thumbnail_area {position:inherit;width:100px;height:100px;display:inline-block;transform:inherit;top:inherit;left:inherit}
.review_count {font-size:14px;display:inline-block;vertical-align:middle;color:#28519C;margin-left:3px;cursor:pointer;font-weight:500}
.itmbox span {line-height:1.5}
.itmbox .p_num {font-size:16px;}
.itmbox .offi_name {display:block;font-size:18px;font-weight:600}
.itmbox .offi_name .offi_num {font-size:16px;font-weight:400;margin-left:5px;}
.itmbox .p_num, .cardbox .offi_name, .cardbox .offi_addr {display:block}
.itmbox .subj {font-size:17px;font-weight:bold;line-height:1.5}
.itmbox .intro_ytube {margin-top:10px;}
.itmbox .intro_ytube iframe {width:100%;}
.agentpage .itmbox:first-child {text-align:center}
.empl_lst {display:flex;flex-wrap:wrap;flex:auto;}
.empl_lst li {display:inline-block;width:110px;padding:10px 5px;text-align:center;box-sizing:border-box;}
.empl_lst li .thumbnail_area {width:70px;height:70px;}
.empl_lst .agent_info span {display:block;text-align:center;}
.empl_lst li .empl_type {font-size:14px}
.review_lst li {padding:15px 0;border-bottom:1px solid #e9e9e9}
.review_lst .cont {padding:10px 0 7px}
.review_lst .date {font-size:14px;color: color(normal, 030);}
.view_more {text-align:center;display:block;}
.view_more {text-align:center;display:block;}
.view_more button {font-size:15px;display:inline-block;padding:5px 20px}
.business-img {padding:20px 0 30px}
.business-img .react_lightgallery_item {display:inline-block;margin:3px;width:calc((100% - 18px) / 3);height:120px;vertical-align:middle;position:relative;}
.lst_attached .react_lightgallery_item li {width:100%;margin:0;    }
.f_text {font-size:13px;width:100px;text-align:center;position:absolute;bottom:-23px;left:50%;transform:translatex(-50%);color:#a6a6a6}
.deposit {font-size:30px}
.pay_method {overflow:hidden;margin-top:10px}
.pay_method li {display:inline-block;float:left;border:1px solid #d2d2d2;width:50%;box-sizing:border-box;text-align:center;padding:20px}
.pay_method li:last-child {border-left:none}
.pay_method li i {display:block;width:24px;height:24px;margin:0 auto}
.pay_method li.card i {background:url(../images/ico_card.png) no-repeat;}
.pay_method li.v_account i {background:url(../images/ico_acc.png) no-repeat;}
.pay_method li.on {border:1px solid #1e1e1e}
.pay_method li:last-child.on:after {margin-left:0}
.info_gp + .m_exp {margin-bottom:15px}
.kr {text-align:right;color:#888;font-size:14px;padding-top:5px}
.ag_intro .cardbox .pic_box {margin-left:-20px;margin-right:-20px}
.lyrpop01 .btn_cirtf {height:45px}
.review_lst {margin-bottom:10px}

/*집구하기*/
/*#wrap.find {display:flex;flex-direction:column}*/
.find .container {padding:0;height:100vh}
/*.find .header {position:fixed;z-index:inherit}*/
#wrap.find {display:flex;display:-ms-flexbox;flex-direction:column;-ms-flex-direction:column;position:fixed;height:100vh;width:100vw;}
.find .header {position:absolute;}
.find .header .logo_link {position:absolute;top:0;left:0;z-index:10}
.find .header .logo_link a {display:inline-block;text-indent:-9999px;background:url(../images/logo02.svg) no-repeat center;width:50px;
  height:55px;}
.find .header .btn-right {/*z-index:inherit*/}
.filter-section {text-align:center;}
.find .header .sch-container {display:inline-block;width:calc(100% - 100px);/*padding-right:50px;padding-left:50px;*/box-sizing:border-box;position:relative;/*z-index:200*/}
.user_area {display:inline-block;/*z-index:202*/}
.alarm {position:relative}
.find .header .sch-container .inp {border:none;height:55px}
.find .header .sch-container .inp:focus {border:none}
/*.find .container.analysis {z-index:202}*/
.btn_del02 {display:inline-block;width:17px;height:17px;background:url(../images/btn_del03.png) no-repeat;background-size:contain;position:absolute;top:18px;right:55px;text-indent:-9999px}
.ft_tit {font-size:13px;font-weight:400;padding-left:5px;padding-bottom:5px}
.sch-container .box-list {border-bottom:1px solid #ccc;top:55px;max-height:250px;padding:10px 15px}
.sch-container .box-list .sch_word {text-align:left;}
.find .header .sch-container .box-list {width:calc(100% + 100px);left:-50px;top:54px}

/*집구하기 필터*/
.filter-container {position:relative;display:block;/*z-index:199;border-top:1px solid #ccc;*/border-bottom: 1px solid #0000000d;text-align:left;background-color:#fff;width:100%;box-sizing:border-box}
.filter_wrap {height:43px;width:100%;padding:0 10px;box-sizing:border-box;}
.filter_wrap:after {display:block;content:'';clear:both}
.filter_wrap li {float:left;margin:0 5px;line-height:40px}
.filter_wrap li:first-child {margin-left:5px}
.filter_wrap li a {display:block;color:#999999;font-size:16px;letter-spacing:-2px;}
.filter_wrap li a.on {color:#000;font-weight:600;}
.filter_wrap .filter-list {margin:0 10px}
.filter_wrap .ft_selec {font-size:17px;line-height:43px;font-weight:400;color:rgb(21, 18, 18)}
.filter_wrap .ft_selec.on {font-weight:700}
.sub_filter_group {position:absolute;top:43px;left:0;right:0;z-index:201;border-bottom:1px solid #ccc;text-align:left;background-color:#fff;width:100%;box-sizing:border-box;padding:10px 0 50px 0;display:block;border-top:1px solid #ececec}
.filter-group {display:block;padding:0 10px}
.fd-radio, .fd-check {display:flex;}
.fd-radio .rd01, .fd-check .chk01 {display:inline-block;/*float:left*/}
.fd-radio input, 
.fd-check input {display:none}
.fd-radio label,
.fd-check label {display:block;line-height:30px;margin:5px 3px;padding:1px 10px 2px;font-size:14px;background-color:#e7e7e7;box-sizing:border-box;border-radius:30px;}
.fd-radio .rd01>input[type=radio]:checked + label,
.fd-check .chk01>input[type=checkbox]:checked + label 
{background:#F64E23;color:#fff}
/*.fd-radio .rd01>input[type=radio]:checked + label:before,*/
/*.fd-check .chk01>input[type=checkbox]:checked + label:before {display:inline-block;content:'';width:10px;height:10px;background:url(../images/new/ico_check.png) no-repeat;background-size:contain;margin-right:5px;line-height:16px}*/
.filter-btn-area {position:absolute;bottom:0;left:0;width:100%;height:40px;background:#f1f1f1;}
.filter-btn-area .btn-xsmall {display:inline-block;background:none;border-color:#bbb;font-size:13px;}
.filter-btn-area .refresh {position:absolute;left:15px;top:10px}
.filter-btn-area .save {position:absolute;right:15px;top:10px}
.filter-group .btn_space_box {bottom:55px;top:inherit}
.filter_wrap .ft_refresh {font-size:0}
.filter_wrap .ft_refresh:before {font-family:'fontello';content:'\e803';font-size:15px;margin-right:3.5px;}


/*지도버튼*/
.map-btn-group-left {position:absolute;left:15px;top:115px;z-index:203}
.map-btn-group-right {position:absolute;top:115px;right:15px;z-index:50}
.my_location {position:absolute;right:20px;bottom:50px;z-index:19;width:37px;height:37px;cursor:pointer;border:1px solid #bfbfbf;margin-bottom:8px;box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08), -1px 1px 1px rgba(0, 0, 0, 0.04), 1px 1px 1px rgba(0, 0, 0, 0.04);padding:0;font-size:14px;line-height:1;letter-spacing:-.8px;word-wrap: break-all;border-radius:2px;font-weight:500;background:#fff url(../images/btn_my_location.png);}
.ico_exp {display:inline-block;background:url(../images/ico_exp.png) no-repeat;width:37px;height:40px;text-indent:-9999px;margin-bottom:5px;background-size:contain;position:relative}
.map-btn-group-right .icon-help {position:absolute;top:-5px;right:-5px}
.map-btn-group-right .icon-help.icon-help[data-tooltip-text]:hover {position:absolute;} 
.icon-help[data-tooltip-text]:hover:after {width:300px;right:0;white-space: pre-line;}
.subloc .map_vbtn {top:60px}
.around_lst {position:absolute;top:89px;right:42px;padding:10px 15px;border:1px solid #d2d2d2;background:#fff;width:70px;text-align:center;padding-bottom:50px}
.around_lst a {display:block;width:100%;padding:3px 0;font-size:14px;}
.around_lst a.on {color:#c22800;font-weight:bold;}
.around_lst .filter-btn-area .refresh {left:50%;top:50%;transform:translate(-50%, -50%)}
/*.btn-map-control:last-child {margin-bottom:0}*/
.map_vbtn button span {font-size:14px;display:inline-block;font-weight:600}
.btn_view {margin-bottom:5px}
.btn_view.on, .btn_arnd.on {background:#9b2000;border:1px solid #8f1d00;}
.map_vbtn span.on button span {color:#fff;font-weight:400;letter-spacing:-.8px;line-height:1}
.dealprice .tab_style02 {position:absolute;right:0;top:50px;z-index:10}
.itmbox_wrap .tab02 + .tabcon {position:relative}
.mapview_tag {font-size:11px;background:#fff;padding:3px 5px;border:1px solid #999;background-color:#fff;opacity:.9;color:#2b2c2e;display:inline-block;position:absolute;top:60px;left:50%;transform:translatex(-50%)}
.map_location {display:inline-block;background:#fff;border:1px solid rgb(0,0,0,0.3);padding:3px 12px;position:absolute;top:115px;left:50%;transform:translatex(-50%);font-size:12px;border-radius:30px}
.allmap .map_location {top:20px}
.tip-box {position:absolute;top:18px;right:0;display:block;padding:10px;box-sizing:border-box;background-color:#3a3a3a;color:#c1c1c1;border-radius:5px;width:220px;font-size:13px;z-index:10}
.map-btn-group-right .btn-map-control {font-size:12px}
.map-btn-group-right .btn-map-control + div > .btn-map-control {font-size:11px;}
.map-btn-group-right .btn-map-control:last-child i,  .map-btn-group-right button:nth-of-type(3) i {font-size:11px;letter-spacing:-1.2px}
.map-btn-group-right button:nth-of-type(1) + .around_lst {top:45px}
.map-btn-group-right button:nth-of-type(2) + .around_lst {top:87px}

.allmap .map-btn-group-right button:nth-of-type(1) + .around_lst {top:55px}
.allmap .map-btn-group-right button:nth-of-type(2) + .around_lst {top:100px}

// .map-btn-group-left .btn-map-control{background:#0052cc;color:#fff;border:1px solid #1e5c8d}
/*
.map-btn-group-left .btn-map-control:nth-child(2) {background:#3174a8;color:#fff;border-color:#1e5c8d}
.map-btn-group-left .btn-map-control:nth-child(3) {background:#3174a8;color:#fff;border-color:#1e5c8d}
*/
.map-btn-group-left .btn-map-control.on {background:#fff;color:#222;font-weight:600;border:2px solid #222}

/* 매물숫자 집합_동 */
.cluster {position:absolute;background-color:#c73009;opacity:0.9;z-index:11;overflow:visible!important;text-align:center;border-radius:3px;padding:6px 8px;min-width:54px;max-width:90px;/*min-height:30px;*/box-shadow:1px 1px 2px 1px rgba(0, 0, 0, 0.2);box-sizing:border-box}
.cluster .info01 {color:#f1f1f1;font-size:12px;font-weight:bold;white-space:nowrap;line-height:1.2;}
.cluster .info02 {color:#fff;display:block;font-weight:400;font-size:13px;height:auto;line-height:1;text-align:center;white-space:nowrap;margin-top:3px;margin-bottom:2px;}
.cluster.on {background:#fff;opacity:.85;border:3px solid #444;box-sizing:border-box}
.cluster.on>.cluster_inner>span {color:#444;font-weight:bold}
.cluster.on>.cluster_inner>span small {color:#444;}
.cluster.selected {border:1px solid #c73009;background:#fff;color:#c73009}
.cluster.selected .info01 {color:#c73009;font-weight:bold}
.cluster.selected .info02 {color:#c73009;font-weight:500}

/* 매물숫자 집합_아파트 */
.danji_count .real-pin {position:absolute}
.real-pin {position:relative;z-index:10;display:inline-block;width:50px;height:39px;}
.real-pin .like {display:block;background:url("../images/new/icon_like.png") no-repeat;width:20px;height:20px;background-size:contain;position:absolute;top:-8px;left:-8px;}
.pin-type01 {background:url("../images/new/ico_price.png") no-repeat;background-size:contain;}
.pin-type02 {background:url("../images/new/ico_price04.png") no-repeat;background-size:contain;}
.pin-type03 {background:url("../images/new/ico_price03.png") no-repeat;background-size:contain;}
.pin-type04 {background:url("../images/new/ico_price05.png") no-repeat;background-size:contain;}
.pin-type01.on, .pin-type02.on, .pin-type03.on, .pin-type04.on {background:url("../images/new/ico_price_on.png") no-repeat;background-size:contain;color:#222;font-weight:600}
.sell-sale01 {background:url("../images/new/ico_price02.png") no-repeat;background-size:contain;}
.sell-sale02 {background:url("../images/new/ico_sale02.png") no-repeat;background-size:contain;}
.sell-sale03 {background:url("../images/new/ico_sale03.png") no-repeat;background-size:contain;}
.real-pin .info01 {width:50px;/*height:39px;*/text-align:center;color:#fff;/*line-height:31px;*/margin-top:6px;font-size:13px;letter-spacing:-1.2px}
.real-pin .info01 small {display:block;font-size:11px;letter-spacing:0;line-height:1;margin-bottom:-1px;margin-top:-10px;padding-top:3px;color:#fff;font-weight:200}
.real-pin.on .info01, .real-pin.on .info01 small {color:#222}
.real-pin .info02 {position:absolute;top:100%;background-color:rgba(61,53,46,.7);color:#fff;padding:2px 5px;margin-top:2px;left:50%;border-radius:2px;font-size:11px;max-width:120px;/*min-width:40px;*/text-align:center;transform:translateX(-90%);font-weight:300;-o-text-overflow:ellipsis;text-overflow:ellipsis; overflow:hidden;white-space:nowrap;box-sizing:border-box;line-height:1.4}
.real-pin .info03 {position:absolute;top:16px;left:42px;text-align:center;min-width:19px;font-size:12px;min-height:1.2;line-height:18px;/*padding:2px 5px 2px 4px;*/box-sizing:border-box;opacity:.9;border-radius:12px;background-color:#444;border:1px solid rgba(43,43,43,.8);color:#fff;transform: translate(-20%);}
.real-pin.pin-type06 .info03 {left:18px;top:12px;}
.real-pin .info04 {position:absolute;top:-12px;left:0;font-size:10px;padding:0 4px;background:#fff;border-radius:20px;border:1px solid #444;box-sizing:border-box;line-height:17px;font-weight:600;letter-spacing:-.6px}
.real-pin .info05 {position:absolute;top:-10px;left:0px;font-size:8px;padding:0 4px;background:#ff0000;color:#fff;border-radius:20px;box-sizing:border-box;height:15px;line-height:15px;letter-spacing:-.6px}
.real-pin .info06 {position:absolute;top:-10px;right:0px;font-size:8px;padding:0 4px;background:#7d7d7d;color:#fff;border-radius:20px;box-sizing:border-box;height:15px;line-height:15px;letter-spacing:-.6px}
.danji_prev {z-index:200;padding:0}
.high {display:inline-block;background:#ff0000;border-radius:40px;font-size:10px;color:#fff;padding:2px 5px 3px;font-weight:300;margin-right:3px;vertical-align:middle;}
.info01 .danji, .high {vertical-align:middle;}
.cluster .info01 small {color:#fff}
.pin-type06 {background:url("../images/new/ico_price_s.png") no-repeat;background-size:contain;width:20px;height:26px;}
.pin-type06.on {background:url("../images/new/ico_price_s_on.png") no-repeat;background-size:contain;width:20px;height:26px;}
.pin-type06 .info_s {width:20px;height:26px;}
.pin-type06 .info02 {transform:translatex(-70%)}
/*201022 단지명 마우스오버*/
.real-pin.pin-type06 .info02 {display:none}
.real-pin.pin-type06:hover > .info02, .real-pin.pin-type06:active > .info02 {display:block}
/* 주변item */
.ar_posi {position:absolute;width:28px;height:28px;display:inline-block;z-index:10;text-align:center;background-size:contain;/*text-indent:-9999px*/font-size:0}
.posi {width:28px;height:28px;display:inline-block;z-index:101;text-align:center;background-size:contain}
.posi_sch01 {width:22px;height:32px;background:url("../images/new/ico_daycare.png") no-repeat;background-size:contain}
.posi_sch01.on {background:url("../images/new/ico_daycare_on.png") no-repeat;background-size:contain}
.posi_sch02 {width:22px;height:32px;background:url("../images/new/ico_kinder.png") no-repeat;background-size:contain}
.posi_sch02.on {background:url("../images/new/ico_kinder_on.png") no-repeat;background-size:contain}
.posi_sch03 {width:22px;height:32px;background:url("../images/new/ico_element.png") no-repeat;background-size:contain}
.posi_sch03.on {background:url("../images/new/ico_element_on.png") no-repeat;background-size:contain}
.posi_sch04 {width:22px;height:32px;background:url("../images/new/ico_mid.png") no-repeat;background-size:contain}
.posi_sch04.on {background:url("../images/new/ico_mid_on.png") no-repeat;background-size:contain}
.posi_sch05 {width:22px;height:32px;background:url("../images/new/ico_high.png") no-repeat;background-size:contain}
.posi_sch05.on {background:url("../images/new/ico_high_on.png") no-repeat;background-size:contain}
.ar_posi .type, .posi .type {position:absolute;bottom:-20px;left:-5px;right:0;font-size:13px;display:inline-block;font-weight:bold;letter-spacing:-.7px}
.posi_bus {background-image:url(../images/ico_bus.png);}
.posi_subway {background-image:url(../images/ico_subway.png);}
.posi_hospt {background-image:url(../images/ico_hos.png);}
.posi_mart {background-image:url(../images/ico_mart.png);}
.posi_bank {background-image:url(../images/ico_bank.png);}
.posi_public {background-image:url(../images/ico_public.png);}
.surr_name {display:none;background:#fff;border:1px solid #ddd;padding:3px 5px;font-size:12px;box-sizing:border-box;max-width:200px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
.ar_posi.on .surr_name {display:block;position:absolute;top:-25px;box-sizing:border-box;transform: translatex(-50%);left: 50%;}

/*단지프리뷰*/
.danji_prev .btn-close-small, .school_prev .btn-close-small {position:absolute;right:0;top:0;z-index:1;background: url(../images/btn-close.png) no-repeat;background-size:contain;width:40px;height:45px;}
.danji_prev .item-card-container .item-card {margin-top:0;display:flex;flex-direction:column}
.item-card {padding:15px;min-height:40px;position:relative;background:#fff;box-sizing:border-box}
.danji_prev .itm_exp .ex_info {color:#6C6C6C}
.danji_prev .price i {color:#969696}
.item-card.onsale {min-height:100px;padding-right:15px}
.item-card.movein {min-height:115px;}
.sale-step {font-size:19px;padding-top:5px;color:#c22800;font-weight:600;display:block}
.item_thumb {background-size:contain;background-repeat:no-repeat;position:absolute;top:15px;right:15px;width:140px;height:110px;display:inline-block;box-sizing:border-box}
.item_thumb img {width:100%;height:100%;object-fit:cover;object-position:50%}
.graph-box {position:absolute;top:50px;right:15px;width:38%;height:110px;display:inline-block;box-sizing:border-box}
.graph-box img {width:100%;height:100%;object-fit:contain;object-position:50%}
.graph-box .tag {display:inline-block;font-size:11px;background:#8e8e8e;border-radius:2px;color:#fff;padding:1px 4px;position:absolute;right:0;font-weight:200}
.prelst .itm_pic {top:-10px}
.item-card .item_info {display:block;width:100%;text-align:left;margin-top:5px;padding-right:43%;box-sizing:border-box}
.item-card.movein .item_info {width:100%;}
.item-card.movein .item_count {position:static;margin-top:8px}
.item-card .bk_name {font-size:17px;font-weight:600;line-height:1.1}
.item-card .p_num {font-size:15px}
.item_info .info01 {font-size:15px;line-height:1.3;margin-bottom:5px}
.item_info .info02 span {margin-right:3px;}
.item_info .info02 span:last-child {margin-right:0;}
.deal_type b {margin-right:3px}
.deal_type .type01, .real-price .type01 {color:#bf3433;font-weight:bold}
.deal_type .type02, .real-price .type02 {color:#5d69bf;font-weight:bold}
.deal_type .type03, .real-price .type03 {color:#43c750;font-weight:bold}
.deal_type i, .deal_price i {color:#222;font-weight:600}
.deal_price i {margin-left:7px}
.item_info .area, .item_info .ex_info {font-size:14px;color:#000;font-weight:400}
.deal_price {font-size:16px;display:block;}
.deal_price b {display:block;font-weight:400;/*padding-bottom:3px;*/}
.txt_noti {display:inline-block;background:#444;opacity:.8;position:absolute;left:50%;bottom:50%;transform:translate(-50%);z-index:99;border-radius:50px;box-sizing:border-box;width:250px;text-align:center;height:40px;vertical-align:middle}
.txt_noti span {color:#fff;font-size:13px;opacity:1;width:100%;text-align:center;vertical-align:middle;line-height:1.3;display:inline-block;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.info_tag {display:inline-block;font-size:10px;background:#ccc;border-radius:50px;padding:0 5px;color:#fff}
// .mark {display:inline-block;font-size:12px;background:#9b958e;color:#fff;font-weight:400;padding:1px 3px 2px;margin-bottom:3px;}
.danjiname {font-size:18px;vertical-align:middle}
.danji_prev .danjiname, .item-card .danjiname {font-size:19px;display:inline-block;max-width:70%;vertical-align:middle;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-weight:600}
.info01 .danji {letter-spacing:-1px;display:inline}
.favo_area {position:absolute;top:20px;right:20px;z-index:99}
.btn-favo {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon/ico_favorite_wh%3C/title%3E%3Cdefs%3E%3Cpath d='M12.6030135,5.92866362 C13.517319,5.01392878 14.7576411,4.5 16.050968,4.5 C17.3442949,4.5 18.584617,5.01392878 19.4989226,5.92866362 C20.4134085,6.84272021 20.9273372,8.08304233 20.9273372,9.37636922 C20.9273372,10.6696961 20.4134085,11.9100182 19.4987981,12.8241993 L12.8687981,19.4541993 C12.5759049,19.7470925 12.1010312,19.7470925 11.8081379,19.4541993 L5.17813793,12.8241993 C3.273954,10.9200153 3.27395403,7.83272313 5.17813798,5.92853917 C7.08232194,4.02435522 10.1696141,4.02435519 12.0737981,5.92853913 L12.338468,6.19320905 L12.6030135,5.92866362 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='icon/ico_favorite_wh' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Rectangle' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Shape' stroke='%23FFFFFF' stroke-width='1.5' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
	background-size: contain;
	cursor: pointer;
	margin-top:-5px;
}
.btn-favo.on {
	width: 24px;
	height: 25px;
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon/ico_favorite_on%3C/title%3E%3Cdefs%3E%3Cpath d='M12.6030135,5.92866362 C13.517319,5.01392878 14.7576411,4.5 16.050968,4.5 C17.3442949,4.5 18.584617,5.01392878 19.4989226,5.92866362 C20.4134085,6.84272021 20.9273372,8.08304233 20.9273372,9.37636922 C20.9273372,10.6696961 20.4134085,11.9100182 19.4987981,12.8241993 L12.8687981,19.4541993 C12.5759049,19.7470925 12.1010312,19.7470925 11.8081379,19.4541993 L5.17813793,12.8241993 C3.273954,10.9200153 3.27395403,7.83272313 5.17813798,5.92853917 C7.08232194,4.02435522 10.1696141,4.02435519 12.0737981,5.92853913 L12.338468,6.19320905 L12.6030135,5.92866362 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='icon/ico_favorite_on' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Rectangle' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Shape' fill='%23FF0000' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
	background-size: contain;
	margin-top:-5px;
}
.item_info span.high, .item_info span.sell-type-tag {display:inline-block;vertical-align:middle}
.item_info .addinfo {font-size:14px;}
.item_info .addinfo b {font-weight:400;}
/*.danji_prev .addinfo {margin-top:5px;}*/
.danji_prev .d_info03 {margin-top:3px;}
.danji_prev .onsale .addinfo {margin-top:0}
.item_count {/*position:absolute;right:15px;top:132px;*/font-size:16px;margin-top:3px}
.onsale .item_info {display:block;width:100%;text-align:left;}
.d_info01, .d_info02, .d_info03 {display:block;}
/*.d_info01 {margin-bottom:3px;}*/
.d_info03 span {font-size:14px;display:inline-block}
.warning {padding:5px;background:#ffecec;color:#c71616;font-size:13px;margin-top:7px;text-align:center;border-radius:2px;}
.warning:before {display:inline-block;content:'';background:url("../images/ico_wrarn.png") no-repeat;width:12px;height:12px;background-size:contain;margin-right:5px;vertical-align:middle}
.d_info01 .favo_area {display:inline-block;position:static;vertical-align:middle;/*margin-left:3px;*/}

/*필터*/
.filter_cont .inpbox {margin-bottom:20px}
.btn_reset {display:block;padding:10px 15px;position:absolute;bottom:0;width:100%;background:#f1f1f1;left:0}
.btn_reset button {font-size:14px}
.btn_reset button span::before {font-family:'FontAwesome';content:"\f0e2";margin-right:8px}
.btn_ftclose {position:absolute;bottom:10px;right:10px}
.btn_ftclose button {display:inline-block;position:relative;}
.btn_ftclose button span {font-size:14px}
.btn_ftclose button span::after {font-family:'FontAwesome';content:"\f077";margin-left:3px}

/**단지상세**/
.itmbox {background:#fff;padding:20px;position:relative;margin-bottom:10px;font-size:16px}
/*.itmbox.danjibasic {background:#a83d21;color:#fff;margin-bottom:0}*/
.panel-header .mlst_tit .danjiname {color:#222;font-weight:400}
.panel-header .mlst_tit .address {color:#222;font-weight:300}
/* .panel-header .mlst_tit .btn-favo {margin-left:3px;} */
.danjidetail .panel-box {margin-top:0;padding-bottom:60px;padding-top:55px;}
.slider-container {height:270px;position:relative}
.slider-container ul {width:100%;height:270px;display:flex;flex-flow:column wrap;flex-basis:auto}
.slider-container ul li {width:100%;height:270px;overflow:hidden}
.slider-container ul li img {width:100%;height:100%;object-fit:cover;object-position:50%;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);max-width:100%;display:block;margin:auto;}
.swiper {position:absolute;bottom:15px;left:50%;margin-left:-30px;display:inline-block;background:#000;opacity:.7;border-radius:20px;width:60px;padding:5px 0;color:#fff;font-size:12px;font-weight:100;text-align:center}
.itmbox .deal_price {font-size:18px;font-weight:600;line-height:1.3;}
.itmbox .info02 span {margin-right:10px;font-size:16px}
.price {font-size:18px;font-weight:bold}
.itmbox .area, .itmbox .addinfo {font-size:15px;margin-top:0}
.itmbox.dealprice {padding:20px 0}
.itmbox.dealprice .tit {padding:0 20px}
.itmbox.dealprice .itmbox_inner {padding:10px 20px 0}
.itmbox.dealprice .scroll-menu-container {padding:15px 20px 0;box-sizing:border-box;margin-bottom:10px;border-bottom:1px solid #eaeaea}
.itmbox.dealprice .scroll-menu-container .ul-container {width:2000px;height:auto;margin-bottom:0;}
.itmbox.dealprice .scroll-menu-container .ul-container .scroll-li {margin-right:0}
.itmbox.dealprice .scroll-menu-container .ul-container .scroll-link {height:40px;text-align:center;margin-right:20px;font-size:15px}
.itmbox_inner {position:relative}
.itmbox_inner .btn_space_box {top:13px;z-index:10}
/*.itmbox_inner .area {display:block;}*/
.itmbox .tit {display:block;/*padding-left:20px;*/padding-bottom:18px;color:#222;/*padding-top:5px;*/font-size:15px;font-weight:500}
.itmbox .tab_con {position:relative}
.graph_area {width:100%;height:200px;}
.price-group {margin-bottom:12px;position:relative}
.price-group .price {font-size:24px;font-weight:bold;}
.l_price {position:absolute;right:0;top:-5px}
.l_price:after {display:block;content:'';clear:both}
.l_price span {float:left;font-size:22px;}
.l_price span:last-child {margin-top:4px;font-weight:bold}
.l_price span:first-child {font-size:15px;margin-right:5px}
.l_price span i {display:block;font-size:11px;margin-bottom:-3px}
.real_detail {margin-top:10px}
table.list {border:1px solid #e5e5e5;border-collapse:separate;border-top:0;border-spacing:0;}
table.list tr {height:32px;background-color:#fafafa;}
table.list tr td {position:relative;text-align:center;font-size:13px;font-weight:narmal;color:#444;vertical-align:middle;border-top:1px solid #e5e5e5;}
.real_detail table.list tbody tr {background:#fff;}
.real_detail .btn_more-price {position:relative;display:block;height:36px;line-height:36px;text-align:center;background-color:#fff;border:1px solid #e5e5e5;border-top:0;font-size:14px}
.go_gov {display:inline-block;position:absolute;right:10px;top:7px;font-size:13px;padding-right:20px;background:url(../images/ico_arr02.png) no-repeat right center}
.box-basicinfo ul {display:block;}
.box-basicinfo ul:after {display:block;content:'';clear:both}
.box-basicinfo li {display:inline-block;float:left;width:50%;box-sizing:border-box;margin-bottom:2px;font-size:15px}
.box-basicinfo li:before {display:inline-block;content:'';width:4px;height:4px;background:#a40000;margin-right:10px;margin-bottom:3px}
.scroll-menu-container {width:100%;white-space:nowrap;position:relative}
.scroll-menu-container:after {display:block;content:'';clear:both}
.ul-container {padding:12px 70px 5px 20px;width:100%;/*overflow-y:hidden;*/overflow-x:scroll;box-sizing:border-box;height:60px;line-height:50px;background:#fff;margin-bottom:10px;/*display:flex;*/}
.ul-container:after {display:block;content:'';clear:both}
.itmbox .ul-container {padding:0;height:40px;line-height:40px} 
.scroll-li {display:inline-block;float:left;margin-right:20px;position:relative;/*height:40px;line-height:40px*/}
.scroll-li .space-tab {color:#666;font-size:14px;}
.scroll-li .space-tab.on {font-weight:600;color:#222}
.r_deal_price span, .real-price span {display:inline-block;vertical-align:middle}
.r_deal_price .high {font-size:13px;padding:1px 5px 2px;}
.scroll-li .high {/*font-size:8px;line-height:12px;*/width:5px;height:5px;position:absolute;top:10px;left:0;padding:0;margin-right:0;text-indent:-9999px}
.real-price .high {padding:1px 3px 2px;line-height:12px;height:12px;font-size:9px}
.vGraph{padding:20px 0;}
.vGraph ul{margin:0;padding:0;height:60px;border:1px solid #ddd;border-top:0;border-right:0;border-left:0;font-size:11px;}
.vGraph ul:after{content:"";display:block;clear:both;}
.vGraph li{float:left;display:inline-block;width:calc(100%/6);height:100%;position:relative;text-align:center;white-space:nowrap;padding:0 3%;box-sizing:border-box;}
.vGraph .gTerm{position:relative;display:inline-block;width:100%;height:20px;line-height:20px; margin:0 -100% -20px 0;padding:60px 0 0 0; vertical-align:bottom;color:#767676;font-weight:bold;}
.vGraph .gBar{position:relative;display:inline-block; width:100%;margin:-1px 0 0 0;background:#c22800;vertical-align:bottom;}
.vGraph .gBar span{position:absolute;width:100%;top:-20px;left:0; color:#767676;font-size:14px}
.itmbox .value {font-size:20px;position:absolute;right:18px;top:15px;font-weight:600}
/*.public_price {margin-bottom:10px;height:25px}*/
.public_price:after {display:block;content:'';clear:both}
.public_price span {vertical-align:inherit}
.public_price .value {font-size:18px;font-weight:bold;position:static;margin-top:-5px;margin-left:20px;}
.info_align {display:block;width:100%}
.info_align.align02 {margin-top:15px}
.info_align li {display:flex;flex:auto;align-items:center;width:100%;font-size:15px;margin-bottom:5px}
.info_align.align02 li{display:inline-block;width:50%;font-size:15px}
.info_align li:last-child .content {font-weight:400}
.info_align li span {display:inline-block;vertical-align:middle}
.info_align .content {/*display:block;*/font-size:16px;font-weight:600;margin-left:20px}
.icon-help {display:inline-block;width:14px;height:14px;background:url("../images/new/ico_tooltip.png") no-repeat;background-size:contain;margin-left:3px;vertical-align:middle}
[data-tooltip-text]:hover {/*position:relative;*/z-index:11}
[data-tooltip-text]:hover:after {content:attr(data-tooltip-text);position:absolute;top:100%;background-color:#3a3a3a;color:#c1c1c1;border-radius:5px;margin-top:8px;padding:10px 10px;font-size:13px;font-weight:normal;max-width:230px;min-width:160px;z-index:9999;width:180px}
.tax-info .selec {height:38px;width:90px}
.tax-info .add-text {font-size:14px;color:#666}
.tax-info .info_align li {margin-bottom:2px}
.tax-info .icon-help[data-tooltip-text]:hover:after {right:inherit;}
.left [data-tooltip-text]:hover:after {left:0;}
.right [data-tooltip-text]:hover:after {right:0;}
.center [data-tooltip-text]:hover:after {left:0;}
.itmbox.tax-info .value {top:-44px;right:0}
.ag-group .ag-li {display:flex;flex:auto;align-items:flex-start;justify-content:space-between;margin:5px 0}
.ag-group .ag-li .ag-info {width:calc(100% - 55px)}
.ag-group .ag-li span {display:block;vertical-align:middle}
.ag-group .ag-li .address {font-size:14px;color:#afafaf}
.ag-info .name {font-size:16px;font-weight:500}
.btn-call  {display:inline-block;padding:1px 7px 6px;width:55px;box-sizing:border-box;background:#b9342a;border-radius:50px;text-align:center;position:relative}
.ag-group .ag-li .btn-call span {background:url("../images/ico_phone.png") no-repeat;display:inline-block;width:13px;height:13px;background-size:contain;text-indent:-9999px;vertical-align:middle}
.edu-group .edu-li {margin:5px 0}
.edu-group .edu-li:after {display:block;content:'';clear:both}
.edu-group .edu-li a {display:flex;flex:auto;align-items:flex-end}
.edu-group .edu-li a span {display:inline-block;vertical-align:middle}
.edu-group .edu-li .edu-info {width:80%}
.edu-info .name {font-size:17px;margin:0 5px}
.edu-info .distance {font-size:14px;color:#9d9d9d}
.edu-info .type {display:inline-block;vertical-align:middle;font-size:12px;padding:0px 5px 1px;border-radius:20px;border:1px solid #484848;line-height:1.4;background:#fff}
.edu-group .edu-li .edu-right {width:20%;text-align:right;position:relative;font-size:20px;font-weight:600}
.edu-group .caption {position:absolute;right:0;top:-10px;font-size:12px;color:#666;font-weight:400}
.daycare .edu-group .edu-li .edu-right {font-size:17px;font-weight:500;padding-bottom:2px}
.btn_moreview {font-size:14px;width:100%;height:22px;line-height:22px;box-sizing:border-box;display:block;text-align:center;margin-top:10px}
.scroll-link {display:block;height:50px;box-sizing:border-box;padding-bottom:8px;color:#A2A2A2}
.scroll-link .noh {display:block;position:absolute;bottom:5px;left:50%;transform:translatex(-50%);font-size:11px;line-height:1;}
.scroll-link.select {font-weight:bold;color:#000;border-bottom:2px solid #333;}
.tit03 .scroll-li {line-height:inherit;height:inherit;}
.sell-type-tag {display:inline-block;border-radius:40px;font-size:11px;color:#fff;padding:2px 5px 3px;font-weight:300;margin-right:3px;text-align:center;box-sizing:border-box;}
.prvtype02 .itm_inner {min-height:100px}
.prvtype02 .itm_info {width:100%;}
.tag01 {background:#85c23b}
.tag02 {background:#249b58}
.tag03 {background:#1f874d}
.tag04 {background:#34b5e0}
.tag05 {background:#ff8d44}
.tag06 {background:#5827d8}
.tag07 {background:#ffd200}
.pin-type02 .competi {line-height:3.2}
.pin-type02 .caption {display:block;font-size:8px;padding-top:2px;font-weight:400;color:#fff;margin-top:-13px}
.txt_red {color:#c22800;}
.info01 .like_area {display:inline-block;position:inherit;left:inherit;top:inherit;right:inherit;vertical-align:middle;margin-left:5px}
.danji_count .txt_noti {left:50%;top:50%;transform:translate(-50%, -50%);}
.title_cont {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.scroll-menu-container .btn_space_box {top:-20px;}
.scroll-menu-container .ul-container {display:block;padding-top:0}

.tblbox li {padding-right:140px;position:relative;margin-bottom:7px;}
.tblbox li:last-child {margin-bottom:0}
.tblbox li a {display:block}
.tblbox li span {margin-right:5px}
.tblbox li span:first-child {font-size:15px}
.tblbox li span:nth-child(2) {font-size:14px;color:#9d9d9d;margin-right:0}
.tblbox li .right {position:absolute;right:0;top:0;}
.tblbox li span.area {font-size:14px;color:#9d9d9d}
.tblbox li span.price {color:#222;font-size:16px;font-weight:bold;}
.box_count {font-size:18px;font-weight:bold}
.box_count b+span {font-weight:500}
.box_add {float:right;color:898989;font-size:14px;margin-top:5px}
.caption.up {font-size:15px;color:#9D9D9D;display:block;}
.box_count .small {font-size:14px;color:#9d9d9d;font-weight:500;}
.school .itmbox_wrap:first-child, .score .itmbox_wrap:first-child {margin-top:10px}
.school .itmbox_wrap:last-child {margin-bottom:10px}
.chart_area {text-align:center;padding:20px 0 10px}
.pie-chart {display:inline-block;position:relative;width:150px;height:150px;background:conic-gradient(#c22800 0% 25%, #ef6845 25% 56%, #edb1b1 56% 100%);border-radius:50%;}
.pie-chart .center{position:absolute;width:80px;height:80px;background:#fff;border-radius:50%;top:50%;left:50%;transform:translate(-50%, -50%)}
.bar-chart {height:30px;background:linear-gradient(to right, #ababab 80%, #eaeaea 20%);}
.chart_value {overflow:hidden;margin-bottom:5px}
.value_left {float:left;font-size:14px;color:#9d9d9d}
.value_right {float:right;font-size:14px;color:#9d9d9d}
.itmnaver_info .list {border:none;border-bottom:1px solid #eaeaea;}
.itmnaver_info .list .t_header {background:#44cc5b;}
.itmnaver_info .list .t_header td {color:#fff;border:none}
.itmnaver_info .list tr {border-bottom:1px solid #eaeaea;height:36px;}
.itmnaver_info .list td {text-align:center;font-size:13px}
.itmnaver_info .list tbody tr td:nth-child(4) {font-weight:bold}
.itmbox h3 b {font-weight:600}
.btn-itmnaverdt {background:#44cc5b;color:#fff}
/*.btn-itmnaverdt:before {display:inline-block;content:'';background:url(../images/ico_n02.png) no-repeat;background-size:contain;width:14px;height:14px;margin-right:5px;margin-top:-3px;vertical-align:middle}*/
h3 .btn-xsmall {margin-top:2px;}
.itmnaver_info .add_text {color:#555;margin-top:5px;}

/*분양단지리스트*/
.onsalelst .header {background:#fff;border-bottom:1px solid #d6d6d6}
.sub-filter02 .sub_filter_group {padding:18px;}
.sub_filter_group.inp-filter {padding:0}
.sub_filter_group.inp-filter form {padding:15px}
.sub_filter_group.inp-filter .check_box02>.chk01>input[type=checkbox] + label {font-size:15px}
.onsalelst .onsale .sale-step {font-size:15px;color:#717171;font-weight:500;padding-top:0}
.onsalelst .danjiname {font-weight:600;font-size:20px;display:inline-block;}
.caption {position:absolute;right:15px;top:50%;transform:translateY(-50%);color:#c22800}
.caption span {display:block;text-align:right}
.caption .extension {font-size:13px;}
.caption .cont {font-size:20px;font-weight:600;line-height:1.2}
.onsalelst .onsale .danji {display:block;margin-top:3px;font-size:19px;}
.btn-filter_close {position:absolute;right:15px;bottom:8px;font-size:15px;}
.btn-filter_cancel {position:absolute;left:15px;bottom:8px;font-size:15px;}
.onsalelst .title {font-weight:600;font-size:17px}
.onsalelst .panel-group .filter-container {z-index:100}

/*분양상세*/
.onsaledetail .panel-box {margin-top:0;padding-top:55px;}
.map-itmbox .pic_box {height:220px;}
.danji_img {position:relative}
.danji-location {height:230px;}
.danji-location img {width:100%;height:230px;object-fit:cover;object-position:50%;}
.schedule-box {padding-left:0;padding-right:0}
.schedule-box .tit {padding-left:20px;padding-right:20px}
.price-group .caption {margin-top:-8px;right:0}
.schedule-lst {width:100%;}
.schedule-lst-group {display:table;width:100%;padding:5px 20px 5px;box-sizing:border-box}
.schedule-lst span {display:table-cell;}
.schedule-lst.expired span {color:#ACACAC}
.schedule-lst.dday {background:#c22800}
.schedule-lst.dday span {color:#fff}
.schedule-lst .date {width:35%;text-align:center}
.schedule-lst .detail {width:45%}
.schedule-lst .today {width:20%}
.schedule-lst .stage {width:20%}
.onsale .add-info {position:absolute;right:0;top:12px}

/*재건축*/
.reconstrlst {height:auto;}
.reconstrlst .header {background:#fff;border-bottom:1px solid #d6d6d6}
.reconstrdetail .panel-box {margin-top:0;padding-bottom:60px;/*padding-top:55px*/}
.reconstrlst .item-card.reconstr {min-height:100px}
.reconstrlst .item-card.reconstr .item_info {width:100%;padding-right:0}
.reconstrlst .danjiname {font-weight:600;font-size:20px;display:inline-block;}
.re_step {margin-bottom:5px;}
.re_step b {color:#c22800}
.re_step b {color:#c22800}
.reconstrlst .reconstr .info01 .favo_area {display:inline-block;position:static;vertical-align:middle;margin-left:5px;margin-top:-5px;}
.reconstrlst .reconstr .sale-step {font-size:16px;color:#666}
.info {margin-bottom:10px;padding:0 20px;display:flex;flex-direction:row;flex-wrap:wrap;width:100%;box-sizing:border-box}
/*
.info span:nth-child(1) {width:40%}
.info span:nth-child(2) {width:60%}
.info span:nth-child(3) {width:100%}
*/
.schedule-box .info span {width:100%;margin-bottom:3px}
.schedule-box .info span .bolder {margin-left:10px}

/*
.info:after {display:block;content:'';clear:both}
.info span {float:left}
.info span:nth-child(2) {float:right}
*/
.line {height:1px;display:block;width:100%;margin:10px 0;background:#e5e5e5;}
.emphasis {background:#b52500;color:#fff;background-image:linear-gradient(-90deg, #b52500, #e47732);margin-bottom:15px;padding:10px 20px}
.emphasis .tit {color:#fff;font-size:15px;padding-bottom:0;margin-bottom:-3px;}
.go_score .small {color:#f9f9f9;font-size:12px;}
.go_score .small i {color:#ffe400;font-style:italic}
.go_score {display:block}
.go_score .value {font-size:22px;font-weight:500;color:#fff;top:50%;transform:translatey(-50%)}
.go_score .blind {display:inline-block;padding:3px 15px 5px;border-radius:50px;background:#8c2106;color:#ffe400;}
.emphasis.other {background:#b9b9b9;padding:15px 20px}
.reconstr .btn-favo {position:absolute;right:15px;top:50%;transform: translatey(-50%);}
.danji_prev .reconstr .btn-favo {position:static;right:inherit;top:inherit;transform:none}

/*190409 입주예정 타입별 정보*/
.moveindetail .panel-box {margin-top:0;padding-bottom:60px;padding-top:55px}
.moveindetail .panel-box>.itmbox:first-child {margin-top:0}
.moveindetail .panel-box .itmbox .sale-step {font-size:20px;padding-top:0}
.type_group {margin-bottom:20px;border-bottom:1px solid #e1e1e1}
.type_info:after {display:block;content:'';clear:both;margin-bottom:10px}
.type_info.subj {font-size:13px;border-top:1px solid #1e1e1e;padding:7px 0;border-bottom:1px solid #e1e1e1;padding-bottom:0;margin-bottom:8px}
.type_info dt, .type_info dd {display:inline-block;float:left;line-height:16px;font-size:13px;text-align:center}
.type_info dt {font-size:16px;width:30%}
.type_info dd {width:20%}
.type_info dd:last-child {width:50%}
.addinfo02 .exp01 {color:#0099d9}

/*학교*/
.school_prev .item-card {min-height:50px;padding:0}
.school_prev .itm_inner .itm_info {width:100%}
.school_prev .info01 {font-size:22px;margin-bottom:5px}
.school_prev .info01 span {display:inline-block;vertical-align:middle}
.school_prev .schoolname {color:#222}
.type_line {font-size:13px;display:inline-block;padding:2px 7px;border:1px solid #bfbfbf;border-radius:20px;margin-right:5px}
.school_prev .tel {color:#818181;margin-top:3px;display:block;font-size:15px;}
.school_prev .tel b, .school_prev .add-info b {color:#222;margin-left:5px}
.school_prev .caption {right:0;color:#666}
.caption .tag {font-size:17px;color:#c22800;margin-bottom:8px} 
.school_prev .tel + .add-info {font-size:15px;color:#818181}

/* 학교상세 */
.schooldetail .panel-box {margin-top:0;padding-top:65px}
.panel-header .tag06, .panel-header .tag07 {background:none;border:1px solid #fff;}
.schoolname {font-size:18px;display:inline-block;vertical-align:middle;color:#fff;font-weight:500}
.basicinfo li {width:100%;display:block;margin-bottom:5px;}
.basicinfo li:after {display:block;content:'';clear:both}
.basicinfo li span {display:inline-block;float:left;font-size:15px}
.basicinfo li span:first-child {width:25%;color:#9d9d9d;font-size:14px}
.basicinfo + .school-info {padding-top:20px;margin-top:20px;border-top:1px solid #ededed}
.school-info {display:flex;flex:auto;justify-content:space-between;align-items:flex-end;line-height:1.4}
.school-info + .school-info {margin-top:15px}
.school-info .caption {position:static;transform:none;font-size:13px;color:#666;padding-bottom:3px}
.school-info .content span:first-child {display:block;font-size:13px;font-weight:400;}
.school-info .count {font-size:18px;}
.school-info small.detail {font-size:13px;font-weight:400;margin-left:0}
.school-info .value {position:static}
.go_link {color:#7a1d05;font-size:16px;text-align:center;display:block}
.basicinfo li span .go_link {font-size:15px;text-align:left}
.school-info .content span:first-child.s_detail {display:inline-block}
.schooldetail .basicinfo li span:last-child {width:75%}

/*201228 입지분석추가 */
.itmbox.location {padding-left:0;padding-right:0}
.itmbox.location>.tit {padding-left:15px;}
.itmbox.location .maparea {height:220px;border:0;position:relative;margin-top:0}
.itmbox.location .content {margin-top:10px;padding:10px 15px}
.itmbox.location .content li {display:block;margin:14px 0;}
.itmbox.location .content li:last-child {margin-bottom:0}
.itmbox.location .content li .tit {display:block;cursor:pointer;margin-bottom:8px;padding-bottom:0;background: #fff url(../images/btn_arr01.png) no-repeat center right 0;}
.itmbox.location .content li .tit.active {background: #fff url(../images/btn_arr02.png) no-repeat center right 0;}
.itmbox.location .content li .tit .txt_loc {font-size:15px;font-weight:400;margin-left:3px;color:#222}
.itmbox.location .content li .detail {display:block;margin-bottom:20px}
.itmbox.location .content li .detail .sb_detail {display:block;font-size:14px;line-height:1.4;margin:5px 0;position:relative}
.itmbox.location .content .loc01 .img_loc {display:inline-block;background:url(../images/ico_loc01.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .loc02 .img_loc {display:inline-block;background:url(../images/ico_loc02.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .loc03 .img_loc {display:inline-block;background:url(../images/ico_loc03.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .loc04 .img_loc {display:inline-block;background:url(../images/ico_loc04.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .loc05 .img_loc {display:inline-block;background:url(../images/ico_loc05.png) no-repeat;width:16px;height:16px;background-size:contain;background-position:50%}
.itmbox.location .content .img_loc, .itmbox.location .content .txt_loc {display:inline-block;vertical-align:middle;line-height:1.5}
.itmbox.location .content li .detail .more_view {position:absolute;top:0;right:0;font-size:14px;}
.itmbox.location .content li .more_cont {padding:10px 10px 15px;background:#f3f3f3;margin-top:8px;margin-bottom:5px;width:100%;box-sizing:border-box}
.itmbox.location .content li .detail .more_cont {display:inline-block}
.itmbox.location .content li .detail span .more_cont span {display:inline-block}
.itmbox.location .content li .detail span .more_cont .edu-li {margin:0}
.location .content li .detail span .more_cont .edu-li .name {margin-right:5px}
.itmbox.location .content li .detail span .more_cont .edu-li .edu-right {font-size:16px;}
.itmbox.location .content li .detail .more_cont .btn_moreview {margin-top:5px}
.itmbox.location .content .sb_detail li {margin:3px 0;}
.itmbox.location .content .sb_detail li a {position:relative}

/* 210302 단지시설 추가 */
.danjiinfo_grp {overflow:hidden;display:table;}
.danjiinfo_grp span {display:table-cell;width:25%;float:left;/*padding:5px 15px;*/text-align:center;/*margin:2px;border:1px solid #efefef;*/border-radius:30px;font-size:14px}
.danjiinfo_grp span.yes {padding-top:42px;/*padding-bottom:15px;*/margin:10px 0}
.danjiinfo_grp #facility_FIT.yes {background: url(../images/img_danjiinfo01.svg) no-repeat center 2px;background-size:52px;}
.danjiinfo_grp #facility_GX.yes {background: url(../images/img_danjiinfo02.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_STU.yes {background: url(../images/img_danjiinfo03.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_LIB.yes {background: url(../images/img_danjiinfo04.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_POO.yes {background: url(../images/img_danjiinfo05.svg) no-repeat center 2px;background-size:42px}
.danjiinfo_grp #facility_SAU.yes {background: url(../images/img_danjiinfo06.svg) no-repeat center 2px;background-size:45px}
.danjiinfo_grp #facility_GOL.yes {background: url(../images/img_danjiinfo07.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_GUH.yes {background: url(../images/img_danjiinfo08.svg) no-repeat center 4px;background-size:36px}
/*.danjiinfo_grp #facility_DLB.yes {background: url(../images/img_danjiinfo09.svg) no-repeat center 2px;background-size:38px}*/
.danjiinfo_grp #facility_DLB.yes {background: url(../images/img_danjiinfo10.svg) no-repeat center 5px;background-size:38px}
.danjiinfo_grp #facility_CAF.yes {background: url(../images/img_danjiinfo11.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_KCF.yes {background: url(../images/img_danjiinfo12.svg) no-repeat center 2px;background-size:38px}
.danjiinfo_grp #facility_WP.yes {background: url(../images/img_danjiinfo13.svg) no-repeat center 2px;background-size:36px}
.danjiinfo_grp #facility_MUL.yes {background: url(../images/img_danjiinfo14.svg) no-repeat center 2px;background-size:34px}
.danjiinfo_grp #facility_THE.yes {background: url(../images/img_danjiinfo15.svg) no-repeat center 4px;background-size:36px}
.danjiinfo_grp #facility_RES.yes {background: url(../images/img_danjiinfo16.svg) no-repeat center 2px;background-size:36px}
.danjiinfo_grp #facility_LOU.yes {background: url(../images/img_danjiinfo17.svg) no-repeat center 2px;background-size:32px}
.danjiinfo_grp #facility_SEN.yes {background: url(../images/img_danjiinfo18.svg) no-repeat center 2px;background-size:30px}
.danjiinfo_grp #facility_BAD.yes {background: url(../images/img_danjiinfo19.svg) no-repeat center 4px;background-size:32px}
.danjiinfo_grp #facility_TAT.yes {background: url(../images/img_danjiinfo20.svg) no-repeat center 2px;background-size:30px}
.danjiinfo_grp #facility_BAS.yes {background: url(../images/img_danjiinfo21.svg) no-repeat center 3px;background-size:36px}
.danjiinfo_grp #facility_TAN.yes {background: url(../images/img_danjiinfo22.svg) no-repeat center 2px;background-size:30px}
.danjiinfo_grp #facility_STO.yes {background: url(../images/img_danjiinfo23.svg) no-repeat center 6px;background-size:41px}
.danjiinfo .btn-basic {height:46px}
.danjifc-cont {padding:10px 20px 50px;}
.danjifc-cont form {margin-bottom:20px;}
.danjifc-cont .lbl {margin-bottom:8px;display:block;font-size:14px;}

/** 매물리스트 **/
.itemlist .panel-header {padding:0 50px}
// .itemlist .panel-box {margin-top:98px;}
.panel-group {height:100%;}
.panel-box {background:#f2f2f2;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);box-sizing:border-box;width:100%;height:100% !important;/*position:absolute;top:0;bottom:0;*/margin-top:44px;overflow-x:hidden}
.panel-header {flex-shrink: 0; height:61px;padding:0 70px 0 50px;position:relative;top:0;left:0;width:100%;z-index:205;text-align:center;background:#fff;/*opacity:.9;*/box-sizing:border-box;}
.itemlist .mlst_tit {padding-right:50px}
.mlst_tit {display:block;position:absolute;left:0;width:100%;top:50%;transform:translatey(-50%);padding:0 50px 0 50px;box-sizing:border-box;font-size:17px;line-height:1}
/*.mlst_tit .favo_area {right:50px;top:50%;transform:translatey(-50%)}*/
.mlst_tit span {vertical-align:middle;line-height:1.2;}
.mlst_tit .title {font-size:17px;}
.mlst_tit .danjiname {font-size:18px;line-height:1.2;font-weight:600;max-width:186px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; display:inline-block;}
.mlst_tit .name-apt {font-size:17px;line-height:1.2;font-weight:600;max-width:230px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;display:inline-block;}
.mlst_tit .address {font-size:12px;display:block;margin-top:1px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
// .panel-group .filter-container {position:fixed;top:54px;left:0;z-index:106}
/*.itemlist .filter_wrap li:last-child {float:right;margin-right:70px}*/
.item-card-container .item-card {margin-top:8px;border:0;}
.item-card-container .item-card:hover {background: #fdf9f8;/*border-top: 1px solid #dd846c;border-bottom: 1px solid #dd846c;*/}
.item-card-container .item-card .favo_area {z-index:5;}
.name-apt {font-size:15px}
.apt .d_info01 .favo_area {margin-top:-5px;}
.d_info01 .name-apt {font-size:17px}
.item-card .real-price {font-size:20px;font-weight:bold;line-height:1.5;margin:3px 0;display:block;}
/*.item-card .real-price i {margin-right:5px;}*/
.item_flag {width:100%;height:100%;background-color:rgb(0,0,0,0.4);position:absolute;top:0;left:0;right:0;bottom:0;box-sizing:border-box;z-index:5}
.item_flag:hover {border:1px solid #d9d9d9;cursor:default}
.item_flag .sta_flag {position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);display:inline-block;}
.item_flag .sta_flag .ico_check {display:inline-block;background:url("../images/img_check.png") no-repeat;width:20px;height:14px;margin-right:3px}
.item_flag .sta_flag .flag_text01 {font-size:24px;color:#fff;display:block;}
.item_flag .sta_flag .flag_text02 {font-weight:300;font-size:16px;color:#fff;text-align:center;display:block;}
.filter-list.sort {float:right}
.item-card.apt .item_info {margin-top:0}
.item_naver {margin-top:10px;padding:0;margin-bottom:20px}
.item_naver .h4_area {padding:10px 10px 10px 15px;;background:#44cc5b;}
.item_naver .h4_area h4 {display:inline-block;font-size:15px;color:#fff;font-weight:400;}
/*.item_naver .h4_area h4:before {display:inline-block;content:'';width:12px;height:12px;background:url(../images/ico_n02.png) no-repeat;background-size:contain;margin-right:5px}*/
.item_naver .item-card-container {margin-top:0;padding:0}
.item_naver .item-card-container li {margin-top:0;min-height:auto;margin-bottom:10px;}
.item_naver .item-card-container li:last-child {margin-bottom:0px}
.item_naver .cardrow span {font-size:18px;}
.item_naver .cardrow .deal_price {font-weight:600;display:inline-block}
.item_naver .cardrow .d_info03 {font-size:14px;}
.go_naveritm {position:absolute;right:62px;top:2px;display:inline-block;font-size:14px;padding:3px 10px;border-radius:50px;border:1px solid #dadada;color:#44cc5b;margin-top:6px;}
.go_naveritm:before {display:inline-block;content:'';background:url(../images/ico_n03.png) no-repeat;width:10px;height:10px;background-size:contain;margin-right:3px;}

/** 매물상세 **/
.panel-header .name-apt {color:#000;font-weight:400;font-size:20rem;}
.panel-header .mlst_tit .address {color:#fff;font-weight:300}
.itemdetail .panel-box {margin-top:0;padding-bottom:60px;padding-top:55px}
.itemdetail .real-price {font-size:24px;font-weight:bold;line-height:1.5;margin:0 0 3px;display:block;text-align:center}
.itemdetail .d_info03 .addinfo:before {display:inline-block;width:4px;height:4px;background:#444;margin:0 11px 4px 6px;content: '';border-radius:4px;}
.itemdetail .d_info03 .addinfo:first-child:before {display:none}
.info_summury {text-align:center}
.info_summury small {margin:0}
.go_naver {display:inline-block;width:100%;box-sizing:border-box;text-align:center;font-weight:400;font-size:16px;padding:0  10px;background:url(../images/ico_arr02.png) no-repeat 80% center;}
.opt_grp {overflow:hidden;display:table;}
.opt_grp span {display:table-cell;width:25%;float:left;/*padding:5px 15px;*/text-align:center;/*margin:2px;border:1px solid #efefef;*/border-radius:30px;font-size:15px}
.opt_grp span.yes {padding-top:40px;/*padding-bottom:15px;*/margin:10px 0}
.opt_grp span.opt01.yes {background: url(../images/opt01.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt02.yes {background: url(../images/opt02.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt03.yes {background: url(../images/opt03.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt04.yes {background: url(../images/opt04.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt05.yes {background: url(../images/opt05.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt06.yes {background: url(../images/opt06.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt07.yes {background: url(../images/opt07.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt08.yes {background: url(../images/opt08.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt09.yes {background: url(../images/opt09.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt10.yes {background: url(../images/opt10.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt11.yes {background: url(../images/opt11.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt12.yes {background: url(../images/opt12.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt13.yes {background: url(../images/opt13.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt14.yes {background: url(../images/opt14.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt15.yes {background: url(../images/opt15.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt16.yes {background: url(../images/opt16.svg) no-repeat center 2px;background-size:35px}
.opt_grp span.opt17.yes {background: url(../images/opt17.svg) no-repeat center 2px;background-size:35px}
.real_detail tr td.real-price {font-size:13px;font-weight:500;margin-bottom:0;vertical-align:middle;line-height:2}
.itemdetail .real-price {}
.pee_wrap {text-align:center} 
.bk_pee {padding:20px 0;display:inline-block;width:160px;}
.bk_pee ul{height:60px;margin:0;padding:0;/*border-bottom:1px solid #ddd;*/font-size:12px;list-style:none}
.bk_pee ul:after{display:block;clear:both;content:""}
.bk_pee li{display:inline;float:left;position:relative;width:60px;height:100%;margin:0 8px;text-align:center;white-space:nowrap}
.bk_pee .gTerm{display:inline-block;position:relative;width:100%;height:20px;margin:0 -100% -20px 0;padding:60px 0 0 0;font-weight:400;color:#767676;line-height:20px;vertical-align:bottom}
.bk_pee .gBar{display:inline-block;position:relative;width:100%;margin:-1px 0 0 0;border-bottom:0;background:#e9e9e9;vertical-align:bottom}
.bk_pee .gBar.dawinpee {background:#901e00;font-size:15px;}
.bk_pee .gBar.dawinpee span {font-size:20px;color:#901e00;top:-30px;font-weight:bold;left:44%}
.bk_pee .gBar span{position:absolute;top:-20px;left:50%;transform:translateX(-50%);display:block;width:100%;color:#767676}
.bk_pee li + li .gTerm {font-size:13px;color:#901e00}
.p_txt {text-align:center;margin-bottom:10px;font-size:15px;}

.npop {padding:0;min-height:200px;max-height:500px}
.npop header {padding:15px 20px;background:#44cc5b}
.npop .itmtitle_group h2 {font-weight:400;font-size:15px;color:#fff}
.npop .itmtitle_group h2.info01 {font-size:20px;font-weight:600;}
/*.npop .itmtitle_group h2.info01:before {display: inline-block;content:'';width:12px;height:12px;background:url(../images/ico_n02.png) no-repeat;background-size:contain;margin-right:6px;vertical-align:middle;padding-top:4px;}*/
.npop .itmtitle_group h2.info02, .npop .itmtitle_group h2.info03 {display:inline-block;font-size:17px;font-weight:400;}
.npop .itmtitle_group h2.info03:before {display:inline-block;margin:0 5px 0 2px;content:'/';font-size:14px;color:#fff}
.npop .itmtitle_group h2.info02 span, .npop .itmtitle_group h2.info03 span {margin-right:3px;}
.npop .itmtitle_group h2.info02 span:last-child, .npop .itmtitle_group h2.info03 span:last-child {margin-right:0}
.npop .container {padding:0;max-height:408px;overflow-y:auto}
.npop .nitm_link {display:block;border-bottom:1px solid rgba(0,0,0,0.1);padding:12px 20px 14px;text-align:left;}
.npop .nitm_link:first-child {border-top:1px solid rgba(0,0,0,0.1)}
.npop .nitm_link:hover {background:#f3fff5}
.npop .nitm_link.selected {background:#f0f0f0}
.npop .nitm_link .row {padding:0;border:none}
.npop .nitm_link .row:first-child {font-size:17px;}
.npop .nitm_link .row .price {color:#4c94e8;font-weight:600}
.npop .nitm_link .row .price:after {display:none}
.npop .nitm_link .row span:after {display:inline-block;content:',';margin-right:5px}
.npop .txt_emp {color:#777;font-size:14px;text-align:left;padding:10px 20px;border-bottom:1px solid rgba(0,0,0,0.1)}

/** 중개사문의하기 **/
.contactag .mlst_tit {font-size:16px;padding-right:50px}
.contactag .panel-box {margin-top:0}
.contactag .container {padding-top:55px;}
.contactag .cont-wrap {background:#eaebed;overflow-y:scroll;box-sizing:border-box;width:100%;height:100vh;margin-top:0;overflow-x:hidden;}
.contactag .agent-list {margin-top:0;}
.contactag .agent-card {border:none;padding-right:50px}
.agent-card .btn-question {position:absolute;right:10px;top:50%;transform:translatey(-50%);margin-top:0}
.lyr_inner .ntc_p {margin-bottom:10px;}
.contactag .agent-list .btn-box {margin:0;}

/* 마이페이지 */
/*.mp .header {-webkit-transform-style: preserve-3d;-webkit-transform: translateZ(-5px);}*/
/*.mp .container {height:100vh;}*/
.mp .cont_wrap {height:100%;display:flex;flex-direction:column;}
.user_info {position:fixed;padding:0 20px 5px;height:60px;box-sizing:border-box;width:100%;background:#fff;z-index:10}
.user_info .name {font-size:22px;font-weight:bold;display:inline-block;vertical-align:middle}
.btn-set {display:inline-block;width:18px;height:18px;text-indent:-9999px;background:url("../images/ico_setting.png") no-repeat;background-size:contain;vertical-align:middle;margin-left:5px}
.snb {position:fixed;top:115px;padding:10px 15px 15px;height:56px;width:100%;background:#fff;box-sizing:border-box;z-index:10;border-top:1px solid #e9e9e9;border-bottom:1px solid #e9e9e9}
.snb ul {display:flex;flex:auto;flex-wrap:wrap;justify-content:space-between}
.snb ul li {width:calc(100% / 4);text-align:center;}
.snb ul li a {display:inline-block;width:100%;padding:5px;box-sizing:border-box;}
.snb ul li a.on {border-radius:50px;background:#7a1d05;color:#fff;}
.sub_cont {background:#efefef;/*height:100%;*/padding:126px 20px 50px;flex:1;-webkit-flex:1;width:100%;}
.sub_cont .inquery {padding-top:10px}
.btn-leaveid {font-size:15px;margin-top:15px}
.sub_cont .tab_style03 .m_exp {margin-top:10px;margin-bottom:0}
.favo-cardwrap .favo_area {z-index:99}
.contr-card .item_flag {height:calc(100% - 66px)}
.cancel .item_flag {height:100%;}
.mysell_btn {border-top:1px solid #d9d9d9;text-align:center;padding:10px 0 10px;width:100%;margin-top:15px}
.mysell_btn .btn-group {margin:-5px 0}
.mysell_btn .btn {height:40px;font-size:15px;}
.item-card.contr-card {padding-bottom:0}
.item-card.contr-card:last-child {margin-bottom:10px;}
.item-card.contr-card.cancel {padding-bottom:15px;}
.contr-card .item_info {min-height:110px;}
.mp .accordian li:first-child {border-top:none}
.mp .lyr .radio {display:block;margin:10px 0}
.mp .lyr .radio:last-child {margin-bottom:10px}

/*추천history*/
.recomm-card span {display:block;}
.item-card.recomm-card .item_info {width:100%}
.recomm-date {font-size:15px;color:#BBBBBB;margin-bottom:5px;}
.recomm-selec b {font-size:16px;}
.recomm-Priority {font-size:16px;}
.recomm-Priority b {font-size:18px;}
.recomm-Priority b:after {display:inline-block;content:'>';font-size:16px;color:#666;margin:0 5px;font-weight:400;}
.recomm-Priority b:last-child:after {display:none}
.recomm-Priority .selected01 {color:#c43713}
.recommhitory .panel-header {background:#fff}
.recommhitory .panel-box {margin-top:0}
.recomm_rnk {font-size:18px;color:#c43713;display:inline-block;vertical-align:middle;margin-right:5px;}
.mlst_tit .recomm-Priority {display:block;}
.mlst_tit .recomm-Priority b {font-size:15px;}
.mlst_tit .recomm-selec {display:block;margin-top:-7px;}
.mlst_tit .recomm-selec b {font-size:14px;font-weight:500}

/** 내가내놓은 매물 **/
.sub_cont .mybox {margin-top:10px}
.sub_cont .mybox .item-card-container {margin:0 -20px}
.noitem>div {margin-top:40px}
.noitem>div p {text-align:center}
.noitem>div .bolder {font-size:17px;margin-bottom:10px}
.noitem .btn-basic {height:50px;margin-top:10px}
.msg {width:80%;margin:0 auto;}
.del {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:100px;z-index:104}
.del .txt {display:block;font-size:15px;color:#fff;text-align:center;}
.subj_tit02 {font-size:18px;font-weight:300;padding-bottom:0;} 
.subj_tit02 .m_tit {font-weight:600}
.mp .panel-group.itemlist {padding-top:98px}
.mp .panel-box {margin-top:0;padding-top:0px}
.mp .panel-box .agent-list {margin-top:10px}
.mp .panel-box .agent-card {border:none}
.mp .panel-box .agent-card .thumbnail_area {left:10px;top:50%;transform:translatey(-50%)}
.mp .panel-box .agent-card .check-box02 {right:-10px;}
.form-rdbk {margin-top:20px}
.mp .danjidetail .panel-box {padding-top:55px}

/*매물삭제*/
.deleteitm .container {padding-top:0}
.deleteitm .cont_wrap {padding:20px;display:block}
.deleteitm .m_exp {font-size:17px}
.deleteitm .selec_option {margin-top:0}
.deleteitm .selec_option .txtarea {margin-top:10px}
.deleteitm .poplg {position:fixed;z-index:1200;width:100%;height:100vh;top:0;bottom:0;right:0;left:0;background:#fff;}
.deleteitm .poplg .cont_wrap {height:100%;padding-top:60px}
.deleteitm .poplg .selec_option {position:inherit;top:inherit;left:inherit;border:none}

/*중개사평가하기*/
.rate .cont_wrap {display:block;background:#fff}
.avr_area {width:100%;padding:10px 20px 20px;background:#fff;/*position:fixed;*/z-index:105;text-align:left;box-sizing:border-box}
.avr_area .subj_tit {padding-bottom:10px}
.avr_area .m_exp {margin-bottom:10px;font-size:16px}
.star_score output {font-size:18px;margin-left:10px;display:inline-block;vertical-align:middle}
.avr_area .star_score {margin-bottom:0;display:inline-block}
.avr_area .star_score span.st_off {display:inline-block;background-image: url(../images/point0_1.png);background-repeat:no-repeat;vertical-align:middle;position:relative;width:153px;height:26px;}
.avr_area .star_score span.st_on {position:absolute;z-index:10;width:153px;height:26px;overflow:hidden;top:0;left:0;text-indent:-9999px;display:inline-block;background-image: url(../images/point5_1.png);background-repeat:no-repeat;}
.rt_wrap {padding:20px 20px 60px;background:#f7f7f7;width:100%;box-sizing:border-box}
.rt_items {overflow:hidden;margin-bottom:3px}
.rt_items .p_area {display:inline-block;font-size:15px;line-height:1.6;margin-bottom:5px}
.star_input>.input,
.star_input>.input>label:hover,
.star_input>.input>input:focus+label,
.star_input>.input>input:checked+label{display: inline-block;vertical-align:middle;background-image:url(../images/inp_star.png);background-repeat:no-repeat;}
.star_input{display:inline-block;white-space:nowrap;width:200px;height:40px;line-height:30px;}
.star_input>.input{display:inline-block;width:161px;background-size:161px;height:24px;white-space:nowrap;overflow:hidden;position: relative;}
.star_input>.input>input{position:absolute;width:1px;height:1px;opacity:0;}
.star_input>.input.focus{outline:1px dotted #ddd;}
.star_input>.input>label{width:24px;height:0;padding:24px 0 0 0;overflow: hidden;float:left;cursor: pointer;position: absolute;top: 0;left: 0;}
.star_input>.input>label:hover,
.star_input>.input>input:focus+label,
.star_input>.input>input:checked+label{background-size:161px;background-position:0 bottom;}
.star_input>.input>label:hover~label{background-image: none;}
.star_input>.input>.lbl01{width:26px;z-index:5;}
.star_input>.input>.lbl02{width:60px;z-index:4;}
.star_input>.input>.lbl03{width:93px;z-index:3;}
.star_input>.input>.lbl04{width:127px;z-index:2;}
.star_input>.input>.lbl05{width:161px;z-index:1;}
.star_input>output{display:inline-block;width:40px;font-size:15px;text-align:right; vertical-align:middle;}
.rt_items .radio-box {padding:5px 0}
.rate .popxlg {height:100%;}

/*비회원 중개사평가하기*/
.nomem_survey .container {padding-top:20px}
.nomem_survey .cont_wrap {display:block}
.nomem_survey .rate .subjwrap {height:auto;position:static}
.nomem_survey .subj_tit {padding-bottom:20px}
.nomem_survey .rate .cont {padding-top:0}
.nomem_survey .rate .subj_tit .m_exp {margin-top:10px}
/** 내문의 **/
.sub_cont .accordian {margin:0 -20px;padding-top:0}
.qbox span, .aitem span {display:block}
.myfaq .qitem {padding-right:40px}
.myfaq .q_cate {font-size:15px;margin-top:8px}
.myfaq .q_cont {font-weight:600;margin-top:5px}
.myfaq .q_contdetail, .myfaq .a_cont {padding:5px 0 0}
.myfaq .date {font-size:14px;color: color(normal, 030);margin-top:10px;}
.myfaq .name-apt {font-size:15px;margin-top:8px}
.myfaq .address {font-size:14px;color:#666}
.myfaq .ag_info {margin-top:8px}
.flag_qa {display:inline-block;color:#fff;padding:1px 5px 3px;font-size:12px;font-weight:100;border-radius:2px}
.flag_qa.no {background:#e0e0e0;border:1px solid #c2c2c2}
.flag_qa.yes {background:#912c0c;border:1px solid #631800}
.myfaq .qitem {background:#fff url("../images/btn_arr01.png") no-repeat top 30px right 20px;}
.myfaq .qitem.active {background:#fff url("../images/btn_arr02.png") no-repeat top 30px right 20px;}
.myfaq .abox {position:relative;padding-left:60px;}
.myfaq .ad_ans {padding-left:0}
.myfaq .abox .thumbnail_area {width:50px;height:50px;top:10px;transform:none}

/*매물수정*/
.btn-group.modi {position:fixed;bottom:65px;width:100%;box-sizing:border-box;left:0;padding:0 20px;z-index:101;margin:0}
.btn-group.modi:after {display:block;content:'';clear:both}
.btn-group.modi .btn_prev {float:left;padding:10px 20px 10px 30px;width:80px;box-sizing:border-box;background:#fff url(../images/ico_left.png) no-repeat 10px center;border-color:#424242;height:45px;margin:0}
.btn-group.modi .btn_next {float:right;padding:10px 30px 10px 20px;width:80px;box-sizing:border-box;background:#fff url(../images/ico_right.png) no-repeat 58px center;border-color:#424242;height:45px;margin:0}

/* 평형전환 */
.btn_space_box {position:absolute;right:15px;top:13px;display:flex}
.btn_space {font-size:12px;line-height:16px;letter-spacing:-.5px;position:relative;top:-1px;width:21px;height:19px;margin-left:-1px;border:1px solid #d9d9d9;background-color:rgba(0,0,0,0.02);text-align:center;vertical-align:middle;color:#777;}
.btn_space.on {z-index:10;border-color:#999;background-color:#fff;color:#2b2c2e;}

/* 404error */
.err_content {background:url(../images/img_errbg.png) no-repeat 50% center;min-height:640px;height:100vh;background-size:cover;}
.err_content .err_txt {font-size:40px;position:absolute;left:20px;top:70px}
.err_content .err_txt {margin-bottom:0;width:100%;min-height:180px;text-indent:-9999px;background:url(../images/img_errtxt.png) no-repeat;background-size:contain;box-sizing:border-box}
.slide_box.errorpage .double {overflow:hidden;}
.double button.go_home {width:90px !important;border-radius:50px;padding:8px 0;font-size:16px;font-weight:100;background:#a42302;background: -webkit-linear-gradient(to right, #a42302, #7a1d05);background:linear-gradient(to right, #a42302, #7a1d05);color:#fff;margin-top:20px;}
.double button.go_back {width:110px !important;border-radius:50px;padding:8px 0;font-size:16px;font-weight:100;background:#fff;color:#222;border:1px solid #222;margin-top:20px;margin-left:5px}
.slide_box.errornw {background:none;min-height:640px;height:100%;}
#main_section .slide_box.errornw .m_sch_wrap {text-align:center;top:45%}
#main_section .slide_box.errornw .m_sch_wrap .m_text {margin-bottom:0;width:100%;min-height:180px;text-indent:-9999px;background:url(../images/img_errtxt02.png) no-repeat 50%;background-size:contain;box-sizing:border-box}
button.btn_refresh {width:110px !important;border-radius:50px;padding:5px 0;font-size:16px;font-weight:100;background:#7a1d05;color:#fff;border:1px solid #7a1d05;margin-top:30px;margin-left:5px;}

/* 중개사 위치 */
.loc_pointer {position:absolute;z-index:100}

/* 중개사 회원가입 swipe */
.swiper-container {width:100%;height:100%;}
.swiper-slide {display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;-webkit-justify-content: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;-webkit-align-items: center;align-items: center;}

/*다운로드앱 레이어*/
.pull_lyr {display:table;width:100%;height:100%;background-color: rgba(0,0,0,0.75);position:absolute;top:0;left:0;right:0;bottom:0;z-index:1000}
.pannel {display:table-cell;vertical-align:middle;padding-bottom:40px;text-align: center;}
.pannel img {width:100%}
.go-app-banner {display:block;width:80%;margin:0 auto;background-position:center;}
.go-event-banner {display:block;width:90%;margin:0 auto;background-position:center;}
.close-banner {display:block;margin-top:12px;text-decoration:underline;color:#fff;}
.lyr_event .pannel {padding-bottom:0}

/*앱설정*/
.set {background:#f9f9f9}
.set .container {padding-top:55px;}
.set .cont_wrap {position:relative;min-height:100%}
/* .setting_box {width:100%;padding:30px 20px;box-sizing:border-box;position:relative;text-align:left;background:#fff;margin-top:10px;height:100%}
.setting_box_inner {position:relative;padding-right:70px;}
.setting_box .heading {font-size:16px;letter-spacing:-1.1px;}
.setting_box .heading h2 {display:inline-block;font-size:22px}
.p_setting_guide {padding-right:60px;margin-top:2px;color:#919191;font-size:14px; line-height:18px;letter-spacing:-.5px;}
.setting_box .switch {position:absolute;right:0;width:54px;top:50%;transform:translatey(-50%)}
.switch{display:inline-block;position:relative;width:54px;height:30px}
.switch input:focus+label{outline:dotted thin;outline:-webkit-focus-ring-color auto 5px}
.switch input[type=checkbox]{position:absolute;-moz-opacity:0;-khtml-opacity:0;-webkit-opacity:0;opacity:0;-ms-filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0);filter:alpha(opacity=0)}
.switch label,.switch label span{position:absolute;left:0;right:0;top:0;bottom:0;-webkit-transition-duration:.2s;transition-duration:.2s}
.switch label{-webkit-border-radius:20px;border-radius:20px;background-color:#b6b6b6}
.switch label span{z-index:1;width:28px;height:28px;margin:1px;-webkit-border-radius:50%;border-radius:50%;background-color:#fff;text-indent:-9999px}
.switch input:checked+label{background-color:#b53210}
.switch input:checked+label span{-webkit-transform:translateX(24px);-ms-transform:translateX(24px);transform:translateX(24px)}
.set .btn-box {width:100%;position:fixed;bottom:70px;left:0}
.set .btn-box .btn {font-size:14px} */

/*중개사신고하기*/
.report .m_exp {font-size:17px;padding-bottom:10px}
.basic-inner {padding:20px 20px 60px;width:100%;box-sizing:border-box}
.report .selec-result {margin:20px 0 ;overflow-y:inherit}
.report .agent-list {display:flex;flex-wrap:nowrap;width:1000px;justify-content:left;padding:0;margin-top:0}
.report .agent-card {margin-right:10px;width:320px;margin-bottom:0;height:124px;}
.report .radio-box .radio {height:auto;padding:0}
.report .radio-box .radio:after {display:block;content:'';clear:both}
.report .radio-box .radio strong {float:none;font-size:15px}
.report .result_area {display:inline-block;padding:6px 10px 6px 15px;color:#fff;border-radius:50px;background-color:#c43713;font-size:14px;text-align:left;margin-top:10px;}
.report .result_area .del {display:inline-block;background:url(../images/ico_del.png) no-repeat;width:13px;height:13px;text-indent:-9999px;vertical-align:middle;margin-left:13px;margin-top:10px;position:static}

.m_exp .em {border-bottom:1px solid #ffd200;box-shadow:inset 0 -8px #ffd200}

.lyr.appdown {width:80%;background:none;border:none;}
.lyr.appdown .lyrpop_wrap {background:none;max-height:unset}
.lyr.appdown .lyr_inner {padding:0}
.lyr.appdown .vt-area {padding:0;color:#fff;text-align:center}
.lyr.appdown .ntc_txt {padding:0}
.lyr.appdown .ntc_p {color:#fff;font-size:24rem;padding-bottom:20px}
.lyr.appdown .ntc_p strong {display:block;font-size:28rem;}
.lyr.appdown .ntc_p small {display:block;font-size:15rem;}
.lyr.appdown .btn {font-size:20px;background:#7a1d05;color:#fff;border:none;}
.lyr.appdown .close_appdown {display:inline-block;text-align:center;color:#fff;text-decoration:underline;margin-top:15px;}

.lyrpop_analy.infopop .lyrpop_wrap {background:#483430;height:500px}
.lyrpop_analy.infopop .lyrpop_wrap .btn-close-wh {position:absolute;right:0;top:0}
.lyrpop_analy.infopop .lyr_inner {height:100%}
.lyrpop_analy.infopop .lyr_inner .txt {width:280px;box-sizing:border-box;position:absolute;top:45px;left:30px}
.lyrpop_analy.infopop .lyr_inner .txt p {color:#fff;font-size:12px;line-height:1.6;}
.lyrpop_analy.infopop .lyr_inner .txt p .yellow {color:#e3ca48;font-weight:bold}
.lyrpop_analy.infopop .lyr_inner .txt .p01 {font-size:4.2vw;font-weight:bold;line-height:1.4}
.lyrpop_analy.infopop .lyr_inner .txt .p02 {font-size:30px;font-weight:bold;line-height:1.3}
.lyrpop_analy.infopop .lyr_inner .txt .p03 {font-size:33px;font-weight:bold;line-height:1.2;margin-bottom:10px}
.lyrpop_analy.infopop .lyr_inner .content {background:url(../images/img_pop.png) no-repeat;width:300px;height:260px;position:absolute;bottom:0;right:0;background-size:100% auto}

/* 뉴스 */
.news .cont_wrap {background:#fff;padding:15px 20px;min-height:100vh}
.news .m_exp {font-size:15px;margin-bottom:10px;}
.news .catebox {margin-bottom:15px;}
.news .catebox .selec, .catebox .inp {height:40px;}
.news .item_container {width:100%;display:flex;align-items: center;justify-content:flex-start;flex-flow:row wrap;}
.news .item {flex-basis:calc((100% - 6%) / 3);box-sizing:border-box;border:1px solid #e0e0e0;margin-bottom:8px;padding-top:calc((100% - 6%) / 3);align-items:center;border-radius:10px;margin:1%;position:relative}
/*.react_lightgallery_item {display:inline-block;width:100%;height:100%;border-radius:10px}*/
.news .react_lightgallery_item {position:absolute;top:0;right:0;left:0;bottom:0;}
.news .react_lightgallery_item:hover {transform: scale(1.1);box-shadow: 0 1px 2px rgba(0,0,0,0.15);transition: box-shadow 300ms ease-in-out, transform 300ms ease-in-out;-webkit-box-shadow: -8px 11px 20px 2px rgba(0,0,0,0.44); 
box-shadow: -8px 11px 20px 2px rgba(0,0,0,0.44);z-index:1}
.news .react_lightgallery_item img {width:100%;height:100%;object-fit:cover;border-radius:10px}
.news .inp-horiz .inp:last-child {margin-top:0}
.lyr.popnews {max-width:90%;max-height:90%;padding:0;min-height:auto;}
.lyr.popnews .lyr_inner {padding:0}
.lyr.popnews .lyr_inner .box_news {height:auto;}
.lyr.popnews .lyr_inner .box_news img {display:inline-block;vertical-align:middle;max-width:100%;max-height:100%;width:100%;height:auto;}
.lyr.popnews .btn-right {right:-20px;top:-55px;}

.news .catebox option {position:relative}
.news .catebox option[value="maburi_on"] {width:15px;height:15px;background:url(../images/ico_maburi02.png) no-repeat;} 
.news .ban_area {position:relative;top:unset;left:unset;right:unset;min-height:80px;box-sizing:border-box;margin:0 0 15px;border-radius:5px;background:rgb(231, 239, 251);padding:15px;}
.news .ban_area .ban_inner {position:relative;display:block;padding-top:35px;}
.news .ban_area .maburi:before {display:block;content:'';width:80px;height:35px;background:url(../images/ico_maburi.png) no-repeat;background-size:contain;position:absolute;left:0;top:-5px;} 
.news .ban_area .subject {font-weight:600;font-size:14px;}
.news .ban_area .date {font-size:13px;}
.news .link_maburi {display:inline-block;padding:5px 25px 5px 14px;border-radius:50px;background:rgb(74, 137, 233);color:#fff;position:absolute;right:10px;top:13px;font-size:12px;}
.news .link_maburi:after {display:inline-block;content:'';background:url(../images/ico_arrowr.png) no-repeat;background-size:contain;width:8px;height:8px;position:absolute;right:10px;top:9px;}

/*찜한단지설정*/
.itm_alert_box h2.tit {font-weight:600;font-size:17px;}
.itm_alert_box .tit_exp {font-size:14px;margin-bottom:10px;}
.itm_alert_box form + form {margin-top:10px;}
.itm_alert_box form + form strong {font-size:15px;}

/* 210402 중개사회언가입, 로그인 버튼수정*/
.bklogin_wrap .btn-group {display:flex;flex:1;flex-direction: row;justify-items: flex-end}
.bklogin_wrap .p01 {font-size:16px;margin-bottom:14px;}
.bklogin_wrap .btn {margin:5px;font-size:14px;}

.bkjoin {background:#7a1d05;border-radius:46px;height:44px;color:#fff;margin:0;}
.bklogin {background:#fff;border-radius:46px;height:44px;color:#222;border:1px solid #b9b9b9;margin:0}

.btn.btn-bkjoin {background:#7a1d05;border-radius:46px;height:44px;color:#fff;}
.btn.btn-bklogin {background:#fff;border-radius:46px;height:44px;color:#222;border:1px solid #b9b9b9;}

/* 210405 로그인 팝업 */
.lyr.alertlogin .login-group {margin:0}
.pop.lyr.alertlogin .vt-area {padding:0 25px 20px 25px}
.easylogin {margin:-8px;}
.lyr.alertlogin .bklogin_wrap {padding:20px 25px 40px;}
.lyr.alertlogin .easylogin .btn {height:45px !important;}
.lyr.alertlogin .bklogin_wrap .btn {height:40px !important;}

/* 210409 앱용로그인 */
.app .header {background:none;}
.app .container {padding-top:0;height:100vh;}
.app .container .cont_wrap {width:100%;display:block;overflow:hidden;background-size:cover;background-position:50%;height:100%;background-image:url(../images/bg_applogin.png);background-repeat:no-repeat;min-height: 640px;position:absolute;left:0;top:0;right:0;bottom:0;}
.app .bklogin_wrap {margin-bottom:0}
.app .bklogin_wrap .p01 {color:#fff}
.app .vt-area {position:absolute;top:50%;transform:translatey(-50%)}
.app .vt-area p {color:#fff;}
.app .container .cont_wrap .apple, 
.app .container .cont_wrap .phone, 
.app .container .cont_wrap .dawin, 
.app .container .cont_wrap .bklogin_wrap .btn {border:none}
.app .container .cont_wrap .easylogin .btn {height:46px}

/* 210510 단지상세 header */
.header_dandji {height:76px;width:100%;background:#7a1d05;color:#fff;position:relative;}
.header_dandji .title {font-size:20px;font-weight:500;line-height:1.2;padding-top:8px;}
.header_dandji .sum_info {font-size:13px;font-weight:300;line-height:1;}
.header_dandji .tit_dept02, .header_dandji .tit_dept03 {font-size:14px;font-weight:300;line-height:1.3;}
.header_dandji .btn-left, .header_dandji .btn-right {position:absolute;top:50%;transform:translatey(-50%)}
.header_danji .lyr span:first-child {position:absolute;top:0 !important;right:0 !important;}
.header_danji .lyr h2 {text-align:left !important;font-size:18px !important;padding:20px 20px 0 !important}

/* 단지상세컨텐츠 */
.complex_info .panel-box {padding-top:10px;margin-top:0;padding-bottom:60px;}
.complex_info .itmbox {padding:0;}
.complex_info .itmbox.emphasis {margin:0 -20px 10px;padding:8px 20px;}
.danjidetail_link {border-bottom:1px solid #0000000d;padding:5px 0 0;width:100%;}
.danjidetail_link_inner {display:flex;flex-direction:row;flex-wrap:wrap;padding:0 20px;width:100%;box-sizing:border-box;align-items:center;}
.link_itmbox {-webkit-box-flex: 1;-webkit-flex:1 0 auto;-ms-flex:1 0 auto;flex:1 0 auto;text-align:center;padding-bottom:8px;padding-top:2px;color:#444;/*margin:0 10px;*/font-size:16px;letter-spacing:-.9px;position:relative;}
.link_itmbox:hover, .link_itmbox.on {font-weight:600px;/*border-bottom:3px solid #222;*/font-weight:bold;color:#222;}
.link_itmbox:hover:after, .link_itmbox.on:after {display:block;width:100%;height:3px;content:'';background:#222;position:absolute;bottom:0;left:50%;transform:translateX(-50%)}
.complex_info .itmbox_inner {padding:20px;}
.complex_info .danjiprice .scroll-li {margin-right:0}
.complex_info .scroll-menu-container .ul-container {width:2000px;background:none;padding-left:6px;margin-bottom:15px}
.complex_info .danjiprice .scroll-link {font-size:13px;color:#444;line-height:1.3;text-align:center;padding:3px 12px;height:38px;box-sizing:border-box;}
.complex_info .scroll-link span {display:block;font-size:12px;font-weight:400;line-height:1;}
.complex_info .danjiprice .scroll-link.select {background:#a50f00;color:#fff;border-radius:50px;border-bottom:0;}
.complex_info .btn_scroll_prev {display:block;width:25px;height:40px;background:#fff url(../images/ico_arr00.png) no-repeat center;text-indent:-9999px;position:absolute;left:-20px;top:0}
.complex_info .btn_scroll_next {display:block;width:25px;height:40px;background:#fff url(../images/ico_arr02.png) no-repeat center;text-indent:-9999px;position:absolute;right:-20px;top:0}
.complex_info .danjiprice .tab_style02 {position:relative;}
.complex_info .danjiprice .tab_style02 ul li a {padding:2px 14px 3px;font-size:13px;}
.complex_info .danjiprice .btn_space_box {top:5px;right:0;}
.complex_info .danjiprice .graph_area {height:300px;margin-top:10px;position:relative}
.complex_info .graph_area .btn_view_prev {position:absolute;left:10px;top:50%;transform:translatey(-50%);display:block;width:38px;height:38px;border-radius:38px;border:1px solid #aeaeae;font-size:20px;font-weight:300;color:#aeaeae;background:rgb(255,255,255,0.8)}
.complex_info .graph_area .btn_view_prev:after {content:'전체기간보기';font-size:10px;font-weight:500;color:#aeaeae;position:absolute;bottom:-18px;left:50%;transform:translateX(-50%)}
.view_gesture {position:absolute;right:0;top:70px;width:62px;height:65px;}
.view_gesture img {width:100%;height:100%;}
.complex_info .danjiprice .graph_area.monthly {height:auto;padding:30px 0}
.complex_info .box-basicinfo {padding:10px 0;}
.complex_info .itmbox_inner .danji_img {margin:0 -20px;}
.complex_info .itmbox_inner .go_naver {display:block;height:42px;line-height:42px;text-align:center;background-color:#fff;border:1px solid #222;font-size:14px;margin-top:15px;}
.danji_school, .facility_info {margin-top:25px;}
.danji_school .info_align {display:flex;}
.danji_school .info_align span {flex:1;}
.danji_school .info_align .value {position:static;margin-left:auto;right:inherit;top:inherit;font-size:15px;text-align:right;}
.facility_info .btn {font-size:14px;height:42px;background: url(../images/ico_arr02.png) no-repeat 80% center;}
.facility_info p {font-size:14px;}
.complex_info .itmbox.location .itmbox_inner {padding:0;}
.panel-group.complex_info .filter-container {position:relative;top:inherit;left:inherit;background:none;border:none;}
.complex_info .itmbox.location .content li .detail .complex_info .sb_detail {display:block;font-size:14px;line-height:1.4;margin:4px 0;position:relative}
.complex_info .item-card {min-height:55px;}
.itm_typeinfo .type01 {color:#bf3433;font-weight:bold;font-size:16px;}
.itm_typeinfo .type02 {color:#5d69bf;font-weight:bold;font-size:16px;}
.itm_typeinfo .type03 {color:#43c750;font-weight:bold;font-size:16px;}
.itm_typeinfo .area {font-size:15px;}
.complex_info .item-card-container .item-card:first-child {margin-top:0}
.complex_info .item-card .real-price {margin:0;font-size:16px;display:inline-block;vertical-align:middle;margin-left:5px;}
.complex_info .filter-item, .aptdetail .filter-item {padding-right:25px;background:url(../images/btn_arr01.png) no-repeat center right 0;font-size:15px;}
.aptdetail .filter_lst:last-child .filter-item {background:none;padding-right:0}
.complex_info .filter-item.on {padding-right:25px;background:url(../images/btn_arr02.png) no-repeat center right 0;}
.complex_info .filter-list:last-child .filter-item {background:none;padding-right:0}
.go_my {display:inline-block;background:#fff;padding:5px 10px;border-radius:30px;-webkit-box-shadow:8px 12px 15px 2px rgb(0 0 0 / 25%);box-shadow:8px 12px 15px 2px rgb(0 0 0 / 25%);position:absolute;right:10px;bottom:10px;font-size:12px;font-weight:600;}
.lyr_inner .share {text-align:center;}
.lyr_inner .share .cont {text-align:left;margin-bottom:15px;}
.lyr_inner .share li {box-shadow:none;}
.complex_info .btn-basic {height:42px;font-size:14px;font-weight:400;background:url(../images/ico_arr02.png) no-repeat 80% center;}
.complex_info .schedule-box {margin:20px 0}
.complex_info .schedule-box .tit {padding:0 0 15px}
.complex_info .schedule-box .info {padding:0;font-size:14px;}
.complex_info .schedule-box .info span {margin-bottom:0}
.complex_info .schedule-box .itmbox_inner {padding:0;margin:0 -20px;}
.complex_info .schedule-box .schedule-lst-group {padding:3px 20px;font-size:15px;}
// .complex_info .maparea {margin:-20px -20px 0;}
.complex_info .itmlist {background:none}
.itmlist .danjidetail_link {background:#fff}
.complex_info .itmbox .tab_con {margin-top:25px;}

.complex_info .itm_msg {top:50%;transform:translate(-50%, -50%);position:absolute;left:50%;width:100%;text-align:center}

.complex_info .itmlist .tab_style02 ul li {margin:0}
.complex_info .itmlist .tab_style02 ul li a {padding: 2px 14px 3px;line-height:1.4;font-size: 13px;}
.complex_info .itmlist .tab_style02 ul li:first-child, .complex_info .itmlist .tab_style02 ul li:nth-child(2) {margin-right:-1px;}

.complex_info .item-card.apt .item_info{padding-right:0}
.complex_info .panel-box .filter_lst:first-child {flex:initial}

.complex_info .item-card .real-price .deal_price {font-size:inherit;}
.complex_info .item-card .itm_typeinfo {display:inline-block;vertical-align: middle}
.complex_info .item-card .itm_typeinfo .type01, .complex_info .item-card .itm_typeinfo .area {font-size:16px;}
.complex_info .item-card .itm_typeinfo .type01 {margin-right:5px;}


/* 단지시세리스트 */
.danji_aptlst .item-card .real-price {line-height:1;margin:0}
.danji_aptlst .deal_price {font-size:20px;line-height:1}
.danji_aptlst .item-card.apt .item_info {width:100%;padding-right:0}

/* 매물상세 */
.aptdetail .panel-box {padding-top:55px;margin-top:0;}
.aptdetail .item-card {padding:0;min-height:80px;}
.aptdetail .item-card .item_info_area{padding:20px;position:relative;width:100%;box-sizing:border-box}
.aptdetail .item_thumb {height:100px;position:inherit;width:35%;top:inherit;right:inherit}
.aptdetail .item_info_area {display:flex;/*margin-bottom:16px;*/}
.aptdetail .item_info_area .favo_area {z-index:1}
.aptdetail .item_info {padding:0;width:65%;padding-right:8px;box-sizing:border-box}
.aptdetail .real-price {margin-top:0;font-size:20px;color:#222;line-height:1.2;margin-bottom:8px;/*opacity:.8*/margin-top:2px;}
.aptdetail .sell-type {color:#fff;font-size:15px;line-height:1;}
.aptdetail .d_info01 {font-size:15px;}
/*.aptdetail .d_info01 .info_dong {font-size:16px;}*/
/*.aptdetail .d_info01 .info_floor {margin-left:5px;}*/
.aptdetail .d_info01 .info_dong, .aptdetail .d_info01 .info_floor b {font-weight:500}
.aptdetail .d_date/*, .aptdetail .d_comment*/ {display:block;}
.d_comment {margin-top:3px;font-size:14px;margin-bottom:4px;}
.aptdetail .item-card .bk_commt_area {padding:5px 20px 25px;position:relative}
.bk_commt_list {position:relative;padding-left:50px;padding-right:50px;padding-bottom:10px;margin-bottom:10px;border-bottom:1px solid #ededed;}
.bk_commt_list:last-child {border-bottom:0;margin-bottom:0;padding-bottom:0}
.bk_commt_list .thumbnail_area {width:40px;height:40px;top:0;transform:none}
.bk_commt_list .agent_info {display:block;color:#515254;font-size:14px;}
.bk_commt_list .bk_office {font-weight:600;}
.bk_commt_list .bk_office .score {color:#ff6c00;margin-left:3px;}
.aptdetail .bk_commt_list .bk_name {font-size:14px;font-weight:400;margin-top:2px;}
.bk_commt_list .bk_comment {margin-top:3px;padding-right:20px;box-sizing:border-box;position:relative;}
.bk_commt_list .bk_comment.fold {height:20px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.bk_commt_list .bk_comment.open {height:auto}
.bk_commt_list .btn-box {position:absolute;right:0;top:0;/*transform:translatey(-50%);*/margin-top:0}
.bk_commt_list .btn-box .btn-question {width:45px;height:45px;padding:0}
.bk_commt_list .icon-angle-down {position:absolute;right:5px;top:0;display:inline-block;width:16px;height:16px;/*border:1px solid #bdbdbd;*/border-radius:2px;cursor:pointer} 
.bk_commt_list .icon-angle-up {position:absolute;right:5px;top:0;display:inline-block;width:16px;height:16px;/*border:1px solid #bdbdbd;*/border-radius:2px;cursor:pointer} 
.aptdetail .itmbox {border-top:1px solid #dedede;margin-bottom:0;font-size:15px;background:none;}
.aptdetail .itmbox:first-child {border-top:none;}
.panel-box .itmbox .pee_wrap {text-align:left;display:flex;flex:1;align-items:flex-end;justify-content:center}
.panel-box .itmbox .bk_pee {min-width:55%;max-width:70%;padding:20px 0;box-sizing:border-box}
.panel-box .itmbox .bk_pee li {width:50px;}
.panel-box .itmbox .bk_pee .gBar.dawinpee span {font-size:17px}
.panel-box .view_all {display:inline-block;font-size:12px;padding:3px 10px;border-radius:50px;border: 1px solid #dadada;color:#929292;position:absolute;right:20px;top:20px;background:#fff}
.aptdetail .itmbox .maparea, .contactag .maparea {height:240px;border:0;position:relative;margin:0 -20px}
.aptdetail .pointer02, .contactag .pointer02 {position:absolute}
.aptdetail .pointer02 button, .contactag .pointer02 button {display:inline-block;background: url(../images/ico_pointer02.png) no-repeat;width:16px;height:25px;text-indent:-9999px;background-size:contain;}
.aptdetail .pointer02 button.on, .contactag .pointer02 button.on {background: url(../images/ico_pointer02_on.png) no-repeat;background-size:contain;}
.pointer02 .nm_score {display:inline-block;position:absolute;left:50%;top:-20px;transform:translateX(-50%);color:#fff;background:#eb6100;padding:1px 5px 1px;font-size:11px;border-radius:30px;}
.aptdetail .opt_grp span {font-size:14px;}
.panel-box .info_align.tax {display:inline-block;max-width:40%;/*min-width:30%;*/width:inherit;/*padding-left:15px;*/}
.panel-box .info_align.tax li {margin-bottom:0}
.panel-box .info_align.tax span {font-size:12px;}
.panel-box .info_align.tax .content {margin-left:3px;font-weight:600;letter-spacing:-.5px}
.panel-box .aroundbk {padding-bottom:0}
.panel-box .agent-card {margin:-2px -20px 0;width:auto;border:none;padding:15px 70px 10px 95px;position:relative;/*border-top:1px solid #dbdbdb;border-bottom:1px solid #dbdbdb;*/height:120px;box-sizing:border-box}
.panel-box .aroundbk .agent-card .agent_info {display:block;position:absolute;top:50%;transform:translatey(-50%);}
.panel-box .agent-card .thumbnail_area {width:65px;height:65px;position:absolute;top:15px;left:20px}
.panel-box .aroundbk .agent-card .thumbnail_area {top:50%;transform:translatey(-50%)}
.panel-box .agent-card .btn-question {width:50px;height:50px;right:20px;}
.panel-box .agent-card .bk_office {font-weight:600;max-width:99%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.panel-box .agent-card .bk_name {font-size:15px;font-weight:400}
.panel-box .agent-card .p_num {display:inline-block}
.panel-box .agent-card .star_score {margin-bottom:0}
.item_detail_area {position:relative;background:#f9f9f9;/*box-shadow: inset -1px 3px 4px -3px rgb(0 0 0 / 30%);*/padding-bottom:25px;border-top:1px solid #cecece}
.item_detail_area:before {display:inline-block;content:'';background:url(../images/img_tri.png) no-repeat;background-size:contain;width:20px;height:20px;position:absolute;top:-15px;left:50%;transform:translatex(-50%);z-index:2;}
.item_detail_area .btn-fold[role="detailclose"]  {position:absolute;top:-19px;left:50%;transform:translatex(-50%);width:50px;height:20px;box-shadow:none;width:100%;}
.item_detail_area .btn-fold{position:absolute;bottom:0;left:50%;transform:translatex(-50%);width:100%;height:26px;/*box-shadow: -1px -3px 4px -3px rgb(0 0 0 / 20%);*/}
.item_info_area .btn-fold, .bk_commt_area .btn-fold {position:absolute;bottom:-1px;left:50%;transform:translatex(-50%);width:50px;height:20px;}

.btn_go_monthly {width:100%;height:46px;background:#a50f00 url(../images/ico_arrowr.png) no-repeat 90% center;color:#fff;}
.aptdetail .item-card.naveritm .d_info01 {font-size:15px;}
.info_dong:after, .info_floor:after {display:inline-block;content: '';width:2px;height:2px;background:#666;margin:0 4px 0 8px;vertical-align:middle;margin-bottom:5px}
.aptdetail .item-card .info_type {display:inline-block;margin-right:5px;}
/*.item-card.naveritm .info_type:before {display:inline-block;content:"";width:16px; height:16px;background:url(../images/ico_n.png) no-repeat;background-size:contain;margin-right:2px;vertical-align:middle;padding-top:4px;margin-right:4px;}*/
.aptdetail .naveritm .item_info_area {padding:15px 20px;display:block}
.aptdetail .naveritm .real-price {margin-bottom:0;font-size:20px;margin-top:3px;}
.item_detail_area .itmbox_banner {background:#f27b35;padding:8px 20px;color:#fff;font-size:12px;}
.itmbox_itmlst {padding:0}
.itmbox_itmlst .itmbox_banner {background:#f9f9f9;color:#222;font-size:13px;}
.itmbox_itmlst .itmbox_banner strong {color:#e83848;font-weight:500}
.nitm_lst {/*padding:15px 125px 15px 20px;*/padding:15px 20px;border-bottom:1px solid #eaeaea;/*background:#fff;*/position:relative;padding-right:125px;}
.nitm_lst:first-child {border-top:1px solid #eaeaea;}
.nitm_lst:last-child {border-bottom:0}
.nitm_lst .info01 span {font-size:16px;font-weight:600}
.nitm_lst .info01 span.price {color:#222;opacity:.8}
.nitm_lst .info02 {padding-right:15px;color:#7c7c7c;margin-top:3px;position:relative;display:-webkit-box;-webkit-box-orient:vertical;word-wrap:break-word;text-overflow:ellipsis;overflow:hidden;-webkit-line-clamp:3;max-height:60px;}
.nitm_lst .info02 .icon-angle-down {position:absolute;right:0;bottom:0;display:inline-block;width:15px;height:15px;border:1px solid #bdbdbd;cursor:pointer} 
.nitm_lst .info02 .icon-angle-up {display:none;position:absolute;right:0;bottom:0;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 
.view_agent .itmbox {position:fixed;width:100%;top:55px;}
.view_agent .agent-list {margin-top:280px;overflow-y:scroll;height:calc(100% - 300px)}
.view_agent .agent-card {margin:0;position:relative;border-top:0;background:#fff;border-bottom:1px solid #eaeaea;height:auto;}
.view_agent .agent-card .thumbnail_area {/*transform:inherit;*/top:50%;}
.view_agent, .view_agent .cont-wrap {overflow-y:hidden}
.view_agent {overflow-x:hidden;}
.nitm_lst .btn-box {position:absolute;right:20px;top:50%;transform:translatey(-50%);display:flex;margin-top:0}
/*
.nitm_lst .btn-box .btn {display:flex;align-items:center;justify-content:center;font-size:12px;color:#666;width:100%;height:28px;border-radius:0;border-color:#aaa;}
.nitm_lst .btn-box .btn:hover {background:#fff;transition-duration:none}
.nitm_lst .btn-box .btn:last-child {margin-left:-1px;}
*/
.nitm_lst .btn-question {width:45px;height:45px; right:20px;background:#af300f;color:#fff;border-radius:60px;font-size:12px;line-height:1.2;display:flex;align-items:center;justify-content:center}
.nitm_lst .btn-gonaver {padding:6px;width:45px;height:45px;font-size:12px;background:#00cd39;border-radius:50%;margin-left:5px;line-height:1.2;color:#fff;text-align:center;padding-top:20px;position:relative;}
.nitm_lst .btn-gonaver:before {display:block;content:'';background:url(../images/ico_n04.png) no-repeat;width:10px;height:10px;background-size:contain;margin-right:0;vertical-align:middle;margin-top:0;position:absolute;top:10px;left:50%;transform:translatex(-50%)}
.panel-group.aptdetail .filter-container {position:relative;background:none;border:none;top:inherit;left:inherit}
.aptdetail .tab_style02 ul {display:flex;align-items:center;}
.aptdetail .tab_style02 ul li {margin-right:0;}
.aptdetail .tab_style02 ul li a {padding:2px 10px 3px;font-size:13px;line-height:1.4;}
.aptdetail .tab_style02 ul li a.on /*.aptdetail .tab_style02 ul li a:hover*/ {background:#af300f;color:#fff;border-color:#af300f;}
.aptdetail .item-card:first-child {margin-top:0}
.aptdetail .icon-help[data-tooltip-text]:hover:after {width:300px;left:50%;transform:translatex(-50%);white-space: pre-line;}


.map_bkcard_wrap {position: absolute;white-space: nowrap;}
.map_bkcard_list {cursor:pointer;background:#fff; overflow-y:scroll;max-height:150px;max-width:150px;border-radius:5px;margin-bottom: 2px;}
.map_bkcard_list li {padding: 12px;border-bottom: 1px solid #eaeaea;display:flex;flex:1;align-items: center;}
.map_bkcard_list li:last-child {border-bottom:0}
.map_bkcard_list li:hover {background-color: #f9eae6}
.map_bkcard_list li .bk_info {font-size: 13px; text-overflow: ellipsis; display: inline-block; width: 78%;overflow: hidden;vertical-align: middle;}
.map_bkcard_list li .nm_score {font-size:12px;color:#eb6100;text-align:right;display:inline-block;vertical-align:middle;width:20%}

.item-card.noimg .item_info_area {display:block;}
.item-card.apt.noimg .item_info {width:100%;}
.d_comment {padding-right:15px;position:relative;display:-webkit-box; -webkit-box-orient:vertical;word-wrap:break-word;text-overflow:ellipsis;overflow:hidden;-webkit-line-clamp: 3;max-height:59px;}
.d_comment .icon-angle-down {position:absolute;right:5px;bottom:0;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 
.d_comment .icon-angle-up {display:none;position:absolute;right:5px;bottom:0;display:inline-block;width:15px;height:15px;/*border:1px solid #bdbdbd;*/cursor:pointer} 

.aptdetail .item-card {border:1px solid #fff;}
.aptdetail .item-card.is-selected, .aptdetail .item-card:hover {background:#fff8f6;/*border-top:1px solid #dd846c;border-bottom:1px solid #dd846c;*/box-sizing:border-box}

.link_office {font-size:11px;font-weight:500;color:#888;padding:2px 5px;border:1px solid #aeaeae;border-radius:30px;display:inline-block;margin-left:5px;vertical-align:middle;cursor:pointer;}

.lyr_outbtn {position:absolute;bottom:20px;left:50%;transform:translatex(-50%)}
.btn_appdown_link {display:inline-block;background-color:#a0290a;padding:10px 20px;color:#fff;border-radius:50px;width:180px;box-sizing:border-box;text-align:center;box-shadow: 0 20px 20px 0 rgb(60 60 60 / 50%);}
.btn_appdown_link:before {display:inline-block;content:'';width:30px;height:30px;background:url(../images/ico_appdown.svg) no-repeat;margin-right:10px;margin-bottom:1px;background-size:contain;vertical-align:middle;border-radius:30px;font-size:26px;}

/* 마이페이지 거래완료신고 시 레이아웃*/
.mp-selecag .pop-complete .panel-box {margin-top:0}

/* 210629 30만원창업 중개사메인 */
.joinagent-main .ja-slide04 {width:100%;height:100vh;background-image:url("../images/bg_ja01.jpg");background-repeat:no-repeat;/*background-attachment:fixed;*/background-position:center;background-size:cover;}
.joinagent-main .ja-slide04 .row {padding-top:100rem;height:calc(100vh - 84rem);position:relative}
.ja-slide04 .row .box-content p:first-child {width:100%;height:220px;background:url("../images/img_submain20.png") no-repeat;background-size:contain;background-position:center}
.ja-slide04 .row .box-card p:nth-child(1){width:100%;height:130px;background:url("../images/bk01.png") no-repeat;background-size:cover;background-position:center}
.ja-slide04 .row .box-card p:nth-child(2){width:100%;height:130px;background:url("../images/bk02.png") no-repeat;background-size:cover;background-position:center}
.joinagent-main .submain-box.ja08 {background:#f1f1f1 url("../images/img_ja08.jpg") no-repeat;background-size:contain;height:600px;background-position:left bottom}
.joinagent-main .submain-box.ja09 {background:#fff url("../images/img_ja09.jpg") no-repeat;background-size:contain;height:670px;background-position:left bottom}
/* .submain-wrap .quickbn.bn_startup {height:auto;bottom:80px;font-size:12px} */
/* .submain-wrap .quickbn.bn_startup .txt_y {font-size:13px;} */

.joinagent-main .ja-slide05 {width:100%;height:100vh;background-image:url("../images/bg_ja02.jpg");background-repeat:no-repeat;/*background-attachment:fixed;*/background-position:center;background-size:cover;}
.joinagent-main .ja-slide05 .row {padding-top:100rem;height:calc(100vh - 84rem);position:relative}
.ja-slide05 .row .box-content {height:230px}
.ja-slide05 .row .box-content p:first-child {width:100%;height:210px;background:url("../images/img_submain21.png") no-repeat;background-size:contain;background-position:center}
.ja-slide05 .row .box-card p:nth-child(1){width:100%;height:130px;background:url("../images/bk01.png") no-repeat;background-size:cover;background-position:center}
.ja-slide05 .row .box-card p:nth-child(2){width:100%;height:130px;background:url("../images/bk02.png") no-repeat;background-size:cover;background-position:center}
.joinagent-main .submain-box.ja08 {background:#f1f1f1 url("../images/img_ja08.jpg") no-repeat;background-size:contain;height:600px;background-position:left bottom}
.joinagent-main .submain-box.ja09 {background:#fff url("../images/img_ja09.jpg") no-repeat;background-size:contain;height:670px;background-position:left bottom}
.submain-wrap .ban_wrap {display:flex;flex-wrap:wrap;flex-direction:row;position:absolute;bottom:10px;z-index:100}
.submain-wrap .quickbn.bn_academy {flex:1;height:auto;padding:15px;box-sizing:border-box;font-size:12px;background:#7a1d05;color:#fff;text-align:left;cursor:pointer;text-indent:0}
.submain-wrap .quickbn.bn_startup {flex:1;height:auto;padding:15px;box-sizing:border-box;font-size:12px;background:#2d135c;color:#fff;text-align:left;cursor:pointer;text-indent:0}
.submain-wrap .quickbn.bn_startup .txt_y {font-size:13px;}

.joinagent-main .submain-box.stup02 {background:#fff;padding-top: 28%;width:100%;height:0;position:relative;max-width:800px;margin:0 auto;padding-bottom:180px;margin-bottom:80px;}
.stup02 .stuplst {display:flex;flex-direction:row;flex-wrap:wrap;padding:20px 30px 50px;}
.stup02 .stuplst li {width:40%;margin:0 5%;text-align:center;padding-bottom:30px}
.stup02 .stuplst li .title {font-size:20px;font-weight:bold;padding-top:80px;position:relative}
.stup02 .stuplst li .title:before {display:block;content:'';height:80px;width:80px;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}
.stup02 .stuplst li.lst01 .title:before {background:url(../images/img_curri01.png) no-repeat;background-size:contain;}
.stup02 .stuplst li .text01 {font-size:15px;margin-top:10px;}
.stup02 .stuplst li.lst02 .title:before {background:url(../images/img_curri02.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst03 .title:before {background:url(../images/img_curri03.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst04 .title:before {background:url(../images/img_curri04.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst05 .title:before {background:url(../images/img_curri05.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst06 .title:before {background:url(../images/img_curri06.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst07 .title:before {background:url(../images/img_curri07.png) no-repeat;background-size:contain;}
.stup02 .stuplst li.lst08 .title:before {background:url(../images/img_curri08.png) no-repeat;background-size:contain;}
.joinagent-main .submain-box.stup02 .btn-horiz {bottom:0;}
.joinagent-main .submain-box.stup02 .row {border-bottom:0}
.joinagent-main .submain-wrap:first-child {height:100vh}
.submain-box.stup02 .sc_tit {padding:20px 30px 20px;} 
.stup02 .add-text {margin-top:10px;font-size:14px;color:#aeaeae;padding:0 20px 30px;}
.stup02 .add-text b {font-size:15px;color:#c22800;font-weight:500;display:block;margin-bottom:5px}

.ja_apply .check-box strong {font-size:15px;width:150px;}
.ja_apply .cont_wrap {display:block;overflow-y:scroll}
.ja_apply .add_t {color: rgb(207, 28, 62);font-size:14px;}

.quickbn {cursor: pointer;}

/* 210803 매물리스트 수정 */
.item-card.apt .item_info {width:calc(100% - 128px);padding-right:0}
.item-card.apt .item_thumb {width:117px;height:100px;top:18px;right:18px;position:absolute;}
.per_price {display:inline-block;padding:2px 7px;height:16px;line-height:15px;background:#efefef;color:#8e8e8e;border-radius:30px;font-size:11px;font-weight:500;vertical-align:middle;margin-top:-5px;}
.item-card.apt .real-price {margin-top:0}
.item-card.apt .d_info01 {margin-bottom:5px;font-size:14px;}
.find .btn-floating {width:65%;position:fixed;bottom:80px;left:50%;transform:translatex(-50%);z-index:10;}
.btn-floating .btn-primary {height:38px;line-height:38px;font-size:15px;padding:0;border-radius:50px;}
.item-card.apt .d_info01 .info_floor:after {content: "";width:2px;height:2px;vertical-align:middle;background:#222;margin:0 5px 4px;display:inline-block;}

/* 210805 집내놓기,집구하기버튼높이수정*/
/* .regi .btn-area-bottom, .regi-phone .btn-area-bottom, .joinagent .btn-area-bottom {bottom:61px;} */
/* .faq .btn-area-bottom, .weekly .btn-area-bottom, .mp .btn-area-bottom, .deleteitm .btn-area-bottom, .rate .btn-area-bottom {bottom:61px;} */
.weekly .container {padding-top:55px !important;}
.ranking .panel-box {padding-top:0;}

/* 210819 내문의 */
.sub_cont .accordian.myfaq, .sub_cont .mybox .item-card-container {margin:0;} 
.mp .cont_inner .sub_cont {height:auto;}
.myfaq .abox {padding-left:0;}
.mp .tab_style03 {padding:5px 20px}
.mp .mybox .m_exp {padding:0 20px;}
.mp .mybox .inquery {margin:0; padding:20px;}

/* 집내놓기 공동중개 동의 */
.chk_agree {padding-bottom:40px;}
.chk_agree strong {float:none;font-size:16px;}
.chk_agree .m_exp {font-size:13px;margin-top:10px;padding-bottom:80px;}
.regi .chk_agree .check {height:auto;}

/* 다윈에만 있는 매물리스트 */
.dawinapt .item-card .item_info_area {display:flex;padding:20px;position:relative;}
.dawinapt .item-card-container .item-card {padding:0;min-height:165px;}
.go_detail {position:absolute;right:15px;top:125px;font-size:12px;padding:3px 15px 3px 5px;border:1px solid #eaeaea;background:#fff;border-radius:30px;}
.go_detail::after {display:inline-block;content:'';width:9px;height:9px;background:url(../images/ico_bullet.png) no-repeat;background-size:contain;position:absolute;right:2px;top:50%;transform:translatey(-50%)}
.noimg .go_detail {top:100px;}
.dawinapt .d_date, .dawinapt .d_comment {display:block;position:relative}
.dawinapt .d_date {font-size:15px;font-weight:bold}
.dawinapt .d_comment {margin-bottom:12px}
.dawinapt .d_comment {font-size:13rem;color:#9d9d9d;}
.dawinapt .info_floor:after {display:none;}
.dawinapt .item-card.noimg .item_info_area {display:block;}
.dawinapt .item-card.apt.noimg .item_info {margin-bottom:8px}
.dawinapt .itmbox {border-top:1px solid #dedede;margin-bottom:0;font-size:15px;background:none;}
.dawinapt .itmbox:first-child {border-top:none}
.dawinapt .view_all {display:inline-block;font-size:12px;padding:3px 10px;border-radius:50px;border: 1px solid #dadada;color:#929292;position:absolute;right:20px;top:20px;background:#fff}
.dawinapt .itmbox .maparea, .contactag .maparea {height:240px;border:0;position:relative;margin:0 -20px}
.dawinapt .pointer02, .contactag .pointer02 {position:absolute}
.dawinapt .pointer02 button, .contactag .pointer02 button {display:inline-block;background: url(../images/ico_pointer02.png) no-repeat;width:16px;height:25px;text-indent:-9999px;background-size:contain;}
.dawinapt .pointer02 button.on, .contactag .pointer02 button.on {background: url(../images/ico_pointer02_on.png) no-repeat;background-size:contain;}
.pointer02 .nm_score {display:inline-block;position:absolute;left:50%;top:-20px;transform:translateX(-50%);color:#fff;background:#eb6100;padding:1px 5px 1px;font-size:11px;border-radius:30px;}
.panel-group.dawinapt .filter-container {position:relative;/*border-bottom:none*/}
// .dawinapt .panel-box {margin-top:52px;/*height:calc(100% - 176px)!important*/}
.dawinapt .fd-radio .rd01>input[type=radio] + label, .dawinapt .fd-check .chk01>input[type=checkbox] + label {line-height:26px;}
.dawinapt .filter_wrap {height:39px;}
.dawinapt .filter_wrap li {line-height:39px}
.dawinapt .item-card.noimg {min-height:152px;}
.dawinapt .fd-check, .dawinapt .fd-radio {flex-wrap:wrap}
.dawinapt .btn_space_box {top:66px;}
.aptdetail .item-card {min-height:140px;}
.pop-complete .panel-box .agent-card {margin-bottom:10px;width:auto;border:none;padding:15px 30px 15px 10px;position:relative;height:auto;box-sizing:border-box}
.popxlg .container {z-index:101}

/* 중개사아카데이 개요 추가 */
.joinagent-main .submain-box.stup01.academy {background:#f9f9f9;padding-top:140%}
.joinagent-main .submain-box.stup01.academy .row {padding:0 30px}
.summary_group {margin-top:30px;}
.summary_lst {display:flex;font-size:18px;align-items:baseline;flex-wrap:nowrap;margin-bottom:10px;}
.summary_lst span:first-child {display:block;padding:3px 8px 4px;flex-basis:60px;background:#9d3115;color:#fff;border-radius:50px;margin-right:15px;font-size:14px;text-align:center;}
.summary_lst span:last-child {text-align:left;width:250px}
.summary_lst span small {color:#aeaeae}

/* 다윈중개매물 탭추가 */
// .dawinapt>.danjidetail_link {position:relative;top:54px;/*padding-top:10px;*/}
// .panel-group.dawinapt .filter-container {top:97px;}
// .dawinapt .panel-box {margin-top:97px;/*height: calc(100% - 216px)!important;*/overflow-y:scroll}
// .panel-group.dawinapt .filter-container:nth-child(5) {border-bottom:1px solid #ccc;}

/* 전화로 집내놓기 텍스트 추가 */
.regi-phone .box-inner .p03 small {font-size:15px;color:#666;display:block;margin-top:5px;margin-bottom:10px}

/* 210927 마이페이지 내집정보 */
.mp .aptdetail .panel-box .mysell .bk_pee {min-width:unset;max-width:unset;width:140px;}
.mp .aptdetail .panel-box .mysell .add-info {position:absolute;right:0;top:-45px;width:150px;}
.mp .aptdetail .panel-box .mysell .add-info .btn-basic {height:32px;font-size:14px;border-radius:3px;}
.mp .aptdetail .panel-box .mysell .add-info .btn-primary {width:150px;height:32px;font-size:14px;border-radius:3px;}
.mp .aptdetail .panel-box .mysell .add-info p {font-size:12px;position:absolute;right:0;top:36px;width:180px;color:#919191}
.mp .aptdetail .panel-box .mysell .add-info .btn-primary + p {width:214px}
.mp .aptdetail .panel-box .mysell .pee_wrap {padding-top:30px}
.mp .aptdetail .panel-box .mysell .add_text {margin-top:15px;font-size:13px;}
.mp .panel-box .mysell .bk_pee .gBar.dawinpee span {font-size:22px;}

/* 211018 중개사 아카데이 신청하기 팝업 */
.lyr_inner .cont {margin-bottom:20px;}
.info_summ {margin-top:20px;margin-bottom:20px;font-size:16px;}
.info_summ span {display:block;text-align:left;}
.info_summ small {display:block;color:#666;margin-top:10px;text-align:left;font-size:14px;margin-left:0}
.ja_apply .add_noti {margin-bottom:10px}
.ja_apply .lyr .btn-horiz .btn {margin-right:8px;height:40px !important;/*width:auto;*/}
.ja_apply .lyr .btn-horiz .btn:last-child {margin-right:0}
.ja_apply .radio-date .radio-box .radio {margin-bottom:7px;}
.ja_apply .radio-date .radio-box strong {float:unset;word-break:break-all;}

/* 211020 마이페이지 툴팁 */
.mp .sub_cont {margin:0 auto;padding:70px 0 90px;-webkit-flex: none;-ms-flex: none;flex: none;height:100%;box-sizing: border-box;overflow-y: scroll;}
.mp .sub_cont .icon-help[data-tooltip-text]:hover:after {left:50%;transform:translatex(-50%)}

.ja_apply .radio-career .radio-box .radio {float:none;width:100%;display:block;}
/* .ja_apply .btn-horiz {width:100%;position:fixed;left:0;bottom:0} */
.lyrpop01 .btn-horiz {justify-content: center;}

/* 211201 내정보 */
.mp .ins .set-nick-area {padding:0}
.mp .ins .set-nick-area .inp {background:#fff;}
.mp .ins .set_area .inp-horiz {margin-bottom:10px;margin-top:10px;}
.mp .ins .set-nick-area .add_text {margin-top:0;padding-top:0;}

:root.root-dawin {
	.unit {font-size:14px;position:absolute;right:15px;top:16px}
}