// .form {
// 	.form-elem {
// 		position: relative;
// 		margin: 0;
// 		padding: 0;
// 		appearance: none;
// 	}
// }

.form {
	position: relative;

	.form-elem {
		position: relative;
		margin: 0;
		padding: 0;
		border-radius: 0;
		background-color: transparent;
		appearance: none;

		&::-webkit-search-cancel-button {
			display: none;
		}

		&::-ms-clear{
			display: none;
		}

		&:not(.typed)~.form-func {
			.form-cancel {
				display: none !important;
			}
		}

		&.typed~.form-func {
			.form-cancel {
				display: block;
				opacity: 0;
				pointer-events: none;
				background-repeat: no-repeat;
				background-position: 0 0;
				background-size: cover;
			}
		}

		&.typed:focus~.form-func,
		&.typed:hover~.form-func {
			.form-cancel {
				opacity: 1;
			}
		}
	}
}

label.form {
	display: inline-flex;
	vertical-align: top;
}

/*
 * form module-a system
 */
.form.module-a {
	display: inline-flex;
	position: relative;
	// border: 1px solid red;

	.form-elem {
		flex-shrink: 0;
		position: relative;
		box-sizing: border-box;
	}

	.form-text {
		display: block;
	}

	&.checkbox,
	&.radio {
		.form-elem {
			display: flex;
			justify-content: center;
			align-items: center;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		.form-text {
			color: color(normal, 010);
		}
	}
	
	&.checkbox {
		// outline
		&.small-1x {
			column-gap: 4rem;

			.form-elem {
				width: 24rem;
				height: 24rem;
			}

			.form-text {
				@include font((outline: body, size: small-1x)...);
			}
		}

		&.style-b {
			.form-elem {
				&:not(:checked) {
					background-image: icon(checkbox-default, color(normal, 030));
				}
			}

			&.accent-01 {
				.form-elem {
					&:checked {
						background-image: icon(checkbox-checked, color(accent, 010));
					}
				}

				.form-elem {
					&:disabled {
						background-image: icon(checkbox-default, color(base, 010));
						
						~ .form-text {
							color: color(base, 020);
						}
					}
				}
			}
		}

		&.style-c {
			.form-elem {
				&:not(:checked) {
					// background-image: icon(checkbox-default, color(normal, 040));
				}
			}

			&.type-fill {
				.form-elem {
					&:not(:checked) {
						background-image: icon(check-fill-round, color(normal, 040));
					}
				}
			}

			&.accent-01 {
				.form-elem {
					&:checked {
						background-image: icon(check-fill-round, color(accent, 010));
					}
				}
			}
		}
	}

	&.radio {
		// outline
		&.small-1x {
			column-gap: 4rem;

			.form-elem {
				width: 24rem;
				height: 24rem;
			}

			.form-text {
				@include font((outline: body, size: small-1x)...);
			}
		}

		&.style-c {
			.form-elem {
				&:not(:checked) {
					background-image: icon(radio-default, color(normal, 030));
				}
			}

			&.accent-01 {
				.form-elem {
					&:checked {
						background-image: icon(radio-checked, color(accent, 010));
					}
				}
			}

			&.type-fill {
				.form-elem {
					&:not(:checked) {
						background-image: icon(radio-fill-round, color(normal, 040));
					}
					&:checked {
						background-image: icon(radio-fill-round, color(accent, 010));
					}
				}
			}
			
		}
	}


	&.switch {
		.form-elem {
			margin: 2rem 0;
			padding: 2rem;
			width: 36rem;
			height: 20rem;
			border-radius: calc(20rem * 0.5);
			background-color: color(base, 020);
			transition: background-color 0.1s ease-out;
			
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 16rem;
				height: 16rem;
				border-width: 2rem;
				border-style: solid;
				border-color: color(base, 020);
				border-radius: calc(20rem * 0.5);
				background-color: color(base, 000);
				transition: left 0.1s ease-out, border-color 0.1s ease-out;
			}

			&:checked {
				background-color: color(accent, 010);
				&::after {
					left: calc(100% - 20rem);
					border-color: color(accent, 010);
				}
			}
		}

		.form-text {
			color: color(normal, 010);
		}

		// outline
		&.small-1x {
			column-gap: 4rem;

			.form-text {
				@include font((outline: body, size: small-1x)...);
			}
		}
	}
}


.form.range {
	position: relative;

	.form-step-list {
		display: flex;
		justify-content: space-between;
		margin: 0 20rem;

		.form-step-item {
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			white-space: nowrap;
			width: 0;
			@include font((outline: body, size: small-3x)...);
			color: color(base, 080);
			text-align: center;

			&:not(:last-child):not(:first-child)::before {
				content: '';
				flex-shrink: 0;
				display: block;
				position: absolute;
				top: 20rem;
				transform: translate(-1rem, -1rem);
				width: 2rem;
				height: 2rem;
				background-color: color(base, 000);
			}
		}
	}

	&.attr-tooltip {
		.form-range-slider {
			span[class*=thumb-] {
				&::after {
					content: attr(data-bui-value);
					display: flex;
					align-items: center;
					position: absolute;
					bottom: 100%;
					height: 24rem;
					@include font((outline: body, size: small-2x)...);
					color: color(base, 080);
					text-align: center;
					padding: 0 8rem;
	
					opacity: 0;
					transform: scale(0);
					transition: all 0.15s ease-out;
	
					border: 1rem solid color(base, 020);
					border-radius: 16rem;
					background-color: color(base, 000);
				}
	
				&.active::after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}

	.form-range-slider {
		display: block;
		position: relative;
		margin: 0 20rem;
		height: 40rem;

		.entered-track {
			display: block;
			position: absolute;
			top: calc(50% - 1rem);
			right: 0;
			left: 0;
			height: 2rem;
			border-radius: 1rem;
			background-color: color(base, 010);
		}
	
		.entered-value {
			display: block;
			position: absolute;
			top: calc(50% - 1rem);
			right: 25%;
			left: 25%;
			height: 2rem;
			border-radius: 1rem;
			background-color: color(accent, 010);
		}
	
		span[class*=thumb-] {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			z-index: 1;
			top: 0;
			width: 40rem;
			height: 40rem;
			pointer-events: none;

			&:not(.disabled)::before {
				content: '';
				display: block;
				width: 24rem;
				height: 24rem;
				border-radius: 50%;
				box-sizing: border-box;
				border: 8rem solid color(accent, 010);
				background-color: color(base, 000);
			}

			&.disabled::before {
				content: '';
				display: block;
				width: 2rem;
				height: 12rem;
				background-color: color(accent, 010);
			}
	
			&.thumb-minimum {
				left: 25%;
				transform: translateX(-20rem);
			}
	
			&.thumb-maximum {
				right: 25%;
				transform: translateX(20rem);
			}
		}
	}
	
	input[type=range] {
		position: absolute;
		display: flex;
		align-items: center;
		z-index: 2;
		width: 100%;
		height: 40rem;
		pointer-events: none;
		background: transparent;
		opacity: 0;

		&::-webkit-slider-thumb {
			width: 40rem;
			height: 40rem;
			border: 0 none transparent;
			border-radius: 0;
			pointer-events: auto;
			-webkit-appearance: none;
		}
		&::-webkit-slider-thumb {
			width: 40rem;
			height: 40rem;
			border: 0 none transparent;
			border-radius: 0;
			pointer-events: auto;
			-webkit-appearance: none;
		}
		&::-moz-range-thumb {
			width: 40rem;
			height: 40rem;
			border: 0 none transparent;
			border-radius: 0;
			pointer-events: auto;
			-webkit-appearance: none;
		}
	}
}

// .form.grade {
//     -webkit-box-flex: 1;
//     -ms-flex-positive: 1;
//     flex-grow: 1;
//     width: 100%;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: flex-start;
// 	position: relative;

// 	.form-elem {
// 		margin: 0;
// 		padding: 0;
// 		overflow: visible;
// 		-webkit-appearance: none;
// 		-moz-appearance: none;
// 		appearance: none;
// 		width: 40rem;
// 		height: 40rem;
// 		border: 0 none;
// 		background-color: transparent;

// 		&:focus {
// 			z-index: 1
// 		}
// 	}

// 	.form-text {
// 		display: -webkit-box;
// 		display: -ms-flexbox;
// 		display: flex;
// 		-webkit-box-pack: center;
// 		-ms-flex-pack: center;
// 		justify-content: center;
// 		-webkit-box-align: center;
// 		-ms-flex-align: center;
// 		align-items: center;
// 		position: relative;
// 		width: 40rem;
// 		height: 40rem;
// 		margin-left: -40rem;
// 		text-indent: -9999rem;
		
		
// 		&::before {
// 			content: '';
// 			display: block;
// 			position: absolute;
// 			top: 0;
// 			right: 0;
// 			bottom: 0;
// 			left: 0;			
// 			background-repeat: no-repeat;
// 			background-size: center center;
// 			background-image: 40rem 40rem;
// 			background-image: icon(star, #ffa800);
// 		}
// 	}

// 	.form-elem:checked~.form-elem+.form-text::before {
// 		background-image: icon(star, color(normal, 040));
// 	}
// }


.form.grade {
	display:flex;
	flex-direction: row-reverse;
	justify-content:space-around;
	text-align:center;

	input {
		display:none;
	}
	
	label {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		width: 40rem;
		height: 40rem;
		text-indent: -9999rem;
		background-repeat: no-repeat;
		background-size: center center;
		background-image: 40rem 40rem;
		background-image: icon(star, color(base, 020));
	}
}

.form.grade :checked ~ label {
	background-image: icon(star, #ffa800);	
}
  
.form.grade label:hover,
.form.grade label:hover ~ label {
	background-image: icon(star, #ffa800);	
}


/*
 * module-b outline
 *
 * @ $padding
 * @ $height
 * @ $line-height
 * @ $font-size
 * @ $font-weight
 * @ $border-width
 */  

$form-module-b-outline: (
	'small'			calc(28rem * 0.25) 28rem 18rem calc(18rem / 1.5) 300 1rem,
	'medium'		calc(32rem * 0.25) 32rem 20rem calc(20rem / 1.5) 300 1rem,
	'large'			calc(40rem * 0.25) 40rem 20rem calc(20rem / 1.5) 300 1rem,
	'large-1x'		calc(48rem * 0.25) 48rem 24rem calc(24rem / 1.5) 300 1rem,
	'large-2x'		calc(56rem * 0.25) 56rem 24rem calc(24rem / 1.5) 300 1rem,
);

/**
 * @ module-b accent
 *
 * @ $color
 * @ $color-unit
 * @ $color-placeholder
 * @ $color-disabled
 *
 * @ $border-color
 *
 * @ $background-readonly
 * @ $background-disabled
 * @ $background-color
 */

$form-module-b-accent: (
	'normal-00': (
		'none' color(base, 100) color(base, 020) color(base, 020) color(base, 040)			transparent				transparent transparent transparent,
		'line' color(base, 100) color(base, 020) color(base, 020) color(base, 040)			color(base, 100)		color(base, 005) color(base, 010) color(base, 000),
		'fill' color(base, 000) color(base, 000) color(base, 000) color(base, 040)			color(base, 100)		color(base, 010) color(base, 010) color(base, 100),
	),
	'normal-01': (
		'none' color(base, 080) color(base, 020) color(base, 020) color(base, 040)			 transparent			transparent transparent transparent,
		'line' color(base, 080) color(base, 020) color(base, 020) color(base, 040)			 color(base, 080)		color(base, 005) color(base, 010) color(base, 000),
		'fill' color(base, 000) color(base, 000) color(base, 000) color(base, 000)			 color(base, 080)		color(base, 090) color(base, 090) color(base, 080),
	),
	'normal-02': (
		'none' color(base, 080) color(base, 020) color(base, 020) color(base, 040)			transparent				transparent transparent transparent,
		'line' color(base, 080) color(base, 020) color(base, 020) color(base, 040)			color(base, 060)		color(base, 005) color(base, 010) color(base, 000),
		'fill' color(base, 000) color(base, 000) color(base, 000) color(base, 000)			color(base, 060)		color(base, 070) color(base, 070) color(base, 060),
	),
	'normal-03': (
		'none' color(base, 080) color(base, 020) color(base, 020) color(base, 040)			transparent				transparent transparent transparent,
		'line' color(base, 080) color(base, 020) color(base, 020) color(base, 040)			color(base, 040)		color(base, 005) color(base, 010) color(base, 000),
		'fill' color(base, 000) color(base, 000) color(base, 000) color(base, 000)			color(base, 040)		color(base, 030) color(base, 030) color(base, 040),
	),
	'normal-04': (
		'none' color(base, 080) color(base, 020) color(base, 020)  color(base, 040)			transparent				transparent transparent transparent,
		'line' color(base, 080) color(base, 020) color(base, 020)  color(base, 040)			color(base, 020)		color(base, 005) color(base, 010) color(base, 000),
		'fill' color(base, 000) color(base, 000) color(base, 030)  color(base, 000)			color(base, 020)		color(base, 010) color(base, 010) color(base, 020),
	),
	'accent-01': (
		'none' color(accent, 010) color(accent, 010) color(base, 030)  color(base, 030)		transparent				transparent transparent transparent,
		'line' color(accent, 010) color(accent, 030) color(base, 030)  color(base, 030)		color(accent, 010)		color(accent, 010) color(base, 010) color(base, 000),
		'fill' color(accent, 000) color(accent, 000) color(base, 030)  color(base, 030)		color(accent, 010)		color(accent, 010) color(base, 010) color(accent, 010),
	),
);


.form.module-b {
	display: inline-flex;
	position: relative;

	.form-elem {
		position: relative;
		box-sizing: border-box; // textarea 적용안됨
		@include font((family: default)...);

		&[type=text] {
			&::-webkit-calendar-picker-indicator {
				display: none;
			}
		}

		&[type=search] {
			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration{
				display:none;
			}
		}
	}

	&.checkbox,
	&.radio {
		.form-elem {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			&:checked {
				~.form-text {
					font-weight: 500;
					color: color(base, 000);
				}
			}

			&:disabled {
				~ .form-text {
					color: color(base, 020);
					border-color: color(base, 010);
					background-color: color(base, 005);
				}
			}
		}

		.form-text {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-style: solid;
			box-sizing: border-box;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			color: color(base, 040);
			border-color: color(base, 020);
			background-color: color(base, 000);

			&::before {
				content: '';
				display: block;
			}
		}

		&.normal-01 {
			.form-elem {
				&:checked {
					~.form-text {
						border-color: color(normal, 010);
						background-color: color(normal, 010);
					}
				}
			}
		}

		&.normal-02 {
			.form-elem {
				&:checked {
					~.form-text {
						border-color: color(normal, 020);
						background-color: color(normal, 020);
					}
				}
			}
		}

		&.normal-03 {
			.form-elem {
				&:checked {
					~.form-text {
						border-color: color(normal, 030);
						background-color: color(normal, 030);
					}
				}
			}
		}

		&.normal-04 {
			.form-elem {
				&:checked {
					~.form-text {
						border-color: color(normal, 040);
						background-color: color(normal, 040);
					}
				}
			}
		}

		&.accent-01 {
			.form-elem {
				&:checked {
					~.form-text {
						border-color: color(accent, 010);
						background-color: color(accent, 010);
					}
				}
			}
		}
	}
	&.radio {
		.form-text::before {background-image: icon(radio-small, color(base, 040));}
		.form-elem {
			&:checked~.form-text::before {background-image: icon(radio-small, color(base, 000));}
			&:disabled ~.form-text::before {background-image: icon(radio-small, color(base, 020));}
		}
	}
	&.checkbox {
		.form-text::before {background-image: icon(check-small, color(base, 040));}
		.form-elem {
			&:checked~.form-text::before {background-image: icon(check-small, color(base, 000));}
			&:disabled ~.form-text::before {background-image: icon(check-small, color(base, 020));}
		}
	}


	// cancel button
	&.date,
	&.textarea,
	&.select,
	&.search,
	&.textfield {
		.form-elem {
			flex: 1 1 100%;
			width: 100%;
			border-style: solid;
		}

		.form-func {
			display: flex;
			align-items: center;
			position: absolute;

			top: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;

			.form-cancel {
				display: none;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}

			.form-delete {
				width: 24rem;
				height: 24rem;
				cursor: pointer;
				flex-shrink: 0;
				justify-content: center;
				align-items: center;
				display: flex;
				pointer-events: auto;
			}

			.btn.search {
				position: relative;
				pointer-events: auto;

				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}

				.btn-text {
					@include hidden;
				}
			}

			.btn.send {
				position: relative;
				pointer-events: auto;

				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}

				.btn-text {
					@include hidden;
				}
			}

			.btn.select {
				position: relative;
				pointer-events: auto;

				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}

				.btn-text {
					@include hidden;
				}
			}
		}
	}

	&:hover.typed,
	&:focus-within.typed {
		.form-func {
			.form-cancel {
				display: block;
			}
		}
	}

	// select
	&.select {
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}

	&.textarea:not([data-bui-form-value]) {
		.form-elem {
			flex: 1 1 100%;
			resize: none;
			@at-root html.mobile & {
				@include overflow-scroll-system(dark, medium, y);
			}
	
			@at-root html.laptop & {
				@include overflow-scroll-custom(dark, medium, y);
			}
		}
	}

	&.textarea[data-bui-form-value] {
		&::before {
			content: attr(data-bui-form-value) " ";		
			flex: 1 1 100%;
			display: block;
			opacity: 0;
			pointer-events: none;
			white-space: pre-wrap;
			word-break: break-all;
			word-wrap: break-word;
		}

		.form-elem {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			resize: none;
			overflow: hidden;
		}
	}

	&.quantity {
		display: flex;

		input[type="number"]::-webkit-outer-spin-button,
		input[type="number"]::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		.form-elem{
			order: 2;
			text-align: center;
			border: 1px solid #333;
			border-left: none;
			border-right: none;
		}
		.form-step-up,
		.form-step-down{
			border: 1px solid #333;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: default;
			@include font((family: default, size: small)...);

			&::after{
				content: '';
				display: block;
				pointer-events: none;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}

		}
		.form-step-up{
			order: 3;
		}
		.form-step-down{
			order: 1;
		}
	}

	// outline
	@each $name, $padding, $height, $line-height, $font-size, $font-weight, $border-width in $form-module-b-outline {
		&.#{$name} {

			&.style-b {
				.form-elem {
					border-radius: $height * 0.1;
				}
			}

			&.style-c {
				.form-elem {
					border-radius: $height * 0.5;
				}
			}

			&.checkbox,
			&.radio {
				&.style-b {
					.form-text {
						border-radius: $height * 0.1;
					}
				}
				&.style-c {
					.form-text {
						border-radius: $height * 0.5;
					}
				}
				.form-text {
					padding-left: $line-height * 0.25;
					padding-right: $line-height * 0.75;
					height: $height;
					font-size: $font-size;
					border-width: $border-width;

					&::before {
						width: $line-height;
						height: $line-height;
					}
				}
			}

			// textfield, select outline
			&.textfield {
				.form-label {
					display:none;
				}
				.form-elem{
					text-indent: $padding;
					padding-right: $padding;
					height: $height;
					line-height: 1;
					font-size: $font-size;
					border-width: $border-width;
					text-overflow: ellipsis;
				}

				.form-func {
					padding: 0 $padding;
					column-gap: calc(($line-height / 1.5) / 4);
					line-height: $line-height;
					font-size: $font-size;
	
					.form-cancel {
						width: $line-height;
						height: $line-height;
					}

					.btn.search {
						width: $line-height;
						height: $line-height;
						.btn-text {
							font-size: calc($line-height/2);
						}
					}
					.btn.send {
						width: $line-height;
						height: $line-height;
						.btn-text {
							font-size: calc($line-height/2);
						}
					}
					.btn.select {
						width: $line-height;
						height: $line-height;
						.btn-text {
							font-size: calc($line-height/2);
						}
					}
				}
			}

			&.date {
				.form-elem{
					padding-left: $padding;
					padding-right: $padding;
					height: $height;
					line-height: 1;
					font-size: $font-size;
					border-width: $border-width;
					text-overflow: ellipsis;
				}
			}

			&.search,
			&.select {
				.form-elem{
					padding-left: $padding;
					padding-right: calc($padding * 2 + $line-height);
					height: $height;
					line-height: 1;
					font-size: $font-size;
					border-width: $border-width;
					text-overflow: ellipsis;
				}

				&::after {
					padding: 0 $padding;
					width: $line-height;
					background-size: $line-height $line-height;
				}
			}

			// textarea
			&.textarea {
				.form-elem{
					padding: calc(($height - $line-height) / 2) $padding;
					line-height: $line-height;
					font-size: $font-size;
					border-width: $border-width;
				}
			}

			&.textarea[data-bui-form-value] {
				padding: calc(($height - $line-height) / 2) $padding;
				line-height: $line-height;
				font-size: $font-size;
				border-width: $border-width;
			}
		}
	}

	@each $accent-name in $form-module-b-accent {
		&.#{nth($accent-name, 1)} {
			// @debug "divider offset: #{nth($accent-name, 1)}";
			@each $type-name, $color, $color-unit, $color-placeholder, $color-disabled, $border-color, $background-readonly, $background-disabled, $background-color  in map-get($form-module-b-accent, nth($accent-name, 1)) {
				&.type-#{$type-name} {
		
					&.file,
					&.select,
					&.search,
					&.date,
					&.textfield,
					&.textarea {
						.form-text {
							color: $color;
						}
						.form-elem {
							color: $color;
							border-color: $border-color;
							background-color: $background-color;

							&::placeholder {
								color: $color-placeholder;
							}
						}
						&.invalid {
							.form-elem {
								border-color: color(accent, 010);
							}
						}

						.form-func {
							.form-cancel {
								background-image: icon(cancel, $color-unit);
							}
							.form-delete {
								background-image: icon(cancel, $color-unit);
							}
							.btn.search {
								&::after {
									background-image: icon(dawin-search, $color);
								}
							}
							.btn.send {
								&::after {
									background-image: icon(send, $color);
								}
							}
							.btn.select {
								&::after {
									background-image: icon(drop-down-small, $color);
								}
							}
							.form-unit {
								color: $color;
							}
						}
						.form-util {
							
							.form-cancel {
								background-image: icon(cancel, $color-unit);
							}
						}
					}

					&.select {
						&::after {
							background-image: icon(drop-down-small, $color);
						}
					}
					
					&.quantity{
						.form-elem {
							color: $color;
							border-color: $border-color;
							background-color: $background-color;
						}
						.form-step-up {				
							border-color: $border-color;		
							&::after {
								background-image: icon(fill-chevron-up, $color-unit);
							}
						}
						.form-step-down {		
							border-color: $border-color;				
							&::after {
								background-image: icon(fill-chevron-down, $color-unit);
							}
						}
					}
				

					//disabled
					&.search,
					&.select,
					&.textfield,
					&.textarea {
						.form-elem {
							&:disabled {
								color: $color-disabled;
								background-color: $background-disabled;
							}	
						}
					}

					//readonly
					&.search,
					&.textfield,
					&.textarea {
						.form-elem {
							&:read-only {
								background-color: $background-readonly;
							}
						}
					}
				}
			}
		}
	}
}


.file-uploader.module-a {
	.file-list {
		display: flex;
		flex-wrap: wrap;
		gap: unset;

		.file-item {
			flex-shrink: 0;
			display: flex;
			@include display-column(3, 8rem, 8rem);
			max-width: 96rem;

			.form.file {
				flex-shrink: 0;
				display: flex;
				// width: 96rem;
				height: 96rem;
				flex: 1 1 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				width: 100%;
				aspect-ratio: 1 / 1;
				border-radius: 8rem;
				overflow: hidden;
				box-shadow: inset 0 0 0 1rem rgba(0,0,0,0.1);
				height: unset;
				max-width: 96rem;

				&::before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border: 1rem dashed color(normal, 100, 0.2);
					border-radius: 8rem;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					background-origin: border-box;
					background-image: var(--background-image);
				}
				
				&[data-bui-form-file-name] {
					&::before {
						border-style: solid;
					}

					.form-elem {
						pointer-events: none;
					}
				}

				&:not([data-bui-form-file-name]) {
					&::after {
						content: '사진추가';
						display: flex;
						justify-content: center;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						padding-top: calc(50% + 8rem);
						pointer-events: none;
						color: color(normal, 040);
						@include font((outline: body, size: small-2x)...);
						background: icon(add-photo, color(normal, 040)) no-repeat center calc(50% - 8rem)/24rem;
					}
				}
		
				.form-elem {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					cursor: pointer;
					margin: 0;
					padding: 0;
					font-size: 0;
					border: 0 none;
					border-radius: 0;
					background-color: transparent;
					appearance: none;
		
					&::-webkit-file-upload-button {opacity: 0;}
					&::file-selector-button {opacity: 0;}
				}
		
				.form-func {
					position: absolute;
					top: 0;
					right: 0;

					.btn.delete {
						position: relative;
						justify-content: center;
						align-items: center;
						width: 32rem;
						height: 32rem;
						// mix-blend-mode: difference;

						svg {
							fill: rgba(0,0,0,0.75);
						}
					}
				}
			}
		}
	}
}

.file-uploader.module-a {
	&.style-b {
		.file-item {
			display: flex;
			flex-shrink: 0;
			width: 40rem;
			height: 48rem;
	
			.form.file {
				flex-shrink: 0;
				display: flex;
				width: 40rem;
				height: 48rem;
				flex: 1 1 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				width: 100%;
				aspect-ratio: 1 / 1;
	
				&::before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					width: 32rem;
					height: 32rem;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					background-origin: border-box;
					background-image: icon(add-file, color(normal, 020));
					top: 50%;
    				transform: translatey(-50%);
				}
					
				&[data-bui-form-file-name] {
					&::before {
						border-style: solid;
					}
	
					.form-elem {
						pointer-events: none;
					}
				}	
			
				.form-elem {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					cursor: pointer;
					margin: 0;
					padding: 0;
					font-size: 0;
					border: 0 none;
					border-radius: 0;
					background-color: transparent;
					appearance: none;
			
					&::-webkit-file-upload-button {
						opacity: 0;
					}
					&::file-selector-button {
						opacity: 0;
					}		
				}
			}
		}
	}	
}