@charset "UTF-8";
/* CSS Document */

:root.root-dawin {
	/* 220711 매물관리 */
	// .bk.bk-resp {
	// 	min-width:unset;
	// }
	// .bk.bk-resp .sect_box01 {
	// 	width:80%
	// }
	// .bk.bk-resp .sect_box02.sect-box-resp {
	// 	padding:0;
	// 	border:1px solid rgb(208, 210, 214);
	// }
	// .bk.bk-resp .section-body .search_area {
	// 	display:flex;
	// 	column-gap: 8px;
	// 	width:60%
	// }
	// .bk.bk-resp .sectask .search_area,
	// .bk.bk-resp .sectask .filter-area {
	// 	margin-bottom:8px;
	// }
	// .bk.bk-resp .sectask .search_area .btninp,
	// .bk.bk-resp .sectask .search_area .btninp .inp {
	// 	width:100%;
	// }
	// .table-grid {
	// 	position:relative;
	// }
	// .grid-thead {
	// 	position:absolute;
	// 	top:0;
	// 	left:0;
	// 	width:100%;
	// }
	// .grid-tr {
	// 	display: grid;
	// 	grid-template-columns: 4% 8% 8% 8% 18% 8% 14% 10% 7% 10% 5%;
	// 	place-items: center;
	// 	border-bottom:1px solid #eaeaea;
	// 	/* grid-auto-rows: 1fr */
	// }
	// .grid-tr.grid-merge {
	// 	grid-template-columns: 100%;
	// }
	// .grid-thead .grid-tr {
	// 	background:#fafafa;
	// 	border-bottom:1px solid #eaeaea;
	// }
	// .grid-tbody .grid-tr:last-child {
	// 	border-bottom:none
	// }
	// .grid-tbody {
	// 	padding-top:54px;
	// 	/* max-height:600px;
	// 	overflow-y:scroll */
	// }
	// .grid-tbody .grid-tr:nth-child(even) {
	// 	background:#fafafa;    
	// }
	// .grid-cell {
	// 	padding:6px 4px;
	// 	font-size:13px;
	// 	word-break: break-word;
	// }
	// .bk.bk.bk-resp .sec_inner,
	// .bk.bk.bk-resp .sectask {
	// 	min-width:unset;
	// 	padding:32px;
	// 	margin:unset;
	// 	width:100%;
	// 	max-width:100%;
	// }
	// .bk.bk.bk-resp .filter-area .inp-horiz {
	// 	display:flex;
	// 	flex-flow:row nowrap;
	// 	column-gap: 8px;
	// 	flex:auto;
	// }
	// .bk.bk.bk-resp .filter-area .selec {
	// 	margin:0;
	// }
	// .grid-cell .sup {
	// 	display:block;
	// }
	// .cell-viewmore {
	// 	width:100%;
	// }
	// .cell-viewmore .viewmore {
	// 	display:block;
	// 	width:100%;
	// 	height:100%;
	// 	padding:8px 0
	// }
	// .bk.bk-resp .section-head .menu {
	// 	display:none
	// }
	// .bk.bk-resp .btn-refresh {
	// 	display:block;
	// 	width:44px;
	// 	height:44px;
	// 	margin-top:0;
	// 	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EActions / Operations / renew / 24%3C/title%3E%3Cg id='메인' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='signup_최종05' transform='translate(-321.000000, -23.000000)' fill='%23000000'%3E%3Cg id='Actions-/-Operations-/-renew-/-24' transform='translate(320.000000, 22.000000)'%3E%3Cpath d='M6,5 L3.39,5 C4.71806723,2.9589652 7.2301519,2.03417266 9.56461657,2.72689214 C11.8990812,3.41961163 13.500155,5.56492596 13.5,8 L14.5,8 C14.5028208,5.26026146 12.7874438,2.81290339 10.211088,1.88093466 C7.63473212,0.948965933 4.75061284,1.73250624 3,3.84 L3,2 L2,2 L2,6 L6,6 L6,5 Z M9.99999653,11 L12.61,11 C11.2819328,13.0410348 8.7698481,13.9658273 6.43538343,13.2731079 C4.10091875,12.5803884 2.49984502,10.435074 2.49999653,8 L1.49999653,8 C1.49717924,10.7397385 3.21255615,13.1870966 5.78891202,14.1190653 C8.36526788,15.0510341 11.2493872,14.2674938 12.9999965,12.16 L12.9999965,14 L13.9999965,14 L13.9999965,10 L9.99999653,10 L9.99999653,11 Z' id='Fill'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	// 	background-color:#fff;
	// 	background-repeat: no-repeat;
	// 	background-position:50%;
	// 	border:1px solid rgb(208, 210, 214);
	// 	flex-shrink: 0;
	// 	margin-left:0;
	// }
	// .bk.bk-resp .real-price {
	// 	font-size:13px;
	// }
	// .bk.bk-resp .real-price i {
	// 	margin-right:0;
	// }

	@media screen and (max-width: 1054px) {
		// .bk.bk-resp section {
		// 	padding-left:0;
		// 	padding-top:50px;
		// }
		// .bk.bk-resp nav {
		// 	display:none;
		// }    
		// .bk.bk-resp .section-head .menu {
		// 	display:block;
		// }
		// .grid-thead .grid-cell {
		// 	font-size:12px;
		// }
		// .grid-tbody {
		// 	padding-top:100px;
		// 	/* max-height:600px;
		// 	overflow-y:scroll */
		// }
		// .grid-tr {
		// 	display: grid;
		// 	grid-template-columns: 10% 15% 30% 19% 13% 13%;
		// }    
		// .grid-cell {
		// 	width:100%;
		// 	vertical-align: middle;
		// 	place-items: center;
		// 	display:flex;
		// 	align-items: center;
		// 	justify-content: center;
		// 	flex-flow:row wrap;
		// 	padding:8px 4px
		// }
		// .cell-num {
		// 	order:1;
		// 	grid-column: span 1;
		// 	padding-bottom:0
		// }
		// .cell-tradetype {
		// 	order:2;
		// 	grid-column: span 1;
		// 	padding-bottom:0
		// }
		// .cell-name {
		// 	order:3;
		// 	grid-column: span 1;
		// 	padding-bottom:0
		// }
		// .cell-area {
		// 	order:4;
		// 	grid-column: span 1;
		// 	padding-bottom:0;
		// 	display:flex;
		// 	flex-flow:column wrap;
		// }
		// .cell-call {
		// 	order:5;
		// 	grid-column: span 2;
		// 	padding-bottom:0;
		// 	display:flex;
		// 	flex-flow:column wrap;
		// }
		// .cell-itemtype {
		// 	order:6;
		// 	grid-column: span 1;
		// }
		// .cell-address {
		// 	order:7;
		// 	grid-column: span 1;
		// }
		// .cell-price {
		// 	order:8;
		// 	grid-column: span 1;
		// }
		// .cell-modiday {
		// 	order:9;
		// 	grid-column: span 1;
		// 	display:flex;
		// 	flex-flow:column wrap;
		// }
		// .cell-co {
		// 	order:10;
		// 	grid-column: span 1;
		// }
		// .cell-memo {
		// 	order:11;
		// 	grid-column: span 1;
		// }    
	}
	@media screen and (max-width: 768px) {
		// .bk.bk-resp .header {
		// 	display:none;
		// }
		// .bk.bk-resp section {
		// 	padding-top:0;
		// }    
		// .bk.bk.bk-resp .sec_inner,
		// .bk.bk.bk-resp .sectask {
		// 	min-width:unset;
		// 	padding:0;
		// 	margin:unset;
		// 	width:100%;
		// 	max-width:100%;
		// }
		// .bk.bk-resp .cont_wrap {
		// 	display:flex;   
		// 	flex-flow:column wrap;
		// 	height:100%;
		// 	flex: 100%;
		// 	overflow-y:hidden;
		// }    
		// .bk.bk-resp .section-head {
		// 	display:flex;
		// 	flex-flow:row nowrap;
		// 	justify-content: left;
		// 	height:54px;
		// 	padding:0 20px 0 10px;
		// 	align-items: center;
		// 	column-gap: 8px;
		// 	box-sizing:border-box;
		// 	background:#fff;
		// 	border-bottom:1px solid #eaeaea;
		// }
		// .bk.bk-resp .section-body {
		// 	padding:0 20px 32px;
		// 	display:flex;
		// 	flex-flow:column wrap;
		// 	flex: 100%;
		// 	overflow-y:auto;
		// }
		// .bk.bk-resp .section-head .menu {
		// 	width:40px;
		// 	height:40px;
		// }
		// .bk.bk-resp .section-head .btn_menu {
		// 	background: url(../images/img_bk/btn_menu.png) no-repeat;
		// 	width: 40px;
		// 	height: 40px;
		// }
		// .bk.bk-resp .section-head .tit {
		// 	margin-bottom:0;
		// }
		// .bk.bk-resp .section-body .search_area {
		// 	width:100%;
		// 	padding-top:20px;
		// 	display:flex;
		// 	column-gap: 8px;
		// }
		// .bk.bk-resp .search_area .btninp {
		// 	width:100%;
		// }
		// .bk.bk-resp .search_area .btninp .inp {
		// 	width:100%;
		// }
		// .bk.bk.bk-resp .filter-area .inp-horiz {
		// 	flex-flow:row wrap;
		// 	row-gap: 8px;
		// }
		// .bk.bk.bk-resp .filter-area .selec {
		// 	width:calc((100% - 16px) / 3);
		// 	background: #fff url(../images/main/btn_arr02.png) no-repeat 92%;
		// }
		// .bk.bk-resp footer {
		// 	display:none;
		// }		
	}
}