@charset "UTF-8";
@import './../../../assets/scss/partials/_variables';
@import './../../../assets/scss/partials/_mixin';
/* CSS Document */
/* index */
#wrap.main {overflow-y:scroll}
.container {/*display:flex;flex-direction:column;*/width:100%;overflow-x:hidden;padding:55px 0 0;/*height:100vh;*/height:100%;box-sizing:border-box;position:relative;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);-webkit-overflow-scrolling:touch;}
.sub_container {height:100%}
.main .container, .joinagent-main .container, .regi-main .container, .regi-phone .container, .company .container {padding:0;display:block;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
.panel-box {background:#eaeaea;height:auto !important}
.main-header .logo {position:absolute;left:0;top:0;width:55px;height:55px}
.main-header .logo_link {width:50px;height:55px;background-image:url("../images/new/logo_new.png");  background-repeat:no-repeat;background-size:contain;text-indent:-9999px;position:absolute;top:0;left:5px;}
.main-header .logo_link a {display:block;width:100%;height:100%}
.btn-right {display:inline-block}
.btn-right:after {display:inline-block;content:'';clear:both}
.btn-right span {display:inline-block;float:left}
.main-searchtab li {display:inline-block;float:left;width:calc(100% / 2);}
.main-content .main_sch_wrap {width:82%;top:46%}
.main-tit .text01 {font-size:9.8vw;color:rgb(255,255,255);}
.main-tit .text02 {font-size:calc(0.37vw + 26.67px);color:rgb(255,255,255);line-height:1.3;letter-spacing:-2px;font-weight:400}
.main-tit .text02 b {font-size:42px;color:rgb(250,210,0)}
.main-tit .more_s {font-size:calc(0.37vw + 25px);color:rgb(255,255,255);margin-left:0}
.gnb {margin-bottom:40px;margin-top:10px;display:flex;flex-flow:row wrap;}
.gnb ul {display:block;width:100%;}
.gnb ul:after {display:blcok;content:"";clear:both}
.gnb li {display:block;float:left;width:calc(100%/4);text-align:center;color:rgb(255,255,255)}
.gnb li span {color:#fff;font-size:15px;display:block;margin:0 7%;letter-spacing:-.9px}
.gnb-icon {display:inline-block;/*width:86%;*/padding-top:100%;}
.gnb-icon_find {background:url("../images/new/btn_find.png") center center no-repeat;background-size:contain;}
.gnb-icon_sell {background:url("../images/new/btn_sell.png") center center no-repeat;background-size:contain;}
.gnb-icon_onsale {background:url("../images/new/btn_sale.png") center center no-repeat;background-size:contain;}
.gnb-icon_reconstr {background:url("../images/new/btn_reconstr.png") center center no-repeat;background-size:contain;}
.gnb-icon_analysis {background:url("../images/new/btn_analysis.png") center center no-repeat;background-size:contain;}
.gnb-icon_news {background:url("../images/new/btn_news.png") center center no-repeat;background-size:contain;}
.main-header {background:none;}
.main-header.active {background:#7a1d05;opacity:.9;border:none}
.main-content {min-height:640px;height:100vh;position:relative}
.slide_box {position:absolute;left:0;top:0;right:0;bottom:0;height:100%;width:100%;display:block;overflow:hidden;background-size:cover;background-position:50%;}
.slidelst {position:absolute;top:0;left:0;right:0;bottom:0;height:100%;min-height:640px;}
.slide01 {background-repeat:no-repeat;background-position:50% 50%;background-size:cover;background-image: url(../images/img_slide05.png);}
.slide02 {background-repeat:no-repeat;background-position:50% 50%;background-size:cover;background-image: url(../images/img_slide01.png);}
.slide03 {background-repeat:no-repeat;background-position:50% 50%;background-size:cover;background-image: url(../images/img_slide03.png);}
.main_sch_wrap {width:80%;position:absolute;left:50%;top:38%;transform:translate(-50%,-50%);z-index:11}
.main-content .inpout{position:relative;padding-right:42px;padding-left:10px;}
.main-content .tabcon .inpout .inp {padding-left:0;font-size:16px;padding-right:0}
.main_sch_wrap .inp {border:0}
.main_sch_wrap .searchbox {position:relative}
.main_sch_wrap .sc_dec_s {color:#fff;margin-top:15px;font-size:13px;line-height:1.5;font-weight:500}
.main_sch_wrap .sc_dec_s b {color:#fad200}
.main_sch_wrap .sch_result {padding-left:10px}
.btn_sch {position:absolute;right:0;top:0}
.btn_sch button {width:40px;height:45px;display:inline-block;}
.btn_sch button span {width:16px;height:13.5px;background-image:url(../images/ico_search.png);background-repeat:no-repeat;background-size:contain;text-indent:-9999px;position:absolute;top:15px;left:13px}
.box-list {width:100%;max-height:180px;padding:10px;box-sizing:border-box;background:#fff;position:absolute;left:0;top:45px;overflow-y:auto;border:1px solid #fff;z-index:100}
.box-list-basic {border:1px solid #d0d2d6}
.box-list .sch_word {display:block;width:100%;padding:5px 0;position:relative;font-size:15px}
.box-list .sch_word b {color:#7a1d05}
.box-list a:hover {text-decoration:underline}
.box-list a .address {display:block;color:#888;font-size:13px}
.sch_ico {display:inline-block;width:19px;height:18px;margin-right:5px;vertical-align:middle}
.listcontaier {background:#eaeaea}
.cont_wrap {width:100%;min-height:100%;position:relative;/*flex:1;*/box-sizing:border-box;/*display:flex*/}
/* .login {height:100vh} */
#dawinWrap #wrap.joinagent {overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);height:100%;}
.joinagent .cont_wrap, .regi .cont_wrap {padding-bottom:60px}
.regi .cont_wrap .tab_cont, .joinagent .cont_wrap .tab_cont, .recomm .cont_wrap .tab_cont {padding:10px 20px;width:100%;box-sizing:border-box}
.cont_wrap>.cont {padding-bottom:60px}
.head-tit {font-size:25px;padding-bottom:20px;position:relative;font-weight:300}
/*.head-tit:after {content: '';position:absolute;bottom:10px;left:50%;width:16px;height:16px;background:url("../images/ico_diag.png") no-repeat}*/
/*.sub_section .submain-box:first-child {background:#f7f7f7;padding-bottom:30px}*/
.sub_section .submain-box:last-child {padding-bottom:50px}
.sc_dec_s {margin-top:20px;font-weight:400;font-size:13px;}
.main .scdec_img {position:relative;max-width:100%;display:inline-block}

// .gnb ul {width:100%;display:flex;justify-content:space-between;flex-wrap:wrap;justify-content:space-evenly;justify-content: flex-start;}
// .gnb ul li {flex-direction:row;flex-basis:25%;box-sizing:border-box;}
// .gnb ul::before, .gnb ul::after {content:'';width:100%;order:1;}
// .gnb ul li:nth-child(n + 3) {order:1;}

@keyframes zoomIn { 
	0% { 
		opacity: 0; 
		transform: scale3d(.3, .3, .3); 
	} 
	50% { 
		opacity: 1; 
	} 
} 
.animated {animation-duration:1s;animation-timing-function:ease-in-out;animation-iteration-count:1;animation-fill-mode:forwards;animation-name:zoomIn;} 
.circle01 {width:47px;height:47px;background-color:#6a0000;border-radius:300px;display:block;position:absolute;top:80px;right:114px;opacity:0.4;animation-delay:1s;}
.circle02 {width:29px;height:29px;background-color:#3c52c4;border-radius:300px;display:block;position:absolute;top:100px;right:98px;opacity:0.4;animation-delay:1s;}
.circle03 {width:47px;height:47px;background-color:#e3441b;border-radius:300px;display:block;position:absolute;top:109px;right:63px;opacity:0.4;animation-delay:1s;}
.circle04 {width:32px;height:32px;background-color:#791900;border-radius:300px;display:block;position:absolute;top:79px;right:49px;opacity:0.4;animation-delay:1s;}
.circle05 {width:26px;height:26px;background-color:#3b1e1e;border-radius:300px;display:block;position:absolute;top:157px;right:28px;opacity:0.4;animation-delay: 1s;}
.circle06 {width:70px;height:70px;background:url(../images/img_bubble01.png) no-repeat;background-size:contain;display:block;position:absolute;top:30px;right:5px;animation-delay: 2s;}
.circle07 {width:80px;height:80px;background:url(../images/img_bubble02.png) no-repeat;background-size:contain;display:block;position:absolute;top:90px;right:0;animation-delay: 2.5s;}
.circle08 {width:92px;height:92px;background:url(../images/img_bubble03.png) no-repeat;background-size:contain;display:block;position:absolute;top:110px;right:90px;animation-delay: 3s;}
.submain-box .box-inner .add_txt {margin-top:15px;line-height:1.8;font-size:14px;}
.submain-box .box-inner .add_txt .btn-xsmall {display:inline-block;font-size:13px;padding:3px 5px;margin-left:5px;vertical-align:middle}
.loginbefore .btn-user-wh {box-sizing:border-box;text-indent:0;color:#fff;background:none;vertical-align:middle;padding-top:16px;font-size:14px;width:58px;text-align:left}



/* 로그인 */
.vt-area {width:100%;padding:40px;box-sizing:border-box;/*position:absolute;top:80px;left:0;*/margin:auto}
.vt-area .p01 {font-size:22px;text-align:left}
.vt-area .p02 {font-size:32px;letter-spacing:-1.5px;line-height:40px;text-align:left;margin-bottom:20px}
.vt-area .noti {text-align:left;font-size:18px;margin-top:40px}
.vt-area .noti b {color:#7a1d05}
.vt-area .inp:focus {border-bottom-color:#CE0003;-webkit-transition:border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;}
.vt-area .radio_box i {width:26px;height:26px;margin-right:10px;display:block;background-image:url(../images/btn_chk02.png);background-repeat:no-repeat;background-size:contain;float:left;position:relative;z-index:1;}
.login-group {margin-top:70px;margin-bottom:70px}
.login-group .btn-group .btn { width: 100%;}
.login-group .btn-login {margin:15px 0;border-radius:50px;background:#a42302;background: -webkit-linear-gradient(to right, #a42302, #7a1d05);background:linear-gradient(to right, #a42302, #7a1d05);}
.login-group .inp {color:#1e1e1e;font-size:16px;font-weight:500;border:none;border-bottom:1px solid #ccc;height:50px;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.login-group .check-box strong {font-size:14px}
.login-group-dawin {margin-top:40px}
.login-group-dawin .btn {width: 100%;}
.lg_link {/*position:absolute;bottom:100px;left:50%;transform:translatex(-50%);*/text-align:center;width:100%;margin-top:40px;display:inline-block;}
.lg_link a {display:inline-block;color:#444;font-weight:500;font-size:15px;}
.lg_link a:nth-child(2):before {display:inline-block;content: '';width:1px;height:8px;margin: -3px 10px 0 10px;vertical-align:middle;background-color:#d2d2d2;}
.lg_link a:last-child:after {clear:both}
.vt-area .btn {width:100%}
/* 221101 중개사 로그인 버튼 추가 */
.login-group .check-box {margin-top:16px;}
.login-group .lg_link {margin-top:16px;}
.login-group .lg_link a {text-decoration:underline;}
.lg_linkgroup {display:flex;flex-flow:row wrap;row-gap:8px;padding:0 40px;box-sizing:border-box;}
.lg_linkgroup .btn {width:100%;border-radius:50px;height:50px;display:flex;align-items: center;justify-content: center;}
.lg_linkgroup .btn.btn-overview {border:1px solid #545454;height:44px;}
.lg_linkgroup .btn.btn-bkjoin {border:1px solid #545454;background:#fff;color:#222;}
.lg_linkgroup .btn.btn-applyacademy {background:#223CFB;color:#fff;}

/*휴대폰번호로 로그인*/
.unit.t_red {color:#fa5252;top:15px}
.ntc_p strong, .ntc_p big {font-size:26px;display:block;}
.checkdiv {padding:12px 15px;border:1px solid #ededed;font-size:17px;margin-top:10px;position:relative}
.checkdiv:first-child {margin-top:0}
.checkdiv.selected {background:#e24f28;color:#fff;border-color:#e24f28}
.checkdiv.selected:after {display:inline-block;content:'';background:url("../images/new/ico_check.png") no-repeat;background-size:contain;width:15px;height:15px;position:absolute;right:15px;top:15px}

/* 회원가입 */
/** 이용동의 **/
.bgrey {background:#f7f7f7;}
.subg {background:#f7f7f7;padding:60px 20px 40px;}
.step {position:absolute;top:10px;right:15px;font-size:15px;color:#000;font-weight:600}
.step b.curr {font-size:22px;color:#7a1d05;}
.step b.all {font-size:20px}
.subj_tit {font-size:16px;font-weight:300;padding-bottom:30px}
.subj_tit>span {display:block;line-height:1.3}
.subj_tit .m_tit {font-size:21px;font-weight:600;letter-spacing:-.9px}
.subj_tit + .process + .m_exp {margin-top:-10px;margin-bottom:10px;font-size:14px;}
.process {position:absolute;right:20px;top:15px}
.process:after {display:block;content:'';clear:both}
.bul_proc {display:inline-block;width:6px;height:6px;border-radius:30px;background:#ccc;float:left;margin-right:5px;vertical-align:middle;line-height:12px;margin-top:2px;cursor:default;}
.bul_proc:last-child {margin-right:0}
.bul_proc:last-child:after {clear:both;content:''}
.bul_proc.prev {background:#922219;}
.bul_proc.on {width:10px;height:10px;background:#922219;margin-top:0}
.term_p {padding-bottom:60px}
.term_box li {background:#fff;width:100%;box-sizing:border-box;margin-bottom:15px;}
.term_box li:first-child {margin-bottom:20px}
.term_p .check-box {font-size:17px;margin-bottom:10px}
.term_p .check-box .check {padding:0;height:25px;display:block;}
.term_p .check-box .ag_all strong {font-size:18px;display:inline-block;}
.term_p .check-box strong em {color:#909090;font-weight:300}
.term_p .check-box strong {font-size:17px;line-height:25px}
.term_p .check_box label strong {letter-spacing:-0.9px}
.term_box {width:100%;box-sizing:border-box;max-height:180px;overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);height:100%;font-size:12px;border:1px solid #b9b9b9;padding:12px;margin-top:16px;margin-bottom:16px;color:#666;font-weight:100;line-height:18px}
.check-box + .t_cont {margin-top:15px}
.t_cont>p {font-size:13px;font-weight:400}
.t_cont>p>strong {font-size:14px !important;text-align:left;float:inherit;padding-left:0 !important}
.btn-area-bottom{width:100%;position:fixed;bottom:0;left:0;z-index:105;}


/** 개인정보입력 **/
.v_nt {padding-top:20px}
.alert_msg {padding-top:8px;padding-bottom:8px;font-size:13px;line-height:1.2}
.alert_msg.red {color:#da2b2b;}
.alert_msg.basic {color:#666;}

/* 비밀번호찾기 */
.ntc_txt {padding:0 10px 20px;font-size:16px;text-align:center;width:100%;box-sizing:border-box}
.ntc_p {font-size:18px;text-align:center;line-height:1.4}
/*.ntc_p big {font-size:24px;}*/
.result_area .rst_p {font-size:26px}
.result_area .ntc_p {margin-top:10px}

/* faq */
.faq .cont_wrap {padding-bottom:60px;/*background:#efefef*/overflow-y:overlay;@include overflow-scroll-custom (dark, medium, y);}
/*.accordian {padding-top:10px;}*/
.faq .accordian {width:100%}
.accordian li {display:block;/*margin-bottom:10px;*/background:#fff;border-bottom:10px solid #efefef}
.accordian li:first-child {border-top:10px solid #efefef}
.qitem {display:block;padding:20px;font-size:18px;}
.qitem b {color:#f76a4c;margin-right:5px;}
.aitem {display:block;padding:15px 45px 15px 20px;border-top:1px solid #dedede;}
.faq .aitem {background:#fff url("../images/btn_arr01.png") no-repeat center right 20px;}
.faq .aitem.active {background:#fff url("../images/btn_arr02.png") no-repeat top 22px right 20px;}
.ans {padding:15px 20px;background:#f9f9f9;white-space:pre-line;}
.qna_cont {width:100%;box-sizing:border-box;padding:20px}
.qna_cont p {margin-bottom:10px}
.selec + .inp, .inp + .txtarea {margin-top:10px}
.qna_cont .txtarea {height:300px}

/*약관*/
.term .cont_wrap {padding:20px;width:100%;box-sizing:border-box;display:block}
.term .box {margin-top:30px}
.term strong {margin-bottom:5px;}
.term_tbl .tbl_type01 {font-size:14px;margin:8px 0}
.term_tbl p {font-size:13px;}

/* 회사소개 */
.company .header {background:none}
.company .company-imgbox01 {width:100%;height:80vh;position:relative;background-position:center;background:url("../images/img_company01.png") no-repeat;background-attachment:fixed;background-size:cover;}
.company .company-imgbox01 .box-inner {padding-top:15rem;}
.company .company-imgbox01 .box-content {background:url("../images/img_company02.png") no-repeat;background-size:contain;background-position:center;height:90px;}
.lst-special {margin-bottom:50px}
.lst-special:after {display:block;content:'';clear:both}
.lst-special li {display:block;width:100%}
.img-special {display:block;padding-bottom:20px}
.ico-spcail01 {display:inline-block;width:56px;height:56px;background:url(../images/ico_special01.png)}
.ico-spcail02 {display:inline-block;width:56px;height:56px;background:url(../images/ico_special02.png)}
.ico-spcail03 {display:inline-block;width:56px;height:56px;background:url(../images/ico_special03.png)}
.ico-spcail04 {display:inline-block;width:56px;height:56px;background:url(../images/ico_special04.png)}
.txt-special {font-size:18px;;display:block;padding-bottom:50px}
.lst-special li:last-child .txt-special {padding-bottom:0}
.company .company-imgbox02 {width:100%;height:400px;position:relative;background:url("../images/img_company03.png") no-repeat 50% 0;background-position:center;background-size:cover;background-attachment:fixed}
.company .company-imgbox02 .box-inner {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);padding-top:0;padding:0 50px;}
.company .company-imgbox02 .box-content {height:80px;background:url("../images/img_company04.png") no-repeat;background-size:contain;background-position:center;}
.company .submain-box .company-dec {font-size:18px;line-height:1.6;padding-bottom:50px;font-weight:400}
.company .submain-box:last-child {background:#7a1d05}
.company .submain-box:last-child .company-dec {color:#fff}

/* 알림페이지 */
.alarm .header {background:#7a1d05;opacity:1}
.alarm .header .title {color:#fff}
.alarm .header .btn_text button span {font-size:15px;color:#fff}
.al_cont {width:100%}
.al_cont .al_lst li {display:block;border-bottom:1px solid #efefef}
.al_cont .al_lst li.unread a {background:#fafafa}
.al_cont .al_lst li a {display:block;padding:20px}
.al_cont .al_lst li.unread span.txt {display:inline-block;vertical-align:middle;}
.al_cont .al_lst li.unread span.txt:before {display:inline-block;content:'';margin:0 10px 0 0;width:5px;height:5px;background:#e33f4b;border-radius:5px;vertical-align:middle;margin-top:-3px}
.al_cont .al_lst li span.date {display:block;color: color(normal, 030);font-size:13px;margin-top:5px}
.opt_grp span.yes {padding-top:50px;background:url(../images/opt01.svg) no-repeat top center;}

.vt-area>.ntc_txt {padding-top:80px}
.main.banner .quickbn {display:block;width:100%;height:70px;background:url(../images/img_quickbn07.png) no-repeat;background-size:cover;text-indent:-9999px}

:root.root-dawin {

}