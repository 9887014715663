@import 'partials/_variables';
@import 'partials/_mixin';


:root.root-front-system {
	height: 100%;
	font-size: 1px;
	
	body {
		margin: 0;
		padding: 0;
		height: 100%;
		word-break: keep-all;
		@include font((outline: body, size: medium, family: default)...);
		-webkit-text-size-adjust: none;
	}
	
	hr {
		display: none;
	}
	
	img {
		max-width: 100%;
	}
	
	body>#wrap {
		display: flex;
		flex-direction: column;
		// align-items: center;
		// padding: 0 16rem;
		height: 100%;
	
		@include respond-below(mobile) {
			// padding: 0 24rem;
		}
	}
	
	#page {
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 1600rem;
		max-width: 100%;
	}
	
	.page-head {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		height: 72rem;
	
		.page-subject {
			margin: 0;
	
			.page-name {
				display: block;
	
				svg {
					display: block;
					fill: color(primary, 070);
				}
			}
		}
	}
	
	.page-body {
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 48rem 0;
	}
	
	
	.page-foot {
		@include hidden;
	}
	
	/**
	 * @type layout
	 * @name page-skip
	 */
	.page-skip {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 100;
		top: -120rem;
		left: calc(50% - 50vw);
		width: 100vw;
		height: 120rem;
		background-color: color(base, 100);
	
		.skip-item {
			color: color(base, 10);
			@include font((outline: body, size: medium)...);
		}
		
		&:focus-within {
			top: 0;
		}
	}
	
	.info-board {
		margin: 0 auto;
		width: 480rem;
		max-width: 100%;
	
		.board-wrap {
			.board-head {
				.board-subject {
					margin: 0;
					@include font((outline: head, size: large-3x)...);
					color: color(base, 100);
	
					~.board-summary {
						margin-top: 16rem;
					}
				}
	
				.board-summary {
					margin: 0;
					@include font((outline: body, size: medium)...);
					color: color(base, 080);
	
					.wbr {
						@include respond-above(mobile) {
	
							display: block;
						}
					}
				}
			}
			.board-util {
				margin-top: 48rem;
	
				.button-area {
					display: flex;
					column-gap: 8rem;
					justify-content: center;

					.btn {
						&[class*=attr] {
							.btn-text::before {
								content: "";
								display: block;
								width: 32rem;
								height: 32rem;
								background-repeat: no-repeat;
								background-position: 0 0;
								background-size: cover;
							}
						}
	
						&.attr-edge {
							.btn-text::before {
								background-image: url(../images/svg/icon_brand_edge.svg);
							}
						}
	
						&.attr-chrome {
							.btn-text::before {
								background-image: url(../images/svg/icon_brand_chrome.svg);
							}
						}
					}	
				}
			}
		}
	
		&.type-c {
			text-align: center;
		}
	}
	
	/**
	 * @type front
	 * @name page-support-browser
	 */
	.page-body.page-support-browser {
		#local {
			.local-head {
				@include hidden;
			}
		}
	}
}