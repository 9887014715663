@import 'partials/_variables';
@import 'partials/_mixin';

:root.root-front-signup {
	#wrap {
		display: flex;
		flex-direction: column;
	}
	
	.page-body {
		#local {
			.local-util {
				margin: 80rem 0;

				.button-display {
					.button-area {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}
	
	
	
	@include respond-below(tablet) {
		.page-body {
			display: flex;
			flex-direction: column;
	
			#local {
				display: flex;
				flex-direction: column;
				
				.local-head {
					display: flex;
					align-items: center;
					column-gap: 8rem;
					padding: 0 16rem;
					height: 64rem;
					box-shadow: 0 1rem 0 0 rgba(0,0,0,0.05);
				
					.local-subject {
						order: 2;
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						margin: 0;
				
						.local-name {
							display: flex;
							color: color(base, 100);
							@include font((outline: head, size: small-1x, weight: bold)...);
							@include ellipsis(1);
						}
					}
				
					.local-control {
						order: 1;
						flex-shrink: 0;
	
						+.local-control {
							order: 3;
						}
				
						.button-area {
							.btn {
								display: block;
								position: relative;
								height: 24rem;
								width: 24rem;
								color: color(base, 100);
								text-align: center;
				
								.btn-text {
									@include font((outline: body, size: small-4x)...);
								}
				
								&::before {
									content: '';
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								}
							}
				
							.btn.back::before {
								background-color: color(base, 000);
								background-image: icon(arrow-back, color(base, 100));
							}
	
							.btn.share::before {
								background-color: color(base, 000);
								background-image: icon(share, color(base, 100));
							}
	
							.btn.search {
								&::before {
									background-color: color(base, 000);
									background-image: icon(dawin-search, color(base, 100));
								}
								&.selected::after {
									content: "";
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									width: 4rem;
									height: 4rem;
									border-radius: 2rem;
									background-color: color(accent, 010);
								}
							}
						}
					}
				}
			
				.local-body {
					padding: 0 20rem;
	
					#content {
						margin: 40rem 0 80rem 0;
					}
				}
	
				.local-util {
					margin: 0;
					
					.button-display {
						.button-area {
							.btn {
								flex: 1 1 100%;
								border-radius: 0;
							}
						}
					}
				}
			}
		}
	
		#page.page-intro {
			flex: 1 1 100%;
			padding: 64rem 0 60rem 0;
			overflow: hidden;
	
			.page-body {
				flex: 1 1 100%;
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
	
					.local-head {
						position: absolute;
						z-index: 1;
						top: 0;
						right: 0;
						left: 0;
						background-color: color(base, 000);
					}
					.local-body {
						@include most-outer-selector('.mobile') {
							@include overflow-scroll-system(dark, medium, y);
						}
				
						@include most-outer-selector('.laptop') {
							@include overflow-scroll-custom(dark, medium, y);
						}
					}
				}
			}
		}
	
		#page {
			.page-head {
				display: none;
			}
	
			.page-body {
				overflow: hidden;
				
				#local {
					flex: 1 1 100%;
					overflow: hidden;
					
					.local-head {
						position: relative;
						z-index: 10;
						background-color: color(base, 000);
					}
				}
	
				.local-body {
					flex: 1 1 100%;
					@include most-outer-selector('.mobile') {
						@include overflow-scroll-system(dark, medium, y);
					}
			
					@include most-outer-selector('.laptop') {
						@include overflow-scroll-custom(dark, medium, y);
					}
				}
			}
	
		}
		
		#footer,
		.page-foot {
			display: none;
		}
	}
	
	
	@include respond-above(tablet) {
		#page {
			margin: 56rem 0 0 0;

			@include most-outer-selector('.mobile') {
				@include overflow-scroll-system(dark, large, y);
			}
	
			@include most-outer-selector('.laptop') {
				@include overflow-scroll-custom(dark, large, y);
			}
		}
	
		.page-head {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
	
		}
	
		.page-body {
			#local {
				display: flex;
				flex-direction: column;
				padding: 0 0 80rem 0;
	
				.local-head {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 48rem 0;
					
					.local-subject {
						margin: 0;
						@include font((outline: body, size: large, weight: medium)...);
						color: color(base, 100);
			
						.local-name {
							display: block;
						}
					}
					.local-control {
						display: none;
					}
				}
			
				.local-body {
					padding: 0 40rem;
				}
			}
		}
	}
	
	
	.local-body {
		#content {	
			
			.content-body {
				display: flex;
				flex-direction: column;
				row-gap: 40rem;
				position: relative;
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 640rem;

				>.info-board {
					margin-top: 48rem;
				}
			}
		}
	}
}